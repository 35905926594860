angular.module('org-admin')

  .directive('programListingRegistrationQuestion', function() {

    return {
      scope: {},
      templateUrl: '/static/org/program-listings/questions/program-listing-registration-question.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '='
      },
      controller: function(_, $scope) {
        var ctrl = this

        ctrl.properties = ctrl.question.properties || {}
        ctrl.properties.flatten_field_data = true
        ctrl.timeEnabled = true

        var timezone = ctrl.properties.timezone || 'UTC'

        ctrl.model = _.isObject(ctrl.answer) ? ctrl.answer.value : null
        ctrl.fields = ctrl.properties.fields || {}
        ctrl.inputName = 'form_result_' + ctrl.question.id
        ctrl.availableChoices = ctrl.question.availableChoiceElements({ include: [].concat(ctrl.model || []) })
        ctrl.urlPattern = /^(https?:\/\/)?[^ ."]+(\.[^ ."]+)+$/

        ctrl.dateFormat = ctrl.properties.dateFormat || 'MM/DD/YYYY'


        ctrl.formatDate = function(date) {
          return moment(date).format(ctrl.dateFormat)
        }
        ctrl.timePickerOptions = {
          timeFormat: 'g:i A',
          appendTo: 'body'
        }

        ctrl.changeRegType = function() {
          ctrl.model.reg_url = null
          if (!_.includes(['sportsengine', 'other'], ctrl.model.reg_type)) {
            ctrl.model.min_date = null
            ctrl.model.max_date = null
            ctrl.model.min_time = null
            ctrl.model.max_time = null
            ctrl.model.min_date_time = null
            ctrl.model.max_date_time = null
          }
        }

        if (ctrl.timeEnabled) {
          var minDate = ctrl.model.min_date_time
          var maxDate = ctrl.model.max_date_time

          $scope.$watch('ctrl.model.min_date', minChange)
          $scope.$watch('ctrl.model.max_date', maxChange)
          $scope.$watch('ctrl.model.min_time', minChange)
          $scope.$watch('ctrl.model.max_time', maxChange)
        }

        function minChange(newVal) {
          dateTimeChange('min')
        }

        function maxChange(newVal) {
          dateTimeChange('max')
        }

        function dateTimeChange(field) {
          var date = ctrl.model[field + '_date']
          if (!date) return
          var time = ctrl.model[field + '_time'] ? moment(ctrl.model[field + '_time']) : null
          var hrs = time ? time.hours() : 0
          var mins = time ? time.minutes() : 0

          ctrl.model[field + '_date_time'] = moment.tz(date, timezone).hours(hrs).minutes(mins)
        }

      }
    }

  })
