angular.module('org-admin')
  .directive('processorSettingsGbr', function() {
    return {
      scope: {},
      bindToController: {},
      templateUrl: '/static/org/processor-settings/GBR/processor-settings-form.html',
      controllerAs: 'ctrl',
      controller: function(
        $routeParams,
        $scope,
        $window,
        $location,
        adyenCountries,
        ProcessorSettings,
        currentOrg,
        setAs,
        i18ng,
        showError,
        _
      ) {
        var ctrl = this
        var processorSettings = {}
        var addressKeys = ['address_1', 'address_2', 'city', 'state', 'postal_code', 'country']
        ctrl.adyenCountries = adyenCountries
        ctrl.updateSettingsLoading = false
        ctrl.phonePattern = /^\(?0( *\d\)?){9,10}$/
        ctrl.noVatNumber = false
        ctrl.hasSettings = false

        ProcessorSettings.http('GET', currentOrg.id)
          .then(loadProcessorSettings)
          .catch(showError)
          .finally(setAs(ctrl, 'loading', false))

        ctrl.submit = function() {
          ctrl.saving = true

          var action = ctrl.hasSettings ? 'PUT' : 'POST'
          setSubMerchantName()
          return ProcessorSettings.http(action, currentOrg.id, ctrl.processorSettings)
            .then(
              function(success) {
                $window.location.reload()
              },
              function(err) {
                location.href = '#processor-settings-errors'
                var responseError = err.data.error || err.statusText
                ctrl.serverErrors = responseError.messages || [responseError]
                ctrl.saving = false
              }
            )
        }

        ctrl.cancel = function() {
          redirectToFinancialSettings()
        }

        ctrl.updateSettings = function() {
          ctrl.updateSettingsLoading = true
          ProcessorSettings.syncProcessorSettings(currentOrg.id)
            .then(loadProcessorSettings)
            .catch(showError)
            .finally(setAs(ctrl, 'updateSettingsLoading', false))
        }

        function redirectToFinancialSettings() {
          $location.path('/org/' + currentOrg.id + '/billing/financial_settings')
        }

        function loadProcessorSettings(data) {
          var resp = data.data.result
          if (!resp) return
          var legalEntity = resp.legal_entity || {}
          var subMerchant = resp.sub_merchant || {}

          // default to org address if legalEntity address is empty
          var legalAddr = _.pick(legalEntity, addressKeys)
          if (_.isEmpty(_.pick(legalAddr, _.identity))) {
            _.assign(legalEntity, _.pick(currentOrg, addressKeys))
          }

          processorSettings = {
            document_image: resp.document_image || {},
            legal_entity: legalEntity,
            sub_merchant: subMerchant
          }
          processorSettings.legal_entity.tax_id_type = 'ein'

          setVatNumber()

          var adyenStatuses = processorSettings.sub_merchant.adyen_status_reason
          if (adyenStatuses) {
            ctrl.merchantAccountErrors = adyenStatuses.merchant_account_errors
            ctrl.documentUploadErrors = adyenStatuses.document_upload_errors
            ctrl.documentErrorMessage = adyenStatuses.document_upload_message || ''
            ctrl.merchantAccountRejected = adyenStatuses.merchant_account_status == 'rejected'
            ctrl.documentUploadRejected = adyenStatuses.document_upload_status == 'rejected'
            ctrl.documentImageFilename = processorSettings.document_image.filename

            ctrl.merchantErrorMessage = function() {
              if (ctrl.merchantAccountErrors === '1501, 1501') {
                return adyenStatuses.merchant_account_message || ''
              }
              else if (adyenStatuses.identity_verification_errors === '1501') {
                return adyenStatuses.identity_verification_message || ''
              }
              else if (adyenStatuses.company_verification_errors === '1501') {
                return adyenStatuses.company_verification_message || ''
              }
            }
          }
          ctrl.showDocumentUploadInForm = !processorSettings.document_image.filename || ctrl.documentUploadRejected
          ctrl.processorSettings = angular.copy(processorSettings)
          ctrl.hasSettings = !_.isEmpty(processorSettings.legal_entity) && !_.isEmpty(processorSettings.sub_merchant)
        }

        function setVatNumber() {
          var taxId = processorSettings.legal_entity.taxId
          if (!taxId) return
          if (taxId.includes('GB')) {
            ctrl.vatNumber = taxId
            ctrl.noVatNumber = false
          }
          else {
            ctrl.noVatNumber = true
            ctrl.countryForTaxId = taxId.substring(0, 2)
            ctrl.vatNumber = ''
          }
        }

        function setSubMerchantName() {
          var sm = ctrl.processorSettings.sub_merchant
          if (!sm) return
          var le = ctrl.processorSettings.legal_entity
          if (!sm.firstName) sm.firstName = le.firstName
          if (!sm.lastName) sm.lastName = le.lastName
        }

        ctrl.taxIdDisplay = function(id) {
          return (id) ? 'GBxxx xxxx ' + id : '--'
        }

        $scope.$watchGroup(['ctrl.vatNumber', 'ctrl.countryForTaxId'], function(partialIds) {
          var taxId
          if (!ctrl.processorSettings) return
          if (ctrl.noVatNumber) {
            taxId = partialIds[1] + '000000000'
          }
          else if (partialIds[0] && partialIds[0].includes('GB')) {
            taxId = partialIds[0]
          }
          else {
            taxId = 'GB' + partialIds[0]
          }
          ctrl.processorSettings.legal_entity.taxId = taxId
        })
      }
    }
  })
