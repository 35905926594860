angular.module('org-admin')
  .component('rosterPersonasFilterChips', {
    bindings: {
      survey: '<',
      surveySelectable: '<?', // chevron will not show on survey chip if not present
      filters: '<',
      removeFilter: '&' // arguments: `filter` (name of property on filters object)
    },
    templateUrl: '/static/org/roster-management/roster-personas-filter-chips.html',
    controller: function(currentOrg, RosteringState, SeasonTeams) {

      var ctrl = this
      ctrl.state = RosteringState

      ctrl.$onInit = function() {
        if (ctrl.filters.team_id) {
          var teamID = ctrl.filters.team_id.value

          if (!_.find(ctrl.state.store.seasonTeams.collection, { team_id: teamID })) {
            SeasonTeams.findAll({
              org_id: currentOrg.id,
              team_id: [teamID]
            }, {
              paramSerializer: '$httpParamSerializerJQLike'
            })
          }
        }
      }

    }
  })
