angular.module('org-admin')
  .component('hqDashboardInvoicing', {
    bindings: {
      status: '<'
    },
    templateUrl: '/static/org/hq-dashboard/hq-dashboard-invoicing.html',
    controller: function($q, $scope, currentOrg, dialog, setAs, InvoiceGroup, OrgInvoiceSummary) {

      var $ctrl = this
      var orgId = currentOrg.id

      $ctrl.$onInit = init
      $ctrl.summary = OrgInvoiceSummary
      $ctrl.provisioningUrl = '/org/' + currentOrg.id + '/billing/payments'
      $ctrl.invoicesUrl = '/org/' + currentOrg.id + '/billing/payments/bills'
      $ctrl.invoicedOpts = invoicedOpts
      $ctrl.pastDueOpts = pastDueOpts

      function init() {
        $q.all(dataRequests()).then(setAs($ctrl, 'loaded', true))
      }

      function dataRequests() {
        if ($ctrl.status.unprovisioned) return []
        return [OrgInvoiceSummary.init(), checkForInvoices()]
      }

      function checkForInvoices() {
        return InvoiceGroup
          .count({ org_id: orgId })
          .then(setInvoiceStatus)
      }

      function invoicedOpts() {
        var summary = $ctrl.summary
        return {
          currency: summary.currency_symbol,
          collected: summary.total_invoices_paid,
          invoiced: summary.total_invoices_balance
        }
      }

      function pastDueOpts() {
        var summary = $ctrl.summary
        return {
          currency: summary.currency_symbol,
          past_due: summary.total_invoices_amount_past_due,
          count: summary.total_invoices_past_due_count
        }
      }

      function setInvoiceStatus(count) {
        $ctrl.invoicesPresent = !!count.total_records
      }

    }
  })
