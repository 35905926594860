angular.module('org-admin')

  .service('Store', function(DS, apiV2) {

    return DS.defineResource({
      name: 'stores',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
      actions: {
        calculate_fees: {
          method: 'GET',
          response: apiV2.deserialize
        }
      }
    })

  })
