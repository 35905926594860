angular.module('org-admin')

  .service('InvoiceGroup', function(DS, _, paginate, localRelations, nestedResource, currentOrg, dateFilter, InvoiceGroupItem, apiV2) {

    function setDefaults(Resource, data) {
      if (_.isArray(data)) {
        _.each(data, setDefaults.bind(null, Resource))
      }
      else {
        _.defaults(data, {
          persona_ids: [],
          due_date: dateFilter(Date.now(), 'yyyy-MM-dd')
        })
      }
    }

    var InvoiceGroup = DS.defineResource({
      name: 'invoice_groups',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
      actions: {
        new: {
          method: 'GET',
          response: apiV2.deserialize
        },
        count: {
          method: 'GET',
        },
        generate_invoices: {
          method: 'POST',
        },
        append_personas: {
          method: 'POST',
        },
        summary: {
          method: 'GET',
        },
        'invoice_group_items/replace': {
          method: 'POST',
        },
        send_reminder_emails: {
          method: 'POST',
        }
      },
      relations: {
        hasMany: {
          invoice_group_items: {
            localField: 'invoice_group_items',
            foreignKey: 'invoice_group_id'
          },
          personas: {
            localField: 'personas',
            localKeys: 'persona_ids'
          }
        }
      },
      serialize: nestedResource('invoice_group', function() { return { org_id: currentOrg.id } }),
      defaultValues: {},
      beforeInject: setDefaults,
      methods: {
        clone: function(preserveId) {
          function omitAttrs(val, key) { return /^\$|^id$/.test(key) || _.isFunction(val) }
          var attrs = _.omit(this, omitAttrs)
          if (preserveId) attrs.id = '_' + this.id
          var clone = InvoiceGroup.createInstance(attrs)
          var cloneDefaultItems = clone.invoice_group_items // added after inject
          _.each(this.invoice_group_items, function(item) {
            clone.addLineItem(_.omit(item, omitAttrs))
          })
          _.each(cloneDefaultItems, function(item) {
            clone.removeLineItem(item) // cleanup from afterInject
          })
          return clone
        },
        addLineItem: function(params) {
          return InvoiceGroupItem.createInstance(_.defaults({ invoice_group_id: this.id }, params))
        },
        removeLineItem: function(lineItem) {
          ejectLineItem(lineItem)
          return lineItem
        },
        removeAllLineItems: function() {
          ejectAllLineItems()
        },
        total: function() {
          return totalInvoiceGroupItemPrice(this.invoice_group_items)
        },
        totalUpfrontAmount: function() {
          return totalInvoiceGroupItemUpfrontAmount(this.invoice_group_items)
        },
        saveAll: function() {
          return InvoiceGroup.save(this)
            .then(saveInvoiceGroupItems.bind(this))
        },
        generateInvoices: function() {
          return InvoiceGroup.generate_invoices(this.id).then(apiV2.deserialize)
        },
        appendPersonas: function(personaIds) {
          return InvoiceGroup.append_personas(this.id, { data: { persona_ids: personaIds } })
            .then(apiV2.deserialize)
        },
        reminder: function(attrs) {
          return InvoiceGroup.send_reminder_emails(this.id, { data: attrs })
        }
      }
    })

    // Class level action wrapper
    InvoiceGroup.orgSummary = function(orgId) {
      return getSummary('org_id', orgId)
    }

    var _count = InvoiceGroup.count
    InvoiceGroup.count = function(params) {
      return _count({ params: params }).then(apiV2.deserialize)
    }

    function saveInvoiceGroupItems() {
      var invoiceGroup = this
      var data = {
        data: _.pick(invoiceGroup, 'invoice_group_items')
      }
      function updateCachedItems(response) {
        InvoiceGroupItem.ejectAll({ where: { invoice_group_id: invoiceGroup.id } })
        return InvoiceGroupItem.inject(apiV2.deserialize(response))
      }

      return InvoiceGroup['invoice_group_items/replace'](invoiceGroup.id, data)
        .then(updateCachedItems)
    }

    function totalInvoiceGroupItemPrice(invoiceGroupItems) {
      return _.sum(
        _.map(invoiceGroupItems, function(invoiceGroupItem) {
          const price = invoiceGroupItem.active_price_schedule ? invoiceGroupItem.active_price_schedule.price : invoiceGroupItem.price
          return invoiceGroupItem.quantity * price
        })
      )
    }

    function totalInvoiceGroupItemUpfrontAmount(invoiceGroupItems) {
      return _.sum(
        _.map(invoiceGroupItems, function(invoiceGroupItem) {
          const upfront_amount = invoiceGroupItem.active_price_schedule ? invoiceGroupItem.active_price_schedule.upfront_amount : invoiceGroupItem.upfront_amount
          return invoiceGroupItem.quantity * upfront_amount
        })
      )
    }

    function ejectLineItem(lineItem) {
      lineItem.invoice_group_id = null
      lineItem.DSEject()
    }

    function ejectAllLineItems() {
      InvoiceGroupItem.ejectAll()
    }

    function getSummary(prop, value) {
      var params = {}
      params[prop] = value
      return InvoiceGroup.summary({
        params: params
      }).then(function(data) {
        data = apiV2.deserialize(data)
        return data
      })
    }

    return paginate(localRelations(InvoiceGroup))
  })

  .service('InvoiceGroupItem', function(DS, localRelations, nestedResource) {
    var InvoiceGroupItem = DS.defineResource({
      name: 'invoice_group_items',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
      relations: {
        belongsTo: {
          invoice_groups: {
            parent: true,
            localKey: 'invoice_group_id',
            localField: 'invoice_group'
          }
        }
      },
      serialize: nestedResource('invoice_group_item'),
      defaultValues: {
        quantity: 1
      }
    })

    return localRelations(InvoiceGroupItem)
  })

  // the resource definitions must be injected for js data associations to initilize correctly
  .run(function(InvoiceGroup, InvoiceGroupItem, Persona) {})
