(function(win) {
  'use strict'

  // This setup (pulling `this` into the closure) was necessary
  // to get some of the karma tests working correctly. jstz
  // was not always available on the `$window` object.

  angular.module('pl-shared')

    .service('timezone', function(moment) {

      // For some reason, injecting i18ng here prevents the routing
      // events from firing (don't even try to understand it).
      var i18ng = win.i18n

      function browserTimezone() {
        if (win.Intl) {
          var format = win.Intl.DateTimeFormat() || {}
          if (format.resolved) return format.resolved.timeZone
        }
        if (win.jstz) {
          var tz = win.jstz.determine()
          if (tz) return tz.name()
        }
        return 'UTC'
      }

      function displayMessage() {
        var parsed = parseZone(api.current.name, moment())
        return i18ng.t('TIMEZONE.display_message', { tz: parsed.zone })
      }

      function set(tz) {
        api.current = moment.tz.zone(tz || browserDefault)
        api.displayMessage = displayMessage()
      }

      function parseZone(name, m) {
        var parts = name.split('/')
        var region = parts[1] ? parts.shift() : api.defaultRegionName
        var zone = parts.join('/').replace(/_/g, ' ')
        var abbr = moment.tz.zone(name).abbr(m || moment())

        return {
          name: name,
          region: region,
          zone: zone,
          abbr: abbr
        }
      }

      var browserDefault = browserTimezone()

      var api = {
        set: set,
        defaultRegionName: i18ng.t('TIMEZONE.default_region_name'),
        parseZone: parseZone,
        browserDefault: browserDefault
      }

      set(browserDefault)

      return api
    })

})(window)
