angular.module('pl-shared')
  .component('dateRange', {
    bindings: {
      startDate: '<',
      endDate: '<',
      startDateTerm: '@',
      endDateTerm: '@'
    },
    templateUrl: '/static/shared/components/date-range/date-range.component.html',
    controllerAs: 'ctrl',
    controller: function() {

      var ctrl = this
      ctrl.$onChanges = function() {
        ctrl.hasNoDates = false
        ctrl.sameDay = false
        ctrl.startDateOutput = parseDate(ctrl.startDate)
        ctrl.endDateOutput = parseDate(ctrl.endDate)
        formatDates()
      }

      function parseDate(date) {
        if (!date) return null
        var parsedDate = moment(date)
        if (!parsedDate.isValid()) return null
        return parsedDate
      }

      function formatDates() {
        var startDate, endDate, formattedStartDate, formattedEndDate
        startDate = ctrl.startDateOutput
        if (startDate) formattedStartDate = startDate.format('MMM D, YYYY')
        endDate = ctrl.endDateOutput
        if (endDate) formattedEndDate = endDate.format('MMM D, YYYY')

        if (!startDate && !endDate) {
          return ctrl.hasNoDates = true
        }

        if (startDate && endDate) {
          // if the dates are the same, we'll show just one date
          if (startDate.isSame(endDate, 'day')) {
            ctrl.sameDay = true
            return ctrl.startDateOutput = startDate.format('MMM D, YYYY')
          }
          // if they have the same year, remove year from first
          else if (startDate.isSame(endDate, 'year')) {
            formattedStartDate = startDate.format('MMM D')
            // if they have the same year and month, remove month from second
            if (startDate.isSame(endDate, 'month')) {
              if (endDate) formattedEndDate = endDate.format('D, YYYY')
            }
          }
        }

        ctrl.startDateOutput = formattedStartDate
        ctrl.endDateOutput = formattedEndDate
      }
    }
  })
