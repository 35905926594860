angular.module('org-admin')
  .directive('installments', function() {
    return {
      scope: {},
      templateUrl: '/static/org/payment-terms/installments.html',
      controllerAs: 'ctrl',
      bindToController: {
        installmentCount: '=',
        customPercentages: '=',
        customFields: '=',
        hasOneInstallment: '&'
      },

      controller: function() {

        var ctrl = this

        ctrl.totalPercentage = 100
        ctrl.installmentCountOptions = _.range(1, 13)

        ctrl.setInstallmentFields = function() {
          ctrl.customPercentages = []
          ctrl.percentageSum = 0
          ctrl.customFields = false
        }
        if (!ctrl.customFields) ctrl.setInstallmentFields()
        if (ctrl.customFields) ctrl.percentageSum = ctrl.totalPercentage

        ctrl.toggleCustomFields = function() {
          ctrl.customFields = !ctrl.customFields
        }

        ctrl.getInstallmentNumbers = function(selectedNumber) {
          var number = parseInt(selectedNumber)
          return _.range(number)
        }

        ctrl.getPercentageSum = function(percentages) {
          ctrl.percentageSum = _.reduce(percentages, function(sum, num) {
            var intSum = parseInt(sum) || 0
            var intNum = parseInt(num) || 0
            return intSum + intNum
          })
        }
      }
    }
  })
