angular.module('pl-shared')

  .directive('userIframe', function() {

    return {
      restrict: 'E',
      replace: true,
      scope: {},
      bindToController: {
        path: '@src',
        domain: '@'
      },
      templateUrl: '/static/shared/components/user-iframes/user-iframe.html',
      controllerAs: 'ctrl',
      controller: function(ENV, $scope, $sce, $window, $routeParams, $document) {

        // Example of expected url https://app.sportngin.com.dev/user/purchases?url=/order/show_user/8317037
        var ctrl = this

        // Listen for iframe events
        $window.addEventListener('message', onPostMessage, false)

        $scope.$on('$destroy', destroy)
        $scope.$watch('ctrl.path', setPath)

        function onPostMessage(event) {
          var data = event.data || {}

          switch (data.type) {
            case 'userIframe:loading': return showSpinner()
            case 'userIframe:loaded': return hideSpinner()
            case 'message:viewed': return updateNginBarMessagesMenu(data.id)
          }
        }

        function updateNginBarMessagesMenu(messageId) {
          if (messageId && $window.nginBarV2) {
            $window.nginBarV2.markMessageAsRead(messageId)
          }
        }

        function setPath(path) {
          if (!path) return
          var domain = ctrl.domain || ENV.urls.nginUserDashboard

          path = $routeParams.url || path || ''
          if (path[0] !== '/') path = '/' + path

          // subsitute custom route params
          var paramSubRegex = /:([a-zA-Z0-9_]+)/
          path = path.replace(paramSubRegex, function(match, param) {
            return ($routeParams[param] || match)
          })

          // split so we can ensure the search hash is at the end for paths like /foo/bar#anchor
          var parts = path.split('#')
          var hash = parts[1] ? '#' + parts[1] : ''
          var url = domain + parts[0]
          url += url.indexOf('?') === -1 ? '?' : '&'
          url += 'layout=pl' + hash

          ctrl.src = $sce.trustAsResourceUrl(url)
          ctrl.loaded = false
        }

        function showSpinner() {
          ctrl.loaded = false
          digest()
        }

        function hideSpinner() {
          ctrl.loaded = true
          digest()
        }

        function digest() {
          if (!$scope.$root.$$phase) $scope.$digest()
        }

        function destroy() {
          $window.removeEventListener('message', onPostMessage, false)
        }
      }
    }

  })
