angular.module('org-admin')
  .service('billingAccountsService', function($http, currentOrg, apiV2, ENV) {
    function fetch() {
      return $http({
        method: 'GET',
        url: ENV.urls.apiProxy + '/billing_accounts/' + currentOrg.billing_account_id,
        headers: apiV2.headers,
        withCredentials: true,
      })
        .then(apiV2.deserialize)
    }

    return {
      fetch: fetch
    }

  })
