angular.module('org-admin')
  .directive('paymentTermsList', function() {
    return {
      scope: {},
      templateUrl: '/static/org/payment-terms/payment-terms-list.html',
      controllerAs: 'ctrl',
      bindToController: {
        cancel: '=',
        confirm: '='
      },
      controller: function(
        $scope,
        currentOrg,
        currentUser,
        dialog,
        debounceCallback,
        PaymentTerms,
        PusherService,
        Search,
        setAs,
        userSettings
      ) {

        var ctrl = this
        var localStorageDismissedAlert = userSettings.get('dismissedPaymentTermsInfoAlertList')
        ctrl.userHasDismissedAlert = localStorageDismissedAlert && localStorageDismissedAlert[currentUser.uuid]

        ctrl.paymentTerms = []
        ctrl.params = { organization_id: currentOrg.id }
        ctrl.options = { result: ctrl.paymentTerms, pagination: {} }
        ctrl.sortColumns = { 'order[name]': 'asc' }

        ctrl.loading = true
        ctrl.searchDirtied = false

        pusherSubscribe()

        ctrl.setAlertAsDismissed = function() {
          userSettings.set('dismissedPaymentTermsInfoAlertList.' + currentUser.uuid, true)
        }

        ctrl.addPaymentTerms = function() {
          dialog.confirm({
            directive: 'payment-term-modal',
            scope: $scope,
            attrs: {}
          })
        }

        ctrl.search = Search.create({
          update: function(searchTerm) {
            ctrl.searchDirtied = true
            ctrl.params.search_fields = 'payment_terms.name,payment_terms.description'
            ctrl.params.search_term = String(searchTerm) || undefined
          }
        })

        var findAll = debounceCallback(PaymentTerms.findAll)
        function loadPaymentTerms() {
          return findAll(ctrl.params, ctrl.options)
            .then(setAs(ctrl, 'paymentTerms'))
            .finally(setAs(ctrl, 'loading', false))
        }

        function pusherSubscribe() {
          PusherService.subscribe('channel_clare_organization_' + currentOrg.id)
          PusherService.bind('event_payment_terms_created', loadPaymentTerms)
        }

        $scope.$watch('ctrl.sortColumns', function(newSort, oldSort) {
          _.each(oldSort, function(dir, col) {
            delete ctrl.params[col]
          })
          _.extend(ctrl.params, newSort)
        })
        $scope.$watch('ctrl.params', loadPaymentTerms, true)
      }
    }
  })
