angular.module('org-admin')
  .component('filterPanelChoiceInput', {
    bindings: {
      id: '@ctrlId',
      filterRule: '<',
      readOnly: '<'
    },
    templateUrl: '/static/org/filtering/inputs/filter-panel-choice-input.html',
    controller: function filterPanelChoiceInputController(_, $scope, $timeout, i18ng) {
      var $ctrl = this

      $ctrl.$onInit = $onInit
      $ctrl.displayValue = displayValue

      function $onInit() {
        $scope.$watch(function() { return $ctrl.filterRule && $ctrl.filterRule.field() }, onFieldChanged)
      }

      $ctrl.choiceSelect2Options = {
        collapseOverflow: true,
        dropdownAutoWidth: true,
        minimumResultsForSearch: 10,
        setupAutoWidth: true
      }

      function onFieldChanged(newVal, oldVal) {
        $ctrl.options = _.map($ctrl.filterRule.field().choice_elements, function(choiceEl) {
          var key = choiceEl.choice.key || choiceEl.choice.name
          return { id: key, text: choiceEl.choice.name }
        })

        if ((newVal === oldVal && !$ctrl.filterRule.value()) || (newVal !== oldVal)) setFirstOption()
      }

      function setFirstOption() {
        $ctrl.filterRule.value(_.first($ctrl.options).id)
      }

      function displayValue() {
        if ($ctrl.filterRule.field().id != 'membership_name') return $ctrl.filterRule.value()

        return _.get(_.findWhere($ctrl.options, { id: $ctrl.filterRule.value() }), 'text')
      }
    }
  })
