angular.module('org-admin')

  .service('OrganizationOptions', function($http, apiV2, i18ng, ENV) {

    var cache = {}
    var apiUrl = ENV.urls.apiProxy

    return {
      getOptions: getOptions
    }

    function setStaticOptions(options) {
      options.genders = labelValuePairs('PROGRAM_LISTING_OPTIONS.genders')
      return options
    }

    function getOptions() {
      var url = apiUrl + '/organization_options'
      var opts = { headers: apiV2.headers, withCredentials: true }
      var promise = cache[url] = !cache[url] ? $http.get(url, opts) : cache[url]

      return promise.then(apiV2.deserialize).then(setStaticOptions)
    }

    function labelValuePairs(translationKey) {
      var pairs = []
      var obj = i18ng.t(translationKey, { returnObjectTrees: true })
      _.each(obj, function(label, value) {
        pairs.push({ label: label, value: value })
      })
      return pairs
    }
  })
