angular.module('org-admin')

  .service('SaleItemService', function(DS) {

    return DS.defineResource({
      name: 'items',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
    })

  })
