angular.module('pl-shared')
  .component('printSurveyResult', {

    bindings: {
      forms: '<',
      formResults: '<',
      surveyResult: '<',
      persona: '<',
      print: '<',
      cancel: '<'
    },

    templateUrl: '/static/shared/components/registration/survey-results/print-survey-result.html',

    controller: function(_, $q) {

      var $ctrl = this

      $ctrl.$onInit = init
      $ctrl.answerType = answerType

      function init() {
        $ctrl.print()
      }

      function answerType(answer) {
        if (answer && answer.isImage) return 'image'
        return 'text'
      }

    }

  })
