angular.module('org-admin')
  .component('rosterTeamsFilterbox', {
    bindings: {
      originalFilters: '<filters',
      cancel: '<',
      confirm: '<'
    },
    templateUrl: '/static/org/roster-management/roster-teams-filterbox.html',
    controller: function($scope, $window, RosteringState, Search, setAs, setWhile) {

      var ctrl = this
      ctrl.state = RosteringState
      var filterSchema = ctrl.state.filtering.teamFilterSchema

      ctrl.saveFilters = !!$window.localStorage.getItem('MassRostering-Teams-SaveFilters')
      ctrl.setSaveFilters = function() {
        if (ctrl.saveFilters) $window.localStorage.setItem('MassRostering-Teams-SaveFilters', true)
        else $window.localStorage.removeItem('MassRostering-Teams-SaveFilters')
      }

      function convertFiltersToFields() {
        var filters = ctrl.filters = _.cloneDeep(ctrl.originalFilters)
        var fields = ctrl.filterFields = _.cloneDeep(filters)
        if (fields.search) fields.search = fields.search.join(',')
        ctrl.search = Search.create({
          minLength: 1,
          term: fields.search,
          update: function(search) {
            fields.search = String(search) || undefined
          }
        })
      }

      function convertFieldsToFilters() {
        var fields = ctrl.filterFields
        var filters = ctrl.filters = _.pick(fields, function(val) { return _.isObject(val) ? !_.isEmpty(val) : !!val })
        if (filters.search) {
          // Remove any whitespace items and duplicates. Ex: test, , test, test1,  => test,test1
          filters.search = _.uniq(_.compact(_.map(filters.search.split(','), _.trim)))
        }
        ctrl.filtersEmpty = _.isEmpty(filters)
      }

      ctrl.$onInit = function() {
        convertFiltersToFields()
        loadField('league_id')
        ctrl.loadLists('league_id', true)
        $scope.$watch('$ctrl.filterFields', convertFieldsToFilters, true)
      }

      function loadField(fieldKey, params) {
        var field = filterSchema[fieldKey]
        return setWhile(ctrl, field.collectionKey + 'Loading', function() {
          params = _.extend({}, field.findAllParams, params)
          return field.service.findAll(params)
            .then(setAs(ctrl, field.collectionKey))
        })()
      }

      ctrl.loadLists = function(fieldKey, preserve) {
        var fields = ctrl.filterFields
        filterSchema.doForChild(fieldKey, function(child, childKey) {
          if (!preserve) ctrl.removeFilter(childKey) // recursively delete/reset child values+collections
          if (fields[fieldKey]) loadField(childKey, _.pick(fields, fieldKey)) // load for new parent value if it's non-empty
          ctrl.loadLists(childKey, preserve)
        })
      }

      ctrl.removeFilter = function(key, item) {
        switch (key) {
          case 'search':
            ctrl.search(item ? _.without(ctrl.filters.search, item).join(',') : '')
            break
          case 'league_id':
          case 'season_id':
          case 'subseason_id':
          case 'division_instance_id':
            delete ctrl.filterFields[key]
            filterSchema.doForChild(key, function(child, childKey) {
              ctrl[child.collectionKey] = []
              ctrl.removeFilter(childKey)
            })
            break
          case 'team_id':
          case 'gender':
            if (!item || ctrl.filterFields[key].length <= 1) delete ctrl.filterFields[key]
            else _.pull(ctrl.filterFields[key], item)
            break
          default:
            delete ctrl.filterFields[key]
            break
        }
      }

      ctrl.clearAll = function() {
        _.each(ctrl.filterFields, function(field, key) { ctrl.removeFilter(key) })
      }

    }

  })
