angular.module('pl-shared')
  .directive('formHiddenIcon', function() {
    return {
      restrict: 'EA',
      replace: true,
      scope: {},
      templateUrl: '/static/shared/components/registration/forms/form-hidden-icon.html',
      bindToController: {
        isHidden: '=',
        helpText: '@'
      },
      controllerAs: 'ctrl',
      controller: function(_, snPopover) {

        var ctrl = this
        ctrl.id = _.uniqueId()
        ctrl.getTooltipId = getTooltipId
        ctrl.toggleTooltip = toggleTooltip

        /**
         * Gets the unique id for the tooltip element.
         *
         * @returns {string} - The unique id of the tooltip element.
         */
        function getTooltipId() {
          return 'form_hidden_icon_tooltip_' + ctrl.id
        }

        /**
         * Shows or hides the tooltip.
         *
         * @param display {boolean} - Optional. Indicates if the tooltip should be displayed or hidden.
         */
        function toggleTooltip(display) {
          var tooltipId = getTooltipId()
          display = (display === void 0) ? !snPopover.openPopovers[tooltipId] : display

          if (display) {
            snPopover.show(tooltipId)
          }
          else {
            snPopover.hide(tooltipId)
          }
        }
      }
    }
  })
