angular.module('org-admin')
  .directive('billsList', function() {
    return {
      scope: {},
      templateUrl: '/static/org/bills/bills-list.html',
      controllerAs: 'ctrl',
      controller: function(
        Invoice, setAs, debounceCallback, $routeParams, $scope, saleFilterHelper,
        Search, SePagination, selectHelper, currentOrg, PusherService, PeopleAndOrgsUiService, i18ng) {

        var ctrl = this

        ctrl.org = currentOrg
        ctrl.loading = true
        ctrl.bills = []
        ctrl.params = { organization_id: currentOrg.id, list: 'summary' }
        ctrl.options = { result: ctrl.bills, data: {} }
        ctrl.sortColumns = { 'order[created_at]': 'desc' }
        ctrl.statuses = saleFilterHelper.statuses
        ctrl.filtersEnabled = 'ctrl.params["monetary_status[]"].length'
        ctrl.showControls = 'ctrl.bills.length || ctrl.search.term || ctrl.bills.loading || ctrl.filtersEnabled'
        ctrl.sePagination = new SePagination({
          loadDataFn: loadBills.bind(this),
          storageId: 'billsList'
        })

        loadDashboardNotifications()
        pusherSubscribe()

        ctrl.filterButtonLabel = function() {
          return saleFilterHelper.filterButtonLabel(ctrl.selectedStatuses)
        }

        $scope.$watchCollection('ctrl.selectedStatuses', function() {
          ctrl.params['monetary_status[]'] = saleFilterHelper.mapMonetaryStatuses(ctrl.selectedStatuses)
        })

        ctrl.search = Search.create({
          update: function(searchTerm) {
            ctrl.params.search_fields = 'sales.description,sale_number,user_full_name'
            ctrl.params.search_term = String(searchTerm) || undefined
          }
        })

        ctrl.displayStatus = function(sale) {
          if (['partial-refund', 'refunded'].includes(sale.monetary_status)) {
            return i18ng.t('MONETARY_STATUS.' + sale.monetary_status)
          }
          return i18ng.t('DISPLAY_STATUS.' + sale.display_status)
        }

        // TODO: ANGULAR2: replace this usage with whatever observable pattern exists in the future
        ctrl.selectedBills = selectHelper.bind($scope, 'ctrl.bills')
        ctrl.selectedStatuses = selectHelper.bind($scope, 'ctrl.statuses')

        $scope.$watch('ctrl.sortColumns', function(newSort, oldSort) {
          _.each(oldSort, function(dir, col) {
            delete ctrl.params[col]
          })
          _.extend(ctrl.params, newSort)
        })

        var findAll = debounceCallback(Invoice.findAll)
        function loadBills(paginationParams) {
          _.set(ctrl, 'loading', true)
          var params = ctrl.sePagination.addPageParams(_.extend({}, ctrl.params, paginationParams))
          return findAll(params, ctrl.options)
            .then(setAs(ctrl, 'bills'))
            .finally(setAs(ctrl, 'loading', false))
        }

        function pusherSubscribe() {
          PusherService.subscribe('channel_clare_organization_' + currentOrg.id)
          PusherService.bind('event_invoices_created', loadBills)
        }

        function loadDashboardNotifications() {
          var CONTAINER_ID = 'bills-list-dashboard-notifications'
          ctrl.hqDashboardNotifications = document.getElementById(CONTAINER_ID)
          PeopleAndOrgsUiService.loadScripts('se-dashboard-notifications', function() {
            PeopleAndOrgsUiService.addBannerEventListeners(ctrl.hqDashboardNotifications)
          })
        }

        function presetStatusFilter() {
          var statusParams = $routeParams.display_status || $routeParams['monetary_status[]']
          if (!statusParams) return
          _.each([].concat(statusParams), function(status) {
            var displayStatus = saleFilterHelper.setDisplayStatus(status)
            ctrl.selectedStatuses.toggle(_.find(ctrl.statuses, { value: displayStatus }), true)
          })
        }

        presetStatusFilter()
        $scope.$watch('ctrl.params', loadBills, true)
      }
    }
  })
