angular.module('pl-shared')

  .directive('profilePhotoQuestionView', function() {

    return {
      scope: {},
      templateUrl: '/static/shared/components/registration/questions/profile-photo-question-view.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        answer: '='
      },
      controller: function($scope, dialog) {

        var ctrl = this

        ctrl.model = ctrl.answer ? ctrl.answer.value : {}

        ctrl.viewImage = function(event) {
          event.stopPropagation()
          dialog.confirm({
            directive: 'image-preview-modal',
            scope: $scope,
            attrs: {
              imgUrl: ctrl.model.url,
              backgroundImage: true,
              imgClass: 'pl-image-preview-modal__image--profile-photo'
            }
          })
        }

      }
    }

  })
