angular.module('org-admin')
  .component('discountName', {
    require: {
      discountForm: '^form'
    },
    bindings: {
      discountCodeDefinition: '='
    },
    templateUrl: '/static/org/discounts/discount-modal/discount-name.component.html',
    controllerAs: 'ctrl',
    controller: function() {
      var ctrl = this
      ctrl.nameMaxLength = 64
    }
  })
