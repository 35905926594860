angular.module('org-admin')
  .component('programListingPreviewFull', {
    templateUrl: '/static/org/program-listings/program-listing-preview-full.html',
    bindings: {
      programListing: '='
    },
    controller: function(currentOrg, ProgramListing, Alerts, renderContext, ENV, $sce, $element, $scope, pageViewHandler) {

      var $ctrl = this
      var previewUrl = currentOrg.sportsengineUrl + '/program/' + $ctrl.programListing.slug + '?iframed=1'

      $ctrl.loaded = false
      $ctrl.onLoadPreview = onLoadPreview
      $ctrl.previewUrl = $sce.trustAsResourceUrl(previewUrl)
      $ctrl.publish = publish

      function onLoadPreview() {
        $ctrl.loaded = true
        $element.find('.pl-wizard').css('opacity', 1)
      }

      function publish() {
        pageViewHandler.fireEvent('Publish', 8)
        return ProgramListing.update($ctrl.programListing.id, { searchable: true })
          .then(publishSuccess, publishFailed)

        function publishSuccess(data) {
          Alerts.success('PROGRAM_LISTING_PUBLISH.success', { list: $ctrl.programListing.name })
          $scope.$emit('publishStatusChanged', 'active', 'draft')
          renderContext.goto('programListings.active', { programListingId: $ctrl.programListing.id })
        }

        function publishFailed() {
          Alerts.error('PROGRAM_LISTING_PUBLISH.failed', { list: $ctrl.programListing.name })
        }
      }

    }

  })
