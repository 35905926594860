angular.module('org-admin')
  .component('membershipDefinitionLinkedMemberships', {
    templateUrl: '/static/org/memberships/membership-definition-linked-memberships.html',
    bindings: {
      membershipDefinition: '=',
    },
    controller: function(
      _, $q, ItemPrice, MembershipDefinition
    ) {
      var $ctrl = this

      $ctrl.$onInit = $onInit

      $ctrl.prices = {}
      $ctrl.linkedMembershipDefinitions = []
      $ctrl.loaded = false

      function $onInit() {
        var promises = _.map($ctrl.membershipDefinition.parent_membership_definition_links, getMemdef)
        $q.all(promises).then(function(data) {
          $ctrl.linkedMembershipDefinitions = data
          getPrices($ctrl.linkedMembershipDefinitions)
        })
      }

      // PRIVATE

      function getMemdef(link) {
        return MembershipDefinition.find(link.parent_membership_definition_id)
      }

      function getPrices(memdefs) {
        var promises = _.map(memdefs, getPrice)
        $q.all(promises).then(function(data) {
          $ctrl.loaded = true
        })
      }

      function getPrice(md) {
        if (!md.clare_item_uuid) return $q.resolve()

        return ItemPrice.find(md.clare_item_uuid).then(function(data) {
          var matches = _.filter(data.variations, function(variation) { return variation.id === data.default_variation_id })
          if (matches.length) {
            $ctrl.prices[md.id] = ((parseInt(matches[0].price_cents, 10) || 0) / 100).toFixed(2)
          }
        })
      }
    }
  })
