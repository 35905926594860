angular.module('org-admin')
  .component('membershipDefinitionAddTags', {
    templateUrl: '/static/org/memberships/membership-definition-add-tags.html',
    bindings: {
      model: '=',
      next: '=',
      back: '=',
      organization: '='
    },
    controllerAs: '$ctrl',
    controller: function(
      currentOrg, _,
      MembershipsUtilService,
      MembershipUiService
    ) {
      var $ctrl = this
      $ctrl.params = { boss_organization_id: currentOrg.id }

      $ctrl.$onInit = $onInit
      $ctrl.validateAndSubmit = validateAndSubmit

      function $onInit() {
        MembershipUiService.loadScripts('se-add-membership-set-tags')
        MembershipUiService.injectScript('element', 'se-add-membership-set-tags')
      }


      function validateAndSubmit() {
        $ctrl.addTags.validateAndSubmit($ctrl.next)
      }

    }
  })
