angular.module('pl-shared')
  /**
   * @name stringUtil - Contains utility functions for working with strings.
   */
  .factory('stringUtil', function stringUtil() {
    return {
      matchTerm: matchTerm
    }

    /**
     * Determines if the provided value matches the provided term.
     * A match is considered any term that can be found in part of the value.
     *
     * Examples:
     *   matchTerm('b', 'abc') === true
     *   matchTerm('Bc', 'abc') === true
     *   matchTerm('d', 'abc') === false
     *
     * @private
     * @param {string} term - The term to match against.
     * @param {string} value - The value to match.
     * @returns {boolean} - True if the term matches the value.
     */
    function matchTerm(term, value) {
      term = (term || '').toLowerCase()
      value = (value || '').toLowerCase()

      return value.indexOf(term) >= 0
    }
  })
