angular.module('org-admin')
  .component('rosterHistoryFilterbox', {
    bindings: {
      cancel: '<',
      confirm: '<'
    },
    templateUrl: '/static/org/roster-history/roster-history-filterbox.html',
    controller: function($element, $q, $scope, $timeout, i18ng, rosterHistoryChangeType, RosterHistoryState) {

      var ctrl = this
      ctrl.state = RosterHistoryState.clone()
      ctrl.state.convertFiltersToFields()
      ctrl.originalFilterFields = _.extend({}, ctrl.state.filterFields)
      ctrl.originalFilters = _.extend({}, ctrl.state.filters)

      var changeModes = [
        'after',
        'before',
        'between',
        'on'
      ]

      ctrl.changeDateModeOpts = {
        data: _.map(changeModes, function(mode) {
          return {
            id: mode,
            text: i18ng.t('ROSTER_HISTORY.FILTERS.CHANGE_DATE.' + mode)
          }
        }),
        minimumResultsForSearch: -1, // disable search
      }

      $scope.$watchCollection('$ctrl.state.filterFields', ctrl.state.convertFieldsToFilters.bind(ctrl.state))

    }

  })
