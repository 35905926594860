angular.module('org-admin')
  .directive('cancelInstallments', function() {
    return {
      scope: {},
      templateUrl: '/static/org/payment-plans/cancel-installments.html',
      controllerAs: 'ctrl',
      bindToController: {
        sale: '<',
        persistedPayments: '<',
        cancel: '=',
        confirm: '='
      },

      controller: function(setAs, $scope, selectHelper, currentOrg, Invoice) {
        var ctrl = this
        ctrl.reasons = []
        ctrl.clientError = false
        ctrl.$onInit = async function() {
          ctrl.cancellableInstallments = angular.copy(_.sortBy(getCancellableInstallments(), 'due_date'))
          ctrl.selectedInstallments = selectHelper.bind($scope, 'ctrl.cancellableInstallments')
          ctrl.totalCancelled = 0
          ctrl.totalOutstanding = _.sum(_.pluck(ctrl.cancellableInstallments, 'amount').map(a => parseFloat(a)))
          ctrl.reasons = await Invoice.getCreditReasons(currentOrg.id)
        }

        ctrl.submit = function() {
          if (!ctrl.selectedInstallments.someSelected()) {
            ctrl.clientError = true
            return
          }
          ctrl.clientError = false
          var payload = createPayload()

          return ctrl.sale.credit(payload)
            .then(ctrl.confirm)
            .catch(setAs(ctrl, 'failure'))
        }

        ctrl.resetDataAndClose = function() {
          return ctrl.cancel()
        }

        ctrl.onChange = function(payment) {
          if (ctrl.selectedInstallments.contains(payment)) {
            ctrl.totalOutstanding -= parseFloat(payment.amount)
            ctrl.totalCancelled += parseFloat(payment.amount)
            payment.amount = 0
            payment.status = 'canceled'
          }
          else {
            _.forEach(ctrl.persistedPayments, p => {
              if (p.id == payment.id) {
                payment.amount = p.amount
                payment.status = p.status
              }
            })
            ctrl.totalOutstanding += parseFloat(payment.amount)
            ctrl.totalCancelled -= parseFloat(payment.amount)
          }
        }

        function createPayload() {
          var saleAdjustments = []
          var payments = []
          ctrl.persistedPayments.forEach(payment => {
            var isCredited = false
            ctrl.cancellableInstallments.forEach(installment => {
              if (payment.id === installment.id && ctrl.selectedInstallments.contains(installment)) {
                isCredited = true
              }
            })
            if (isCredited) {
              payments.push({
                ...payment,
                creditAmount: parseFloat(payment.amount) || 0,
                amount: 0
              })
            }
            else {
              payments.push({
                ...payment
              })
            }
          })

          angular.forEach(ctrl.cancellableInstallments.filter(installment => ctrl.selectedInstallments.contains(installment)), function(installment) {
            var fieldName = 'credit_reason_' + installment.id
            var formField = ctrl.cancelInstallmentsForm[fieldName]

            if (formField && formField.hasOwnProperty('$modelValue')) {
              var selectedOption = formField.$modelValue
              payments.forEach(payment => {
                if (payment.id === installment.id) {
                  payment.selectedOption = selectedOption
                }
              })

              if (selectedOption === null || selectedOption === undefined) {
                formField.$setValidity('required', false)
              }
              else {
                formField.$setValidity('required', true)
              }
              var amount = 0
              ctrl.persistedPayments.forEach(payment => {
                if (payment.id === installment.id) {
                  amount = payment.amount
                }
              })
              var saleAdjustment = {
                amount: parseFloat(amount) || 0,
                debit_credit: 'credit',
                credit_reason: Number(selectedOption.id) ?
                  { id: selectedOption.id } :
                  { title: selectedOption.title || selectedOption, tenant_id: currentOrg.id },
                notes: ctrl.notes
              }

              saleAdjustments.push(saleAdjustment)
            }
          })

          var payload = {
            sale_adjustment: saleAdjustments,
            sale_id: ctrl.sale.id,
            payments: payments
          }
          return payload
        }

        function isLockedPaymentStatus(payment) {
          return payment.status === 'canceled' || payment.status === 'disputed' || payment.status === 'chargeback'
        }

        function getCancellableInstallments() {
          return _.filter(ctrl.persistedPayments, function(payment) {
            if (isLockedPaymentStatus(payment)) return false
            return !payment.is_paid && !payment.upfront_payment
          })
        }
      }
    }
  })
