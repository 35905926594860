angular.module('org-admin')
  .component('competitionProgramDetails', {
    bindings: {
      competitionProgram: '<?',
      cancel: '<',
      confirm: '<'
    },
    templateUrl: '/static/org/competition-programs/competition-program-details.html',
    controller: function(
      _, appPermission, currentOrg, i18ng, Affiliation, Program, ProgramConfiguration, setAs, setWhile, showError
    ) {
      if (!appPermission.competitionPrograms.visible) return showError('404')

      var ctrl = this
      ctrl.program_types = i18ng.t('COMPETITION_PROGRAMS.TYPES', _.extend({ returnObjectTrees: true }))

      ctrl.$onInit = function() {
        if (ctrl.competitionProgram) {
          ctrl.mode = 'edit'
          ctrl.governingSeasonOrgs = []
          return setWhile(ctrl, 'governingSeasonsLoading', loadProgramConfiguration)()
        }
        else {
          ctrl.mode = 'add'
          ctrl.governingSeasonOrgs = currentOrg.root_organizations
          ctrl.competitionProgram = Program.createInstance({
            org_id: currentOrg.id,
            sport_key: null,
            type: null
          })
        }
      }

      ctrl.submit = function() {
        return ctrl.competitionProgram.DSCreate() // upsert will choose POST vs. PUT
          .then(ctrl.confirm)
      }

      ctrl.revertChanges = function() {
        if (ctrl.mode !== 'add') {
          ctrl.competitionProgram.DSRevert()
        }
        ctrl.cancel()
      }

      ctrl.fetchGoverningSeasons = function() {
        if (ctrl.mode === 'add') {
          if (ctrl.competitionProgram.sport_key && ctrl.competitionProgram.type && ctrl.competitionProgram.attached_program_org_id) {
            setWhile(ctrl, 'governingSeasonsLoading', loadAffiliations)()
          }
          else {
            ctrl.governingSeasons = []
          }
        }
      }

      function loadAffiliations() {
        return Affiliation.findAll({
          ancestor_organization_id: ctrl.competitionProgram.attached_program_org_id,
          descendent_organization_id: currentOrg.id
        })
          .then(setAs(ctrl, 'affiliations'))
          .then(loadGoverningSeasons)
      }

      function loadGoverningSeasons() {
        return Program.findAll({
          org_id: ctrl.competitionProgram.attached_program_org_id,
          descendent_org_id: currentOrg.id,
          sport_key: ctrl.competitionProgram.sport_key,
          type: 'GoverningSeason',
          available_to_program_type: ctrl.competitionProgram.type,
          credential_definition_id: Affiliation.credentialDefinitionIDs(ctrl.affiliations)
        })
          .then(setAs(ctrl, 'governingSeasons'))
      }

      function loadProgramConfiguration() {
        return ProgramConfiguration.findAll({ program_id: ctrl.competitionProgram.id, include: ['root_organization'] })
          .then(function(programConfigurations) { return _.first(programConfigurations) })
          .then(setAs(ctrl, 'programConfiguration'))
          .then(function() {
            if (!ctrl.programConfiguration) return
            ctrl.governingSeasonOrgs = [programConfigurationOrgOption()]
            ctrl.competitionProgram.attached_program_org_id = ctrl.programConfiguration.root_organization_id
            ctrl.governingSeasons = [programConfigurationGoverningSeasonOption()]
            ctrl.competitionProgram.attached_program_id = ctrl.programConfiguration.root_program_id
          })
      }

      function programConfigurationOrgOption() {
        return {
          id: ctrl.programConfiguration.root_organization_id,
          name: ctrl.programConfiguration.root_organization_name
        }
      }

      function programConfigurationGoverningSeasonOption() {
        return {
          id: ctrl.programConfiguration.root_program_id,
          name: ctrl.programConfiguration.root_program_name
        }
      }
    }
  })
