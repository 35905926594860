angular.module('org-admin')
  .directive('invoiceGroupEdit', function() {
    return {
      scope: {},
      bindToController: {
        originalInvoiceGroup: '=invoiceGroup',
        cancel: '<',
        confirm: '<'
      },
      templateUrl: '/static/org/invoice-groups/invoice-group-edit-modal.html',
      controllerAs: 'ctrl',

      controller: function(Alerts, dateFilter, InvoiceGroup) {
        var ctrl = this

        ctrl.maxCharacterCount = 64
        ctrl.originalInvoiceGroup.notify_recipient = false
        ctrl.invoiceGroup = angular.copy(ctrl.originalInvoiceGroup)
        ctrl.characterCount = ctrl.originalInvoiceGroup.description.length

        ctrl.checkMaxCharacterCount = function() {
          return ctrl.editInvoiceGroupForm.description.$dirty ? ctrl.maxCharacterCount : ctrl.originalInvoiceGroup.description.length
        }

        ctrl.updateCharacterCount = function() {
          ctrl.characterCount = ctrl.invoiceGroup.description.length
        }

        ctrl.descriptionChanged = function() {
          return ctrl.originalInvoiceGroup.description !== ctrl.invoiceGroup.description
        }

        ctrl.submit = function() {
          if (ctrl.descriptionChanged()) {
            return InvoiceGroup.update(ctrl.invoiceGroup.id, ctrl.invoiceGroup)
              .then(
                function(success) {
                  ctrl.confirm()
                  Alerts.success('INVOICE_GROUP_EDIT.invoice_group_success')
                },
                function(err) {
                  var responseError = err.data.error || err.statusText
                  ctrl.serverErrors = responseError.messages || [responseError]
                }
              )
          }
          else {
            ctrl.cancel()
          }
        }
      }
    }
  })
