angular.module('org-admin')
  .directive('accessDenied', function() {
    return {
      restrict: 'A',
      scope: {},
      templateUrl: '/static/org/error/access-denied.html',
      controller: function($scope, Organization, $routeParams, i18ng) {
        Organization.find($routeParams.currentOrgId).then($scope.setAs('org'))
      }
    }
  })
