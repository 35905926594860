angular.module('org-admin')
  .component('membershipDefinitionPublishCard', {
    templateUrl: '/static/org/memberships/membership-definition-publish-card.html',
    bindings: {
      membershipDefinition: '='
    },
    controller: function(_, moment, currentOrg, pageViewHandler, dialog, $scope, i18ng) {
      var TIMEZONE = currentOrg.timezone || 'America/New_York'
      var $ctrl = this

      $ctrl.loaded = false
      $ctrl.$onInit = $onInit
      $ctrl.formatPurchaseDateTime = formatPurchaseDateTime
      $ctrl.editPublishDateTime = editPublishDateTime
      $ctrl.readOnly = false
      $scope.$watch('$ctrl.membershipDefinition.purchase_open', publishTimeString)
      $scope.$watch('$ctrl.membershipDefinition.purchase_close', publishTimeString)

      function $onInit() {
        if ($ctrl.membershipDefinition.isExpired) $ctrl.readOnly = true
        $ctrl.loaded = true
        publishTimeString()
      }

      function publishTimeString() {
        if ($ctrl.membershipDefinition.isExpired) {
          return i18ng.t('MEMBERSHIP_DEFINITION_AVAILABILITY.expired')
        }

        var now = moment.tz(TIMEZONE)
        var returnString = null
        var purchaseClose = forOrgZone($ctrl.membershipDefinition.purchase_close)
        var purchaseOpen = forOrgZone($ctrl.membershipDefinition.purchase_open)

        if (purchaseOpen) {
          // If purchaseOpen has passed
          if (purchaseOpen <= now) {
            // if purchaseClose is in the future
            if (purchaseClose && now <= purchaseClose) {
              returnString = i18ng.t('MEMBERSHIP_DEFINITION_AVAILABILITY.SUMMARY.open_with_close', {
                purchaseOpen: formatPurchaseDateTime(purchaseOpen),
                purchaseClose: formatPurchaseDateTime(purchaseClose) })
            }
            // if both purchaseOpen and purchaseClose have passed
            else if (purchaseClose && purchaseClose <= now) {
              returnString = i18ng.t('MEMBERSHIP_DEFINITION_AVAILABILITY.SUMMARY.closed', {
                purchaseOpen: formatPurchaseDateTime(purchaseOpen),
                purchaseClose: formatPurchaseDateTime(purchaseClose) })
            }
            // if purchaseOpen has passed and there is no purchaseClose
            else {
              returnString = i18ng.t('MEMBERSHIP_DEFINITION_AVAILABILITY.SUMMARY.open', {
                purchaseOpen: formatPurchaseDateTime(purchaseOpen) })
            }
          }
          // if purchaseOpen is in the future
          else {
            // if purchaseClose is set and in the future
            if (purchaseClose) {
              returnString = i18ng.t('MEMBERSHIP_DEFINITION_AVAILABILITY.SUMMARY.will_open_with_close', {
                purchaseOpen: formatPurchaseDateTime(purchaseOpen),
                purchaseClose: formatPurchaseDateTime(purchaseClose) })
            }
            else {
              returnString = i18ng.t('MEMBERSHIP_DEFINITION_AVAILABILITY.SUMMARY.will_open', {
                purchaseOpen: formatPurchaseDateTime(purchaseOpen) })
            }
          }
        }
        $ctrl.publishString = returnString
      }

      function forOrgZone(datetimeString, default_val) {
        if (!datetimeString) return default_val || null
        return moment.tz(datetimeString, TIMEZONE)
      }

      function formatPurchaseDateTime(dateTime) {
        return forOrgZone(dateTime).format('MMM D, YYYY h:mma zz')
      }

      function editPublishDateTime(membershipDefinition) {
        pageViewHandler.fireEvent('Availability.EditPencil.Click', 8)
        dialog.confirm({
          directive: 'membership-definition-publish-edit',
          scope: $scope,
          attrs: {
            membershipDefinition: membershipDefinition
          }
        })
      }
    }
  })
