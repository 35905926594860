angular.module('org-admin')
  .component('contactsWebsitePermissions', {
    bindings: {
      persona: '<',
    },
    template: '<div style="flex: 0 1 auto; padding: 24px 24px 0 24px;"><se-back-button back-or-goto-context="app.admins.detail"></se-back-button></div><div ng-if="$ctrl.loaded" class="pl-iframe__wrapper"><iframe src="{{ $ctrl.iframeSrc }}" class="pl-iframe"></iframe></div>',
    controllerAs: '$ctrl',
    controller: function($sce, currentOrg, renderContext) {
      var $ctrl = this
      $ctrl.loaded = false

      $ctrl.$onInit = function() {
        $ctrl.iframeSrc = $sce.trustAsResourceUrl(currentOrg.siteUrl + '/personas/website_permissions/' + $ctrl.persona.id)
        $ctrl.loaded = true
      }
    }
  })
