angular.module('org-admin')
  .component('filterPanelMonetaryInput', {
    bindings: {
      id: '@ctrlId',
      filterRule: '<',
      readOnly: '<'
    },
    templateUrl: '/static/org/filtering/inputs/filter-panel-monetary-input.html',
    controller: function filterPanelMonetaryInputController(_, $scope) {
      var $ctrl = this

      $ctrl.model = {
        value: null
      }

      $scope.$watch(function() { return !!$ctrl.filterRule && $ctrl.filterRule.value() }, updateModel)
      $scope.$watch('$ctrl.model.value', updateFilterRule)

      function updateModel(value) {
        var parsedValue = parseInt(value, 10)
        if (isNumber(parsedValue)) {
          $ctrl.model.value = parsedValue / 100
        }
      }

      function updateFilterRule(value) {
        if (isNumber(value)) {
          var centValue = value * 100
          $ctrl.filterRule.value(centValue)
        }
      }

      function isNumber(num) {
        return _.isNumber(num) && !isNaN(num)
      }
    }
  })
