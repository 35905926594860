'use strict'

// Validation directives for number comparison

function numberCompareDirective(name, compare) {
  angular.module('pl-shared')
    .directive(name, function() {
      return {
        require: 'ngModel',
        link: function(scope, elm, attrs, controller) {
          controller.$validators[name] = function(modelValue, viewValue) {

            var parsedViewValue = viewValue
            // Parse the value if it's a string, remove invalid characters
            if (viewValue && typeof viewValue === 'string') {
              parsedViewValue = viewValue.replace(/^\$|,/g, '')
            }

            // Skip this validator if either value is not a number
            if (isNaN(attrs[name])) return true
            if (isNaN(parsedViewValue)) return true

            return compare(+parsedViewValue, +attrs[name]) // Parse values as numbers
          }
        }
      }
    })
}

numberCompareDirective('numberMin', function(a, b) { return a >= b })
numberCompareDirective('numberMax', function(a, b) { return a <= b })
numberCompareDirective('numberGreaterThan', function(a, b) { return a > b })
numberCompareDirective('numberLessThan', function(a, b) { return a < b })
numberCompareDirective('numberEqualTo', function(a, b) { return a == b })
