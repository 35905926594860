angular.module('org-admin')
  .component('discountDuration', {
    require: {
      discountForm: '^form'
    },
    bindings: {
      discountCodeDefinition: '=',
      editMode: '='
    },
    templateUrl: '/static/org/discounts/discount-modal/discount-duration.component.html',
    controllerAs: 'ctrl',
    controller: function() {
      var ctrl = this
      ctrl.discountCodeDefinition.deactivated = ctrl.discountCodeDefinition.status === 'deactivated'

      formatTimestamps()
      setEarliestEndDate()
      initializeInputs()

      ctrl.isInvalid = function() {
        var beginsAfterTheEnd = moment(ctrl.discountCodeDefinition.starts_at).isAfter(moment(ctrl.discountCodeDefinition.expires_at))
        return (!ctrl.discountCodeDefinition.deactivated && ctrl.discountCodeDefinition.starts_at === null) || beginsAfterTheEnd
      }

      ctrl.isExpired = function() {
        return moment(ctrl.discountCodeDefinition.expires_at).isBefore(moment().format('YYYY-MM-DD')) && !ctrl.isInvalid()
      }

      ctrl.isScheduled = function() {
        return moment(ctrl.discountCodeDefinition.starts_at).isAfter(moment().format('YYYY-MM-DD')) && !ctrl.isInvalid()
      }

      ctrl.isActive = function() {
        formatTimestamps()
        var startsAtMoment = moment(ctrl.discountCodeDefinition.starts_at)
        var expiresAtMoment = moment(ctrl.discountCodeDefinition.expires_at)
        var isValidRange = !!(startsAtMoment.isBefore(expiresAtMoment) || startsAtMoment.isSame(expiresAtMoment) || (ctrl.discountCodeDefinition.starts_at && !ctrl.discountCodeDefinition.expires_at))
        var isScheduled = startsAtMoment.isAfter(moment().format('YYYY-MM-DD'))
        var isExpired = expiresAtMoment.isBefore(moment().format('YYYY-MM-DD'))
        return !ctrl.isInvalid() && isValidRange && !isScheduled && !isExpired
      }

      ctrl.toggleActivation = function() {
        if (ctrl.isActive()) {
          deactivateDiscount()
        }
        else {
          activateDiscount()
        }
        ctrl.discountForm.starts_at.$setPristine(true)
        ctrl.discountForm.expires_at.$setPristine(true)
      }

      ctrl.startDateChanged = function() {
        formatTimestamps()
        setEarliestEndDate()
      }

      ctrl.toggleNoEndDate = function() {
        ctrl.discountCodeDefinition.no_end_date = !ctrl.discountCodeDefinition.no_end_date
        if (ctrl.discountCodeDefinition.expires_at) {
          ctrl.discountCodeDefinition.expires_at = null
        }
      }

      function formatTimestamps() {
        if (ctrl.discountCodeDefinition.starts_at) {
          ctrl.discountCodeDefinition.starts_at = moment(ctrl.discountCodeDefinition.starts_at).format('YYYY-MM-DD')
        }
        if (ctrl.discountCodeDefinition.expires_at) {
          ctrl.discountCodeDefinition.expires_at = moment(ctrl.discountCodeDefinition.expires_at).format('YYYY-MM-DD')
        }
      }

      function setEarliestEndDate() {
        if (ctrl.discountCodeDefinition.deactivated) {
          ctrl.earliestEndDate = null
        }
        else {
          ctrl.earliestEndDate = moment(ctrl.discountCodeDefinition.starts_at).format('YYYY-MM-DD')
        }
      }

      function initializeInputs() {
        if (ctrl.discountCodeDefinition.deactivated) {
          ctrl.fieldsDisabled = true
        }
        else {
          ctrl.fieldsDisabled = false
          if (ctrl.editMode && !ctrl.discountCodeDefinition.expires_at) {
            ctrl.discountCodeDefinition.no_end_date = true
          }
          else {
            ctrl.discountCodeDefinition.no_end_date = false
          }
        }
      }

      function activateDiscount() {
        ctrl.discountCodeDefinition.deactivated = false
        ctrl.discountCodeDefinition.starts_at = moment().format('YYYY-MM-DD')
        ctrl.discountCodeDefinition.expires_at = null
        ctrl.discountCodeDefinition.no_end_date = false
        ctrl.fieldsDisabled = false
        setEarliestEndDate()
      }

      function deactivateDiscount() {
        ctrl.discountCodeDefinition.deactivated = true
        ctrl.discountCodeDefinition.starts_at = null
        ctrl.discountCodeDefinition.expires_at = null
        ctrl.discountCodeDefinition.no_end_date = false
        ctrl.fieldsDisabled = true
      }

    }
  })
