angular.module('pl-shared')
  .factory('WistiaVideo', function($q, $rootScope, $window, $timeout, $compile) {

    var win = $window
    var doc = win.document
    var body = doc.body
    var elementCounter = 0
    var api, apiLoader, apiScriptTag
    var queue = win._wq = win._wq || []

    var PLAY_DELAY = 500

    // Class Definition

    function WistiaVideo(videoId, scope) {
      this.videoId = videoId
      this.id = 'WistiaEmbed_' + (++elementCounter)
      this.el = asyncElement(this.id, videoId)
      this.scope = scope || $rootScope
      this.open = this.open.bind(this)
      this.close = this.close.bind(this)
      this.play = this.play.bind(this)

      this.el
        .querySelector('se-icon-button')
        .addEventListener('click', this.close)

      doc.addEventListener('keydown', this.close)

      this.scope.$on('$destroy', this.destroy)

      getApi()
    }

    var WV = WistiaVideo.prototype

    WV.open = function() {
      if (this.destroyed) return // Exit early if scope was already destroyed
      this.el.classList.add('is-visible')
      if (!api) return getApi().then(this.open)
      if (!this.video) return this.load(this.open)
      $timeout(this.play, PLAY_DELAY, false)
    }

    WV.play = function() {
      api(this.id).play()
    }

    WV.load = function(callback) {
      if (this.video) return callback(this.video)
      queue.push({ id: this.videoId, onReady: setVideo(this, callback) })
    }

    WV.close = function(e) {
      if (e.type === 'keydown' && e.key !== 'Escape') return
      this.scope.$emit('videoClosed', this.videoId)
      this.el.classList.remove('is-visible')
      var video = api(this.id)
      if (!video) return
      video.pause().time(0)
    }

    WV.destroy = function() {
      this.destroyed = true
      doc.removeEventListener('keydown', this.close)
      if (this.video) api(this.id).remove()
      if (this.el.parentNode) this.el.parentNode.removeChild(this.el)
    }

    // Utility meathods

    function setVideo(instance, callback) {
      return function(video) {
        instance.video = video
        callback(video)
      }
    }

    function asyncElement(id, videoId) {
      var overlay = elWithClasses(body, ['pl-video-overlay__overlay'])
      var videoFrame = elWithClasses(overlay, ['pl-video-overlay__video'])
      var videoEmbed = elWithClasses(videoFrame, ['pl-video-overlay__video-embed', 'wistia_embed', 'wistia_async_' + videoId])
      videoEmbed.id = id
      addCloseButton(overlay, ['pl-video-overlay__close'])
      return overlay
    }

    function addCloseButton(parent, classes) {
      var el = document.createElement('div')
      el.classList.add.apply(el.classList, classes)
      var angularEl = angular.element(`<se-icon-button se-data-aria-label="'Close video player'" se-data-icon="remove"></se-icon-button>`)
      var compiledEl = $compile(angularEl)($rootScope.$new())
      el.appendChild(compiledEl[0])
      parent.appendChild(el)
      return el
    }

    function elWithClasses(parent, classes) {
      var el = document.createElement('div')
      el.classList.add.apply(el.classList, classes)
      parent.appendChild(el)
      return el
    }

    function getApi() {
      if (!apiLoader) {
        apiLoader = $q.defer()
        var firstScriptTag = doc.getElementsByTagName('script')[0]
        var apiScriptTag = doc.createElement('script')
        apiScriptTag.charset = 'ISO-8859-1'
        apiScriptTag.src = '//fast.wistia.com/assets/external/E-v1.js'
        apiScriptTag.async = true
        win.wistiaInit = initApi
        firstScriptTag.parentNode.insertBefore(apiScriptTag, firstScriptTag)
      }
      return apiLoader.promise
    }

    function initApi(wistiaApi) {
      apiLoader.resolve(api = wistiaApi.api)
    }

    return WistiaVideo

  })
