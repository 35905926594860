angular.module('pl-shared')

  .service('choiceQuestionView', function() {

    return {
      scope: {},
      templateUrl: '/static/shared/components/registration/questions/choice-question-view.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        answer: '='
      },
      controller: ['_', '$scope', function(_, $scope) {
        var ctrl = this
        var answers = [].concat(ctrl.answer && ctrl.answer.value || [])
        ctrl.availableChoices = ctrl.question.availableChoiceElements({ include: answers })
        ctrl.checked = function(val) {
          return _.contains(answers, val)
        }
      }]
    }

  })
