angular.module('org-admin')
  .component('competitionProgramsList', {
    templateUrl: '/static/org/competition-programs/competition-programs-list.html',
    controller: function($routeParams, appPermission, currentOrg, dialog, Program, renderContext, setAs, setWhile, showError) {
      if (!appPermission.competitionPrograms.visible) return showError('404')

      var ctrl = this
      var PROGRAM_TYPE = 'League'
      ctrl.$routeParams = $routeParams

      ctrl.$onInit = function() {
        setWhile(ctrl, 'competitionProgramsLoading', loadCompetitionPrograms)()
      }

      ctrl.details = function(competitionProgram) {
        dialog.confirm({
          component: 'competition-program-details',
          attrs: { competitionProgram: competitionProgram }
        })
          .then(ctrl.$onInit)
      }

      function loadCompetitionPrograms() {
        return Program.findAll({
          org_id: currentOrg.id,
          type: PROGRAM_TYPE
        })
          .then(setAs(ctrl, 'competitionPrograms'))
      }

    }
  })
