angular.module('pl-shared')
  .factory('quillHelper', function(Quill) {

    var $ = angular.element
    var quillLinkConfig = Quill.import('formats/link')

    return {
      sanitize: sanitize,
      positionHandler: positionHandler,
      linkHandler: linkHandler
    }

    function sanitize(url) {
      var externalLinkPrefixes = ['http://', 'https://', 'mailto:', 'tel:']
      var isExternalLink = _.any(externalLinkPrefixes, function(prefix) {
        return url.indexOf(prefix) === 0
      })

      return (isExternalLink ? '' : 'http://') + url
    }

    function positionHandler() {
      var TOOLTIP_POSITION = {
        right: '0px',
        top: '-50px'
      }
      this.root.classList.add('ql-snow-tooltip')
      this.root.style.right = TOOLTIP_POSITION.right
      this.root.style.top = TOOLTIP_POSITION.top
      this.root.setAttribute('id', 'quill-update-url-ui')
      $('<style> #quill-update-url-ui { margin-top: 0px !important } </style>').insertAfter($('#quill-update-url-ui'))
    }

    function linkHandler(format, handler) {
      if (format === 'link') {
        var oldLinkHandler = handler
        handler = function() {
          var boundShowTooltip = _.bind(_.debounce(showTooltip), this.quill)
          this.quill.on('editor-change', boundShowTooltip)
          this.quill.on('selection-change', _.debounce(checkAndRemoveSelection))
          var selection = window.getSelection()
          var range = selection.getRangeAt(0)

          if (selection.type === 'Range' && range.endContainer.parentNode.hasAttribute('href')) {
            oldLinkHandler.apply(this, arguments)
            return
          }

          if (selection.type === 'Caret' && !isSymbolOnLeftSideOfCursor(range)) {
            showEmptyTextTooltip()                                                    //user need to select a text to create a link
            return
          }

          if (selection.type === 'Caret') {                                   //we need to select a word if caret placed in the end of word or it's middle
            if (range.endContainer.parentNode.hasAttribute('href')) return    //and we shouldn't do anything if caret placed inside a link
            var targetWord = findTargetWord(range)
            if (targetWord) {
              setSelection(selection, range, targetWord.textNode, targetWord.startPosition, targetWord.endPosition)
            }
          }
          wrapSelection(selection)                                            //wrapping selection in a span with background as if it selected
          oldLinkHandler.apply(this, arguments)
          replacePlaceholder(this.quill.theme.tooltip)                        //replacing default placeholder
        }
      }
      this.handlers[format] = handler


      function showTooltip() {                       //show tooltip if the cursor left, right or in the middle of a word
        var tooltip = this.theme.tooltip
        var eventType = arguments[0]
        if (eventType === 'selection-change') {
          var range = arguments[1]
          if ((range && !range.length) && this.getFormat(range).link) {
            var link = tooltip.quill.scroll.descendant(quillLinkConfig, range.index - 1)[0]
            var offset = tooltip.quill.scroll.descendant(quillLinkConfig, range.index - 1)[1] + 1
            tooltip.linkRange = { index: range.index - offset, length: link.length() }
            var preview = quillLinkConfig.formats(link.domNode)
            tooltip.preview.textContent = preview
            tooltip.preview.setAttribute('href', preview)
            tooltip.show()
          }
        }
        else {
          tooltip.hide()
        }
      }

      function replacePlaceholder(tooltip) {
        tooltip.edit('link', '')
        tooltip.textbox.placeholder = 'Enter URL Here'
      }

      function isSymbolOnLeftSideOfCursor(range) {
        return range.startOffset ? !!range.startContainer.textContent[range.startOffset - 1].match(/\w+/) : false
      }

      function checkAndRemoveSelection(range, oldRange) {
        if ($('span[ql-highlighted-text]').length && !oldRange) {
          $('span[ql-highlighted-text]').replaceWith($('span[ql-highlighted-text]').text())
        }
        if ($('.ql-editor').find('a[style]').length && !oldRange) {
          $('.ql-editor').find('a[style]').css({ backgroundColor: '' })
        }
      }

      function wrapSelection(selection) {
        document.execCommand('backColor', false, '#b1d7fe')
        $(selection.anchorNode.parentNode).attr('ql-highlighted-text', '')
      }


      function setSelection(selection, range, node, startPosition, endPosition) {
        range.setStart(node, startPosition)
        range.setEnd(node, endPosition)
        selection.removeAllRanges()
        selection.addRange(range)
      }

      function showEmptyTextTooltip() {
        $('#hyperlink-tooltip').remove()
        var tooltipTmpl = '<div class="pl-tooltip" id="hyperlink-tooltip"><div class="pl-tooltip__pointer"></div><p class="pl-tooltip-content">Select text to create a hyperlink</p></div>'
        var linkButton = $('.ql-link')
        var linkButtonPosition = linkButton.position()
        linkButton.after(tooltipTmpl)
        var tooltip = $('#hyperlink-tooltip')
        tooltip.css({ display: 'inline-block', left: linkButtonPosition.left - 97, top: linkButtonPosition.top + 20 })
        setTimeout(function() { tooltip.remove() }, 2000)
      }

      function findTargetWord(range) {
        if (!range.collapsed) return
        var firstIndex, lastIndex
        var textContent = range.startContainer.textContent

        var leftCharacter = textContent[range.startOffset - 1] || ''
        var rightCharacter = textContent[range.startOffset] || ''

        var isCaretInTheEndOfWord = caretInTheEndOfWord()
        var isCaretInsideWord = caretInsideWord()

        if (!isCaretInTheEndOfWord && !isCaretInsideWord) return

        if (isCaretInTheEndOfWord) {
          var clippedText = textContent.substring(0, range.startOffset + 1)
          var lastWord = _.words(clippedText).pop()
          firstIndex = range.startOffset - lastWord.length
          lastIndex = range.startOffset
        }
        else if (isCaretInsideWord) {
          firstIndex = findFirstCharacterIndex()
          lastIndex = findLastCharacterIndex()
        }

        return {
          textNode: range.startContainer,
          startPosition: firstIndex,
          endPosition: lastIndex
        }

        function caretInTheEndOfWord() {
          return leftCharacter.match(/\w+/) && !rightCharacter.match(/\w+/)
        }

        function caretInsideWord() {
          return leftCharacter.match(/\w+/) && rightCharacter.match(/\w+/)
        }

        function findFirstCharacterIndex() {
          for (var i = range.startOffset; i >= 0; i--) {
            if (textContent[i] === ' ' || textContent[i] === undefined) {
              return i + 1
            }
          }
        }

        function findLastCharacterIndex() {
          for (var i = range.startOffset; i <= textContent.length; i++) {
            if (textContent[i] === ' ' || textContent[i] === undefined) {
              return i
            }
          }
        }
      }
    }

  })
