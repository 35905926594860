angular.module('org-admin')
  .component('leagueLocationSidePanel', {
    bindings: {
      venue: '<',
      closePanel: '&?',
      loadVenues: '&?'
    },
    templateUrl: '/static/org/league-locations/league-location-side-panel.html',
    controller: function(_, $scope, Alerts, currentOrg, dialog, ENV, i18ng, Persona, pageViewHandler, setAs, setWhile, Venue, Subvenue) {

      var ctrl = this

      function updateVenue(event) {
        $scope.$apply(() => {
          Venue.inject(event.detail)
          Object.assign(ctrl.venue, event.detail)
        })
      }

      ctrl.$onInit = function() {
        pageViewHandler.fireEvent('Location.FlyoutDisplayed', 8)
        ctrl.Persona = Persona
      }
      ctrl.$onChanges = function() {
        ctrl.loadSubvenues()
      }

      ctrl.anyAvailableTimes = function() {
        return _.any(ctrl.venue.available_times, { closed: false })
      }

      ctrl.availableTimes = function() {
        dialog.confirm({
          component: 'league-location-available-times',
          attrs: { venue: ctrl.venue }
        })
          .then(ctrl.$onChanges)
      }

      ctrl.formatTime = function(time) {
        return moment(time, 'hh:mm').format('h:mm a')
      }

      ctrl.loadSubvenues = setWhile(ctrl, 'subvenuesLoading', function() {
        return Subvenue.findAll({ venue_id: ctrl.venue.id, order_by: 'name', per_page: 100 }, { urlPath: '/subvenues' })
          .then(setAs(ctrl, 'subvenues'))
          .then(buildsubvenueActionLinks)
      })

      ctrl.locationActionLinks = [
        {
          text: i18ng.t('Edit'),
          primaryAction: true,
          action: function() {
            const detail = { orgId: currentOrg.id, venue: ctrl.venue }
            const el = document.getElementById('venue-add-edit-modal')
            el.dispatchEvent(new CustomEvent('open', { detail }))
            el.removeEventListener('venueSave', updateVenue)
            el.addEventListener('venueSave', updateVenue)
          },
        },
        {
          text: i18ng.t('Delete'),
          action: function() {
            dialog.confirm({
              component: 'confirm-dialog',
              attrs: {
                heading: i18ng.t('LEAGUE_LOCATIONS.DETAILS.LABELS.title', { action: 'Delete' }),
                message: i18ng.t('LEAGUE_LOCATIONS.confirm_delete', { name: ctrl.venue.name }),
                confirmAction: function() { ctrl.closePanel(null) },
                modalSize: 'small',
                deleteButton: true
              }
            })
              .then(deleteVenue)
          }
        }
      ]

      ctrl.availableTimesActionLinks = [
        {
          text: i18ng.t('Edit'),
          action: function() {
            dialog.confirm({
              component: 'league-location-available-times',
              attrs: { venue: ctrl.venue }
            })
              .then(ctrl.$onChanges)
          },
          primaryAction: true
        }
      ]

      ctrl.subvenuesActionLinks = [
        {
          text: i18ng.t('Add'),
          action: function() {
            dialog.confirm({
              component: 'league-location-details',
              attrs: {
                venue: ctrl.venue,
                addSubvenueOnly: true
              }
            })
              .then(ctrl.$onChanges)
          },
          primaryAction: true
        }
      ]

      function buildsubvenueActionLinks() {
        _.forEach(ctrl.subvenues, function(subvenue) {
          subvenue.actionLinks = [
            {
              text: i18ng.t('Edit'),
              action: function() {
                dialog.confirm({
                  component: 'league-facility-details',
                  attrs: { subvenue: subvenue }
                })
                  .then(ctrl.$onChanges)
              },
              primaryAction: true
            },
            {
              text: i18ng.t('Delete'),
              action: function() {
                if (ctrl.subvenues.length > 1) {
                  subvenue.DSDestroy({ params: { venue_id: false } })
                    .then(function() { pageViewHandler.fireEvent('Location.DeleteFacilityModal.Delete', 8) })
                    .then(ctrl.loadSubvenues)
                }
                else {
                  Alerts.error('LEAGUE_LOCATIONS.DETAILS.ERRORS.facility_delete')
                }
              }
            }
          ]
        })
      }

      function deleteVenue() {
        return ctrl.venue.DSDestroy()
          .then(function(v) { ctrl.loadVenues() })
      }

    }
  })
