angular.module('pl-shared')
  .service('MembershipStatistics', function($http, apiV2, ENV) {

    var apiProxyUrl = ENV.urls.apiProxy

    return {
      membershipCount: membershipCount,
      affiliatedMembershipsByMemDef: affiliatedMembershipsByMemDef
    }

    /**
     * @parameter [integer] boss_organization_id(required)
     * @parameter [date] purchase_date_start
     * @parameter [date] purchase_date_end
     * @parameter [uuid] membership_definition_id
     */
    function membershipCount(orgId, startDate, endDate, memdefId) {
      var url = apiProxyUrl + '/membership_statistics/membership_count'
      var params = {
        boss_organization_id: orgId
      }
      if (startDate) params.purchase_date_start = startDate
      if (endDate) params.purchase_date_end = endDate
      if (memdefId) params.membership_definition_id = memdefId
      var opts = {
        headers: apiV2.headers,
        params: params,
        withCredentials: true
      }
      return $http.get(url, opts).then(apiV2.deserialize)
    }

    /**
     * @parameter [integer] boss_organization_id(required)
     */
    function affiliatedMembershipsByMemDef(orgId) {
      var url = apiProxyUrl + '/membership_statistics/affiliated_memberships_by_mem_def'
      var params = {
        boss_organization_id: orgId
      }
      var opts = {
        headers: apiV2.headers,
        params: params,
        withCredentials: true
      }
      return $http.get(url, opts).then(apiV2.deserialize)
    }
  })
