angular.module('org-admin')

  .factory('Subseasons', function(DS) {
    var Subseasons = DS.defineResource({
      name: 'subseasons',
      basePath: DS.adapters.ngin_v1.defaults.basePath,
      httpConfig: DS.adapters.ngin_v1.defaults.httpConfig,
      defaultAdapter: 'ngin_v1',
    })

    return Subseasons
  })
