angular.module('pl-shared')

  .service('EligibilitySearch', function(DS, apiV2, paginate) {

    var EligibilitySearch = DS.defineResource({
      name: 'eligibility_search',
      endpoint: 'v3/eligibility',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
      actions: {
        aggregate: {
          method: 'POST',
          response: apiV2.deserialize
        },
        membership_invites: {
          method: 'POST',
        },
        search: {
          method: 'GET',
          response: apiV2.deserialize
        }
      }
    })

    paginate(EligibilitySearch)

    return EligibilitySearch
  })
