angular.module('org-admin')
  .component('percentage', {
    bindings: {
      percentages: '=',
      getPercentageSum: '&',
      index: '<'
    },
    templateUrl: '/static/org/payment-terms/percentage.html',
    controller: function() {

      this.selectOnClick = function(event) {
        event.target.select()
      }

    }
  })
