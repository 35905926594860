angular.module('org-admin')

  .config(function($locationProvider, seBarProvider) {
    $locationProvider.html5Mode({ enabled: true, requireBase: false })
    seBarProvider.setApp('hq')
    seBarProvider.setTheme('dark')
  })

  .constant('defaultPageDepths', { depth1: 'HQ', depth2: 'Generic' })
  .constant('Quill', Quill)

  .run(function(ENV, Search, Alerts, Quill, quillHelper, $rootScope, payload, $window, $log) {
    // Expose env on all scopes
    $rootScope.constructor.prototype.ENV = ENV

    // Configure Quill JS Links to not assume relative path
    var quillLinkConfig = Quill.import('formats/link')
    quillLinkConfig.sanitize = quillHelper.sanitize

    // Overrides Quill tooltip position
    var quillTooltip = Quill.import('ui/tooltip')
    quillTooltip.prototype.position = quillHelper.positionHandler

    // Extend default handler when clicking on a link button
    var quillToolbar = Quill.import('modules/toolbar')
    quillToolbar.prototype.addHandler = quillHelper.linkHandler

    Search.config({
      error: function() {
        Alerts.warning('SEARCH.error')
      }
    })

    // Give console warnings about missing data from payload for development
    logPayloadWarnings()

    function logPayloadWarnings() {
      if (_.isEmpty(payload.featureToggles)) {
        $log.warn('\'featureToggles\' missing from payload')
      }
      if (_.isEmpty(payload.provisioning.sites)) {
        $log.warn('\'sites\' missing from payload')
      }
      if (_.isEmpty(payload.provisioning.powerPay)) {
        $log.warn('\'powerPay\' missing from payload')
      }
      else if (payload.provisioning.powerPay.visible === false) {
        $log.warn('\'powerPay.visible\' equals \'false\' in payload')
      }

    }

  })
