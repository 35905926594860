angular.module('org-admin')

  .service('BankAccount', function($q, $http, apiV2, DS, paginate, ENV) {

    var cache = {}
    var bankAccount = DS.defineResource({
      name: 'accounts',
      endpoint: 'bank_accounts',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
    })
    paginate(bankAccount)

    return {
      createBankAccount: createBankAccount,
      getCurrentAccount: getCurrentAccount,
      moveNginAccountToClare: moveNginAccountToClare,
      selectBankAccount: selectBankAccount,
      findAll: findAll,
      findAllGBRApproved: findAllGBRApproved,
      findAllUSApproved: findAllUSApproved,
      getAccount: getAccount,
      editAccount: editAccount,
      findAllWithPagination: findAllWithPagination,
      displayAccountNumber: displayAccountNumber,
      approvedAccounts: approvedAccounts
    }

    // PRIVATE METHODS

    function getPromise(url, opts, clearCache) {
      if (clearCache) cache = {}
      opts.withCredentials = true
      if (!opts.headers) opts.headers = { Accept: 'application/json' }
      return cache[url] = cache[url] || $http.get(ENV.urls.apiProxy + url, opts)
    }

    function getCurrentAccount(orgId, clearCache) {
      var url = '/bank_accounts?organization_id=' + orgId + '&selected=true'
      var promise = getPromise(url, {}, clearCache)
      return promise.then(function(resp) {
        return resp.data.result[0] // endpoint returns an array with one item
      })
    }

    function findAll(orgId, clearCache) {
      var url = '/bank_accounts?organization_id=' + orgId
      var promise = getPromise(url, {}, clearCache)
      return promise.then(function(resp) {
        return resp.data.result
      })
    }

    function getAccount(accountId, clearCache) {
      var url = '/bank_accounts/' + accountId
      var promise = getPromise(url, {}, clearCache)
      return promise.then(function(resp) {
        return resp.data.result
      })
    }

    function findAllUSApproved(orgId, clearCache) {
      var url = '/bank_accounts?organization_id=' + orgId + '&status=approved&account_country=us'
      var promise = getPromise(url, {}, clearCache)
      return promise.then(function(resp) {
        return resp.data.result
      })
    }

    function findAllGBRApproved(orgId, clearCache) {
      var url = '/bank_accounts?organization_id=' + orgId + '&status=approved&account_country=gb'
      var promise = getPromise(url, {}, clearCache)
      return promise.then(function(resp) {
        return resp.data.result

      })
    }

    function moveNginAccountToClare(accountId) {
      var url = '/provisioning/bank_accounts/' + accountId
      var opts = { headers: apiV2.headers }
      return $http.post(ENV.urls.apiProxy + url, {}, opts).then(apiV2.deserialize)
    }

    function createBankAccount(data) {
      return $http({
        method: 'POST',
        url: ENV.urls.apiProxy + '/bank_accounts',
        data: data,
        withCredentials: true,
        headers: { Accept: 'application/json' },
      })
    }

    function editAccount(accountId, data) {
      return $http({
        method: 'PATCH',
        url: ENV.urls.apiProxy + '/bank_accounts/' + accountId,
        data: data,
        withCredentials: true,
        headers: { Accept: 'application/json' },
      })
    }

    function selectBankAccount(orgId, accountId) {
      var url = '/settlement_accounts/' + accountId + '/set_payout_account'
      return $http.post(ENV.urls.apiProxy + url, { organization_id: orgId }, {
        withCredentials: true,
        headers: { Accept: 'application/json' }
      }).then(apiV2.deserialize)
    }

    function findAllWithPagination(orgId, params) {
      return bankAccount.findAll(_.extend({ organization_id: orgId }, params))
    }

    function displayAccountNumber(account) {
      return (account.iban) ? account.iban : account.masked_account_number
    }

    function approvedAccounts(accounts) {
      var approvedStatuses = ['approved', 'status_new']
      return accounts.filter(function(account) { return approvedStatuses.includes(account.status) })
    }

  })
