angular.module('org-admin')
  .component('contactsRole', {
    bindings: {
      persona: '<',
      resourceType: '<',
      roleAssignment: '<',
      personaRoleAssignments: '<',
      cancel: '<',
      confirm: '<'
    },
    templateUrl: '/static/org/contacts/contacts-role.html',
    controller: function(_, appPermission, currentOrg, CompositeRoles, RoleLabel, RoleAssignments, setWhile, showError) {
      var ctrl = this
      if (!appPermission.contactRoles.visible) return handleError()
      // This will currently only allow creation of organization composite roles and edit of team instance roles
      if (!ctrl.roleAssignment && ctrl.resourceType != 'Organization') return handleError()

      ctrl.roleLabels = {}
      ctrl.roleAssignment = ctrl.roleAssignment || {
        persona_id: ctrl.persona.id,
        principal_type: 'Persona',
        principal_id: ctrl.persona.id,
        resource_type: 'Organization',
        resource_id: String(currentOrg.id), // this field is stored as a string in US
        org_id: currentOrg.id,
        role_type: 'CompositeRole',
        role_id: null
      }

      ctrl.$onInit = setWhile(ctrl, 'loadingRoles', function() {
        return CompositeRoles.findAll({
          boss_organization_id: currentOrg.id,
          applies_to: ctrl.resourceType,
          enabled: 1,
          override_enabled: 1
        })
          .then(function(compositeRoles) {
            var hiddenRoles = [
              'tournamentDirector',
              'governingSeasonAdminMenuReader',
              'governingSeasonViewMenuReader'
            ]
            if (!currentOrg.rootOrgForAdminManagement()) hiddenRoles.push('governingSeasonAdmin', 'governingSeasonView')
            // if user already has the role, hide the role
            //  also hide it if the user has the "MenuReader" version of the role
            _.remove(compositeRoles, function(compositeRole) {
              return hiddenRoles.includes(compositeRole.key) ||
                _.find(ctrl.personaRoleAssignments || [], function(ra) { return ra.role_key === compositeRole.key || compositeRole.key + 'MenuReader' === ra.role_key })
            })

            if (!ctrl.roleAssignment.role_id && compositeRoles.length == 1) {
              ctrl.roleAssignment.role_id = compositeRoles[0].id
            }
            ctrl.compositeRoles = compositeRoles
            _.forEach(compositeRoles, function(role) {
              ctrl.roleLabels[role.id] = RoleLabel.transformCompositeRole(role)
            })
          })
      })

      ctrl.submit = function() {
        if (ctrl.roleAssignment.id) {
          ctrl.roleAssignment.persona_id = ctrl.persona.id
          return ctrl.roleAssignment.DSUpdate({
            role_id: ctrl.roleAssignment.role_id
          })
            .then(ctrl.confirm)
        }
        else {
          return RoleAssignments.create(ctrl.roleAssignment)
            .then(ctrl.confirm)
        }
      }

      function handleError() {
        ctrl.cancel()
        return showError('404')
      }
    }
  })
