angular.module('pl-shared')
  .component('datepickerWatcher', {
    bindings: {
      params: '=',
      reload: '='
    },
    templateUrl: '/static/shared/components/datepicker/datepicker-watcher.html',
    controllerAs: 'ctrl',
    controller: function($element, $scope, snDatepickerService) {
      var ctrl = this
      var keyPressed = false

      ctrl.dates = {
        start: ctrl.params.start_date,
        end: ctrl.params.end_date,
        prevStartDate: ctrl.params.start_date,
        prevEndDate: ctrl.params.end_date
      }
      ctrl.validation = {
        errors: function() {
          var dateLatestError = false

          if (!angular.isUndefined(ctrl.dates.start) && !angular.isUndefined(ctrl.dates.end)) {
            var startDate = new Date(ctrl.dates.start)
            var endDate = new Date(ctrl.dates.end)
            dateLatestError = (startDate > endDate)
          }

          return {
            dateLatest: dateLatestError
          }
        },
        hasErrors: function() {
          return ctrl.validation.errors().dateLatest
        },
        errorClass: function() {
          return { 'error': ctrl.validation.hasErrors() }
        }
      }

      function datesExist() {
        return ctrl.dates.start && ctrl.dates.end
      }

      function saveValidDates() {
        if (datesExist() && !ctrl.reload) {
          ctrl.params.start_date = ctrl.dates.start
          ctrl.params.end_date = ctrl.dates.end
          if (!keyPressed) {

            if (angular.element('#start-date-field').hasClass('pl-has-focus')) {
              ctrl.reload = true
            }
            if (angular.element('#end-date-field').hasClass('pl-has-focus')) {
              ctrl.reload = true
            }
          }
          keyPressed = false
        }
      }

      ctrl.keydown = function(e) {
        keyPressed = true
        if (e.which === 13 && datesExist()) {
          ctrl.reload = true
        }
      }

      $scope.$watch('ctrl.dates', saveValidDates, true)
    }
  })
