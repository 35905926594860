(function() {
  function buttonDirective() {

    return {
      scope: {},
      templateUrl: '/static/shared/components/registration/questions/button-question.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '='
      },
      controller: function(_, $scope, i18ng) {
        var ctrl = this

        var defaultTranslations = i18ng.t('BUTTON_QUESTION', { returnObjectTrees: true })
        var defaultTranslationKeys = _.transform(defaultTranslations, fullTranslationKey, {})

        function fullTranslationKey(result, value, key) {
          result[key] = 'BUTTON_QUESTION.' + key
        }

        ctrl.loading = false
        ctrl.properties = ctrl.question.properties
        ctrl.model = _.isObject(ctrl.answer) ? ctrl.answer.value : null
        ctrl.inputName = 'form_result_' + ctrl.question.id
        ctrl.translations = _.defaults({}, ctrl.properties.translations, defaultTranslationKeys)

        ctrl.click = function(evt) {
          ctrl.loading = true
          // setTimeout allows the button to go into the disabled loading state
          // while the click function is processing.
          setTimeout(function(e) {
            ctrl.properties.click(e)
            ctrl.loading = false
          }, 0, evt)
        }
      }
    }

  }

  // Work the same way regardless of isEditable in both edit and view mode
  angular.module('pl-shared')
    .directive('buttonQuestion', buttonDirective)
    .directive('buttonQuestionView', buttonDirective)
})()
