angular.module('org-admin')
  .component('rosterPersonasFilterbox', {
    bindings: {
      originalFilters: '<filters',
      showMemberships: '<',
      cancel: '<',
      confirm: '<'
    },
    templateUrl: '/static/org/roster-management/roster-personas-filterbox.html',
    controller: function($debounce, $scope, $window, Alerts, currentOrg, dialog, filterCollectionFactory, IndexArchivedSurvey, i18ng, listFilter, moment, RosteringState, Search, SeasonTeams, Survey, setAs, setWhile) {

      var ctrl = this
      ctrl.state = RosteringState
      ctrl.filters = ctrl.originalFilters // prevents JS error in chips component

      ctrl.saveFilters = !!$window.localStorage.getItem('MassRostering-Personas-SaveFilters')
      ctrl.setSaveFilters = function() {
        if (ctrl.saveFilters) $window.localStorage.setItem('MassRostering-Personas-SaveFilters', true)
        else $window.localStorage.removeItem('MassRostering-Personas-SaveFilters')
      }

      function convertFiltersToFields() {
        var filters = ctrl.originalFilters
        var fields = ctrl.filterFields = {}
        fields.surveyId = filters.survey.source
        fields.filterSurveyId = filters.survey.source.replace('survey_results.', '')
        fields.search = filters.search ? filters.search.join(',') : undefined
        ctrl.search = Search.create({
          minLength: 1,
          term: fields.search,
          update: function(search) {
            fields.search = String(search) || undefined
          }
        })
        if (filters.birthdate) {
          switch (filters.birthdate.logic) {
            case 'between_date':
              fields.birthdate_start = filters.birthdate.values[0]
              fields.birthdate_end = filters.birthdate.values[1]
              break
            case 'after_date':
              fields.birthdate_start = filters.birthdate.value
              break
            case 'before_date':
              fields.birthdate_end = filters.birthdate.value
              break
          }
        }
        if (filters.gender) {
          fields.gender = filters.gender.value
        }
        if (filters.rostered_status) {
          switch (filters.rostered_status.logic) {
            case 'equal':
              fields.rostered_status = 'assigned'
              break
            case 'not_equal':
              fields.rostered_status = 'unassigned'
              break
          }
        }
        if (filters.team_id) {
          fields.team_id = filters.team_id.value
        }
      }

      function convertFieldsToFilters() {
        var fields = ctrl.filterFields
        var filters = ctrl.filters = {
          survey: { source: fields.surveyId, field: 'data_submitted', logic: 'data_submitted' },
          advanced: (ctrl.filters || ctrl.originalFilters).advanced
        }
        var surveyId = fields.surveyId.toString().replace('survey_results.', '')
        ctrl.survey = Survey.get(surveyId)
        if (fields.search) {
          // Remove any whitespace items and duplicates. Ex: test, , test, test1,  => test,test1
          filters.search = _.uniq(_.compact(_.map(fields.search.split(','), _.trim)))
        }
        if (fields.birthdate_start || fields.birthdate_end) {
          filters.birthdate = {
            source: '',
            field: 'date_of_birth'
          }
          if (fields.birthdate_start) {
            if (fields.birthdate_end) {
              filters.birthdate.logic = 'between_date'
              filters.birthdate.values = [
                moment(fields.birthdate_start).format('YYYY-MM-DD'),
                moment(fields.birthdate_end).format('YYYY-MM-DD')
              ]
            }
            else {
              filters.birthdate.logic = 'after_date'
              filters.birthdate.value = moment(fields.birthdate_start).format('YYYY-MM-DD')
            }
          }
          else {
            filters.birthdate.logic = 'before_date'
            filters.birthdate.value = moment(fields.birthdate_end).format('YYYY-MM-DD')
          }
        }
        if (fields.gender) {
          filters.gender = { source: '', field: 'gender', logic: 'choice', value: fields.gender }
        }
        if (fields.rostered_status) {
          filters.rostered_status = {
            source: 'data_by_survey',
            path: 'data_by_survey.' + ctrl.state.type,
            field: ctrl.state.type + '.survey_id',
            logic: fields.rostered_status === 'assigned' ? 'equal' : 'not_equal',
            value: surveyId
          }
        }
        if (fields.team_id) {
          filters.team_id = {
            source: '',
            field: 'team_ids',
            logic: 'choice',
            value: parseInt(fields.team_id, 10) // select2 sets this as a string
          }
        }
      }

      // Construct the initial data set for the Teams dropdown.
      //   The SeasonTeams cache should already have been populated by filter-chips or by the filterbox.
      function initTeamsOpts() {
        var teamId = ctrl.originalFilters.team_id
        var team = teamId && SeasonTeams.filter({ team_id: teamId.value })[0]
        ctrl.teamsSelect2Opts = _.defaults({
          data: team ? [{ id: teamId.value, text: team.name }] : []
        }, ctrl.state.filtering.teamsSelect2Opts)
      }

      ctrl.$onInit = function() {
        convertFiltersToFields()
        initTeamsOpts()
        filterCollectionFactory.buildFilterCollection({
          update: setAs(ctrl, 'filters.advanced'),
          filterRuleInfos: ctrl.originalFilters.advanced,
          allowEmpty: true,
          transformSerializedRule: function(opts) {
            var ret = opts.ret
            var rule = opts.rule

            ret.text = i18ng.t('ROSTERING.FILTERS.chip_text', {
              field: ret.field && (rule.field().text || rule.field().name),
              logic: rule.logic().label,
              value: _.trim(listFilter(rule.value())) || rule.value() || '',
              dataLabel: rule.logic().data_label || ''
            }) // TODO: this pattern doesn't work for longer_than and some other items

            if (ret.or) ret.text = [ret.text].concat(_.map(ret.or, 'text')).join(i18ng.t('ROSTERING.FILTERS.or_sep_text'))

            // These properties will not be serialized to JSON in the filter request
            Object.defineProperties(ret, {
              org_id: { value: currentOrg.id },
              rule: { value: rule }
            })

            return ret
          }
        })
          .then(setAs(ctrl, 'advancedFilterCollection'))

        $scope.$watch('$ctrl.filterFields.surveyId', function(newId, oldId) {
          var surveyId = newId.toString().replace('survey_results.', '')

          if (oldId && newId !== oldId) {
            if (_.any(ctrl.filters.advanced, { source: oldId })) {
              dialog.confirm({
                component: 'confirm-dialog',
                scope: $scope,
                attrs: {
                  heading: i18ng.t('ROSTERING.FILTERS.advanced_filter_conflict_header'),
                  message: i18ng.t('ROSTERING.FILTERS.advanced_filter_conflict_body'),
                  confirmLabel: i18ng.t('MODAL.continue')
                }
              })
                .then($debounce(function() {
                  _.each(_.filter(ctrl.filters.advanced, { source: oldId }), function(filter) {
                    ctrl.advancedFilterCollection.remove(filter.rule)
                  })
                  ctrl.filterFields.filterSurveyId = surveyId
                  ctrl.filterFields.surveyId = newId
                  angular.element('#filterbox-survey').val(newId).trigger('change')
                }))
              ctrl.filterFields.surveyId = oldId
            }
            else ctrl.filterFields.filterSurveyId = surveyId
          }
        })

        $scope.$watchCollection('$ctrl.filterFields', convertFieldsToFilters)
      }

      ctrl.removeFilter = function(key, item) {
        switch (key) {
          case 'search':
            ctrl.search(item ? _.without(ctrl.filters.search, item).join(',') : '')
            break
          case 'birthdate':
            delete ctrl.filterFields.birthdate_start
            delete ctrl.filterFields.birthdate_end
            break
          default:
            delete ctrl.filterFields[key]
            break
        }
      }

      ctrl.clearAll = function() {
        _.each(_.omit(ctrl.filterFields, ['surveyId', 'filterSurveyId', 'advanced']), function(field, key) { ctrl.removeFilter(key) })
        ctrl.advancedFilterCollection.clear()
      }

      ctrl.updateSurvey = function() {
        if (ctrl.filterFields.surveyId == 0) delete ctrl.filterFields.rostered_status
      }
    }

  })
