angular.module('org-admin')

  .service('featureToggles', function(DS, _, $q, $rootScope, $route, $routeParams, renderContext, showError) {

    var FeatureToggle = DS.defineResource({
      name: 'feature_toggles',
      endpoint: 'feature_instances',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
    })
    var orgId
    var deferred = $q.defer()
    var featureToggles = { $promise: deferred.promise }

    function update() {
      if (renderContext.layout === 'error') return

      orgId = $routeParams.currentOrgId
      if (!orgId) return

      getFeatures()
        .then(parse)
        .catch(handleError)
        .finally(done)
    }

    function getFeatures() {
      return FeatureToggle.findAll({
        organization_id: orgId
      })
    }

    function parse(features) {
      clearFeatures()
      featureToggles.features = features
      _.each(features, function(feature) {
        if (feature.can_use) featureToggles[feature.key] = feature.release_stage || true
      })
      deferred.resolve(featureToggles)
    }

    function handleError(error) {
      clearFeatures()
      deferred.reject()
      showError(error)
    }

    function clearFeatures() {
      for (var key in featureToggles) {
        if (key !== '$promise') delete featureToggles[key]
      }
    }

    function done() {
      featureToggles.loaded = true
    }

    $rootScope.$on('$routeChangeSuccess', update)
    if ($route.current) update()

    return featureToggles
  })
  .run(function($rootScope, featureToggles, payload) {
    $rootScope.constructor.prototype.featureToggles = featureToggles
    payload.featureToggles = featureToggles
  })
