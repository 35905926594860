angular.module('org-admin')
  .component('membershipDefinitionEligibilityEdit', {
    templateUrl: '/static/org/memberships/membership-definition-eligibility-edit.html',
    bindings: {
      cancel: '=',
      confirm: '=',
      dialogOptions: '=',
      membershipDefinition: '=',
      eligibility: '=',
      customCredentials: '=?',
      electronicDocuments: '=?',
      backgroundChecks: '=?'
    },
    controllerAs: '$ctrl',
    controller: function(
      _, i18ng, EligibilityUtilService, EligibilityRuleSet, currentOrg,
      MembershipDefinition, MembershipsUtilService) {

      var $ctrl = this
      $ctrl.$onInit = $onInit
      $ctrl.submit = submit
      $ctrl.submitting = false

      function $onInit() {
        initModelEligibility()
      }

      function initModelEligibility() {
        if ($ctrl.eligibility) {
          var eligibility = EligibilityUtilService.getMembershipEligibilityObject($ctrl.eligibility)
          eligibility.electronic_document_required = eligibility.electronic_document_required || eligibility.waiver_required
          eligibility.waivers.forEach(function(waiverId) {
            var edoc = $ctrl.electronicDocuments.find(function(edoc) {
              return edoc.waiver_id === waiverId
            })
            eligibility.electronic_documents.push(edoc.id)
          })
          $ctrl.membershipDefinition.eligibility = eligibility
        }
        else {
          $ctrl.membershipDefinition.eligibility = {}
        }
      }

      function submit() {
        $ctrl.submitting = true
        $ctrl.failed = false
        $ctrl.serverErrorMessages = null

        submitMembershipDefinition()
      }


      function submitEligibility() {
        if (!$ctrl.eligibility) $ctrl.eligibility = { eligibility_rules: [] }

        var data = {
          boss_organization_id: currentOrg.id,
          name: $ctrl.membershipDefinition.name,
          eligibility_rules: EligibilityUtilService.getUpdatedEligibilityRules(
            $ctrl.eligibility,
            $ctrl.membershipDefinition.eligibility
          )
        }

        /**
         * If a membdef has an eligibility rule set attached to it, it will always have a rule for the membership definition itself, the length will be greater than 0.
         * If it doesn't, then we want to create a new rule set for that membership. See the call to EligibilityRuleSet.create below.
         **/

        if ($ctrl.eligibility.eligibility_rules.length) {
          EligibilityRuleSet.update($ctrl.eligibility.id, data)
            .then(submitSuccess)
            .catch(submitError)
        }
        else {
          data.eligibility_rules = data.eligibility_rules.concat({
            originator_system: 'MembershipService',
            originator_type: 'membership_definition',
            originator_id: $ctrl.membershipDefinition.id
          })
          EligibilityRuleSet.create(data)
            .then(submitSuccess)
            .catch(submitError)
        }

      }

      function submitMembershipDefinition() {
        // update the membership definition validation_rules if necessary
        var next = $ctrl.membershipDefinition.is_editable ? submitEligibility : function() { submitSuccess($ctrl.eligibility) }
        var updatedValidationRules = MembershipsUtilService.mapProfileFieldsToValidationRules(
          $ctrl.membershipDefinition.profile_fields, $ctrl.membershipDefinition.validation_rules
        )
        var updatedAdditionalFields = MembershipsUtilService.mapSelectedAdditionalFieldsToModel(
          $ctrl.membershipDefinition.selected_additional_fields
        )

        var data = _.clone($ctrl.membershipDefinition)
        data.validation_rules = updatedValidationRules
        data.additional_fields = updatedAdditionalFields

        MembershipDefinition.update($ctrl.membershipDefinition.id, data)
          .then(next)
          .catch(submitError)
      }

      function submitSuccess(result) {
        $ctrl.submitting = false
        return $ctrl.confirm(result)
      }

      function submitError() {
        $ctrl.submitting = false
        $ctrl.failed = true
        $ctrl.serverErrorMessages = _.get(arguments[0], 'data.error.messages') || [i18ng.t('MEMBERSHIP_DEFINITION_FORMS.ERRORS.edit_failed')]
      }
    }
  })
