angular.module('org-admin')
  .service('OrganizationProduct', function(DS) {
    var organizationProduct =  DS.defineResource({
      name: 'organization_products',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
    })

    return {
      findByOrgId: findByOrgId,
      hasProduct: hasProduct
    }

    function findByOrgId(orgId) {
      return organizationProduct.findAll({ organization_id: orgId, status: 'active' }, { bypassCache: true })
    }

    function hasProduct(organization_products, product_key) {
      return !!_.find(organization_products, function(organization_product) {
        return organization_product.product_key === product_key && organization_product.status === 'active'
      })
    }
  })
