angular.module('org-admin')
  .component('cancelMembership', {
    templateUrl: '/static/org/memberships/cancel-membership.html',
    controllerAs: '$ctrl',
    bindings: {
      cancel: '=',
      confirm: '=',
      dialogOptions: '=',
      membership: '=',
      persona: '=',
      transaction: '='
    },
    controller: function(_, $scope, currentOrg, Membership, SaleTransactions, Alerts, appPermission) {
      var $ctrl = this
      $ctrl.loaded = false
      $ctrl.submitting = false
      $ctrl.confirmCancel = confirmCancel
      $ctrl.canRefund = false
      $ctrl.refundAmountMax = 0

      $ctrl.model = {
        notes: '',
        issue_refund: false,
        refund_amount: 0
      }

      $scope.$watchCollection('$ctrl.membership', function(m) {
        $ctrl.loaded = m && m.id
      })

      $scope.$watchCollection('$ctrl.transaction', function(tx) {
        if (!appPermission.transactions.userCanRefund) return
        if (tx && tx.id && $ctrl.membership.membershipDefinition.boss_organization_id === currentOrg.id) {
          $ctrl.canRefund = true
          $ctrl.refundAmountMax = tx.amount
        }
        else {
          $ctrl.canRefund = false
        }
      })

      function confirmCancel() {
        $ctrl.submitting = true
        Membership.cancel($ctrl.membership.id, {
          notes: JSON.stringify({ notes: $ctrl.model.notes, organizationName: currentOrg.name })
        })
          .then(function() {
            if ($ctrl.model.issue_refund) {
              SaleTransactions.refund($ctrl.transaction.id, {
                notes: $ctrl.model.notes,
                amount: parseFloat($ctrl.model.refund_amount)
              })
                .then(completeCancel)
                .catch(function() {
                  Alerts.warn('CANCEL_MEMBERSHIP.error')
                })
            }
            else completeCancel()
          })
          .catch(function() {
            Alerts.warn('CANCEL_MEMBERSHIP.error')
          })
      }

      function completeCancel() {
        Alerts.success('CANCEL_MEMBERSHIP.success')
        $ctrl.confirm()
      }
    }
  })
