angular.module('pl-shared')

  .service('MembershipDefinition', function(DS, apiV2, paginate, ENV, $http) {
    var MembershipDefinition = DS.defineResource({
      name: 'membership_definitions',
      endpoint: 'membership_definitions',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
      actions: {
        archive: {
          method: 'DELETE',
          response: apiV2.deserialize
        }
      },
      methods: {
        purchaseStatusTagColor: function() {
          if (this.archived_at) return 'gray'
          if (this.purchase_status === 'published') return 'green'
          if (this.purchase_status === 'unpublished') return 'gray'
          return null
        }
      },
      computed: {
        price: ['price_cents', function(price_cents) {
          return parseInt(price_cents || 0.00, 10) / 100
        }],
        hasChildMembership: ['child_membership_definition_id', function(childMembershipDefinitionId) {
          return !!childMembershipDefinitionId
        }],
        hasParentMembership: ['parent_membership_definition_id', function(parentMembershipDefinitionId) {
          return !!parentMembershipDefinitionId
        }],
        isExpired: ['effective_type', 'end_date', function(effectiveType, endDate) {
          if (effectiveType !== 'termed') return false
          var expires = Date.parse(endDate)
          var now = Date.now()
          return now > expires
        }],
        isExpiring: [
          'effective_type',
          'start_date',
          'end_date',
          'archived_at',
          function(effectiveType, startDate, endDate, archivedAt) {
            // is there less than 25% of time remaining for this memdef?
            if (pastDate(archivedAt)) return false
            if (effectiveType !== 'termed') return false
            var starts = Date.parse(startDate)
            var expires = Date.parse(endDate)
            var now = Date.now()
            if (now > expires) return false
            var duration = expires - starts
            var progress = now - starts
            if (!duration) return false
            return (progress / duration) > .75
          }
        ],
        isArchived: ['archived_at', function(archivedAt) {
          return pastDate(archivedAt)
        }],
        isPublished: ['purchase_status', 'archived_at', function(purchaseStatus, archivedAt) {
          if (pastDate(archivedAt)) return false
          return purchaseStatus === 'published'
        }],
        isScheduled: ['purchase_status', 'purchase_open', 'archived_at',
          function(purchaseStatus, purchaseOpen, archivedAt) {
            if (pastDate(archivedAt)) return false
            if (purchaseStatus === 'published') return false
            if (!purchaseOpen) return false
            var open = Date.parse(purchaseOpen)
            var now = Date.now()
            return open && open > now
          }
        ],
        isUnscheduled: ['purchase_status', 'purchase_open', 'archived_at',
          function(purchaseStatus, purchaseOpen, archivedAt) {
            if (pastDate(archivedAt)) return false
            if (purchaseStatus === 'published') return false
            return !purchaseOpen
          }
        ],

        // this is defined as a many-to-many relationship,
        //  but we currently only support 1 creddef per memdef
        credentialDefinitionID: ['membership_definitions_credential_definitions', function(mdcd) {
          return (mdcd && mdcd.length) ? mdcd[0].credential_definition_id : null
        }]

      }
    })

    paginate(MembershipDefinition)

    if (ENV.current === 'production') {
      MembershipDefinition.membershipUrl = 'https://memberships.sportsengine.com/buy/'
    }
    else if (ENV.current === 'development') {
      MembershipDefinition.membershipUrl = 'https://memberships.sportsengine.com.dev/buy/'
    }
    else {
      // default to staging
      MembershipDefinition.membershipUrl = 'https://memberships.sestage.us/buy/'
    }

    MembershipDefinition.validators = {
      effectivity: function(values, modelGroup, fieldName) {
        if (fieldName === 'effective_type') return true
        if (isTermedField(values, fieldName)) {
          return validateTermedField(values, fieldName)
        }
        else if (isDurationField(values, fieldName)) {
          return validateDurationField(values, fieldName)
        }
        return true

        function isTermedField(values, fieldName) {
          return values.effective_type === 'termed' &&
            ['start_date', 'end_date'].indexOf(fieldName) !== -1
        }

        function validateTermedField(values, fieldName) {
          var minDate = moment('2000-01-01').format()
          var startDate = values.start_date && new Date(values.start_date)
          var endDate = values.end_date && new Date(values.end_date)
          if (fieldName === 'start_date' && (!startDate || moment(startDate).isBefore(minDate, 'day'))) return false
          if (fieldName === 'end_date' && (!endDate || moment(endDate).isBefore(minDate, 'day'))) return false
          // if we have 1 valid date but not the other, do not return invalid for the valid field
          // the lines above will catch invalid for the proper field
          if (!endDate || !startDate) return true
          return endDate >= startDate
        }

        function isDurationField(values, fieldName) {
          return values.effective_type === 'pick_a_date' &&
            ['duration_type', 'duration_value'].indexOf(fieldName) !== -1
        }

        function validateDurationField(values, fieldName) {
          if (fieldName === 'duration_type') {
            return (['years', 'months', 'weeks', 'days'].indexOf(values.duration_type) !== -1)
          }
          else if (fieldName === 'duration_value') {
            return /^\d+$/.test(values.duration_value) && !!parseInt(values.duration_value)
          }
          return false // impossible
        }
      }
    }

    MembershipDefinition.eligibilityValidators = {
      customCredentials: function(values, modelGroup, fieldName) {
        if (fieldName === 'customCredentialRequired' || !values.customCredentialRequired) return true
        return !!(values.custom_credentials && values.custom_credentials.length)
      },
      electronicDocuments: function(values, modelGroup, fieldName) {
        // electronic_document_required is the checkbox, electronic_documents is the specific edoc selected
        // so we return true if electronic_document_required is not checked, or false unless there is at least 1 edoc selected
        if (fieldName === 'edocRequired' || !values.edocRequired) return true
        return !!(values.electronic_documents && values.electronic_documents.length)
      },
      backgroundChecks: function(values, modelGroup, fieldName) {
        if (fieldName === 'backgroundCheckRequired' || !values.backgroundCheckRequired) return true
        return !!(values.backgroundCheck)
      },
      trainings: function(values, modelGroup, fieldName) {
        if (fieldName === 'trainingRequired' || !values.trainingRequired) return true
        return !!(values.trainings && values.trainings.length)
      }
    }

    function pastDate(d) {
      var comp = Date.parse(d)
      if (!comp) return false
      return Date.now() > comp
    }

    return MembershipDefinition
  })
