angular.module('org-admin').value('templateSelectData', [
  { id: 4929, key: 'any_sport', icon: 'flag' },
  { id: 9768, key: 'baseball', icon: 'sport-baseball' },
  { id: 9756, key: 'basketball', icon: 'sport-basketball' },
  { id: 5622, key: 'diy', icon: 'pencil-fill' },
  { id: 9770, key: 'football', icon: 'sport-american-football' },
  { id: 9278, key: 'gymnastics', icon: 'sport-gymnastics' },
  { id: 9707, key: 'ice_hockey', icon: 'sport-ice-hockey' },
  { id: 9771, key: 'lacrosse', icon: 'sport-lacrosse' },
  { id: 9808, key: 'rugby', icon: 'sport-rugby' },
  { id: 9708, key: 'soccer', icon: 'sport-soccer' },
  { id: 14881, key: 'softball', icon: 'sport-softball' },
  { id: 16079, key: 'tennis', icon: 'sport-tennis' },
  { id: 3148, key: 'volleyball', icon: 'sport-volleyball' },
  { id: 5843, key: 'wrestling', icon: 'sport-wrestling' }
])
