angular.module('org-admin')
  .component('editStaticGroup', {
    bindings: {
      cancel: '<',
      confirm: '<',
      group: '='
    },
    controllerAs: '$ctrl',
    templateUrl: '/static/org/groups/edit-static-group.html',
    controller: function(StaticGroupPersona, setAs) {
      var $ctrl = this

      $ctrl.failed = false
      $ctrl.groupName = $ctrl.group.name
      $ctrl.groupType = $ctrl.group.public ? 'public' : 'private'
      $ctrl.updateGroup = updateGroup

      function updateGroup() {
        $ctrl.failed = false
        var isPublic = $ctrl.groupType === 'public'

        return StaticGroupPersona.update($ctrl.group.group_id, {
          name: $ctrl.groupName,
          public: isPublic
        })
          .then(setAs($ctrl, 'group.name', $ctrl.groupName))
          .then(setAs($ctrl, 'group.public', isPublic))
          .then($ctrl.confirm)
          .catch(setAs($ctrl, 'failed', true))
      }
    }
  })
