angular.module('org-admin')
  .factory('MembershipsUtilService', function(
    _, $q, pageViewHandler, i18ng,
    appPermission, currentOrg,
    Affiliation,
    EligibilitySearch,
    MembershipDefinition,
    MembershipDefinitionsCredentialDefinitions,
    userSettings,
    renderContext
  ) {

    var cache = {}
    var TIMEZONE = currentOrg.timezone || 'America/New_York'
    var DEFAULT_VALIDATION_RULES = ['first_name', 'last_name', 'email_addresses', 'date_of_birth', 'gender']
    var DEFINED_PROFILE_FIELDS = [{ field: 'address_required', rule: 'addresses' }, { field: 'phone_number_required', rule: 'phone_numbers' }, { field: 'graduation_year_required', rule: 'graduation_year' }]
    var DEFAULT_PROFILE_FIELDS = {
      address_required: null,
      phone_number_required: null,
      graduation_year_required: null
    }
    var DEFAULT_SELECTED_ADDITIONAL_FIELDS = {
      ethnicities: null,
      disability: null,
      military_status: null
    }

    var service = {
      dataFromModel: dataFromModel,
      forOrgZone: forOrgZone,
      getInvalidFields: getInvalidFields,
      getInviteBossOrganizationId: getInviteBossOrganizationId,
      trackAction: trackAction,
      loadAffiliatedMemberships: loadAffiliatedMemberships,
      loadPurchaseableOboMemberships: loadPurchaseableOboMemberships,
      mapModelToSelectedAdditionalFields: mapModelToSelectedAdditionalFields,
      mapProfileFieldsToValidationRules: mapProfileFieldsToValidationRules,
      mapSelectedAdditionalFieldsToModel: mapSelectedAdditionalFieldsToModel,
      mapValidationRulesToProfileFields: mapValidationRulesToProfileFields,
      memdefPublishedLabel: memdefPublishedLabel,
      memdefTotalMembers: memdefTotalMembers,
      memdefValidityLabel: memdefValidityLabel,
      TIMEZONE: TIMEZONE,
      viewRecent: viewRecent
    }

    return service

    function checkCache(cacheKey, fn) {
      if (cache.hasOwnProperty(cacheKey)) return cache[cacheKey]
      cache[cacheKey] = fn()
      return cache[cacheKey]
    }

    function loadPurchaseableOboMemberships(orgId, affiliateOrgId) {
      affiliateOrgId = affiliateOrgId || null
      return $q.all([
        loadAffiliatedMemberships(orgId, affiliateOrgId, false, false, true),
        loadFreeMemberships(orgId)
      ]).then(function(results) {
        var affiliatedMemDefs = _.filter(results[0], function(memDef) {
          return memDef.purchase_status === 'published' && memDef.can_affiliate_obo
        })
        return _.union(affiliatedMemDefs, results[1])
      })
    }

    function loadAffiliatedMemberships(orgId, affiliateOrgId, linkable, includeTags, affiliationActive) {
      if (!appPermission.governingSeasons.usable && !appPermission.affiliateMemberships.usable) return $q.resolve(null)
      affiliateOrgId = affiliateOrgId || null
      var cacheKey = 'affiliatedMemberships:' + orgId + '_' + affiliateOrgId + '_' + linkable
      return checkCache(cacheKey, function() {
        var params = {
          boss_organization_id: orgId,
          owner_org_id: affiliateOrgId,
          linkable: linkable,
          per_page: 300
        }
        if (includeTags) params.include_membership_def_tags = true
        if (affiliationActive) params.active = true
        return MembershipDefinitionsCredentialDefinitions.affiliated({
          params: params
        }).then(function(memDefCredDefs) {
          return _.map(memDefCredDefs, function(memDefCredDef) {
            return MembershipDefinition.createInstance(memDefCredDef.membership_definition)
          })
        })
      })
    }

    function loadFreeMemberships(orgId) {
      if (!appPermission.memberships.usable) return $q.resolve(null)
      var cacheKey = 'freeMemberships:' + orgId
      return checkCache(cacheKey, function() {
        return MembershipDefinition.findAll({
          boss_organization_id: orgId,
          publish_status: 'published',
          per_page: 200
        }).then(function(results) {
          return _.filter(results, function(memDef) {
            return !_.any(memDef.membership_definitions_credential_definitions) || memDef.can_invite
          })
        })
      })
    }

    function forOrgZone(datetimeString, defaultVal) {
      if (!datetimeString) return defaultVal || null
      return moment.tz(datetimeString, TIMEZONE)
    }

    function dataFromModel(model, attrs) {
      var data = {
        boss_organization_id: currentOrg.id,
        can_affiliate_obo: model.can_affiliate_obo,
        can_direct: model.can_direct,
        can_invite: model.can_invite,
        description: model.description,
        effective_type: model.effective_type,
        linkable: model.linkable,
        membership_assignment_type: model.membership_assignment_type,
        name: model.name,
        parent_linked_membership_definition_ids: model.linked_membership_definition_id ? [model.linked_membership_definition_id] : [],
        price_cents: Math.round(parseFloat(model.price || 0) * 100),
        purchase_open: model.purchase_open,
        purchase_close: model.purchase_close,
        validation_rules: mapProfileFieldsToValidationRules(model.profile_fields, model.validation_rules),
        additional_fields: mapSelectedAdditionalFieldsToModel(model.selected_additional_fields),
        welcome_delay_minutes: model.welcome_delay_minutes,
      }

      if (model.message) {
        data.messages = [{ body: model.message }]
      }

      if (!isNaN(Number.parseInt(model.credential_definition_id))) {
        data.credential_definition_id = model.credential_definition_id
      }

      if (model.parent_membership_definition_id) {
        data.parent_membership_definition_id = model.parent_membership_definition_id
      }

      if (data.effective_type === 'termed') {
        data.start_date = moment.tz(model.start_date, TIMEZONE).format()
        // Need to add 23:59:59 to end_date, since the whole day should be included
        data.end_date = moment.tz(model.end_date, TIMEZONE).add(1, 'day').subtract(1, 'seconds').format()
      }
      else if (model.effective_type === 'pick_a_date') {
        data.duration_type = model.duration_type
        data.duration_value = model.duration_value
      }

      return data
    }

    function trackAction(errors, action, label) {
      if (errors) {
        pageViewHandler.fireEvent(action + `.Errors`, 8, errors.length)
      }
      else {
        pageViewHandler.fireEvent(action, 8)
      }
    }

    function getInvalidFields(fields) {
      var invalidFields = {}
      for (var index = 0; index < fields.length; index++) {
        var error = fields[index]
        if (error.$invalid) {
          invalidFields[error.$name] = error.$error
        }
      }
      return invalidFields
    }

    function getInviteBossOrganizationId(membershipDefinitions, affiliateOnly) {
      // no membership definitions, return null
      if (!_.some(membershipDefinitions)) return $q.resolve(null)

      // if not from an affiliate screen, return currentOrg if they own a membership
      if (!affiliateOnly) {
        var anyOwned = _.some(membershipDefinitions, function(md) { return md.boss_organization_id === currentOrg.id })
        if (anyOwned) return $q.resolve(currentOrg.id)
      }

      // get orgIds that arent the currentOrg from membership definitions
      var orgIds = _.filter(_.uniq(_.map(membershipDefinitions, 'boss_organization_id')), function(orgId) { return orgId !== currentOrg.id })
      // no other orgIds, return null
      if (!_.some(orgIds)) return $q.resolve(null)
      // only 1 other orgIds, return it
      if (orgIds.length === 1) return $q.resolve(orgIds[0])

      // get parent orgs
      return Affiliation.findAll({ child_organization_id: currentOrg.id })
        .then(function(affiliations) {
          var parentOrgIds = _.uniq(_.map(affiliations, 'parent_organization_id'))
          var parentOrgIdsWithMemDefs = _.intersection(parentOrgIds, orgIds)
          // if parent org id has a membership definition, return top i guess
          if (_.some(parentOrgIdsWithMemDefs)) return parentOrgIdsWithMemDefs[0]
          // no parnet org owns a membership definition, return top i guess
          return orgIds[0]
        })
    }

    function mapSelectedAdditionalFieldsToModel(selected_additional_fields) {
      var result = []
      _.each(selected_additional_fields, function(value, key) {
        if (value) result.push(key)
      })
      return result
    }

    function mapModelToSelectedAdditionalFields(additional_fields) {
      var result = DEFAULT_SELECTED_ADDITIONAL_FIELDS
      if (Array.isArray(additional_fields)) {
        _.each(DEFAULT_SELECTED_ADDITIONAL_FIELDS, function(value, key) {
          result[key] = additional_fields.includes(key)
        })
      }
      return result
    }

    /*
      Maps our input profile_fields object to an array of validation_rules for the membership

      profile_fields: {address_required, phone_number_required} (see DEFINED_PROFILE_FIELDS)
      validation_rules: ['first_name', 'last_name', 'gender', ... ] (see DEFAULT_VALIDATION_RULES)
    */
    function mapProfileFieldsToValidationRules(profile_fields, validation_rules) {
      var result = validation_rules || DEFAULT_VALIDATION_RULES
      if (profile_fields) {
        DEFINED_PROFILE_FIELDS.forEach(function(element, index) {
          if (profile_fields[element.field] && !result.includes(element.rule)) {
            result.push(element.rule)
          }
          else if (!profile_fields[element.field] && result.includes(element.rule)) {
            result = _.without(result, element.rule)
          }
        })
      }

      return result
    }

    /*
      Maps an array of validation rule strings to the model profile_fields object

      validation_rules: ['first_name', 'last_name', 'gender', ... ] (see DEFAULT_VALIDATION_RULES)
    */
    function mapValidationRulesToProfileFields(validation_rules) {
      var result = DEFAULT_PROFILE_FIELDS
      if (Array.isArray(validation_rules)) {
        DEFINED_PROFILE_FIELDS.forEach(function(element, index) {
          result[element.field] = validation_rules.includes(element.rule)
        })
      }
      return result
    }

    function memdefPublishedLabel(memdef) {
      if (memdef.isPublished) {
        return i18ng.t('MEMBERSHIPS_LIST.published_on', {
          purchase_open: currentOrg.formatDateTime(memdef.purchase_open, 'MMM D, YYYY')
        })
      }
      else if (memdef.isScheduled) {
        return i18ng.t('MEMBERSHIPS_LIST.will_be_published', {
          purchase_open: currentOrg.formatDateTime(memdef.purchase_open, 'MMM D, YYYY')
        })
      }
      else if (memdef.isUnscheduled) {
        return i18ng.t('MEMBERSHIPS_LIST.unscheduled')
      }
      else {
        return ''
      }
    }

    function memdefTotalMembers(membership_count) {
      if (membership_count !== 1) {
        return i18ng.t('MEMBERSHIPS_LIST.membership_count_plural', {
          membership_count: membership_count ? membership_count.toLocaleString() : 0
        })
      }
      else {
        return i18ng.t('MEMBERSHIPS_LIST.membership_count')
      }
    }

    function setColumnsInLocalStorage(membershipDefinitionId) {
      var membershipKey = 'memberships.' + membershipDefinitionId
      var columnKeys = [
        membershipKey + '.number',
        membershipKey + '.purchased_date',
        membershipKey + '.effective_date',
        membershipKey + '.expiration_date',
        membershipKey + '.membership_status'
      ]
      userSettings.set('contacts-v2-' + currentOrg.id, columnKeys)
    }

    function viewRecent(membershipDefinition, filterParams) {
      pageViewHandler.fireEvent('RecentOrders.ViewAll.Click', 8)
      setColumnsInLocalStorage(membershipDefinition.id)
      renderContext.goto('app.billing.personas', { filters: filterParams })
    }

    function memdefValidityLabel(memdef, memdefOwnerOrg) {
      var org = memdefOwnerOrg || currentOrg
      if (memdef.effective_type === 'termed') {
        return i18ng.t('MEMBERSHIPS_LIST.valid_for_term', {
          start_date: formatDateTime(memdef.start_date, org.timezone),
          end_date: formatDateTime(memdef.end_date, org.timezone)
        })
      }
      else if (memdef.effective_type === 'pick_a_date') {
        return i18ng.t('MEMBERSHIPS_LIST.valid_for_duration_' + memdef.duration_type, {
          count: memdef.duration_value
        })
      }
      else if (memdef.effective_type === 'lifetime') {
        return i18ng.t('MEMBERSHIPS_LIST.valid_for_life')
      }
    }

    function formatDateTime(dateTime, timezone, _fmt) {
      var DEFAULT_TIMEZONE = 'America/New_York'
      var fmt = _fmt || 'MMM D, YYYY'
      var tz = timezone || DEFAULT_TIMEZONE
      return moment(dateTime).tz(tz).format(fmt)
    }

  })
