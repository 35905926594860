angular.module('org-admin')
  .service('PayoutsReport', function($http, DS, $q, paginate) {
    var payoutReport = DS.defineResource({
      name: 'payouts',
      endpoint: 'reports/settlement_items',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
    })
    return paginate(payoutReport)
  })
