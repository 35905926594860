angular.module('pl-shared')

  .directive('timezoneQuestion', function() {

    return {
      scope: {},
      templateUrl: '/static/shared/components/regform/questions/timezone-question.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '='
      },
      controller: function(_, ApiTimezone, setAs) {
        var ctrl = this

        ctrl.properties = ctrl.question.properties
        ctrl.model = _.isObject(ctrl.answer) ? ctrl.answer.value : null
        ctrl.inputName = 'form_result_' + ctrl.question.id

        ctrl.selectOptions = {
          placeholder: null,
          allowClear: false
        }

        var zones = []
        ctrl.zones = []

        ctrl.sortOffsets = function(zone) {
          var minus = zone.offset.charAt(0) === '-'
          var hr = parseInt(zone.offset.substring(1, 3))
          var min = parseInt(zone.offset.substring(4, 6)) / 60
          var offsetAsNum = hr + min
          return minus ? 0 - offsetAsNum : offsetAsNum
        }

        ApiTimezone.getOptions().then(setAs(ctrl, 'zones'))

      }
    }

  })
