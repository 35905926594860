angular.module('pl-shared')
  .directive('postalCode', function() {
    return {
      restrict: 'A',
      replace: true,
      scope: {},
      bindToController: {
        country: '<postalCode'
      },
      template: '<input type="text" ng-pattern="$ctrl.pattern" auto-format="postal_code" auto-format-options="{country: $ctrl.country}">',
      controllerAs: '$ctrl',
      controller: function($scope) {

        var $ctrl = this

        $scope.$watch('$ctrl.country', function(newValue, oldValue) {
          $ctrl.pattern = $ctrl.POSTAL_CODE_PATTERNS[newValue]
        })

        $ctrl.POSTAL_CODE_PATTERNS = {
          'US': /(^\d{5}$)|(^\d{5}-\d{4}$)/,
          'USA': /(^\d{5}$)|(^\d{5}-\d{4}$)/,
          'CA': /^[ABCEFGHJKLMNPRSTVXY][0-9][ABCEFGHJKLMNPRSTVWXYZ] [0-9][ABCEFGHJKLMNPRSTVWXYZ][0-9]$/,
          'CAN': /^[ABCEFGHJKLMNPRSTVXY][0-9][ABCEFGHJKLMNPRSTVWXYZ] [0-9][ABCEFGHJKLMNPRSTVWXYZ][0-9]$/,
          'GB': /^([A-Z]{1,2})([0-9]{1,2})([A-Z]{0,1})\s?([0-9][A-Z]{2})$/,
          'GBR': /^([A-Z]{1,2})([0-9]{1,2})([A-Z]{0,1})\s?([0-9][A-Z]{2})$/
        }
      }
    }
  })
