angular.module('org-admin')

  .service('PaymentPlan', function(DS) {
    var paymentPlan = DS.defineResource({
      name: 'payment_plans',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
    })

    return paymentPlan
  })
