var MARKETPLACE_URL = 'https://marketplace.sportsengine.com'
var MARKETPLACE_PARAMS = 'utm_campaign=Marketplace+Internal+Links&utm_source=Backoffice&utm_medium=Navigation+Link'

angular.module('org-admin')
  .component('marketplaceLauncher', {
    templateUrl: '/static/org/marketplace/marketplace-launcher.html',
    controllerAs: '$ctrl',
    controller: function($scope, $window, currentOrg) {
      var $ctrl = this

      $ctrl.launchMarketplace = function() {
        $window.open(MARKETPLACE_URL + '?' + MARKETPLACE_PARAMS, '_blank')
      }
    }
  })
