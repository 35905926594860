angular.module('pl-shared')

  .directive('profilePhotoQuestion', function() {

    return {
      scope: {},
      templateUrl: '/static/shared/components/registration/questions/profile-photo-question.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '=',
        editMode: '=',
        organizationName: '='
      },
      controller: function(_, $scope, dialog, fileHelper, i18ng, currentUser) {
        var ctrl = this

        ctrl.fileHelper = fileHelper
        ctrl.properties = ctrl.question.properties
        ctrl.inputName = 'form_result_' + ctrl.question.id
        ctrl.saveAsProfile = false
        ctrl.viewable = true

        $scope.$watch('ctrl.editMode', function(editMode) {
          if (!_.isObject(ctrl.answer) || !_.isObject(ctrl.answer.value)) return ctrl.model = null
          var url = ctrl.answer.value.url
          var icon = ctrl.answer.value.crop_url
          ctrl.model = fileHelper.parseUrl(url, icon)
        })

        ctrl.edit = function() {
          if (!ctrl.question.isEditable) return
          dialog.confirm({
            directive: 'file-upload-modal',
            scope: $scope,
            attrs: {
              fileUploadOptions: {
                accept: fileHelper.imageTypes,
                allowCrop: true,
                checkboxLabel: i18ng.t('ANSWER.PROFILE_PHOTO.save_as_profile', { name: currentUser.firstName }),
                checkboxModel: ctrl.saveAsProfile,
                clearLinkText: i18ng.t('ANSWER.PROFILE_PHOTO.clear_link'),
                imgClass: 'pl-document-upload__image--profile-photo',
                backgroundImage: true,
                showFilename: false,
                titleText: i18ng.t('ANSWER.PROFILE_PHOTO.title', { question: ctrl.question.question }),
                uploadLinkText: i18ng.t('ANSWER.PROFILE_PHOTO.upload_link'),
              },
              model: ctrl.model
            }
          })
            .then(updateModel)
        }

        ctrl.viewImage = function(event) {
          event.stopPropagation()
          dialog.confirm({
            directive: 'image-preview-modal',
            scope: $scope,
            attrs: {
              imgUrl: fileHelper.base64Data(ctrl.model) || ctrl.model.url,
              backgroundImage: true,
              imgClass: 'pl-image-preview-modal__image--profile-photo'
            }
          })
        }

        function updateModel(model) {
          ctrl.model = model
        }

      }
    }

  })
