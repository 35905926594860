angular.module('org-admin')
  .component('filterPanelLengthInput', {
    bindings: {
      id: '@ctrlId',
      filterRule: '<',
      readOnly: '<'
    },
    templateUrl: '/static/org/filtering/inputs/filter-panel-length-input.html',
    controller: function filterPanelLengthInputController(_) {
      var $ctrl = this

      $ctrl.getFeetValue = getFeetValue
      $ctrl.getInchesValue = getInchesValue

      function getFeetValue() {
        if (!$ctrl.filterRule || !_.isNumber($ctrl.filterRule.value())) {
          return null
        }

        return Math.floor($ctrl.filterRule.value() / 12)
      }

      function getInchesValue() {
        if (!$ctrl.filterRule || !_.isNumber($ctrl.filterRule.value())) {
          return null
        }

        return Math.round($ctrl.filterRule.value() % 12)
      }
    }
  })
