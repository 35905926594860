angular.module('pl-shared')
  .directive('dropdownDatePicker', function(_, moment) {

    var YEARS_PAST = 100
    var YEARS_FUTURE = 0

    return {
      scope: {},
      require: 'ngModel',
      bindToController: {
        name: '@',
        hasErrors: '=',
        clear: '<',
        dayMonthFormat: '<',
        yearsPast: '@',
        yearsFuture: '@',
        yearOptions: '='
      },
      templateUrl: '/static/shared/components/dropdown-date-picker/dropdown-date-picker.html',
      controllerAs: 'ctrl',
      controller: function() {},
      link: function($scope, $element, $attrs, ngModelCtrl) {
        var ctrl = $scope.ctrl
        var today = new Date()
        var internals = {}
        var unwatch = $scope.$watch('ctrl.ngModelCtrl.$modelValue', setInternals)
        var tabindex = $attrs.tabindex

        if (ctrl.clear) {
          // This component was originally set up to only watch for initial model data and then unsubscribe after that change.
          // The drop downs would still return correct dates on submit, but wouldn't allow the parent component to update the model.
          // This makes it so that we cannot reset the drop downs on change.
          // The below allows us to conditionally watch for those changes if we want to.
          $scope.$watch('ctrl.ngModelCtrl.$modelValue', updateModel)
        }

        function updateModel() {
          var parsed = moment(ngModelCtrl.$modelValue || 'INVALID')
          internals = {
            day: parsed.isValid() ? parsed.format('D') : null,
            month: parsed.isValid() ? parsed.format('M') : null,
            year: parsed.isValid() ? parsed.format('YYYY') : null
          }
          updateModelValue()
        }

        if (tabindex) {
          $element
            .removeAttr('tabindex')
            .find('select')
            .attr('tabindex', tabindex)
        }

        ctrl.ngModelCtrl = ngModelCtrl
        ctrl.years = getYears()

        ctrl.getterSetter = function(prop) {
          return function(value) {
            if (!arguments.length) return internals[prop]
            internals[prop] = value
            generateDaysOptions()
            updateModelValue()
          }
        }

        function updateModelValue() {
          ngModelCtrl.$setViewValue(fullDate())
          ngModelCtrl.$commitViewValue()
          ngModelCtrl.$validate()
          ngModelCtrl.$render()
        }

        function getYears() {
          if (ctrl.yearOptions) {
            return _.map(ctrl.yearOptions, function(yr) {
              return { value: yr, label: yr }
            })
          }
          var years = []
          var yearsPast = Number(ctrl.yearsPast || YEARS_PAST)
          var yearsFuture = Number(ctrl.yearsFuture || YEARS_FUTURE)
          var todayYear = today.getFullYear()
          var startingYear = todayYear - yearsPast
          var endingYear = todayYear + yearsFuture
          for (var y = startingYear; y <= endingYear; y++) {
            years.push({
              value: y,
              label: y
            })
          }
          return years.reverse()
        }

        ctrl.months = _.map(new Array(12), function(val, i) {
          return {
            value: i + 1,
            label: moment.months('-MMM-', i)
          }
        })

        ctrl.days = []
        function generateDaysOptions() {
          var year = internals.year
          if (!year) year = today.getFullYear()
          var daysInMonth = moment(year + '-' + internals.month, 'YYYY-MM').daysInMonth()
          if (internals.day > daysInMonth) internals.day = ''
          ctrl.days = _.map(new Array(daysInMonth), function(val, i) {
            return {
              value: i + 1,
              label: i + 1
            }
          })
        }

        function setInternals() {
          if (isNaN(ctrl.ngModelCtrl.$modelValue) && typeof ctrl.ngModelCtrl.$modelValue === 'number') return
          var parsed = moment(ngModelCtrl.$modelValue || 'INVALID')

          internals = {
            day: parsed.isValid() ? parsed.format('D') : null,
            month: parsed.isValid() ? parsed.format('M') : null,
            year: parsed.isValid() ? parsed.format('YYYY') : null
          }
          generateDaysOptions()
          unwatch()
        }

        function fullDate() {
          if (!internals.day || !internals.month || !internals.year) return null
          var month = zeroPad(internals.month)
          var day = zeroPad(internals.day)
          return moment(internals.year + '-' + month + '-' + day).format('YYYY-MM-DD')
        }

        function zeroPad(val) {
          return val.length === 1 ? '0' + val : val
        }
      }
    }
  })
