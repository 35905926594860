angular.module('org-admin')
  .directive('bankAccountEdit', function() {
    return {
      scope: {},
      require: '^^form',
      bindToController: {
        bankAccount: '='
      },
      templateUrl: '/static/org/bank-account-settings/GBR/edit-account-form.html',
      controllerAs: 'ctrl',

      link: function($scope, $element, $attr, bankAccountForm) {
        $scope.ctrl.bankAccountForm = bankAccountForm
      },

      controller: function() { }
    }
  })
