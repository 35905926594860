angular.module('org-admin')
  .directive('bankAccountSetup', function() {
    return {
      scope: {},
      require: '^^form',
      bindToController: {
        bankAccount: '=',
        ibanSelected: '='
      },
      templateUrl: '/static/org/bank-account-settings/GBR/setup-account-form.html',
      controllerAs: 'ctrl',

      link: function($scope, $element, $attr, bankAccountForm) {
        $scope.ctrl.bankAccountForm = bankAccountForm
      },

      controller: function($element, i18ng, $scope) {
        var ctrl = this

        ctrl.labelChooseFile = i18ng.t('BANK_ACCOUNT_NEW.LABELS.check_image_upload')
        ctrl.fileSelected = ''

        // Bind click to hidden file upload input
        ctrl.selectCheckImage = function() {
          angular.element('#checkImageUpload').trigger('click')
        }

        // Get Check Image file information when upload file input change
        $scope.uploadCheckImage = function(el) {
          if (el.files) {
            ctrl.uploadingImage = true
            ctrl.labelChooseFile = ''
            ctrl.fileSelected = el.files[0].name
            var reader  = new FileReader()
            // Encode file data in base64 to add it to the json
            reader.readAsDataURL(el.files[0])
            reader.onload = function() {
              // Remove headers from base64 code
              var matches = reader.result.split(',')
              ctrl.bankAccount.check_image = {
                image_data: matches[1],
                filename: el.files[0].name,
                filetype: el.files[0].type
              }
            }
          }
          else {
            // For testing purposes
            ctrl.fileSelected = i18ng.t('BANK_ACCOUNT_NEW.LABELS.check_image_no_image')
          }
          $scope.$apply()
          ctrl.uploadingImage = false
          ctrl.labelChooseFile = i18ng.t('BANK_ACCOUNT_NEW.LABELS.check_image_change')
        }

        ctrl.switchAccountInfo = function() {
          if (!ctrl.ibanSelected) {
            ctrl.bankAccount.account_number = null
            ctrl.bankAccount.branch_code = null
            ctrl.ibanSelected = true
          }
          else {
            ctrl.bankAccount.iban = null
            ctrl.ibanSelected = false
          }
        }
      }
    }
  })
