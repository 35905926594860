angular.module('org-admin')
  .component('governingSeasonsList', {
    bindings: {
      isContextAffiliate: '&'
    },
    templateUrl: '/static/org/governing-seasons/governing-seasons-list.html',
    controller: function(_, $routeParams, $q, appPermission, currentOrg, dateFilter, dialog, i18ng, Affiliation, Program, ProgramConfiguration, renderContext, setAs, setWhile, showError, ENV, pageViewHandler) {
      if (!appPermission.governingSeasons.visible) return showError('404')

      var TYPE_CLUB = 'Club'
      var TYPE_GOVERNING_SEASON = 'GoverningSeason'

      var ctrl = this
      ctrl.canEdit = appPermission.governingSeasons.editable
      ctrl.sortColumns = { start_date: 'asc' }

      ctrl.$routeParams = $routeParams

      ctrl.$onInit = setWhile(ctrl, 'governingSeasonsLoading', loadAll)

      function loadAll() {
        return $q.all([
          loadGoverningSeasons(),
          loadConfigurations()
        ])
      }

      function setPageDepths() {
        var rootOrgs = currentOrg.root_organizations
        var ngbString
        if (rootOrgs.length > 0) { // Has root orgs - is NOT an NGB
          ngbString = rootOrgs[0].name
        }
        else { // Has no root orgs - IS an NGB
          ngbString = currentOrg.name
        }
        ngbString = ngbString.replace(/[\s]/g, '')
        pageViewHandler.addPageDepths({ depth3: ngbString })
      }

      function loadGoverningSeasons() {
        return loadAffiliations()
          .then(function() {
            setPageDepths()
            if (!hasParentAffiliation()) return showError('404')
            return Program.findAll({
              org_id: orgId(),
              descendent_org_id: descendentOrgId(),
              readable_org_id: descendentOrgId(),
              type: TYPE_GOVERNING_SEASON,
              include: 'credential_configuration',
              available_to_program_type: availableToProgramType(),
              credential_definition_id: credentialDefinitionIDs()
            })
              .then(filterUpcomingSeasons)
              .then(setAs(ctrl, 'governingSeasons'))
          })
      }

      function loadAffiliations() {
        if (ctrl.isContextAffiliate()) {
          return Affiliation.findAll({
            ancestor_organization_id: orgId(),
            descendent_organization_id: descendentOrgId(),
            credential_status: 'active' // Only affiliations with status 'active' or null
          })
            .then(setAs(ctrl, 'affiliations'))
        }
        return $q.resolve()
      }

      function availableToProgramType() {
        if (ctrl.isContextAffiliate()) return TYPE_CLUB
        return null
      }

      function credentialDefinitionIDs() {
        if (ctrl.isContextAffiliate()) return Affiliation.credentialDefinitionIDs(ctrl.affiliations)
        return null
      }

      // Seasons are visible to affiliates only if they have opened, or if the current org has visibility to upcoming seasons
      function filterUpcomingSeasons(programs) {
        if (!ctrl.isContextAffiliate()) return programs
        var today = dateFilter(Date.now(), 'yyyy-MM-dd')
        return _.filter(programs, function(program) {
          return today >= program.start_date || joinUpcomingSeasons(program)
        })
      }

      // Indicates whether upcoming seasons are visible for the current affiliated org, based on
      // the join_upcoming_seasons attribute in the credential profile
      function joinUpcomingSeasons(program) {
        if (!ctrl.isContextAffiliate()) return true
        var allowedClassifications = _.get(program, 'credential_configuration.join_upcoming_seasons', '').trim().split(',')
        var credentialDefinitionIds = Affiliation.credentialDefinitionIDsList(ctrl.affiliations)
        var currentOrgAffiliation = _.find(ctrl.affiliations, function(affiliation) {
          return affiliation.child_organization_id == descendentOrgId() &&
            affiliation.parent_organization_id == orgId() &&
            _.any(_.get(affiliation, 'credentials'), function(credential) {
              return _.contains(credentialDefinitionIds, _.get(credential, 'credential_definition.id'))
            })
        })
        return _.contains(allowedClassifications, _.get(currentOrgAffiliation, 'credentials[0].classification'))
      }

      function hasParentAffiliation() {
        if (!ctrl.isContextAffiliate()) return true
        return !!_.findWhere(_.get(ctrl, 'affiliations'), { child_organization_id: descendentOrgId() })
      }

      function loadConfigurations() {
        if (ctrl.isContextAffiliate()) {
          return ProgramConfiguration.findAll(
            { organization_id: descendentOrgId(), root_organization_id: orgId(), program_type: TYPE_CLUB },
            { load: 'all' }
          )
            .then(loadAffiliatePrograms)
        }
        return $q.resolve()
      }

      function loadAffiliatePrograms(programConfigurations, bypassCache) {
        var programIds = _.map(programConfigurations, 'program_id')
        if (_.isEmpty(programIds)) return $q.resolve()
        return $q.when(ctrl.isContextAffiliate() && Program.findAll({
          by_id: JSON.stringify(programIds),
          type: TYPE_CLUB,
          include: 'roster_setup,credential_configuration'
        }, { load: 'all', bypassCache: !!bypassCache }))
      }

      ctrl.addButtonLabel = function() {
        if (!ctrl.canAdd()) return null
        return i18ng.t('GOVERNING_SEASONS.add_governing_season')
      }

      ctrl.addButtonAction = function() {
        if (!ctrl.canAdd()) return null
        return ctrl.add()
      }

      ctrl.add = function() {
        dialog.confirm({
          component: 'governing-season-add'
        })
          .then(gotoDetailContext)
          .then(ctrl.$onInit)
      }

      ctrl.copy = function(governingSeason) {
        setWhile(governingSeason, 'copying', function() {
          return Program.copy(governingSeason.id, {
            params: {
              klass: TYPE_CLUB,
              org_id: currentOrg.id,
              include: 'roster_setup,credential_configuration'
            }
          })
            .then(function(program) {
              if (program.write('teams')) return gotoRosterSetup(program)
              return gotoDetailContext(program, { route: program.tagStatus.status === 'upcoming' ? '/overview' : '/teams' })
            })
            .then(ctrl.$onInit)
        })()
      }

      ctrl.startRosterSetupWizard = function(governingSeason) {
        return reloadChildPrograms(governingSeason.id)
          .then(function() {
            if (_.isEmpty(governingSeason.childProgramId())) {
              return ctrl.copy(governingSeason)
            }
            return gotoRosterSetup({ id: governingSeason.childProgramId() })
          })
      }

      function reloadChildPrograms(rootProgramId) {
        return ProgramConfiguration.findAll(
          { organization_id: descendentOrgId(), root_program_id: rootProgramId, program_type: TYPE_CLUB },
          { params: { include: 'roster_setup,credential_configuration' }, load: 'all', bypassCache: true })
          .then(function(programConfiguration) {
            return loadAffiliatePrograms(programConfiguration, true)
          })
      }

      ctrl.programLinkId = function(governingSeason) {
        if (ctrl.isContextAffiliate()) return governingSeason.childProgramId()
        return governingSeason.id
      }

      ctrl.rosterSetupWizardStateStatus = function(governingSeason) {
        return _.get(governingSeason, 'childProgram.roster_setup_wizard_state.status')
      }

      ctrl.canJoinOrContinueWizard = function(governingSeason) {
        if (!ctrl.canEdit) return false
        if (!governingSeason.childProgramId()) return !governingSeason.isActionLocked('change_roster')
        return governingSeason.childProgram && governingSeason.childProgram.write('teams') &&
               ctrl.rosterSetupWizardStateStatus(governingSeason) !== 'complete' &&
               !governingSeason.childProgram.isActionLocked('change_roster')
      }

      ctrl.canAdd = function() {
        return ctrl.canEdit && !ctrl.isContextAffiliate()
      }

      ctrl.loadRosterStatusRollup = function(governingSeason) {
        if (ctrl.canEdit && governingSeason) {
          governingSeason.loadRosterStatusRollup(ctrl.isContextAffiliate())
        }
      }

      ctrl.submitRostersTranslationKey = function(governingSeason) {
        if (!ctrl.canEdit) return null
        return 'GOVERNING_SEASONS.ACTIONS.submit_rosters' + (
          ctrl.rosterSetupWizardStateStatus(governingSeason) ? '_continue' : ''
        )
      }

      function gotoDetailContext(program, routeParams) {
        var state = renderContext.state
        var newContext = state.context + '.detail'
        var newParams = _.defaults({ seasonId: program.id }, routeParams, state.params)
        renderContext.goto(newContext, newParams)
      }

      function gotoRosterSetup(program) {
        gotoDetailContext(program, { route: '/roster-setup' })
      }

      function orgId() {
        if (ctrl.isContextAffiliate()) return $routeParams.affiliateOrgId
        return currentOrg.id
      }

      function descendentOrgId() {
        if (ctrl.isContextAffiliate()) return currentOrg.id
        return null
      }

      ctrl.handleMessageFallback = function(eventData) {

        switch (_.get(eventData, 'type')) {

          case 'wizardUpdated':
            Program.find(eventData.programId, { params: { include: 'roster_setup,credential_configuration' }, bypassCache: true })
            break

          case 'rosterStatusUpdated':
            var program
            if (ctrl.isContextAffiliate()) {
              program = _.find(ctrl.governingSeasons, function(p) {
                return p.childProgramId() == eventData.programId
              })
            }
            else program = Program.get(eventData.programId)

            if (program && ctrl.canEdit) program.loadRosterStatusRollup(ctrl.isContextAffiliate(), true)
            break

        }

      }
    }
  })
