angular.module('org-admin')
  .service('Sale', function(DS, paginate, apiV2) {

    var Sale = DS.defineResource({
      name: 'sales',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
      actions: {
        send_reminder_emails: {
          method: 'POST',
        },
        choose_payment_plan: {
          method: 'POST',
        }
      }
    })

    Sale.reminder = function(attrs) {
      return Sale.send_reminder_emails(null, { data: attrs })
        .then(apiV2.deserialize)
    }

    Sale.choosePaymentPlan = function(saleId, paymentPlanOptionId) {
      return Sale.choose_payment_plan(saleId, { data: { payment_plan_option_id: paymentPlanOptionId } })
        .then(apiV2.deserialize)
    }

    return paginate(Sale)
  })
