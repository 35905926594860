angular.module('pl-shared')

  .service('Membership', function(_, DS, paginate, apiV2, MembershipDefinition, ENV) {
    var Membership = DS.defineResource({
      name: 'memberships',
      endpoint: 'memberships',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
      actions: {
        mine: {
          method: 'GET',
          response: apiV2.deserializeAs('memberships')
        }
      },
      computed: {
        full_name: ['persona.first_name', 'persona.last_name', function(firstName, lastName) {
          return _.compact([firstName, lastName]).join(' ')
        }],
        membershipDefinition: ['membership_definition', function(membership_definition) {
          return MembershipDefinition.createInstance(membership_definition)
        }],
        membershipProvider: ['membership_providers', function(membershipProviders) {
          if (!membershipProviders || !membershipProviders.length) return null
          return _.find(membershipProviders, { service_name: 'clare_accounting' }) || membershipProviders[0]
        }],
        providerId: ['membership_providers', function(membershipProviders) {
          if (!membershipProviders || !membershipProviders.length) return null
          var clare_provider = _.find(membershipProviders, { service_name: 'clare_accounting' })
          return clare_provider ? clare_provider.provider_id : membershipProviders[0].provider_id
        }],
        affiliatedAndOwnerOrganizationIds: ['membership_affiliation_path', 'membership_definition', function(membershipAffiliationPath, membershipDefinition) {
          var org_ids = _.map(membershipAffiliationPath, function(path) {
            return path.organization_id
          })
          org_ids.push(membershipDefinition.boss_organization_id)
          return _.uniq(org_ids)
        }]
      }
    })

    // POST to memberships/:id/cancel
    Membership.cancel = function(id, params, options) {
      var opts = {
        endpoint: 'memberships/' + id + '/cancel'
      }
      return Membership.create(params, _.defaults(opts, options))
    }

    if (ENV.current === 'production') {
      Membership.membershipUrl = 'https://memberships.sportsengine.com/'
    }
    else if (ENV.current === 'development') {
      Membership.membershipUrl = 'https://memberships.sportsengine.com.dev/'
    }
    else {
      // default to staging
      Membership.membershipUrl = 'https://memberships.sestage.us/'
    }

    paginate(Membership)

    return Membership
  })
