angular.module('org-admin')
  .directive('cancelPaymentPlan', function() {
    return {
      scope: {},
      templateUrl: '/static/org/payment-plans/cancel-payment-plan.html',
      controllerAs: 'ctrl',
      bindToController: {
        sale: '<',
        persistedPayments: '<',
        cancel: '=',
        confirm: '='
      },

      controller: function(setAs, $scope, selectHelper, currentOrg, Invoice) {
        var ctrl = this
        ctrl.reasons = []
        ctrl.selectedOption = null
        ctrl.clientError = false
        ctrl.$onInit = async function() {
          ctrl.cancellableInstallments = _.sortBy(getCancellableInstallments(), 'due_date').map(payment => {
            return {
              ...payment,
              status: 'canceled'
            }
          })
          ctrl.reasons = await Invoice.getCreditReasons(currentOrg.id)
          ctrl.selectedOption = ctrl.selectedOption || null
        }

        ctrl.submit = function() {
          var payload = createPayload()

          return ctrl.sale.credit(payload)
            .then(ctrl.confirm)
            .catch(setAs(ctrl, 'failure'))
        }

        ctrl.resetDataAndClose = function() {
          return ctrl.cancel()
        }

        ctrl.onChange = function(payment) {
          if (ctrl.selectedInstallments.contains(payment)) {
            ctrl.totalOutstanding -= parseFloat(payment.amount)
            ctrl.totalCancelled += parseFloat(payment.amount)
            payment.amount = 0
          }
          else {
            _.forEach(ctrl.persistedPayments, p => {
              if (p.id == payment.id) {
                payment.amount = p.amount
              }
            })
            ctrl.totalOutstanding += parseFloat(payment.amount)
            ctrl.totalCancelled -= parseFloat(payment.amount)
          }
        }

        function createPayload() {
          var saleAdjustments = []

          var fieldName = 'cancellation_reason'
          var formField = ctrl.cancelPaymentPlan[fieldName]

          var selectedOption = formField.$modelValue

          angular.forEach(ctrl.cancellableInstallments, function(payment) {
            payment.selectedOption = selectedOption
            var saleAdjustment = {
              amount: parseFloat(payment.amount) || 0,
              debit_credit: 'credit',
              credit_reason: Number(selectedOption.id) ?
                { id: selectedOption.id } :
                { title: selectedOption.title || selectedOption, tenant_id: currentOrg.id },
              notes: ctrl.notes
            }

            saleAdjustments.push(saleAdjustment)
          })

          var payload = {
            sale_adjustment: saleAdjustments,
            sale_id: ctrl.sale.id,
            payments: getCancellableInstallments().map(payment => {
              return {
                ...payment,
                amount: '0'
              }
            })
          }
          return payload
        }

        function isLockedPaymentStatus(payment) {
          return payment.status === 'canceled' || payment.status === 'disputed' || payment.status === 'chargeback'
        }

        function getCancellableInstallments() {
          return _.filter(ctrl.persistedPayments, function(payment) {
            if (isLockedPaymentStatus(payment)) return false
            return !payment.is_paid && !payment.upfront_payment
          })
        }
      }
    }
  })
