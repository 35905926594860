angular.module('org-admin')
  .directive('bankAccountSettings', function() {
    return {
      scope: {},
      bindToController: {
        provisioning: '<',
        country: '<'
      },
      templateUrl: '/static/org/bank-account-settings/bank-account-settings.html',
      controllerAs: 'ctrl',
      controller: function(
        _,
        $location,
        $q,
        $scope,
        appPermission,
        featureToggles,
        renderContext,
        BankAccount,
        currentOrg,
        debounceCallback,
        dialog,
        ngDialog,
        selectHelper,
        setAs,
        showError
      ) {
        if (!appPermission.bankAccountSettings.visible) return showError('404')

        var ctrl = this
        ctrl.options = { data: { } }
        ctrl.filters = []
        ctrl.loading = true
        ctrl.params = { sort: 'nickname', sort_dir: 'asc' }
        ctrl.selectedBankAccounts = selectHelper.bind($scope, 'ctrl.bankAccounts')
        ctrl.sortColumns = { nickname: 'asc' }
        ctrl.displayAccountNumber = BankAccount.displayAccountNumber

        $scope.$watch('ctrl.sortColumns', updateSortParams)

        function updateSortParams() {
          ctrl.params = _.omit(ctrl.params, function(val, key) { return _.startsWith(key, 'order[') })
          _.each(ctrl.sortColumns, function(dir, col) { ctrl.params['order[' + col + ']'] = dir })
        }

        ctrl.openAdyenBankAccount = function(bankAccount) {
          dialog.confirm({
            directive: 'bank-account-modal-gbr',
            scope: $scope,
            attrs: {
              bankAccount: bankAccount,
              createAccount: !bankAccount
            }
          })
        }

        ctrl.addLitleBankAccount = function(bankAccount) {
          dialog.confirm({
            directive: 'bank-account-add-other',
            scope: $scope,
            attrs: {}
          }).then(function() {
            ngDialog.open({
              template: '/static/org/bank-account-settings/success-modal.html'
            })
          })
        }


        ctrl.editLitleBankAccount = function(bankAccount) {
          dialog.confirm({
            directive: 'bank-account-edit-other',
            scope: $scope,
            attrs: {
              accountId: bankAccount.id
            }
          }).then(function() {
            if (bankAccount.status !== 'approved') {
              ngDialog.open({
                template: '/static/org/financial-settings/bank-account-settings/success-edit-modal.html'
              })
            }
          })
        }

        ctrl.showPagination = function() {
          return !ctrl.loading && !ctrl.failure && ctrl.options.load !== 'all' && ctrl.bankAccounts.length
        }

        ctrl.chooseAddBankAccountModal = function() {
          ctrl.country === 'GBR' ? ctrl.openAdyenBankAccount() : ctrl.addLitleBankAccount()
        }

        ctrl.accountsEditable = function() {
          return ctrl.bankAccounts.length && appPermission.bankAccountSettings.editable
        }

        ctrl.onboarded = function() {
          return appPermission.bankAccountSettings.onboarded
        }

        // this watch kicks off the load request, so it must go last
        $scope.$watchCollection('ctrl.params', loadAccounts, true)
        $scope.$on('bankAccounts:update', updateAccounts)

        // PRIVATE METHODS

        function updateAccounts() {
          loadAccounts({ clearCache: true })
        }

        var findAll = debounceCallback(BankAccount.findAllWithPagination)
        function loadAccounts(opts) {
          opts = opts || {}
          var requests = [
            findAll(currentOrg.id, ctrl.params)
              .then(setAs(ctrl, 'bankAccounts'))
          ]
          return $q.all(requests)
            .catch(showError) // TODO: make this show an inline error
            .finally(setAs(ctrl, 'loading', false))
        }

        if ($location.search().editAccount) {
          ctrl.editAccount($location.search().editAccount)
        }
      }
    }
  })
