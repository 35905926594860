'use strict'

angular.module('pl-shared')
  .directive('matchField', function() {
    return {
      require: 'ngModel',
      scope: {
        comparisonValue: '=matchField'
      },
      link: function(scope, element, attributes, ngModel) {
        ngModel.$validators.matchField = function(modelValue) {
          if (!scope.comparisonValue.$viewValue) return
          return modelValue == scope.comparisonValue.$viewValue
        }

        scope.$watch('comparisonValue.$viewValue', function() {
          ngModel.$validate()
        })
      }
    }
  })
