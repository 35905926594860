angular.module('org-admin')

  .directive('orgNavLoader', function() {
    return {
      scope: {},
      controllerAs: '$ctrl',
      replace: true,
      templateUrl: '/static/org/navigation/org-nav-loader.html'
    }
  })
