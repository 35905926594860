angular.module('org-admin')

  .directive('programListingRegistrationQuestionView', function() {

    return {
      scope: {},
      templateUrl: '/static/org/program-listings/questions/program-listing-registration-question-view.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '='
      },
      controller: function(_, $scope) {
        var ctrl = this
        var answer = ctrl.answer || {}
        ctrl.answerValue = answer.value || {}
        ctrl.hasRegInfo = ctrl.answerValue.reg_type == 'sportsengine' || ctrl.answerValue.reg_type == 'other'
        ctrl.displayValue = _.assign({
          regUrl: null,
          regType: null,
          dateRange: null
        }, answer.display_value)
      }
    }

  })
