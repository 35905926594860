
// <input type="number" />
// <input type="number" step=".01" />

angular.module('pl-shared')

  .directive('step', function(_) {
    return {
      restrict: 'A',
      require: '?ngModel',
      link: function(scope, el, attrs, ctrl) {
        if (!ctrl) return
        var ngModel = ctrl
        var step = +attrs.step

        ngModel.$parsers.push(parser)
        el.on('blur', updateView)

        function parser(inputVal) {
          // BOOLEANS
          if (inputVal === false) return 0
          if (inputVal === true) return 1
          // STRINGS... num will be NaN if empty string or if there are any non-numeric characters
          var num = parseFloat(inputVal && Number(inputVal))
          // this math can lead to rounding errors like '6.8740000000000006'. toFixed(10) prevents these.
          return isNaN(num) ? inputVal : step ? +(Math.round(num / step) * step).toFixed(10) : num
        }

        function updateView() {
          if (isNaN(ngModel.$modelValue)) return
          ngModel.$setViewValue(ngModel.$modelValue)
          ngModel.$render()
        }
      }
    }
  })
