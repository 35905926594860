angular.module('org-admin')
  .directive('invoiceEdit', function() {
    return {
      scope: {},
      bindToController: {
        invoice: '=',
        cancel: '<',
        confirm: '<'
      },
      templateUrl: '/static/org/invoices/invoice-edit-modal.html',
      controllerAs: 'ctrl',

      controller: function(dateFilter, setAs, Invoice) {
        var ctrl = this

        ctrl.invoice.notify_recipient = false
        ctrl.dueDate = ctrl.invoice.due_date
        ctrl.today = dateFilter(Date.now(), 'yyyy-MM-dd')

        ctrl.submit = function() {
          if (ctrl.dateChanged()) {
            ctrl.invoice.due_date = ctrl.dueDate
            return Invoice.update(ctrl.invoice.id, ctrl.invoice)
              .then(
                function(success) {
                  ctrl.confirm()
                },
                function(err) {
                  var responseError = err.data.error || err.statusText
                  ctrl.serverErrors = responseError.messages || [responseError]
                }
              )
          }
          else {
            ctrl.cancel()
          }
        }

        ctrl.dateChanged = function() {
          return ctrl.invoice.due_date !== ctrl.dueDate
        }
      }
    }
  })
