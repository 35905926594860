angular.module('org-admin')
  .service('CentralService', function(_, $http, $q, apiV2, ENV) {
    var policies = { profiles: {} }

    return {
      checkPolicy: checkPolicy,
      checkProfilesPolicy: checkProfilesPolicy
    }

    function checkPolicy(madeParams) {
      var apiProxyUrl = ENV.urls.apiProxy
      var requestConfig = {
        method: 'POST',
        url: apiProxyUrl + '/v3/policies/check',
        data: madeParams,
        headers: apiV2.headers,
        withCredentials: true
      }
      return $http(requestConfig).then(apiV2.deserialize)
    }

    function checkProfilesPolicy(orgId) {
      return policies.profiles[orgId] ?
        $q.resolve(policies.profiles[orgId]) :
        checkPolicy({
          resource: { type: 'hq_profiles', id: orgId },
          actions: '*'
        }).then(function(result) {
          policies.profiles[orgId] = result.actions || {}
          return policies.profiles[orgId]
        })
    }
  })
