angular.module('org-admin')

  .directive('billingProvisioning', function() {

    return {
      restrict: 'A',
      templateUrl: '/static/org/provisioning/billing-provisioning.html',
      scope: {},
      controllerAs: 'ctrl',
      controller: function(
        $q,
        $scope,
        $window,
        setWhile,
        currentOrg,
        BankAccount,
        dialog,
        appPermission,
        setAs
      ) {
        var ctrl = this

        setWhile(ctrl, 'loading', loadAccounts)()
          .then(setAs(ctrl, 'bankAccounts'))

        function loadAccounts() {
          return BankAccount.findAllUSApproved(currentOrg.id)
        }
        ctrl.transactionFee = currentOrg.transaction_fee
        ctrl.processingFee = currentOrg.processing_fee
        ctrl.financialSettingsLink = 'http://help.sportsengine.com/customer/portal/articles/1459770'

        ctrl.chooseBankAccount = function() {
          dialog.confirm({
            directive: 'choose-bank-account',
            scope: $scope,
            attrs: {
              bankAccounts: ctrl.bankAccounts
            }
          })
            .then(ctrl.reloadWindow)
        }

        // split this out for the spy in tests
        ctrl.reloadWindow = function() {
          $window.location.reload()
        }
      }
    }

  })
