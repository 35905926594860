angular.module('org-admin')
  .component('membershipDefinitionSettingsCard', {
    templateUrl: '/static/org/memberships/membership-definition-settings-card.html',
    bindings: {
      membershipDefinition: '=',
      hasEligibility: '='
    },
    controller: function($scope, dialog, Alerts, pageViewHandler) {
      var $ctrl = this

      $ctrl.editWelcomeEmail = editWelcomeEmail

      function editWelcomeEmail() {
        pageViewHandler.fireEvent('Welcome.EditLink.Click', 8)
        dialog.confirm({
          directive: 'membership-definition-message-edit',
          scope: $scope,
          attrs: {
            membershipDefinition: $ctrl.membershipDefinition,
            hasEligibility: $ctrl.hasEligibility
          }
        })
      }

    }
  })
