angular.module('org-admin')
  .component('hqDashboard', {
    templateUrl: '/static/org/hq-dashboard/hq-dashboard.html',
    controller: function(UserPreferences, PeopleAndOrgsUiService) {
      const $ctrl = this

      UserPreferences.get().then((result) => {
        $ctrl.prefs = { } // default to version 1
        $ctrl.prefsString = result.hq_dashboard.value
        $ctrl.prefsString.split('&').forEach(str => {
          const [key, value = 'true'] = str.split('=')
          $ctrl.prefs[key] = value.includes('|') ? value.split('|') : value
        })
        $ctrl.version = parseInt($ctrl.prefs.version || '1', 10) || 1
        if ($ctrl.version > 1) PeopleAndOrgsUiService.loadScripts('se-hq-dashboard')
      })
    }
  })
