angular.module('pl-shared')
  .service('EligibilityRuleSet', function(_, DS, paginate) {
    var EligibilityRuleSet = DS.defineResource({
      name: 'eligibility_rule_sets',
      endpoint: '/eligibility_rule_sets',
      basePath: DS.adapters.se_api.defaults.basePath,
      defaultAdapter: 'se_api',
      computed: {
        hasEligibility: ['eligibility_rules', function(rules) {
          return _.filter(rules, function(rule) { return rule.originator_type !== 'membership_definition' }).length > 0
        }]
      }
    })

    EligibilityRuleSet.addAggregations = function(ruleSet, aggs) {
      ruleSet.totals = {
        eligible: aggStatusInt(aggs[ruleSet.id], 'eligible'),
        incomplete: aggStatusInt(aggs[ruleSet.id], 'incomplete'),
        expired: aggStatusInt(aggs[ruleSet.id], 'expired'),
        suspended: aggStatusInt(aggs[ruleSet.id], 'suspended'),
        upcoming: aggStatusInt(aggs[ruleSet.id], 'upcoming'),
      }
      ruleSet.totals.not_eligible = ruleSet.totals.expired + ruleSet.totals.incomplete
      ruleSet.totals.total = ruleSet.totals.eligible + ruleSet.totals.upcoming + ruleSet.totals.suspended + ruleSet.totals.not_eligible
      return ruleSet
    }

    EligibilityRuleSet.calculateEligibilityVisibility = function(ruleSet) {
      ruleSet.shouldShowEligibility = ruleSet && ruleSet.totals.total > 0 && ruleSet.hasEligibility
      return ruleSet
    }

    function aggStatusInt(agg, status) {
      return parseInt(agg[status], 10) || 0
    }

    paginate(EligibilityRuleSet)

    return EligibilityRuleSet
  })
