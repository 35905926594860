angular.module('org-admin')
  .component('hqDashboardRegistration', {
    bindings: {
      status: '<'
    },
    templateUrl: '/static/org/hq-dashboard/hq-dashboard-registration.html',
    controller: function(_, currentOrg, Survey, setAs, HqMenu, SEAnalyticsService) {

      var $ctrl = this

      $ctrl.loaded = false
      $ctrl.learnMoreUrl = '/org/' + currentOrg.id + '/registration/learn_more'
      $ctrl.requestUrl = '/org/' + currentOrg.id + '/registration_request'
      $ctrl.$onInit = init()
      $ctrl.mParticleClick = mParticleClick

      function init() {
        if ($ctrl.status.unprovisioned) return $ctrl.loaded = true

        return fetchRegisrationSurveys()
          .then(prepareRegistrationSurveys)
          .then(setAs($ctrl, 'registrations'))
          .then(fetchRegistrationUrl)
          .then(setAs($ctrl, 'loaded', true))
      }

      function fetchRegisrationSurveys() {
        return Survey.findAll({
          organization_id: currentOrg.id,
          exclude_forms: true,
          include_survey_summary: true,
          status: 1,
          page: 1,
          per_page: 30
        })
      }

      function mParticleClick() {
        SEAnalyticsService.analytics.push({
          type: 'seEvent',
          data: { depth1: 'HQ', depth2: 'Dashboard', depth3: 'Registration', action: 'Registration Request', event_type: 1 }
        })
      }

      function prepareRegistrationSurveys(registrations) {
        return _(registrations)
          .sortByOrder([function(x) { return x.survey_summary.total || 0 }], ['desc'])
          .take(3)
          .each(function(registration) {
            registration.titleAttributes = { href: registration.survey_url, target: '_top' }
          })
          .value()
      }

      function fetchRegistrationUrl() {
        return HqMenu.get(currentOrg.id).then(findRegistrationUrl)
      }

      function findRegistrationUrl(hqMenu) {
        var secondaryItems = []
        var registrations
        secondaryItems = _.each((_.find(hqMenu.data.nav_tree, { label: 'Registration' }) || {}).secondaryItems)
        registrations = _.find(secondaryItems, { label: 'Registrations' })
        $ctrl.registrationsUrl = registrations.attributes.href
      }

      function formatTitleAttributes(url) {
        return {
          href: url,
          target: '_top'
        }
      }
    }
  })
