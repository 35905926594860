angular.module('pl-shared')

  .service('Provisioning', function($q) {

    var cache = {}
    var Provisioning = {
      register: register
    }

    function stripData(resp) {
      return resp && resp.data || resp
    }

    function register(name, fetch) {
      if (Provisioning[name]) throw new Error('Provision setup for `' + name + '` was already loaded.')
      Provisioning[name] = function(reload) {
        return cache[name] = !reload && cache[name] || fetch(reload).then(stripData)
      }
    }

    return Provisioning

  })
