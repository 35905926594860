angular.module('pl-shared')

  .service('Form', function(DS) {
    return DS.defineResource({
      name: 'forms',
      basePath: DS.adapters.ngin_v2.defaults.basePath,
      httpConfig: DS.adapters.ngin_v2.defaults.httpConfig,
      defaultAdapter: 'ngin_v2',
      relations: {
        hasMany: {
          question_elements: {
            localField: 'question_elements',
            foreignKey: 'form_id'
          }
        }
      }
    })
  })
  .run(function(Form) {})
