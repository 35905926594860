angular.module('org-admin')

  .factory('DivisionInstances', function(DS) {
    var DivisionInstances = DS.defineResource({
      name: 'division_instances',
      basePath: DS.adapters.ngin_v1.defaults.basePath,
      httpConfig: DS.adapters.ngin_v1.defaults.httpConfig,
      defaultAdapter: 'ngin_v1',
    })

    return DivisionInstances
  })
