angular.module('org-admin').
  directive('mobileFilters', function() {
    return {
      scope: {},
      bindToController: {
        cancel: '<',
        confirm: '<',
        filters: '=',
        applyFilters: '=',
        org: '<'
      },
      templateUrl: '/static/org/filtering/mobile-filters.html',
      controllerAs: '$ctrl',
      controller: function(_, $scope, filterPanelService, IndexArchivedSurvey) {
        var $ctrl = this
        var MIN_REMOTE_SEARCH = 3
        var initialDataSource

        $ctrl.page = 1

        $ctrl.selectDataSource = selectDataSource
        $ctrl.dataSourceSelected = dataSourceSelected
        $ctrl.selectedDataSourceChanged = selectedDataSourceChanged
        $ctrl.updateFilters = updateFilters
        $ctrl.loadMore = loadMore

        $ctrl.$onInit = $onInit
        $ctrl.hasMore = true

        function $onInit() {
          $ctrl.dataSources = []
          $ctrl.filteredDataSources = []
          loadDataSources()

          $scope.$watch('$ctrl.search', filterDataSources)
        }

        function loadDataSources() {
          $ctrl.loading = true
          filterPanelService.getDataSources({
            orgId: $ctrl.org.id,
            page: $ctrl.page
          })
            .then(function(result) {
              $ctrl.loading = false
              $ctrl.hasMore = !(result.surveys && result.surveys.pagination && result.surveys.pagination.last_page)
              $ctrl.dataSources = $ctrl.dataSources.concat(result.surveys)
              $ctrl.filteredDataSources = $ctrl.dataSources
              $ctrl.page++
              var rule = $ctrl.filters && _.head($ctrl.filters.rules)
              if (!rule) return
              var ruleId = parseInt((rule.dataSourceId() || '').replace('survey_results.', ''), 10)
              initialDataSource = _.findWhere($ctrl.dataSources, { id: ruleId })
              selectDataSource(initialDataSource)
            })
        }

        function loadMore() {
          if ($ctrl.loading || !$ctrl.hasMore || $ctrl.search) return
          loadDataSources()
        }

        function dataSourceSelected(dataSource) {
          if (!dataSource) return false
          return !!($ctrl.selectedDataSource && ($ctrl.selectedDataSource.id === dataSource.id))
        }

        function selectDataSource(dataSource) {
          if (dataSourceSelected(dataSource)) return $ctrl.selectedDataSource = null
          $ctrl.selectedDataSource = dataSource
        }

        function updateFilters() {
          if (!$ctrl.selectedDataSource && !initialDataSource) return
          if (!$ctrl.selectedDataSource) {
            $ctrl.filters.clear()
            $ctrl.cancel()
            return
          }

          var isIndexed = $ctrl.selectedDataSource.is_es_indexed

          if (isIndexed) {
            $ctrl.filters.setRule({
              dataSourceId: 'survey_results.' + $ctrl.selectedDataSource.id,
              field: { key: 'data_submitted', type: 'survey' },
              logic: { logic: 'data_submitted', type: 'null' }
            })
            $ctrl.applyFilters = $ctrl.filters
            $ctrl.cancel()

            return
          }

          IndexArchivedSurvey.index($ctrl.selectedDataSource)
          $ctrl.cancel()
        }

        function filterDataSources() {
          if (!$ctrl.search) return setFilteredSources($ctrl.dataSources)

          // quick filter existing sources
          $ctrl.filteredDataSources = _.filter($ctrl.dataSources, function(ds) {
            return ds.name.toLowerCase().indexOf(($ctrl.search.toLowerCase())) !== -1
          })
          if ($ctrl.search.length < MIN_REMOTE_SEARCH) return

          $ctrl.searching = true

          filterPanelService.getDataSources({
            orgId: $ctrl.org.id,
            search: $ctrl.search
          })
            .then(function(result) {
              setFilteredSources(result.surveys)
            })
        }

        function setFilteredSources(sources) {
          $ctrl.searching = false
          $ctrl.filteredDataSources = sources
        }

        function selectedDataSourceChanged() {
          if ($ctrl.loading) return false
          if (initialDataSource) return !dataSourceSelected(initialDataSource)
          return !!($ctrl.selectedDataSource)
        }
      }
    }
  })
  .directive('mobileFiltersScroll', function() {
    return {
      scope: {
        mobileFiltersScroll: '='
      },
      link: function($scope, $elem, $attr) {
        $elem.bind('scroll', function(evt) {
          if ($elem[0].scrollHeight - $elem.scrollTop() - $elem.height() < 2) {
            $scope.mobileFiltersScroll()
          }
        })
      }
    }
  })
