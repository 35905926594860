angular.module('pl-shared')
  .directive('scrollToError', function() {
    return {
      scope: {
        serverErrors: '=scrollToError'
      },
      link: function($scope, $element, $attrs) {
        $scope.$watch('serverErrors', function() {
          if ($scope.serverErrors) {
            $element[0].scrollTop = 0
          }
        })
      }
    }
  })
