angular.module('pl-shared')

  .directive('radioQuestion', function() {

    return {
      scope: {},
      templateUrl: '/static/shared/components/registration/questions/radio-question.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '='
      },
      controller: function(_, $scope) {
        var ctrl = this
        var allowClearValue = ctrl.question.properties.disable_clear ? false : true

        ctrl.properties = ctrl.question.properties
        ctrl.model = _.isObject(ctrl.answer) && ctrl.answer.value ? ctrl.answer.value : null
        ctrl.inputName = 'form_result_' + ctrl.question.id
        ctrl.availableChoices = ctrl.question.availableChoiceElements({ include: [].concat(ctrl.model || []) })
        ctrl.selectOptions = {
          placeholder: '',
          allowClear: allowClearValue
        }

        ctrl.clearSelection = function(form) {
          ctrl.model = null
          form.validateFields(ctrl.inputName)
        }
      }
    }

  })
