angular.module('org-admin')
  .component('membershipsOverview', {
    templateUrl: '/static/org/memberships/memberships-overview.html',
    scope: {},
    bindings: {},
    controllerAs: '$ctrl',
    controller: function($scope, currentOrg, dialog, Alerts, renderContext,
      MembershipsUtilService, appPermission, ItemPrice, $q, pageViewHandler, $window, $routeParams) {
      var $ctrl = this
      $ctrl.showEligibilityPrompt = appPermission.affiliateMemberships.showEligibilityPrompt
      $ctrl.showYourMembershipTab = appPermission.memberships.usable
      $ctrl.showAffiliateMembershipTabs = appPermission.affiliateMemberships.usable && currentOrg.root_organizations.length > 0

      preloadLinkableMemDefs()
      $ctrl.add = add
      $ctrl.currentOrg = currentOrg
      $ctrl.$routeParams = $routeParams
      $ctrl.yourMembershipTab = false

      $ctrl.$onInit = function() {
        setYourMembershipTab(renderContext.state.context)
      }

      $scope.$on('$routeChangeStart', function($event, next, current) {
        setYourMembershipTab(next.$$route.context)
      })

      function add() {
        pageViewHandler.fireEvent('AddMembershipButtonMainClick', 8)
        dialog.confirm({
          directive: 'membership-definition-new',
          scope: $scope,
          attrs: {
            membershipDefinitions: $ctrl.membershipDefinitions,
            linkableMemDefs: $ctrl.linkableMemDefs
          }
        })
          .then(addSuccess)
      }

      function addSuccess(newMemDef) {
        $window.Appcues.show('26657bd9-b052-4377-926b-6f234d088407')
        gotoNewMemdefContext(newMemDef)
        Alerts.success('MEMBERSHIP_DEFINITION_FORMS.ALERTS.create_success')
        pageViewHandler.fireEvent('AddMembershipModal.Save', 8)
      }

      function preloadLinkableMemDefs() {
        var promises = []
        var tempMemDefs = []
        currentOrg.root_organizations.forEach(function(ro) {
          promises.push(MembershipsUtilService.loadAffiliatedMemberships(currentOrg.id, ro.id, true, false, true).then(function(result) {
            if (result) {
              if (tempMemDefs.length === 0 && result.length > 0) {
                tempMemDefs.push({ id: null, name: 'Select a Membership', price: null })
              }
              tempMemDefs = tempMemDefs.concat(result)
            }
          }))
        })

        $ctrl.linkableMemDefs = tempMemDefs // allows `membership-definitions-list` to show before prices are loaded
        $q.all(promises).then(function() {
          getPrices(tempMemDefs)  // Prices are attached to each memdef
        }).then(function() {
          $ctrl.linkableMemDefs = tempMemDefs
        })
      }

      function gotoNewMemdefContext(memdef) {
        renderContext.goto('app.billing.memberships.membershipDefinition.info', { membershipDefinitionId: memdef.id })
      }

      function getPrices(memDefs) {
        if (!memDefs || !appPermission.memberships.financial) {
          return
        }
        memDefs.forEach(function(memDef) {
          if (memDef.clare_item_uuid) {
            ItemPrice.find(memDef.clare_item_uuid).then(function(data) {
              var matches = _.filter(data.variations, function(variation) { return variation.id === data.default_variation_id })
              if (matches.length) {
                memDef.price_cents = matches[0].price_cents
                memDef.price = parseInt(memDef.price_cents || 0.00, 10) / 100
              }
            })
          }
        })
      }

      function setYourMembershipTab(context) {
        $ctrl.yourMembershipTab = [
          'app.billing.memberships.published',
          'app.billing.memberships.unpublished',
          'app.billing.memberships.archived'
        ].includes(context)
      }
    }
  })
