angular.module('org-admin')
  .directive('membershipDefinitionArchive', function() {

    var $ = angular.element

    return {
      restrict: 'EA',
      scope: {},
      bindToController: {
        cancel: '=',
        confirm: '=',
        membershipDefinition: '=',
        dialogOptions: '='
      },
      templateUrl: '/static/org/memberships/membership-definition-archive.html',
      controllerAs: '$ctrl',
      controller: function(_, i18ng, currentOrg, Alerts, MembershipDefinition, SNAP) {
        var $ctrl = this
        $ctrl.archive = archive

        function archive() {
          $ctrl.submitting = true
          MembershipDefinition.archive($ctrl.membershipDefinition.id)
            .then(archiveSuccess)
            .catch(archiveError)

          function archiveSuccess(result) {
            $ctrl.submitting = false
            return $ctrl.confirm(result)
          }

          function archiveError() {
            $ctrl.submitting = false
            $ctrl.serverErrorMessages = _.get(arguments[0], 'data.error.messages') || [i18ng.t('MEMBERSHIP_DEFINITION_ARCHIVE.error')]
          }
        }
      }
    }
  })
