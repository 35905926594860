angular.module('org-admin')
  .directive('invoiceVoid', function() {
    return {
      restrict: 'A',
      scope: {},
      bindToController: {
        invoice: '=',
        cancel: '=',
        confirm: '=',
        dialogOptions: '='
      },
      controllerAs: 'ctrl',
      templateUrl: '/static/org/invoices/invoice-void.html',
      controller: function(setAs, Invoice) {
        var ctrl = this
        ctrl.notes = ''

        // PUBLIC METHODS
        ctrl.void = function() {
          return ctrl.invoice.void({ memo: ctrl.notes })
            .then(ctrl.confirm)
            .catch(setAs(ctrl, 'failure'))
        }
      }
    }
  })
