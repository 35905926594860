angular.module('pl-shared')

  .directive('imageQuestion', function() {

    return {
      scope: {},
      templateUrl: '/static/shared/components/regform/questions/image-question.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '=',
        editMode: '=',
        organizationName: '='
      },
      controller: function(_, $scope, fileHelper, i18ng) {

        var ctrl = this
        var removingImage
        var defaultTranslations = i18ng.t('IMAGE_QUESTION', { returnObjectTrees: true })
        var defaultTranslationKeys = _.transform(defaultTranslations, fullTranslationKey, {})

        function fullTranslationKey(result, value, key) {
          result[key] = 'IMAGE_QUESTION.' + key
        }

        ctrl.fileHelper = fileHelper
        ctrl.properties = ctrl.question.properties
        ctrl.translations = _.defaults({}, ctrl.properties.translations, defaultTranslationKeys)
        ctrl.inputName = 'form_result_' + ctrl.question.id
        ctrl.removeImage = removeImage

        ctrl.imageWidth = ctrl.properties.width || 1800
        ctrl.imageHeight = ctrl.properties.height || 600
        ctrl.maxWidth = 370

        // Adjust max width base on image proportions so tall images don't take up lots of space
        var aspectRatio = ctrl.imageWidth / ctrl.imageHeight
        if (aspectRatio <= 1) ctrl.maxWidth = 150
        else if (aspectRatio <= 2) ctrl.maxWidth = 250

        // console.log('translations', ctrl.translations)

        $scope.$watch('ctrl.editMode', toggleEditMode)
        $scope.$watch('ctrl.model.data', setModelFromUpload)

        function toggleEditMode() {
          if (removingImage) {
            removingImage = false
            removeImageData()
          }
          else {
            setModelFromAnswer()
          }
        }

        function setModelFromAnswer() {
          if (ctrl.editMode && ctrl.imageSrc) return
          var value = ctrl.answer.value || {}
          if ((ctrl.imageSrc = value.url)) {
            ctrl.model = fileHelper.parseUrl(value.url, value.crop_url)
          }
          else {
            removeImageData()
          }
        }

        function setModelFromUpload() {
          if (!ctrl.model || !ctrl.model.data) return // exit on first run (before upload data exists)
          ctrl.imageSrc = fileHelper.base64Data(ctrl.model)
          ctrl.editMode = true
        }

        function removeImage(e) {
          if (!ctrl.editMode) {
            removingImage = true
            ctrl.editMode = true
          }
          else {
            removeImageData()
          }
        }

        function removeImageData() {
          ctrl.model = null
          ctrl.imageSrc = null
        }

      }
    }

  })
