angular.module('pl-shared')

  .directive('dateRangeQuestion', function() {

    return {
      scope: {},
      templateUrl: '/static/shared/components/regform/questions/date-range-question.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '='
      },
      controller: function(_, i18ng, $scope) {
        var ctrl = this

        ctrl.properties = ctrl.question.properties || {}
        ctrl.dateFormat = ctrl.properties.dateFormat || 'MM/DD/YYYY'

        var defaultFields = {
          min_date: {
            id: 'min_date',
            label: i18ng.t('DATE_RANGE.LABELS.min_date'),
            is_required: false
          },
          max_date: {
            id: 'max_date',
            label: i18ng.t('DATE_RANGE.LABELS.max_date'),
            is_required: false
          }
        }
        var timeFields = {
          min_time: {
            id: 'min_time',
            label: '',
            is_required: false
          },
          max_time: {
            id: 'max_time',
            label: '',
            is_required: false
          },
          min_date_time: {
            id: 'min_date_time',
            label: '',
            is_required: false
          },
          max_date_time: {
            id: 'max_date_time',
            label: '',
            is_required: false
          }
        }

        var field
        var fields = ctrl.properties.fields || {}
        var timezone = ctrl.properties.timezone || 'UTC'

        ctrl.formatDate = function(date) {
          return moment(date).format(ctrl.dateFormat)
        }
        ctrl.timePickerOptions = {
          timeFormat: 'g:i A',
          appendTo: 'body'
        }

        // NOTE: This property allows to set all fields on the directive as different fields on the data object
        //        Check rf-form-directive.js (submitForm method) for more information.
        ctrl.properties = _.extend(ctrl.properties, { flatten_field_data: true })

        ctrl.model = _.isObject(ctrl.answer) ? ctrl.answer.value : null

        ctrl.fields = {}

        for (field in defaultFields) {
          ctrl.fields[field] = _.extend(defaultFields[field], fields[field] || {})
        }
        for (field in timeFields) {
          if (fields[field]) {
            ctrl.fields[field] = _.extend(timeFields[field], fields[field] || {})
          }
        }

        // add a time picker in addition to dates
        ctrl.timeEnabled = ctrl.fields.min_time && ctrl.fields.max_time
        if (ctrl.timeEnabled) {
          var minDate = ctrl.model.min_date_time
          var maxDate = ctrl.model.max_date_time

          $scope.$watch('ctrl.model.min_date', minChange)
          $scope.$watch('ctrl.model.max_date', maxChange)
          $scope.$watch('ctrl.model.min_time', minChange)
          $scope.$watch('ctrl.model.max_time', maxChange)
        }

        function minChange(newVal) {
          dateTimeChange('min')
        }

        function maxChange(newVal) {
          dateTimeChange('max')
        }

        function dateTimeChange(field) {
          var date = ctrl.model[field + '_date']
          if (!date) return
          var time = ctrl.model[field + '_time'] ? moment(ctrl.model[field + '_time']) : null
          var hrs = time ? time.hours() : 0
          var mins = time ? time.minutes() : 0

          ctrl.model[field + '_date_time'] = moment.tz(date, timezone).hours(hrs).minutes(mins)
        }

      }
    }

  })
