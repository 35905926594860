angular.module('org-admin')

  .directive('invoicesDetail', function() {

    return {
      scope: {},
      bindToController: {
        context: '='
      },
      templateUrl: '/static/org/invoices/invoices-detail.html',
      controllerAs: 'ctrl',
      controller: function(
        _,
        $debounce,
        $filter,
        $routeParams,
        $scope,
        $window,
        Alerts,
        appPermission,
        currentOrg,
        dialog,
        ENV,
        i18ng,
        Invoice,
        moment,
        Payment,
        PusherService,
        routeTitle,
        setAs,
        setWhile,
        showError,
        currentUser,
        launchDarklyFlags
      ) {

        var ctrl = this
        $scope.$watch(invoiceId, setWhile(ctrl, 'loading', update))
        $scope.$watch(invoiceId, pusherSubscribe)
        $scope.$on('invoice:update', update)

        ctrl.showRefundExpiredMessage = false
        ctrl.saleCanBePaid = false
        ctrl.planOptionData = null
        ctrl.someInstallmentsCanBeCancelled = false

        ctrl.$onInit = function() {
          ctrl.enableCancelInstallmentsFlag = launchDarklyFlags.creditReasons
          ctrl.enableSaleInfoFlag = launchDarklyFlags.betaFlag
        }

        setRefundWindow()

        // PUBLIC METHODS

        ctrl.sendReminder = function() {
          dialog.confirm({
            directive: 'sale-reminder',
            scope: $scope,
            attrs: {
              sales: [].concat(ctrl.invoice),
              allSettled: ctrl.invoice.is_settled
            }
          })
            .then(function(invoices) {
              invoices.length > 1 ? Alerts.success(invoices.length + i18ng.t('SALE_REMINDER.success_plural')) :
                Alerts.success(i18ng.t('SALE_REMINDER.success'))
            })
        }

        ctrl.voidBill = function() {
          dialog.confirm({
            directive: 'invoice-void',
            scope: $scope,
            attrs: {
              invoice: ctrl.invoice
            }
          })
            .then(function(saved) {
              ctrl.invoice = saved
              Alerts.success('INVOICE_VOID.success', { sale_number: saved.sale_number })
              $scope.$emit('invoice:void', saved)
              $scope.$emit('invoice:update')
            })
        }

        ctrl.applyCredit = function() {
          dialog.confirm({
            directive: 'invoice-apply-credit',
            scope: $scope,
            attrs: {
              invoice: ctrl.invoice
            }
          })
            .then(function(credit) {
              ctrl.loadPayments()
              Alerts.success(i18ng.t('INVOICE_APPLY_CREDIT.success', { credit_amount: $filter('currency')(credit[0].amount) }))
              $scope.$emit('invoice:update')
            })
        }

        ctrl.cancelRemainingInstallments = function() {
          dialog.confirm({
            directive: 'cancel-payment-plan',
            scope: $scope,
            attrs: {
              sale: ctrl.invoice,
              persistedPayments: ctrl.invoice.payments,
            }
          })
            .then(function(credits) {
              ctrl.loadPayments().then(() => {
                Alerts.success(i18ng.t('INVOICE_APPLY_CREDIT.success', {
                  credit_amount: $filter('currency')(credits.reduce((partialSum, credit) => {
                    return partialSum + parseFloat(credit.amount)
                  }, 0))
                }))
                $scope.$emit('invoice:update')
              })
            })
        }

        ctrl.payOffline = function() {
          dialog.confirm({
            directive: 'pay-offline',
            scope: $scope,
            attrs: {
              invoice: ctrl.invoice
            }
          })
            .then(function(payment) {
              Alerts.success(i18ng.t('PAY_OFFLINE.success', { payment_amount: $filter('currency')(payment.amount) }))
              $scope.$emit('invoice:update')
            })
        }

        ctrl.chooseTerms = function() {
          dialog.confirm({
            component: 'choose-terms-modal',
            scope: $scope,
            attrs: {
              optionData: ctrl.planOptionData,
              saleId: ctrl.invoice.id
            }
          }).then((saleNumber) => {
            ctrl.termsChosen(saleNumber)
          })
        }

        ctrl.termsChosen = function(saleNumber) {
          $scope.$emit('invoice:update')
          Alerts.success(i18ng.t('CHOOSE_TERMS_MODAL.success_toast', { sale_number: saleNumber }))
        }

        ctrl.onlinePayment = function() {
          $window.open(ENV.urls.sePayments + '/' + ctrl.invoice.sale_number)
        }

        ctrl.refundPayment = function() {
          dialog.confirm({
            directive: 'payment-refund',
            scope: $scope,
            attrs: {
              sale: ctrl.invoice,
              refundable: _.any(ctrl.invoice.payments, { can_be_refunded: true })
            }
          })
            .then(function(refund) {
              Alerts.success(i18ng.t('PAYMENT_REFUND.success', { refund_amount: $filter('currency')(refund.amount) }))
              $scope.$emit('invoice:update')
            })
        }

        ctrl.loadPayments = function() {
          return Payment.adjustmentCountsByDueDate(ctrl.invoice.id)
            .then(setAs(ctrl, 'payments'))
        }

        ctrl.showPaymentTerms = function() {
          return appPermission.multiPaymentTerm.usable &&
              !ctrl.invoice.placed_successfully &&
              ctrl.invoice.status !== 'voided' &&
              ctrl.invoice.payment_plan_options.length > 0
        }

        // PRIVATE METHODS
        function setRefundWindow() {
          ctrl.refundWindow = 180
          if (currentUser.hasRole('financial_admin') || currentUser.hasRole('financial_support')) {
            ctrl.refundWindow = 365
          }
        }

        function allPaymentsPastRefundDate() {
          var pastRefundDate = _.filter(ctrl.invoice.refundablePayments(), function(payment) {
            if (payment.status == 'paid_offline') return false
            var daysPassed = moment().diff(payment.created_at, 'days')
            return daysPassed > ctrl.refundWindow
          })
          return pastRefundDate.length === ctrl.invoice.refundablePayments().length
        }

        function anyPaid() {
          return _.any(ctrl.invoice.payments, { is_paid: true })
        }

        function anyChargedback() {
          return _.any(ctrl.invoice.payments, { is_chargeback: true })
        }

        function update() {
          var id = invoiceId()
          if (!id) return
          return Invoice.find(id, { bypassCache: true })
            .then(setAs(ctrl, 'invoice'))
            .then(function(invoice) {
              routeTitle.setParams({
                sale_number: ctrl.invoice.sale_number,
                invoice_description: ctrl.invoice.description
              })
              ctrl.showRefundButton = anyPaid() || anyChargedback()
              ctrl.showRefundExpiredMessage = allPaymentsPastRefundDate() && ctrl.invoice.refundablePayments().length
              ctrl.saleCanBePaid = ctrl.invoice.canBePaid()
              ctrl.someInstallmentsCanBeCancelled = getCancellableInstallments().length > 0
              return invoice.significantActivity()
            })
            .catch(showError) // this takes us out of the app context... mayb find a way to show 404 errors inline?
            .then(setAs(ctrl, 'activity'))
            .then(ctrl.loadPayments)
            .finally(() => {
              ctrl.someInstallmentsCanBeCancelled = getCancellableInstallments().length > 0
            })
        }

        function invoiceId() {
          return $routeParams.invoiceId
        }

        function pusherSubscribe() {
          PusherService.subscribe('channel_clare_organization_' + currentOrg.id)
          PusherService.bind('event_invoice_update_' + invoiceId(),
            $debounce(function(data) { $scope.$emit('invoice:update', 'pusherSubscribe') }, 1000)
          )
        }

        function isLockedPaymentStatus(payment) {
          return payment.status === 'canceled' || payment.status === 'disputed' || payment.status === 'chargeback'
        }

        function getCancellableInstallments() {
          return _.filter(ctrl.invoice.payments, function(payment) {
            if (isLockedPaymentStatus(payment)) return false
            return !payment.is_paid && !payment.upfront_payment
          })
        }
      }
    }
  })
