angular.module('org-admin')
  .factory('OrgInvoiceSummary', function($q, currentOrg, InvoiceGroup, PusherService, setAs) {

    var loadPromise
    var api = {
      init: init,
      load: loadSummary
    }

    return api

    function init() {
      if (loadPromise) return loadPromise
      PusherService.subscribe('channel_clare_organization_' + currentOrg.id)
      PusherService.bind('event_invoice_group_totals_updated', loadSummary)
      return loadSummary()
    }

    function loadSummary() {
      return loadPromise = InvoiceGroup.orgSummary(currentOrg.id)
        .then(loadSuccess, loadWarning)
    }

    function loadSuccess(data) {
      angular.merge(api, data, { loaded: true })
    }

    // returning a resolved promise on error because this info is not critical
    // to the usefullness of the page (summary will just be hidden).
    function loadWarning(e) {
      console.error('Summary data failed to load:', e)
      return $q.resolve()
    }
  })
