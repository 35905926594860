angular.module('pl-shared')
  .factory('HqMenu', function($http, apiV2, ENV, $window) {

    var promises = {}
    var apiUrl = ensureHttps(ENV.urls.apiProxy)

    return {
      get: get
    }

    function get(bossOrgId) {
      if (promises[bossOrgId]) return promises[bossOrgId]
      return promises[bossOrgId] = $http({
        method: 'GET',
        headers: {
          Accept: 'application/vnd.ngin-api.v2, application/json',
          'X-SPORTS-VERSION': 'v1'
        },
        url: apiUrl + '/hq_menu?org_id=' + bossOrgId,
        withCredentials: true,
        adapterName: 'se_api'
      })
    }

    function ensureHttps(url) {
      return (url || '').replace(/^http:\/\//i, 'https://')
    }

  })
