angular.module('org-admin')
  .component('staticGroupAddMembers', {
    bindings: {
      cancel: '<',
      confirm: '<',
      group: '<'
    },
    controllerAs: 'ctrl',
    templateUrl: '/static/org/groups/static-group-add-members.html',
    controller: function(_, $debounce, $q, Alerts, Contact, currentOrg, debounceCallback, GroupPersona, StaticGroupPersona, listFilter, i18ng, setAs) {
      var ctrl = this

      var findAllMatches = debounceCallback(function(params) {
        return Contact.findAll({
          per_page: 25,
          org_id: currentOrg.id,
          search: params.data.term
        })
      })
      var membersPromise = null

      ctrl.currentOrg = currentOrg
      ctrl.members = []
      ctrl.newMembers = []

      ctrl.orgMemberOptions = {
        minimumInputLength: 2,
        collapseOverflow: true,
        ajax: {
          transport: $debounce(function(params, success, failure) {
            findAllMatches(params).then(success, failure)
          }, 250),
          processResults: function(contacts) {
            var alreadyAdded = _.map(ctrl.newMembers, function(member) {
              return parseInt(member)
            })

            var existing = alreadyAdded.concat(_.map(ctrl.members, function(member) {
              return member.id
            }))

            var filteredContacts = _.filter(contacts, function(contact) {
              return _.all(existing, function(member) {
                return member != contact.id
              })
            })
            return { results: formatPersonas(filteredContacts) }
          }
        },
        templateResult: function(persona) {
          return persona.html || persona.title || persona.text
        }
      }

      ctrl.orgMemberOptions.data = []

      ctrl.save = save
      ctrl.$onInit = $onInit

      /**
         * Initializes the component.
         *
         * @returns {Promise} - A promise that is resolved once the component is initialized.
         */
      function $onInit() {
        return getMembers()
      }

      /**
         * Updates the group with the new members.
         *
         * @returns {Promise} - A promise that is resolved once the group has been updated.
         */
      function save() {
        return getMembers()
          .then(function(members) {
            var attrs = {
              name: ctrl.group.name,
              archived: ctrl.group.archived,
              public: ctrl.group.public,
              persona_ids: members.map(function(member) { return member.id + '' }).concat(ctrl.newMembers).join(',')
            }

            return StaticGroupPersona
              .update(ctrl.group.group_id, attrs)
              .then(function() {
                Alerts.success('STATIC_GROUP_ADD_MEMBERS.add_success')
                ctrl.confirm()
              })
              .catch(setAs(ctrl, 'failed', true))
          })
      }

      /**
         * Retrieves the members associated with the group.
         *
         * @private
         * @returns {Promise<Contact[]>} - The members associated with the group.
         */
      function getMembers() {
        if (!membersPromise) {
          membersPromise = Contact.findAll({
            org_id: currentOrg.id,
            group_persona_id: ctrl.group.id
          }, {
            load: 'all'
          })
            .then(setAs(ctrl, 'members'))
        }

        return membersPromise
      }

      /**
         * Formats persona data for select2 component.
         *
         * @param {*} personas - The persona information to format.
         * @returns {*} - The elements to display in the select.
         */
      function formatPersonas(personas) {
        function wrapOptionObj(persona, text, additionalInfo, disabled) {
          var title = text
          var html =
              '<div pl-grid="tight align-middle">' +
                '<div pl-grid="shrink@phone" pl-grid-el class="pl-lead">' +
                  '<svg class="pl-icon--light">' +
                    '<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-avatar"></use>' +
                  '</svg>' +
                '</div>' +
                '<div pl-grid="3/4@phone" pl-grid-el>' +
                  '<div>' + text + '</div>'

          if (additionalInfo) {
            title += ' (' + additionalInfo + ')'
            html += '<small class="pl-field__help--inline">' + additionalInfo + '</small>'
          }

          html += '</div></div>'
          return { id: persona.id, text: text, title: title, html: angular.element(html), disabled: disabled }
        }

        return personas.map(function(persona) {
          return wrapOptionObj(persona, persona.full_name, persona.email)
        })
      }
    }
  })
