angular.module('org-admin')
  .component('perks', {
    templateUrl: '/static/org/perks/perks.html',
    controller: function($route, $window, $scope, $rootScope, $sce, setAs, currentOrg, currentUser) {
      var $ctrl = this
      $ctrl.src = $sce.trustAsResourceUrl('https://www.sportsengine.com/perks')


      $window.addEventListener('message', onPostMessage, false)
      $scope.$on('$destroy', function() { $window.removeEventListener('message', onPostMessage, false) })


      currentUser.getSelfPersona().then(loadComplete)

      function loadComplete() {
        $rootScope.$on('$routeChangeSuccess', setSrc)
        if ($route.current) setSrc()
      }

      function setSrc() {
        $ctrl.src = $sce.trustAsResourceUrl($ctrl.src + '?' + getParams())
      }

      function getParams() {
        return angular.element.param({
          first_name: currentUser.firstname,
          last_name: currentUser.lastname,
          email: currentUser.selfPersona.owner_primary_email,
          org_name: currentOrg.name
        })
      }

      function onPostMessage(event) {
        var data = event.data || {}
        if (data.type === 'frame:loading') $rootScope.safeApply(setAs($ctrl, 'loaded', false))
        if (data.type === 'frame:loaded') $rootScope.safeApply(setAs($ctrl, 'loaded', true))
      }
    }
  })

  // Code for the landing pages (to be installed by design)
  // ;(function(){
  //
  //   window.addEventListener('load', postMessage('frame:loaded'))
  //   window.addEventListener('beforeunload', postMessage('frame:loading'))
  //
  //   // If you modify this to pass anything sensitive, you will need specific domains (instead of *) to avoid security holes.
  //   function postMessage(type) {
  //     return function(e) {
  //       try { window.parent.postMessage({ type: type }, '*'); } catch(e) {}
  //     }
  //   }
  //
  // })();
