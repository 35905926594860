angular.module('org-admin')

  .directive('programListingAgeRangeQuestion', function() {

    return {
      scope: {},
      templateUrl: '/static/org/program-listings/questions/program-listing-age-range-question.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '='
      },
      controller: function(_, i18ng, $scope, moment) {
        var ctrl = this

        ctrl.properties = ctrl.question.properties
        ctrl.model = _.isObject(ctrl.answer) ? _.clone(ctrl.answer.value) : null
        ctrl.inputName = 'form_result_' + ctrl.question.id
        var dateFormat = 'YYYY-MM-DD'

        var startDate = moment(ctrl.model.start_date || undefined)
        if (ctrl.model.age_by_date) {
          startDate = moment(ctrl.model.age_by_date)
        }

        // Set initial values
        ctrl.internalModel = {
          min_age_years: ctrl.model.min_age_years,
          max_age_years: ctrl.model.max_age_years,
          start_date_year: parseInt(startDate.format('YYYY')),
          start_date_month: parseInt(startDate.format('M')),
          start_date_day: parseInt(startDate.format('D')),
          add_age_by_date: !!ctrl.model.age_by_date,
          add_age_additional_info: !!ctrl.model.age_additional_info
        }

        // Check for any change on inputs and calculare program listing model values
        $scope.$watch('ctrl.internalModel', function() {
          var data = ctrl.internalModel
          var referenceMinDate = moment(ctrl.model.start_date)
          var referenceMaxDate = moment(ctrl.model.start_date)

          if (data.start_date_month) {  // Update days by month
            var date = new Date(data.start_date_year, data.start_date_month, 0)
            ctrl.dayChoices = []
            var maxDays = data.start_date_month === 2 ? 28 : date.getDate()
            for (var i = 1; i <= maxDays; i++) {
              ctrl.dayChoices.push({ value: i, name: i })
            }
          }

          if (data.add_age_by_date && data.start_date_year && data.start_date_month && data.start_date_day) {
            // Calculate specific date for age range
            var selectedDate = data.start_date_year + '-' + data.start_date_month + '-' + data.start_date_day
            referenceMinDate = moment(selectedDate)
            referenceMaxDate = moment(selectedDate)
            // Set age_by_date value on model
            ctrl.model.age_by_date = referenceMaxDate.format(dateFormat)
          }
          else {
            // Remove age by age from model
            ctrl.model.age_by_date = null
          }

          // Remove additional information
          if (!data.add_age_additional_info) {
            ctrl.model.age_additional_info = null
          }

          ctrl.model.min_age_years = (data.min_age_years !== null && data.min_age_years !== '') ? parseInt(data.min_age_years, 10) : null
          ctrl.model.max_age_years = (data.max_age_years !== null && data.max_age_years !== '') ? parseInt(data.max_age_years, 10) : null
          ctrl.model.min_age = ctrl.model.min_age_years ? referenceMinDate.subtract(data.min_age_years, 'years').format(dateFormat) : null
          ctrl.model.max_age = ctrl.model.max_age_years ? referenceMaxDate.subtract(data.max_age_years, 'years').format(dateFormat) : null

        }, true)

        ctrl.yearChoices = []
        var initYear = parseInt(startDate.format('YYYY')) - 5 // set year options as program Start Date +-5 years.
        for (var i = 0; i < 10; i++) {
          ctrl.yearChoices.push({ value: initYear + i, name: initYear + i })
        }
        ctrl.monthChoices = []
        var momentMonths = moment.months()
        for (i = 0; i < momentMonths.length; i++) {
          ctrl.monthChoices.push({ value: i + 1, name: momentMonths[i] })
        }
        ctrl.dayChoices = []
        for (i = 1; i <= 31; i++) {
          ctrl.dayChoices.push({ value: i, name: i })
        }

        ctrl.yearSelectOptions = {
          placeholder: i18ng.t('PROGRAM_LISTING_FORMS.LABELS.age_year_placeholder'),
          allowClear: false
        }
        ctrl.monthSelectOptions = {
          placeholder: i18ng.t('PROGRAM_LISTING_FORMS.LABELS.age_month_placeholder'),
          allowClear: false
        }
        ctrl.daySelectOptions = {
          placeholder: i18ng.t('PROGRAM_LISTING_FORMS.LABELS.age_day_placeholder'),
          allowClear: false
        }
      }
    }

  })
