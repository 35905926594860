angular.module('org-admin')

  .directive('programListingAgeRangeQuestionView', function() {

    return {
      scope: {},
      templateUrl: '/static/org/program-listings/questions/program-listing-age-range-question-view.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '='
      },
      controller: function(_, i18ng, $scope, moment) {
        var ctrl = this
        var dateFormat = 'MM/DD/YYYY'

        ctrl.properties = ctrl.question.properties
        ctrl.model = _.isObject(ctrl.answer) ? ctrl.answer.value : null

        var startDate = moment(ctrl.model.start_date)
        if (ctrl.model.age_by_date) {
          startDate = moment(ctrl.model.age_by_date)
        }

        ctrl.displayValue = '--'
        if (ctrl.model.min_age_years !== null && ctrl.model.max_age_years !== null) {
          ctrl.displayValue = ctrl.model.min_age_years + ' - ' + ctrl.model.max_age_years
          if (ctrl.model.min_age && ctrl.model.max_age) {
            var minDob = moment(ctrl.model.min_age).format(dateFormat)
            var maxDob = moment(ctrl.model.max_age).format(dateFormat)
            ctrl.displayValue += ' (' + minDob + ' - ' + maxDob + ')'
          }
        }
      }
    }

  })
