angular.module('org-admin')

  .directive('programListingPricingQuestion', function() {

    return {
      scope: {},
      templateUrl: '/static/org/program-listings/questions/program-listing-pricing-question.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '='
      },
      controller: function(_, $scope, ProgramListing) {
        var ctrl = this

        ctrl.properties = ctrl.question.properties
        ctrl.model = _.isObject(ctrl.answer) ? ctrl.answer.value : null

        ctrl.inputName = 'form_result_' + ctrl.question.id
        ctrl.inputNameFixed = 'form_result_' + ctrl.question.id + '_fixed_price'
        ctrl.inputNameMin = 'form_result_' + ctrl.question.id + '_range_min'
        ctrl.inputNameMax = 'form_result_' + ctrl.question.id + '_range_max'
        ctrl.validators = ProgramListing.validators
        ctrl.maxPriceError = ProgramListing.maxPriceError

        ctrl.availableChoices = ctrl.question.availableChoiceElements({ include: [].concat(ctrl.model || []) })

        var fieldIds = [
          ctrl.inputName,
          ctrl.inputNameFixed,
          ctrl.inputNameMin,
          ctrl.inputNameMax,
        ]

        ctrl.hasErrors = function(form) {
          return _.filter(fieldIds, function(key, index) { return !!form.hasErrors(key) }).length > 0
        }

        ctrl.getErrorMessages = function(form) {
          var errors = {}
          _.forEach(fieldIds, function(key, index) {
            if (form[key]) {
              _.extend(errors, form[key].$error)
            }
          })
          return errors
        }

      }
    }

  })
