angular.module('org-admin')
  .directive('contactsDetail', function() {
    return {
      scope: {},
      templateUrl: '/static/org/contacts/contacts-detail.html',
      controllerAs: 'ctrl',
      bindToController: {
        profilesPolicy: '<'
      },
      controller: function(
        _,
        $q,
        $rootScope,
        $scope,
        appPermission,
        dialog,
        i18ng,
        SeConfirmService,
        SeToastService,
        setWhile,
        showError,
        Persona,
        Contact,
        $routeParams,
        currentOrg,
        setAs,
        routeTitle,
        featureToggles,
        personaSurveyResults,
        OrphanInvite,
        MembershipsUtilService,
        pageViewHandler,
        $location,
        $window,
        UserServicePersonas,
        Organization,
        currentUser,
        AdminService,
        launchDarklyFlags,
        preloadScript,
        ENV
      ) {

        var ctrl = this

        ctrl.org = currentOrg
        ctrl.detailLoading = true
        ctrl.newMessage = newMessage
        ctrl.confirmDelete = confirmDelete
        ctrl.listenees = []
        ctrl.surveyResults = []
        ctrl.showPurchaseMemberships = false
        ctrl.purchaseableOboMemberships = []
        ctrl.canDeletePersona = false
        ctrl.items = []
        ctrl.detailMenuOptions = {
          sections: [{ menuItems: ctrl.items }]
        }
        ctrl.realTimeMigrationsEnabled = false
        ctrl.litmosTrainingEnabled = false
        ctrl.usslcTrainingEnabled = false
        ctrl.lmsEnabled = false
        ctrl.credentialsShowEligibilityPrompt = appPermission.credentials.showEligibilityPrompt
        ctrl.membershipsShowEligibilityPrompt = appPermission.memberships.showEligibilityPrompt
        ctrl.rosterHistoryShowEligibilityPrompt = appPermission.rosterHistory.showEligibilityPrompt
        ctrl.teamPermissions = launchDarklyFlags.teamPermissions

        if (ctrl.teamPermissions) {
          preloadScript(`${ ENV.urls.sportAdminElements }/roster-persona-team-permissions-card-element/element.js`)
        }

        initializeFeatureToggles()

        function addInvoiceItem() {
          if (
            appPermission.powerPay.usable &&
            ctrl.profilesPolicy.invoice &&
            ctrl.persona.email_addresses.length
          ) {
            ctrl.items.push({
              text: i18ng.t('PAYMENTS_OVERVIEW.send_invoices'),
              action: function() {
                ctrl.sendInvoices()
              }
            })
          }
        }

        function addMembershipItem() {
          if (ctrl.canBuyMembership) {
            ctrl.items.push({
              text: i18ng.t('CONTACT_DETAIL.buy_membership'),
              action: function() {
                ctrl.purchaseMemberships()
              }
            })
          }
        }

        function addSuspensionItem() {
          if (ctrl.profilesPolicy.suspend) {
            ctrl.items.push({
              text: i18ng.t('CONTACT_DETAIL.apply_suspension'),
              action: function() {
                ctrl.applySuspension()
              }
            })
          }
        }

        function addDeleteItem() {
          ctrl.items.push({
            text: i18ng.t('CONTACT_DETAIL.delete'),
            action: function() { ctrl.confirmDelete() }
          })
        }

        $scope.$watch(personaIdParam, update)

        ctrl.canSendInvoices = function() {
          return ctrl.profilesPolicy.invoice && ctrl.persona.email_addresses.length
        }

        ctrl.sendInvoices = function() {
          dialog.confirm({
            directive: 'send-invoices',
            scope: $scope,
            attrs: {
              recipients: [].concat(ctrl.contact || []),
              showStaticRecipients: true
            }
          }).then(function() { pageViewHandler.fireEvent('SendInvoiceModal.SendInvoiceClick', 8) })
        }

        ctrl.applySuspension = function() {
          return dialog.confirm({
            component: 'suspension-apply',
            scope: $scope,
            attrs: {
              persona: ctrl.persona
            }
          })
            .then(function(suspension) {
              $rootScope.$emit('suspensions:create_instance', suspension)
            })
        }

        ctrl.purchaseMemberships = function() {
          if (!ctrl.contact) return
          if (ctrl.canBuyMembership) {
            ctrl.contactsForMembership = $q.resolve([ctrl.contact])
            ctrl.showPurchaseMemberships = true
          }
          else {
            dialog.confirm({
              directive: 'no-purchaseable-memberships',
              scope: $scope,
            })
          }
          pageViewHandler.fireEvent('BuyMembershipClick', 8)
        }

        ctrl.updateVerifiedInfo = function(update) {
          var newVerifiedInfo = angular.copy(ctrl.verifiedInfo)
          Object.assign(newVerifiedInfo, update)
          ctrl.verifiedInfo = angular.copy(newVerifiedInfo)

          var newLegalInfo = {
            date_of_birth: ctrl.verifiedInfo.date_of_birth,
            first_name: ctrl.verifiedInfo.first_name,
            gender: ctrl.verifiedInfo.gender,
            last_name: ctrl.verifiedInfo.last_name,
            middle_name: ctrl.verifiedInfo.middle_name,
            organization_id: ctrl.verifiedInfo.org_id,
            suffix: ctrl.verifiedInfo.suffix,
          }
          var legalIndex = _.findIndex(ctrl.contact.legal, { 'organization_id': ctrl.verifiedInfo.org_id })
          if (legalIndex >= 0) {
            ctrl.contact.legal[legalIndex] = newLegalInfo
          }
          else {
            ctrl.contact.legal.push(newLegalInfo)
          }
        }

        function personaIdParam() {
          return $routeParams.personaId
        }

        function update() {
          setWhile(ctrl, 'loading', loadData)()
        }

        function loadData() {
          if (personaIdParam()) {
            return findPersona()
              .then(loadPersonaSuccess)
              .then(independentCalls)
              .then(loadOrphanInviteSuccess)
              .then(addSuspensionItem)
              .then(loadAdminDetail)
              .catch(showError)
          }
        }

        function independentCalls() {
          return $q.all([
            findUserServicePersona(),
            loadAllSurveyResults(),
            membershipCheck(),
            findContact(),
            loadOrphanInvite()
          ])
        }

        function findPersona() {
          return Persona.find(personaIdParam(), { params: { org_id: currentOrg.id, create_missing_email_alert_preferences: 1 } })
        }

        function findUserServicePersona() {
          return UserServicePersonas.find(personaIdParam(), { params: { org_id: currentOrg.id }, bypassCache: true })
            .then(function(response) {
              var verifiedInfoList = _.get(response, 'verified_information') || []
              verifiedInfoList = _.filter(verifiedInfoList, 'verified')
              ctrl.verifiedInfo = _.sortByOrder(verifiedInfoList, [function(vi) { return vi.approved_at || '1900-01-01' }, 'created_at'], ['desc', 'desc'])[0]
            }).catch(function(error) {
              // need to eat this for now to prevent the page from showing the error message
              ctrl.verifiedInfo = null
              $q.resolve()
            })
        }

        function loadPersonaSuccess(persona) {
          $q(function(resolve, reject) {
            ctrl.persona = persona
            routeTitle.setParams({
              persona_name: persona.full_name
            })
            addInvoiceItem()
            resolve()
          }
          )
        }

        function loadOrphanInvite() {
          if (!ctrl.persona.is_orphan || !ctrl.persona.orphan_invite_id) return $q.resolve()
          return OrphanInvite.find(ctrl.persona.orphan_invite_id)
        }

        function loadOrphanInviteSuccess(orphanInvite) {
          if (!orphanInvite) return $q.resolve()
          ctrl.orphanInvite = orphanInvite
        }

        function loadAllSurveyResults() {
          if (ctrl.persona.is_orphan) return $q.resolve()
          var params = {
            organization_id: $routeParams.currentOrgId,
            persona_id: $routeParams.personaId,
            show_hidden: true
          }
          var options = { result: ctrl.surveyResults, load: 'all' }
          return personaSurveyResults.loadAllSurveyResults(params, options)
            .then(personaSurveyResults.filterEmptySiteMemberSurveyResults)
            .then(setAs(ctrl, 'surveyResults'))
        }

        function findContact() {
          return Contact.find(personaIdParam(), { params: { org_id: currentOrg.id }, bypassCache: true })
            .then(function(result) {
              ctrl.contact = _.first(result) || result

              if (ctrl.contact.invite_status === 'claimed') {
                ctrl.canDeletePersona = false
              }
              else {
                // Do not allow delete with useful info attached
                if (ctrl.contact.roster_players.length > 0 ||
                    ctrl.contact.memberships.length > 0 ||
                    (ctrl.contact.survey_results && ctrl.contact.survey_results.length > 0)) {
                  ctrl.canDeletePersona = false
                }
                else {
                  ctrl.canDeletePersona = true
                  addDeleteItem()
                }
              }
            })
        }

        function membershipCheck() {
          if (!ctrl.profilesPolicy.buy_memberships) return

          return MembershipsUtilService.loadPurchaseableOboMemberships(currentOrg.id)
            .then(function(result) {
              ctrl.purchaseableOboMemberships = result
              ctrl.canBuyMembership = _.some(result)
              addMembershipItem()
            })
        }

        function newMessage() {
          dialog.confirm({
            component: 'new-org-message',
            scope: $scope,
            attrs: {
              recipients: [ctrl.persona],
              verifiedInfo: [ctrl.verifiedInfo]
            }
          })
        }

        function confirmDelete() {
          return SeConfirmService.confirm({
            message: i18ng.t('CONTACT_DETAIL.delete_message', { persona_name: ctrl.persona.first_name }),
            cancel: i18ng.t('CONTACT_DETAIL.delete_cancel'),
            destroy: i18ng.t('CONTACT_DETAIL.delete_destroy'),
            onConfirm: deletePersona
          }).then(function(result) {
            if (result === false) {
              pageViewHandler.fireEvent('KeepProfileClick', 8)
            }
          })
        }

        function deletePersona() {
          pageViewHandler.fireEvent('DeleteProfileClick', 8)
          return Persona.adminDestroy(ctrl.persona.id
          ).then(function() {
            SeToastService.success(
              i18ng.t('CONTACT_DETAIL.delete_success', { persona_name: ctrl.persona.full_name }),
              'check-circle')
            $location.url('/org/' + currentOrg.id + '/people?removed_sport_ngin_id=' + ctrl.persona.sport_ngin_id)
          }, function() {
            SeToastService.error(
              i18ng.t('CONTACT_DETAIL.delete_error'),
              'warning'
            )
          })
        }

        function initializeFeatureToggles() {
          var featureToggleOrgId =  (ctrl.org.root_organizations && ctrl.org.root_organizations.length) ?
            ctrl.org.root_organizations[0].id : ctrl.org.id

          Organization.hasFeatureToggleEnabled(featureToggleOrgId, 'real_time_migrations')
            .then(function(enabled) {
              ctrl.realTimeMigrationsEnabled = enabled
            })

          Organization.hasFeatureToggleEnabled(featureToggleOrgId, 'litmos_training')
            .then(function(enabled) {
              ctrl.litmosTrainingEnabled = enabled
            })

          Organization.hasFeatureToggleEnabled(featureToggleOrgId, 'usslc_training')
            .then(function(enabled) {
              ctrl.usslcTrainingEnabled = enabled
            })

          Organization.hasFeatureToggleEnabled(featureToggleOrgId, 'lms_concussion_training')
            .then(function(enabled) {
              ctrl.lmsEnabled = enabled
            })
        }

        function loadAdminDetail() {
          if (!appPermission.adminManagement.visible) return

          return AdminService.findDetail($routeParams.personaId, ctrl.org.id)
            .then(admin => {
              ctrl.detail = admin
              ctrl.detailLoading = false
            })
        }
      }
    }
  })
