angular.module('org-admin')
  .directive('saleReminder', function() {
    return {
      restrict: 'A',
      scope: {},
      bindToController: {
        sales: '=',
        cancel: '=',
        confirm: '=',
        dialogOptions: '=',
        allSettled: '<',
        someSettled: '<'
      },
      controllerAs: 'ctrl',
      templateUrl: '/static/org/sales-reminder/sale-reminder.html',
      controller: function(setAs, Sale, $filter) {
        var ctrl = this

        // Accept array of sales or single sale
        var sales = [].concat(ctrl.sales)

        // Limit number of items displayed in modal
        var MAX = 6
        ctrl.to = truncate(sales, 'user_full_name', MAX)
        ctrl.for = truncate(sales, 'description', MAX)

        function truncate(array, key, max) {
          var items = _.uniq(_.map(array, key))
          if (items.length > max) {
            items = items.slice(0, max)
            items.push('…')
          }
          return items.join(', ')
        }

        // PUBLIC METHODS

        ctrl.previousReminders = _.compact(_.pluck(sales, 'reminded_at'))

        ctrl.reminder = function() {
          var attrs = {
            sale_numbers: _.map(sales, 'sale_number')
          }
          if (ctrl.message) attrs.message = ctrl.message
          return Sale.reminder(attrs)
            .then(ctrl.confirm)
            .catch(setAs(ctrl, 'failure'))
        }

        ctrl.formatDate = function(date) {
          return $filter('date')(date, 'MMM d yyyy')
        }

        ctrl.mostRecentPreviousReminder = function() {
          var dates = _.map(ctrl.previousReminders, function(date) {
            return new Date(date)
          })
          var mostRecent = Math.max.apply(null, dates)
          return ctrl.formatDate(mostRecent)
        }

        ctrl.settledReason = function(sale) {
          if (sale.status === 'voided') return '_voided'
          return '_paid'
        }
      }
    }
  })
