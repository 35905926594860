angular.module('pl-shared')
  .directive('integer', function() {
    return {
      require: 'ngModel',
      scope: {
        integer: '='
      },
      link: function(scope, el, attr, ctrl) {
        if (scope.integer !== false) {
          ctrl.$parsers.unshift(function(viewValue) {
            return parseInt(viewValue, 10)
          })
        }
      }
    }
  })
