angular.module('pl-shared')

  .directive('colorQuestion', function() {

    var $ = angular.element

    return {
      scope: {},
      templateUrl: '/static/shared/components/regform/questions/color-question.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '='
      },
      controller: function(_, $scope, $timeout, $window) {
        var ctrl = this

        ctrl.properties = ctrl.question.properties
        ctrl.model = _.isObject(ctrl.answer) ? ctrl.answer.value : null
        ctrl.inputName = 'form_result_' + ctrl.question.id

        ctrl.colorPickerSettings = {
          position: 'top left'
        }
        function calculateColorPickerPosition() {
          var colorInput = $('.pl-color-picker')
          var formContainer = colorInput.closest('.pl-form-result__questions')
          if (!colorInput.length || !formContainer.length) return
          var colorRect = colorInput[0].getBoundingClientRect()
          var formContainerRect = formContainer[0].getBoundingClientRect()
          var colorTop = colorRect.top - formContainerRect.top
          var colorBottom = formContainerRect.bottom - colorRect.bottom

          ctrl.colorPickerSettings.position = (colorTop > colorBottom ? 'top' : 'bottom') + ' left'
        }

        // TODO: calculate input position after all field on 'edit mode' are shown and the ones in 'view mode' are hide
        $timeout(calculateColorPickerPosition, 500)

        function recalculate() {
          calculateColorPickerPosition()
          // manual $digest required as resize event is outside of angular
          if ($scope.$root && !$scope.$root.$$phase) $scope.$digest()
        }
        $($window).on('resize', recalculate)
        $scope.$on('$destroy', function() {
          $($window).off('resize', recalculate)
        })

        // additional element attributes that may be needed in future
        // ctrl.question.is_enabled - question does not show in view mode but shows as disabled in edit mode. filtered out by API?
        // ctrl.question.is_hidden - question shows for admins only. filtered out by API
        // ctrl.question.auto_fill - will already be auto-filled by the time it gets here
        // ctrl.question.mask - hide answer on email receipt (still shows while editing). filter out by API for orgs?
        // ctrl.question.properties.hidden_default - question is hidden by default when creating

      }
    }

  })
