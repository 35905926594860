angular.module('org-admin')

  .service('DiscountCodeDefinitions', function(DS, paginate) {
    var DiscountCodeDefinitions = DS.defineResource({
      name: 'discountCodeDefinitions',
      endpoint: 'discount/code_definitions',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
      actions: {
        statistics: {
          method: 'GET',
        }
      }
    })

    DiscountCodeDefinitions.tagColor = {
      'active': 'green',
      'deactivated': 'gray',
      'expired': 'orange',
      'out_of_stock': 'yellow',
      'scheduled': 'blue'
    }

    return paginate(DiscountCodeDefinitions)
  })
