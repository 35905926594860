angular.module('org-admin')
  .directive('setPayoutAccount', function() {
    return {
      scope: { },
      bindToController: {
        accounts: '<'
      },
      templateUrl: '/static/org/bank-account-settings/set-payout-account.html',
      controllerAs: 'ctrl',
      controller: function(
        $scope,
        BankAccount,
        currentOrg,
        currentUser,
        dialog
      ) {

        var ctrl = this

        ctrl.country = (currentOrg.country === 'GBR') ? 'GBR' : 'Other'

        ctrl.setPayoutAccount = function() {
          var chooseAccountModalConfigs = {
            'Other': {
              directive: 'choose-bank-account',
              scope: $scope,
              attrs: {
                bankAccounts: ctrl.accounts
              }
            },
            'GBR': {
              directive: 'choose-bank-account-gbr',
              scope: $scope,
              attrs: {
                bankAccounts: ctrl.accounts
              }
            }
          }
          dialog.confirm(chooseAccountModalConfigs[ctrl.country])
        }

        ctrl.showSetPayoutAccount = function(country) {
          if (currentOrg.country === 'CAN') return false
          if (!(currentUser.hasRole('financial_client_admin') || currentUser.hasRole('financial_gateway_admin'))) return false
          var approvedAccounts = BankAccount.approvedAccounts(ctrl.accounts)
          return approvedAccounts.length > 1
        }
      }
    }
  })
