angular.module('org-admin')
  .component('membershipNotesDetails', {
    templateUrl: '/static/org/memberships/membership-notes-details.html',
    bindings: {
      cancel: '=',
      dialogOptions: '=',
      membership: '<',
    },
    controller: function(currentOrg) {
      var $ctrl = this

      $ctrl.affiliateName = affiliateName
      $ctrl.membershipInfo = $ctrl.membership.membership_definition.name
      if ($ctrl.membership.membership_affiliation_path) {
        $ctrl.membershipInfo += ' • ' + affiliateName()
      }

      var audit_log = $ctrl.membership.audit_logs[0]
      if (audit_log) {
        $ctrl.createdAt = currentOrg.formatDateTime(audit_log.created_at, 'MMM D, YYYY')
        $ctrl.fullName = audit_log.user_full_name
        $ctrl.notes = audit_log.notes

        try {
          var notesObj = JSON.parse($ctrl.notes)
          $ctrl.notes = notesObj.notes
          $ctrl.organizationName = notesObj.organizationName + ', '
        }
        catch (e) { }
      }

      function affiliateName() {
        return $ctrl.membership.membership_affiliation_path.find(function(affiliate) {
          return affiliate.organization_id === $ctrl.membership.affiliation_organization_id
        }).organization_name
      }
    }
  })
