angular.module('org-admin')

  .directive('planPreview', function() {

    return {
      scope: {},
      bindToController: {
        hasCustomDates: '<',
        invoiceGroup: '<',
        paymentPlan: '=',
        showUpfrontPayment: '<',
        showUpfrontPaymentTooltip: '<'
      },
      controllerAs: 'ctrl',
      templateUrl: '/static/org/payment-plans/plan-preview.html',
      controller: function() {

        var ctrl = this
        ctrl.currencySymbol = ctrl.invoiceGroup.currencySymbol || '$'

        ctrl.$onInit = function() {
          if (!ctrl.paymentPlan) return
          setUpfrontPayment()
          setInstallments()
        }

        function setUpfrontPayment() {
          ctrl.upfrontPayment = ctrl.paymentPlan.payments.find(payment => payment.upfront_payment)
        }

        function setInstallments() {
          ctrl.installments = ctrl.paymentPlan.payments.filter(payment => !payment.upfront_payment)
        }
      }
    }
  })
