angular.module('pl-shared')

  .service('GeographicOptions', function(_, DS, apiV2) {
    var GeographicOptions = DS.defineResource({
      name: 'GeographicOptions',
      endpoint: 'geographic_options',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
      actions: {
        countries: {
          method: 'GET',
          response: apiV2.deserialize
        },
        subregions: {
          method: 'GET',
          response: sortByLabel
        }
      }
    })

    GeographicOptions.all_countries = function() {
      return GeographicOptions.countries({ params: { include_all: 'true' } })
        .then(apiV2.deserialize)
    }

    return GeographicOptions

    function sortByLabel(resp) {
      return _.sortBy(apiV2.deserialize(resp), 'label')
    }
  })
