angular.module('org-admin')
  .directive('addRecipients', function() {
    return {
      restrict: 'A',
      scope: {},
      bindToController: {
        invoiceGroup: '=',
        cancel: '=',
        confirm: '=',
        dialogOptions: '='
      },
      controllerAs: 'ctrl',
      templateUrl: '/static/org/invoice-groups/add-recipients.html',
      controller: function(_, $timeout, $scope, $log, $debounce, Contact, currentOrg, Alerts) {
        var ctrl = this

        /**
         *  Public Controller Properties/Methods
         */

        ctrl.recipientOptions = []
        ctrl.recipientSelect2Options = {
          data: ctrl.recipientOptions,
          minimumInputLength: 2,
          query: $debounce(function(query) {
            var searchParams = {
              org_id: currentOrg.id,
              search: query.term
            }

            function getResults(data) {
              data = _.reject(data, function(obj) { return _.contains(ctrl.invoiceGroup.persona_ids, obj.id.toString()) })
              query.callback({ results: data })
            }

            Contact.findAll(searchParams)
              .then(getResults)
              .catch($log.error)
          }, 250),
          formatResult: formatPersonaResult,
          formatSelection: formatPersonaSelection
        }


        ctrl.send = function() {
          function sendSuccess() {
            $scope.$emit('invoiceGroup:updateRequest')
            Alerts.success('ADD_RECIPIENTS.send_success')
            ctrl.confirm()
          }

          function sendError(response) {
            var err = response.data && response.data.error || response.data
            var messages = [].concat(err && err.messages || err || [])
            ctrl.failure = { messages: _.filter(messages) }
          }

          return ctrl.invoiceGroup.appendPersonas(_.pluck(ctrl.recipientOptions, 'id'))
            .then(sendSuccess, sendError)
        }

        function formatPersonaResult(persona) {
          return persona.full_name + ' (' + persona.email + ')'
        }

        function formatPersonaSelection(persona) {
          return persona.full_name
        }

      }
    }
  })
