angular.module('pl-shared')

  .directive('dateQuestion', function() {

    return {
      scope: {},
      templateUrl: '/static/shared/components/registration/questions/date-question.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '='
      },
      controller: function(_, $scope, moment, i18ng) {
        var ctrl = this

        ctrl.properties = ctrl.question.properties
        ctrl.model = _.isObject(ctrl.answer) ? ctrl.answer.value : null
        ctrl.inputName = 'form_result_' + ctrl.question.id

        ctrl.dateFormat = 'MM/DD/YYYY'
        ctrl.minDate = moment(ctrl.properties.min).format(ctrl.dateFormat)
        ctrl.maxDate = moment(ctrl.properties.max).format(ctrl.dateFormat)
      }
    }

  })
