angular.module('org-admin')
  .component('discountCode', {
    require: {
      discountForm: '^form'
    },
    bindings: {
      discountCodeDefinition: '=',
      editMode: '='
    },
    templateUrl: '/static/org/discounts/discount-modal/discount-code.component.html',
    controllerAs: 'ctrl',
    controller: function() {
      var ctrl = this

      ctrl.discountCodeDefinition.additional_code_count = '0'
      ctrl.showMaxRedeemed = ctrl.discountCodeDefinition.max_use_count === null ? false : true
      ctrl.numberOfCodesPattern = '^([1-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|1[0-9]{3}|2000)$'
      ctrl.additionalNumberOfCodesPattern = '^([0-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|1[0-9]{3}|2000)$'
      ctrl.customCodePattern = '^([a-zA-Z0-9]{5,20})$'
      setReusableCount()

      ctrl.toggleMaxRedeemed = function() {
        ctrl.showMaxRedeemed = !ctrl.showMaxRedeemed
        switchToMultiUseCode()
        setReusableCount()
        clearMaxUseCount()
      }

      ctrl.codeTypeRequired = function(codeType) {
        return codeType === ctrl.discountCodeDefinition.single_use
      }

      ctrl.onClick = function(key, option) {
        ctrl.discountCodeDefinition[key] = option
        clearInputs()
      }

      ctrl.onChange = function() {
        clearInputs()
      }

      ctrl.displayOutOfStock = function() {
        var additionalCount
        if (ctrl.discountCodeDefinition.single_use) {
          additionalCount = ctrl.discountCodeDefinition.additional_code_count
          var noneAvailable = ctrl.discountCodeDefinition.available_count === 0
          return noneAvailable && (additionalCount == 0 || (isNaN(parseInt(additionalCount)) || isNaN(Number(additionalCount))))
        }
        else {
          additionalCount = ctrl.discountCodeDefinition.additional_reusable_count
          return ctrl.showMaxRedeemed && (additionalCount == 0 || (isNaN(parseInt(additionalCount)) || isNaN(Number(additionalCount))))
        }
      }

      function setReusableCount() {
        if (ctrl.showMaxRedeemed) {
          ctrl.discountCodeDefinition.additional_reusable_count = ctrl.discountCodeDefinition.available_count
        }
        else {
          ctrl.discountCodeDefinition.additional_reusable_count = null
        }
      }

      function clearMaxUseCount() {
        if (!ctrl.showMaxRedeemed) ctrl.discountCodeDefinition.max_use_count = null
      }

      function switchToMultiUseCode() {
        if (ctrl.showMaxRedeemed) ctrl.discountCodeDefinition.single_use = false
      }

      function clearInputs() {
        if (ctrl.discountCodeDefinition.single_use) {
          ctrl.discountCodeDefinition.supplied_code = null
          ctrl.showMaxRedeemed = false
          ctrl.discountCodeDefinition.max_use_count = null
          ctrl.discountForm.customCode.$setPristine(true)
        }
        if (!ctrl.discountCodeDefinition.single_use) {
          ctrl.discountCodeDefinition.code_count = null
          ctrl.discountForm.numberOfCodes.$setPristine(true)
        }
      }
    }
  })
