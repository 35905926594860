angular.module('org-admin')

  .directive('programListingDateRangeQuestion', function() {

    return {
      scope: {},
      templateUrl: '/static/org/program-listings/questions/program-listing-date-range-question.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=?',
        answer: '='
      },
      controller: function(_, i18ng, $scope, $element, moment, ProgramListing) {
        var FIELDS = {
          start_date: {},
          end_date: {},
          approximate_dates: {}
        }

        var ctrl = this
        var question = ctrl.question || {}
        var properties = ctrl.properties = question.properties || {}
        var fields = ctrl.fields = properties.fields = FIELDS // this exposes the fields for rf-form submit (must be on question.properties)
        var parseFormat = 'YYYY-MM-DD'
        var monthAbbrs = i18ng.t('PROGRAM_LISTING_DATE_RANGE.MONTH_ABBRS', { returnObjectTrees: true })
        var monthLabels = i18ng.t('PROGRAM_LISTING_DATE_RANGE.MONTHS', { returnObjectTrees: true })
        var startLabelExact = i18ng.t('PROGRAM_LISTING_FORMS.LABELS.start_date')
        var startLabelApprox = i18ng.t('PROGRAM_LISTING_FORMS.LABELS.start_date_approx')
        var endLabelExact = i18ng.t('PROGRAM_LISTING_FORMS.LABELS.end_date')
        var endLabelApprox = i18ng.t('PROGRAM_LISTING_FORMS.LABELS.end_date_approx')
        var placeholders = i18ng.t('PROGRAM_LISTING_DATE_RANGE.PLACEHOLDERS', { returnObjectTrees: true })
        var _monthOptions
        var tempData = {}

        // NOTE: This property allows to set all fields on the directive as different fields on
        // the data object. Check rf-form-directive.js (submitForm method) for more information.
        _.extend(properties, { flatten_field_data: true })

        ctrl.validators = ProgramListing.validators
        ctrl.approximateDates = approximateDates
        ctrl.clear = clear
        ctrl.getterSetters = {
          start_date: {
            month: getterSetter('start_date', 'month'),
            date: getterSetter('start_date', 'date'),
            year: getterSetter('start_date', 'year'),
            monthYear: getterSetter('start_date', 'monthYear')
          },
          end_date: {
            month: getterSetter('end_date', 'month'),
            date: getterSetter('end_date', 'date'),
            year: getterSetter('end_date', 'year'),
            monthYear: getterSetter('end_date', 'monthYear')
          }
        }

        setLabels()

        // Keep the passed in answer synced if it changes, or parse the model once
        if (ctrl.answer) $scope.$watchCollection('ctrl.answer.value', updateAnswer)
        else updateModel()

        function updateAnswer() {
          ctrl.model = ctrl.answer && angular.copy(ctrl.answer.value)
          updateModel()
        }

        function updateModel() {
          ctrl.model = ctrl.model || {}
          parseModel('start_date')
          parseModel('end_date')
          updateOptions('start_date')
          updateOptions('end_date')
        }

        function updateOptions(field) {
          var data = getData(field)
          data.months = monthOptions()
          data.dates = dateOptions(field)
          data.years = yearOptions(field)
          data.monthYears = monthYearOptions(field)
          // reset date when changing month/year
          var lastDate = _.last(data.dates).value
          if (data.date > lastDate) data.date = lastDate
        }

        function getData(field) {
          var prop = field + '_data'
          return ctrl[prop] = ctrl[prop] || {}
        }

        function parseModel(field) {
          var m = moment(ctrl.model[field], parseFormat)
          var data = getData(field)
          data.month = formatMoment(m, 'MM', '')
          data.date = formatMoment(m, 'DD', '')
          data.year = formatMoment(m, 'YYYY', '')
        }

        function formatInput(field) {
          var formCtrl = $scope.form
          var data = getData(field)
          var str = [data.year, data.month, data.date].join('-')
          var format = 'YYYY-MM-DD'
          var completeDate = str.length === format.length
          ctrl.model[field] = completeDate ? formatMoment(moment(str, format), parseFormat, null) : null
          if (completeDate && formCtrl) formCtrl.validateFields(field) // dirty touch and validate hidden field
        }

        function monthOptions() {
          if (_monthOptions) return _monthOptions
          var options = monthAbbrs.map(function(label, i) {
            var str = (i + 1).toString()
            if (str.length === 1) str = '0' + str
            return { label: label, value: str }
          })
          options.unshift({ label: placeholders.month, value: '' })
          return _monthOptions = options
        }

        function dateOptions(field) {
          var data = getData(field)
          var options = [{ label: placeholders.date, value: '' }]
          var max = daysInMonth(data.month, data.year)

          for (var d = 1; d <= max; d++) {
            options.push({ label: d, value: zeroPad(d) })
          }

          return options
        }

        function yearOptions(field) {
          var options = [{ label: placeholders.year, value: '' }]
          var year = getData(field).year
          var yearInt = parseInt(year, 10)
          var currentYear = parseInt(moment().format('YYYY'), 10)
          var max = currentYear + 2
          var included

          for (var y = currentYear; y <= max; y++) {
            options.push({ label: y.toString(), value: y })
            if (y === yearInt) included = true
          }

          // Add the stored year value first if not normally in range
          if (year && !included) options.unshift({ label: year, value: yearInt })

          return options
        }

        function monthYearOptions(field) {
          var options = [{ label: placeholders.month_and_year, value: monthYearOptionValue('', '') }]
          var now = moment()
          var data = getData(field)
          var year = data.year
          var yearInt = parseInt(year, 10)
          var y = parseInt(now.format('YYYY'), 10)
          var month = data.month
          var monthInt = parseInt(month, 10)
          var m = parseInt(now.format('MM'), 10)
          var max = 24 // 2 years
          var included

          for (var i = 0; i < max; i++) {
            options.push(monthYearOption(m, y))
            if (y === yearInt && m === monthInt) included = true
            if (m === 12) {
              m = 1
              y++
            }
            else {
              m++
            }
          }

          // Add the stored month/year values first if not normally in range
          if (month && year && !included) options.unshift(monthYearOption(monthInt, year))

          return options
        }

        function monthYearOption(m, y) {
          return {
            label: monthLabels[m - 1] + ' ' + y,
            value: monthYearOptionValue(m, y)
          }
        }

        function monthYearOptionValue(m, y) {
          return zeroPad(m) + ':' + y
        }

        function getterSetter(field, prop) {
          var isMonthYear = prop === 'monthYear'
          return function(val) {
            var d = getData(field)

            if (!d) return
            if (!arguments.length) return isMonthYear ? monthYearOptionValue(d.month, d.year) : d[prop]

            if (isMonthYear) {
              var parts = val.split(':')
              d.month = parts[0]
              d.year = parts[1]
            }
            else {
              d[prop] = val
            }

            delete tempData[field] // clear temp data whenever a field changes

            updateOptions(field)
            if (approximateDates()) syncApproximateDates()
            formatInput(field)
          }
        }

        function setLabels() {
          var approx = approximateDates()
          ctrl.labels = {
            start_date: approx ? startLabelApprox : startLabelExact,
            end_date: approx ? endLabelApprox : endLabelExact
          }
        }

        function clear() {
          clearField('start_date')
          clearField('end_date')
        }

        function clearField(field) {
          var data = getData(field)
          if (!data) return
          data.month = data.date = data.year = ''
          formatInput(field)
          updateOptions(field)
        }

        function approximateDates(bool) {
          if (!arguments.length) return ctrl.model.approximate_dates
          ctrl.model.approximate_dates = bool
          if (bool) setApproximateDates()
          else setExactDates()
          setLabels()
        }

        function setApproximateDates() {
          tempData.start_date_data = _.clone(getData('start_date'))
          tempData.end_date_data = _.clone(getData('end_date'))
          syncApproximateDates()
          formatInput('start_date')
          formatInput('end_date')
        }

        function syncApproximateDates() {
          var s = getData('start_date')
          var e = getData('end_date')
          if (!s.month || !s.year) s.month = s.year = ''
          if (!e.month || !e.year) e.month = e.year = ''
          s.date = s.month ? '01' : ''
          e.date = e.month ? _.last(e.dates).value : ''
        }

        function setExactDates() {
          if (tempData.start_date_data) ctrl.start_date_data = tempData.start_date_data
          if (tempData.end_date_data) ctrl.end_date_data = tempData.end_date_data
          tempData = {}
        }

        function formatMoment(m, format, val) {
          return m.isValid() ? m.format(format) : val
        }

        function daysInMonth(month, year) {
          if (year) return moment(month + '/' + year, 'MM/YYYY').daysInMonth()
          if (month === '02') return 29
          if (['05', '06', '09', '11'].indexOf(month) !== -1) return 30
          return 31
        }

        function zeroPad(n) {
          if (n === undefined) return ''
          var str = n.toString()
          if (str.length === 1) str = '0' + str
          return str
        }

      }
    }

  })
