angular.module('pl-shared')
  .service('Payment', function(_, $q, DS, i18ng, localRelations, nestedResource, apiV2) {
    var Payment = DS.defineResource({
      name: 'payments',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
      relations: {
        belongsTo: {
          invoices: {
            localKey: 'sale_id',
            localField: 'invoice'
          }
        }
      },
      computed: {
        payment_status_display: [
          'status',
          function() {
            return i18ng.t('PAYMENT_STATUS.' + this.status)
          }
        ],
        method_info_display: [
          'status',
          function() {
            var key = (this.status == 'paid_offline') ? 'offline' : 'account'
            return i18ng.t('PAYMENT_DISPLAY.' + key, {
              acct_num: this.acct_num,
              acct_type: this.acct_type_display
            })
          }
        ],
        payment_info_display: [
          'method_info_display', 'name',
          function() {
            if (this.name && this.status != 'paid_offline') {
              return i18ng.t('PAYMENT_DISPLAY.payment', {
                info: this.method_info_display,
                name: this.name,
                payment_status: this.payment_status_display
              })
            }
            else {
              return this.method_info_display
            }
          }
        ]
      },
      actions: {
        activity: {
          method: 'GET',
        },
        adjustment_counts: {
          method: 'GET',
        },
        refund: {
          method: 'POST',
          pathname: 'refunds'
        }
      },
      methods: {
        refund: function(attrs) {
          attrs = _.extend(_.pick(this, 'amount'), attrs)
          return Payment.refund(this.id, { data: attrs })
            .then(apiV2.deserialize)
        }
      }
    })

    Payment.adjustmentCountsByDueDate = function(saleId) {
      return Payment.adjustment_counts(saleId)
        .then(function(response) {
          return _.sortBy(apiV2.deserialize(response), 'due_date')
        })
    }

    return Payment
  })

  .run(function(Payment) {})
