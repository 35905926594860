angular.module('org-admin')

  .service('StatNginSports', function(DS, apiV2) {

    return DS.defineResource({
      name: 'statNginSports',
      endpoint: '/sports',
      basePath: DS.adapters.stat_ngin_api.defaults.basePath, // workaround for js-data-angular fallbacks
      httpConfig: DS.adapters.stat_ngin_api.defaults.httpConfig, // workaround for js-data-angular fallbacks
      defaultAdapter: 'stat_ngin_api'
    })

  })
