angular.module('org-admin')

  .directive('saleList', function() {

    return {
      scope: {},
      bindToController: {
        paymentTermId: '='
      },
      templateUrl: '/static/org/sales/sale-list.html',
      controllerAs: 'ctrl',
      controller: function(
        $scope,
        debounceCallback,
        Sale,
        currentOrg,
        Search,
        setAs
      ) {

        var ctrl = this

        ctrl.loading = true
        ctrl.params = { organization_id: currentOrg.id, payment_term_id: ctrl.paymentTermId, sold: true }
        ctrl.options = { result: ctrl.sales, pagination: {} }
        ctrl.sortColumns = { 'order[due_date]': 'desc' }
        ctrl.searchDirtied = false


        ctrl.displaySearchEmptyState = function() {
          return ctrl.search.term || ctrl.sales.loading
        }
        ctrl.displayNoInvoicesEmptyState = function() {
          return !ctrl.params.search_term && !ctrl.searchDirtied
        }
        ctrl.displaySearchBar = function() {
          return ctrl.sales.length || ctrl.sales.loading || ctrl.search.term
        }

        ctrl.displayDiscounts = function() {
          return _.any(ctrl.sales, { discounted: true })
        }

        ctrl.displayRefunds = function() {
          return _.any(ctrl.sales, { refunded: true })
        }

        ctrl.displayCredits = function() {
          return _.any(ctrl.sales, { credited: true })
        }

        ctrl.saleGoToContext = function(saleType) {
          if (saleTypeInvoice(saleType)) {
            return 'app.billing.invoiceGroups.bills.detail'
          }
          else if (saleTypeOrder(saleType)) {
            return 'app.billing.orders.detail'
          }
        }

        ctrl.saleContextParams = function(sale) {
          if (saleTypeInvoice(sale.type)) {
            return { invoiceId: sale.id }
          }
          else if (saleTypeOrder(sale.type)) {
            return { orderId: sale.id }
          }
        }

        function saleTypeOrder(saleType) {
          return saleType === 'Order'
        }

        function saleTypeInvoice(saleType) {
          return saleType === 'Invoice'
        }

        function loadSales() {
          if (ctrl.sales) ctrl.sales.loading = true
          // Due to restricted permissions on sale, we had to call invoices and
          // we don't want to change all the naming to invoices because it will make the switch over to Sale.findAll in the future
          var findAll = debounceCallback(Sale.findAll)
          return findAll(ctrl.params, ctrl.options)
            .then(setAs(ctrl, 'sales'))
            .finally(setAs(ctrl, 'loading', false))
        }

        ctrl.search = Search.create({
          update: function(searchTerm) {
            ctrl.searchDirtied = true
            ctrl.params.search_fields = 'sale_number,sales.user_full_name'
            ctrl.params.search_term = String(searchTerm) || undefined
          }
        })

        $scope.$watch('ctrl.sortColumns', function(newSort, oldSort) {
          _.each(oldSort, function(dir, col) {
            delete ctrl.params[col]
          })
          _.extend(ctrl.params, newSort)
        })
        $scope.$watch('ctrl.params', loadSales, true)
      }
    }
  })
