angular.module('org-admin')
  .factory('OffersService', function(userSettings, currentUser, currentOrg, OffersData, $timeout) {

    var OFFERS_PREFIX = 'hide-offer-'
    var OFFERS_INTERVAL = 1000 * 60 * 10 // 10 minutes
    var offersTimeout
    var offers
    var orgSportKeys = []

    function parseOffers() {
      var offer, starts, ends
      var now = Date.now()
      var allOffers = OffersData.slice()

      orgSportKeys = _.map(currentOrg.sports, 'key') // set this at run time when data is loaded
      offers.length = 0 // reset array in place so we can rebuild it

      while ((offer = allOffers.shift())) {
        starts = offer.starts
        ends = offer.ends

        if (starts) starts = new Date(starts[0], starts[1], starts[2], 0, 0, 0) // start of day
        if (ends) ends = new Date(ends[0], ends[1], ends[2], 23, 59, 59) // end of day

        if (starts && starts > now) continue
        if (ends && ends < now) continue
        if (offer_hidden_from_user(offer)) continue

        var url = parseUrl(offer.url)

        offers.push(angular.merge({}, offer, {
          hide: hideOffer.bind(null, offer),
          url: url,
          target: target(url)
        }))
      }

      $timeout.cancel(offersTimeout)
      offersTimeout = $timeout(parseOffers, OFFERS_INTERVAL)

      return offers
    }

    function parseUrl(url) {
      if (!url || url[0] !== '/') return url
      return '/org/' + currentOrg.id + url
    }

    function target(url) {
      return (url && url[0] !== '/') ? '_blank' : '_self'
    }

    function hideOffer(offer) {
      userSettings.set(OFFERS_PREFIX + offer.key, true)
      parseOffers()
    }

    function offer_hidden_from_user(offer) {
      if (userSettings.get(OFFERS_PREFIX + offer.key)) return true
      if (offer.orgRoles && !_.some(offer.orgRoles, userHasOrgRole)) return true
      if (offer.orgSports && !_.some(offer.orgSports, orgHasSport)) return true
    }

    function userHasOrgRole(role) {
      return currentUser.hasRoleForResource(role, 'Organization', currentOrg.id)
    }

    function orgHasSport(sport) {
      return orgSportKeys.indexOf(sport) !== -1
    }

    function get() {
      if (!offers) {
        offers = []
        parseOffers()
      }
      return offers
    }

    return {
      get: get
    }
  })
