angular.module('org-admin')
  .component('governingSeasons', {
    templateUrl: '/static/org/governing-seasons/governing-seasons.html',
    controller: function(_, $rootScope, $routeParams, appPermission, currentOrg, renderContext, showError) {
      if (!appPermission.governingSeasons.visible) return showError('404')

      var CONTEXT_AFFILIATE = 'affiliate'

      var ctrl = this
      ctrl.hasPermission = false
      ctrl.showEligibilityPrompt = appPermission.governingSeasons.showEligibilityPrompt

      ctrl.$onInit = function() {
        checkPermissions()
        if (ctrl.isContextAffiliate()) {
          $rootScope.constructor.prototype.affiliateOrgId = parseInt($routeParams.affiliateOrgId)
        }
      }

      ctrl.isContextAffiliate = function() {
        return renderContext.get('governingSeasons.next') == CONTEXT_AFFILIATE
      }

      function checkPermissions() {
        var hasRootOrganizations = !_.isEmpty(currentOrg.root_organizations)
        if (ctrl.isContextAffiliate() && !hasRootOrganizations) return showError('404')
        else if (!ctrl.isContextAffiliate() && hasRootOrganizations)  return showError('404')
        else ctrl.hasPermission = !appPermission.governingSeasons.showEligibilityPrompt
      }
    }
  })
