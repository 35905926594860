angular.module('org-admin')
  .component('rosterPersonaDetail', {
    bindings: {
      cancel: '<',
      persona: '<',
      surveyId: '<?'
    },
    templateUrl: '/static/org/roster-management/roster-persona-detail.html',
    controller: function(_, $log, RosteringPersonas, RosteringState, setWhile, setAs) {
      var ctrl = this
      ctrl.state = RosteringState

      ctrl.$onInit = setWhile(ctrl, 'rosteringPersonaLoading', function() {
        return RosteringPersonas.find(ctrl.persona.id, { params: { check_stats: 1 } })
          .then(setAs(ctrl, 'rosteringPersona'))
          .then(function(rps) {
            ctrl.rosterPlayers = _.filter(rps.roster_players, function(rp) {
              return rp.type === ctrl.state.type && (!+ctrl.surveyId || rp.survey_id === +ctrl.surveyId)
            })
          })
      })

      ctrl.teamInstance = function(rosterPlayer) {
        var teamInstance = _.first(rosterPlayer.team_instances)
        if (!teamInstance) return $log.error('Team instance not found for roster player ' + rosterPlayer.id)
        return teamInstance
      }

      ctrl.rosterPlayerPositions = function(rosterPlayer) {
        return _.isEmpty(rosterPlayer.positions) ? '' : '(' + _.map(rosterPlayer.positions, 'name').join(', ') + ')'
      }

    }

  })
