angular.module('pl-shared')

  .directive('sortColumns', function() {
    return {
      restrict: 'A',
      scope: {
        sortColumns: '='
      },
      bindToController: true,
      controllerAs: 'ctrl',
      controller: function() {}
    }
  })

  .directive('sortColumn', function(_) {
    var sortIcons = {
      asc: 'arrow-up',
      desc: 'arrow-down',
      undefined: 'arrow-up-down'
    }

    return {
      restrict: 'A',
      require: '^sortColumns',
      scope: {
        sortColumn: '@'
      },
      transclude: {
        'subHeader': '?p'
      },
      template: '<ng-transclude></ng-transclude> <svg-img ng-if="sortColumn" class="prefix + \'-sort-icon\'" use="sortIcons[sortDir]"></svg-img><p ng-transclude="subHeader"></p>',
      controller: function($scope, $element) {
        $scope.prefix = $element.closest('.sn-table').length ? 'sn' : 'pl'
        $scope.sortIcons = sortIcons
      },
      link: function($scope, $element, $attrs, sortColumnsCtrl) {

        if (!$scope.sortColumn) return

        function sort(newDir) {
          if (arguments.length > 0) sortColumnsCtrl.sortColumns[$scope.sortColumn] = newDir
          return sortColumnsCtrl.sortColumns[$scope.sortColumn]
        }

        $element.addClass($scope.prefix + '-sortable-column')

        $element.on('click', function(event) {
          var sortDir = sort() === 'asc' ? 'desc' : 'asc'
          sortColumnsCtrl.sortColumns = {} // add `if (!event.shiftKey)` if we do multi-sorting later
          sort(sortDir)
          if ($scope.$root && !$scope.$root.$$phase) $scope.$root.$digest() // kick off sortColumns watchers
        })

        $scope.$watch(_.ary(sort, 0), function(sortDir) {
          $element.removeClass($scope.prefix + '-sortable-active')
          if (sortDir) $element.addClass($scope.prefix + '-sortable-active')
          $scope.sortDir = sortDir
        })

      }
    }
  })
