angular.module('org-admin')
  .component('discountDetail', {
    templateUrl: '/static/org/discounts/discount-detail.component.html',
    controllerAs: 'ctrl',
    controller: function(
      $routeParams,
      $scope,
      currentOrg,
      DiscountCodeDefinitions,
      PusherService,
      routeTitle,
      setAs,
      setWhile
    ) {

      var ctrl = this

      $scope.$watch(ctrl.discountCodeDefinitionId, setWhile(ctrl, 'loading', getDiscount))
      $scope.$watch(ctrl.discountCodeDefinitionId, pusherSubscribe)

      ctrl.discountCodeDefinitionId = function() {
        return $routeParams.discountId
      }

      function getDiscount() {
        var id = ctrl.discountCodeDefinitionId()
        if (!id) return
        return DiscountCodeDefinitions.statistics(id)
          .then(function(response) {
            ctrl.discountCodeDefinition = response.data.result
          })
          .then(function() {
            routeTitle.setParams({
              name: ctrl.discountCodeDefinition.name
            })
          })
      }

      function pusherSubscribe() {
        PusherService.subscribe('channel_clare_organization_' + currentOrg.id)
        PusherService.bind('event_code_definition_updated_' + ctrl.discountCodeDefinitionId(), getDiscount)
      }
    }
  })
