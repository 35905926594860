angular.module('org-admin')
  .component('discountAmount', {
    require: {
      discountForm: '^form'
    },
    bindings: {
      discountCodeDefinition: '=',
      currencySymbol: '<',
      editMode: '='
    },
    templateUrl: '/static/org/discounts/discount-modal/discount-amount.component.html',
    controllerAs: 'ctrl',
    controller: function($filter) {
      var ctrl = this
      ctrl.rendered = false
      ctrl.showMaxAmount = false

      ctrl.$postLink = function() {
        ctrl.rendered = true
      }

      ctrl.toggleMaxAmount = function() {
        ctrl.showMaxAmount = !ctrl.showMaxAmount
        switchDiscountTypeToPercentage()
        clearMaxAmount()
      }

      ctrl.getMinimumDollarValidation = function(amountType) {
        if (ctrl.amountTypeIsRequired(amountType)) return 0
        else return -1
      }

      ctrl.getPercentRangeValidation = function(amountType) {
        var rangePattern = '^([1-9][0-9]?|100)$'
        if (ctrl.amountTypeIsRequired(amountType)) return rangePattern
        else return
      }

      ctrl.amountTypeIsRequired = function(amountType) {
        return  amountType === ctrl.discountCodeDefinition.discount_type
      }

      ctrl.onClick = function(key, option) {
        ctrl.discountCodeDefinition[key] = option
        clearInputs()
      }

      ctrl.onChange = function() {
        clearInputs()
      }

      function clearInputs() {
        if (ctrl.discountCodeDefinition.discount_type !== 'amount') {
          ctrl.discountCodeDefinition.dollarAmount = null
          ctrl.discountForm.dollar_amount.$setPristine(true)
        }
        if (ctrl.discountCodeDefinition.discount_type !== 'percentage') {
          ctrl.discountCodeDefinition.percentageAmount = null
          ctrl.showMaxAmount = false
          ctrl.discountCodeDefinition.maximum_discount_value = null
          ctrl.discountForm.percentage_amount.$setPristine(true)
        }
      }

      ctrl.getDisplayValue = function() {
        if (ctrl.discountCodeDefinition.discount_type === 'percentage') {
          return parseInt(ctrl.discountCodeDefinition.discount_value) + '%'
        }
        else {
          return $filter('displayCurrency')(ctrl.discountCodeDefinition.discount_value, ctrl.discountCodeDefinition.currency_symbol)
        }
      }

      function clearMaxAmount() {
        if (!ctrl.showMaxAmount) ctrl.discountCodeDefinition.maximum_discount_value = null
      }

      function switchDiscountTypeToPercentage() {
        if (ctrl.showMaxAmount) ctrl.discountCodeDefinition.discount_type = 'percentage'
      }
    }
  })
