angular.module('pl-shared')

  .service('Answer', function(DS, fileHelper) {
    return DS.defineResource({
      name: 'answers',
      basePath: DS.adapters.ngin_v2.defaults.basePath,
      httpConfig: DS.adapters.ngin_v2.defaults.httpConfig,
      defaultAdapter: 'ngin_v2',
      relations: {
        belongsTo: {
          form_results: {
            localField: 'form_result',
            localKey: 'form_result_id'
          },
          question_elements: {
            localField: 'question_element',
            localKey: 'question_element_id'
          }
        }
      },
      computed: {
        directiveName: [
          'value_type',
          function(valueType) {
            return valueType.split('::').pop()
          }
        ],
        questionLabel: [
          'report_name',
          function(reportName) {
            return reportName.replace(/_cp(\d+)$/, '')
          }
        ],
        isImage: [function() {
          return fileHelper.isImage(this.value)
        }],
        base64Data: [function() {
          return fileHelper.base64Data(this.value)
        }]
      }
    })
  })
  .run(function(Answer) {})
