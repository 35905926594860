angular.module('org-admin')
  .service('LegacySiteService', function(_, appPermission) {
    return {
      getUrl: getUrl
    }

    function getUrl(path) {
      var siteCheck = _.get(appPermission, 'checks.sites') || {}
      var websiteUrl = siteCheck.provisioned && _.get(siteCheck, 'sites[0].url') || ''
      if (websiteUrl) {
        return websiteUrl + path
      }
    }
  })
