angular.module('org-admin')
  .component('payoutDetailAlerts', {
    scope: {},
    bindings: {
      payoutStatus: '<',
      fundsForwarded: '<',
      forwardedTo: '<'
    },
    templateUrl: '/static/org/reports/payout-detail-alerts.component.html',
    controllerAs: 'ctrl',
    controller: function() {

      var ctrl = this

      ctrl.$onInit = function() {
        ctrl.payoutFailed = ctrl.payoutStatus == 'failed'
        ctrl.payoutForwarded = ctrl.payoutStatus == 'forwarded'
        ctrl.payoutHasFundsForwarded = ctrl.fundsForwarded && ctrl.fundsForwarded.length
        ctrl.fundsForwardedToOne = ctrl.payoutHasFundsForwarded && ctrl.fundsForwarded.length == 1
        ctrl.fundsForwardedToMany = ctrl.payoutHasFundsForwarded && ctrl.fundsForwarded.length > 1
      }
    }
  })
