angular.module('pl-shared')
  .factory('Team', function(_, apiV2, DS, paginate) {
    var Team = DS.defineResource({
      name: 'teams',
      endpoint: '/v3/teams',
      basePath: DS.adapters.se_api.defaults.basePath, // workaround for js-data-angular fallbacks
      httpConfig: DS.adapters.se_api.defaults.httpConfig, // workaround for js-data-angular fallbacks
      defaultAdapter: 'se_api',
      actions: {
        org_rollup: {
          method: 'GET',
          response: apiV2.deserialize
        }
      }
    })

    return paginate(Team)
  })
