angular.module('org-admin')
  .directive('recipientsForm', function() {

    var CONCURRENT_PERSONA_REQUESTS = 5
    var CONCURRENT_GROUP_REQUESTS = 5
    var GROUP_REQUEST_LIMIT = 25

    return {
      scope: {},
      bindToController: {
        invoiceGroup: '=',
        groupPersonas: '<',
        originalRecipients: '<recipients',
        showStaticRecipients: '<'
      },
      controllerAs: 'ctrl',
      templateUrl: '/static/org/recipients-form/recipients-form.html',
      controller: function(
        $q,
        $debounce,
        $scope,
        $window,
        Contact,
        currentOrg,
        dialog,
        GroupPersona,
        Persona,
        setWhile,
        snAsync
      ) {

        var ctrl = this

        ctrl.addPersonContainerId = 'recipients-form-add-person'
        ctrl.addPersonCallbackObj = { personAdded: addContactSuccess }

        setWhile(ctrl, 'loading', loadRecipients)()
          .then(setRecipientValues)

        ctrl.addContact = function() {
          $window.document.getElementById(ctrl.addPersonContainerId).firstElementChild.open()
        }

        function addContactSuccess(event) {
          if (!event.detail.success) return

          var contacts = [event.detail.result.result]
          _.forEach(contacts, function(contact) {
            ctrl.invoiceGroup.persona_ids.push(contact.id.toString())
          })
          loadRecipients()
            .then(setRecipientValues)
        }

        ctrl.recipientSelect2Options = {
          minimumInputLength: 2,
          ajax: {
            transport: $debounce(function(params, success, failure) {
              Contact.findAll({
                org_id: currentOrg.id,
                search: params.data.term
              }).then(success, failure)
            }, 250),
            processResults: function(contacts) {
              return { results: formatPersonas(contacts) }
            }
          },
          templateResult: function(persona) {
            var text = persona.text
            if (persona.email) text += ' (' + persona.email + ')'
            return text
          }
        }

        ctrl.clearUnbillableRecipientOptions = function() {
          delete $scope.unbillableRecipientOptions
        }


        /**
         * Form options and display
         */

        function formatPersonas(personas) {
          if (!personas) return []
          return personas.map(function(persona) {
            return { id: persona.id, text: persona.full_name, email: persona.email } //select2 requires id and text
          })
        }

        function loadRecipients() {
          ctrl.recipientsLoaded = false
          if (ctrl.groupPersonas && ctrl.groupPersonas.length) {
            return $q.all({
              recipients: loadGroupRecipients(),
              groups: loadIncompleteGroups()
            })
              .then(checkForUnbillableRecipients)
          }
          else if (_.any(ctrl.invoiceGroup.persona_ids)) {
            return snAsync.mapLimit(ctrl.invoiceGroup.persona_ids, CONCURRENT_PERSONA_REQUESTS, function(id) {
              return Persona.find(id, { params: { org_id: currentOrg.id } })
            })
          }
          else {
            return $q.when(ctrl.originalRecipients)
              .then(removeContactsWithoutEmail(ctrl.originalRecipients))
          }
        }

        function setRecipientValues(personas) {
          ctrl.invoiceGroup.persona_ids = _.map(personas, 'id')
          ctrl.staticMessageRecipients = _.map(personas, 'full_name').join(', ')
          ctrl.recipientOptions = formatPersonas(personas)
          ctrl.recipientSelect2Options.data = ctrl.recipientOptions
          ctrl.recipientsLoaded = true
        }


        /**
         * Group loading and filtering
         */

        function loadGroupRecipients() {
          return snAsync.mapLimit(ctrl.groupPersonas, 2, function(groupPersona) {
            var params = {
              org_id: currentOrg.id,
              group_persona_id: groupPersona.id,
              per_page: 100
            }
            return Contact.findAll(params, { load: 'all' })
          })
            .then(_.flatten)
        }

        function loadIncompleteGroups() {
          var groups = ctrl.groupPersonas
          var incomplete = incompleteGroups(groups)
          if (!incomplete.length) return groups
          var complete = completeGroups(groups)
          return snAsync.mapLimit(
            chunkedGroupParams(incomplete),
            CONCURRENT_GROUP_REQUESTS,
            GroupPersona.findAll
          )
            .then(function(loaded) {
              return complete.concat(_.flatten(loaded))
            })
        }

        function chunkedGroupParams(groups) {
          var chunks = []
          while (groups.length) {
            var groupSet = groups.splice(0, 25)
            chunks.push({
              per_page: GROUP_REQUEST_LIMIT,
              org_id: currentOrg.id,
              ids: _.pluck(groupSet, 'id').join(',')
            })
          }
          return chunks
        }

        function completeGroups(groups) {
          return _.filter(groups, function(group) { return 'members' in group })
        }

        function incompleteGroups(groups) {
          return _.filter(groups, function(group) { return 'members' in group === false })
        }


        /**
         * Invalid checks
         */

        function checkForUnbillableRecipients(data) {
          var unbillableRecipientCount = getUnbillableRecipientCount(data.groups, data.recipients)
          if (unbillableRecipientCount) {
            $scope.unbillableRecipientOptions = {
              count: unbillableRecipientCount,
              group_count: data.groups.length
            }
          }
          return data.recipients
        }

        function getUnbillableRecipientCount(groups, personas) {
          return Math.max(_.sum(_.pluck(groups, 'members')) - personas.length, 0)
        }

        function removeContactsWithoutEmail(contacts) {
          if (!contacts) return
          var billableContacts = _.filter(contacts, 'se_profile_email')
          var unbillableRecipientCount = contacts.length - billableContacts.length
          if (unbillableRecipientCount) {
            $scope.unbillableRecipientOptions = {
              count: unbillableRecipientCount,
              group_count: 1
            }
          }
          return billableContacts
        }

      }
    }

  })
