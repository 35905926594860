// NOTE: main permission logic for the org app was moved to ./services/org-app-permissions-service.js
// initially to enable sharing of these permissions in ACP left-nav, but that is no longer using it


var PROVISIONABLE = ['contacts', 'powerPay']

angular.module('org-admin')

  .service('appPermission', function(
    _,
    orgAppPermissions,
    showError,
    HqMenu,
    $q,
    $routeParams,
  ) {

    var promise
    var orgId
    var currentRoute

    var api = {
      assignPermissions: assignPermissions,
      checkPermissions: checkPermissions,
      reload: reload,
    }

    // this is a test helper useful when stubbing permissions
    function reload() {
      assignPermissions(orgAppPermissions.build())
      checkAppState()
    }

    function assignPermissions(perms) {
      _.assign(api, perms)
    }

    // HELPER METHODS
    function checkPermissions(route) {
      if (route.appPermission === 'oauthCallback') {
        setAppState('oauthCallback')
        return
      }

      if (!route.params.currentOrgId) {
        setAppState('loading')
        return
      }
      else if (orgId !== route.params.currentOrgId) {
        setAppState('loading')
        promise = orgAppPermissions.init(route.params.currentOrgId)
        orgId = route.params.currentOrgId
        checkHqAccess()
      }
      else {
        promise = $q.resolve(orgAppPermissions.build())
      }

      currentRoute = route
      promise.then(function(perms) {
        assignPermissions(perms)
        checkAppState()
      })
    }

    function checkAppState() {
      if (!currentRoute) return

      var toolName = currentRoute.appPermission
      var tool = api[toolName]
      if (!tool || tool.usable) setAppState('ready')
      else if (tool.visible && _.contains(PROVISIONABLE, toolName)) setAppState('provisioning')
      else setErrorState('403', toolName)
    }

    function setAppState(state) {
      api.$ready = api.$loading = api.$provisioning = api.$error = api.$oauthCallback = false
      api['$' + state] = true
    }

    function setErrorState(err, context) {
      setAppState('error')
      showError(err, context)
    }

    function checkHqAccess() {
      if (!$routeParams.currentOrgId) return
      HqMenu.get($routeParams.currentOrgId)
        .catch(showError)
        .finally(() => api.$hqAccess = true)
    }

    return api

  })

  .run(function($rootScope, appPermission) {
    $rootScope.constructor.prototype.appPermission = appPermission
  })
