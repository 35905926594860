angular.module('org-admin')
  .component('editSmartGroup', {
    bindings: {
      cancel: '<',
      confirm: '<',
      group: '='
    },
    controllerAs: '$ctrl',
    templateUrl: '/static/org/groups/edit-smart-group.html',
    controller: function(SmartGroupPersona, setAs) {
      var $ctrl = this

      $ctrl.failed = false
      $ctrl.groupName = $ctrl.group.name
      $ctrl.updateGroup = updateGroup

      function updateGroup() {
        $ctrl.failed = false

        return SmartGroupPersona.update($ctrl.group.group_id, {
          name: $ctrl.groupName
        })
          .then(setAs($ctrl, 'group.name', $ctrl.groupName))
          .then($ctrl.confirm)
          .catch(setAs($ctrl, 'failed', true))
      }
    }
  })
