angular.module('org-admin')
  .component('filterPanel', {
    bindings: {
      addFilterText: '@',
      addFilterEmptyText: '@',
      appliedFiltersCount: '=?',
      canClearFilters: '=?',
      clearFilters: '=?',
      filters: '<',
      filteredTotal: '<',
      firstFilterText: '@',
      limitToSurveyId: '<?',
      org: '<',
      onClose: '&?',
      onUpdate: '&?',
      readOnly: '=?',
      memberships: '<?',
      useMemberIdSource: '<?'
    },
    templateUrl: '/static/org/filtering/filter-panel.html',
    controllerAs: 'ctrl',
    controller: function filterPanelController($scope, $q, setWhile, setAs) {
      var ctrl = this

      ctrl.model = {
        filters: null
      }

      ctrl.addFilter = addFilter
      ctrl.newAndFilter = newAndFilter
      ctrl.newOrFilter = newOrFilter
      ctrl.removeFilterRule = removeFilterRule
      ctrl.canClose = canClose
      ctrl.canEdit = canEdit
      ctrl.canRemoveFilterRule = canRemoveFilterRule
      ctrl.canClearFilters = canClearFilters
      ctrl.clearFilters = clearFilters
      ctrl.cancel = cancel
      ctrl.update = update

      ctrl.$onInit = $onInit

      function $onInit() {
        $scope.$watch('ctrl.filters', updateModel)
        $scope.$watch('ctrl.model.filters.validCount', updateAppliedFiltersCount)
      }

      /**
       * Creates a new AND filter on the filters object, or shows AND/OR if there are any existing filter rules.
       */
      function addFilter() {
        if (!ctrl.model.filters.rules.length) ctrl.newAndFilter()
        else ctrl.andOrCanBeShown = true
      }

      /**
       * Creates a new AND filter on the filters object.
       */
      function newAndFilter() {
        ctrl.model.filters.addAnd()
        ctrl.andOrCanBeShown = false
      }

      /**
       * Creates a new OR filter on the filters object.
       */
      function newOrFilter() {
        ctrl.model.filters.addOr()
        ctrl.andOrCanBeShown = false
      }

      /**
       * Indicates if the rule can be removed.
       *
       * @return {boolean} - True if there is more than one rule.
       */
      function canRemoveFilterRule() {
        return ctrl.model.filters.rules.length > 1 && !ctrl.readOnly
      }

      /**
       * Removes the provided rule from list. If the rule is the only rule in the collection, closes the panel.
       *
       * @param {object} rule - The rule to remove.
       */
      function removeFilterRule(rule) {
        ctrl.model.filters.remove(rule)
      }

      /**
       * Indicates if the component should show a close button.
       *
       * @return {boolean} - True if the component can close. False otherwise.
       */
      function canClose() {
        return !!ctrl.onClose
      }

      /**
       * Indicates if the component should show edit controls.
       *
       * @return {boolean} - True if the component can show edit controls. False otherwise.
       */
      function canEdit() {
        return !ctrl.readOnly && !!ctrl.onUpdate
      }

      /**
       * Cancels the edit action and resets the model.
       */
      function cancel() {
        ctrl.readOnly = true
        updateModel()
      }

      /**
       * Indicates if the filters can be cleared.
       *
       */
      function canClearFilters() {
        return _.some(ctrl.model.filters.rules, function(rule) { return rule.dataSourceId() })
      }

      /**
       * Clears the filters.
       */
      function clearFilters() {
        ctrl.model.filters.clear()
      }

      /**
       * Saves the current updates to the collection.
       *
       * @returns {Promise} - A promise that is resolved once the collection is saved.
       */
      function update() {
        var result = ctrl.onUpdate({ $filterCollection: ctrl.model.filters })

        return $q.resolve(result)
          .then(setAs(ctrl, 'readOnly', true))
      }

      /**
       * Updates the model with the filter collection.
       */
      function updateModel() {
        if (!ctrl.filters) {
          ctrl.model.filters = null
          return
        }

        //If the consumer provided an update callback, then we want to clone the provided
        //filters so we can revert if the user decided to cancel changes.
        //If the consumer does not provide a callback, then we want to modify the filters
        //directly so the consumer receives the updates.
        ctrl.model.filters = (ctrl.onUpdate) ? ctrl.filters.clone() : ctrl.filters
      }

      function updateAppliedFiltersCount(count) {
        ctrl.appliedFiltersCount = count
      }
    }
  })
