angular.module('org-admin')
  .directive('membershipDefinitionRenew', function() {
    return {
      restrict: 'EA',
      scope: {},
      bindToController: {
        cancel: '=',
        confirm: '=',
        dialogOptions: '=',
        membershipDefinition: '=',
        membershipDefinitions: '=',
        linkableMemDefs: '<'
      },
      templateUrl: '/static/org/memberships/membership-definition-renew.html',
      controllerAs: '$ctrl',
      controller: function(
        _, i18ng, currentOrg,
        MembershipDefinition,
        MembershipDefinitionsCredentialDefinitions,
        MembershipsUtilService,
        CredentialDefinition,
        moment
      ) {
        var $ctrl = this

        $ctrl.failed = false
        $ctrl.membershipDefinition = Object.assign({}, $ctrl.membershipDefinition)
        $ctrl.model = prepareMembershipForRenewal($ctrl.membershipDefinition)
        $ctrl.step = 1
        $ctrl.totalSteps = 3
        $ctrl.showMessageModal = showMessageModal
        $ctrl.showPublishingModal = showPublishingModal
        $ctrl.back = back

        $ctrl.$onInit = function() {
          loadCredentialDefinitions()
        }

        $ctrl.submit = submit
        $ctrl.removeServerMessages = removeServerMessages

        /**
         * Creates the membership definition and closes the dialog if the request is successful.
         */
        function showMessageModal() {
          $ctrl.step = 2
        }

        function showPublishingModal() {
          $ctrl.step = 3
        }

        function back() {
          $ctrl.step -= 1
          if ($ctrl.step < 0) {
            $ctrl.step = 0
          }
        }

        function submit() {
          $ctrl.submitting = true
          $ctrl.failed = false
          $ctrl.serverErrorMessages = null

          var attrs = {
            message: $ctrl.message,
            purchase_open_date: $ctrl.purchase_open_date,
            purchase_open_time: $ctrl.purchase_open_time
          }

          var data = MembershipsUtilService.dataFromModel($ctrl.model, attrs)

          MembershipDefinition.create(data)
            .then(createSuccess)
            .catch(createError)

          function createSuccess(result) {
            $ctrl.submitting = false
            return $ctrl.confirm(result)
          }

          function createError() {
            $ctrl.submitting = false
            $ctrl.failed = true
            $ctrl.serverErrorMessages = _.get(arguments[0], 'data.error.messages') || [i18ng.t('MEMBERSHIPS.ERRORS.create_failed')]
          }
        }

        function loadCredentialDefinitions() {
          CredentialDefinition.forOrgWithTag(currentOrg.id, 'memberships')
            .then(function(result) {
              $ctrl.credentialDefinition = result
              $ctrl.loaded = true
            })
        }

        /**
         * Clears server error messages from the dialog.
         */
        function removeServerMessages() {
          if ($ctrl.serverErrorMessages) {
            $ctrl.serverErrorMessages.length = 0
          }
        }

        /**
         * Prepares the membership definition for use in renewals
         */
        function prepareMembershipForRenewal(membershipDefinition) {
          var memDefDifference = getDateDifference(membershipDefinition.start_date, membershipDefinition.end_date)
          membershipDefinition.start_date = moment(membershipDefinition.end_date).add(1, 'days')
          membershipDefinition.end_date = moment(membershipDefinition.start_date).add(memDefDifference.value, memDefDifference.type)
          membershipDefinition.credential_definition_id = membershipDefinition.credentialDefinitionID
          membershipDefinition.parent_membership_definition_id = membershipDefinition.id
          membershipDefinition.purchase_status = null
          return membershipDefinition
        }

        function getDateDifference(startDate, endDate) {
          var parsedStartDate = moment(startDate)
          var parsedEndDate = moment(endDate)
          var adjustmentOutput = {
            type: undefined,
            value: undefined
          }

          var yearDifference = parsedEndDate.diff(parsedStartDate, 'years', true)
          if (Number.isInteger(yearDifference)) {
            adjustmentOutput.type = 'years'
            adjustmentOutput.value = yearDifference
            return adjustmentOutput
          }
          var monthDifference = parsedEndDate.diff(parsedStartDate, 'months', true)
          if (Number.isInteger(monthDifference)) {
            adjustmentOutput.type = 'months'
            adjustmentOutput.value = monthDifference
            return adjustmentOutput
          }
          var weekDifference = parsedEndDate.diff(parsedStartDate, 'months', true)
          if (Number.isInteger(weekDifference)) {
            adjustmentOutput.type = 'weeks'
            adjustmentOutput.value = weekDifference
            return adjustmentOutput
          }
          var dayDifference = parsedEndDate.diff(parsedStartDate, 'days', true)
          if (Number.isInteger(dayDifference)) {
            adjustmentOutput.type = 'days'
            adjustmentOutput.value = dayDifference
            return adjustmentOutput
          }
          return adjustmentOutput
        }
      }
    }
  })
