angular.module('org-admin')
  .component('selectMembership', {
    templateUrl: '/static/org/buy-memberships/select-membership.html',
    scope: {},
    controllerAs: '$ctrl',
    bindings: {
      purchaseableMemberships: '=',
      contacts: '='
    },

    controller: function(_, currentOrg) {
      var $ctrl = this
      $ctrl.$onInit = $onInit
      // accessors to unit test private methods
      $ctrl._generateOboPayload = generateOboPayload

      function $onInit() {
        generateOboPayload()
      }

      function generateOboPayload() {
        return $ctrl.contacts
          .then(function(data) {
            var contacts = _.filter(_.flatten(data), function(contact) {
              return contact
            })

            $ctrl.oboPayload = {
              membership_data: [{
                membership_type: 'person',
                membership_definition_id: $ctrl.purchaseableMemberships[0].id,
                persona_ids: _.map(contacts, 'id'),
                available_membership_definition_ids: _.map($ctrl.purchaseableMemberships, 'id'),
              }],
              action: 'select-membership',
              abort_redirect_url: window.location.href,
              payment_redirect_params: 'back-office-memberships',
              payment_redirect_id: null,
              affiliation_organization_id: currentOrg.id,
            }
          })
      }
    }
  })
