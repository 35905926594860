angular.module('pl-shared')
  .filter('decodeHtml', function() {
    return function(val) {
      return val ? angular.element('<div>').html(val).text() : ''
    }
  })
  .filter('encodeHtml', function() {
    return function(val) {
      return val ? angular.element('<div>').text(val).html() : ''
    }
  })
  .directive('encodeHtml', function($filter) {
    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        encodeHtml: '='
      },
      link: function($scope, $element, $attrs, ngModel) {
        if (!$scope.encodeHtml) return
        ngModel.$formatters.push($filter('decodeHtml'))
        ngModel.$parsers.push($filter('encodeHtml'))
      }
    }
  })
