angular.module('org-admin')

  .directive('programListingDetail', function() {

    return {
      scope: {},
      bindToController: {},
      templateUrl: '/static/org/program-listings/program-listing-detail.html',
      controllerAs: 'ctrl',
      controller: function(_, $rootScope, $scope, $timeout, renderContext, ProgramListing, setWhile, showError, $routeParams, currentOrg, routeTitle, Alerts, dialog, i18ng, pageViewHandler) {
        var ctrl = this
        var onRouteChange = $rootScope.$on('$routeChangeSuccess', load)
        load()

        ctrl.archive = archive
        ctrl.remove = remove
        ctrl.publish = publish
        ctrl.copy = copy
        ctrl.preview = preview

        function load() {
          if (!renderContext.programListings) return
          var id = parseInt($routeParams.programListingId, 10)
          ctrl.org = currentOrg
          if (id) {
            ctrl.loaded = false
            ProgramListing.find(id)
              .then(loadSuccess)
              .catch(showError)
          }
          else ctrl.loaded = true
        }

        function loadSuccess(programListing) {
          ctrl.loaded = true
          ctrl.programListing = programListing
          ctrl.originator = programListing.owned_externally ? i18ng.t('ORIGINATORS.' + programListing.originator_system) : null
          ctrl.backTo = backToContext()
          ctrl.editMode = programListing.publishedStatus === 'draft'
          routeTitle.setParams({ program_name: programListing.name })
        }

        function remove() {
          dialog.confirm({
            component: 'confirm-dialog',
            scope: $scope,
            attrs: {
              confirmAction: removeConfirmed,
              heading: i18ng.t('PROGRAM_LISTING_DELETE_DRAFT.heading'),
              message: i18ng.t('PROGRAM_LISTING_DELETE_DRAFT.message', { list: ctrl.programListing.name }),
              confirmLabel: i18ng.t('PROGRAM_LISTING_DELETE_DRAFT.confirm_label')
            }
          })

          function removeConfirmed() {
            return ProgramListing.destroy(ctrl.programListing.id)
              .then(removeSuccess, removeError)
          }

          function removeSuccess() {
            $scope.$emit('publishStatusChanged', 'draft')
            renderContext.goto('programListings.drafts')
            Alerts.success('PROGRAM_LISTING_DELETE_DRAFT.success', { list: ctrl.programListing.name })
          }

          function removeError() {
            Alerts.error('PROGRAM_LISTING_DELETE_DRAFT.failed', { list: ctrl.programListing.name })
          }
        }

        function archive() {
          pageViewHandler.fireEvent('Archive', 8)
          dialog.confirm({
            component: 'confirm-dialog',
            scope: $scope,
            attrs: {
              confirmAction: archiveConfirmed,
              heading: i18ng.t('PROGRAM_LISTING_ARCHIVE.heading'),
              message: i18ng.t('PROGRAM_LISTING_ARCHIVE.message', { list: ctrl.programListing.name }),
              confirmLabel: i18ng.t('PROGRAM_LISTING_ARCHIVE.confirm_label')
            }
          })

          function archiveConfirmed() {
            return ctrl.programListing.archive()
              .then(archiveSuccess, archiveError)
          }

          function archiveSuccess(data) {
            ctrl.programListing = null // clears out rf-form
            return $timeout(function() {
              ctrl.loaded = true
              ctrl.programListing = data
              $scope.$emit('publishStatusChanged', 'active', 'archived')
              Alerts.success('PROGRAM_LISTING_ARCHIVE.success', { list: ctrl.programListing.name })
            })
          }

          function archiveError() {
            Alerts.error('PROGRAM_LISTING_ARCHIVE.failed', { list: ctrl.programListing.name })
          }
        }

        function copy() {
          pageViewHandler.fireEvent('MakeACopy', 8)
          ctrl.loaded = false
          return ProgramListing.copy(ctrl.programListing)
            .then(copySuccess, copyError)

          function copySuccess(data) {
            ctrl.programListing = null // clears out rf-form
            return $timeout(function() {
              ctrl.loaded = true
              ctrl.programListing = data
              Alerts.success('PROGRAM_LISTING_COPY.success', { list: ctrl.programListing.name })
              renderContext.goto('programListings.programListing.info', { programListingId: ctrl.programListing.id })
            })
          }

          function copyError() {
            ctrl.loaded = true
            Alerts.error('PROGRAM_LISTING_COPY.failed', { list: ctrl.programListing.name })
          }
        }

        function publish() {
          return ProgramListing.update(ctrl.programListing.id, { searchable: true })
            .then(publishSuccess, publishFailed)

          function publishSuccess(data) {
            $scope.$emit('publishStatusChanged', 'active', 'draft')
            Alerts.success('PROGRAM_LISTING_PUBLISH.success', { list: ctrl.programListing.name })
            renderContext.goto('programListings.active', { programListingId: ctrl.programListing.id })
          }

          function publishFailed() {
            Alerts.error('PROGRAM_LISTING_PUBLISH.failed', { list: ctrl.programListing.name })
          }
        }

        function preview() {
          pageViewHandler.fireEvent('Preview', 8)
          renderContext.goto('programListings.programListing.preview', { programListingId: ctrl.programListing.id })
        }

        function backToContext() {
          var status = ctrl.programListing.publishedStatus
          var route = 'programListings.'
          switch (status) {
            case 'archived': return route + 'archive'
            case 'draft': return route + 'drafts'
            default: return route + 'active'
          }
        }

      }
    }
  })
