angular.module('org-admin')
  .component('filterPanelDataSourceSelect', {
    bindings: {
      filterRule: '<',
      readOnly: '<',
      org: '<',
      limitToSurveyId: '<?',
      memberships: '<?',
      first: '<?',
      useMemberIdSource: '<?'
    },
    templateUrl: '/static/org/filtering/filter-panel-data-source-select.html',
    controller: function(_, $scope) {
      var $ctrl = this

      $ctrl.$onInit = $onInit

      $scope.$watch('$ctrl.model.dataSource', onDataSourceChanged)

      /**
       * Initializes the component
       */
      function $onInit() {
        $ctrl.uniq = _.uniqueId()

        var dataSourceId = $ctrl.filterRule.dataSourceId()
        $ctrl.model = dataSourceId ? { dataSource: { id: dataSourceId } } : { dataSource: null }
      }

      function onDataSourceChanged(newDataSource, oldDataSource) {
        if (newDataSource && newDataSource.id === $ctrl.filterRule.dataSourceId()) {
          return
        }

        $ctrl.filterRule.dataSourceId(newDataSource ? newDataSource.id : null)
      }
    }
  })
