angular.module('org-admin')
  .directive('contactsOverview', function() {
    return {
      templateUrl: '/static/org/contacts/contacts-overview.html',
      controllerAs: 'ctrl',
      controller: function contactsOverviewController(
        _, $q, $routeParams, $scope, $window, currentOrg,
        featureToggles, setAs,
        Affiliation, CentralService, CredentialDefinition, FinancialAdminUiService,
        AdminsListService, LaunchDarklyService, renderContext
      ) {
        var ctrl = this

        ctrl.loaded = false
        ctrl.$onInit = $onInit
        ctrl.$routeParams = $routeParams
        ctrl.addPerson = addPerson
        ctrl.addPersonContainerId = 'contacts-overview-add-person'
        ctrl.addAdminContainerId = 'contacts-overview-add-admin'
        ctrl.addPersonCallbackObj = { adminInvited: reloadAdminListOnSuccess,  adminRemoved: reloadAdminListOnSuccess, personAdded: addPersonSuccess }
        ctrl.affiliatons = []
        ctrl.credentials = []
        ctrl.myCredentialDefinitions = []
        ctrl.credentialDefinitions = []
        ctrl.classifications = []
        ctrl.classificationParents = {}
        ctrl.profilesPolicy = {}

        function $onInit() {
          FinancialAdminUiService.loadSharedScripts()
          CentralService.checkProfilesPolicy(currentOrg.id).then(function(result) {
            ctrl.profilesPolicy = result
            if (!ctrl.profilesPolicy.view_affiliations) return ctrl.loaded = true
            if (!featureToggles.affiliated_clubs_list) return ctrl.loaded = true
            $q.resolve()
              .then(checkFeatures)
              .then(getAffiliations)
              .then(getCredentials)
              .then(getCredentialDefinitions)
              .then(getClassifications)
              .then(setAs(ctrl, 'loaded', true))
          })
        }

        function addPerson() {
          var containerId = renderContext.admins ? ctrl.addAdminContainerId : ctrl.addPersonContainerId
          $window.document.getElementById(containerId).firstElementChild.open()
        }

        function addPersonSuccess(event) {
          if (!event.detail.success) return
          $scope.$emit('addPerson:personAdded', [event.detail.result.result])
        }

        function reloadAdminListOnSuccess(event) {
          if (!event.detail.success) return

          AdminsListService.setPagination({ current_page: 1 })
        }

        function checkFeatures() {
          return LaunchDarklyService.client($routeParams.currentOrgId).then(function(ldClient) {
            ctrl.directoryAffiliationsEnabled = ldClient.variation('directory-affiliations', false)
          })
        }

        // Load affiliations from the first root organization (if any)
        function getAffiliations() {
          var rootOrg = _.first(currentOrg.root_organizations)
          if (!rootOrg) return
          var params = {
            ancestor_organization_id: rootOrg.id,
            child_organization_id: currentOrg.id
          }
          return Affiliation
            .findAll(params)
            .then(setAs(ctrl, 'affiliations'))
        }

        // Parse credentials off of the affiliations (if any)
        function getCredentials() {
          ctrl.credentials = _.flatten(_.map(ctrl.affiliations, 'credentials'))
        }

        // Parse affiliated credentials and load this the current orgs credential definitions
        function getCredentialDefinitions() {
          return CredentialDefinition
            .forOrg(currentOrg.id)
            .then(setAs(ctrl, 'myCredentialDefinitions'))
            .then(mergeWithAffiliatedCredentialDefinitions)
            .then(setAs(ctrl, 'credentialDefinitions'))
        }

        function getClassifications() {
          var credClassifications = _.chain(ctrl.credentials).map(credentialClassifications).flatten().value()
          var defClassifications = _.chain(ctrl.myCredentialDefinitions).map(credentialDefinitionClassifications).flatten().value()
          ctrl.classifications = _.uniq(_.compact([].concat(credClassifications).concat(defClassifications)))
        }

        function credentialClassifications(cred) {
          return credentialDefinitionClassifications(cred.credential_definition, cred.classification)
        }

        function credentialDefinitionClassifications(credentialDefinition, classification) {
          var opts = credentialDefinition.classification_options || []
          var classifications = _.takeRightWhile(opts, function(o) { return o !== classification })
          mapClassificationParents(classifications)
          return classifications
        }

        function mergeWithAffiliatedCredentialDefinitions(credentialDefinitions) {
          return _.map(ctrl.credentials, 'credential_definition').concat(credentialDefinitions)
        }

        // Creates a hash mapping classifications under this org to their parent classification name.
        // For example, and NGB with classifications of [Region, Club] would map to { "Club": "Region" }
        // but the region under would see [Club] map to {} since the region classification is not a child.
        // This data will be passed to filtering to determine which options to show. This also assumes the
        // classification sources do not contradict each other, like a credential def having [Region, Club],
        // but an affiliation having [District, Club], for example.
        function mapClassificationParents(classifications) {
          for (var i = 0; i < classifications.length; i++) {
            if (!classifications[i - 1]) continue
            ctrl.classificationParents[_.camelCase(classifications[i])] = classifications[i - 1]
          }
        }

      }
    }
  })
