angular.module('pl-shared')

  .service('Item', function(DS, paginate, ENV) {
    var Item = DS.defineResource({
      name: 'items',
      endpoint: 'items/summary',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api'
    })

    paginate(Item)

    return Item
  })

  .service('ItemPrice', function(DS, ENV) {
    var ItemPrice = DS.defineResource({
      name: 'itemprice',
      endpoint: 'items/',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api'
    })

    return ItemPrice
  })
