angular.module('pl-shared')

  .directive('actionButton', function() {
    return {
      restrict: 'A',
      require: '^actionButtons',
      link: function($scope, $element, $attrs, actionButtonsCtrl) {
        actionButtonsCtrl.registerElement($element)
        $element.on('$destroy', actionButtonsCtrl.unregisterElement)
      }
    }
  })

  .component('actionButtons', {

    transclude: true,
    template: '<action-buttons-buttons ng-transclude></action-buttons-buttons> ' +
              '<button action-buttons-toggle class="pl-button">' +
              '  <svg class="svg-ellipsis pl-icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-ellipsis"></use></svg>' +
              '</button>' +
              '<action-buttons-menu class="pl-dropdown-menu--no-icons"></action-buttons-menu>',

    controller: function(_, $scope, $element, $window, $document, $timeout, KEY) {

      $element.css({
        'white-space': 'nowrap', // required for calculating overflow
        'opacity': '0' // to prevent unstyled flash
      })

      var $ = angular.element
      var CLICK = 'click'
      var RESIZE = 'resize'
      var VISIBLE = ':visible'
      var ACTIVE_CLASS = 'is-active'
      var PRIMARY_CLASS = 'pl-button--highlight'
      var BUTTON_CLASS = 'pl-button'
      var MENU_ITEM_CLASS = 'pl-dropdown-menu__item pl-dropdown-menu__item--no-focus'
      var ALL_CLASSES = PRIMARY_CLASS + ' ' + BUTTON_CLASS + ' ' + MENU_ITEM_CLASS
      var ACTION_BUTTON = '[action-button]'

      var $ctrl = this
      var Tether = $window.Tether
      var win = angular.element($window)
      var el = $element[0]
      var parent = el.parentNode
      var newSize = size()
      var oldSize = newSize
      var rendered = false
      var render = _.debounce(renderItems, 50)
      var maxVisibleCount = 3
      var maxCountWhenUsedMenu = 2
      var maxCountWhenUsedMenuInMobile = 1
      var itemsContainer = []
      var parentNode = angular.element(el.parentNode)

      // Expose these for testing (menu get's moved by Tether and is hard to find)
      var buttons = $ctrl.buttons = $element.find('action-buttons-buttons')
      var toggle = $ctrl.toggle = $element.find('[action-buttons-toggle]').hide()
      var menu = $ctrl.menu = $element.find('action-buttons-menu').hide()

      var tetherRef = $window.tetherRef = new Tether({
        element: menu[0],
        target: toggle[0],
        attachment: 'top right',
        targetAttachment: 'bottom right'
      })

      $ctrl.actionItems = []
      $ctrl.registerElement = registerElement
      $ctrl.unregisterElement = unregisterElement

      $scope.$watch(size, renderIfSizeChanged)
      win.on(RESIZE, resizeWindow)
      toggle.on(CLICK, toggleMenu)
      menu.on('keydown', ACTION_BUTTON, navigate)
      $scope.$on('$destroy', destroy)

      function registerElement(el) {
        $ctrl.actionItems.push({
          primary: el.attr('action-button') === 'primary',
          element: el
        })
      }

      function unregisterElement(el) {
        _.remove($ctrl.actionItems, { element: el })
      }

      function size() {
        return parent.offsetWidth + ':' + el.offsetWidth
      }

      function resizeWindow() {
        renderIfSizeChanged()
        // Tether closes automatically when the window resizes,
        // so we must reset the menu state as well
        hideMenu()
      }

      function renderIfSizeChanged() {
        newSize = size()
        if (oldSize !== newSize) render()
        oldSize = newSize
      }

      function renderItems(collapsed, isMobile) {
        var items = $ctrl.actionItems
        var i = 0
        var itemsCount = items.length
        var sortedItems = _.sortBy(items, { primary: false })
        itemsContainer = []
        if (itemsCount > maxVisibleCount) collapsed = true
        if (!collapsed) {
          toggle.hide()
          menu.hide()
        }
        while (i < itemsCount) {
          renderItem(sortedItems[i], collapsed, isMobile)
          if (!isMobile && isMobileMode()) return renderItems(true, true)
          i++
        }
        if (collapsed) {
          toggle.show()
          if (menu.is(VISIBLE)) tetherRef.position()
        }
        if (!rendered) $element.animate({ opacity: 1 }, 200) // fade in fast
        rendered = true
        $timeout(angular.noop) // trigger another digest cycle to fix moved elements
      }

      function isMobileMode() {
        var elWidth = Math.round(el.offsetWidth) + Math.round(toggle.outerWidth())
        var parentWidth = Math.round(parentNode.width())
        return parentWidth < elWidth
      }

      function mobileRender(actionItem, collapsed) {
        if (itemsContainer.length < maxCountWhenUsedMenuInMobile) return renderButton(actionItem)
        renderMenu(actionItem, collapsed)
      }
      function menuRender(actionItem, collapsed) {
        if (itemsContainer.length < maxCountWhenUsedMenu) return renderButton(actionItem)
        renderMenu(actionItem, collapsed)
      }

      function renderButton(actionItem) {
        setClass(actionItem.element, actionItem.primary  && itemsContainer.length === 0 ? PRIMARY_CLASS : BUTTON_CLASS)
        buttons.append(' ', actionItem.element)
        itemsContainer.push(actionItem)
      }

      function renderMenu(actionItem, collapsed) {
        var newClass = collapsed ? MENU_ITEM_CLASS : BUTTON_CLASS
        var container = collapsed ? menu : buttons
        setClass(actionItem.element, newClass)
        container.append(' ', actionItem.element)
      }

      function renderItem(actionItem, collapsed, isMobile) {
        if (isMobile) return mobileRender(actionItem, collapsed)
        if (collapsed) return menuRender(actionItem, collapsed)
        renderButton(actionItem)
      }

      function setClass(el, newClass) {
        el.removeClass(ALL_CLASSES).addClass(newClass)
      }

      function toggleMenu(e) {
        e.stopPropagation() // prevents immediate close
        if (menu.is(VISIBLE)) hideMenu()
        else showMenu()
      }

      function showMenu() {
        toggle.addClass(ACTIVE_CLASS)
        menu.show().children().first().focus()
        tetherRef.position()
        $document.on(CLICK, hideMenu)
      }

      function hideMenu() {
        menu.hide()
        toggle.removeClass(ACTIVE_CLASS)
      }

      function navigate(e) {
        var key = e.which
        if (key === KEY.RIGHT || key === KEY.DOWN) {
          $(e.currentTarget).nextAll(ACTION_BUTTON).first().focus()
        }
        else if (key === KEY.LEFT || key === KEY.UP) {
          $(e.currentTarget).prevAll(ACTION_BUTTON).first().focus()
        }
      }

      function destroy() {
        hideMenu()
        win.off(RESIZE, renderIfSizeChanged)
        $document.off(CLICK, hideMenu)
      }

    }

  })
