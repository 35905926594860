angular.module('org-admin')

  .directive('programListingAddressQuestionView', function() {

    return {
      scope: {},
      templateUrl: '/static/org/program-listings/questions/program-listing-address-question-view.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        answer: '='
      },
      controller: function(_, i18ng, GeographicOptions) {
        var ctrl = this

        ctrl.fields = {
          address_same_as_org: {
            label: i18ng.t('PROGRAM_LISTING_FORMS.LABELS.ADDRESS.address_same_as_org')
          },
          address_1: {
            label: i18ng.t('PROGRAM_LISTING_FORMS.LABELS.ADDRESS.street')
          },
          address_2: {
            label: i18ng.t('PROGRAM_LISTING_FORMS.LABELS.ADDRESS.street_2')
          },
          country: {
            label: i18ng.t('PROGRAM_LISTING_FORMS.LABELS.ADDRESS.country')
          },
          state: {
            label: i18ng.t('PROGRAM_LISTING_FORMS.LABELS.ADDRESS.state')
          },
          city: {
            label: i18ng.t('PROGRAM_LISTING_FORMS.LABELS.ADDRESS.city')
          },
          postal_code: {
            label: i18ng.t('PROGRAM_LISTING_FORMS.LABELS.ADDRESS.postal_code')
          }
        }

        var answer = ctrl.answer || {}
        if (!ctrl.answer.value.address_same_as_org) {
          ctrl.answer.value.address_same_as_org = !ctrl.answer.value.postal_code
        }

        ctrl.properties = ctrl.question.properties

        // Get country lable
        GeographicOptions.countries()
          .then(function(countries) {
            _.each(countries, function(country) {
              if (country.value == ctrl.answer.value.country) {
                ctrl.display_country = country.label
              }
            })
          })
        // Get subregion label
        GeographicOptions.subregions({ params: { country: ctrl.answer.value.country } })
          .then(function(subregions) {
            _.each(subregions, function(subregion) {
              if (subregion.value == ctrl.answer.value.state) {
                ctrl.display_state = subregion.label
              }
            })
          })
      }
    }

  })
