angular.module('org-admin')
  .component('redemptionCard', {
    bindings: {
      discountCodeDefinition: '<'
    },
    templateUrl: '/static/org/discounts/redemption-card.component.html',
    controllerAs: 'ctrl',
    controller: function(
      debounceCallback,
      DiscountCodes,
      showError,
      Search,
      setAs,
      setWhile,
      currentOrg,
      PusherService,
      $scope,
      $window,
      $httpParamSerializer,
      ENV
    ) {

      var ctrl = this
      ctrl.loading = true
      ctrl.codeRedemption = []
      ctrl.params = { discount_code_definition_id: ctrl.discountCodeDefinition.id }
      ctrl.options = { result: ctrl.codeRedemption, pagination: {} }
      ctrl.codesFilter = 'all'
      ctrl.filterUsed = false
      ctrl.sortColumns = { 'order[redeemed_at]': 'desc' }
      ctrl.searchDirtied = false
      ctrl.codeType = ctrl.discountCodeDefinition.single_use ? 'single' : 'multi'
      ctrl.codesFilterOptions = {
        sections: [{
          menuItems: [{
            text: 'All Codes',
            action: function() { ctrl.codesFilter = 'all' }
          },
          {
            text: 'Redeemed',
            action: function() { ctrl.codesFilter = 'redeemed' }
          },
          {
            text: 'Available',
            action: function() { ctrl.codesFilter = 'available' }
          }]
        }]
      }

      ctrl.exportReport = function() {
        $window.open(ENV.urls.apiProxy + '/discount/codes?format=csv&' + $httpParamSerializer(ctrl.params))
      }

      ctrl.search = Search.create({
        update: function(searchTerm) {
          ctrl.searchDirtied = true
          ctrl.params.search_fields = getSearchFields()
          ctrl.params.search_term = String(searchTerm) || undefined
        }
      })

      $scope.$watch('ctrl.codesFilter', function(newValue, oldValue) {
        if (ctrl.codesFilter !== 'all') ctrl.filterUsed = true
        if (ctrl.codesFilter === 'all') {
          ctrl.params['codes_filter[]'] = null
        }
        else {
          ctrl.params['codes_filter[]'] = ctrl.codesFilter
        }
      })

      $scope.$watch('ctrl.sortColumns', function(newSort, oldSort) {
        _.each(oldSort, function(dir, col) {
          delete ctrl.params[col]
        })
        _.extend(ctrl.params, newSort)
      })

      var findAll = debounceCallback(DiscountCodes.findAll)
      function getCodeRedemption() {
        if (!ctrl.discountCodeDefinition.id) return
        return findAll(ctrl.params, ctrl.options)
          .then(setAs(ctrl, 'codeRedemption'))
          .finally(setAs(ctrl, 'loading', false))
      }

      function getSearchFields() {
        var searchFields = 'sales.sale_number,sales.user_full_name'
        if (ctrl.discountCodeDefinition.single_use) {
          return 'discount_codes.code_as_entered,' + searchFields
        }
        else {
          return searchFields
        }
      }

      ctrl.filterAndSearchable = function() {
        return ctrl.codeRedemption.length || ctrl.search.term ||
          ctrl.filterUsed || ctrl.codeRedemption.loading
      }

      $scope.$watch('ctrl.params', getCodeRedemption, true)
      $scope.$watch(ctrl.discountCodeDefinition.id, pusherSubscribe)

      function pusherSubscribe() {
        PusherService.subscribe('channel_clare_organization_' + currentOrg.id)
        PusherService.bind('event_code_definition_updated_' + ctrl.discountCodeDefinition.id, getCodeRedemption)
      }

    }
  })
