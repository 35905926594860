angular.module('org-admin')
  .component('orgDataSourceSelect', {
    bindings: {
      model: '=',
      org: '<',
      readOnly: '<?',
      enableMemberships: '<?',
      enableTeamRosters: '<?',
      enableSeasonManagement: '<?',
      limitToSurveyId: '<?',
      useMemberIdSource: '<?'
    },
    templateUrl: '/static/org/filtering/org-data-source-select.html',
    controller: function(_, $scope, $q, i18ng, appPermission, dialog, setAs, filterPanelService, IndexArchivedSurvey, OrganizationProfileSchema, featureToggles) {
      var $ctrl = this
      $ctrl.actionPromise = null

      var AVAILABLE_BUCKETS = [
        { label: i18ng.t('FILTER_PANEL_DATA_SOURCE_SELECT.BUCKETS.survey_results'), value: 'survey_results', enabled: false },
        { label: i18ng.t('FILTER_PANEL_DATA_SOURCE_SELECT.BUCKETS.se_profile'), value: 'se_profile', enabled: true },
        { label: i18ng.t('FILTER_PANEL_DATA_SOURCE_SELECT.BUCKETS.memberships'), value: 'memberships', enabled: false },
        { label: i18ng.t('FILTER_PANEL_DATA_SOURCE_SELECT.BUCKETS.team_instance_ids' + (appPermission.seasonManagement.visible ? '_legacy' : '')), value: 'team_instance_ids', enabled: false },
        { label: i18ng.t('FILTER_PANEL_DATA_SOURCE_SELECT.BUCKETS.orgProfile', { org_name: $ctrl.org.name }), value: 'orgProfile', enabled: false },
        { label: i18ng.t('FILTER_PANEL_DATA_SOURCE_SELECT.BUCKETS.suspensions'), value: 'suspensions', enabled: false },
        { label: i18ng.t('FILTER_PANEL_DATA_SOURCE_SELECT.BUCKETS.roster_personas'), value: 'roster_personas', enabled: false }
      ]

      $ctrl.buckets = []

      $ctrl.sourceSelect2Options = {
        ajax: {
          delay: 1000,
          transport: function(params, success, error) {
            if (!_.isUndefined(params.data.search) || params.data.page > 1) {
              return $ctrl.actionPromise(params.data)
                .then(success)
            }
            else {
              return success($ctrl.rawDataSources[$ctrl.bucket.value])
            }
          },
          data: function(params) {
            return {
              orgId: $ctrl.org.id,
              page: params.page || 1,
              perPage: 50,
              surveyIds: $ctrl.limitToSurveyId,
              search: params.term
            }
          },
          processResults: function(data, params) {
            var newDataSources = processDataSourcesToSelect2(data)
            concatDataSources(newDataSources)

            var results = initializeDataSourceBucket(data, params)
            var isPaginationEnabled = data.pagination && !data.pagination.last_page

            return {
              results: results,
              pagination: {
                more: isPaginationEnabled
              }
            }
          }
        },
        collapseOverflow: true,
        closeAfterRequest: {
          requestFn: IndexArchivedSurvey.index,
          selector: '.select2-unindexed-item'
        },
        escapeMarkup: function(markup) { return markup },
        templateResult: function(item) {
          var markup = item.is_es_indexed === false ?
            '<i class="select2-unindexed-item">' + item.text + '</i>' :
            item.text
          return markup
        },
        dropdownAutoWidth: true,
        placeholder: i18ng.t('CONTACTS_LIST.FILTERS.SELECT2_PLACEHOLDERS.data_source'),
        setupAutoWidth: false
      }

      $ctrl.$onInit = $onInit
      $ctrl.$onChanges = $onChanges
      $ctrl.setBucket = setBucket

      $scope.$watch('$ctrl.model.dataSource', onDataSourceChanged)

      function $onInit() {
        $ctrl.loaded = false
        $ctrl.uniq = _.uniqueId()
        if (!$ctrl.model) $ctrl.model = { dataSource: null }

        var requestParams = {
          orgId: $ctrl.org.id,
          page: 1,
          perPage: 500,
          surveyIds: $ctrl.limitToSurveyId
        }

        var requests = {
          survey_results: filterPanelService.getSurveys(requestParams),
          orgProfile: OrganizationProfileSchema.find($ctrl.org.id),
        }

        if ($ctrl.enableMemberships) {
          requests.memberships = filterPanelService.getMemberships(requestParams)
        }
        if ($ctrl.enableTeamRosters) {
          requests.team_instance_ids = filterPanelService.getTeamRosterGroups(requestParams)
        }
        if ($ctrl.enableSeasonManagement) {
          requests.roster_personas = filterPanelService.getSeasons(requestParams)
        }

        $q.all(requests).then(handleRequests)

        function handleRequests(results) {
          $ctrl.rawDataSources = results
          $ctrl.orgProfile = results.orgProfile
          $ctrl.dataSources = initializeDataSources()
          $ctrl.buckets = _.filter(AVAILABLE_BUCKETS, 'enabled')
          if (!$ctrl.model.dataSource) setBucket($ctrl.buckets[0])
          initializeModel()
          $ctrl.loaded = true
        }

        function initializeDataSources() {
          var dataSources = {
            se_profile: {
              id: 'se_profile',
              column_type: 'se_profile',
              text: i18ng.t('CONTACTS_LIST.FILTERS.METADATA_FIELDS.se_profile'),
              isSEProfile: true,
              is_es_indexed: true,
              survey_results_count: 1
            }
          }

          if (hasSurveyResults()) {
            dataSources.survey_results = processDataSourcesToSelect2($ctrl.rawDataSources.survey_results, 'survey_results')
            enableBucket('survey_results')
          }

          if (hasOrgFields()) {
            dataSources.orgProfile = {
              id: 'orgProfile',
              column_type: 'org_profile',
              text: $ctrl.org.name,
              isOrgProfile: true,
              is_es_indexed: true,
              survey_results_count: 1
            }
            enableBucket('orgProfile')
          }

          if (!$ctrl.limitToSurveyId) {
            if ($ctrl.enableMemberships && _.some($ctrl.rawDataSources.memberships)) {
              if ($ctrl.useMemberIdSource || _.get($ctrl, 'model.dataSource.id', '').includes('memberships.')) {
                dataSources.memberships = processDataSourcesToSelect2($ctrl.rawDataSources.memberships, 'memberships')
              }
              else {
                dataSources.memberships = {
                  id: 'memberships',
                  column_type: 'memberships',
                  isMemberships: true,
                  text: $ctrl.org.name,
                  is_es_indexed: true,
                  survey_results_count: 1
                }
              }
              enableBucket('memberships')
            }
            if ($ctrl.enableTeamRosters && _.some($ctrl.rawDataSources.team_instance_ids)) {
              dataSources.team_instance_ids = processDataSourcesToSelect2($ctrl.rawDataSources.team_instance_ids, 'team_instance_ids')
              enableBucket('team_instance_ids')
            }
            if (featureToggles.suspensions) {
              dataSources.suspensions = { id: 'suspensions', column_type: 'suspensions' }
              enableBucket('suspensions')
            }
            if ($ctrl.enableSeasonManagement && _.some($ctrl.rawDataSources.roster_personas)) {
              dataSources.roster_personas = processDataSourcesToSelect2($ctrl.rawDataSources.roster_personas, 'roster_personas')
              enableBucket('roster_personas')
            }
          }

          $ctrl.initDataSources = dataSources
          return $ctrl.initDataSources

          function hasOrgFields() {
            if (!$ctrl.orgProfile) return false
            return _.any($ctrl.orgProfile.schema, filterPanelService.isOrgProfileField)
          }

          function hasSurveyResults() {
            return $ctrl.rawDataSources.survey_results.length > 0
          }
        }

        function initializeModel() {
          if (!$ctrl.model.dataSource) return

          var dataSourceId = $ctrl.model.dataSource.id
          var parts = dataSourceId.split('.')
          var bucket = 'se_profile'
          var id = null
          var dataSource = null
          if (parts.length > 1) {
            bucket = parts[0]
            id = parts[1]
          }
          else if (/^\d+$/.test(dataSourceId)) {
            bucket = 'survey_results'
            id = dataSourceId
          }
          else if (['suspensions', 'memberships'].includes(dataSourceId)) {
            bucket = id = dataSourceId
          }
          else {
            id = dataSourceId
          }

          if (['se_profile', 'orgProfile', 'suspensions'].includes(bucket) || id == 'memberships') {
            dataSource = $ctrl.dataSources[bucket]
          }
          else {
            dataSource = _.find($ctrl.dataSources[bucket], { id: bucket + '.' + id })
          }
          $ctrl.bucket = _.find($ctrl.buckets, { value: bucket })

          if (!_.isEmpty(dataSource)) {
            $ctrl.model.dataSource = dataSource
          }
          else if (['survey_results', 'team_instance_ids', 'memberships'].includes(bucket)) {
            filterPanelService.getDataSourceById(dataSourceId)
              .then(function(data) {
                var newDataSource = processDataSourcesToSelect2([data], bucket)
                concatDataSources(newDataSource)
                $ctrl.model.dataSource = newDataSource
              })
          }
        }
      }

      /**
       * Reinitializes when limitToSurveyId or enableMemberships changes
       */
      function $onChanges(changesObj) {
        if (changesObj.limitToSurveyId && !changesObj.limitToSurveyId.isFirstChange()) $onInit()
        if (changesObj.enableMemberships && !changesObj.enableMemberships.isFirstChange()) $onInit()
      }

      function onDataSourceChanged(newDataSource, oldDataSource) {
        if (!newDataSource || (oldDataSource && oldDataSource.id === newDataSource.id)) {
          return
        }

        // @todo implement result_count for other buckets?
        var hasResults = !newDataSource.isSurvey || !!newDataSource.survey_results_count
        var isIndexed = !newDataSource.isSurvey || newDataSource.is_es_indexed

        if (hasResults && isIndexed) {
          $ctrl.model.dataSource = newDataSource

          return
        }
        else if (!hasResults) {
          dialog.confirm({ component: 'no-results-registration-dialog' })
            .finally(setAs($ctrl.model, 'dataSource', oldDataSource))
        }
      }

      /**
       * set the active bucket (called when dropdown choice is changed)
       * @param {Object<label, value>} bucket
       * @returns void
       */
      function setBucket(bucket) {
        if (['se_profile', 'orgProfile', 'suspensions'].includes(bucket.value)) {
          $ctrl.model.dataSource = $ctrl.dataSources[bucket.value]
          $ctrl.actionPromise = null
        }
        else {
          $ctrl.model.dataSource = null
        }
        $ctrl.bucket = bucket

        switch ($ctrl.bucket.value) {
          case 'survey_results':
            $ctrl.actionPromise = filterPanelService.getSurveys
            break
          case 'memberships':
            if ($ctrl.useMemberIdSource) $ctrl.actionPromise = filterPanelService.getMemberships
            else {
              $ctrl.model.dataSource = $ctrl.dataSources[bucket.value]
              $ctrl.actionPromise = null
            }
            break
          case 'team_instance_ids':
            $ctrl.actionPromise = filterPanelService.getTeamRosterGroups
            break
          case 'roster_personas':
            $ctrl.actionPromise = filterPanelService.getSeasons
            break
          default:
            $ctrl.actionPromise = null
        }
      }

      /**
       * can be used before filterPanelService.getDataSources resolves (and sets $ctrl.buckets)
       *   to enable one of the AVAILABLE_BUCKETS above
       * @param {string} bucketValue
       * @return void
       */
      function enableBucket(bucketValue) {
        var bucket = _.find(AVAILABLE_BUCKETS, { value: bucketValue })
        bucket.enabled = true
      }

      /**
       * Append paginated dataSource to list of existing dataSources
       *
       * @param {object} data - The new survey source from the server.
       */
      function concatDataSources(data) {
        var bucket = $ctrl.bucket.value
        $ctrl.dataSources[bucket] = _.uniq($ctrl.dataSources[bucket].concat(data), function(item) {
          return item.id
        })
      }

      function initializeDataSourceBucket(data, params) {
        var sources = processDataSourcesToSelect2(data)
        var isPaginationOperation = params && params.page
        var isSearchOperation = params && !params.page && params.term
        var dataSources = []

        if (isSearchOperation) {
          dataSources = _.filter(sources, function(item) {
            return item.text.toUpperCase().indexOf(params.term.toUpperCase()) !== -1
          })
        }

        if (isPaginationOperation) {
          dataSources = sources
        }

        if (!isSearchOperation && !isPaginationOperation) {
          dataSources = dataSources.concat(sources)
        }

        return dataSources
      }

      function appendPagination(src, dest) {
        if (src.pagination) dest.pagination = src.pagination
        return dest
      }

      function processDataSourcesToSelect2(data, bucket) {
        if (!bucket) bucket = $ctrl.bucket.value
        if (bucket === 'survey_results') return appendPagination(data, processSurveysToSelect2(data))
        else if (bucket === 'memberships') return appendPagination(data, processMembershipsToSelect2(data))
        else if (bucket === 'team_instance_ids') return appendPagination(data, processTeamsToSelect2(data))
        else if (bucket === 'roster_personas') return appendPagination(data, processSeasonsToSelect2(data))
        else return data
      }

      function processSurveysToSelect2(data) {
        return _.chain(data)
          .sortBy('created_at')
          .reverse()
          .map(mapSurveyToSelect2Item)
          .value()
      }
      function mapSurveyToSelect2Item(dataSource) {
        return {
          constructor: Object,
          id: _.startsWith(dataSource.id, 'survey_results.') ? dataSource.id : 'survey_results.' + dataSource.id,
          column_type: 'survey',
          isSurvey: true,
          text: dataSource.name,
          is_es_indexed: dataSource.is_es_indexed,
          survey_results_count: dataSource.survey_results_count,
          url: dataSource.survey_url
        }
      }

      function processMembershipsToSelect2(data) {
        return _.chain(data)
          .sortBy('membership_definition_name')
          .map(mapMembershipToSelect2Item)
          .value()
      }
      function mapMembershipToSelect2Item(dataSource) {
        return {
          constructor: Object,
          id: 'memberships.' + dataSource.membership_definition_id,
          column_type: 'memberships',
          isMemberships: true,
          text: dataSource.membership_definition_name,
          is_es_indexed: true,
          survey_results_count: 1, // @todo - this is used to show popup if no survey_results exist. Similar for Memberships?
          url: '' // @todo link to membership_definition detail page
        }
      }

      function processSeasonsToSelect2(data) {
        return _.chain(data)
          .sortBy('season_name')
          .map(mapSeasonToSelect2Item)
          .value()
      }
      function mapSeasonToSelect2Item(dataSource) {
        return {
          constructor: Object,
          id: 'roster_personas.' + dataSource.season_id,
          column_type: 'roster_personas',
          isRosterPersonas: true,
          text: dataSource.season_name,
          is_es_indexed: true,
          survey_results_count: 1,
          url: null
        }
      }

      function processTeamsToSelect2(data) {
        return _.chain(data)
          .sortBy('name')
          .map(mapTeamToSelect2Item)
          .value()
      }
      function mapTeamToSelect2Item(dataSource) {
        return {
          constructor: Object,
          id: 'team_instance_ids.' + dataSource.team_instance_id,
          column_type: 'rostering',
          isGroupsAndRosters: true,
          text: dataSource.name,
          is_es_indexed: true,
          survey_results_count: 1,
          url: null
        }
      }
    }
  })
