angular.module('pl-shared')

  .service('Contact', function(_, DS, paginate, apiV2, Player) {
    var Contact = DS.defineResource({
      name: 'contacts',
      endpoint: 'contact_list',
      basePath: DS.adapters.ngin_v2.defaults.basePath,
      httpConfig: DS.adapters.ngin_v2.defaults.httpConfig,
      defaultAdapter: 'ngin_v2',
      computed: {
        full_name: [
          'name', 'first_name', 'last_name',
          function(name, first_name, last_name) {
            return name || (first_name + ' ' + last_name)
          }
        ],
        verified_full_name: [
          'name', 'first_name', 'last_name', 'org_verified_first_name', 'org_verified_last_name',
          function(name, first_name, last_name, org_verified_first_name, org_verified_last_name) {
            if (org_verified_first_name) {
              return (org_verified_first_name + ' ' + org_verified_last_name)
            }

            return name || (first_name + ' ' + last_name)
          }
        ],
        is_orphan: [
          'invite_status',
          function(invite_status) {
            return invite_status !== 'claimed'
          }
        ],
        is_uninvited_orphan: [
          'invite_status',
          function(invite_status) {
            return invite_status === 'uninvited'
          }
        ],
        dataBySurveyId: [
          'data_by_survey',
          function(tbs) {
            return _.mapValues(tbs, function(pairs) { // objects with survey_id and team
              return _.indexBy(pairs, 'survey_id')
            })
          }
        ],
        profile_images: ['profile_image', function(profileImage) {
          // alias profile_image -> profile_images because Persona uses plural where Contact uses singular
          return profileImage
        }]
      },
      relations: {
        hasMany: {
          players: {
            localField: 'roster_players',
            foreignKey: 'persona_id'
          }
        }
      }
    })

    // Creates a unique-ish hashcode for an object
    function hashCode(obj) {
      var str = JSON.stringify(obj)
      var hash = 0
      if (_.isEmpty(obj)) return hash
      for (var i = 0; i < str.length; i++) {
        hash = ((hash << 5) - hash) + str.charCodeAt(i)
        hash = hash & hash // Convert to 32bit integer
      }
      return hash
    }

    var _findAll = Contact.findAll
    Contact.findAll = function(params, options) {
      params = _.extend({}, params)
      options = _.extend({}, options, { endpoint: 'contact_list/filter', method: 'POST' })
      params.exclude_teams = 'true'
      // Ensure that the params are unique for each request. Necessary in js-data 2.x, not 3.x
      var dataHash = hashCode(options.data)
      if (dataHash) params._dataHash = dataHash
      return _findAll(params, options)
    }

    return paginate(Contact)
  })
