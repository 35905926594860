angular.module('pl-shared', [
  'ngRoute',
  'ngMessages',
  'ngDialog',
  'i18ng',
  'renderContext',
  'checklist-model',
  'dndLists',
  'js-data',
  'ui.timepicker',
  'uiCropper',
  'se.ui.toast',
])
