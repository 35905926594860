angular.module('org-admin')

  .directive('groupsList', function() {

    return {
      scope: {},
      bindToController: {},
      templateUrl: '/static/org/groups/groups-list.html',
      controllerAs: 'ctrl',
      controller: function(
        _, $q, $scope, $routeParams, appPermission, Alerts, dialog, renderContext,
        setAs, selectHelper, Contact, currentUser,
        showError, MembershipsUtilService, Search, SePagination, GroupPersona,
        StaticGroupPersona, SmartGroupPersona, currentOrg, debounceCallback, i18ng,
        pageViewHandler, $filter, LegacySiteService) {

        var ctrl = this
        ctrl.MAX_CLUB_ASSIGNMENTS = 50

        ctrl.groupPersonas = []
        ctrl.groupPersonaContacts = []
        ctrl.params = {
          org_id: currentOrg.id,
          page: 1
        }
        ctrl.clubAssignmentError = false
        ctrl.canPurchaseOboMemberships = false
        ctrl.canSendClubAssignment = false
        ctrl.currentUser = currentUser
        ctrl.options = { result: ctrl.groupPersonas, pagination: {} }
        ctrl.groupTypes = ['active', 'static', 'smart', 'roster', 'archived']
        ctrl.groupType = ctrl.groupTypes[0]
        ctrl.showControls = 'ctrl.groupPersonas.length || ctrl.search.term || ctrl.groupPersonas.loading'
        ctrl.selectedGroupPersonas = selectHelper.bind($scope, 'ctrl.groupPersonas', { strategy: selectHelper.STRATEGY.Stateful })
        ctrl.toggleAllGroups = toggleAllGroups
        ctrl.pagination = new SePagination({
          loadDataFn: updatePagination,
          storageId: 'groupsList'
        })
        ctrl.inviteBossOrganizationId = null
        ctrl.legacyLink = LegacySiteService.getUrl('/groups/list')

        ctrl.search = Search.create({
          update: function(searchInputValue) {
            ctrl.params.name = String(searchInputValue) || undefined
          }
        })

        $scope.$watch('ctrl.groupType', function(newValue, oldValue) {
          ctrl.params.type = ctrl.groupType
        })

        // this watch kicks off the load request, so it must go last
        $scope.$watch('ctrl.params', onParamsChanged, true)

        $scope.$on('groupMembersChanged', _.ary(loadGroupPersonas, 0))
        $scope.listenToRoot('invoiceGroup:updateRequest', uncheckAll)

        // PUBLIC METHODS

        ctrl.$onInit = function() {
          updatePagination() // presets stored perPage
          ctrl.checkAffiliations()
        }

        ctrl.canSendInvoices = function() {
          return powerPayPermissions()
        }

        ctrl.newOrgMessage = function() {
          pageViewHandler.fireEvent('New Org Message', 7)

          dialog.confirm({
            component: 'new-org-message',
            scope: $scope,
            attrs: {
              groupRecipients: expandSelectedGroups()
            }
          })
        }

        ctrl.sendInvoices = function(groupPersonas) {
          pageViewHandler.fireEvent('Send Invoices', 4)

          dialog.confirm({
            directive: 'send-invoices',
            scope: $scope,
            attrs: {
              groupPersonas: groupPersonas || ctrl.selectedGroupPersonas
            }
          })
        }

        ctrl.addStaticGroup = function addStaticGroup() {
          pageViewHandler.fireEvent('Add Static Group', 7)

          dialog.confirm({
            directive: 'new-static-group',
            scope: $scope,
            attrs: {
              orgId: currentOrg.id,
              personas: []
            }
          })
            .then(addGroupSuccess)
        }

        ctrl.openArchivationConfirm = function() {
          dialog.confirm({
            component: 'confirm-dialog',
            attrs: {
              heading: i18ng.t('GROUPS_LIST.ARCHIVATION.CONFIRMATION.title',
                { count: ctrl.selectedGroupPersonas.length }),
              message: i18ng.t('GROUPS_LIST.ARCHIVATION.CONFIRMATION.message',
                { groupName: ctrl.selectedGroupPersonas[0].name,
                  count: ctrl.selectedGroupPersonas.length
                }),
              confirmLabel: i18ng.t('GROUPS_LIST.ARCHIVATION.CONFIRMATION.confirm_label'),
              confirmAction: ctrl.archiveGroups.bind(this, true)
            }
          })
        }

        ctrl.archiveGroups = function(archive) {
          ctrl.loaded = false
          ctrl.archivationError = false
          $q.resolve(expandSelectedGroups())
            .then(function(selectedGroups) {
              return _.map(selectedGroups, function(group) {
                return group.type === 'Smart Group' ? archiveSmartGroup(group, archive) : archiveStaticGroup(group, archive)
              })
            })
            .then(function(promises) {
              $q.all(promises)
                .then(setAs(ctrl, 'archivationError', false))
                .catch(setAs(ctrl, 'archivationError', true))
                .finally(finishArchivingGroups)
            })

          function finishArchivingGroups() {
            ctrl.selectedGroupPersonas.length = 0
            loadGroupPersonas().then(function() {
              if (ctrl.archivationError) {
                Alerts.warning(archive === true ? 'GROUPS_LIST.ARCHIVATION.error' : 'GROUPS_LIST.ARCHIVATION.restore_error')
              }
              else {
                Alerts.success(archive === true ? 'GROUPS_LIST.ARCHIVATION.success' : 'GROUPS_LIST.ARCHIVATION.restore_success')
              }
            })
          }
        }

        ctrl.transformTypeToClassName = function(groupPersonaType) {
          return 'sn-' + _.kebabCase(groupPersonaType) + '-icon'
        }

        ctrl.checkAffiliations = function() {
          MembershipsUtilService.loadPurchaseableOboMemberships(currentOrg.id)
            .then(function(result) {
              ctrl.purchaseableOboMemberships = result
              ctrl.canPurchaseOboMemberships = _.some(result)
              var invitableMemDefs = _.filter(result, function(memDef) {
                return memDef.can_invite
              })
              ctrl.canSendClubAssignment = ctrl.currentUser.canSendClubAssignment() && _.some(invitableMemDefs)
              loadInviteBossOrganizationId()
            })
        }

        ctrl.purchaseMemberships = function() {
          getGroupPersonaContacts()
            .then(function(groupPersonaContacts) {
              if (ctrl.canPurchaseOboMemberships) {
                ctrl.contactsForMembership = $q.when(groupPersonaContacts)
                ctrl.showPurchaseMemberships = true
              }
              else {
                dialog.confirm({
                  directive: 'no-purchaseable-memberships',
                  scope: $scope,
                })
              }
            })
        }

        ctrl.openClubAssignment = function() {
          $q.all(_.map(ctrl.selectedGroupPersonas, function(selectedGroup) {
            return selectedGroup ? GroupPersona.find(selectedGroup.id, {
              params: {
                show_messageable_members: true,
                show_unmessageable_members: true
              },
              bypassCache: true
            }) : undefined
          }))
            .then(function(groups) {
              var groupPersonaContacts
              groupPersonaContacts = groups.map(function(group) {
                return !group ? [] : group.messageable_members.concat(group.unmessageable_members)
              })
              groupPersonaContacts = _.flatten(groupPersonaContacts, [])
              groupPersonaContacts = _.uniq(groupPersonaContacts)
              ctrl.groupPersonaContacts = groupPersonaContacts

              if (groupPersonaContacts && groupPersonaContacts.length > ctrl.MAX_CLUB_ASSIGNMENTS) {
                return ctrl.clubAssignmentError = true
              }
              else ctrl.clubAssignmentError = false

              var ids = []
              groupPersonaContacts.forEach(function(contact) {
                if (contact) ids.push(contact.id)
              })
              dialog.confirm({
                attrs: {
                  bossOrgId: ctrl.inviteBossOrganizationId,
                  personaIds: ids
                },
                component: 'membership-invite-modal',
                scope: $scope
              })
            })
        }

        function loadInviteBossOrganizationId() {
          return MembershipsUtilService.getInviteBossOrganizationId(ctrl.purchaseableOboMemberships, false)
            .then(function(orgId) {
              ctrl.inviteBossOrganizationId = orgId
            })
        }

        function getGroupPersonaContacts() {
          return $q.all(_.map(ctrl.selectedGroupPersonas, function(selectedGroup) {
            return selectedGroup ? expandSelectedGroupContacts(selectedGroup) : undefined
          })).then(function(groupPersonaContacts) {
            groupPersonaContacts = _.flatten(groupPersonaContacts, [])
            groupPersonaContacts = _.uniq(groupPersonaContacts)
            groupPersonaContacts = groupPersonaContacts.sort(function(a, b) {
              if (a.name < b.name) return -1
              if (a.name > b.name) return 1
              return 0
            })
            return groupPersonaContacts
          })
        }

        function updatePagination(paginationParams) {
          if (ctrl.pagination) ctrl.params.per_page = ctrl.pagination.pageParams.per_page
          if (paginationParams) angular.merge(ctrl.params, paginationParams)
        }

        function expandSelectedGroupContacts(selectedGroup) {
          var opts = { per_page: 1000, result: [], load: 'all', org_id: currentOrg.id, group_persona_id: [selectedGroup.id, selectedGroup.id] }
          return Contact.findAll(opts)
        }

        checkForActions()

        function toggleAllGroups(allSelected) {
          if (allSelected) {
            Alerts.info('SELECT_ALL.info_message', {
              item_count: $filter('number')(ctrl.groupPersonas.pagination.total),
              item_count_num: ctrl.groupPersonas.pagination.total,
              page_count: $filter('number')(ctrl.groupPersonas.pagination.total_pages),
              page_count_num: ctrl.groupPersonas.pagination.total_pages,
              label: 'GROUPS_LIST.pagination_label'
            })
          }
        }

        // PRIVATE METHODS

        function onParamsChanged(newVal, oldVal) {
          if (nonPageParamChanged(newVal, oldVal)) ctrl.params.page = 1 // reset to first page when other criteria change
          return loadGroups(newVal)
        }

        function nonPageParamChanged(newVal, oldVal) {
          return oldVal ? !_.isEqual(removePageParam(newVal), removePageParam(oldVal)) : false
        }

        function removePageParam(val) {
          var clone = _.cloneDeep(val)
          delete clone.page
          return clone
        }

        GroupPersona.findAll({ org_id: currentOrg.id, per_page: 1 })
          .then(function(groups) { return groups.pagination.total })
          .then(setAs(ctrl.options.pagination, 'unfiltered_total'))
          .catch(showError)

        var findAll = debounceCallback(GroupPersona.findAll)
        function loadGroupPersonas(params, options) {
          var personaParams = { show_messageable_members: true, show_unmessageable_members: true }
          angular.merge(personaParams, params)
          return loadGroups(personaParams, options)
        }

        function loadGroups(params, options) {
          params = _.extend({}, ctrl.params, params)
          options = _.extend({}, ctrl.options, options)
          return findAll(params, options) // passing result allows tracking on the collection
            .catch(showError) // TODO: make this show an inline error
            .finally(loadGroupsComplete)
        }

        function loadGroupsComplete() {
          ctrl.loaded = true
          ctrl.paginationObject = _.extend({}, ctrl.groupPersonas.pagination) // clone triggers changes in the se-pagination component
        }

        // See if there are query params that should trigger a non-routable action on load
        function checkForActions() {
          switch ($routeParams.action) {
            case 'send_invoices': return ctrl.sendInvoices([{ id: $routeParams.groupId }])
            case 'processing_import':
              Alerts.info('IMPORT_TOOLS.processing_import')
              break
          }
        }

        function expandSelectedGroups() {
          var groupParams = { per_page: 1000 }
          var groupIds = []

          if (ctrl.selectedGroupPersonas.length > 0 && _.all(ctrl.selectedGroupPersonas)) {
            groupIds = _.map(ctrl.selectedGroupPersonas, function(group) { return group.group_id })
          }
          else if (ctrl.selectedGroupPersonas.length == 0) {
            return []
          }

          if (groupIds.length > 0) {
            angular.merge(groupParams, { ids: groupIds.join() })
          }
          return loadGroupPersonas(groupParams, { result: [], load: 'all', pagination: {} })
            .then(function(groups) {
              return groups
            })
        }

        function addGroupSuccess(createdGroup) {
          if (!createdGroup) return
          Alerts.success('MANAGE_GROUP.success', { group_name: createdGroup.name })
          renderContext.goto('app.billing.groups.detail.members', { groupId: createdGroup.id })
        }


        function powerPayPermissions() {
          return appPermission.checks.powerPay.has_fees && appPermission.powerPay.usable
        }

        /**
         * Sets the archived property on the provided SmartGroupPersona.
         *
         * @private
         * @param {} group - The group to update.
         * @param {boolean} archive - The
         */
        function archiveSmartGroup(group, archive) {
          return SmartGroupPersona.update(group.group_id, {
            archived: archive
          })
        }

        /**
         * Sets the archived property on the provided StaticGroupPersona.
         *
         * @private
         * @param {} group - The group to update.
         * @param {boolean} archive - The archive status to set.
         */
        function archiveStaticGroup(group, archive) {
          return StaticGroupPersona.update(group.group_id, {
            name: group.name,
            archived: archive
          })
        }

        /**
         * @private
         */
        function uncheckAll() {
          ctrl.selectedGroupPersonas.clearAll()
        }

      }
    }
  })
