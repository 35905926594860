angular.module('org-admin')

  .factory('Seasons', function(DS) {
    var Seasons = DS.defineResource({
      name: 'seasons',
      basePath: DS.adapters.ngin_v1.defaults.basePath,
      httpConfig: DS.adapters.ngin_v1.defaults.httpConfig,
      defaultAdapter: 'ngin_v1',
    })

    return Seasons
  })
