angular.module('org-admin')
  .component('leagueFacilityDetails', {
    bindings: {
      subvenue: '<',
      cancel: '<',
      confirm: '<'
    },
    templateUrl: '/static/org/league-locations/league-facility-details.html',
    controller: function(i18ng, Subvenue, pageViewHandler) {

      var ctrl = this
      ctrl.options = [
        { value: true, label: i18ng.t('LEAGUE_LOCATIONS.DETAILS.LABELS.have_lights_true') },
        { value: false, label: i18ng.t('LEAGUE_LOCATIONS.DETAILS.LABELS.have_lights_false') }
      ]

      ctrl.$onInit = function() {
      }

      ctrl.submit = function() {
        return ctrl.subvenue.DSSave({ params: { venue_id: false } })
          .then(function() { pageViewHandler.fireEvent('Location.EditFacilityModal.Save', 8) })
          .then(ctrl.confirm)
      }

      ctrl.revertChanges = function() {
        ctrl.subvenue.DSRevert()
        ctrl.cancel()
      }

    }
  })
