angular.module('org-admin')
  .directive('transactionsDetail', function() {

    return {
      scope: {},
      templateUrl: '/static/org/transactions/transactions-detail.html',
      controllerAs: 'ctrl',
      controller: function(
        $filter,
        $q,
        $routeParams,
        $scope,
        Alerts,
        dialog,
        i18ng,
        Invoice,
        routeTitle,
        SaleTransactions,
        setAs,
        setWhile,
        showError
      ) {

        var ctrl = this
        ctrl.nativeTransactionId = ''

        $scope.$watch(ctrl.nativeTransactionId, setWhile(ctrl, 'loading', update))

        ctrl.isNonRefundable = function() {
          if (!ctrl.payment) return false
          // don't include fully refunded payments as "Non Refundable"
          return _.any(ctrl.invoice.nonRefundablePayments(), { id: ctrl.payment.id, fully_refunded: false })
        }

        ctrl.isAlertOpen = true
        ctrl.closeAlert = function() {
          ctrl.isAlertOpen = false
        }
        ctrl.issueRefund = function() {
          dialog.confirm({
            directive: 'payment-refund',
            scope: $scope,
            attrs: {
              sale: ctrl.invoice,
              transactionId: ctrl.nativeTransactionId,
              fromTransactions: true
            }
          })
            .then(function(refund) {
              Alerts.success(i18ng.t('PAYMENT_REFUND.success', {
                refund_amount: $filter('currency')(refund.amount)
              }))
              update()
            })
        }

        function update() {
          return $q.all([
            getSalesTransaction()
              .then(getInvoice) // getInvoice is dependent on transaction.sale_number
              .then(getPayment)
          ])
            .catch(showError)
            .finally(setAs(ctrl, 'loading', false))
        }

        function getSalesTransaction() {
          return SaleTransactions.find($routeParams.transactionId, { bypassCache: true })
            .then(setAs(ctrl, 'transaction'))
            .then(function() {
              if (ctrl.transaction.amount_refunded > 0) getRefunds()
              ctrl.isRefund = ctrl.transaction.trxtype == 'C'
              ctrl.isSale = ctrl.transaction.trxtype == 'S'
              ctrl.hasTransactionProperties = !_.isEmpty(ctrl.transaction.properties)
              ctrl.nativeTransactionId = ctrl.transaction.id
              routeTitle.setParams({
                transaction_id: ctrl.nativeTransactionId
              })
            })
        }

        function getInvoice() {
          return Invoice.find(ctrl.transaction.sale_number)
            .then(setAs(ctrl, 'invoice'))
        }

        function getPayment() {
          if (!ctrl.invoice.payments) return
          ctrl.payment = _.filter(ctrl.invoice.payments, { sale_transaction_id: ctrl.transaction.id })[0]
        }

        function getRefunds() {
          SaleTransactions.findAll({ original_trans_id: ctrl.transaction.id })
            .then(setAs(ctrl, 'refunds'))
        }
      }
    }
  })
