angular.module('org-admin')
  .component('membershipDefinitionMessageForm', {
    templateUrl: '/static/org/memberships/membership-definition-message-form.html',
    bindings: {
      model: '=',
      next: '=',
      back: '=',
      organization: '='
    },
    controllerAs: '$ctrl',
    controller: function(
      _, SNAP,
      MembershipsUtilService
    ) {
      var $ctrl = this
      var getInvalidFields = MembershipsUtilService.getInvalidFields
      var trackAction = MembershipsUtilService.trackAction

      $ctrl.emailPreviewPath = SNAP.assetPath + '/images/memberships/email-preview.svg'
      $ctrl.$onInit = $onInit
      $ctrl.validateAndSubmit = validateAndSubmit
      $ctrl.editorCreated = editorCreated

      function $onInit() {
        initMessage()
        initWelcomeDelayMinutes()
        initEmailPreviewPath()
      }

      function initMessage() {
        if ($ctrl.model.message) return
        if ($ctrl.model.messages && $ctrl.model.messages.length) {
          $ctrl.model.message = $ctrl.model.messages[0].body
        }
        else $ctrl.model.message = ''
      }

      function initWelcomeDelayMinutes() {
        if ($ctrl.model.welcome_delay_minutes) return
        $ctrl.model.welcome_delay_minutes = 0
      }

      function initEmailPreviewPath() {
        var hasEligibility = $ctrl.model.eligibility &&
          (
            $ctrl.model.eligibility.background_check_required ||
            $ctrl.model.eligibility.fifa_integration_required ||
            $ctrl.model.eligibility.proof_of_birth_required ||
            $ctrl.model.eligibility.training_required ||
            $ctrl.model.eligibility.violation_review_required ||
            $ctrl.model.eligibility.waiver_required ||
            $ctrl.model.eligibility.electronic_document_required ||
            $ctrl.model.eligibility.custom_credentials_required
          )
        var emailImageFileName = hasEligibility ? 'sample-eligibility-email.svg' : 'sample-email.svg'
        $ctrl.emailPreviewPath = SNAP.assetPath + '/images/memberships/' + emailImageFileName
      }

      function getAddMessageErrors() {
        var formErrors = $ctrl.messageForm.$error.required
        if (formErrors) {
          return getInvalidFields(formErrors)
        }
        return null
      }

      function validateAndSubmit() {
        trackAction(getAddMessageErrors(), 'NextButton.MessageForm')
        $ctrl.messageForm.validateAndSubmit($ctrl.next)
      }

      function editorCreated(editor) {
        editor.root.setAttribute('data-gramm', false)
        editor.root.setAttribute('data-gramm_editor', false)
      }
    }
  })
