angular.module('org-admin')

  .directive('invoicesList', function() {

    return {
      scope: {},
      bindToController: {
        contact: '<',
        invoiceGroup: '=',
        orphanInvite: '=?'
      },
      templateUrl: '/static/org/invoices/invoices-list.html',
      controllerAs: 'ctrl',
      controller: function(
        $q,
        $routeParams,
        $scope,
        Contact,
        currentOrg,
        debounceCallback,
        dialog,
        Invoice,
        PusherService,
        Search,
        selectHelper,
        setAs,
        showError, renderContext
      ) {

        var ctrl = this
        var personaID = parseInt(ctrl.contact && ctrl.contact.id || $routeParams.personaId, 10)

        ctrl.loading = true
        ctrl.invoices = []
        ctrl.context = ctrl.invoiceGroup ? 'invoice_group' : 'contact'
        ctrl.params = { org_id: currentOrg.id }
        ctrl.options = { result: ctrl.invoices, pagination: {} } // passing result allows tracking on the collection
        ctrl.searchPlaceholder = 'INVOICE_LIST.search_placeholder_' + ctrl.context
        ctrl.sortColumns = { id: 'desc' }
        _.extend(ctrl.params, getContextParams())
        ctrl.countParams = _.clone(ctrl.params)

        pusherSubscribe()

        $scope.$watch('ctrl.sortColumns', function() {
          ctrl.params = _.omit(ctrl.params, function(val, key) { return _.startsWith(key, 'order[') })
          _.each(ctrl.sortColumns, function(dir, col) { ctrl.params['order[' + col + ']'] = dir })
        })

        ctrl.search = Search.create({
          update: function(searchTerm, searchOptions) {
            ctrl.params.search_term = String(searchTerm) || undefined
            ctrl.params.search_fields = getSearchFields()
            ctrl.options.load = ctrl.params.search_term ? 'all' : 'default'
          }
        })

        ctrl.uninvitedOrphan = function() {
          if (!ctrl.contact) return false
          return ctrl.contact.is_orphan && !ctrl.orphanInvite
        }

        ctrl.emptySearchState = function() {
          return !ctrl.invoices.length && (ctrl.params.search_term || ctrl.invoices.loading)
        }

        ctrl.emptyInvoicesState = function() {
          return !ctrl.invoices.length && !ctrl.params.search_term && !ctrl.invoices.loading && !ctrl.uninvitedOrphan()
        }

        ctrl.displayDiscounts = function() {
          return _.any(ctrl.invoices, { discounted: true })
        }
        ctrl.displayRefunds = function() {
          return _.any(ctrl.invoices, { refunded: true })
        }
        ctrl.displayCredits = function() {
          return _.any(ctrl.invoices, { credited: true })
        }

        // this watch kicks off the load request, so it must go last
        $scope.$watch('ctrl.params', loadInvoices, true)

        // TODO: ANGULAR2: replace this usage with whatever observable pattern exists in the future
        ctrl.selectedInvoices = selectHelper.bind($scope, 'ctrl.invoices')


        // PUBLIC METHODS

        ctrl.editRequest = function() {
          console.warn('Feature not implemented yet.')
        }

        ctrl.openInviteModal = function() {
          return dialog.confirm({
            component: 'send-orphan-invite-modal',
            attrs: {
              orphanPersona: ctrl.contact
            }
          })
            .then(setAs(ctrl, 'orphanInvite'))
        }

        ctrl.sendInvoices = function() {
          dialog.confirm({
            directive: 'send-invoices',
            scope: $scope,
            attrs: {
              recipients: [].concat(ctrl.contact || []),
              showStaticRecipients: true
            }
          })
        }

        // PRIVATE METHODS

        function getContextParams() {
          switch (ctrl.context) {
            case 'invoice_group': return { invoice_group_id: ctrl.invoiceGroup.id }
            case 'contact': return { persona_id: personaID }
          }
        }

        function getSearchFields() {
          switch (ctrl.context) {
            case 'invoice_group': return 'sale_number,sales.user_full_name'
            case 'contact': return 'sale_number,sales.description'
            default: return
          }
        }

        var findAll = debounceCallback(Invoice.findAll)
        function loadInvoices() {
          ctrl.invoicePrevContext = (renderContext.invoices || {}).prev || 'sent'
          var loadContact
          if (ctrl.context === 'contact' && !Contact.is(ctrl.contact)) {
            loadContact = Contact.find(personaID, { params: { org_id: currentOrg.id }, bypassCache: true })
              .then(function(result) {
                ctrl.contact = _.first(result) || result
              })
          }
          return $q.all({
            contact: loadContact,
            invoiceCount: Invoice.count(ctrl.countParams),
            invoices: findAll(ctrl.params, ctrl.options)
          })
            .then(setPagination)
            .then(function(invoices) {
              // keep the invoice group in sync with the latest data for sort, search, etc.
              if (!ctrl.loading) $scope.$emit('invoice:update')
              return invoices
            })
            .catch(showError)
            .finally(setAs(ctrl, 'loading', false))
        }

        function setPagination(results) {
          _.extend(ctrl.options.pagination, {
            filtered: !!ctrl.params.search_term,
            unfiltered_total: results.invoiceCount.total_records
          })
        }

        function updateInvoice($event, invoice) {
          var index = _.findIndex(ctrl.invoices, { id: invoice.id })
          if (index > -1) ctrl.invoices.splice(index, 1, invoice)
        }

        function pusherSubscribe() {
          PusherService.subscribe('channel_clare_organization_' + currentOrg.id)
          PusherService.bind('event_invoices_created', loadInvoices)
          PusherService.bind('event_invoices_updated', loadInvoices)
        }

        $scope.listenToRoot('invoice:void', updateInvoice)
      }
    }

  })
