angular.module('org-admin')
  .component('membershipDefinitionEligibilityForm', {
    templateUrl: '/static/org/memberships/membership-definition-eligibility-form.html',
    bindings: {
      model: '=',
      next: '=',
      back: '=',
      cancel: '=',
      organization: '=',
      submitting: '=',
      customCredentials: '=?',
      electronicDocuments: '=?',
      backgroundChecks: '=?',
      edit: '<?',
      eligibility: '='
    },
    controllerAs: '$ctrl',
    controller: function(
      $q, BackgroundScreenService, MembershipDefinition, ElectronicDocumentService, EligibilityRuleSet, i18ng,
      MembershipsUtilService, AbsorbService, EligibilityUtilService,
      featureToggles, appPermission, currentOrg, CredentialManagementService
    ) {
      var $ctrl = this
      var trackAction = MembershipsUtilService.trackAction
      var getInvalidFields = MembershipsUtilService.getInvalidFields

      var DEFAULT_ELIGIBILITY = {
        background_check_required: null,
        background_check: null,
        custom_credentials_required: null,
        custom_credentials: null,
        proof_of_birth_required: null,
        training_required: null,
        trainings: null,
        electronic_document_required: null,
        electronic_documents: null,
        fifa_integration_required: null,
        violation_review_required: null
      }

      $ctrl.$onInit = $onInit
      $ctrl.validateAndSubmit = validateAndSubmit
      $ctrl.trainingChanged = trainingChanged
      $ctrl.trainingRequiresAddress = trainingRequiresAddress

      $ctrl.select2Opts = {
        allowClear: true,
        minimumResultsForSearch: 10
      }
      $ctrl.validators = MembershipDefinition.eligibilityValidators

      $ctrl.edocsEnabled = appPermission.electronicDocuments.usable
      $ctrl.eligibilityEnabled = appPermission.eligibility.usable
      $ctrl.fifaIntegrationEnabled = $ctrl.eligibilityEnabled && !!_.result(featureToggles, 'fifa_integration')
      $ctrl.proofOfBirthApprovalFlowEnabled = $ctrl.eligibilityEnabled && !!_.result(featureToggles, 'proof_of_birth_approval_flow')
      $ctrl.violationReviewEnabled = $ctrl.eligibilityEnabled && !!_.result(featureToggles, 'violation_review')
      $ctrl.lmsEnabled = true // $ctrl.eligibilityEnabled && !!_.result(featureToggles, 'violation_review')

      $ctrl.getTrainingOptionValue = EligibilityUtilService.getTrainingOptionValue
      $ctrl.showElectronicDocumentsInput = showElectronicDocumentsInput
      $ctrl.showCustomCredentialsInput = showCustomCredentialsInput
      $ctrl.showEligibilityInfo = showEligibilityInfo
      $ctrl.showEligibilityInput = showEligibilityInput
      $ctrl.linkedElectronicDocumentDisplay = linkedElectronicDocumentDisplay
      $ctrl.linkedValidationRuleExists = linkedValidationRuleExists
      $ctrl.linkedAdditionalFieldExists = linkedAdditionalFieldExists
      $ctrl.showEligibilityRule = showEligibilityRule
      $ctrl.edocsRequired = edocsRequired
      $ctrl.customCredentialsRequired = customCredentialsRequired

      function $onInit() {
        initModel()
        initRenewal()
        $q.all([
          loadLinkedMembershipDefinition(),
          loadElectronicDocuments(),
          loadBackgroundScreenConfiguration(),
          loadSafesport(),
          loadCustomCredentials()
        ]).then(function() {
          setTrainings()
          $ctrl.loaded = true
        })
      }

      function initModel() {
        if (!$ctrl.model.eligibility) {
          $ctrl.model.eligibility = DEFAULT_ELIGIBILITY
        }
        $ctrl.model.profile_fields = MembershipsUtilService.mapValidationRulesToProfileFields($ctrl.model.validation_rules)
        $ctrl.model.selected_additional_fields = MembershipsUtilService.mapModelToSelectedAdditionalFields($ctrl.model.additional_fields)

        if ($ctrl.eligibility) {
          $ctrl.eligibilityRules = EligibilityUtilService.getMembershipEligibilityRules($ctrl.eligibility)
          $ctrl.customCredentialsLabel = EligibilityUtilService.getCustomCredentialsLabel($ctrl.eligibilityRules, $ctrl.customCredentials)
          $ctrl.edocsLabel = EligibilityUtilService.getElectronicDocumentsLabel($ctrl.eligibilityRules, $ctrl.electronicDocuments)
          $ctrl.bgCheckLabel = EligibilityUtilService.getBgCheckLabel($ctrl.eligibilityRules, $ctrl.backgroundChecks)
        }
        setLinkedMembershipDefinitionId()
      }

      function initRenewal() {
        if ($ctrl.model.parent_membership_definition_id) $ctrl.isRenewal = true
        if ($ctrl.isRenewal) {
          // @todo preload eligibility_rules from parent
        }
      }

      function setLinkedMembershipDefinitionId() {
        if ($ctrl.model.linked_membership_definition_id) {
          $ctrl.linkedMembershipDefinitionId = $ctrl.model.linked_membership_definition_id
        }
        else if ($ctrl.model.parent_membership_definition_links && $ctrl.model.parent_membership_definition_links.length) {
          $ctrl.linkedMembershipDefinitionId = $ctrl.model.parent_membership_definition_links[0].parent_membership_definition_id
        }
      }

      function loadLinkedMembershipDefinition() {
        if (!$ctrl.linkedMembershipDefinitionId) return

        MembershipDefinition.find($ctrl.linkedMembershipDefinitionId)
          .then(function(result) {
            $ctrl.linkedMembershipDefinition = result
            $ctrl.linkedAdditionalFieldsDisplay = linkedAdditionalFieldsDisplay()
            loadLinkedEligibility()
          }).catch(function(result) {
            $ctrl.linkedMembershipDefinition = null
          })
      }

      function loadLinkedEligibility() {
        EligibilityRuleSet.findAll({
          membership_definition_id: $ctrl.linkedMembershipDefinition.id,
          boss_organization_id: $ctrl.linkedMembershipDefinition.boss_organization_id,
          per_page: 100
        }).then(function(result) {
          $ctrl.linkedEligibilityRules = result && result.length ? EligibilityUtilService.getMembershipEligibilityRules(result[0]) : null
          $ctrl.linkedValidationDisplay = linkedValidationDisplay()
          $ctrl.showLinkedSection = showLinkedSection()
          setTrainings()
        }).catch(function(result) {
          $ctrl.linkedEligibilityRules = null
        })
      }

      function loadElectronicDocuments() {
        if (!$ctrl.edocsEnabled || ($ctrl.electronicDocuments && $ctrl.electronicDocuments.length)) return

        return ElectronicDocumentService.findAll({
          boss_organization_id: $ctrl.organization.id
        }).then(function(result) {
          $ctrl.electronicDocuments = result
          $ctrl.edocsLabel = EligibilityUtilService.getElectronicDocumentsLabel($ctrl.eligibilityRules, $ctrl.electronicDocuments)
        }).catch(function() {
          $ctrl.electronicDocuments = []
        })
      }

      function loadBackgroundScreenConfiguration() {
        if (!$ctrl.eligibilityEnabled || $ctrl.backgroundScreenConfigurationModeAny || $ctrl.backgroundScreenConfigurationModeLegacy) return

        return BackgroundScreenService.find($ctrl.organization.id).then(function(result) {
          $ctrl.backgroundScreenConfigurationModeAny = result.mode === 'any'
          $ctrl.backgroundScreenConfigurationModeLegacy = result.mode === 'ncsi_legacy'
          if ($ctrl.backgroundScreenConfigurationModeAny) {
            $ctrl.model.eligibility.background_check = 'any'
            $ctrl.backgroundChecks = null
          }
          else if ($ctrl.backgroundScreenConfigurationModeLegacy) {
            $ctrl.model.eligibility.background_check = 'ncsi_legacy'
            $ctrl.backgroundChecks = null
          }
        }).catch(function() {
          $ctrl.backgroundScreenConfigurationMode = null
        }).then(function() {
          loadBackgroundChecks()
        })
      }

      function loadBackgroundChecks() {
        if (!$ctrl.eligibilityEnabled || $ctrl.backgroundScreenConfigurationModeAny || $ctrl.backgroundScreenConfigurationModeLegacy || ($ctrl.backgroundChecks && $ctrl.backgroundChecks.length)) return

        return  EligibilityUtilService.loadBackgroundChecks($ctrl.organization.id)
          .then(function(result) {
            $ctrl.backgroundChecks = result
            $ctrl.bgCheckLabel = EligibilityUtilService.getBgCheckLabel($ctrl.eligibilityRules, $ctrl.backgroundChecks)
          }).catch(function(result) {
            $ctrl.backgroundChecks = []
          })
      }

      function loadSafesport() {
        return AbsorbService.getOrganizations()
          .then(function(result) {
            var orgIds = _.map(result.data, function(org) { return org.orgId })
            var allowed = _.contains(orgIds, currentOrg.id)
            $ctrl.hasSafesport = allowed
          }).catch(function(result) {
            $ctrl.hasSafesport = false
          })
      }

      function loadCustomCredentials() {
        if (!$ctrl.eligibilityEnabled || ($ctrl.customCredentials && $ctrl.customCredentials.length)) return

        CredentialManagementService.search(currentOrg.id)
          .then(function(result) {
            $ctrl.customCredentials = result
          }).catch(function() {
            $ctrl.customCredentials = []
          })
      }

      function showElectronicDocumentsInput() {
        return $ctrl.edocsEnabled && (!$ctrl.edit || $ctrl.model.is_editable)
      }

      function showCustomCredentialsInput() {
        return $ctrl.customCredentials.length && (!$ctrl.edit || $ctrl.model.is_editable)
      }

      function showEligibilityInfo() {
        return $ctrl.eligibilityEnabled && $ctrl.edit && !$ctrl.model.is_editable
      }

      function showEligibilityInput() {
        return $ctrl.eligibilityEnabled && (!$ctrl.edit || $ctrl.model.is_editable)
      }

      function showLinkedSection() {
        if (!$ctrl.linkedEligibilityRules) return false

        return $ctrl.linkedValidationDisplay.length > 0 ||
          $ctrl.linkedAdditionalFieldsDisplay.length > 0 ||
          $ctrl.linkedEligibilityRules.background_checks.required ||
          $ctrl.linkedEligibilityRules.trainings.required ||
          $ctrl.linkedEligibilityRules.waivers.required ||
          $ctrl.linkedEligibilityRules.electronic_documents.required ||
          $ctrl.linkedEligibilityRules.proof_of_birth.required ||
          $ctrl.linkedEligibilityRules.fifa_integration.required ||
          $ctrl.linkedEligibilityRules.violation_review.required ||
          $ctrl.linkedEligibilityRules.custom_credentials.required
      }

      function linkedValidationDisplay() {
        var translations = []
        if (linkedValidationRuleExists('phone_numbers')) translations.push(i18ng.t('MEMBERSHIP_DEFINITION_ELIGIBILITY.LABELS.phone_number'))
        if (linkedValidationRuleExists('addresses')) translations.push(i18ng.t('MEMBERSHIP_DEFINITION_ELIGIBILITY.LABELS.address'))
        if (linkedValidationRuleExists('graduation_year')) translations.push(i18ng.t('MEMBERSHIP_DEFINITION_ELIGIBILITY.LABELS.graduation_year'))
        if (translations.length) translations.unshift(i18ng.t('MEMBERSHIP_DEFINITION_ELIGIBILITY.LABELS.additional_profile_data'))

        return translations.join(', ')
      }

      function linkedAdditionalFieldsDisplay() {
        var translations = []
        if (linkedAdditionalFieldExists('ethnicities')) translations.push(i18ng.t('MEMBERSHIP_DEFINITION_ELIGIBILITY.LABELS.ethnicities'))
        if (linkedAdditionalFieldExists('disability')) translations.push(i18ng.t('MEMBERSHIP_DEFINITION_ELIGIBILITY.LABELS.disability'))
        if (linkedAdditionalFieldExists('military_status')) translations.push(i18ng.t('MEMBERSHIP_DEFINITION_ELIGIBILITY.LABELS.military_status'))

        return translations.join(', ')
      }

      function linkedValidationRuleExists(rule) {
        if (!$ctrl.linkedMembershipDefinition) return false

        return _.includes($ctrl.linkedMembershipDefinition.validation_rules, rule)
      }

      function linkedAdditionalFieldExists(field) {
        if (!$ctrl.linkedMembershipDefinition) return false

        return _.includes($ctrl.linkedMembershipDefinition.additional_fields, field)
      }

      function showEligibilityRule(rule) {
        switch (rule) {
          case 'custom_credential': return !$ctrl.linkedEligibilityRules || !$ctrl.linkedEligibilityRules.custom_credentials.required
          case 'background_checks': return !$ctrl.linkedEligibilityRules || !$ctrl.linkedEligibilityRules.background_checks.required
          case 'trainings': return $ctrl.trainings.length
          case 'proof_of_birth': return $ctrl.proofOfBirthApprovalFlowEnabled && (!$ctrl.linkedEligibilityRules || !$ctrl.linkedEligibilityRules.proof_of_birth.required)
          case 'fifa_integration': return $ctrl.fifaIntegrationEnabled && (!$ctrl.linkedEligibilityRules || !$ctrl.linkedEligibilityRules.fifa_integration.required)
          case 'violation_review': return $ctrl.violationReviewEnabled && (!$ctrl.linkedEligibilityRules || !$ctrl.linkedEligibilityRules.violation_review.required)
          default: return true
        }
      }

      function setTrainings() {
        $ctrl.trainings = []
        if (_.result(featureToggles, 'aps') && !findLinkedTrainingItem('aps')) {
          $ctrl.trainings.push({
            value: 'aps',
            label: 'Abuse Prevention Systems Certification'
          })
        }
        if (_.result(featureToggles, 'safesport') && $ctrl.hasSafesport && !findLinkedTrainingItem('safesport')) {
          $ctrl.trainings.push({
            value: 'safesport',
            label: 'SafeSport Certification'
          })
        }
        if (_.result(featureToggles, 'sideline_sports_doc') && !findLinkedTrainingItem('sideline_sports')) {
          $ctrl.trainings.push({
            value: 'sideline_sports',
            label: 'Sideline Sports Doc Certification'
          })
        }
        if (_.result(featureToggles, 'litmos_training') && !findLinkedTrainingItem('litmos_training')) {
          $ctrl.trainings.push({
            value: 'litmos_impact',
            label: 'Impact Training',
          })
        }
        if (_.result(featureToggles, 'lms_concussion_training') && !findLinkedTrainingItem('lms_crash_course')) {
          $ctrl.trainings.push({
            value: 'lms_crash_course',
            label: 'CrashCourse: Concussion Education',
          })
        }
      }

      function findLinkedTrainingItem(originator_type) {
        if (!$ctrl.linkedEligibilityRules) return false

        return _.some($ctrl.linkedEligibilityRules.trainings.items, function(item) { return item.originator_type == originator_type })
      }

      function trainingChanged() {
        if ($ctrl.trainingRequiresAddress()) {
          $ctrl.model.profile_fields.address_required = true
        }
      }

      function trainingRequiresAddress() {
        // require address if sideline sports training selected
        return $ctrl.model.eligibility.training_required &&
          $ctrl.model.eligibility.trainings &&
          $ctrl.model.eligibility.trainings.includes('sideline_sports')
      }

      function validateAndSubmit() {
        trackAction(getFormErrors(), 'NextButtonEligibility.Click')
        $ctrl.eligibilityForm.validateAndSubmit($ctrl.next)
      }

      function getFormErrors() {
        var formErrors = $ctrl.eligibilityForm.$error.required
        if (formErrors) {
          return getInvalidFields(formErrors)
        }
        return null
      }

      function edocsRequired() {
        return $ctrl.linkedEligibilityRules.waivers.required || $ctrl.linkedEligibilityRules.electronic_documents.required
      }

      function customCredentialsRequired() {
        return $ctrl.linkedEligibilityRules.custom_credentials.required
      }

      function linkedElectronicDocumentDisplay() {
        var rules = $ctrl.linkedEligibilityRules.electronic_documents.items.concat($ctrl.linkedEligibilityRules.waivers.items)
        return _.map(rules, function(item) { return item.display_name }).join(', ')
      }
    }
  })
