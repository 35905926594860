angular.module('org-admin')
  .directive('upfrontPaymentForm', function() {

    return {
      scope: {},
      bindToController: {
        maxUpfrontEqualsZero: '<',
        maxUpfrontPayment: '<',
        upfrontPayment: '='
      },
      controllerAs: 'ctrl',
      templateUrl: '/static/org/send-invoices/upfront-payment-form.html',
      controller: function() {

        var ctrl = this
        ctrl.upfrontPaymentInput = ctrl.upfrontPayment

        ctrl.applyPartialPayment = function() {
          ctrl.upfrontPayment = ctrl.upfrontPaymentInput
        }

      }
    }
  })
