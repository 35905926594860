angular.module('org-admin')
  .component('governingSeasonAdd', {
    bindings: {
      cancel: '<',
      confirm: '<'
    },
    templateUrl: '/static/org/governing-seasons/governing-season-add.html',
    controller: function(_, $q, appPermission, currentOrg, setAs, setWhile, showError, CredentialDefinition, Program) {
      if (!appPermission.governingSeasons.editable) return showError('404')

      var ctrl = this
      ctrl.$onInit = setWhile(ctrl, 'loading', onInit)

      ctrl.programTypes = [
        {
          key: 'Club',
          name: 'club_credential_definition',
          setName: 'set_club_credential_definition'
        },
        {
          key: 'League',
          name: 'league_credential_definition',
          setName: 'set_league_credential_definition'
        }
      ]
      ctrl.governingSeason = {
        org_id: currentOrg.id,
        type: 'GoverningSeason',
        sport_key: null,
        credential_definition_params: {},
        $showCredentialDefinition: {}
      }

      ctrl.submit = function() {
        return Program.create(ctrl.governingSeason)
          .then(ctrl.confirm)
      }

      ctrl.validators = {
        unique_credential_definition: function(values) {
          var valuesArray = _.compact(_.values(values))
          return _.uniq(valuesArray).length === valuesArray.length
        },
        require_credential_definition: function(values) {
          return _.compact(_.values(values)).length
        }
      }

      function onInit() {
        return $q.all([
          loadCredentialDefinitions()
        ])
      }

      function loadCredentialDefinitions() {
        return CredentialDefinition.forOrg(currentOrg.id)
          .then(setAs(ctrl, 'credentialDefinitions'))
          .then(function() {
            if (ctrl.credentialDefinitions.length == 1) {
              ctrl.governingSeason.credential_definition_params.Club = ctrl.credentialDefinitions[0].id
            }
          })
      }
    }
  })
