angular.module('pl-shared')

// see http://www.competa.com/blog/limit-input-to-uppercase-or-lowercase-in-angular/
// note auto_formats may not work correctly on [input type="email", "number"]

  .directive('autoFormat', function($parse, $filter) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, element, attrs, modelCtrl) {
        modelCtrl.$parsers.push(viewToModel)
        modelCtrl.$formatters.push(modelToView)

        function options() {
          return scope.$eval(attrs.autoFormatOptions) || {}
        }

        function viewToModel(inputValue) {
          var modelValue = autoFormatForModel(inputValue, attrs.autoFormat, options())
          var viewValue = modelToView(modelValue)
          if (viewValue !== inputValue) {
            var caretPos = element[0].selectionStart
            modelCtrl.$setViewValue(viewValue)
            modelCtrl.$commitViewValue()
            modelCtrl.$validate()
            modelCtrl.$render()
            element[0].selectionStart = element[0].selectionEnd = caretPos
          }
          return modelValue
        }

        function modelToView(modelValue) {
          return autoFormatForView(modelValue, attrs.autoFormat, options())
        }
      }
    }

    function autoFormatForView(txt, transform, options) {
      switch (transform) {
        case 'phone': return $filter('tel')(txt)
        case 'postal_code': return formatPostalCode(txt, options.country)
        case 'currency':
          txt = _.trim(txt)
          return txt && $filter('currency')(txt, '', 2)
        default: return txt
      }
    }

    function autoFormatForModel(txt, transform, options) {
      switch (transform) {
        case 'upper': return txt.toUpperCase()
        case 'lower': return txt.toLowerCase()
        case 'capitalize': return capitalizeEachWord(txt)
        case 'integer': return formatInt(txt)
        case 'natural': return txt.replace(/[^0-9]/g, '')
        case 'phone': return txt.replace(/[^0-9]/g, '')
        case 'postal_code': return formatPostalCode(txt, options.country)
        case 'currency': return formatCurrency(txt)
        default: return txt
      }
    }

    function formatPostalCode(code, country) {
      switch (country) {
        case 'CA':
        case 'CAN': return formatCanadianPostalCode(code)
        case 'US':
        case 'USA': return formatUSPostalCode(code)
        default: return code
      }
    }

    function formatCanadianPostalCode(code) {
      if (!code) return null
      code = code.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()
      return code.substring(0, 3) + ' ' + code.substring(3, 6)
    }

    function formatUSPostalCode(code) {
      if (!code) return null
      code = code.replace(/[^0-9]/g, '')
      if (code.length === 9) {
        code = code.substring(0, 5) + '-' + code.substring(5, 9)
      }
      return code
    }

    function formatInt(txt) {
      var negativeNumber = txt.indexOf('-') === 0
      txt = txt.replace(/[^0-9]/g, '')
      return negativeNumber ? '-' + txt : txt
    }

    function formatCurrency(txt) {
      var str = txt.replace(/[^0-9.]/g, '')
      var number = Math.floor((parseFloat(str) * 100).toFixed(2)) / 100
      return !str || isNaN(number) ? null : number
    }

    function capitalizeEachWord(str) {
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })
    }

  })
