/**
 * usage:
 *  dialog.confirm({
 *            component: 'persona-list-modal',
 *            scope: $scope,
 *            attrs: {
 *              heading: 'Title for the Modal',
 *              modalSize: 'medium',
 *              personas: arrayOfPersonaDetails
 *            }
 *         })
 *
 * where arrayOfPersonaDetails is array of objects like:
 *   [{
 *     id: personaId,
 *     name: personaFullName,
 *     email: personaEmail,
 *     date_of_birth: personaDob,
 *     avatar_url: urlToPersonaAvatar
 *   }, ...]
 */
angular.module('pl-shared')
  .component('personaListModal', {
    bindings: {
      cancel: '<',
      confirm: '<',
      heading: '<',
      modalSize: '<',
      personas: '<'
    },
    templateUrl: '/static/shared/components/persona-list-modal/persona-list-modal.html',
    controller: function(_, i18ng, $sce, $q) {
      var $ctrl = this
      var SIZES = ['extra-small', 'small', 'medium', 'medium-large', 'large', 'xxlarge']
      var DEFAULT_SIZE = 'large'

      if (SIZES.indexOf($ctrl.modalSize) === -1) $ctrl.modalSize = DEFAULT_SIZE
    }
  })
