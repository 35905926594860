angular.module('org-admin')
  .component('rosterColumns', {
    bindings: {
      close: '&',
      availableColumnsSrc: '<',
      staticColumnText: '@',
      dynamicColumns: '<'
    },
    templateUrl: '/static/org/roster-management/roster-columns.html',
    controller: function($timeout, gtm, i18ng, RosteringState, setAs, setWhile) {

      var ctrl = this
      ctrl.state = RosteringState
      var MAX_SHOWN = ctrl.MAX_SHOWN = 3

      ctrl.$onInit = setWhile(ctrl, 'loading', function() {
        ctrl.shownColumns = _.clone(ctrl.dynamicColumns.shown)
        return ctrl.dynamicColumns.getAvailableColumns(ctrl.availableColumnsSrc)
          .then(setAs(ctrl, 'availableColumns'))
      })

      ctrl.dropColumn = function(index, item) {
        $timeout(function() { // $timeout runs a digest afterward and looks smoother (no flash)
          var prevItem = _.find(ctrl.shownColumns, { key: item.key })
          ctrl.shownColumns.splice(index - 2, 0, item) // -2 for name and header items
          _.pull(ctrl.shownColumns, prevItem)
        })
        return true
      }

      ctrl.addToShownColumns = function(column) {
        swapLists(column, ctrl.shownColumns, ctrl.availableColumns)
        ctrl.shownColumnTitles = _.map(ctrl.shownColumns, 'title').join(', ')
        ctrl.gtmCategory = gtm.getCategoryString(ctrl.state)
      }

      ctrl.removeFromShownColumns = function(column) {
        swapLists(column, ctrl.availableColumns, ctrl.shownColumns)
        ctrl.shownColumnTitles = _.map(ctrl.shownColumns, 'title').join(', ')
        ctrl.gtmCategory = gtm.getCategoryString(ctrl.state)
      }

      ctrl.apply = setWhile(ctrl, 'submitting', function() {
        var shownColumns = _.map(ctrl.shownColumns, 'key')
        return ctrl.dynamicColumns.updateShownColumns(shownColumns)
          .then(ctrl.dynamicColumns.save)
          .then(ctrl.close)
      })

      function swapLists(column, toList, fromList) {
        _.pull(fromList, column)
        toList.push(column)
      }

    }
  })
