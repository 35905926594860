angular.module('org-admin')
  .service('ActivityReport', function(DSHttpAdapter, ENV) {
    function deserialize(response) {
      var result = response.data.result
      var metadata = response.data.metadata
      return {
        credits: result.credits,
        offline_payments: result.offline_payments,
        offline_refunds: result.offline_refunds,
        online_payments: result.online_payments,
        online_refunds: result.online_refunds,
        invoices: result.invoices,
        voids: result.voids,
        totals: {
          credits: metadata.total_credits,
          offline_payments: metadata.total_payments_offline,
          offline_refunds: metadata.total_refunds_offline,
          online_payments: metadata.total_payments_online,
          online_refunds: metadata.total_refunds_online,
          invoices: metadata.total_invoices,
          voids: metadata.total_voids
        }
      }
    }

    return {
      findAll: function(orgId, filters) {
        return  DSHttpAdapter
          .GET(ENV.urls.apiProxy + '/reports/activity/' + orgId, {
            params: filters || {},
            headers: { Accept: 'application/json' },
            withCredentials: true,
          })
          .then(deserialize)
      }
    }
  })
