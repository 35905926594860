'use strict'

// This service was updates to shim old Alerts calls to use the SeToastService. The toast
// service did not have exact parity. We kept internal code to ignore some alerts, but
// removed the ability to show a title and message (as separate styles) and to create
// alerts that never dissapear.

angular.module('pl-shared')
  .factory('Alerts', function(SeToastService, i18ng, userSettings) {

    return {
      info: show('info'),
      success: show('success'),
      alert: show('error'),
      warn: show('warning'),
      // old method names
      show: show('info'),
      error: show('error'),
      warning: show('warning'),
      clearIgnoreStats: clearIgnoreStats
    }

    function show(method) {
      return function(key, options) {
        if (!key || shouldIgnoreAlert(options)) return

        var message = i18ng.t(key, options)
        if (options && options.title) message = options.title + ' – ' + message
        SeToastService[method](message)
        updateShownCount(alert)

        return alert
      }
    }

    function shouldIgnoreAlert(options) {
      if (!!options && !!options.ignoreOptions && !!options.ignoreOptions.id) {
        var ignoreData = userSettings.get('alerts.' + options.ignoreOptions.id)
        if (!!ignoreData && !!ignoreData.countThreshold) {
          var countThreshold = ignoreData.countThreshold
          var currentCount = ignoreData.currentCount

          if (!countThreshold || currentCount >= countThreshold) {
            return true
          }
        }
      }

      return false
    }

    function updateShownCount(alert) {
      if (!alert.ignoreOptions || !alert.ignoreOptions.id || !alert.ignoreOptions.countThreshold) return

      var currentCount = 0

      var currentIgnoreData = userSettings.get('alerts.' + alert.ignoreOptions.id)
      if (currentIgnoreData) {
        currentCount = currentIgnoreData.currentCount
      }

      var ignoreData = {
        id: alert.ignoreOptions.id,
        countThreshold: alert.ignoreOptions.countThreshold,
        currentCount: currentCount + 1
      }

      userSettings.set('alerts.' + alert.ignoreOptions.id, ignoreData)
    }

    function clearIgnoreStats(id) {
      userSettings.remove('alerts.' + id)
    }

  })
