/**
 * A service that groups the resources that filter panel components use and provides a simple api for them
 * to interact with. This service is meant to only be used by filter panel components.
 */
angular.module('org-admin')
  .factory('filterPanelService', function(_, $filter, $q, i18ng, OrganizationProfileSchema, Survey, SurveySchema, GroupPersona, MembershipDefinition, CredentialDefinition) {
    var ORGANIZATION_PROFILE_PREFIX = 'organization_profile'

    var FILTER_TYPE_MAPPING = {
      'selector': 'pulldown'
    }

    var service = {
      getSurveys: getSurveys,
      getDataSources: getDataSources,
      getIndexedDataSourceIds: getIndexedDataSourceIds,
      getDataSourceById: getDataSourceById,
      getTeamRosterGroups: getTeamRosterGroups,
      getSeasons: getSeasons,
      getMemberships: getMemberships,
      getDivisions: getDivisions,
      getTeams: getTeams,
      getMembershipFields: getMembershipFields,
      filterSourceHasMemDef: filterSourceHasMemDef,
      getTeamInstanceFields: getTeamInstanceFields,
      getSeasonFields: getSeasonFields,
      getProfileFields: getProfileFields,
      getSurveyFields: getSurveyFields,
      getField: getField,
      getLogicOptionsByType: getLogicOptionsByType,
      isSEProfileField: isSEProfileField,
      isOrgProfileField: isOrgProfileField,
      serializeFiltersAsParam: serializeFiltersAsParam,
      deserializeFiltersParam: deserializeFiltersParam,
      getSuspensionFields: getSuspensionFields
    }

    return service

    /**
     * Serializes as base64 encoded string for use as a url param or cookie
     * @param {FilterCollection} filters
     * @return string | null
     */
    function serializeFiltersAsParam(filterArray) {
      return (filterArray && filterArray.length) ? btoa(JSON.stringify(filterArray)) : null
    }

    /**
     * Deserializes base64 encoded string to array of filters. Invers of serializeFiltersAsParam
     * @param {string} filterString
     * @return FilterCollection | null
     */
    function deserializeFiltersParam(filterString) {
      if (!filterString) return null
      var filters
      try {
        filters = JSON.parse(atob(decodeURIComponent(filterString)))
      }
      catch (error) {
        return null
      }

      return _.isArray(filters) && filters.length > 0 ? filters : null
    }

    /**
     * Retrieves the data sources for the provided org.
     * @deprecated this does not return what should be considered the full set of data sources any more
     *
     * @param {number} orgId - The id of the organization.
     * @return {Promise.<object.<surveys, profileFieldSchema>>} - A promise that is resolved with surveys and profiles that can
     *   be used as a data source.
     */
    function getDataSources(params) {
      return $q.all({
        surveys: getSurveys(params),
        profileFieldSchema: OrganizationProfileSchema.find(params.orgId)
      })
    }

    function getSurveys(params) {
      return  Survey.findAll({
        exclude_team_only_surveys: 'true',
        organization_id: params.orgId,
        page: params.page,
        per_page: params.perPage,
        search: params.search,
        survey_ids: params.surveyIds,
        survey_types: 'registration',
        exclude_forms: true,
        with_results: true
      })
    }

    /**
     * Retrieves only data sources that have already been indexed
     */
    function getIndexedDataSourceIds(params) {
      return Survey.findAll({
        exclude_team_only_surveys: 'true',
        organization_id: params.orgId,
        per_page: 'all',
        survey_types: 'registration',
        with_results: true,
        fields: 'id',
        is_es_indexed: true,
        exclude_forms: true
      })
    }

    /**
     * Retrieves the data source by id.
     *
     * @param {string | number} id - The id of the data source. If it's a number, assumed to be survey_id, strings will be split on dot
     * @return {Promise.<object>} - A promise that is resolved with survey that can
     *   be used as a data source.
     */
    function getDataSourceById(id) {
      var bucket
      var sourceId
      if (/^\d+$/.test(id)) {
        bucket = 'survey_results'
        sourceId = id
      }
      else {
        var parts = id.toString().split('.')
        bucket = parts[0]
        sourceId = parts[1]
      }
      if (bucket === 'survey_results') return  Survey.find(sourceId)
      else if (bucket === 'memberships') return getMembershipDefinition(sourceId)
      else if (bucket === 'team_instance_ids') return GroupPersona.find(sourceId)
      else return $q.resolve(null)
    }

    /**
     * Retrieves profile fields.
     *
     * @param {number} orgId - The id of the organization
     * @param {string} profileType - The profile type. Can be either `se_profile` or `orgProfile`.
     * @returns {Promise} - A promise that is resolved with the the profile fields.
     */
    function getProfileFields(orgId, profileType) {
      return OrganizationProfileSchema.find(orgId)
        .then(function(fieldSchema) {
          return profileType === 'se_profile' ? _.filter(fieldSchema.schema, isSEProfileField) : _.filter(fieldSchema.schema, isOrgProfileField)
        })
    }

    /**
     * Retrieves roster groups.
     *
     * @returns {Promise} - A promise that is resolved with the roster groups.
     */
    function getTeamRosterGroups(params) {
      return GroupPersona.findAll({
        org_id: params.orgId,
        team_instance_id: params.team_instance_id,
        type: 'roster',
        page: params.page,
        per_page: params.perPage,
        search: params.search
      })
    }

    /**
     * Retrieves seasons.
     *
     * @returns {Promise} - A promise that is resolved with the seasons for a given org_id.
     */
    function getSeasons(params) {
      return OrganizationProfileSchema.indexed_seasons(params.orgId)
    }

    /**
     * Retrieves memberships
     *
     * @returns {Promise} - A promise that is resolved with memberships for a given org_id
     */
    function getMemberships(params) {
      return OrganizationProfileSchema.indexed_membership_definitions(params.orgId)
    }

    /**
     * Retrieves divisions.
     *
     * @returns {Promise} - A promise that is resolved with the divisions for a given org_id and season_id.
     */
    function getDivisions(params) {
      return OrganizationProfileSchema.indexed_divisions(params.orgId, params.seasonId)
    }

    /**
     * Retrieves teams.
     *
     * @returns {Promise} - A promise that is resolved with the teams for a given org_id and season_id.
     */
    function getTeams(params) {
      return OrganizationProfileSchema.indexed_teams(params.orgId, params.seasonId)
    }

    /**
     * return filterable fields when data source is a membership
     * @param {object.<orgId, membershipDefinitionId>} params - optional
     * @return {Promise <Array>}
     */
    function getMembershipFields(params) {
      var membershipId = i18ng.t('FILTER_PANEL_SERVICE.MEMBERSHIP_FIELDS.membership_id')
      var membershipName = i18ng.t('FILTER_PANEL_SERVICE.MEMBERSHIP_FIELDS.membership_name')
      var hasMembership = i18ng.t('FILTER_PANEL_SERVICE.MEMBERSHIP_FIELDS.has_membership')
      var purchaseDate = i18ng.t('FILTER_PANEL_SERVICE.MEMBERSHIP_FIELDS.purchase_date')
      var effectiveDate = i18ng.t('FILTER_PANEL_SERVICE.MEMBERSHIP_FIELDS.effective_date')
      var expirationDate = i18ng.t('FILTER_PANEL_SERVICE.MEMBERSHIP_FIELDS.expiration_date')
      var status = i18ng.t('FILTER_PANEL_SERVICE.MEMBERSHIP_FIELDS.status')
      var statusValid = i18ng.t('FILTER_PANEL_SERVICE.MEMBERSHIP_FIELDS.status_valid')
      var statusPending = i18ng.t('FILTER_PANEL_SERVICE.MEMBERSHIP_FIELDS.status_pending_text')
      var statusExpired = i18ng.t('FILTER_PANEL_SERVICE.MEMBERSHIP_FIELDS.status_expired')
      var statusCanceled = i18ng.t('FILTER_PANEL_SERVICE.MEMBERSHIP_FIELDS.status_canceled')

      var fields = [
        { text: membershipId, name: membershipId, id: 'number', key: 'number', type: 'short_text' },
        { text: hasMembership, name: hasMembership, id: 'has_membership', key: 'has_membership', type: 'has_membership' },
        { text: purchaseDate, name: purchaseDate, id: 'purchased_date', key: 'purchased_date', type: 'date' },
        { text: effectiveDate, name: effectiveDate, id: 'effective_date', key: 'effective_date', type: 'date' },
        { text: expirationDate, name: expirationDate, id: 'expiration_date', key: 'expiration_date', type: 'date' },
        { text: status, name: status, id: 'membership_status', key: 'membership_status', type: 'membership_status', choice_elements: [
          { choice: { name: statusValid, key: 'valid' } },
          { choice: { name: statusPending, key: 'pending' } },
          { choice: { name: statusExpired, key: 'expired' } },
          { choice: { name: statusCanceled, key: 'canceled' } }
        ] }
      ]

      if (params.orgId) {
        var memDefPromise = params.membershipDefinitionId ? getMembershipDefinition : getMemberships
        return memDefPromise(params.membershipDefinitionId || params)
          .then(function(memDefs) {
            memDefs = Array(memDefs).flatten()
            var choiceElements = _.map(memDefs, function(memDef) {
              return { choice: { name: memDef.membership_definition_name || memDef.name, key: memDef.membership_definition_id || memDef.id } }
            })
            fields.push({
              text: membershipName,
              name: membershipName,
              id: 'membership_name',
              key: 'membership_definition_id', // maps to "field" param in contact list filter params
              type: 'membership_name', // maps to question type filters in org persona decorator
              choice_elements: choiceElements
            })
            if (params.membershipDefinitionId && _.isEmpty(memDefs[0].membership_definitions_credential_definitions)) {
              return $q.resolve(fields)
            }
            return getMembershipFieldsWithClassifications(params, fields)
          })
      }
      else return $q.resolve(fields)
    }

    function getMembershipFieldsWithClassifications(params, fields) {
      return OrganizationProfileSchema
        .membership_organizations_by_classification(params.orgId, params.membershipDefinitionId)
        .then(function(orgsByClass) {
          _.forEach(orgsByClass, function(classOrgs) {
            fields.push({
              text: i18ng.t('FILTER_PANEL_SERVICE.MEMBERSHIP_FIELDS.affiliation_classification', { classification: classOrgs.classification }),
              name: i18ng.t('FILTER_PANEL_SERVICE.MEMBERSHIP_FIELDS.affiliation_classification', { classification: classOrgs.classification }),
              type: 'membership_affiliation_path',
              id: 'affiliation_path.classification.' + classOrgs.classification,
              key: 'affiliation_path.classification.' + classOrgs.classification,
              choice_elements: _.map(classOrgs.organizations, function(org) {
                return { choice: { name: org.organization_name, key: org.organization_id } }
              })
            })
            fields.push({
              text: i18ng.t('FILTER_PANEL_SERVICE.MEMBERSHIP_FIELDS.affiliation_classification_id', { classification: classOrgs.classification }),
              name: i18ng.t('FILTER_PANEL_SERVICE.MEMBERSHIP_FIELDS.affiliation_classification_id', { classification: classOrgs.classification }),
              type: 'membership_affiliation_path',
              id: 'affiliation_path.classification_id.' + classOrgs.classification,
              key: 'affiliation_path.classification_id.' + classOrgs.classification,
              choice_elements: _.map($filter('orderBy')(classOrgs.organizations, 'organization_id'), function(org) {
                return { choice: { name: org.organization_id, key: org.organization_id } }
              })
            })
          })
          return $q.resolve(fields)
        })
    }

    function getMembershipDefinition(memDefId) {
      if (_.isEmpty(memDefId)) return $q.when()

      return MembershipDefinition.find(memDefId)
    }

    /**
     * Returns true if at least one filter has a mem def ID in the source, e.g. source = 'membership.123-abc'.
     * Used to set the useMemberidSource flag, which is passed to the contacts customize component to support
     * legacy membership filter formats.
     *
     */
    function filterSourceHasMemDef(filters) {
      return filters &&
        _.some(filters, function(filter) {
          return _.contains(_.get(filter, 'source'), 'memberships.')
        })
    }

    function getSuspensionFields() {
      var active = i18ng.t('FILTER_PANEL_SERVICE.SUSPENSION_FIELDS.active')
      var expired = i18ng.t('FILTER_PANEL_SERVICE.SUSPENSION_FIELDS.expired')
      var startDate = i18ng.t('FILTER_PANEL_SERVICE.SUSPENSION_FIELDS.start_date')
      var endDate = i18ng.t('FILTER_PANEL_SERVICE.SUSPENSION_FIELDS.end_date')
      var appliedDate = i18ng.t('FILTER_PANEL_SERVICE.SUSPENSION_FIELDS.applied_date')
      var reason = i18ng.t('FILTER_PANEL_SERVICE.SUSPENSION_FIELDS.reason')
      var administrative = i18ng.t('FILTER_PANEL_SERVICE.SUSPENSION_FIELDS.administrative')
      var misconduct = i18ng.t('FILTER_PANEL_SERVICE.SUSPENSION_FIELDS.misconduct')
      var status = i18ng.t('FILTER_PANEL_SERVICE.SUSPENSION_FIELDS.status')

      var fields = [
        { text: active, name: active, id: 'active', key: 'active_suspension', type: 'suspensions' },
        { text: expired, name: expired, id: 'expired', key: 'expired_suspension', type: 'suspensions' },
        { text: startDate, name: startDate, id: 'effective_at', key: 'effective_at', type: 'date' },
        { text: endDate, name: endDate, id: 'expires_at', key: 'expires_at', type: 'date' },
        { text: appliedDate, name: appliedDate, id: 'created_at', key: 'created_at', type: 'date' },
        { text: reason, name: reason, id: 'reason', key: 'reason', type: 'suspension_reason', choice_elements: [
          { choice: { name: administrative, key: 'administrative' } },
          { choice: { name: misconduct, key: 'misconduct' } }
        ] },
        { text: status, name: status, id: 'status', key: 'status', type: 'suspensions' },
      ]

      return $q.resolve(fields)
    }

    /**
     * return filterable fields when data source is a team_instance_ids
     * @param {object.<orgId, page, perPage>} params - consistent with other paginatable params, but ignored here
     * @return {Promise <Array>}
     */
    function getTeamInstanceFields(params) {
      var teamMembers = i18ng.t('FILTER_PANEL_SERVICE.TEAM_INSTANCE_FIELDS.team_members')
      var notTeamMembers = i18ng.t('FILTER_PANEL_SERVICE.TEAM_INSTANCE_FIELDS.not_team_members')

      return $q.resolve([
        { text: teamMembers, id: 'equal', key: 'equal', type: 'team_instance_ids' },
        { text: notTeamMembers, id: 'not_equal', key: 'not_equal', type: 'team_instance_ids' }
      ])
    }

    /**
     * return filterable fields when data source is a season
     * @param {object.<orgId, seasonId>} params - consistent with other paginatable params, but ignored here
     * @return {Promise <Array>}
     */
    function getSeasonFields(params) {
      var seasonMember = i18ng.t('FILTER_PANEL_SERVICE.SEASON_FIELDS.season_member')
      var division = i18ng.t('FILTER_PANEL_SERVICE.SEASON_FIELDS.division')
      var team = i18ng.t('FILTER_PANEL_SERVICE.SEASON_FIELDS.team')
      var role = i18ng.t('FILTER_PANEL_SERVICE.SEASON_FIELDS.role')
      var player = i18ng.t('FILTER_PANEL_SERVICE.SEASON_FIELDS.player')
      var staff = i18ng.t('FILTER_PANEL_SERVICE.SEASON_FIELDS.staff')

      var fields = [
        { text: seasonMember, id: 'season_member', key: 'season_member', type: 'season_member' },
        {
          text: role,
          id: 'role',
          key: 'roster_role',
          type: 'role',
          choice_elements: [
            { choice: { name: player, key: 'player' } },
            { choice: { name: staff, key: 'staff' } }
          ]
        }
      ]

      return Promise.all([getDivisions(params), getTeams(params)])
        .then(function([divisions, teams]) {
          var divisionChoiceElements = _.map(divisions, function(division) {
            return { choice: { name: division.division_name, key: division.division_id } }
          })
          fields.push({
            text: division,
            name: division,
            id: 'division',
            key: 'program_secondary_originator_id', // maps to "field" param in contact list filter params
            type: 'division', // maps to question type filters in org persona decorator
            choice_elements: divisionChoiceElements
          })
          var teamChoiceElements = _.map(teams, function(team) {
            return { choice: { name: team.team_name, key: team.team_id } }
          })
          fields.push({
            text: team,
            name: team,
            id: 'team',
            key: 'team_id', // maps to "field" param in contact list filter params
            type: 'team', // maps to question type filters in org persona decorator
            choice_elements: teamChoiceElements
          })
          return $q.resolve(fields)
        })
    }

    /**
     * Retrieves the fields for the provided survey.
     *
     * @param {number} orgId - The id of the organization the survey belongs to.
     * @param {number} surveyId - The id of the survey.
     * @returns {Promise} - A promise that is resolved with the Survey Schema.
     */
    function getSurveyFields(orgId, surveyId) {
      if (_.startsWith(surveyId, 'survey_results.')) {
        surveyId = parseInt(surveyId.split('.')[1], 10)
      }
      return SurveySchema.find(surveyId)
    }

    /**
     * Retrieves a single field with the provided key.
     *
     * @param {number} orgId - The id of the organization.
     * @param {number|string} dataSourceId - The id of the data source the field belongs to.
     *        Can be `team_instance_ids`, `se_profile`, `orgProfile`, `memberships`, `suspensions`, a season id, or a survey id.
     * @param {string} fieldKey - The unique field key.
     * @return {object.<key, name, type, choice_elements>} - The field that matches the provided key, or null if not found.
     */
    function getField(orgId, dataSourceId, fieldKey) {
      var bucket
      if (/^\d+$/.test(dataSourceId)) bucket = 'survey_results'
      else {
        var parts = dataSourceId.toString().split('.')
        bucket = parts[0]
        if (parts.length > 1) dataSourceId = parts[1]
      }

      if (bucket === 'team_instance_ids') {
        return getTeamInstanceFields({ orgId: orgId })
          .then(function(fields) {
            return _.find(fields, { id: fieldKey })
          })
      }
      else if (bucket === 'memberships') {
        var memDefId = dataSourceId === 'memberships' ? null : dataSourceId
        return getMembershipFields({ orgId: orgId, membershipDefinitionId: memDefId })
          .then(function(fields) {
            return _.find(fields, { id: fieldKey == 'membership_definition_id' ? 'membership_name' : fieldKey })
          })
      }
      else if (bucket === 'survey_results') {
        return getSurveyFields(orgId, dataSourceId)
          .then(function(result) {
            var field = _.find(result.metadata_question_elements, { key: fieldKey }) || _.chain(result.schema).map('question_elements').flatten().find({ key: fieldKey }).value()

            return field
          })
      }
      else if (bucket === 'suspensions') {
        return getSuspensionFields().then(function(fields) { return _.find(fields, { key: fieldKey }) })
      }
      else if (bucket === 'roster_personas') {
        return getSeasonFields({ orgId: orgId, seasonId: dataSourceId })
          .then(function(fields) {
            return _.find(fields, { id: fieldKey })
          })
      }
      else {
        return getProfileFields(orgId, dataSourceId)
          .then(function(fields) {
            return _.find(fields, { key: fieldKey })
          })
      }
    }

    /**
     * Retrieves filters.
     *
     * @param {string} type - The question type.
     * @returns {Promise} - A promise that is resolved with filters
     */
    function getLogicOptionsByType(type) {
      return OrganizationProfileSchema.filters()
        .then(function(filters) {
          return filters[FILTER_TYPE_MAPPING[type] || type]
        })
    }

    /**
     * Determines if the provided field is a SE Profile field.
     *
     * @param {object.<key>} fieldSchema - The field to check.
     * @returns {boolean} - True if the field is a SE Profile field. False otherwise.
     */
    function isSEProfileField(fieldSchema) {
      return fieldSchema.key && fieldSchema.key.lastIndexOf(ORGANIZATION_PROFILE_PREFIX, 0) !== 0 && fieldSchema.show_filter !== false
    }

    /**
     * Determines if the provided field is an Org Profile field.
     *
     * @param {object.<key>} fieldSchema - The field to check.
     * @returns {boolean} - True if the field if is a Org Profile field. False otherwise.
     */
    function isOrgProfileField(fieldSchema) {
      return fieldSchema.key.lastIndexOf(ORGANIZATION_PROFILE_PREFIX, 0) === 0 && fieldSchema.show_filter !== false
    }

  })
  .config(function($provide) {
    $provide.decorator('filterPanelService', function(_, $cacheFactory, $delegate) {

      var cache = $cacheFactory('filterPanelService')
      var service = angular.merge($delegate, {
        getDataSources: cacheAsyncResult(getDataSourcesCacheKey, $delegate.getDataSources),
        getDataSourceById: cacheAsyncResult(getDataSourceByIdCacheKey, $delegate.getDataSourceById),
        getProfileFields: cacheAsyncResult(getProfileFieldCacheKey, $delegate.getProfileFields),
        getSurveyFields: cacheAsyncResult(getSurveyFieldCacheKey, $delegate.getSurveyFields),
        getTeamRosterGroups: cacheAsyncResult(getTeamRosterGroupsCacheKey, $delegate.getTeamRosterGroups),
        getSeasons: cacheAsyncResult(getSeasonsCacheKey, $delegate.getSeasons),
        getMemberships: cacheAsyncResult(getMembershipsCacheKey, $delegate.getMemberships),
        getDivisions: cacheAsyncResult(getDivisionsCacheKey, $delegate.getDivisions),
        getTeams: cacheAsyncResult(getTeamsCacheKey, $delegate.getTeams),
      })

      return service

      /**
       * Creates a function that caches the result of the provided function.
       *
       * @private
       * @param {Function} getCacheKey - A method that returns a unique cache key for the provided function.
       *   It is expected that this function accepts the same arguments as getValue.
       * @param {Function} getValue - The method that gets the promise to cache.
       * @return {Function} - The decorated method.
       */
      function cacheAsyncResult(getCacheKey, getValue) {
        return function() {
          var cacheKey = getCacheKey.apply(this, arguments)

          if (!cache.get(cacheKey)) {
            var promise = getValue.apply(this, arguments)
            promise = promise.catch(function(error) {
              cache.put(cacheKey, null)
              throw error
            })

            cache.put(cacheKey, promise)
          }

          return cache.get(cacheKey)
        }
      }

      /**
       * Gets a unique key for the provided orgId, page, per_page, surveyIds and search.
       *
       * @param {object} params - Includes orgId, page, per_page, surveyIds and search.
       * @return {string} - The cache key to use.
       */
      function getDataSourcesCacheKey(params) {
        return '{orgId}:{page}:{per_page}:{survey_ids}:{search}:dataSources'
          .replace('{orgId}', params.orgId.toString())
          .replace('{page}', params.page ? params.page.toString() : '')
          .replace('{per_page}', params.perPage ? params.perPage.toString() : '')
          .replace('{survey_ids}', params.surveyIds ? params.surveyIds.toString() : '')
          .replace('{search}', params.search ? params.search.toString() : '')
      }

      /**
       * Gets a unique key for the provided survey id.
       *
       * @param {number} id - The id of the survey.
       * @return {string} - The cache key to use.
       */
      function getDataSourceByIdCacheKey(id) {
        return '{dataSourceId}:dataSources'
          .replace('{dataSourceId}', id.toString())
      }

      /**
       * Gets a unique key for the provided orgId.
       *
       * @param {number} orgId - The id of the organization.
       * @param {string} profileType - The profile type.
       * @return {string} - The cache key to use.
       */
      function getProfileFieldCacheKey(orgId, profileType) {
        return '{orgId}:{profileType}:fields'
          .replace('{orgId}', orgId.toString())
          .replace('{profileType}', profileType)
      }

      /**
       * Gets a unique key for the provided orgId and surveyId.
       *
       * @param {number} orgId - The id of the organization.
       * @param {number} surveyId - The id of the survey.
       * @return {string} - The cache key to use.
       */
      function getSurveyFieldCacheKey(orgId, surveyId) {
        return '{orgId}:{surveyId}:fields'
          .replace('{orgId}', orgId.toString())
          .replace('{surveyId}', surveyId.toString())
      }

      /**
       * Gets a unique key for the provided orgId and groupType.
       *
       * @return {string} - The cache key to use.
       */
      function getTeamRosterGroupsCacheKey(params) {
        return '{orgId}:{team_instance_id}:{page}:{per_page}:{search}:groups'
          .replace('{orgId}', params.orgId.toString())
          .replace('{team_instance_id}', params.team_instance_id ? params.team_instance_id.toString() : '')
          .replace('{page}', params.page ? params.page.toString() : '')
          .replace('{per_page}', params.perPage ? params.perPage.toString() : '')
          .replace('{search}', params.search ? params.search.toString() : '')
      }

      /**
       * Gets a unique key for the provided orgId.
       *
       * @return {string} - The cache key to use.
       */
      function getSeasonsCacheKey(params) {
        return '{orgId}:{page}:{per_page}:{search}:seasons'
          .replace('{orgId}', params.orgId.toString())
          .replace('{page}', params.page ? params.page.toString() : '')
          .replace('{per_page}', params.perPage ? params.perPage.toString() : '')
          .replace('{search}', params.search ? params.search.toString() : '')
      }

      /**
       * Gets a unique key for the provided orgId
       *
       * @return {string} - The cache key to use.
       */
      function getMembershipsCacheKey(params) {
        return '{orgId}:{page}:{per_page}:{search}:memberships'
          .replace('{orgId}', params.orgId.toString())
          .replace('{page}', params.page ? params.page.toString() : '')
          .replace('{per_page}', params.perPage ? params.perPage.toString() : '')
          .replace('{search}', params.search ? params.search.toString() : '')
      }

      /**
       * Gets a unique key for the provided orgId and seasonId.
       *
       * @return {string} - The cache key to use.
       */
      function getDivisionsCacheKey(params) {
        return '{orgId}:{seasonId}:{page}:{per_page}:{search}:divisions'
          .replace('{orgId}', params.orgId.toString())
          .replace('{seasonId}', params.seasonId.toString())
          .replace('{page}', params.page ? params.page.toString() : '')
          .replace('{per_page}', params.perPage ? params.perPage.toString() : '')
          .replace('{search}', params.search ? params.search.toString() : '')
      }

      /**
       * Gets a unique key for the provided orgId and seasonId.
       *
       * @return {string} - The cache key to use.
       */
      function getTeamsCacheKey(params) {
        return '{orgId}:{seasonId}:{page}:{per_page}:{search}:teams'
          .replace('{orgId}', params.orgId.toString())
          .replace('{seasonId}', params.seasonId.toString())
          .replace('{page}', params.page ? params.page.toString() : '')
          .replace('{per_page}', params.perPage ? params.perPage.toString() : '')
          .replace('{search}', params.search ? params.search.toString() : '')
      }
    })
  })
