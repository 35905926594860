angular.module('org-admin')
  .service('rosterTeamsColumnProvider', function($q, i18ng) {
    // Expose this as injectable for code cleanliness
    return function() {
      return $q.when([
        {
          key: 'roster_teams.*.players',
          id: 'roster_player_persona_ids.length', // this weirdness is needed for dynamic-column-cell
          name: i18ng.t('ROSTERING.COLUMNS.TEAMS.players_name'),
          title: i18ng.t('ROSTERING.COLUMNS.TEAMS.players_title'),
          sort_column: 'player'
        },
        {
          key: 'roster_teams.*.staff',
          id: 'roster_coach_persona_ids.length', // this weirdness is needed for dynamic-column-cell
          name: i18ng.t('ROSTERING.COLUMNS.TEAMS.staff_name'),
          title: i18ng.t('ROSTERING.COLUMNS.TEAMS.staff_title'),
          sort_column: 'coach'
        },
        {
          key: 'roster_teams.*.division',
          id: 'division.abbrev', // this weirdness is needed for dynamic-column-cell
          name: i18ng.t('ROSTERING.COLUMNS.TEAMS.division_name'),
          title: i18ng.t('ROSTERING.COLUMNS.TEAMS.division_title'),
          sort_column: 'order_by_division_abbreviation'
        },
        {
          key: 'roster_teams.*.gender',
          id: 'gender',
          name: i18ng.t('ROSTERING.COLUMNS.TEAMS.gender_name'),
          title: i18ng.t('ROSTERING.COLUMNS.TEAMS.gender_title'),
          sort_column: 'order_by_gender'
        },
      ])
    }
  })
  .component('rosterTeams', {
    bindings: {
      transferPosition: '@?'
    },
    templateUrl: '/static/org/roster-management/roster-teams.html',
    controller: function(_, $log, $q, $scope, $timeout, $window, Alerts, Contact, currentOrg, dialog, dynamicColumnsFactory, featureToggles, pageViewHandler, listFilter, moment, Player, RoleAssignments, RosteringPersonas, RosteringState, rosterTeamsColumnProvider, Search, SeasonTeams, setAs, setWhile, snAsync) {

      var ctrl = this
      ctrl.state = RosteringState
      ctrl.seasonTeams = []
      ctrl.sortColumns = $window.localStorage.getItem('MassRostering-Teams-SaveFilters') &&
                         JSON.parse($window.localStorage.getItem('MassRostering-Teams-SortColumns')) || { order_by_name: 'asc' }
      ctrl.params = { org_id: currentOrg.id }
      ctrl.filters = $window.localStorage.getItem('MassRostering-Teams-SaveFilters') &&
                     JSON.parse($window.localStorage.getItem('MassRostering-Teams-Filters')) || {}
      ctrl.options = { result: ctrl.seasonTeams, bypassCache: true, paramSerializer: '$httpParamSerializerJQLike' }
      if (ctrl.state.transferable) ctrl.uniqueId = ctrl.params.uniqueId = _.uniqueId() // can't make two identical pending queries in js-data
      ctrl.search = Search.create({
        minLength: 1,
        update: function(search) {
          ctrl.filters.search = _.uniq(_.compact(_.map(search.split(','), _.trim)))
        }
      })

      ctrl.$onInit = function() {
        dynamicColumnsFactory.create({
          cacheKey: 'rostering-teams-' + currentOrg.id + (ctrl.transferPosition ? '-transfer-' + ctrl.transferPosition : ''),
          defaultColumnKeys: [
            'roster_teams.*.players',
            'roster_teams.*.staff',
            'roster_teams.*.division'
          ],
          columnProviders: {
            roster_teams: rosterTeamsColumnProvider
          }
        })
          .then(setAs(ctrl, 'dynamicColumns'))
      }

      if (ctrl.state.transferable) {
        $scope.$watch('$ctrl.state.selectedLeague', function(leagueId) {
          if (leagueId !== ctrl.filters.league_id) {
            ctrl.removeFilter('league_id', null, true)
            ctrl.filters.league_id = leagueId
            ctrl.selectedTeam = null // in case the team they're viewing is now filtered out of the main list
          }
        })
      }
      $scope.$watch('{ sort: $ctrl.sortColumns, filters: $ctrl.filters }', setWhile(ctrl, 'loading', loadSeasonTeams), true)

      $scope.listenToRoot('rostering:removeSuccess', function(event, player) {
        var seasonTeam = _.find(ctrl.seasonTeams, _.pick(player, 'season_id', 'team_id'))
        var personaIds = _.result(seasonTeam, 'roster_' + player.type + '_persona_ids')
        _.pull(personaIds, player.persona_id)
      })

      // The param names are a little weird, so just map them here so we can work with intuitive var names elsewhere.
      var filterMap = {
        league_id: 'by_league', // TODO: by_solo_league
        season_id: 'season_id',
        subseason_id: 'by_subseason',
        division_instance_id: 'division_instance_id',
        gender: 'by_gender',
        team_id: 'team_id',
        search: 'related_names_like'
      }

      function loadSeasonTeams() {
        if ($window.localStorage.getItem('MassRostering-Teams-SaveFilters')) {
          $window.localStorage.setItem('MassRostering-Teams-Filters', JSON.stringify(_.omit(ctrl.filters, 'search')))
          $window.localStorage.setItem('MassRostering-Teams-SortColumns', JSON.stringify(ctrl.sortColumns))
        }

        ctrl.seasonTeams.length = 0
        var filters = _.mapKeys(ctrl.filters, _.rearg(_.propertyOf(filterMap), 1)) // translate the filter keys for API
        ctrl.search.term = filters.related_names_like = (_.any(filters.related_names_like) ? filters.related_names_like.join(',') : undefined)
        return SeasonTeams.findAll(_.extend({}, ctrl.params, {
          sort: _.keys(ctrl.sortColumns)[0],
          sort_dir: _.values(ctrl.sortColumns)[0]
        }, filters), ctrl.options)
      }

      var filterSchema = ctrl.state.filtering.teamFilterSchema
      ctrl.filterCount = function() {
        var filterCount = _.size(ctrl.filters)
        if (_.any(ctrl.filters.team_id)) filterCount += ctrl.filters.team_id.length - 1
        if (_.any(ctrl.filters.gender)) filterCount += ctrl.filters.gender.length - 1
        if (_.any(ctrl.filters.search)) filterCount += ctrl.filters.search.length - 1
        return filterCount - (ctrl.state.transferable ? 1 : 0) // exclude League filter in Transfer mode
      }
      ctrl.removeFilter = function(key, item, immediate) {
        if (immediate) _removeFilter(key, item)
        else $timeout(_removeFilter, 0, true, key, item) // allow event to bubble up to parent first

        function _removeFilter(key, item) {
          switch (key) {
            case 'league_id':
            case 'season_id':
            case 'subseason_id':
            case 'division_instance_id':
              delete ctrl.filters[key]
              filterSchema.doForChild(key, function(child, childKey) { _removeFilter(childKey) })
              break
            case 'team_id':
            case 'gender':
            case 'search':
              ctrl.search(item ? _.without(ctrl.filters.search, item).join(',') : '')
              break
            default:
              delete ctrl.filters[key]
              break
          }
        }
      }

      ctrl.openFilterbox = function() {
        dialog.confirm({
          component: 'roster-teams-filterbox',
          attrs: {
            filters: ctrl.filters
          }
        })
          .then(setAs(ctrl, 'filters'))
      }

      function checkPlayerStats(seasonTeam, player) {
        var isPlayer = player.type === 'player'
        if (isPlayer && !player.has_stats) return $q.resolve({})
        return dialog.confirm({
          component: isPlayer ? 'player-transfer-details' : 'coach-role-select',
          attrs: {
            player: player,
            personas: [player.rostering_persona],
            hasStats: player.has_stats,
            seasonTeam: seasonTeam
          }
        })
      }

      function checkCoachRole(seasonTeam, personas, type) {
        switch (type) {
          case 'player':
            return $q.resolve({})
          case 'coach':
            if (!_.any(personas)) return $q.resolve({})
            return dialog.confirm({
              component: 'coach-role-select',
              attrs: {
                seasonTeam: seasonTeam,
                personas: personas
              }
            })
        }
      }

      function assignCoachRole(role, seasonTeam, persona) {
        if (!role || role.key === 'other') return
        var roleProps = {
          persona_id: persona.id,
          principal_type: 'Persona',
          principal_id: persona.id,
          resource_type: role.applies_to,
          resource_id: String(role.applies_to === 'TeamInstance' ? seasonTeam.id : currentOrg.id), // this field is stored as a string in US
          org_id: currentOrg.id,
          role_id: role.id,
          role_type: 'CompositeRole'
        }
        return RoleAssignments.findAll(roleProps, {
          bypassCache: true
        })
          .then(function(currentRoles) {
            if (_.any(currentRoles)) return $q.resolve()
            return RoleAssignments.create(roleProps)
          })
      }

      var playerSaveOptions = { params: { include_rostering_persona: 1, check_stats: 1, rostering: 1 } }

      ctrl.transferPlayer = function(seasonTeam, player) {
        player = Player.get(player.id)
        var previousTeam = SeasonTeams.filter(_.pick(player, 'team_id', 'season_id'))[0]
        var personaIdsKey = 'roster_' + player.type + '_persona_ids'
        var existingPersonaIds = _.get(seasonTeam, personaIdsKey)
        var personaId = player.persona_id
        var alertParams = {
          name: player.full_name,
          old_team_name: previousTeam.name,
          new_team_name: seasonTeam.name
        }

        if (_.contains(existingPersonaIds, personaId)) { // TODO: check for status: 'disabled' here too?
          Alerts.warn('ROSTERING.TRANSFER.already_assigned_message', alertParams)
          return true
        }

        checkPlayerStats(seasonTeam, player)
          .then(function(ret) {
            var coachRole = ret.coachRole
            var newPlayer = Player.createInstance(_.omit(_.cloneDeep(player), 'id', '$$hashKey'))
            _.extend(newPlayer, _.pick(seasonTeam, 'roster_id', 'team_id', 'season_id'))
            if (coachRole) {
              newPlayer.title = coachRole.name
              if (coachRole.key !== 'other') playerSaveOptions.params.composite_role_id = coachRole.id
            }
            newPlayer.transfer_player_id = player.id

            setWhile(seasonTeam, '$loading_' + player.type,
              newPlayer.DSCreate(playerSaveOptions)
                .then(function(newRosterPlayer) {
                  // don't disable the old player until the new one is created
                  return player.DSUpdate(angular.merge({
                    status: 'disabled',
                    inactive_info: {
                      date_deactivated: moment().format('MM/DD/YYYY') // fallback, can be overridden by the modal return value
                    }
                  }, ret.roster_player), playerSaveOptions)
                    .then(function() { previousTeam.DSRefresh() })
                    .then(() => Player.afterDestroy(null, player, _.noop))
                    .then(_.constant(newRosterPlayer)) // pass the new one to the callback
                })
                .then(function(rosterPlayer) {
                  existingPersonaIds.push(personaId)
                  Alerts.success('ROSTERING.TRANSFER.success_message', alertParams)
                  ctrl.state.updateDataBySurveyId(rosterPlayer)
                  return assignCoachRole(coachRole, seasonTeam, rosterPlayer.rostering_persona)
                })
                .catch(function(err) {
                  var errors = _.compact(_.uniq(_.get(err, 'data.error.messages') || _.get(err, 'data.errors')))
                  Alerts.alert('ROSTERING.TRANSFER.error_message', _.extend(alertParams, { errors: errors.join(', ') }))
                })
            )()
          })

        return true
      }

      ctrl.assignTeams = function(seasonTeam, personas) {
        function assigned(persona) {
          return featureToggles.limit_rostering_to_single_team ?
            _.any(Contact.get(persona).roster_players, { type: ctrl.state.type, survey_id: ctrl.state.survey.id /*, status: 'active'*/ }) :
            _.contains(existingPersonaIds, persona.id)
        }
        var existingPersonaIds = seasonTeam['roster_' + ctrl.state.type + '_persona_ids']
        var existingPersonas = _.filter(personas, assigned)
        var newPersonas = _.reject(personas, assigned)
        var errors = { personas: [], messages: [] }
        ctrl.state.selectedPersonas.clearAll(assigned)

        checkCoachRole(seasonTeam, newPersonas, ctrl.state.type)
          .then(function(ret) {
            var coachRole = ret.coachRole
            if (coachRole && coachRole.key !== 'other') playerSaveOptions.params.composite_role_id = coachRole.id

            setWhile(seasonTeam, '$loading_' + ctrl.state.type, snAsync.mapLimit(newPersonas, 5, function(persona) {
              return Player.create({
                roster_id: seasonTeam.roster_id,
                persona_id: persona.id,
                first_name: persona.first_name,
                last_name: persona.last_name,
                survey_result_id: persona[_.find(_.keys(persona), function(key) { return _.endsWith(key, 'survey_result_id') })],
                type: ctrl.state.type,
                title: coachRole ? coachRole.name : undefined
              }, playerSaveOptions)
                .then(function(rosterPlayer) {
                  existingPersonaIds.push(persona.id)
                  ctrl.state.selectedPersonas.clearAll(persona)
                  return rosterPlayer
                })
                .catch(function(err) {
                  errors.messages = _.compact(_.uniq(errors.messages.concat(_.get(err, 'data.error.messages') || _.get(err, 'data.errors'))))
                  errors.personas.push(persona) // return undefined so the others can go through
                })
            })
              .then(function(newPlayers) {
                function alertMsg(type, msgKey, players, options, listOptions) {
                  Alerts[type](msgKey, _.extend({
                    count: players.length,
                    team_name: seasonTeam.name,
                    list: listFilter(_.map(players, 'full_name'), listOptions)
                  }, options))
                }

                newPlayers = _.compact(newPlayers)

                if (existingPersonas.length) {
                  featureToggles.limit_rostering_to_single_team ?
                    alertMsg('alert', 'ROSTERING.ASSIGN.already_assigned_message_any', existingPersonas, { noTimeout: true }, { limit: Infinity }) :
                    alertMsg('warn', 'ROSTERING.ASSIGN.already_assigned_message_one', existingPersonas)
                  pageViewHandler.fireEvent('AssignPersona.AlreadyAssigned', 8, existingPersonas.length)
                }
                if (errors.personas.length) {
                  alertMsg('alert', 'ROSTERING.ASSIGN.error_message', errors.personas, { errors: errors.messages.join(', ') })
                  pageViewHandler.fireEvent('AssignPersona.Error', 8, errors.personas.length)
                }
                if (newPlayers.length) {
                  alertMsg('success', 'ROSTERING.ASSIGN.success_message_' + ctrl.state.type, newPlayers)
                  $scope.$emit('rostering:assignSuccess', newPlayers)
                  var newPlayerPersonas = _.map(newPlayers, function(player) {
                    return _.find(personas, { id: player.persona_id })
                  })
                  pageViewHandler.fireEvent('AssignPersona.Success', 8, newPlayers.length)
                  _.each(newPlayers, ctrl.state.updateDataBySurveyId)
                  return $q.all(_.map(newPlayerPersonas, _.partial(assignCoachRole, coachRole, seasonTeam)))
                }
              })
            )()
          })

        return true
      }

    }
  })
