angular.module('pl-shared')

  .directive('emailValidator', function() {
    return {
      require: 'ngModel',
      scope: {},
      link: function(scope, el, attrs, ctrl) {
        var ngModel = ctrl
        var pattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/

        function parser(inputVal) {
          var isValid = pattern.test(inputVal)
          ngModel.$setValidity('emailValidator', isValid)
          return inputVal
        }

        ngModel.$parsers.push(parser)
      }
    }
  })
