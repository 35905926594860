'use strict'

// Validation directives for number comparison

function dateCompareDirective(name, compare) {
  angular.module('pl-shared')
    .directive(name, function() {
      return {
        require: 'ngModel',
        link: function(scope, elm, attrs, controller) {
          var comparisonValue

          controller.$validators[name] = function(modelValue) {
            var dateFormat = /^\d{4}-\d{2}-\d{2}$/

            if (!dateFormat.test(modelValue)) return true // leave this validation to something else

            if (!comparisonValue) return true // skip validation for undefined/falsy comparison values

            if (!dateFormat.test(comparisonValue)) {
              return console.error(name + ': Comparison Value must be in the format of YYYY-MM-DD (given "' + comparisonValue + '")')
            }

            return compare(modelValue, comparisonValue)
          }

          scope.$watch(attrs[name], function(value) {
            comparisonValue = value
            controller.$validate()
          })
        }
      }
    })
}

dateCompareDirective('dateEarliest', function(a, b) { return a >= b })
dateCompareDirective('dateLatest', function(a, b) { return a <= b })
