angular.module('org-admin')
  .service('RosteringState', function($debounce, $q, $rootScope, $timeout, Contact, currentOrg, dialog, DivisionInstances, DS, ENV, i18ng, IndexArchivedSurvey, Leagues, renderContext, Seasons, SeasonTeams, setAs, Subseasons, Survey) {

    var typeMap = {
      players: 'player',
      staff: 'coach'
    }

    var state = {
      store: DS.store,
      filtering: {
        teamFilterSchema: {
          // Runs a block-ish function (childObj, childKey) if a child exists for the key.
          doForChild: function(key, fnc) {
            var schema = state.filtering.teamFilterSchema
            var childKey = _.get(schema, [key, 'childKey'])
            var child = schema[childKey]
            if (childKey && child) fnc(child, childKey)
          },
          league_id: {
            service: Leagues,
            findAllParams: { organization_id: currentOrg.id },
            collectionKey: 'leagues',
            childKey: 'season_id'
          },
          season_id: {
            service: Seasons,
            collectionKey: 'seasons',
            childKey: 'subseason_id'
          },
          subseason_id: {
            service: Subseasons,
            collectionKey: 'subseasons',
            childKey: 'division_instance_id'
          },
          division_instance_id: {
            service: DivisionInstances,
            collectionKey: 'divisionInstances'
          }
        },
        select2Opts: {
          allowClear: true,
          dropdownAutoWidth: true,
          minimumResultsForSearch: 0
        },
        teamsSelect2Opts: {
          allowClear: true,
          dropdownAutoWidth: true,
          placeholder: i18ng.t('ROSTERING.FILTERS.team_name_placeholder'),
          minimumInputLength: 2,
          ajax: {
            transport: $debounce(function(params, success, failure) {
              return SeasonTeams.findAll({
                org_id: currentOrg.id,
                name_like: params.data.term,
                page: params.data.page || 1,
                per_page: 10,
                sort: 'order_by_name',
                sort_dir: 'asc'
              }, {
                bypassCache: true
              })
                .then(success, failure)
            }, 250),
            processResults: function(teams) {
              var uniqueTeams = _.uniq(teams, 'team_id')
              if (teams.pagination.first_page && !teams.pagination.last_page && uniqueTeams.length <= 6) {
                // tell select2 to load the next page, since this one won't fill up the dropdown and load is triggered by scroll
                var select2 = this.container
                $timeout(function() {
                  select2.$results.trigger('scroll')
                }, 0, false)
              }
              return {
                results: _.map(uniqueTeams, function(team) {
                  return { id: team.team_id, text: team.name }
                }),
                pagination: {
                  more: !teams.pagination.last_page
                }
              }
            }
          }
        }
      },
      surveySelect2Options: {
        ajax: {
          delay: 1000,
          transport: function(params, success, error) {
            return Survey.findAll({
              organization_id: currentOrg.id,
              survey_types: 'registration',
              exclude_team_only_surveys: 'true',
              order_by: 'created_at',
              direction: 'desc',
              page: params.data.page || 1,
              per_page: 50,
              search: params.data.term || undefined
            }, { cache: true }) // use $http's caching to preserve response pagination
              .then(function(surveys) {
                if ((params.data.page || 1) === 1) {
                  return $q.when(state.mdSurvey ||
                    Contact.findAll({ org_id: currentOrg.id, per_page: 1 })
                      .then(function(contacts) {
                        return state.mdSurvey = Survey.inject({
                          id: 0, // still falsy, but not == null
                          name: i18ng.t('ROSTERING.member_directory'),
                          is_es_indexed: true,
                          survey_results_count: contacts.pagination.total
                        })
                      }))
                    .then(function(mdSurvey) {
                      surveys.unshift(mdSurvey)
                      return surveys
                    })
                    .catch(function() { // Member Directory not available, probably a permission thing
                      return surveys
                    })
                }
                return surveys
              })
              .then(success, error)
          },
          processResults: function(surveys, params) {
            var surveyOptions = _.map(surveys, function(survey) {
              return {
                id: survey.id && 'survey_results.' + survey.id,
                text: survey.name + (!survey.id || ENV.current === 'development' ? ' (' + survey.survey_results_count + ')' : ''),
                is_es_indexed: survey.is_es_indexed,
                survey_results_count: survey.survey_results_count
              }
            })
            if (!_.all(surveys, 'id')) { // select2 creates a new optgroup header for each page, we only need the separator once
              surveyOptions = _.chain(surveyOptions)
                .partition('id') // Member Directory will have this undefined
                .reverse() // array of truthy results comes first, we want it last
                .map(function(subArray, i) {
                  if (i === 0) return subArray[0]
                  return { text: 'Registrations', children: subArray }
                })
                .compact()
                .value()
            }
            return {
              results: surveyOptions,
              pagination: {
                more: !surveys.pagination.last_page
              }
            }
          }
        },
        dropdownAutoWidth: true,
        closeAfterRequest: {
          requestFn: function(survey) {
            if (survey.survey_results_count === 0) {
              dialog.confirm({ component: 'no-results-registration-dialog' })
            }
            else return IndexArchivedSurvey.index(survey)
          },
          selector: '.select2-unindexed-item, .pl-dropdown-menu__label--empty'
        },
        escapeMarkup: _.identity,
        templateResult: function(item) {
          var text = item.text.replace(/\(0\)$/, '<span class="pl-dropdown-menu__label--empty">$&</span>')
          var markup = item.is_es_indexed === false ?
            '<i class="select2-unindexed-item">' + text + '</i>' :
            text
          return markup
        }
      }
    }

    function updateRouteStates() {
      state.type = typeMap[_.get(renderContext, 'billing.rostering.assign.next')]
      state.transferable = !!_.get(renderContext, 'billing.rostering.transfer')
    }
    $rootScope.$on('$routeChangeSuccess', updateRouteStates)
    updateRouteStates()

    // Updates the data hash for the associated survey on the persona when a player is assigned/removed
    state.updateDataBySurveyId = function(player) {
      var persona = Contact.get(player.persona_id)
      if (!persona) return

      var surveyId = player.survey_id
      var rosterPlayersForSurvey = _.filter(persona.roster_players, { survey_id: surveyId, type: player.type })

      var dataTypeArray = persona.data_by_survey[player.type]
      var surveyData = _.find(dataTypeArray, { survey_id: surveyId })

      if (!surveyData) dataTypeArray.push(surveyData = { survey_id: surveyId })

      if (!rosterPlayersForSurvey.length) _.pull(dataTypeArray, surveyData)
      else surveyData.team = rosterPlayersForSurvey.length > 1 ? rosterPlayersForSurvey.length : rosterPlayersForSurvey[0].team_name

      persona.DSCompute() // rebuild persona.dataBySurveyId
    }

    return state

  })
