angular.module('pl-shared')

  // CONTROLLER EXAMPLE

  // ctrl.dynamicColumns = dynamicColumns({
  //   cacheKey: 'ContactList-' + currentOrg.id,
  //   columns: columnDefinitions(),
  //   defaultColumnKeys: ['name', 'email'], // used if no shown keys are found in local storage
  //   requiredColumnKeys: ['name'] // merged with keys from local storage
  // })

  // TEMPLATE EXAMPLE

  // <td dynamic-column-cell="contact" ng-repeat="column in ctrl.dynamicColumns.shown track by column.key" ng-switch="column.type">
  //   <span ng-switch-when="monetary">{{ displayValue | currency }}</span>
  //   <span ng-switch-when="number" class="sn-ellipsis">{{ displayValue | number }}</span>
  //   <span ng-switch-when="link">
  //     <span ng-if="empty">{{displayValue}}</span>
  //     <a ng-if="!empty"
  //       class="sn-link sn-ellipsis"
  //       href="{{displayValue}}"
  //       title="{{displayValue}}">
  //       {{displayValue}}
  //     </a>
  //   </span>
  //   <span ng-switch-default class="sn-ellipsis">{{ displayValue }}</span>
  // </td>

  .factory('dynamicColumnsFactory', function(_, $q, $location, userSettings, SmartGroupPersona, StaticGroupPersona) {
    return {
      create: create
    }

    /**
     * Creates a new instance of dynamicColumns and loads the initial state
     *
     * @param {object} config - The config object.
     * @param {string} config.cacheKey - The unique cache key. Will be used to load and save the state of the object.
     * @param {string[]} config.defaultColumnKeys - The columns to display if the user has not selected any columns.
     * @param {string[]} [config.requiredColumnKeys] - The columns that cannot be hidden. These columns will always be returned by getShownColumns().
     * @param {object.<columnConfigProvider>} config.columnProviders - Object that contains methods to load columns for each data source type
     * @param {string} strategy - Optional param passed when bypassing local storage. Options are "smart-group-api" and "static-group-api"
     * @param {number} groupId - Optional param passed when using the "smart-group-api" oe "static-group-api" strategy
     * @param {string[]} displayColumns - Optional param used to override columns saved in local storage
     * @returns {Promise.<object>} - A promise that is resolved once the initial state has been loaded.
     */
    function create(config) {
      var dc = dynamicColumns(config)

      return dc.load()
        .then(function() { return dc })
    }

    /**
     * @object
     * @alias columnConfig - The column data for a column in a DynamicColumns instance.
     *
     * @property {string} key - The unique id for the column
     * @property {string} field - The field name for the column. This is used to lookup the data value for the column.
     * @property {string} name - The name of the column
     * @property {string} title - The title of the columns
     * @property {string} type - The data type displayed within the column
     * @property {string} id - The element id for the column.
     * @property {string} style - Custom styles to apply to the column header
     * @property {string} contentStyle - Custom styles to apply to the column cells.
     * @property {string} dataSourceName - The name of the data source for the column.
     * @property {string} dataSourceType - The data source type from when the column was loaded.
     * @property {number} dataSourceId - The data source id from when the column was loaded.
     */

    /**
     * @function
     * @alias columnConfigProvider - A method that is used to load columnConfig data from a data source.
     *
     * @param {string} dataSourceId - The id of the dataSource.
     * @param {string} dataSourceType - The type of the dataSource.
     * @returns {Promise.<columnConfig[]>} - A promise that is resolved with the column data.
     */

    /**
     * Creates a new instance of dynamicColumns based on the provided config. DynamicColumns are used to manage and store
     * column data for tables where the user can customize the columns.
     *
     * @param {object} config - The config object.
     * @param {string} config.cacheKey - The unique cache key. Will be used to load and save the state of the object.
     * @param {string[]} config.defaultColumnKeys - The columns to display if the user has not selected any columns.
     * @param {string[]} config.requiredColumnKeys - The columns that cannot be hidden. These columns will always be returned by getShownColumns().
     * @param {object.<columnConfigProvider>} config.columnProviders - Object that contains methods to load columns for each data source type
     * @returns {object} - The dynamicColumns instance.
     */
    function dynamicColumns(config) {
      var columnsByDataSource = {}

      var service = {
        load: load,
        save: save,
        getAvailableColumns: getAvailableColumns,
        updateShownColumns: updateShownColumns,
        isRequiredColumn: isRequiredColumn,
        setColumnsInUrl: setColumnsInUrl,
        shown: [],
        config: config
      }

      return service

      /**
       * Loads the saved state into the current instance.
       *
       * @param {string} [cacheKey=config.cacheKey] - The cache key to load the data from.
       * @returns {Promise} - A promise that is resolved once the object has been loaded.
       */
      function load(cacheKey) {
        var columnKeys = config.defaultColumnKeys
        var urlColumns = getColumnsFromUrl()

        if (urlColumns) {
          columnKeys = urlColumns
        }
        else if (config.displayColumns) {
          columnKeys = config.displayColumns
        }
        else {
          var userSettingsColumns = userSettings.get(cacheKey || config.cacheKey)
          if (userSettingsColumns) columnKeys = userSettingsColumns
        }

        return updateShownColumns(columnKeys)
      }

      /**
       * Saves the current state.
       *
       * @param {string} [cacheKey=config.cacheKey] - The cache key to save the data into.
       * @returns {Promise} - A promise that is resolved once the save is complete.
       */
      function save(cacheKey) {
        var columnKeys = _.map(service.shown, 'key')

        if (config.strategy === 'static-group-api') {
          StaticGroupPersona.update(config.groupId, { display_columns: columnKeys })
        }
        else if (config.strategy === 'smart-group-api') {
          SmartGroupPersona.update(config.groupId, { display_columns: columnKeys })
        }
        else {
          userSettings.set(cacheKey || config.cacheKey, columnKeys)
        }

        return $q.resolve()
      }

      /**
       * Serializes as base64 encoded string for use as a url param or cookie. Inverse of getColumnsFromUrl.
       * @param {ColumnCollection} columns
       * @return string | null
       */
      function setColumnsInUrl(columnKeys) {
        var serializedColumns = (columnKeys && columnKeys.length) ? btoa(JSON.stringify(columnKeys)) : null
        $location.search('columns', serializedColumns)
      }

      /**
       * Deserializes base64 encoded string to array of columns. Inverse of setColumnsInUrl.
       * @param {string} columnString
       * @return ColumnCollection | null
       */
      function getColumnsFromUrl() {
        var urlParams = $location.search()
        if (urlParams && urlParams.columns) {
          var columnString = urlParams.columns
          var columns
          try {
            columns = JSON.parse(atob(decodeURIComponent(columnString)))
          }
          catch (error) {
            return null
          }

          return _.isArray(columns) && columns.length > 0 ? columns : null
        }
        else return null
      }

      /**
       * Updates the currently displayed columns.
       *
       * @param {string[]} columnKeys - The array of column keys to show.
       * @returns {Promise} - A promise that is resolved once the columns have been updated.
       */
      function updateShownColumns(columnKeys) {
        function getDataSourceFromColumnKey(columnKey) {
          return columnKey.split('.').slice(0, 2).join('.')
        }

        function loadDataSourceColumns(dataSource) {
          var tokens = dataSource.split('.')
          var dataSourceType = tokens[0]
          var dataSourceId
          if (tokens[1] === '*') {
            dataSourceId = (void 0)
          }
          else if (dataSourceType === 'memberships') {
            dataSourceId = tokens[1] || dataSourceType
          }
          else {
            dataSourceId = parseInt(tokens[1], 10)
          }

          return loadColumns(dataSourceId, dataSourceType)
        }

        function setShown(shownColumnKeys) {
          var columnsByKey = _.chain(columnsByDataSource)
            .values()
            .flatten()
            .indexBy('key')
            .value()

          service.shown = _.chain(shownColumnKeys)
            .map(function(columnKey) {
              return columnsByKey[columnKey]
            })
            .compact()
            .value()
        }

        var shownColumnKeys = _.union(_.difference(config.requiredColumnKeys, columnKeys), columnKeys)
        var dataSources = _.chain(shownColumnKeys)
          .map(getDataSourceFromColumnKey)
          .uniq()
          .value()

        return $q.all(_.map(dataSources, loadDataSourceColumns))
          .then(_.partial(setShown, shownColumnKeys))
      }

      /**
       * Gets the columns that are not currently displayed for the provided dataSource.
       *
       * @param {object} dataSources - A hash map of the data source types to load.
       *                               Keys should be values in `FILTER_PANEL_DATA_SOURCE_SELECT.BUCKETS`,
       *                               values are ids for the key's data source type.
       * @returns {Promise.<object[]>} - A promise that is resolved with the flattened column data.
       */
      function getAvailableColumns(dataSources) {
        return $q.all(_.map(dataSources, loadColumns))
          .then(function(columnMap) {
            var columns = _.flatten(_.values(columnMap))
            return _.reject(columns, function(column) {
              return _.any(service.shown, function(shownColumn) { return column.key === shownColumn.key })
            })
          })
      }

      /**
       * Determines if the provided column is considered required.
       *
       * @param {string} columnKey - The unique key of the column to check.
       * @returns {boolean}
       */
      function isRequiredColumn(columnKey) {
        return _.contains(config.requiredColumnKeys, columnKey)
      }

      /**
       * Gets the unique key for the provided data source.
       *
       * @private
       * @param {string} dataSourceType - The data source type. Should be a value in `FILTER_PANEL_DATA_SOURCE_SELECT.BUCKETS`
       * @param {number} [dataSourceId] - Optional. The id for the provided data source type.
       * @returns {string}
       */
      function getDataSourceKey(dataSourceType, dataSourceId) {
        return dataSourceType + (dataSourceId && ('.' + dataSourceId) || '')
      }

      /**
       * Loads the columns for the specified data source.
       *
       * @private
       * @param {number} [dataSourceId] - Optional. The id for the provided data source type.
       * @param {string} dataSourceType - The data source type to load columns from. Should be a value in `FILTER_PANEL_DATA_SOURCE_SELECT.BUCKETS`
       * @returns {Promise} - A promise that is resolved once the columns have been loaded.
       */
      function loadColumns(dataSourceId, dataSourceType) {
        var dataSourceKey = getDataSourceKey(dataSourceType, dataSourceId)

        if (columnsByDataSource[dataSourceKey]) {
          return $q.resolve(columnsByDataSource[dataSourceKey])
        }

        var provider = config.columnProviders[dataSourceType]
        if (!provider) {
          return $q.reject(new Error('Columns are not defined for data source type [' + dataSourceType + ']'))
        }

        return provider(dataSourceId, dataSourceType)
          .then(function(columns) {
            return columnsByDataSource[dataSourceKey] = columns
          })
      }
    }
  })
