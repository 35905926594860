angular.module('pl-shared')
// USE: <td dynamic-column-cell="rowData" ng-repeat="column in dynamicColumns.shown track by column.key" ng-switch="column.type"><!-- switch content here --></td>
  .directive('dynamicColumnCell', function(_) {
    return {
      restrict: 'A',
      require: 'ngRepeat',
      controller: function($scope, $element, $attrs) {
        var rowKey = $attrs.dynamicColumnCell
        if (!rowKey) throw (new Error('The dynamicColumnCell directive requires row data.'))

        var repeatParts = _.trim($attrs.ngRepeat).split(/\s/)
        var columnKey = repeatParts[0]
        var column = $scope.$eval(columnKey)

        $scope.rowData = $scope.$eval(rowKey)
        $scope.$parent.$watch(rowKey + '.' + column.id, updateDisplayValue, true)

        /**
         * Updates the displayValue and empty properties on the current scope.
         *
         * @private
         * @param {string|null} value - The value to display in the table
         */
        function updateDisplayValue(value) {
          $scope.empty = value === undefined || value === null || value === ''
          $scope.displayValue = ($scope.empty) ? '--' : value
        }
      }
    }
  })
