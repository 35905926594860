angular.module('org-admin')
  .component('groupMembers', {
    bindings: {
      org: '<',
      group: '<',
      reload: '=',
      canPurchaseOboMemberships: '<'
    },
    templateUrl: '/static/org/groups/group-members.html',
    controller: function groupMembersController(_, $q, $scope, $filter,
      Alerts, appPermission, Contact, currentOrg, contactsDynamicTableEvents,
      dialog, ENV, filterPanelService, MembershipsUtilService, setAs,
      showError, contactsDynamicColumnsFactory, setWhile,
      SmartGroupPersona, StaticGroupPersona, i18ng) {

      var $ = angular.element
      var $ctrl = this

      $ctrl.org = currentOrg
      $ctrl.loading = false
      $ctrl.totalMembers = null
      $ctrl.model = {
        search: null
      }

      $ctrl.$onInit = setWhile($ctrl, 'loading', $onInit)
      $ctrl.canPurchaseOboMemberships = false
      $ctrl.selectedMembers = null // Will be set once a member has been selected in the group-members tab.
      $ctrl.useMemberIdSource = false

      $ctrl.selectedMembersChanged = selectedMembersChanged
      $ctrl.getMissingCount = getMissingCount
      $ctrl.getACPGroupUrl = getACPGroupUrl
      $ctrl.newOrgMessage = newOrgMessage
      $ctrl.isStaticGroup = isStaticGroup
      $ctrl.canSendInvoices = canSendInvoices
      $ctrl.sendInvoices = sendInvoices
      $ctrl.exportGroup = exportGroup
      $ctrl.openRemovePeopleConfirm = openRemovePeopleConfirm
      $ctrl.addPeople = addPeople
      $ctrl.openArchivationConfirm = openArchivationConfirm

      $ctrl.archiveGroup = archiveGroup


      $ctrl.checkAffiliations = function() {
        MembershipsUtilService.loadPurchaseableOboMemberships(currentOrg.id)
          .then(function(result) {
            $ctrl.purchaseableOboMemberships = result
            $ctrl.canPurchaseOboMemberships = _.some(result)
          })
      }

      $ctrl.purchaseMemberships = function() {
        if ($ctrl.canPurchaseOboMemberships) {
          $ctrl.contactsForMembership = $q.when($ctrl.selectedMembers)
          $ctrl.showPurchaseMemberships = true
        }
        else {
          dialog.confirm({
            directive: 'no-purchaseable-memberships',
            scope: $scope,
          })
        }
      }

      /**
       * Initializes the component.
       *
       * @return {Promise} - A promise that is resolved once the component is initialized.
       */
      function $onInit() {
        return contactsDynamicColumnsFactory.create({
          cacheKey: 'group-members-v1-' + $ctrl.group.id,
          strategy: $ctrl.group.type === 'Smart Group' ? 'smart-group-api' : 'static-group-api',
          groupId: $ctrl.group.group_id,
          displayColumns: $ctrl.group.display_columns
        })
          .then(setAs($ctrl, 'dynamicColumns'))
          .then($ctrl.checkAffiliations)
          .then(function() {
            $ctrl.ACPGroupUrl = getACPGroupUrl()
            $ctrl.useMemberIdSource = filterPanelService.filterSourceHasMemDef($ctrl.group.filters)
          })
      }

      /**
       * Updates the selected members when the selected members list changes.
       *
       * @param {Contact[]} selectedMembers - The current list of selected group members.
       */
      function selectedMembersChanged(selectedMembers) {
        $ctrl.selectedMembers = resolveSelectedMembers(selectedMembers)
      }

      /**
       * Opens a dialog that allows the user to send a message to the group.
       *
       * @return {Promise} - A promise that is resolved once the dialog is closed.
       */
      function newOrgMessage() {
        var selectedMembers = $ctrl.selectedMembers
        var attributes = _.isEmpty(selectedMembers) ? { groupRecipients: [$ctrl.group] } : { recipients: selectedMembers }
        return dialog.confirm({
          component: 'new-org-message',
          scope: $scope,
          attrs: attributes
        })
      }

      /**
       * Indicates if the current group is a static group.
       *
       * @private
       * @return {boolean} - True if the current group is a static group. False otherwise.
       */
      function isStaticGroup() {
        return !!$ctrl.group && $ctrl.group.type === 'Static Group'
      }

      /**
       * Export group persona data, presents modal to select dynamic columns
       */

      function exportGroup() {
        var params = {
          org_id: currentOrg.id,
          group_persona_id: $ctrl.group.id
        }

        dialog.confirm({
          directive: 'contacts-export',
          scope: $scope,
          attrs: {
            params: params,
            columns: $ctrl.dynamicColumns,
            filteredCount: $ctrl.totalMembers,
            unfilteredCount: $ctrl.totalMembers
          }
        }).then(exportSuccess)
      }

      /**
       *
       * Shows alert if the export was async
       *
       * @param {*} exportResult - Result of export
       */
      function exportSuccess(exportResult) {
        if (exportResult.asyncExport) {
          Alerts.success('CONTACTS_EXPORT.async_export', { total_count: $filter('number')(exportResult.totalCount, 0) })
        }
      }

      /**
       * Indicates if the user can request a payment using the current group.
       *
       * @return {boolean} - True if the user can request a payment. False otherwise.
       */
      function powerPayPermissions() {
        return appPermission.checks.powerPay.has_fees && appPermission.powerPay.usable
      }

      function canSendInvoices() {
        return powerPayPermissions()
      }

      /**
       * Opens a dialog that allows the user to send invoices to the group.
       *
       * @return {Promise} - A promise that is resolved once the dialog is closed.
       */

      function sendInvoices() {
        var selectedMembers = $ctrl.selectedMembers
        var groupPersonas = { groupPersonas: [$ctrl.group] }
        var recipients = { recipients: selectedMembers }
        var attributes = _.isEmpty(selectedMembers) ? groupPersonas : recipients

        dialog.confirm({
          directive: 'send-invoices',
          scope: $scope,
          attrs: attributes
        })
      }

      /**
       * Archives or un-archives the current group.
       *
       * @param {boolean} archive - Indicates if the group should be archived or un-archived.
       */
      function archiveGroup(archive) {
        $ctrl.loading = true
        var promise = ($ctrl.group.type === 'Smart Group') ? archiveSmartGroup($ctrl.group, archive) : archiveStaticGroup($ctrl.group, archive)

        promise
          .then(setAs($ctrl, 'group.archived', archive))
          .catch(showError)
          .finally(setAs($ctrl, 'loading', false))
      }

      function openArchivationConfirm() {
        dialog.confirm({
          component: 'confirm-dialog',
          attrs: {
            heading: i18ng.t('GROUPS_LIST.ARCHIVATION.CONFIRMATION.title'),
            message: i18ng.t('GROUPS_LIST.ARCHIVATION.CONFIRMATION.message', { groupName: $ctrl.group.name }),
            confirmLabel: i18ng.t('GROUPS_LIST.ARCHIVATION.CONFIRMATION.confirm_label'),
            confirmAction: $ctrl.archiveGroup.bind(this, true)
          }
        })
      }

      /**
       * Sets the archived property on the provided group.
       *
       * @private
       * @param {} group - The group to update.
       * @param {boolean} archive - The
       */
      function archiveSmartGroup(group, archive) {
        return SmartGroupPersona.update(group.group_id, {
          archived: archive
        })
      }

      /**
       * Sets the archived property on the provided group.
       *
       * @private
       * @param {} group - The group to update.
       * @param {boolean} archive - The archive status to set.
       */
      function archiveStaticGroup(group, archive) {
        return StaticGroupPersona.update(group.group_id, {
          name: group.name,
          filters: group.filters,
          archived: archive
        })
      }

      /**
       * Opens confirmation modal when clicking Remove People
       */
      function openRemovePeopleConfirm() {
        dialog.confirm({
          component: 'confirm-dialog',
          attrs: {
            heading: i18ng.t('GROUP_DETAIL.REMOVE_MEMBERS.CONFIRMATION.title'),
            message: i18ng.t('GROUP_DETAIL.REMOVE_MEMBERS.CONFIRMATION.message',
              { personName: $ctrl.selectedMembers[0].name,
                groupName: $ctrl.group.name,
                count: $ctrl.selectedMembers.length
              }),
            confirmAction: removePeople,
            cancelLabel: i18ng.t('MODAL.no'),
            confirmLabel: i18ng.t('MODAL.yes')
          }
        })
      }

      /**
       * Removes the currently selected people from the group.
       *
       * @returns {Promise} - A promise that is resolved once the action is complete.
       */
      function removePeople() {
        var params = { org_id: currentOrg.id, group_persona_id: $ctrl.group.id, sort: 'name', sort_dir: 'asc', per_page: 1000 }
        var options = { load: 'all', result: [] }

        return $q.all([Contact.findAll(params, options), $q.when($ctrl.selectedMembers)])
          .then(function(result) {
            var groupMembers = result[0]
            var selectedMembers = result[1]

            var newMembers = _.filter(groupMembers, function(member) {
              return _.all(selectedMembers, function(selectedMember) {
                return selectedMember.id !== member.id
              })
            })
            var attrs = {
              name: $ctrl.group.name,
              archived: $ctrl.group.archived,
              public: $ctrl.group.public,
              persona_ids: newMembers.map(function(member) { return member.id + '' }).join(',')
            }
            return StaticGroupPersona
              .update($ctrl.group.group_id, attrs)
              .then(removePeopleSuccess)
              .catch(removePeopleFailure)
          })

        function removePeopleSuccess() {
          Alerts.success('GROUP_DETAIL.remove_people_success')
          $scope.$emit(contactsDynamicTableEvents.refresh)
          $ctrl.reload = true
        }

        function removePeopleFailure() {
          Alerts.success('GROUP_DETAIL.remove_people_failure')
        }
      }

      /**
       * Opens a dialog that allows the user to add people to the current group.
       *
       * @returns {Promise} - A promise that is resolved once the dialog is closed.
       */
      function addPeople() {
        return dialog.confirm({
          component: 'static-group-add-members',
          scope: $scope,
          attrs: {
            group: $ctrl.group
          }
        })
          .then(function() {
            $scope.$emit('groupMembersChanged')
            $scope.$emit(contactsDynamicTableEvents.refresh)
            $ctrl.reload = true
          })
      }

      function getMissingCount() {
        if (!$ctrl.group || _.isNull($ctrl.totalMembers)) {
          return 0
        }

        return $ctrl.group.members - $ctrl.totalMembers
      }

      function getACPGroupUrl() {
        if (!$ctrl.org || !$ctrl.group || !appPermission.profiles.provisioned) {
          return ''
        }

        var groupTypes = {
          'Static Group': 'static_group',
          'FormBuilder::SmartGroup': 'smart_group',
          'ElasticSmartGroup': 'smart_group',
          'Roster': 'static_group'
        }
        var groupType = groupTypes[$ctrl.group.group_type]
        if (!groupType) {
          throw new Error('Unknown group type: ' + $ctrl.group.group_type)
        }

        return '{site_url}/{org_id}/{group_type}/show/{group_id}'
          .replace('{org_id}', $ctrl.org.id.toString())
          .replace('{site_url}', ENV.urls.siteRedirect)
          .replace('{group_type}', groupType)
          .replace('{group_id}', $ctrl.group.group_id.toString())
      }

      /**
       * If selectedMembers is a function, call it to load contacts
       *
       * @private
       * @param {(Function|SelectHelper|Contact[])} selectedMembers
       */
      function resolveSelectedMembers(selectedMembers) {
        return _.isFunction(selectedMembers) ? selectedMembers() : selectedMembers
      }

    }
  })
