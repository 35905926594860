angular.module('org-admin')
  .component('filterPanelBetweenMonetaryInput', {
    bindings: {
      id: '@ctrlId',
      filterRule: '<',
      readOnly: '<'
    },
    templateUrl: '/static/org/filtering/inputs/filter-panel-between-monetary-input.html',
    controller: function filterPanelBetweenMonetaryInputController(_, $scope) {
      var $ctrl = this

      $ctrl.model = {
        minValue: null,
        maxValue: null
      }

      $scope.$watch(function() { return !!$ctrl.filterRule && $ctrl.filterRule.value() }, updateModel)
      $scope.$watch('$ctrl.model', updateFilterRule, true)

      function updateFilterRule() {
        if (!$ctrl.filterRule) {
          return
        }

        $ctrl.filterRule.valueAt(0)(getCentValue($ctrl.model.minValue))
        $ctrl.filterRule.valueAt(1)(getCentValue($ctrl.model.maxValue))
      }


      function updateModel(values) {
        if (!_.isArray(values)) {
          $ctrl.model.minValue = null
          $ctrl.model.maxValue = null
          return
        }

        $ctrl.model.minValue = getDollarValue(values[0])
        $ctrl.model.maxValue = getDollarValue(values[1])
      }

      function getCentValue(dollarValue) {
        return _.isNumber(dollarValue) ? dollarValue * 100 : null
      }

      function getDollarValue(centValue) {
        var parsedValue = parseInt(centValue)
        return _.isNumber(parsedValue) ? parsedValue / 100 : null
      }
    }
  })
