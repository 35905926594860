angular.module('org-admin')
  .component('programListingsOverview', {
    templateUrl: '/static/org/program-listings/program-listings-overview.html',
    controller: function(_, $scope) {

      // This pattern allows the parent (overview) component to track state semi-independently
      // of the render context. It will update the local context only when the renderContext
      // matches one of the tab contexts. This lets detail views (like add or edit) load at a
      // single route (like /program-listings/add) instead of having duplicate routes for the detail
      // view under each tab context. It also allows the detail views to load on their own on
      // initial load, then load the list context and all its data later, when navigating "back".

      var $ctrl = this
      var tabContexts = ['active', 'drafts', 'archive']

      $scope.$on('publishStatusChanged', publishStatusChanged)
      $scope.$watch('renderContext.programListings.next', updateContext)

      function updateContext(tabContext) {
        var currentContext = _.intersection(tabContexts, [tabContext])[0]
        if (currentContext) $ctrl.context = currentContext
      }

      // update the list view if a new program listing of that type is added,
      // or if a program listing of that type has been removed.
      function publishStatusChanged() {
        _.each(arguments, function(status) {
          switch (status) {
            case 'active': return reloadListContext('active')
            case 'draft': return reloadListContext('drafts')
            case 'archived': return reloadListContext('archive')
          }
        })
      }

      function reloadListContext(context) {
        if (context === $ctrl.context) $ctrl.reloadList = true
      }

    }
  })
