angular.module('pl-shared')
  .directive('personaRegistrationHistory', function() {

    return {
      restrict: 'E',
      scope: {},
      bindToController: {
        persona: '=',
        orphanInvite: '=',
        surveyResults: '=',
        showHidden: '<'
      },
      templateUrl: '/static/shared/components/personas/persona-registration-history.html',
      controllerAs: 'ctrl',
      controller: function(_, $q, $scope, $rootScope, $route, $routeParams, $window, Alerts, routeTitle, dialog, i18ng, renderContext, setAs, OrphanInvite, Site, currentOrg, showError, ENV, Order) {

        var ctrl = this
        var isSiteMemberSurvey = _.iteratee({ survey_type: 'site_member' })
        var isNotSiteMemberSurvey = _.negate(isSiteMemberSurvey)

        var SUCCESS_STATUSES = ['completed or billed', 'fully refunded', 'paid']
        var WARNING_STATUSES = ['chargeback']
        var ERROR_STATUSES = ['unsuccessful']

        ctrl.org = currentOrg
        ctrl.openInviteModal = openInviteModal
        ctrl.resendInvite = resendInvite
        ctrl.statusClass = statusClass

        routeTitle.setParams({ org_name: currentOrg.name })

        $scope.$on('$destroy', destroy)
        $window.addEventListener('message', onPostMessage, false)

        var unbindUpdate = $rootScope.$on('$routeChangeSuccess', update)
        if ($route.current) update()

        function update() {
          ctrl.loaded = false
          setSurveyResult()

          if (!ctrl.surveyResults.length) return updateComplete()

          getSurveySite()
            .then(setUrls)
            .then(getOrders)
            .then(updateComplete, updateComplete)
        }

        function updateComplete() {
          ctrl.loaded = true
        }

        function setSurveyResult() {
          var results = ctrl.surveyResults || []
          var sr = ctrl.selectedSurveyResult = _.findWhere(results, { id: +$routeParams.surveyResultId }) || results[0]
        }

        function getSurveySite() {
          if (!ctrl.selectedSurveyResult) return $q.resolve(null)
          return Site.find(ctrl.selectedSurveyResult.site_id)
        }

        function setUrls(site) {
          if (!site) return showError('404')
          var sr = ctrl.selectedSurveyResult
          ctrl.orgSiteDomain = ENV.urls.nginUserDashboard.replace('login', site.secure_subdomain)
          ctrl.surveyResultUrl = sr && sr.id ? '/survey_result/show/' + sr.id : ''
        }

        function getOrders() {
          var sale_numbers = ctrl.surveyResults
            .filter(function(surveyResult) { return surveyResult.order_reference })
            .map(function(surveyResult) { return surveyResult.order_reference.sale_number }).join()
          if (!sale_numbers.length) return

          return Order.findAll({ sale_numbers }, { bypassCache: true })
            .then(function(response) {
              response.forEach(function(order) {
                var result = ctrl.surveyResults.find(function(surveyResult) {
                  return surveyResult.order_reference && surveyResult.order_reference.sale_number === order.sale_number
                })
                result.attachments = {
                  order: {
                    status: order.monetary_status
                  }
                }
              })
            })
        }

        function openInviteModal() {
          return dialog.confirm({
            component: 'send-orphan-invite-modal',
            attrs: {
              orphanPersona: ctrl.persona
            }
          })
            .then(setAs(ctrl, 'orphanInvite'))
        }

        function resendInvite() {
          return OrphanInvite.resend(ctrl.orphanInvite.id)
            .then(resendInviteSuccess, resendInviteError)
        }

        function resendInviteSuccess() {
          Alerts.success('ORPHAN_INVITES.RESEND_INVITE.success')
        }

        function resendInviteError() {
          Alerts.error('ORPHAN_INVITES.RESEND_INVITE.error')
        }

        function statusClass(status) {
          if (SUCCESS_STATUSES.indexOf(status.toLowerCase()) !== -1) return 'pl-tag--success'
          if (WARNING_STATUSES.indexOf(status.toLowerCase()) !== -1) return 'pl-tag--warn'
          if (ERROR_STATUSES.indexOf(status.toLowerCase()) !== -1) return 'pl-tag--alert'
          return 'pl-tag--highlight'
        }

        function destroy() {
          unbindUpdate()
          $window.removeEventListener('message', onPostMessage, false)
        }

        function onPostMessage(e) {
          var data = e.data || {}
          if (data.type === 'surveyResult:removed') unclaimed(data.id)
        }

        function unclaimed(surveyResultId) {
          var results = ctrl.surveyResults || []
          _.pull(results, _.find(results, { id: surveyResultId }))
          update()
          renderContext.backto('registration_history.org')
        }
      }
    }
  })
