angular.module('org-admin')
  .directive('paymentRefund', function() {
    return {
      restrict: 'A',
      scope: {},
      bindToController: {
        sale: '=',
        refundable: '<',
        fromTransactions: '<',
        cancel: '=',
        confirm: '=',
        dialogOptions: '=',
        transactionId: '='
      },
      controllerAs: 'ctrl',
      templateUrl: '/static/org/payments/payment-refund.html',
      controller: function($q, $scope, $timeout, setAs, launchDarklyFlags) {
        var ctrl = this
        var dateFormat = 'YYYY-MM-DD'
        var refundablePayments = ctrl.sale.refundablePayments()

        ctrl.currentStep = 0
        ctrl.dueDate = 0
        ctrl.enableLdFlags
        ctrl.showNonRefundables = true
        ctrl.expandNonRefundablePayments = false
        ctrl.nonRefundablePayments = ctrl.sale.nonRefundablePayments()
        ctrl.doNotCollect = true
        ctrl.submitButtonText = 'PAYMENT_REFUND.refund_payment'
        ctrl.selectedPayment
        if (ctrl.fromTransactions) {
          ctrl.payment = _.filter(refundablePayments, { sale_transaction_id: +ctrl.transactionId })[0]
          ctrl.refundable = true
        }
        else {
          ctrl.payments = refundablePayments
        }

        ctrl.$onInit = function() {
          ctrl.enableLdFlags = launchDarklyFlags.testingFlag
          ctrl.selectedPayment = null
        }

        $scope.$watch('ctrl.dueDate', function(newValue) {
          if (newValue) {
            ctrl.datePastTomorrow = moment(newValue, 'MMM/DD/YYYY').isBefore(moment(), 'day')
          }
        })

        ctrl.onDoNotCollectChange = function() {
          ctrl.submitButtonText = 'PAYMENT_REFUND.refund_payment'
          ctrl.collect = !ctrl.collect
        }

        ctrl.onCollectChange = function() {
          ctrl.submitButtonText = 'PAYMENT_REFUND.next'
          ctrl.doNotCollect = !ctrl.doNotCollect
        }

        ctrl.prevStep = function() {
          if (ctrl.currentStep === 0) return
          ctrl.dueDate = 0
          ctrl.currentStep--
        }

        $scope.$watch('ctrl.payment.id', function() {
          if (!ctrl.payment) return

          var maxIntPayment = Math.round(ctrl.payment.amount * 100)
          var maxIntRefunded = Math.round(ctrl.payment.amount_refunded * 100)

          var max = (maxIntPayment - maxIntRefunded) / 100
          ctrl.refundAmountMax = max // default to max refundAmount

          // Debounce this so that the template bindings can update for validation
          $timeout(function() { ctrl.refundAmount = max })
        })

        $scope.$watch('ctrl.currentStep', function(newVal) {
          if (newVal === 1) {
            ctrl.selectedPayment = ctrl.payment
          }
        })

        $scope.$watchCollection(refundablePayments, function() {
          if (ctrl.refundable && _.isEmpty(refundablePayments)) { $timeout(function() { return ctrl.cancel() }) }
        })

        // PUBLIC METHODS

        ctrl.closeNonRefundableAlert = function() {
          ctrl.showNonRefundables = false
        }

        ctrl.toggleShowMore = function() {
          ctrl.expandNonRefundablePayments = !ctrl.expandNonRefundablePayments
        }

        ctrl.dueDatePast = function(due_date) {
          return moment(due_date).isBefore(moment(), 'day')
        }

        ctrl.getEarliestDate = function() {
          if (checkPristine()) return
          return moment().add(1, 'days').format(dateFormat)
        }

        function checkPristine() {
          var datepicker = ctrl.paymentRefundForm.due_date
          return datepicker ? datepicker.$pristine : false
        }

        ctrl.refund = function() {
          if (!ctrl.payment) return

          if (ctrl.doNotCollect) {
            return ctrl.payment
              .refund({ notes: ctrl.notes, amount: ctrl.refundAmount })
              .then(function(refund) {
                if (refund.status === 'failed') return $q.reject(refund)
                ctrl.confirm(refund)
              })
              .catch(setAs(ctrl, 'failure'))
          }
          else {
            ctrl.currentStep = 1
            if (ctrl.dueDate) {
              if (ctrl.paymentRefundForm.hasErrors('due_date')) {
                return $q.reject('due_date error')
              }
              return ctrl.payment
                .refund({
                  amount: ctrl.refundAmount,
                  adjustment_payment: {
                    due_date: ctrl.dueDate
                  }
                })
                .then(function(refund) {
                  if (refund.status === 'failed') return $q.reject(refund)
                  ctrl.confirm(refund)
                })
                .catch(setAs(ctrl, 'failure'))
            }
          }
        }
      }
    }
  })
