angular.module('org-admin')
  .component('ordersList', {
    templateUrl: '/static/org/orders/orders-list.component.html',
    controllerAs: 'ctrl',
    controller: function(Order, currentOrg, debounceCallback, $routeParams,
      saleFilterHelper, Search, selectHelper, SePagination, $scope, pageViewHandler, i18ng) {
      pageViewHandler.firePageView()
      var ctrl = this
      ctrl.loading = true
      ctrl.ordersLoading = false
      ctrl.orders = []
      ctrl.params = { org_id: currentOrg.id, list: 'summary', sold: true }
      ctrl.sortColumns = { 'order[created_at]': 'desc' }
      ctrl.statuses = saleFilterHelper.statuses.slice(0, 6) // ['open', 'paid', 'past_due', 'partial-refund', 'refunded'] - 'voided' is not an option for orders
      ctrl.filtersEnabled = 'ctrl.params["monetary_status[]"].length'
      ctrl.showControls = 'ctrl.orders.length || ctrl.search.term || ctrl.orders.loading || ctrl.filtersEnabled'
      ctrl.paginationObject = {}
      ctrl.pagination = new SePagination({
        loadDataFn: loadOrders,
        storageId: 'orderList'
      })

      ctrl.$onInit = function() {
        ctrl.statuses.splice(3, 1) // 'voided' is not an option for orders, we must delete that option here
      }

      ctrl.filterButtonLabel = function() {
        return saleFilterHelper.filterButtonLabel(ctrl.selectedStatuses)
      }

      $scope.$watchCollection('ctrl.selectedStatuses', function() {
        ctrl.params['monetary_status[]'] = saleFilterHelper.mapMonetaryStatuses(ctrl.selectedStatuses)
      })

      ctrl.search = Search.create({
        update: function(searchTerm) {
          ctrl.params.search_fields = 'sales.description,sale_number,user_full_name'
          ctrl.params.search_term = String(searchTerm) || undefined
        }
      })

      ctrl.displayStatus = function(sale) {
        if (['partial-refund', 'refunded'].includes(sale.monetary_status)) {
          return i18ng.t('MONETARY_STATUS.' + sale.monetary_status)
        }
        return i18ng.t('DISPLAY_STATUS.' + sale.display_status)
      }

      // TODO: ANGULAR2: replace this usage with whatever observable pattern exists in the future
      ctrl.selectedStatuses = selectHelper.bind($scope, 'ctrl.statuses')

      $scope.$watch('ctrl.sortColumns', function(newSort, oldSort) {
        _.each(oldSort, function(dir, col) {
          delete ctrl.params[col]
        })
        _.extend(ctrl.params, newSort)
      })

      var findAll = debounceCallback(Order.findAll)
      function loadOrders(paginationParams) {
        ctrl.ordersLoading = true
        if (ctrl.pagination) ctrl.params.per_page = ctrl.pagination.pageParams.per_page
        if (paginationParams) ctrl.params = angular.merge(ctrl.params, paginationParams)
        return findAll(ctrl.params)
          .then(function(response) {
            ctrl.orders = response
            ctrl.paginationObject = response.pagination
          })
          .finally(function() {
            ctrl.loading = false
            ctrl.ordersLoading = false
          })
      }

      function presetStatusFilter() {
        var statusParams = $routeParams['monetary_status[]'] || $routeParams.display_status
        if (!statusParams) return
        _.each([].concat(statusParams), function(status) {
          var displayStatus = saleFilterHelper.setDisplayStatus(status)
          ctrl.selectedStatuses.toggle(_.find(ctrl.statuses, { value: displayStatus }), true)
        })
      }

      presetStatusFilter()
      $scope.$watch('ctrl.params', function() {
        if (!ctrl.ordersLoading) loadOrders()
      }, true)
    }
  })
