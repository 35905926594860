angular.module('org-admin')
  .service('SalePayments', function($http, ENV) {
    return {
      offlinePaymentOptions: offlinePaymentOptions
    }
    function offlinePaymentOptions(saleNumber) {
      return $http({
        method: 'GET',
        url: ENV.urls.apiProxy + '/sale_payments/' + saleNumber + '/offline_payment_options',
        headers: { Accept: 'application/json' },
        withCredentials: true,
      })
    }
  })
