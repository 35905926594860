angular.module('org-admin')
  .constant('contactsDynamicTableEvents', {
    /**
     * Will cause the table to refresh the list of contacts.
     */
    refresh: 'contacts-dynamic-table:refresh'
  })
  .component('contactsDynamicTable', {
    bindings: {
      orgId: '<',
      dynamicColumns: '<',
      groupId: '<?',
      searchTerm: '<?',
      filterCollection: '<?',
      totalContacts: '=?',

      onSelectedContactsChanged: '&?'
    },
    templateUrl: '/static/org/contacts/contacts-dynamic-table.html',
    controller: function contactsDynamicTableController(_, $debounce, $scope, Contact, selectHelper, contactsDynamicTableEvents, $q, debounceCallback) {
      var $ctrl = this

      var findAll = debounceCallback(Contact.findAll)

      $ctrl.contacts = []
      $ctrl.selectedContacts = selectHelper.bind($scope, '$ctrl.contacts', { strategy: selectHelper.STRATEGY.Stateful })
      $ctrl.sortColumns = { name: 'asc' }
      $ctrl.getUnfilteredTotal = getUnfilteredTotal

      $ctrl.$onInit = $onInit

      /**
       * Initializes the component.
       */
      function $onInit() {

        $scope.listenToRoot(contactsDynamicTableEvents.refresh, refresh)
        $scope.listenToRoot('invoiceGroup:updateRequest', uncheckAll)
        $scope.$watchCollection('$ctrl.selectedContacts', selectedContactsChanged)

        var debouncedLoadContacts = $debounce(function(result) { loadContacts(result.params, result.fields, true) }, 300)
        $scope.$watch(function() {
          return {
            params: getContactsParams(),
            fields: getFields()
          }
        }, debouncedLoadContacts, true)
      }

      /**
       * Returns the total number of contacts without the filters applied.
       *
       * @return {number} - The total number of contacts.
       */
      function getUnfilteredTotal() {
        //unfiltered_total is always the number of contacts within the org
        //When group is included, the unfiltered total should be the number of members in the group.

        return ($ctrl.groupId) ? _.get($ctrl, 'contacts.pagination.total') : _.get($ctrl, 'contacts.pagination.unfiltered_total')
      }

      /**
       * Gets the params object used to retrieve contacts.
       *
       * @private
       * @returns {*} - The params to use with Contact.findAll
       */
      function getContactsParams() {
        return angular.merge({
          org_id: $ctrl.orgId,
          group_persona_id: $ctrl.groupId,
          search: $ctrl.searchTerm
        }, getSortParams())
      }

      /**
       * Gets the sort parameters used to retrieve contacts.
       *
       * @private
       * @returns {*} - The sort parameters to use with Contact.findAll
       */
      function getSortParams() {
        var sortColumnKey = _.chain($ctrl.sortColumns)
          .keys()
          .first()
          .value()
        var column = $ctrl.dynamicColumns && _.find($ctrl.dynamicColumns.shown, function(shownColumn) {
          return shownColumn.key === sortColumnKey
        })

        if (!column) {
          //The column is not registered in the dynamic columns service. Assuming it is a static column.
          return {
            sort: sortColumnKey,
            sort_dir: $ctrl.sortColumns[sortColumnKey]
          }
        }
        else if (_.includes(['survey', 'memberships'], column.dataSourceType)) {
          var sourceName = column.dataSourceType === 'survey' ? 'survey_results' : column.dataSourceType
          return {
            sort_source: sourceName === 'memberships' && !column.dataSourceId ? sourceName : sourceName + '.' + column.dataSourceId,
            sort: column.field,
            sort_dir: $ctrl.sortColumns[sortColumnKey]
          }
        }
        else {
          return {
            sort: column.field,
            sort_dir: $ctrl.sortColumns[sortColumnKey]
          }
        }
      }

      /**
       * Gets the fields array used to retrieve contacts.
       *
       * @private
       * @return {*} - The array of fields to pass to Contacts.findAll
       */
      function getFields() {
        return ($ctrl.dynamicColumns) ? _.map($ctrl.dynamicColumns.shown, mapColumnToField) : null
      }

      /**
       *
       *
       * @private
       * @param {Object} params - The parameters are used to define filters, sorting and etc. when call Contact.findAll
       * @param {Object} fields - The fields are passed to Contact.findAll
       * @param {boolean} [clearSelection=false] - Use it to clear the selected items
       */
      function loadContacts(params, fields, clearSelection) {
        if (clearSelection) $ctrl.selectedContacts.clearAll()

        return Contact.findAll(params, {
          result: $ctrl.contacts,
          data: {
            fields: fields
          }
        })
          .then(function() {
            $ctrl.totalContacts = _.get($ctrl, 'contacts.pagination.total')
          })
      }

      /**
       * Handles updating parent components when selected contacts have changed.
       *
       * @private
       * @param {Contacts[]} selectedContacts - The collection of selected contacts.
       */
      function selectedContactsChanged(selectedContacts) {
        if (!$ctrl.onSelectedContactsChanged) {
          return
        }

        $ctrl.onSelectedContactsChanged({ $selectedContacts: selectedContactsResolver(selectedContacts) })
      }

      /**
       * Converts column data to field data.
       *
       * @private
       * @param {columnConfig} column - The column data to convert
       * @returns {object} - The field to request.
       */
      function mapColumnToField(column) {
        var source
        if (column.dataSourceType === 'survey') {
          source = 'survey_results.' + String(column.dataSourceId)
        }
        else if (column.dataSourceType === 'memberships') {
          source = column.dataSourceId ? 'memberships.' + String(column.dataSourceId) : 'memberships'
        }
        else if (column.dataSourceType === 'suspensions') {
          source = 'suspensions'
        }
        else {
          source = (void 0)
        }

        return {
          source: source,
          field: column.field,
          key: column.id
        }
      }

      /**
       * Refreshes the table with a new list of contacts.
       *
       * @private
       */
      function refresh() {
        $ctrl.contacts = []

        return loadContacts(getContactsParams(), getFields(), true)
      }

      /**
       * Returns function to lazy load contacts. When "Select All" mode is enabled, we don't have all items (items are loaded only for the current page in data grid) so load all items only when it is really needed.
       *
       * @param {SelectHelper} selectedContacts
       * @returns {Function}
       */
      function selectedContactsResolver(selectedContacts) {
        return (function() {
          if (_.all(selectedContacts)) {
            return selectedContacts
          }

          var params = _.extend({}, getContactsParams(), { per_page: 1000 })
          var options = _.extend({}, getFields(), { result: [], load: 'all' })
          var deselectedContacts = selectedContacts.deselectedItems()

          return findAll(params, options)
            .then(function(contacts) {
              if (!deselectedContacts) return contacts

              var deselectedContactIds = _.map(deselectedContacts, function(contact) {
                return contact.id
              })

              return _.filter(contacts, function(contact) {
                return deselectedContactIds.indexOf(contact.id) === -1
              })
            }.bind(this))
        })
      }

      /**
       * uncheck all members
       * @private
       */
      function uncheckAll() {
        $ctrl.selectedContacts.clearAll()
      }
    }
  })
