angular.module('pl-shared')

  .directive('questionHelp', function() {

    return {
      restrict: 'E',
      scope: {},
      templateUrl: '/static/shared/components/registration/questions/question-help.html',
      bindToController: {
        question: '='
      },
      controllerAs: 'ctrl',
      controller: function() {

      }
    }

  })
