angular.module('org-admin')
  .component('leagueSeasonDetails', {
    bindings: {
      leagueSeason: '<?',
      cancel: '<',
      confirm: '<'
    },
    templateUrl: '/static/org/league-seasons/league-season-details.html',
    controller: function(
      _, appPermission, currentOrg, Program, setAs, setWhile, showError, StatNginSports
    ) {
      if (!appPermission.leagueSeasons.visible) return showError('404')

      var ctrl = this

      ctrl.$onInit = function() {
        if (ctrl.leagueSeason) {
          ctrl.mode = 'edit'
        }
        else {
          ctrl.mode = 'add'
          ctrl.leagueSeason = Program.createInstance({
            org_id: currentOrg.id,
            sport_key: null,
            type: 'League',
            timezone: currentOrg.timezone,
            time_format: '12'
          })
        }
      }

      ctrl.submit = function() {
        return StatNginSports.findAll()
          .then(function(snSports) {
            if (!_.find(snSports, { key: ctrl.leagueSeason.sport_key })) ctrl.leagueSeason.sport_key = 'other'
            return ctrl.leagueSeason.DSCreate() // upsert will choose POST vs. PUT
          })
          .then(ctrl.confirm)
      }

      ctrl.revertChanges = function() {
        if (ctrl.mode !== 'add') ctrl.leagueSeason.DSRevert()
        ctrl.cancel()
      }

    }
  })
