angular.module('org-admin')
  .component('filterPanelBetweenChoiceInput', {
    bindings: {
      id: '@ctrlId',
      filterRule: '<',
      readOnly: '<'
    },
    templateUrl: '/static/org/filtering/inputs/filter-panel-between-choice-input.html',
    controller: function filterPanelBetweenChoiceInputController(_, $scope) {
      var $ctrl = this
      $ctrl.$onInit = $onInit

      function $onInit() {
        $ctrl.options = _.map($ctrl.filterRule.field().choice_elements, function(choiceEl) {
          var key = choiceEl.choice.key || choiceEl.choice.name
          return { id: key, text: choiceEl.choice.name }
        })
      }

      $ctrl.model = {
        minValue: null,
        maxValue: null
      }

      $scope.$watch(function() { return !!$ctrl.filterRule && $ctrl.filterRule.value() }, updateModel)
      $scope.$watch('$ctrl.model', updateFilterRule, true)

      function updateFilterRule() {
        if (!$ctrl.filterRule) {
          return
        }
        $ctrl.filterRule.valueAt(0)($ctrl.model.minValue)
        $ctrl.filterRule.valueAt(1)($ctrl.model.maxValue)
      }

      function updateModel(values) {
        if (!_.isArray(values)) {
          $ctrl.model.minValue = null
          $ctrl.model.maxValue = null
          return
        }
        $ctrl.model.minValue = values[0]
        $ctrl.model.maxValue = values[1]
      }
    }
  })
