angular.module('pl-shared')

  .directive('defaultQuestionToggle', function(renderContext) {

    return {
      scope: {},
      templateUrl: '/static/shared/components/registration/questions/default-question-toggle.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        organizationName: '=',
        editMode: '='
      },
      controller: function() {}
    }

  })
