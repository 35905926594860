angular.module('org-admin')
  .directive('installmentWindow', function() {
    return {
      scope: {},
      templateUrl: '/static/org/payment-terms/installment-window.html',
      controllerAs: 'ctrl',
      bindToController: {
        installmentCount: '<',
        strategyType: '=',
        interval: '=',
        dayOfMonth: '=',
        hasOneInstallment: '&'
      },

      controller: function() {

        var ctrl = this

        ctrl.daysOfMonth = _.range(1, 28)

        ctrl.dayIntervalSelected = function() {
          return ctrl.strategyType === 'day_interval'
        }

        ctrl.validateIfSelected = function() {
          var validateNumber = '^([1-9][0-9]?|[12][0-9][0-9]|3[0-5][0-9]|36[0-5])$'
          if (!ctrl.dayIntervalSelected()) return
          return validateNumber
        }

        ctrl.selectOption = function(option) {
          return ctrl.strategyType = option
        }
      }
    }
  })
