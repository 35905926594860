angular.module('org-admin')
  .component('discountModal', {
    bindings: {
      currencySymbol: '<',
      cancel: '=',
      confirm: '=',
      discountCodeDefinition: '=?'
    },
    templateUrl: '/static/org/discounts/discount-modal.component.html',
    controllerAs: 'ctrl',
    controller: function(
      currentOrg,
      DiscountCodeDefinitions,
      i18ng,
      setAs,
      SeToastService,
      setWhile
    ) {
      var ctrl = this

      if (ctrl.discountCodeDefinition) {
        ctrl.editMode = true
        ctrl.discountCodeDefinition = angular.copy(ctrl.discountCodeDefinition)
      }
      else {
        ctrl.editMode = false
        ctrl.discountCodeDefinition = {
          discount_type: 'amount',
          max_use_count: null,
          single_use: false,
          starts_at: moment().format('YYYY-MM-DD')
        }
      }

      function setDiscountValue(discount) {
        if (discount.discount_type === 'amount') {
          discount.discount_value = discount.dollarAmount
        }
        else {
          discount.discount_value = discount.percentageAmount
        }
      }

      function setName(discount, updatedProperties) {
        updatedProperties.name = discount.name
      }

      function setCodeCount(discount, updatedProperties) {
        if (discount.single_use) {
          updatedProperties.code_count = discount.code_count + parseInt(discount.additional_code_count)
        }
      }

      function setSuppliedCode(discount, updatedProperties) {
        if (!discount.single_use) {
          updatedProperties.supplied_code = discount.supplied_code
        }
      }

      function setMaxUseCount(discount, updatedProperties) {
        if (discount.additional_reusable_count === null) {
          updatedProperties.max_use_count = null
        }
        else {
          updatedProperties.max_use_count = discount.redeemed_count + parseInt(discount.additional_reusable_count)
        }
      }

      function setDuration(discount, updatedProperties) {
        updatedProperties.starts_at = discount.starts_at
        updatedProperties.expires_at = discount.expires_at
      }

      function setUpdatedDiscountProperties(discount, updatedProperties) {
        setName(discount, updatedProperties)
        setDuration(discount, updatedProperties)
        setCodeCount(discount, updatedProperties)
        setSuppliedCode(discount, updatedProperties)
        setMaxUseCount(discount, updatedProperties)
      }

      function submitDiscount(discount) {
        if (ctrl.editMode) {
          var updatedProperties = {}
          setUpdatedDiscountProperties(discount, updatedProperties)
          return DiscountCodeDefinitions.update(discount.id, updatedProperties)
        }
        else {
          setDiscountValue(discount)
          var payload = {
            organization_id: currentOrg.id,
            code_definition: discount
          }
          return DiscountCodeDefinitions.create(payload)
        }
      }

      function setAlertMessage() {
        if (ctrl.editMode) return 'DISCOUNT_MODAL.TOASTS.edit_discount_success'
        else return 'DISCOUNT_MODAL.TOASTS.add_discount_success'
      }

      function onSubmitSuccess(response) {
        SeToastService.success(
          i18ng.t(setAlertMessage(), { name: ctrl.discountCodeDefinition.name }),
          'check-circle',
          {
            displayText: i18ng.t('DISCOUNT_MODAL.view'),
            href: '/org/' + currentOrg.id + '/discounts/' + response.id + '/detail'
          })
        return ctrl.confirm(response)
      }

      function onSubmitError(response) {
        var responseError = response.data.error ? response.data.error : response.statusText
        ctrl.serverErrors = responseError.messages || [responseError]
      }

      ctrl.submit = function() {
        if (ctrl.saving) return
        return setWhile(ctrl, 'saving', submitDiscount(ctrl.discountCodeDefinition))()
          .then(onSubmitSuccess, onSubmitError)
          .finally(setAs(ctrl, 'saving', false))
      }

    }
  })
