angular.module('pl-shared')

  .factory('Survey', function(DS, apiV2, paginate) {
    var Survey = DS.defineResource({
      name: 'surveys',
      endpoint: '/registration/surveys',
      basePath: DS.adapters.ngin_v2.defaults.basePath,
      httpConfig: DS.adapters.ngin_v2.defaults.httpConfig,
      defaultAdapter: 'ngin_v2',
      bypassCache: true,
      actions: {
        index_survey: {
          method: 'POST',
          endpoint: '/org_persona_index/',
          response: apiV2.deserialize
        }
      }
    })

    return paginate(Survey)
  })
