angular.module('org-admin')
  .component('contactsList', {
    templateUrl: '/static/org/contacts/contacts-list.html',
    controllerAs: 'ctrl',
    bindToController: {
      profilesPolicy: '<'
    },
    controller: function contactListController(
      _, $scope, $timeout, $interval, $q, $location, $routeParams,
      Alerts, dialog, i18ng, appPermission,
      selectHelper, Search, SePagination, Contact, CentralService,
      currentOrg, currentUser, debounceCallback, GroupPersona,
      OrgPersonaIndex, MembershipsUtilService, filterCollectionFactory,
      contactsDynamicColumnsFactory, filterPanelService,
      renderContext, ENV, $filter, pageViewHandler, LegacySiteService) {

      var MAX_REINDEX = 20000

      var ctrl = this
      var findAll = debounceCallback(Contact.findAll)
      var lastSuccess
      var updatingFilters

      ctrl.MAX_CLUB_ASSIGNMENTS = 50
      ctrl.clubAssignmentError = false
      ctrl.filters = null
      ctrl.reindexable = false
      ctrl.contacts = [] // populated when contacts load
      ctrl.params = { org_id: currentOrg.id }
      ctrl.options = { result: ctrl.contacts, data: {} }
      ctrl.sortColumns = { name: 'asc' }
      ctrl.selectedContacts = selectHelper.bind($scope, 'ctrl.contacts', { strategy: selectHelper.STRATEGY.Stateful })
      ctrl.pagination = new SePagination({
        loadDataFn: updatePagination,
        storageId: 'contactsList'
      })
      ctrl.contactsForMembership = []
      ctrl.search = Search.create(searchOptions())
      ctrl.filtersApplied = false
      ctrl.currentOrg = currentOrg
      ctrl.currentUser = currentUser
      ctrl.showFilterPanel = false
      ctrl.importToolsUrl = ENV.urls.importTools + '/org/' + currentOrg.id
      ctrl.inviteBossOrganizationId = null
      ctrl.legacyLink = LegacySiteService.getUrl('/site_admin/user_directory')

      ctrl.exportData = exportData
      ctrl.newOrgMessage = newOrgMessage
      ctrl.sendInvoices = sendInvoices
      ctrl.addStaticGroup = addStaticGroup
      ctrl.addSmartGroup = addSmartGroup
      ctrl.addToGroups = addToGroups
      ctrl.changeContactSuccess = changeContactSuccess
      ctrl.retry = loadContacts
      ctrl.toggleFilterPanel = toggleFilterPanel
      ctrl.openMobileFilterModal = openMobileFilterModal
      ctrl.toggleAllContacts = toggleAllContacts
      ctrl.rebuildIndex = rebuildIndex
      ctrl.canPurchaseOboMemberships = false
      ctrl.canSendClubAssignment = false
      ctrl.showPurchaseMemberships = false
      ctrl.purchaseableOboMemberships = null
      ctrl.contactNameField = 'verified_full_name'
      ctrl.canReviewDuplicates = false
      ctrl.useMemberIdSource = false
      ctrl.purchaseMemberships = purchaseMemberships
      ctrl.profilesPolicy = {}
      ctrl.openSelectMembership = openSelectMembership
      ctrl.$onInit = $onInit

      // COMPONENT LIFECYCLE HOOKS

      /**
       * Initializes the component.
       */
      function $onInit() {
        updatePagination() // presets stored perPage
        $scope.listenToRoot('invoiceGroup:updateRequest', uncheckAll)
        $scope.listenToRoot('addPerson:personAdded', function($event, contacts) {
          changeContactSuccess(contacts, false)
        })
        ctrl.canReviewDuplicates = canReviewDuplicates()
        GroupPersona.findAll({
          org_id: currentOrg.id,
          per_page: 1,
          type: 'static'
        }).then(function(result) {
          ctrl.orgHasStaticGroups = _.some(_.flatten(result))
        })

        MembershipsUtilService.loadPurchaseableOboMemberships(currentOrg.id)
          .then(function(result) {
            ctrl.purchaseableOboMemberships = result
            ctrl.canPurchaseOboMemberships = _.some(result)
            var invitableMemDefs = _.filter(result, function(memDef) {
              return memDef.can_invite
            })
            ctrl.canSendClubAssignment = ctrl.currentUser.canSendClubAssignment() && _.some(invitableMemDefs)
            loadInviteBossOrganizationId()
          })

        $scope.$on('$routeChangeSuccess', function() {
          setColumnsInUrl()
          if ($routeParams.removed_sport_ngin_id) {
            var removedSportNginId = $routeParams.removed_sport_ngin_id
            $location.search('removed_sport_ngin_id', null)
            ctrl.changeContactSuccess([{ sport_ngin_id: removedSportNginId }], true)
          }
        })

        return $q.all({
          dynamicColumns: contactsDynamicColumnsFactory.create({
            cacheKey: 'contacts-v2-' + currentOrg.id
          }),
          filterCollection: filterCollectionFactory.buildFilterCollection(filterOptions()),
          profilesPolicy: CentralService.checkProfilesPolicy(currentOrg.id),
        })
          .then(function(results) {
            ctrl.dynamicColumns = results.dynamicColumns
            ctrl.profilesPolicy = results.profilesPolicy
            setColumnsInUrl()
            ctrl.filters = results.filterCollection
            ctrl.useMemberIdSource = filterPanelService.filterSourceHasMemDef(ctrl.filters)
            $scope.$watch('ctrl.sortColumns', onSortColumnsChanged)

            //This must be set before watching params and options because this sets the fields property on the options.data object.
            $scope.$watchCollection('ctrl.dynamicColumns.shown', updateFields)

            //Kicks off the request to load contacts.
            $scope.$watch('{ params: ctrl.params, filters: ctrl.options.data}', onSearchParamsChanged, true)
          })
      }

      // PRIVATE METHODS

      function setColumnsInUrl() {
        if (renderContext.state.context === 'app.billing.personas') {
          ctrl.dynamicColumns.setColumnsInUrl(_.map(ctrl.dynamicColumns.shown, 'key'))
        }
      }

      function uncheckAll() {
        ctrl.selectedContacts.clearAll()
      }

      /**
       * Converts column data to field data.
       *
       * @private
       * @param {columnConfig} column - The column data to convert
       * @returns {object} - The field to request.
       */
      function mapColumnToField(column) {
        var source
        if (column.dataSourceType === 'survey') {
          source = 'survey_results.' + String(column.dataSourceId)
        }
        else if (column.dataSourceType === 'memberships') {
          source = _.any(column.dataSourceId) ? 'memberships.' : 'memberships'
        }
        else if (column.dataSourceType === 'suspensions') {
          source = 'suspensions'
        }
        else {
          source = (void 0)
        }

        return {
          source: source,
          field: column.field,
          key: column.id
        }
      }

      function updatePagination(paginationParams) {
        if (ctrl.pagination) ctrl.params.per_page = ctrl.pagination.pageParams.per_page
        if (paginationParams) angular.merge(ctrl.params, paginationParams)
      }

      function updateFields() {
        ctrl.options.data.fields = _.map(ctrl.dynamicColumns.shown, mapColumnToField)
      }

      function onSearchParamsChanged(newVal, oldVal) {
        if (nonPageParamChanged(newVal, oldVal)) ctrl.params.page = 1 // reset to first page when other criteria change
        ctrl.filtersApplied = !!ctrl.options.data.filters && ctrl.options.data.filters.length > 0
        ctrl.useMemberIdSource = filterPanelService.filterSourceHasMemDef(_.extend({}, ctrl.options.data.filters))
        ctrl.selectedContacts.clearAll()
        setFiltersInUrl()
        return loadContacts()
      }

      function nonPageParamChanged(newVal, oldVal) {
        return oldVal ? !_.isEqual(removePageParam(newVal), removePageParam(oldVal)) : false
      }

      function removePageParam(val) {
        var clone = _.cloneDeep(val)
        delete clone.params.page
        return clone
      }

      function loadContacts() {
        return getContacts()
          .then(loadContactsSuccess)
          .catch(loadContactsError)
          .finally(loadContactsComplete)
      }

      function getContacts(params, options) {
        params = _.extend({}, ctrl.params, params)
        options = _.extend({}, ctrl.options, options)

        // these fields are required for purchaseable membership
        // in the future, this list may need to be dynamic
        var defaultFields = [
          { source: undefined, field: 'email', key: 'se_profile_email' },
          { source: undefined, field: 'gender', key: 'se_profile_gender' },
          { source: undefined, field: 'date_of_birth', key: 'se_profile_date_of_birth' },
        ]

        // there is a deep watch on { params: ctrl.params, filters: ctrl.options.data}
        // that triggers this to reload when it changes - that's why all these objects re cloned via _.extend
        var optData = _.extend({}, options.data)
        var fields = _.extend([], optData.fields)
        _.forEach(defaultFields, function(df) {
          if (!_.find(fields, { key: df.key })) {
            fields.push(df)
          }
        })
        optData.fields = fields
        options.data = optData
        return findAll(params, options)
      }

      function loadContactsError(err) {
        if (!lastSuccess) ctrl.failure = 'load'
        else if (!angular.equals(ctrl.options.data.filters, lastSuccess.filters)) ctrl.failure = 'filter'
        else if (!angular.equals(ctrl.params.search, lastSuccess.params.search)) ctrl.failure = 'search'
        else ctrl.failure = 'load'
        if (updatingFilters) updatingFilters.reject()
      }

      function loadContactsSuccess() {
        lastSuccess = angular.copy({
          params: ctrl.params,
          filters: ctrl.options.data.filters
        })
        ctrl.failure = false
        if (updatingFilters) updatingFilters.resolve()
      }

      function loadContactsComplete() {
        var params = ctrl.params || {}
        var data = (ctrl.options || {}).data || {}
        ctrl.loaded = true
        ctrl.paginationObject = _.extend({}, ctrl.contacts.pagination) // clone triggers changes in the se-pagination component
        ctrl.reindexable = !params.search &&
          (!data.filters || !data.filters.length) &&
          (
            (typeof ctrl.paginationObject.total === 'number' && ctrl.paginationObject.total < MAX_REINDEX) ||
            ctrl.failure === 'load'
          )
      }

      // DATA OPTIONS

      /**
       * Builds the options object for the Filtering factory.
       *
       * @returns {object}
       */
      function filterOptions() {
        var opts = {
          update: function(filters) {
            var data = ctrl.options.data
            var changed = !_.isEqual(filters, data.filters)
            if (filters) data.filters = filters
            else delete data.filters
            if (changed) {
              updatingFilters = $q.defer()
              return updatingFilters.promise
            }
          }
        }
        var filters = getFiltersFromUrl()
        if (filters) {
          _.each(filters, function(f) {
            f.org_id = currentOrg.id
            if (f.or && f.or.length) {
              _.each(f.or, function(orf) {
                orf.org_id = currentOrg.id
              })
            }
          })
          opts.filterRuleInfos = filters
        }
        return opts
      }

      function searchOptions() {
        return {
          update: function(searchTerm) {
            ctrl.params.search = String(searchTerm) || undefined
          }
        }
      }

      // USER ACTIONS
      /**
       * Updates search parameters when the column sorting info changes.
       *
       * @private
       */
      function onSortColumnsChanged() {
        var sortColumnKey = _.chain(ctrl.sortColumns)
          .keys()
          .first()
          .value()
        var column = _.find(ctrl.dynamicColumns.shown, function(shownColumn) {
          return shownColumn.key === sortColumnKey
        })

        if (!column) {
          //The column is not registered in the dynamic columns service. Assuming it is a static column.
          delete ctrl.params.sort_source
          ctrl.params.sort = sortColumnKey
          ctrl.params.sort_dir = ctrl.sortColumns[sortColumnKey]
        }
        else if (_.includes(['survey'], column.dataSourceType)) {
          var sourceName = column.dataSourceType === 'survey' ? 'survey_results' : column.dataSourceType
          ctrl.params.sort_source = sourceName + '.' + column.dataSourceId
          ctrl.params.sort = column.field
          ctrl.params.sort_dir = ctrl.sortColumns[sortColumnKey]
        }
        else {
          delete ctrl.params.sort_source
          ctrl.params.sort = column.field
          ctrl.params.sort_dir = ctrl.sortColumns[sortColumnKey]
        }
      }

      function expandSelectedContacts(inBackground) {
        if (_.all(ctrl.selectedContacts)) {
          return $q.when(ctrl.selectedContacts)
        }
        if (inBackground && ctrl.paginationObject.total > 1000) {
          return
        }
        var opts = { result: [], load: 'all' }

        return getContacts({ per_page: 1000 }, opts)
          .then(function(contacts) {
            // Is is better to filter contacts on backend, but currently filter parameters are not supported on backend
            var deselectedContacts = ctrl.selectedContacts.deselectedItems()

            if (!deselectedContacts) return contacts

            var deselectedContactIds = _.map(deselectedContacts, function(contact) { return contact.id })
            // Be carefully some js-data meta (pagination and etc.) will be dropped after filtration, it will work normally if use filters on backend
            return _.filter(contacts, function(contact) { return deselectedContactIds.indexOf(contact.id) === -1 })
          }.bind(this))
      }

      function exportData() {
        dialog.confirm({
          directive: 'contacts-export',
          scope: $scope,
          attrs: {
            params: ctrl.params,
            filters: ctrl.options.data.filters,
            columns: ctrl.dynamicColumns,
            filteredCount: ctrl.contacts.pagination.total,
            unfilteredCount: ctrl.contacts.pagination.unfiltered_total,
            possibleFilters: true
          }
        }).then(exportSuccess)
      }

      function exportSuccess(exportResult) {
        if (exportResult.asyncExport) {
          Alerts.success('CONTACTS_EXPORT.async_export', { total_count: $filter('number')(exportResult.totalCount, 0) })
        }
        pageViewHandler.fireEvent('ExportModal.ExportClick.' + exportResult.export, 8)
      }

      function newOrgMessage() {
        pageViewHandler.fireEvent('New Org Message', 7)

        dialog.confirm({
          component: 'new-org-message',
          scope: $scope,
          attrs: {
            excludeIds: ctrl.selectedContacts.deselectedItems().map(function(item) { return item.id }),
            filters: ctrl.options.data.filters,
            recipients: expandSelectedContacts(true),
            search: ctrl.params.search
          }
        })
      }

      function sendInvoices() {
        dialog.confirm({
          directive: 'send-invoices',
          scope: $scope,
          attrs: {
            recipients: expandSelectedContacts()
          }
        }).then(function() { pageViewHandler.fireEvent('SendInvoiceModal.SendInvoiceClick', 8) })
      }

      function purchaseMemberships() {
        if (ctrl.canPurchaseOboMemberships) {
          ctrl.contactsForMembership = expandSelectedContacts()
          ctrl.showPurchaseMemberships = true
        }
        else {
          dialog.confirm({
            directive: 'no-purchaseable-memberships',
            scope: $scope,
          })
        }
      }

      function openSelectMembership() {
        if (ctrl.selectedContacts && ctrl.selectedContacts.length > ctrl.MAX_CLUB_ASSIGNMENTS) {
          return ctrl.clubAssignmentError = true
        }
        else ctrl.clubAssignmentError = false

        var ids = ctrl.selectedContacts.map(function(contact) {
          return contact.id
        })
        dialog.confirm({
          attrs: {
            bossOrgId: ctrl.inviteBossOrganizationId,
            personaIds: ids
          },
          component: 'membership-invite-modal',
          scope: $scope
        })
      }

      function loadInviteBossOrganizationId() {
        return MembershipsUtilService.getInviteBossOrganizationId(ctrl.purchaseableOboMemberships, false)
          .then(function(orgId) {
            ctrl.inviteBossOrganizationId = orgId
          })
      }

      /**
         * Displays a dialog that allows the user to create a static group.
         */
      function addStaticGroup() {
        pageViewHandler.fireEvent('Add Static Group', 7)

        dialog.confirm({
          directive: 'new-static-group',
          scope: $scope,
          attrs: {
            orgId: currentOrg.id,
            columns: _.map(ctrl.dynamicColumns.shown, 'key'),
            personas: expandSelectedContacts()
          }
        })
          .then(addGroupSuccess)
      }

      /**
         * Displays a dialog that allows the user to create a smart group.
         */
      function addSmartGroup() {
        pageViewHandler.fireEvent('Add Smart Group', 7)

        dialog.confirm({
          directive: 'new-smart-group',
          scope: $scope,
          attrs: {
            orgId: currentOrg.id,
            filters: ctrl.options.data.filters,
            columns: _.map(ctrl.dynamicColumns.shown, 'key'),
            currentSize: ctrl.contacts.pagination.total
          }
        })
          .then(addGroupSuccess)
      }

      function addGroupSuccess(createdGroup) {
        if (!createdGroup) return
        var groupType = createdGroup.groupType || 'private'
        pageViewHandler.fireEvent('AddGroupModal.' + groupType + '.AddClick', 8)
        Alerts.success('MANAGE_GROUP.success', { group_name: createdGroup.name })
        renderContext.goto('app.billing.groups.detail.members', { groupId: createdGroup.id })
      }

      function changeContactSuccess(changedContacts, removed) {
        if (!changedContacts.length) return

        // Reload after we know all the new contacts will be returned in the list
        var filters = _.map(changedContacts, function(contact) {
          return {
            field: 'sport_ngin_id',
            logic: 'end_with',
            value: String(contact.sport_ngin_id).replace(/^\w*-/, '')
          }
        })
        if (filters.length > 1) filters[0].or = filters.splice(1)

        var newTotal = removed ? 0 : changedContacts.length
          ;(function checkChangedContacts(checkCount) {
          var limit = 5
          if (ctrl.contacts.loading) return // something else hit loadContacts, so let it go
          findAll({ org_id: currentOrg.id }, { data: { filters: filters } })
            .then(function(results) {
              if (results.pagination.total === newTotal || (checkCount + 1) >= limit) loadContacts()
              else $timeout(checkChangedContacts.bind(null, checkCount + 1), 1000)
            })
        })(0)
      }

      /**
         * Displays a dialog that allows the user to add people to Groups.
         */
      function addToGroups() {
        dialog.confirm({
          component: 'contacts-add-to-groups',
          scope: $scope,
          attrs: {
            personas: expandSelectedContacts(),
            personasCount: ctrl.selectedContacts.length
          }
        })
      }

      function toggleFilterPanel(display) {
        if (arguments.length === 0) {
          display = !ctrl.showFilterPanel
        }

        ctrl.showFilterPanel = display

        if (ctrl.showFilterPanel && _.some(ctrl.filters.rules, { 'valid': false })) {
          ctrl.clearFilters()
        }

        var updateStickyContainer = $interval(function() {                  //needs for a smooth transition when filter panel slides up/down
          angular.element('[sticky-container]').triggerHandler('scroll')
        }, 10)

        $timeout(function() {
          $interval.cancel(updateStickyContainer)
        }, 300)
      }

      function openMobileFilterModal() {
        dialog.confirm({
          directive: 'mobile-filters',
          scope: $scope,
          attrs: {
            applyFilters: ctrl.options.data.filters,
            filters: ctrl.filters,
            org: ctrl.currentOrg
          }
        })
      }

      function toggleAllContacts(allSelected) {
        if (allSelected) {
          Alerts.info('SELECT_ALL.info_message', {
            item_count: $filter('number')(ctrl.contacts.pagination.total),
            item_count_num: ctrl.contacts.pagination.total,
            page_count: $filter('number')(ctrl.contacts.pagination.total_pages),
            page_count_num: ctrl.contacts.pagination.total_pages,
            label: 'CONTACTS_LIST.pagination_label'
          })
        }
      }

      function rebuildIndex() {
        pageViewHandler.fireEvent('RebuildIndexClick', 7)
        dialog.confirm({
          component: 'confirm-dialog',
          scope: $scope,
          attrs: {
            confirmAction: confirmRebuildIndex,
            heading: i18ng.t('CONTACTS_REINDEX.confirm_heading'),
            message: i18ng.t('CONTACTS_REINDEX.confirm_message'),
            confirmLabel: i18ng.t('CONTACTS_REINDEX.confirm_label')
          }
        })
      }

      function confirmRebuildIndex(data) {
        OrgPersonaIndex.rebuild(currentOrg.id)
          .then(function(data) {
            Alerts.info('CONTACTS_REINDEX.success')
            pageViewHandler.fireEvent('RebuildIndexModal.Confirm', 8)
          })
      }

      function getFiltersFromUrl() {
        var urlParams = $location.search()
        if (urlParams && urlParams.filters) {
          return filterPanelService.deserializeFiltersParam(urlParams.filters)
        }
        else return null
      }

      function setFiltersInUrl() {
        $location.search('filters', filterPanelService.serializeFiltersAsParam(ctrl.filters.serialize()))
      }

      function canReviewDuplicates() {
        if (ctrl.currentUser) {
          return ctrl.currentUser.hasRole('platform_admin') || ctrl.currentUser.hasRole('org_admin') || ctrl.currentUser.hasRole('third_north')
        }
        else {
          return false
        }
      }

    }
  })
