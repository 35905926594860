angular.module('pl-shared')

  .factory('SurveySchema', function(DS) {
    var SurveySchema = DS.defineResource({
      name: 'survey_schemas',
      basePath: DS.adapters.ngin_v2.defaults.basePath,
      httpConfig: DS.adapters.ngin_v2.defaults.httpConfig,
      defaultAdapter: 'ngin_v2',
      idAttribute: 'survey_id'
    })

    return SurveySchema
  })
