angular.module('org-admin')

  .directive('choosePaymentTerms', function() {

    return {
      scope: {},
      bindToController: {
        invoiceGroup: '=',
        paymentTermError: '='
      },
      controllerAs: 'ctrl',
      templateUrl: '/static/org/send-invoices/choose-payment-terms.html',
      controller: function(
        _,
        currentOrg,
        dialog,
        ENV,
        appPermission,
        FinancialAdminUiService,
        $scope
      ) {

        var ctrl = this

        ctrl.orgId = currentOrg.id
        ctrl.$onInit = $onInit

        ctrl.addPaymentTerms = function() {
          dialog.confirm({
            directive: 'payment-term-modal',
            scope: $scope,
            attrs: { fromSendInvoices: true }
          }).then(function(paymentTerm) {
            if (paymentTerm) {
              ctrl.paymentTermId = paymentTerm.id
              ctrl.newTermsAdded = true
            }
          })
        }

        ctrl.invoiceGroupItems = function() {
          if (ctrl.invoiceGroup.invoice_group_items.length) {
            var billAmountItem = ctrl.invoiceGroup.invoice_group_items[0].name === 'Bill Amount'
            return billAmountItem ? null : ctrl.invoiceGroup.invoice_group_items
          }
        }

        ctrl.setInvoiceGroupProperties = function(formData) {
          if (appPermission.multiPaymentTerm.usable && formData.strategy_type === 'due_in_full') {
            ctrl.invoiceGroup.paymentTermName = formData.selected_term_name
            ctrl.invoiceGroup.payment_term_id = formData.selected_term_id
            ctrl.invoiceGroup.payment_due_dates = formData.payment_dates
          }
          else if (!appPermission.multiPaymentTerm.usable) {
            ctrl.invoiceGroup.paymentTermName = formData.selected_term_name
            ctrl.invoiceGroup.payment_term_id = formData.selected_term_id
            ctrl.invoiceGroup.payment_due_dates = formData.payment_dates
          }
          ctrl.invoiceGroup.formValid = formData.valid
          ctrl.invoiceGroup.payment_terms.push({ payment_term_dates: formData.payment_dates,
            payment_term_id: formData.selected_term_id, payment_term_name: formData.selected_term_name })
          ctrl.invoiceGroup.service_fee = formData.customer_service_fee
          this.paymentTermError = false
        }

        function $onInit() {
          if (appPermission.multiPaymentTerm.usable) {
            FinancialAdminUiService.loadElementScript('multiPaymentTermsSelector')
          }
          else {
            FinancialAdminUiService.loadElementScript('paymentTermsSelector')
          }
          setTimeout(() => {
            watchPaymentTermSelectorEvents()
          }, 3000)
        }

        function watchPaymentTermSelectorEvents() {
          var element
          if (appPermission.multiPaymentTerm.usable) {
            element = document.getElementById('multi-payment-terms-selector-element')
          }
          else {
            element = document.getElementById('payment-terms-selector-element')
          }

          element.addEventListener('openPaymentTermsModal', function(event) {
            var openModal = event.detail
            if (openModal) ctrl.addPaymentTerms()
            openModal = false
            ctrl.newTermsAdded = false
          })

          element.addEventListener('outputPaymentTermForm', function(event) {
            const output = event.detail
            ctrl.invoiceGroup.payment_terms = []

            if (appPermission.multiPaymentTerm.usable) {
              for (const detail of output) {
                ctrl.setInvoiceGroupProperties(detail)
              }
              const paymentAmount = output.filter(term => term.strategy_type !== 'due_in_full')
              const fullAmount = output.filter(term => term.strategy_type === 'due_in_full')
              if (output.length === 1 && fullAmount.length > 0) {
                ctrl.invoiceGroup.initial_required_amount = fullAmount[0].initial_payment_amount
              }
              else {
                ctrl.invoiceGroup.initial_required_amount = paymentAmount[0].initial_payment_amount
              }
            }
            else {
              ctrl.setInvoiceGroupProperties(event.detail)
              ctrl.invoiceGroup.initial_required_amount = event.detail.initial_payment_amount
            }
          })
        }
      }
    }
  })
