angular.module('org-admin')
  .component('contactsHelpLinks', {
    bindings: {},
    templateUrl: '/static/org/contacts/contacts-help-links.component.html',
    controllerAs: 'ctrl',
    controller: function($location, $route, $window, FreemiumOrgService, pageViewHandler, WistiaVideo) {
      var ctrl = this
      const WISTIA_VIDEO_ID = 'npcip4qrdq'
      ctrl.$onInit = function() {
        ctrl.freemiumOrg = FreemiumOrgService.orgIsFreemium()
      }

      ctrl.triggerAppcue = function() {
        $window.Appcues?.show('c030c32a-b32a-4012-9f15-1356e46ab81d')
        this.analyticsClick('TakeATour.Click')
      }

      // Leaving the following for now, will likely remove later as this is not ideal
      // ctrl.triggerWalkme = function() {
      //   $location.search('walkme', '19-1405781')
      //   $window.location.href = $location.absUrl()

      //   this.analyticsClick('TakeATour.Click')
      // }

      ctrl.openVideo = function() {
        const video = new WistiaVideo(WISTIA_VIDEO_ID, null)
        video.open()
        this.analyticsClick('WatchAVideo.Click')
      }

      ctrl.analyticsClick = function(clickAction) {
        pageViewHandler.fireEvent(clickAction, 8)
      }
    }
  })
