angular.module('pl-shared')

  .service('surveyResultService', function(SurveyResult) {
    var formIdMap = {}

    return {
      formIds: formIds,
      surveyType: surveyType
    }

    function formIds(surveyResultId, theFormIds) {
      if (!surveyResultId) return
      if (!theFormIds) return formIdMap[surveyResultId] || []
      formIdMap[surveyResultId] = theFormIds
    }

    function surveyType(surveyResultId) {
      var sr = SurveyResult.get(surveyResultId)
      return sr ? sr.survey_type : null
    }
  })

  .service('FormResult', function(_, DS, surveyResultService, Answer, localRelations) {

    var FormResult = DS.defineResource({
      name: 'form_results',
      basePath: DS.adapters.ngin_v2.defaults.basePath,
      httpConfig: DS.adapters.ngin_v2.defaults.httpConfig,
      defaultAdapter: 'ngin_v2',
      beforeInject: function(options, instances) {
        if (!_.isArray(instances)) instances = [instances]
        _.each(instances, function(instance) {
          Answer.ejectAll({ where: { form_result_id: instance.id } })
        })
      },
      afterFindAll: function(resource, data, cb) {
        // make sure we don't collect any formResults where id is null
        //   unless it's from a member survey
        var results = []
        var formIds = []
        var surveyResultId
        _.forEach(data, function(fr, idx) {
          surveyResultId = fr.survey_result_id
          if (fr.id) { results.push(fr) }
          else if (surveyResultService.surveyType(surveyResultId) === 'site_member') {
            fr.id = (surveyResultId * 100 + idx) * -1
            results.push(fr)
          }
          formIds.push(fr.form_id)
        })
        if (surveyResultId) surveyResultService.formIds(surveyResultId, formIds)
        cb(null, results)
      },
      relations: {
        belongsTo: {
          forms: {
            localKey: 'form_id',
            localField: 'form'
          },
          survey_results: {
            localKey: 'survey_result_id',
            localField: 'survey_result'
          }
        },
        hasMany: {
          answers: {
            foreignKey: 'form_result_id',
            localField: 'answers'
          }
        }
      }
    })

    return localRelations(FormResult)
  })
  .run(function(FormResult) {})
