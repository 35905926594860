angular.module('pl-shared')

  .service('SurveyResult', function(_, DS, paginate) {
    var SurveyResult = DS.defineResource({
      name: 'survey_results',
      basePath: DS.adapters.ngin_v2.defaults.basePath,
      httpConfig: DS.adapters.ngin_v2.defaults.httpConfig,
      defaultAdapter: 'ngin_v2',
      actions: {
        unclaim: {
          method: 'PUT'
        }
      }
    })

    SurveyResult.mine = function(params, options) {
      var opts = {
        endpoint: 'survey_results/mine'
      }
      return SurveyResult.findAll(params, _.defaults(opts, options))
    }

    return paginate(SurveyResult)
  })
  .run(function(SurveyResult) {})
