angular.module('org-admin')
  .component('membershipDefinitionAttributesForm', {
    templateUrl: '/static/org/memberships/membership-definition-attributes-form.html',
    bindings: {
      model: '=',
      next: '=',
      cancel: '=',
      organization: '=',
      linkableMemDefs: '<'
    },
    controllerAs: '$ctrl',
    controller: function(
      _, $scope, i18ng, appPermission, ENV, featureToggles,
      CredentialDefinition, MembershipDefinition, MembershipsUtilService
    ) {
      var $ctrl = this
      var trackAction = MembershipsUtilService.trackAction
      var forOrgZone = MembershipsUtilService.forOrgZone
      var TIMEZONE = MembershipsUtilService.TIMEZONE
      var getInvalidFields = MembershipsUtilService.getInvalidFields
      var defaultPublishDateTime = moment.tz(TIMEZONE)

      $ctrl.$onInit = $onInit
      $ctrl.validateAndSubmit = validateAndSubmit
      $ctrl.attrValidators = MembershipDefinition.validators
      $ctrl.currentYear = (new Date()).getFullYear()
      $ctrl.linkableMemDefs = $ctrl.linkableMemDefs ? $ctrl.linkableMemDefs : []
      $ctrl.yearOptions = _.range($ctrl.currentYear - 1, $ctrl.currentYear + 3)
      $ctrl.canEditPrice = canEditPrice()
      $ctrl.removeSessionStorage = removeSessionStorage

      $ctrl.durationTypeOptions = [
        { value: 'years', label: i18ng.t('MEMBERSHIP_DEFINITION_FORMS.DURATION_OPTIONS.years') },
        { value: 'months', label: i18ng.t('MEMBERSHIP_DEFINITION_FORMS.DURATION_OPTIONS.months') },
        { value: 'weeks', label: i18ng.t('MEMBERSHIP_DEFINITION_FORMS.DURATION_OPTIONS.weeks') },
        { value: 'days', label: i18ng.t('MEMBERSHIP_DEFINITION_FORMS.DURATION_OPTIONS.days') },
      ]

      $ctrl.defaultModel = {
        boss_organization_id: $ctrl.organization.id,
        description: null,
        duration_type: 'years',
        duration_value: null,
        effective_type: null,
        end_date: null,
        name: null,
        price: null,
        linked_membership_definition_id: null,
        price_cents: 0,
        purchase_status: 'scheduled',
        start_date: null
      }

      function $onInit() {
        if ($ctrl.model.parent_membership_definition_id) $ctrl.isRenewal = true
        if ($ctrl.isRenewal) $ctrl.parentMembershipName = String($ctrl.model.name)
        if (!$ctrl.model) $ctrl.model = $ctrl.defaultModel
        if (!$ctrl.model.linked_membership_definition_id && $ctrl.linkableMemDefs.length > 0) $ctrl.model.linked_membership_definition_id = $ctrl.linkableMemDefs[0].id

        $scope.$watch('$ctrl.model.start_date', effectiveTermedChange)
        $scope.$watch('$ctrl.model.end_date', effectiveTermedChange)
        $scope.$watch('$ctrl.model.duration_type', effectiveDurationChange)
        $scope.$watch('$ctrl.model.duration_value', effectiveDurationChange)
        $scope.$watch('$ctrl.model.linked_membership_definition_id', linkedMemDefChange)

        $ctrl.purchase_open_date = forOrgZone(defaultPublishDateTime)
        $ctrl.purchase_open_time = forOrgZone('12:00 AM').format('HH:mm')
        $ctrl.purchase_close_date = null
        $ctrl.purchase_close_time = null

        loadCredentialDefinitions($ctrl.organization.id)
      }

      function removeSessionStorage() {
        window.sessionStorage.removeItem('roleTagSelection')
        window.sessionStorage.removeItem('checkedRoles')
        window.sessionStorage.removeItem('genderTagSelection')
        window.sessionStorage.removeItem('checkedGenders')
        window.sessionStorage.removeItem('seasonalityTagSelection')
        window.sessionStorage.removeItem('checkedSeasonality')
        window.sessionStorage.removeItem('ageInput')
        window.sessionStorage.removeItem('ageTagSelection')
        window.sessionStorage.removeItem('processedEndDate')
        window.sessionStorage.removeItem('processedStartDate')
        window.sessionStorage.removeItem('startDate')
        window.sessionStorage.removeItem('endDate')
        window.sessionStorage.removeItem('ageTagName')
      }

      function loadCredentialDefinitions(orgId) {
        CredentialDefinition.forOrgWithTag(orgId, 'memberships')
          .then(function(result) {
            $ctrl.credentialDefinition = result
            if (!$ctrl.credentialDefinition || $ctrl.isRenewal) $ctrl.model.can_direct = true
            $ctrl.loaded = true
          })
      }

      function validateAndSubmit() {
        trackAction(getFormErrors(), 'NextButtonAttributes.Click')
        $ctrl.attrForm.validateAndSubmit($ctrl.next)
      }

      function getFormErrors() {
        var formErrors = $ctrl.attrForm.$error.required
        if (formErrors) {
          return getInvalidFields(formErrors)
        }
        return null
      }

      /**
       * watches for changes on start_date, end_date and selects effective_type=termed
       */
      function effectiveTermedChange(newVal, oldVal, scope) {
        if (newVal && (newVal !== oldVal)) $ctrl.model.effective_type = 'termed'
      }

      /**
       * watches for changes on start_date, end_date and selects effective_type=pick_a_date
       */
      function effectiveDurationChange(newVal, oldVal, scope) {
        if (newVal && (newVal !== oldVal)) $ctrl.model.effective_type = 'pick_a_date'
      }

      function linkedMemDefChange(newVal, oldVal, scope) {
        if (newVal !== oldVal) {
          if (newVal === null) {
            $ctrl.linked_price = null
            $ctrl.credentialDefinition = null
            return
          }
          var mem_def = $ctrl.linkableMemDefs.find(function(md) {
            return md.id == newVal
          })
          if (mem_def) loadCredentialDefinitions(mem_def.boss_organization_id)
          $ctrl.linked_price = mem_def ? mem_def.price : null
        }
      }

      function canEditPrice() {
        if (!['production', 'test'].includes(ENV.current)) return true
        var stripeEnabled = !!_.result(featureToggles, 'stripe_payment_processing')
        if (stripeEnabled && appPermission.financialSettingsUi.approved) return true
        if (!stripeEnabled && appPermission.powerPay.usable) return true
        return false
      }
    }
  })
