angular.module('org-admin')
  .directive('paymentTermsDetail', function() {

    return {
      scope: {},
      templateUrl: '/static/org/payment-terms/payment-terms-detail.html',
      controllerAs: 'ctrl',
      controller: function(
        dialog,
        i18ng,
        $routeParams,
        $scope,
        PaymentTerms,
        setAs,
        setWhile,
        PusherService,
        currentOrg,
        routeTitle,
        showError
      ) {

        var ctrl = this

        ctrl.editPaymentTerm = function() {
          dialog.confirm({
            directive: 'payment-term-modal',
            scope: $scope,
            attrs: {
              paymentTerm: ctrl.paymentTerm
            }
          }).then(function() {
            routeTitle.setParams({
              payment_term_name: ctrl.paymentTerm.name
            })
          })
        }

        $scope.$watch(ctrl.paymentTermId, setWhile(ctrl, 'loading', getPaymentTerm))
        $scope.$watch(ctrl.paymentTermId, pusherSubscribe)

        function getPaymentTerm() {
          var id = ctrl.paymentTermId()
          if (!id) return
          return PaymentTerms.find(id)
            .then(setAs(ctrl, 'paymentTerm'))
            .then(function(paymentTerm) {
              ctrl.dueInFull = ctrl.isDueInFull()
              routeTitle.setParams({
                payment_term_name: ctrl.paymentTerm.name
              })
            })
            .catch(showError)
            .finally(setAs(ctrl, 'loading', false))
        }

        ctrl.paymentTermId = function() {
          return $routeParams.paymentTermId
        }

        ctrl.isDueInFull = function() {
          return ctrl.paymentTerm.payment_term_strategy.strategy_type === 'due_in_full'
        }

        ctrl.description = function() {
          if (ctrl.dueInFull) {
            return i18ng.t('PAYMENT_TERMS_DETAIL.description_due_in_full')
          }
          return ctrl.paymentTerm.description
        }

        function pusherSubscribe() {
          PusherService.subscribe('channel_clare_organization_' + currentOrg.id)
          PusherService.bind('event_payment_term_updated', +ctrl.paymentTermId(), getPaymentTerm)
        }
      }
    }
  })
