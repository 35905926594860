angular.module('pl-shared')

  .directive('defaultProtocol', function() {

    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, element, attrs, ngModelController) {
        var defaultProtocol = attrs.defaultProtocol
        if (defaultProtocol && ngModelController.$validators.url) {
          defaultProtocol += (defaultProtocol === 'mailto' ? ':' : '://')
          ngModelController.$parsers.push(function(viewValue) {
            if (viewValue && !ngModelController.$validators.url(viewValue)) {
              viewValue = defaultProtocol + viewValue
            }
            return viewValue
          })
        }
      }
    }

  })
