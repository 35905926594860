angular.module('org-admin')
  .component('filterPanelFieldSelect', {
    bindings: {
      filterRule: '<',
      org: '<',
      readOnly: '<'
    },
    templateUrl: '/static/org/filtering/filter-panel-field-select.html',
    controller: function filterPanelFieldSelectController(_, $q, $scope, i18ng, filterPanelService) {
      var $ctrl = this

      $ctrl.id = _.uniqueId('filter-panel-field-select-')
      $ctrl.model = {
        selectedField: null
      }
      $ctrl.fields = null
      $ctrl.timestamp = null
      $ctrl.actionPromise = null
      $ctrl.bucket = null
      $ctrl.select2MapFunction = angular.noop
      $ctrl.select2Options = {}

      var ajaxSelect2Options = {
        collapseOverflow: true,
        dropdownAutoWidth: true,
        placeholder: i18ng.t('CONTACTS_LIST.FILTERS.SELECT2_PLACEHOLDERS.field'),
        setupAutoWidth: true,
        ajax: {
          delay: 1000,
          transport: function(params, success, error) {
            if (!_.isUndefined(params.data.search) || params.data.page > 1) {
              var results = $ctrl.actionPromise ? $ctrl.actionPromise(params.data) : filterFieldsBySearch(params.data.search)
              return $q.resolve(results)
                .then(success)
            }
            else {
              return success($ctrl.initialFields)
            }
          },
          data: function(params) {
            return {
              orgId: $ctrl.org.id,
              page: params.page || 1,
              perPage: 50,
              search: params.term
            }
          },
          processResults: function(data, params) {
            if (!data.pagination) return { results: data, pagination: { more: false } }
            var newFields = $ctrl.select2MapFunction(_.flatten(data))
            concatFields(newFields)
            return {
              results: newFields,
              pagination: {
                more: !data.pagination.last_page
              }
            }
          }
        }
      }
      var standardSelect2Options = {
        collapseOverflow: true,
        dropdownAutoWidth: true,
        placeholder: i18ng.t('CONTACTS_LIST.FILTERS.SELECT2_PLACEHOLDERS.field'),
        setupAutoWidth: true,
        minimumResultsForSearch: -1
      }

      $scope.$watch(function() { return $ctrl.filterRule && $ctrl.filterRule.dataSourceId() }, onDataSourceChanged)
      $scope.$watch('$ctrl.model.selectedField', onFieldChanged)

      /**
       * Updates the fields list when the data source changes on a rule.
       *
       * @private
       * @param {number|string} dataSource - The unique id of the current data source.
       *   If the id is a string it is expected to be "team_instance_ids", "se_profile" or "orgProfile". If the id is a number
       *   it should be an id for a survey/registration
       */
      function onDataSourceChanged(dataSource, oldDataSource) {
        $ctrl.fields = $ctrl.initialFields = null
        $ctrl.bucket = null
        $ctrl.select2Options = standardSelect2Options

        if (!dataSource) {
          return
        }
        var dsSplit

        if (/^\d+$/.test(dataSource)) {
          $ctrl.bucket = 'survey_results'
          dsSplit = null
        }
        else {
          dsSplit = dataSource.toString().split('.')
          $ctrl.bucket = dsSplit[0]
        }

        var timestamp = $ctrl.timestamp = Date.now()

        var promise = null

        if ($ctrl.bucket === 'survey_results') {
          $ctrl.select2Options = ajaxSelect2Options
          promise = filterPanelService.getSurveyFields($ctrl.org.id, dataSource)
            .then(function(results) {
              if ($ctrl.timestamp !== timestamp) {
                return
              }
              $ctrl.initialFields = []
              $ctrl.initialFields = $ctrl.initialFields.concat(mapFormToSelect2Items({
                form_id: -1,
                form_name: 'Metadata',
                question_elements: results.metadata_question_elements
              }))
              if (results.participation_options && results.participation_options.length) {
                $ctrl.initialFields = $ctrl.initialFields.concat(mapFormToSelect2Items({
                  form_id: -3,
                  form_name: 'Participation Options',
                  question_elements: results.participation_options
                }))
              }
              $ctrl.initialFields = $ctrl.initialFields.concat(_.map(results.schema, mapFormToSelect2Items))
              $ctrl.fields = _.flatten(_.pluck($ctrl.initialFields, 'children'))
            })
        }
        else if (dataSource === 'se_profile' || dataSource === 'orgProfile') {
          promise = filterPanelService.getProfileFields($ctrl.org.id, dataSource)
            .then(function(fields) {
              if ($ctrl.timestamp !== timestamp) {
                return
              }
              $ctrl.initialFields = $ctrl.fields = _.map(fields, _.partial(mapQuestionToSelect2Item, null))
            })
        }
        else if ($ctrl.bucket === 'memberships') {
          promise = filterPanelService.getMembershipFields({ orgId: $ctrl.org.id, membershipDefinitionId: dsSplit[1] })
            .then(function(fields) {
              if ($ctrl.timestamp !== timestamp) return
              $ctrl.initialFields = $ctrl.fields = fields
              if (oldDataSource && oldDataSource !== dataSource) {
                $ctrl.filterRule.field(null)
                $ctrl.model.selectedField = null
              }
            })
        }
        else if ($ctrl.bucket === 'team_instance_ids') {
          promise = filterPanelService.getTeamInstanceFields({ orgId: $ctrl.org.id, page: 1, perPage: 50 })
            .then(function(fields) {
              if ($ctrl.timestamp !== timestamp) return
              $ctrl.initialFields = $ctrl.fields = fields
            })
        }
        else if ($ctrl.bucket === 'suspensions') {
          promise = filterPanelService.getSuspensionFields()
            .then(function(fields) {
              if ($ctrl.timestamp !== timestamp) return
              $ctrl.initialFields = $ctrl.fields = fields.filter(function(field) { return field.id !== 'status' })
            })
        }
        else if ($ctrl.bucket === 'roster_personas') {
          promise = filterPanelService.getSeasonFields({ orgId: $ctrl.org.id, seasonId: dsSplit[1] })
            .then(function(fields) {
              if ($ctrl.timestamp !== timestamp) return
              $ctrl.initialFields = $ctrl.fields = fields
            })
        }

        promise.then(function() {
          if ($ctrl.filterRule.field()) {
            var field = _.find($ctrl.fields, { id: $ctrl.filterRule.field().id })
            if ($ctrl.bucket === 'team_instance_ids' || $ctrl.bucket === 'memberships') {
              if (!_.isEmpty(field)) {
                $ctrl.model.selectedField = field
              }
            }
            else {
              $ctrl.model.selectedField = _.find($ctrl.fields, { key: $ctrl.filterRule.field().key })
            }
          }
        })
      }

      function onFieldChanged(selectedField) {
        if (!$ctrl.filterRule || !selectedField) {
          return
        }

        var currentField = $ctrl.filterRule.field()
        if (!!currentField && currentField.key === selectedField.key) {
          return
        }

        $ctrl.filterRule.field(selectedField)
      }

      /**
       * Filter fields by search term.
       *
       * @private
       * @param {string} searchTerm - search term.
       * @returns {Array} - Filtered fields for Select2.
       */
      function filterFieldsBySearch(searchTerm) {
        var searchingFields = angular.copy($ctrl.initialFields)
        var fieldsWithForms = _.some(searchingFields, 'children')

        return fieldsWithForms ? filterFieldsWithForms() : filterFields()

        function filterFieldsWithForms() {
          return _.filter(searchingFields, function(form) {
            form.children = _.filter(form.children, function(item) {
              return item.text.toUpperCase().indexOf(searchTerm.toUpperCase()) !== -1
            })
            return form.children.length
          })
        }

        function filterFields() {
          return _.filter(searchingFields, function(field) {
            return field.text.toUpperCase().indexOf(searchTerm.toUpperCase()) !== -1
          })
        }
      }

      /**
      * Maps a form to a Select2 item group.
      *
      * @private
      * @param {object} form - The form to map.
      * @returns {object.<id, text, children>} - The Select2 item group.
      */
      function mapFormToSelect2Items(form) {
        return {
          id: form.form_id,
          text: form.form_name,
          children: _.map(form.question_elements, _.partial(mapQuestionToSelect2Item, form))
        }
      }

      /**
      * Maps a question to a Select2 item.
      *
      * @private
      * @param {object} form - The form the question belongs to.
      * @param {object} question - The question data.
      * @returns {object} - The Select2 item.
      */
      function mapQuestionToSelect2Item(form, question) {
        form = form || {}

        return {
          id: question.id || _.uniqueId(),
          text: question.name,
          type: question.type,
          key: question.key,
          group: form.form_name || '',
          choice_elements: question.choice_elements
        }
      }

      /**
       * Maps team roster groups to Select2 item.
       *
       * @private
       * @param {Array} groups - The form the question belongs to.
       * @returns {Array} - The Select2 items.
       */
      function mapTeamRostersToSelect2(groups) {
        return _.map(groups, function(group) {
          return {
            id: group.id,
            text: group.name,
            type: 'team_member',
            key: group.team_instance_id.toString()
          }
        })
      }

      /**
       * Add new fields to existing ones
       *
       * @private
       * @param {Array} newFields - The new fields from the server.
       */
      function concatFields(newFields) {
        $ctrl.fields = _.uniq($ctrl.fields.concat(newFields), function(item) {
          return item.id
        })
      }

    }
  })
