angular.module('org-admin')
  .directive('paymentAdjustment', function() {
    return {
      scope: {},
      bindToController: {
        cancel: '=',
        confirm: '=',
        dialogOptions: '=',
        number: '<',
        payment: '<'
      },
      templateUrl: '/static/org/payments/payment-adjustment-modal.html',
      controllerAs: 'ctrl',
      controller: function(apiV2, currentOrg, dialog, Payment, timezone) {
        var ctrl = this
        ctrl.timezone = timezone.parseZone(timezone.current.name).abbr
        ctrl.loading = true
        loadPaymentActivity()

        function loadPaymentActivity() {
          if (!ctrl.payment) return
          return Payment.activity(ctrl.payment.id)
            .then(apiV2.deserialize)
            .then(function(response) {
              ctrl.activity = response
              ctrl.loading = false
            })
        }

        ctrl.discountNotes = function(notes) {
          return notes.includes('Code')
        }

        ctrl.noteLabelKey = function(label) {
          return label === 'Invoice Sent' ? 'view_message' : 'view_note'
        }

      }
    }
  })
