angular.module('org-admin')

  .directive('generalInfo', function() {

    return {
      scope: {},
      bindToController: {
        invoiceGroup: '=',
        groupPersonas: '<',
        recipients: '<',
        showStaticRecipients: '<',
        formName: '<'
      },
      controllerAs: 'ctrl',
      templateUrl: '/static/org/send-invoices/general-info.html',
      controller: function() {

        var ctrl = this
        ctrl.descriptionMaxlength = 64

      }
    }
  })
