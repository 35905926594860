angular.module('org-admin')
  .component('groupDetail', {
    templateUrl: '/static/org/groups/group-detail.html',
    controller: function groupDetailController(_, $q, $scope, $routeParams,
      Contact, currentOrg, dialog,
      GroupPersona, routeTitle, setAs, setWhile, showError) {
      var $ctrl = this

      $ctrl.groupId = parseInt($routeParams.groupId, 10)
      $ctrl.org = currentOrg
      $ctrl.transformTypeToClassName = transformTypeToClassName
      $ctrl.$onInit = $ctrl.loadGroup = setWhile($ctrl, 'loading', loadGroup)

      $scope.$watch('$ctrl.reload', reload)

      /**
       * Gets the icon class for the given groupPersonaType
       *
       * @param {string} groupPersonaType - The group persona type.
       * @returns {string} - The icon class to use.
       */
      function transformTypeToClassName(groupPersonaType) {
        return 'sn-' + _.kebabCase(groupPersonaType) + '-icon'
      }

      /**
       * Load the latest version of the group.
       *
       * @returns {Promise} - A promise that is resolved once the group is loaded.
       */
      function loadGroup() {
        var id = parseInt($routeParams.groupId, 10)
        return GroupPersona.find(id,
          {
            params: {
              show_messageable_members: true,
              show_unmessageable_members: true
            },
            bypassCache: true
          })
          .then(function(group) {
            $ctrl.group = group
            routeTitle.setParams({
              group_name: group.name,
              group_type: group.type
            })
          })
          .catch(showError)
      }

      /**
       * this can be called automatically from $scope.watch
       * by setting it to true in the GroupMembersComponent as a way to reload the group member list
       * @param {boolean} bool
       */
      function reload(bool) {
        if (bool) loadGroup()
        $ctrl.reload = false
      }

    }
  })
