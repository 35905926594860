angular.module('pl-shared')

  .directive('teamRosterQuestion', function() {

    return {
      scope: {},
      templateUrl: '/static/shared/components/registration/questions/team-roster-question.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '='
      },
      controller: function(_, $scope, dialog) {
        var ctrl = this

        ctrl.players = ctrl.answer.value

        ctrl.edit = function() {
          dialog.confirm({
            component: 'roster-editing-modal',
            attrs: {
              answer: ctrl.answer,
              question: ctrl.question
            }
          }).then(function() {
            ctrl.players = ctrl.answer.value
          })
        }

      }
    }

  })
