angular.module('org-admin')
  .component('rosterPersonas', {
    bindings: {
    },
    templateUrl: '/static/org/roster-management/roster-personas.html',
    controller: function(
      $debounce, $q, $sce, $scope, $timeout, $window, ENV,
      Contact, contactsDynamicColumnsFactory, currentOrg, currentUser,
      debounceCallback, dialog, featureToggles, i18ng, listFilter,
      MembershipsUtilService, pageViewHandler, Player, renderContext, RosteringState,
      Search, setAs, setWhile, Survey, selectHelper,
    ) {

      var ctrl = this
      ctrl.personas = []
      ctrl.options = { result: ctrl.personas, pagination: {}, data: {}, showUnfilteredTotal: false }
      initFiltersAndSort()
      ctrl.filterCount = filterCount
      ctrl.removeFilter = removeFilter
      ctrl.state = RosteringState
      ctrl.state.selectedPersonas = selectHelper.bind($scope, '$ctrl.personas')
      ctrl.selectedPersonasAnd = selectedPersonasAnd
      ctrl.showRosterPersonaDetail = showRosterPersonaDetail
      ctrl.columnText = columnText
      ctrl.columnSource = columnSource
      ctrl.availableColumnsSrc = { rostering: null, se_profile: null }
      ctrl.sortColumn = sortColumn
      ctrl.setTitle = setTitle
      ctrl.dragStart = dragStart
      ctrl.openFilterbox = openFilterbox
      ctrl.search = Search.create({
        minLength: 1,
        update: function(search) {
          ctrl.filters.search = _.uniq(_.compact(_.map(search.split(','), _.trim)))
        }
      })

      $scope.$on('$destroy', setAs(ctrl.state, 'survey', null))

      var defaultColumnKeys = [
        'se_profile.*.date_of_birth',
        'se_profile.*.gender',
        'rostering.*.roster_player_count'
      ]
      function shownColumnKeys() {
        var shownKeys = _.map(ctrl.dynamicColumns.shown, 'key')
        var fillinKeys = _.difference(defaultColumnKeys, shownKeys)
        _.each(shownKeys, function(key, i) {
          if (/^(se_profile|rostering)/.test(key)) return
          if (!_.startsWith(key, 'survey.' + ctrl.survey.id)) shownKeys[i] = fillinKeys.shift()
        })
        return shownKeys
      }

      ctrl.$onInit = setWhile(ctrl, 'surveysLoading', function() {
        return $q.all([
          ctrl.state.surveySelect2Options.ajax.transport({ data: {} }), // preload the js-data Survey cache for select2
          contactsDynamicColumnsFactory.create({
            cacheKey: 'rostering-players-' + currentOrg.id,
            defaultColumnKeys: defaultColumnKeys
          })
            .then(setAs(ctrl, 'dynamicColumns')),
          MembershipsUtilService.loadPurchaseableOboMemberships(currentOrg.id)
            .then(function(result) {
              ctrl.showMemberships = featureToggles.memberships || _.some(result)
            })
        ])
          .then(function() {
            if (ctrl.filters.survey.source) loadPersonasWithWatchValues() // if it was set from localStorage
          })
      })
      ctrl.$onDestroy = function() {
        Player.off('DS.afterCreate', reloadPersonasIfFiltered)
        Player.off('DS.afterDestroy', reloadPersonasIfFiltered)
      }

      var findAll = debounceCallback(Contact.findAll)
      var loadPersonas = setWhile(ctrl, 'personasLoading', _loadPersonas)
      var watchProps = '{ surveyId: $ctrl.filters.survey.source, filters: $ctrl.filters, sort: $ctrl.sortColumns, fields: $ctrl.dynamicColumns.shown }'
      var oldWatchValues

      function reloadPersonasIfFiltered() {
        if (_.any(_.omit(ctrl.filters, 'survey'))) {
          ctrl.personas.loading = true
          reloadDebounced()
        }
      }
      var reloadDebounced = $debounce(function() { ctrl.personas.pagination.reload() }, 2000)

      function loadPersonasWithWatchValues() {
        // When calling loadPersonas directly, we need to eval the watch values so that loadPersonas() is called with the same arguments as the watch.
        return loadPersonas($scope.$eval(watchProps), oldWatchValues || {})
      }
      function initFiltersAndSort() {
        ctrl.filters = $window.localStorage.getItem('MassRostering-Personas-SaveFilters') &&
                       JSON.parse($window.localStorage.getItem('MassRostering-Personas-Filters')) ||
                       { survey: { source: null, field: 'data_submitted', logic: 'data_submitted' } } // source will be set in watch
        ctrl.sortColumns = $window.localStorage.getItem('MassRostering-Personas-SaveFilters') &&
                           JSON.parse($window.localStorage.getItem('MassRostering-Personas-SortColumns')) ||
                           { name: 'asc' }
      }
      $scope.$watch('$ctrl.state.type', function(newType, oldType) {
        pageViewHandler.addPageDepths({ depth3: 'Assign' + _.startCase(_.get(renderContext, 'billing.rostering.assign.next')) })
        if (oldType && newType !== oldType) {
          initFiltersAndSort()
          if (ctrl.stickyHeaderRef) {
            ctrl.stickyHeaderRef.rebuild() // update for hidden/shown checkbox column
          }
        }
      })
      Player.on('DS.afterCreate', reloadPersonasIfFiltered)
      Player.on('DS.afterDestroy', reloadPersonasIfFiltered)
      $scope.$watch(watchProps, loadPersonas, true)

      var staticFields = [ // necessary for assigning players
        { field: 'first_name', key: 'first_name' },
        { field: 'last_name', key: 'last_name' },
        { field: 'persona_type_id', key: 'persona_type_id' },
        { field: 'data_by_survey', key: 'data_by_survey' }
      ]
      function mapColumnToField(column) {
        return {
          source: column.dataSourceType === 'survey' ? 'survey_results.' + String(column.dataSourceId) :
            column.dataSourceType === 'memberships' ? 'memberships.' + String(column.dataSourceId) : (void 0),
          field: column.field,
          key: column.id
        }
      }

      var watchReverted
      function _loadPersonas(newValues, oldValues) {
        var surveyId = (newValues.surveyId || '').replace('survey_results.', '')
        oldWatchValues = newValues // track for loadPersonasWithWatchValues
        ctrl.survey = ctrl.state.survey = Survey.get(surveyId)
        ctrl.showDropdownLinks = showDropdownLinkButton(surveyId)
        ctrl.showLinkToReg = showLinkToRegistration(surveyId)
        ctrl.showExportData = showExportDataLink(surveyId)
        ctrl.state.personaFilters = newValues.filters

        if (watchReverted) return watchReverted = false
        if (!ctrl.survey || !ctrl.dynamicColumns) return // could be called before dynamicColumns is ready, with saved filters

        if (newValues.surveyId !== oldValues.surveyId) {
          if (_.any(ctrl.filters.advanced, { source: oldValues.surveyId })) {
            dialog.confirm({
              component: 'confirm-dialog',
              scope: $scope,
              attrs: {
                heading: i18ng.t('ROSTERING.FILTERS.advanced_filter_conflict_header'),
                message: i18ng.t('ROSTERING.FILTERS.advanced_filter_conflict_body'),
                confirmLabel: i18ng.t('MODAL.continue')
              }
            })
              .then($debounce(function() {
                _.each(_.filter(ctrl.filters.advanced, { source: oldValues.surveyId }), function(filter) {
                  _.pull(ctrl.filters.advanced, filter)
                })
                angular.element('#selectedSurvey').val(newValues.surveyId).trigger('change')
              }))
            ctrl.filters.survey.source = oldValues.surveyId
            watchReverted = true
            return
          }

          if (ctrl.filters.rostered_status) {
            ctrl.filters.rostered_status.value = newValues.surveyId
          }

          if (ctrl.survey.id == 0) {
            delete ctrl.availableColumnsSrc.survey
            delete ctrl.filters.rostered_status // in case this was updated from the chips
          }
          else ctrl.availableColumnsSrc.survey = ctrl.survey.id

          // need to preload new columns and set new filters before main request is made
          return ctrl.dynamicColumns.load() // get the original (saved) column set
            .then(function() {
              return ctrl.dynamicColumns.updateShownColumns(shownColumnKeys())
                .then(function() {
                  if (_.isEqual(ctrl.dynamicColumns.shown, newValues.fields)) return loadPersonasWithWatchValues() // manually retrigger if $watch won't, to do the actual load
                })
            })
        }

        if ($window.localStorage.getItem('MassRostering-Personas-SaveFilters')) {
          $window.localStorage.setItem('MassRostering-Personas-Filters', JSON.stringify(_.omit(ctrl.filters, 'search')))
          $window.localStorage.setItem('MassRostering-Personas-SortColumns', JSON.stringify(ctrl.sortColumns))
        }

        var membershipFilters = _.filter(ctrl.filters.advanced, function(rule) { return /^memberships\./.test(rule.source) })
        if (_.any(membershipFilters)) {
          ctrl.availableColumnsSrc.memberships = membershipFilters[0].source.replace(/^memberships\./, '')
        }
        else delete ctrl.availableColumnsSrc.memberships

        ctrl.personas.length = 0
        ctrl.options.data.fields = _.map(ctrl.dynamicColumns.shown, mapColumnToField).concat(staticFields)
        ctrl.options.data.filters = _.chain(ctrl.filters)
          .omit('search') // remove search
          .values() // ctrl.filters is a hash
          .filter(_.isObject)
          .flatten() // move values in the advanced array to the base level
          .compact()
          .value()
        if (ctrl.survey.id != 0) {
          ctrl.options.data.fields.push({ source: 'survey_results.' + ctrl.survey.id, field: 'survey_result_id', key: 'survey_' + ctrl.survey.id + '_survey_result_id' })
        }
        else {
          ctrl.options.data.filters = _.reject(ctrl.options.data.filters, { field: 'data_submitted' })
          if (!_.any(ctrl.options.data.filters)) delete ctrl.options.data.filters
        }

        return findAll({
          org_id: currentOrg.id,
          survey_id: ctrl.survey.id != 0 ? ctrl.survey.id : undefined,
          search: (ctrl.search.term = (_.any(ctrl.filters.search) ? ctrl.filters.search.join(',') : undefined)),
          sort: sortKey(),
          sort_source: sortSource(),
          sort_dir: sortDir()
        }, ctrl.options)
      }

      function selectedPersonasAnd(persona) {
        return _.uniq(ctrl.state.selectedPersonas.concat(persona))
      }

      function showRosterPersonaDetail(persona, surveyId) {
        dialog.confirm({
          component: 'roster-persona-detail',
          attrs: {
            persona: persona,
            surveyId: surveyId
          }
        })
      }

      function showLinkToRegistration(survey_id) {
        return currentUser.hasRoleForResource('registration_admin', 'Survey', survey_id) ||
          currentUser.hasRoleForResource('registration_admin', 'Organization', currentOrg.id) ||
          currentUser.superRole
      }

      function showDropdownLinkButton(survey_id) {
        return showLinkToRegistration(survey_id) || showExportDataLink(survey_id)
      }

      function showExportDataLink(survey_id) {
        return featureToggles.pga_jr_league_rostering &&
          currentUser.hasRoleForResource('relational_registration', 'Survey', survey_id)
      }

      function columnText(column) {
        if (column.id !== 'se_profile_date_of_birth') return column.title || column.name
        return i18ng.t('ROSTERING.' + (ctrl.state.type === 'player' ? 'date_of_birth' : 'age'))
      }

      function columnSource(column) {
        switch (column.dataSourceType) {
          case 'rostering':
          case 'se_profile':
            return i18ng.t('ROSTERING.from', { source: column.dataSourceName })
          default:
            return i18ng.t('ROSTERING.from', { source: i18ng.t('ROSTERING.registration') })
        }
      }

      function sortColumn(column) {
        if (column.id !== 'roster_players') return column.key
        return 'data_by_survey.' + ctrl.state.type
      }

      function setTitle($event) {
        var td = $event.currentTarget
        var $td = angular.element(td)
        $td.find('.sn-ellipsis').removeClass('sn-ellipsis') // let the td do its own ellipsis cutoff
        if (td.clientWidth < td.scrollWidth) $td.attr('title', _.trim($td.text()))
      }

      function dragStart(persona, event) {
        if (event.dataTransfer.setDragImage) {
          var personaNames = listFilter(_.map(selectedPersonasAnd(persona), 'name'))
          var dragImage = angular.element('.ba-rostering__drag-image')
          dragImage.text(personaNames)
          event.dataTransfer.setDragImage(dragImage[0], 0, 0)
        }
      }

      function filterCount() {
        var filterCount = _.size(_.omit(ctrl.filters, 'advanced'))
        if (_.any(ctrl.filters.search)) filterCount += ctrl.filters.search.length - 1
        return filterCount + _.size(ctrl.filters.advanced)
      }
      function removeFilter(key, item) {
        $timeout(function() { // allow event to bubble up to parent first
          switch (key) {
            case 'search':
              ctrl.search(item ? _.without(ctrl.filters.search, item).join(',') : '')
              break
            default:
              delete ctrl.filters[key]
              break
          }
        })
      }

      function openFilterbox() {
        dialog.confirm({
          component: 'roster-personas-filterbox',
          attrs: _.pick(ctrl, 'filters', 'showMemberships')
        })
          .then(setAs(ctrl, 'filters'))
      }

      function sortKey() {
        var sortColumnKey = _.first(_.keys(ctrl.sortColumns))
        var column = _.find(ctrl.dynamicColumns.shown, { key: sortColumnKey })
        return column ? column.field : sortColumnKey
      }

      function sortSource() {
        var sortColumnKey = _.first(_.keys(ctrl.sortColumns))
        var column = _.find(ctrl.dynamicColumns.shown, { key: sortColumnKey })
        if (column && column.dataSourceType === 'survey') return column.dataSourceId
      }

      function sortDir() {
        if (sortKey() == 'date_of_birth' && ctrl.state.type === 'coach') return (_.first(_.values(ctrl.sortColumns)) == 'asc') ? 'desc' : 'asc'
        return _.first(_.values(ctrl.sortColumns))
      }

      ctrl.exportColumns = function() {
        return ctrl.dynamicColumns.getAvailableColumns(_.pick(ctrl.availableColumnsSrc, 'survey'))
          .then(function(availableColumns) {
            var url = ENV.urls.apiProxy + '/ngin/v2/contact_list/export.csv?' + $.param({
              org_id: currentOrg.id,
            })
            ctrl.exportOptions = {
              url: url,
              trustedUrl: $sce.trustAsResourceUrl(url),
              search: ctrl.filters.search ? ctrl.filters.search.join(',') : undefined,
              filters: ctrl.options.data.filters,
              columns: _.filter(ctrl.dynamicColumns.shown.concat(availableColumns), { dataSourceType: 'survey' })
            }
            return $timeout(function() { // allow the form bindings time to update
              angular.element('#exportForm').submit()
            })
          })
      }

      ctrl.team_column = function(persona) {
        const personaRegistrations = persona.dataBySurveyId[ctrl.state.type]
        const personaRegistrationArray = Object.values(personaRegistrations)
        if (personaRegistrationArray.length === 0) {
          return null
        }

        if (ctrl.filters.survey.source != 0) {
          return personaRegistrations[ctrl.filters.survey.source.replace('survey_results.', '')]?.team
        }

        if (personaRegistrationArray.length === 1) {
          return personaRegistrationArray[0].team
        }

        return teamSum(personaRegistrationArray)
      }

      function teamSum(personaRegistrationArray) {
        return personaRegistrationArray.reduce(
          (previousValue, currentValue) => previousValue + (!isNaN(currentValue.team) ? parseInt(currentValue.team) : 1),
          0
        )
      }
    }

  })
