angular.module('pl-shared')
  .factory('MembershipUiService', function(
    $q, $window, ENV
  ) {

    return {
      loadScripts: loadScripts,
      injectScript: injectScript
    }

    function loadScripts(ngElName) {
      var scripts = ['runtime', 'polyfills', 'vendor']
      scripts.forEach(function(scriptName) {
        injectScript(scriptName, ngElName)
      })
    }

    // private functions
    function injectScript(scriptName, ngElName) {
      // only load 1 polyfill across all libs
      const prefix = scriptName === 'polyfills' ? 'se-ui-libs-' : 'se-membership-ui-'
      const id = prefix + scriptName
      var script = $window.document.getElementById(id)
      if (!script) {
        script = $window.document.createElement('script')
        script.async = false // ensure the scripts load in order
        script.src = ENV.urls.membershipUiLibrary + '/' + ngElName + '/' + scriptName + '.js'
        script.type = 'text/javascript'
        script.id = id
        $window.document.head.appendChild(script)
      }
    }

  })
