angular.module('org-admin')

  .factory('SeasonTeams', function(DS, paginate, Player) {
    var SeasonTeams = DS.defineResource({
      name: 'seasonTeams',
      endpoint: 'season_teams',
      basePath: DS.adapters.ngin_v2.defaults.basePath,
      httpConfig: DS.adapters.ngin_v2.defaults.httpConfig,
      defaultAdapter: 'ngin_v2',
      relations: {
        hasMany: {
          players: _.map([null, 'player', 'coach'], function(type) {
            var filterParams = type && { type: type }
            return {
              localField: 'roster_players' + (type ? '_' + type : ''),
              foreignKey: 'roster_id',
              get: function(SeasonTeams, relationDef, seasonTeam, orig) {
                return Player.filter(_.extend({ roster_id: seasonTeam.roster_id }, filterParams))
              }
            }
          })
        }
      }
    })

    return paginate(SeasonTeams)
  })
