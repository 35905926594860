angular.module('org-admin')

  .directive('invoiceGroupsDetail', function() {

    return {
      scope: {},
      bindToController: {},
      templateUrl: '/static/org/invoice-groups/invoice-groups-detail.html',
      controllerAs: 'ctrl',
      controller: function(
        _,
        $scope,
        $debounce,
        $routeParams,
        currentOrg,
        dialog,
        InvoiceGroup,
        PaymentTerms,
        routeTitle,
        showError
      ) {

        var ctrl = this
        ctrl.org = currentOrg
        ctrl.loading = true

        // PUBLIC METHODS

        ctrl.editInvoiceGroup = function() {
          dialog.confirm({
            directive: 'invoice-group-edit',
            scope: $scope,
            attrs: {
              invoiceGroup: ctrl.invoiceGroup
            }
          }).then(function() {
            routeTitle.setParams({ invoice_group_description: ctrl.invoiceGroup.description })
          })
        }

        ctrl.getPercentage = function(divisor, dividend) {
          dividend = +dividend
          divisor = +divisor

          var multiplier = dividend / divisor
          if (_.isNaN(multiplier)) {
            return '--'
          }
          else {
            return (multiplier * 100).toFixed() + '%'
          }
        }

        ctrl.hasPaymentTerms = function() {
          return ctrl.invoiceGroup && ctrl.invoiceGroup.payment_term_id
        }

        ctrl.showPaymentTerms = function() {
          return ctrl.invoiceGroup && ctrl.invoiceGroup.invoices_paid_count !== 0
        }


        $scope.$watch(invoiceGroupId, update)
        $scope.$on('invoice:update', $debounce(update, 1000)) // allow other pages' updates to happen first


        // PRIVATE METHODS

        function update() {
          var id = invoiceGroupId()
          if (!id) return
          InvoiceGroup.find(id, { bypassCache: true })
            .then(updateSuccess)
            .catch(showError) // TODO: make this inline error
            .then(getPaymentTerm)
            .finally(function() {
              ctrl.hideEdit = !ctrl.invoiceGroup.invoices_open_count && !ctrl.invoiceGroup.invoices_past_due_count
              ctrl.loading = false
            })
        }

        function getPaymentTerm() {
          if (ctrl.hasPaymentTerms()) {
            PaymentTerms.find(ctrl.invoiceGroup.payment_term_id)
              .then(function(response) {
                ctrl.paymentTerm = response
              })
          }
        }

        function updateSuccess(invoiceGroup) {
          routeTitle.setParams({ invoice_group_description: invoiceGroup.description })
          ctrl.invoiceGroup = invoiceGroup
        }

        function invoiceGroupId() {
          return $routeParams.invoiceGroupId
        }
      }
    }

  })
