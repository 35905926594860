angular.module('org-admin')
  .component('filterPanelRule', {
    bindings: {
      canRemove: '&',
      filterRule: '<',
      first: '<?',
      org: '<',
      readOnly: '<',
      onRemove: '&?',
      limitToSurveyId: '<?',
      memberships: '<?',
      useMemberIdSource: '<?'
    },
    templateUrl: '/static/org/filtering/filter-panel-rule.html',
    controller: function filterPanelRuleController() {
    }
  })
