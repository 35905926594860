angular.module('org-admin')
  .component('leagueLocationDetails', {
    bindings: {
      venue: '<?',
      cancel: '<',
      confirm: '<',
      addSubvenueOnly: '@?'
    },
    templateUrl: '/static/org/league-locations/league-location-details.html',
    controller: function(_, $q, appPermission, currentOrg, Persona, pageViewHandler, setAs, setWhile, showError, Venue, Subvenue) {
      if (!appPermission.leagueSeasons.visible) return showError('404')

      var ctrl = this

      ctrl.$onInit = function() {
        if (ctrl.venue) {
          ctrl.mode = 'edit'
        }
        else {
          ctrl.mode = 'add'
          ctrl.venue = Venue.createInstance({
            org_id: currentOrg.id,
            address: { country: 'US' }
          })
        }
        ctrl.subvenues = [{}] // or fetch from Subvenue, then add {}
        ctrl.Persona = Persona
        ctrl.states = Persona.states_provinces['United States']
      }

      ctrl.addSubvenue = function() {
        if (_.last(ctrl.subvenues).name) ctrl.subvenues.push({})
      }

      ctrl.submit = function() {
        if (!ctrl.addSubvenueOnly) {
          return Venue.save(ctrl.venue) // upsert will choose POST vs. PUT
            .then(createSubvenues)
            .then(confirm)
        }
        else {
          return createSubvenues(ctrl.venue)
            .then(confirm)
        }
      }

      ctrl.revertChanges = function() {
        if (ctrl.mode !== 'add') ctrl.venue.DSRevert()
        ctrl.cancel()
      }

      ctrl.removeNewSubvenue = function(subvenue) {
        _.pull(ctrl.subvenues, subvenue)
      }

      ctrl.hasSubvenue = function() {
        return _.any(_.filter(ctrl.subvenues, 'name'))
      }

      function createSubvenues(venue) {
        if (ctrl.mode == 'edit' && !ctrl.addSubvenueOnly) return

        var subvenues = _.filter(ctrl.subvenues, 'name')
        if (!ctrl.hasSubvenue()) subvenues.push({ name: venue.name, is_default: true /* not used yet */ })
        return $q.all(_.map(subvenues, function(sv) {
          if (!Subvenue.is(sv)) sv = Subvenue.createInstance(sv)
          sv.venue_id = venue.id
          return Subvenue.save(sv)
        }))
      }

      function confirm() {
        var action = ctrl.mode === 'add' ?
          'Location.AddLocationModal.Create' :
          ctrl.addSubvenueOnly ?
            'Location.AddFacilityModal.Create' :
            'Location.EditLocationModal.Save'

        pageViewHandler.fireEvent(action, 8)
        ctrl.confirm()
      }
    }
  })
