angular.module('org-admin')
  .component('filterPanelInheritanceInput', {
    bindings: {
      id: '@ctrlId',
      filterRule: '<',
      readOnly: '<'
    },
    templateUrl: '/static/org/filtering/inputs/filter-panel-inheritance-input.html',
    controller: function(_, $scope, i18ng) {
      var $ctrl = this

      $ctrl.getChoices = getChoices

      $ctrl.select2Options = {
        minimumResultsForSearch: 10,
        collapseOverflow: true,
        placeholder: i18ng.t('CONTACTS_LIST.FILTERS.SELECT2_PLACEHOLDERS.select')
      }

      function getChoices() {
        if (!$ctrl.filterRule || !$ctrl.filterRule.field()) {
          return []
        }

        return _.map($ctrl.filterRule.field().choice_elements, 'choice')
      }
    }
  })
