angular.module('org-admin')

  .service('PaymentTerms', function(DS) {
    var paymentTerms = DS.defineResource({
      name: 'payment_terms',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
      bypassCache: true
    })

    return paymentTerms
  })
