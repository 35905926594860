angular.module('pl-shared')
  .factory('SiteMembership', function(_, DS, colorHelper) {

    // Ordered by most relevant for org colors (according to Jesse Houchins' experience)
    var colorProps = [
      'site_link_color',
      'site_accent_color',
      'site_hover_color',
      'site_background_color'
      // NOTE: highlight_color and button_highlight_text are irrelevant, so don't even check those ;)
    ]

    var SiteMembership = DS.defineResource({
      name: 'site_memberships',
      endpoint: 'site_memberships',
      idAttribute: 'id',
      basePath: DS.adapters.ngin_v2.defaults.basePath,
      httpConfig: DS.adapters.ngin_v2.defaults.httpConfig,
      defaultAdapter: 'ngin_v2',
      computed: {
        // This tries to select the most likely org color from the site paintjob
        // based on what colors are used as org colors and the colors chroma. If
        // a particular named color (like link_color) is very dull, we will check
        // other named colors first to try to find one that is more vibrant.
        primaryColor: [function() {
          var membership = this
          var colors = _.map(colorProps, colorChroma)

          return choosePrimary()

          function colorChroma(prop) {
            var hex = membership[prop]
            return {
              hex: hex,
              chroma: colorHelper.hexToChroma(hex)
            }
          }

          function choosePrimary() {
            var clr
            for (var i = 0; i < colors.length; i++) {
              clr = colors[i]
              if (clr.chroma > 0.1) return '#' + clr.hex
            }
            clr = _.sortBy(colors, 'chroma').reverse()[0] || {}
            return clr.hex ? '#' + clr.hex : '#666666'
          }
        }],
        // Check to see if the primary color is light or dark
        primaryColorIsLight: ['primaryColor', function(primaryColor) {
          return colorHelper.hexToLuma(primaryColor) > 0.5
        }]
      },
      methods: {
        cancel: function() {
          return this.DSUpdate({ status: 'declined' })
        }
      }
    })

    return SiteMembership
  })
