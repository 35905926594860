angular.module('pl-shared')
  .factory('personaSurveyResults', function($q, SurveyResult, FormResult) {


    var isSiteMemberSurvey = _.iteratee({ survey_type: 'site_member' })
    var isNotSiteMemberSurvey = _.negate(isSiteMemberSurvey)

    var personaSurveyResults = {
      loadAllSurveyResults: loadAllSurveyResults,
      filterEmptySiteMemberSurveyResults: filterEmptySiteMemberSurveyResults,
      getOrganizations: getOrganizations
    }

    return personaSurveyResults


    function loadAllSurveyResults(params, options) {
      return SurveyResult.findAll(params, options)
    }

    function filterEmptySiteMemberSurveyResults(surveyResults) {

      return $q.all(_.map(surveyResults, function(surveyResult) {
        if (isNotSiteMemberSurvey(surveyResult)) return surveyResult

        return FormResult
          .findAll({ survey_result_id: surveyResult.id })
          .then(function(formResults) {
            if (_.any(formResults)) return surveyResult
          })
      }))
        .then(_.compact)
    }

    function getOrganizations(surveyResults) {
      var groupedSurveyResults = _.groupBy(surveyResults, 'organization_id')

      var orgs = _.map(groupedSurveyResults, function(surveyResults, organization_id) {
        return {
          id: +organization_id,
          name: surveyResults[0].organization_name,
          surveyResults: _.sortBy(surveyResults, isNotSiteMemberSurvey)
        }
      })

      return _.sortBy(orgs, 'name')

    }

  })
