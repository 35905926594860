angular.module('pl-shared')
  .component('countrySelect', {
    bindings: {
      fieldName: '@',
      countries: '<?',
      selectedCountry: '=',
      isRequired: '<',
      label: '@'
    },
    templateUrl: '/static/shared/components/country-select/country-select.html',
    controller: function(countries) {
      if (!this.countries) this.countries = countries
    }
  })
