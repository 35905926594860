angular.module('org-admin')
  .service('CompositeRoles', function(DS, $q) {
    var CompositeRoles = DS.defineResource({
      name: 'composite_roles',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
    })

    return CompositeRoles
  })
