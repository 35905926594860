angular.module('org-admin')
  .component('rosterAssign', {
    bindings: {},
    templateUrl: '/static/org/roster-management/roster-assign.html',
    controller: function() {

      var ctrl = this

    }
  })
