angular.module('pl-shared')

  .directive('monetaryQuestion', function() {

    return {
      scope: {},
      templateUrl: '/static/shared/components/registration/questions/monetary-question.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '='
      },
      controller: function(_, $scope) {
        var ctrl = this
        var el = ctrl.question

        ctrl.inputName = 'form_result_' + el.id
        ctrl.inputId = ctrl.inputName
        ctrl.required = el.is_required
        ctrl.shortHelp = el.inline_help
        ctrl.longHelp = el.long_help
        ctrl.model = _.isObject(ctrl.answer) ? (parseFloat(ctrl.answer.value) || '') : ''

        // answer.value will be in cents, but input expected in dollars
        ctrl.dollarValue = parseInt(ctrl.model || 0) / 100

        $scope.$watch('ctrl.dollarValue', function(dollars, oldval) {
          ctrl.model = parseInt(parseFloat(dollars || 0) * 100)
        })

      }
    }

  })
