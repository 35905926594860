angular.module('org-admin')
  .factory('ReportsService', function(
    apiV2, ENV, $http, i18ng, currentOrg, $window
  ) {
    var payoutStatusTags = {
      paid: { color: 'success', label: i18ng.t('PAYOUTS_REPORT.STATUSES.deposited') },
      pending: { color: 'info', label: i18ng.t('PAYOUTS_REPORT.STATUSES.scheduled') },
      in_transit: { color: 'info', label: i18ng.t('PAYOUTS_REPORT.STATUSES.scheduled') },
      canceled: { color: 'neutral-dark', label: i18ng.t('PAYOUTS_REPORT.STATUSES.canceled') },
      failed: { color: 'error', label: i18ng.t('PAYOUTS_REPORT.STATUSES.failed') },
      forwarded: { color: 'neutral-dark', label: i18ng.t('PAYOUTS_REPORT.STATUSES.forwarded') },
    }

    function viewLegacyReports() {
      $window.open(currentOrg.siteUrl + '/order/summary', '_blank')
    }

    function getLatestNginOrder() {
      var url = ENV.urls.apiProxy + '/ngin/v2/orders.json'
      var params = {
        organization_id: currentOrg.id,
        latest_order_by_org_id: true
      }
      var opts = {
        headers: apiV2.headers,
        params: params,
        withCredentials: true
      }
      return $http.get(url, opts)
    }

    return {
      payoutStatusTags: payoutStatusTags,
      viewLegacyReports: viewLegacyReports,
      getLatestNginOrder: getLatestNginOrder
    }
  })
