angular.module('org-admin')
  .directive('bankAccountModalGbr', function() {
    return {
      scope: {},
      bindToController: {
        cancel: '=',
        confirm: '=',
        dialogOptions: '=',
        bankAccount: '<',
        createAccount: '<'
      },
      templateUrl: '/static/org/bank-account-settings/GBR/bank-account-modal.html',
      controllerAs: 'ctrl',

      controller: function(
        Alerts,
        BankAccount,
        currentOrg,
        setAs,
        setWhile,
        $scope,
        showError) {

        var ctrl = this
        ctrl.bankAccount = angular.copy(ctrl.bankAccount)

        var permittedParams = [
          'account_country',
          'account_number',
          'account_owner_name',
          'account_type',
          'bank_name',
          'branch_code',
          'address_1',
          'iban',
          'nickname',
          'organization_id',
          'city',
          'postal_code',
          'check_image'
        ]

        if (ctrl.createAccount) setDefaultAccount()

        if (ctrl.bankAccount) {
          if (ctrl.bankAccount.reject_reason) ctrl.bankAccountUpdate = true
          ctrl.showDetailedErrorMessage = showDetailedErrorMessage()
          ctrl.ibanSelected = !!ctrl.bankAccount.iban
        }
        else {
          ctrl.bankAccountUpdate = true
          setWhile(ctrl, 'loading', loadSelectedAccount)()
            .then(setAs(ctrl, 'bankAccount'))
            .then(function() {
              ctrl.showDetailedErrorMessage = showDetailedErrorMessage()
              ctrl.ibanSelected = !!ctrl.bankAccount.iban
            })
            .catch(setDefaultAccount)
        }

        ctrl.editMode = function() {
          return !!ctrl.bankAccount && ctrl.bankAccount.status === 'approved'
        }

        function setDefaultAccount() {
          ctrl.bankAccount = {
            organization_id: currentOrg.id,
            account_country: 'GB',
            account_type: 'checking',
            check_image: ''
          }
        }

        function showDetailedErrorMessage() {
          return ctrl.bankAccount.reject_reason && ctrl.bankAccount.check_image.filename
        }

        function loadSelectedAccount() {
          return BankAccount.getCurrentAccount(currentOrg.id, true)
        }

        function saveAccount(params) {
          if (ctrl.bankAccount.id) {
            return BankAccount.editAccount(ctrl.bankAccount.id, params)
          }
          else {
            return BankAccount.createBankAccount(params)
          }
        }

        function updateBankAccountList() {
          $scope.$emit('bankAccounts:update')
          if (ctrl.editMode()) {
            Alerts.success('BANK_ACCOUNT_EDIT.success')
          }
          else {
            Alerts.info('BANK_ACCOUNT_SETTINGS.GBR_new_account_info')
          }
        }

        ctrl.submit = function() {
          var params = _.pick(ctrl.bankAccount, permittedParams)
          params.bank_name = params.nickname
          ctrl.savingBankAccount = true
          setWhile(ctrl, 'saving', saveAccount(params))()
            .then(
              function() {
                ctrl.savingBankAccount = false
                updateBankAccountList()
                return ctrl.confirm()
              },
              function(response) {
                var responseError = ''
                responseError = response.data.error ? response.data.error : response.statusText
                ctrl.serverErrors = responseError.messages || [responseError]
              }
            ).catch(showError)
        }
      }
    }
  })
