angular.module('pl-shared')

  .directive('imageCropper', function() {

    var $ = angular.element

    return {
      restrict: 'A',
      scope: {},
      bindToController: {
        model: '='
      },
      templateUrl: '/static/shared/components/image-cropper/image-cropper.html',
      controllerAs: 'ctrl',
      controller: function($scope, $element, fileHelper) {
        var ctrl = this
        ctrl.dataUrl = fileHelper.base64Data(ctrl.model)
        ctrl.croppedImage = '' // must be initialized to get valid data in the change callback
        ctrl.cropImage = function(data) {
          ctrl.model.data = fileHelper.stripBase64Data(data)
        }
      }
    }

  })
