angular.module('pl-shared')
  .service('FifaService', function($http, ENV, apiV2, _) {

    var apiProxyUrl = ENV.urls.apiProxy
    var opts = {
      withCredentials: true
    }

    return {
      getOrganizationConfiguration: getOrganizationConfiguration,
      getPersonaPlayerId: getPersonaPlayerId,
      isFifaOnForOrganization: isFifaOnForOrganization,
      syncFifaCompetition: syncFifaCompetition
    }

    /**
     * Retrieves FIFA organization configuration from
     * SE-FifaProvider API Gateway
     * @param {number} orgId BOSS Organization Id
     */
    function getOrganizationConfiguration(orgId) {
      var url = apiProxyUrl + '/fifa/configuration/' + orgId
      return $http.get(url, opts).then(returnData)
    }

    /**
     * Retrieves FIFA player id for the persona
     * @param {number} personaId Persona Id
     */
    function getPersonaPlayerId(personaId) {
      var url = apiProxyUrl + '/fifa/status/player/' + personaId
      return $http.get(url, opts).then(returnData)
    }

    /**
     * Retreives boss flags for org via api and checks if fifa is on
     * @param {number} orgId
     */
    function isFifaOnForOrganization(orgId) {

      return $http({
        method: 'GET',
        url: apiProxyUrl + '/feature_instances',
        params: { organization_id: orgId },
        headers: apiV2.headers
      })
        .then(apiV2.deserialize)
        .then(function(response) {
          return !!_.find(response.data, function(feature) {
            return feature.key === 'fifa_integration' && feature.can_use && feature.enabled
          })
        })
    }

    /**
     * Submits an organization to be synced to FIFA
     * @param {number} orgId BOSS Organization Id
     */
    function syncFifaCompetition(orgId) {
      var url = apiProxyUrl + '/fifa/competition/' + orgId
      return $http.post(url, null, opts).then(returnData)
    }

    /**
     * Internal method for parsing data from the $http response object
     */
    function returnData(httpResponse) {
      return httpResponse.data
    }
  })
