angular.module('org-admin')

  .service('ProgramListingOptions', function($q, $http, apiV2, i18ng, currentOrg, ENV) {

    var cache = {}
    var apiProxyUrl = ENV.urls.apiProxy

    return {
      getOptions: getOptions,
      includeSports: includeSports,
      staticOptions: setStaticOptions({}),
    }

    function setStaticOptions(options) {
      options.genders = labelValuePairs('PROGRAM_LISTING_OPTIONS.genders')
      options.days_of_weeks = labelValuePairs('PROGRAM_LISTING_OPTIONS.days_of_week')
      options.pricings = labelValuePairs('PROGRAM_LISTING_OPTIONS.pricing')
      options.registration_types = labelValuePairs('PROGRAM_LISTING_OPTIONS.reg_type')
      options.registration_statuses = labelValuePairs('PROGRAM_LISTING_OPTIONS.reg_status')
      return options
    }

    function setSports(data) {
      if (_.any(currentOrg.sports)) data.sports = _.cloneDeep(currentOrg.sports)
      if (!data.sports) data.sports = []
      return data
    }

    function includeSports(programSports, dataSports) {
      _.forEach(programSports, function(s) {
        if (!_.find(dataSports, { value: s.value })) {
          dataSports.push(_.clone(s))
        }
      })
      return _.sortBy(dataSports, function(s) { return s.label })
    }

    function getOptions() {
      var url = apiProxyUrl + '/program_listing_options'
      var opts = { headers: apiV2.headers, withCredentials: true }
      var promise = cache[url] = !cache[url] ? $http.get(url, opts) : cache[url]

      return promise.then(apiV2.deserialize).then(setStaticOptions).then(setSports)
    }

    function labelValuePairs(translationKey) {
      var pairs = []
      var obj = i18ng.t(translationKey, { returnObjectTrees: true })
      _.each(obj, function(label, value) {
        pairs.push({ label: label, value: value })
      })
      return pairs
    }
  })
