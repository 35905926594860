angular.module('org-admin')
  .factory('OrgPersonaIndex', function(_, $http, apiV2, ENV) {

    /**
     * @alias OrgPersonaIndexStatus
     *
     * @property {object} org_persona - Defines the status for the OrgPersona.
     * @property {string} org_persona.status - The status of the index. Can be one of the following values; "NotIndexed", "Indexing", "Indexed"
     * @property {number} org_persona.total_personas_count - The number of personas expected to be in the index.
     * @property {number} org_persona.indexed_personas_count - The number of personas that have been added to the index.
     */

    var OrgPersonaIndex = {
      status: getStatus,
      provision: provision,
      rebuild: rebuild
    }
    var apiProxyUrl = ENV.urls.apiProxy

    return OrgPersonaIndex

    /**
     * Retrieves the current status of the organization for the specified organization.
     *
     * @param {number} orgId - The organization id to retrieve the status for.
     * @returns {Promise.<OrgPersonaIndexStatus>} - A promise that is resolved with the current status of the organization index.
     */
    function getStatus(orgId) {
      var requestConfig = {
        method: 'GET',
        url: apiProxyUrl + '/org_persona_index/status?org_id=' + orgId,
        headers: apiV2.headers,
        withCredentials: true,
      }

      return $http(requestConfig)
        .then(apiV2.deserialize)
    }

    /**
     * Starts the job to provision the specified organization for People360.
     *
     * @param {number} orgId - The organization to reindex.
     * @returns {Promise} - A promise that is resolved once the job is started.
     */
    function provision(orgId) {
      var requestConfig = {
        method: 'POST',
        url: apiProxyUrl + '/org_persona_index/provision?org_id=' + orgId,
        headers: apiV2.headers,
        withCredentials: true,
      }

      return $http(requestConfig)
        .then(apiV2.deserialize)
    }

    /**
     * rebuilds index
     */
    function rebuild(orgId) {
      var requestConfig = {
        method: 'POST',
        url: apiProxyUrl + '/org_persona_index/reindex',
        headers: apiV2.headers,
        data: { org_id: orgId },
        withCredentials: true,
      }

      return $http(requestConfig)
        .then(apiV2.deserialize)
    }

  })
