angular.module('org-admin')
  .component('rosterTransfer', {
    templateUrl: '/static/org/roster-management/roster-transfer.html',
    controller: function(RosteringState, setAs, setWhile) {

      var ctrl = this
      ctrl.state = RosteringState
      var leagueField = ctrl.state.filtering.teamFilterSchema.league_id

      ctrl.$onInit = setWhile(ctrl, leagueField.collectionKey + 'Loading', function() {
        return leagueField.service.findAll(leagueField.findAllParams)
          .then(setAs(ctrl, leagueField.collectionKey))
      })

      ctrl.$onDestroy = function() {
        delete ctrl.state.selectedLeague
      }

    }

  })
