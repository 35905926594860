angular.module('org-admin')
  .service('ProcessorSettings', function($http, ENV) {
    return {
      http: http,
      syncProcessorSettings: syncProcessorSettings
    }

    // PRIVATE METHODS

    function http(method, orgId, data) {
      return $http({
        method: method,
        url: ENV.urls.apiProxy + '/organizations/' + orgId + '/processor_settings',
        data: data,
        headers: { Accept: 'application/json' },
        withCredentials: true,
      })
    }

    function syncProcessorSettings(orgId) {
      return $http({
        method: 'PUT',
        url: ENV.urls.apiProxy + '/organizations/' + orgId + '/processor_settings/sync',
        headers: { Accept: 'application/json' },
        withCredentials: true,
      })
    }
  })
