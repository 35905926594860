angular.module('org-admin')
  .directive('bankAccountAddOther', function() {
    return {
      scope: {},
      bindToController: {
        cancel: '=',
        confirm: '=',
        dialogOptions: '='
      },
      templateUrl: '/static/org/bank-account-settings/Other/add-account-modal.html',
      controllerAs: 'ctrl',

      controller: function(
        BankAccount,
        currentOrg,
        getEnum,
        i18ng,
        setWhile,
        $element,
        $rootScope,
        $scope,
        showError
      ) {

        var permittedParams, ctrl = this

        ctrl.countries = ['US', 'CA']
        ctrl.accountTypes = ['checking', 'savings']
        ctrl.failed = false
        ctrl.labelChooseFile = i18ng.t('BANK_ACCOUNT_NEW.LABELS.check_image_upload')
        ctrl.uploadingImage = false
        $scope.fileSelected = ''

        // Set defaults
        ctrl.bankAccount = {
          organization_id: currentOrg.id,
          account_country: 'US',
          account_type: 'checking',
          check_image: ''
        }

        permittedParams = [
          'account_country',
          'account_number',
          'account_number_confirmation',
          'account_owner_name',
          'account_type',
          'bank_name',
          'canada_institution_number',
          'canada_bank_transit_number',
          'nickname',
          'organization_id',
          'routing_number',
          'check_image'
        ]

        // Bind click to hidden file upload input
        ctrl.selectCheckImage = function() {
          angular.element('#checkImageUpload').trigger('click')
        }

        // Get Check Image file information when upload file input change
        $scope.uploadCheckImage = function(el) {
          if (el.files) {
            ctrl.uploadingImage = true
            ctrl.labelChooseFile = ''
            $scope.fileSelected = el.files[0].name
            var reader  = new FileReader()
            // Encode file data in base64 to add it to the json
            reader.readAsDataURL(el.files[0])
            reader.onload = function() {
              // Remove headers from base64 code
              var matches = reader.result.split(',')
              ctrl.bankAccount.check_image = {
                image_data: matches[1],
                filename: el.files[0].name,
                filetype: el.files[0].type
              }
            }
          }
          else {
            // For testing purposes
            $scope.fileSelected = i18ng.t('BANK_ACCOUNT_NEW.LABELS.check_image_no_image')
          }
          $scope.$apply()
          ctrl.uploadingImage = false
          ctrl.labelChooseFile = i18ng.t('BANK_ACCOUNT_NEW.LABELS.check_image_change')
        }

        ctrl.submit = function() {
          var bankAccountParams = _.pick(ctrl.bankAccount, permittedParams)
          bankAccountParams.check_image = ctrl.bankAccount.check_image
          setWhile(ctrl, 'loading', BankAccount.createBankAccount(bankAccountParams))()
            .then(
              function() {
                $scope.$emit('bankAccounts:update')
                return ctrl.confirm()
              },
              function(response) {
                var responseError = ''
                if (response.data.error) {
                  responseError = response.data.error
                }
                else {
                  responseError = response.statusText
                }
                ctrl.serverErrors = responseError.messages || [responseError]
              }
            ).catch(showError)
        }

        ctrl.removeServerError = function() {
          ctrl.serverErrors = null
        }

      }
    }
  })
