angular.module('org-admin')

  .directive('newSmartGroup', function() {
    return {
      restrict: 'EA',
      scope: {},
      bindToController: {
        cancel: '=',
        confirm: '=',
        dialogOptions: '=',
        orgId: '<',
        filters: '<',
        columns: '<',
        currentSize: '<'
      },
      templateUrl: '/static/org/contacts/new-smart-group.html',
      controllerAs: 'ctrl',
      controller: function(_, SmartGroupPersona, i18ng) {

        var ctrl = this
        ctrl.failed = false
        ctrl.model = {
          name: ''
        }
        ctrl.saving = false
        ctrl.submit = submit
        ctrl.removeServerMessages = removeServerMessages

        /**
         * Creates the smart group and closes the dialog if the request is successful.
         */
        function submit() {
          ctrl.saving = true
          ctrl.failed = false
          ctrl.serverErrorMessages = null

          SmartGroupPersona.create({
            name: ctrl.model.name,
            org_id: ctrl.orgId,
            filters: ctrl.filters,
            display_columns: ctrl.columns
          })
            .then(createGroupSuccess)
            .catch(createGroupError)

          function createGroupSuccess(createdAccount) {
            return ctrl.confirm(createdAccount)
          }

          function createGroupError() {
            ctrl.saving = false
            ctrl.failed = true
            ctrl.serverErrorMessages = _.get(arguments[0], 'data.error.messages') || [i18ng.t('MANAGE_GROUP.ERRORS.create_failed')]
          }
        }

        /**
         * Clears server error messages from the dialog.
         */
        function removeServerMessages() {
          if (ctrl.serverErrorMessages) {
            ctrl.serverErrorMessages.length = 0
          }
        }
      }
    }

  })
