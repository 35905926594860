angular.module('org-admin')

  .service('SaleTransactions', function(DS, paginate, apiV2, $http, ENV) {

    var SaleTransactions = DS.defineResource({
      name: 'sale_transactions',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
      actions: {
        refunds: {
          method: 'GET',
          response: apiV2.deserialize
        },
        disputes: {
          method: 'GET',
          response: apiV2.deserialize
        }
      }
    })

    SaleTransactions.refund = function(saleTransactionId, data) {
      return $http({
        method: 'POST',
        url: ENV.urls.apiProxy + '/sale_transactions/' + saleTransactionId + '/refunds',
        data: data,
        headers: apiV2.headers,
        withCredentials: true,
      })
        .then(apiV2.deserialize)
    }

    return paginate(SaleTransactions)
  })
