angular.module('pl-shared')
  .directive('snTableOverlay', function($timeout) {
    return {
      scope: {
        show: '=snTableOverlay'
      },
      link: function($scope, $element, $attrs) {
        var overlay = angular.element('<div class="sn-table-overlay">')
        var promise
        $scope.$watch('show', function(show) {
          if (promise) $timeout.cancel(promise)
          // Delay in cases like clearing filters where layout shifts prior to the reload
          if (show) promise = $timeout(showOverlay, 0, true)
          else {
            overlay.remove()
            var stickyContainer = angular.element($element[0]).find('[sticky-container]')[0]
            if (stickyContainer) stickyContainer.scrollTop = 0
          }
        })

        function showOverlay() {
          overlay.height($element[0].scrollHeight).appendTo($element)
        }
      }
    }
  })
