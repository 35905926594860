angular.module('org-admin')
  .directive('payoutsReportList', function() {
    return {
      scope: { },
      templateUrl: '/static/org/reports/payouts-report-list.html',
      controllerAs: 'ctrl',
      controller: function(
        $filter,
        $q,
        $scope,
        BankAccount,
        currentOrg,
        debounceCallback,
        featureToggles,
        PayoutsReport,
        ReportsService,
        setAs,
        SePagination,
        showError,
        $window
      ) {

        var ctrl = this
        ctrl.isStripe = featureToggles.stripe_payment_processing
        ctrl.learnMoreUrl = 'https://help.sportsengine.com/en/articles/241'
        ctrl.params = { organization_id: currentOrg.id }
        ctrl.options = { }
        ctrl.loading = true
        ctrl.loadNginOrder = true
        ctrl.loadingAccounts = true
        ctrl.reload = true
        ctrl.sortColumns = { 'order[settlement_date]': 'desc' }
        ctrl.sePagination = new SePagination({
          loadDataFn: loadPayoutsForReport,
          storageId: 'payoutsList'
        })
        ctrl.payoutStatusTags = ReportsService.payoutStatusTags
        getLatestNginOrder()

        ctrl.stripNegative = function(currencyField, currencySymbol) {
          return currencyField >= 0 ? $filter('currency')(currencyField, currencySymbol) : '(' + $filter('currency')(currencyField * -1, currencySymbol) + ')'
        }

        ctrl.hasPayouts = function() {
          return ctrl.payouts && ctrl.payouts.length
        }

        ctrl.isRangeAll = function() {
          return !ctrl.payouts.loading && !ctrl.params.start_date && !ctrl.params.end_date
        }

        ctrl.applyDateFilter = function(dateParams) {
          ctrl.params = _.extend({}, ctrl.params, dateParams)
          ctrl.reload = true
        }

        ctrl.viewLegacyReports = function() {
          ReportsService.viewLegacyReports()
        }

        function replaceSortParam(newSort, oldSort) {
          _.each(oldSort, function(dir, col) {
            delete ctrl.params[col]
          })
          _.extend(ctrl.params, newSort)
          ctrl.reload = true
        }
        $scope.$watch('ctrl.sortColumns', replaceSortParam)

        var findAll = debounceCallback(BankAccount.findAllWithPagination)
        function loadPayoutAccounts(opts) {
          opts = opts || {}
          var requests = [
            findAll(currentOrg.id, { sort: 'nickname', sort_dir: 'asc' })
              .then(setAs(ctrl, 'bankAccounts'))
          ]
          return $q.all(requests)
            .catch(showError) // TODO: make this show an inline error
            .finally(setAs(ctrl, 'loadingAccounts', false))
        }
        $scope.$on('bankAccounts:update', updatePayoutAccounts)

        function updatePayoutAccounts() {
          loadPayoutAccounts({ clearCache: true })
        }

        function reloadPayouts() {
          if (!ctrl.reload) return
          loadPayoutsForReport()
          ctrl.reload = false
        }

        function loadPayoutsForReport(paginationParams) {
          _.set(ctrl, 'payouts.loading', true)

          var params = ctrl.sePagination.addPageParams(_.extend({}, ctrl.params, paginationParams))
          return PayoutsReport.findAll(params, ctrl.options)
            .then(setAs(ctrl, 'payouts'))
            .catch(setAs(ctrl, 'failure'))
            .finally(setAs(ctrl, 'payouts.loading', false))
            .finally(setAs(ctrl, 'loading', false))
        }

        function getLatestNginOrder() {
          ReportsService.getLatestNginOrder()
            .then(function(response) {
              ctrl.anyNginOrders = !!response.data.result
            })
            .catch(setAs(ctrl, 'failure'))
            .finally(setAs(ctrl, 'loadNginOrder', false))
        }

        $scope.$watch('ctrl.reload', reloadPayouts, true)
        loadPayoutAccounts()
      }
    }
  })
