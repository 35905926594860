angular.module('pl-shared')

  .directive('documentUploadQuestion', function() {

    return {
      scope: {},
      templateUrl: '/static/shared/components/registration/questions/document-upload-question.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '=',
        editMode: '=',
        organizationName: '='
      },
      controller: function(_, $scope, dialog, fileHelper, i18ng) {
        var ctrl = this

        ctrl.fileHelper = fileHelper
        ctrl.properties = ctrl.question.properties
        ctrl.inputName = 'form_result_' + ctrl.question.id

        $scope.$watch('ctrl.editMode', function(editMode) {
          ctrl.model = _.isObject(ctrl.answer) ? ctrl.answer.value : null
          var url = _.get(ctrl, 'model.filename') || _.get(ctrl, 'model.url') // use filename if it's there, it may not match the url
          _.defaults(ctrl.model, fileHelper.parseUrl(url, _.get(ctrl, 'model.crop_url'))) // preserve filename, url, etc.
        })

        ctrl.edit = function() {
          if (!ctrl.question.isEditable) return

          // This allows any customizations to the file upload modal to be passed in through the properties.
          _.defaults(ctrl.properties.fileUploadOptions || (ctrl.properties.fileUploadOptions = {}), {
            titleText: i18ng.t('ANSWER.DOCUMENT_UPLOAD.title', { question: ctrl.question.question }),
            uploadLinkText: i18ng.t('ANSWER.DOCUMENT_UPLOAD.upload_link')
          })

          dialog.confirm({
            directive: 'file-upload-modal',
            scope: $scope,
            attrs: {
              fileUploadOptions: ctrl.properties.fileUploadOptions,
              model: ctrl.model
            }
          })
            .then(updateModel)
        }

        ctrl.viewImage = function(event) {
          event.stopPropagation()
          dialog.confirm({
            directive: 'image-preview-modal',
            scope: $scope,
            attrs: {
              imgUrl: fileHelper.base64Data(ctrl.model) || ctrl.model.url,
              imgName: ctrl.model.filename
            }
          })
        }

        function updateModel(model) {
          ctrl.model = model
        }

      }
    }

  })
