angular.module('pl-shared')

  .service('billingProvisioningService', function($http, currentOrg, apiV2, ENV) {

    function fetch(orgId) {
      var url = ENV.urls.apiProxy + '/provisioning/billing/status/' + (orgId || currentOrg.id)
      return $http.get(url, {
        headers: { Accept: 'application/json' },
        withCredentials: true
      }).then(checkIfComplete)
    }

    function checkIfComplete(resp) {
      var provisioning = apiV2.deserialize(resp)
      var complete = provisioning.processor_settings.approved && provisioning.store.bank_account.approved
      provisioning.complete = complete
      provisioning.incomplete = !complete
      return provisioning
    }

    return {
      fetch: fetch
    }

  })

  .run(function(Provisioning, billingProvisioningService) {

    Provisioning.register('billing', billingProvisioningService.fetch)

  })
