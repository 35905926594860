angular.module('org-admin')

  .directive('programListingAddressQuestion', function() {

    return {
      scope: {},
      templateUrl: '/static/org/program-listings/questions/program-listing-address-question.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '='
      },
      controller: function(_, $scope, i18ng, GeographicOptions) {
        var ctrl = this

        ctrl.countries = []
        ctrl.subregions = []
        GeographicOptions.countries().then(function(response) {
          ctrl.countries = response
        })

        $scope.$watch('ctrl.model.country', function() {
          if (ctrl.model.country) {
            GeographicOptions.subregions({ params: { country: ctrl.model.country } }).then(function(response) {
              ctrl.subregions = response
            })
          }
          else {
            ctrl.subregions = []
            ctrl.model.state = ''
          }
        })

        ctrl.onSameAddressChange = function() {
          if (!ctrl.model.address_same_as_org) {
            // restore program listing fields
            for (var field in ctrl.originalModel) {
              if (field !== 'address_same_as_org') {
                ctrl.model[field] = ctrl.originalModel[field]
              }
            }
          }
        }

        ctrl.properties = ctrl.question.properties

        // NOTE: This property allows to set all fields on the directive as different fields on the data object
        //        Check rf-form-directive.js (submitForm method) for more information.
        ctrl.properties = _.extend(ctrl.properties, { flatten_field_data: true })

        ctrl.model = _.isObject(ctrl.answer) ? ctrl.answer.value : null
        ctrl.originalModel = _.clone(ctrl.model)
        ctrl.fields = {
          address_same_as_org: {
            id: 'address_same_as_org',
            label: i18ng.t('PROGRAM_LISTING_FORMS.LABELS.ADDRESS.address_same_as_org'),
          },
          address_1: {
            id: 'address',
            label: i18ng.t('PROGRAM_LISTING_FORMS.LABELS.ADDRESS.street'),
            auto_format: 'capitalize'
          },
          address_2: {
            id: 'address_2',
            label: i18ng.t('PROGRAM_LISTING_FORMS.LABELS.ADDRESS.street_2'),
            auto_format: 'capitalize'
          },
          country: {
            id: 'country',
            label: i18ng.t('PROGRAM_LISTING_FORMS.LABELS.ADDRESS.country')
          },
          state: {
            id: 'state',
            label: i18ng.t('PROGRAM_LISTING_FORMS.LABELS.ADDRESS.state')
          },
          city: {
            id: 'city',
            label: i18ng.t('PROGRAM_LISTING_FORMS.LABELS.ADDRESS.city'),
            default: 'USA'
          },
          postal_code: {
            id: 'postal_code',
            label: i18ng.t('PROGRAM_LISTING_FORMS.LABELS.ADDRESS.postal_code'),
            is_required: true
          }
        }

        // Set fields into question object, needed in rf-form-directive submitForm method
        ctrl.question.properties.fields = ctrl.fields

        ctrl.model.country = ctrl.model.country || ctrl.fields.country.default

        ctrl.countrySelectOptions = {
          placeholder: ctrl.fields.country.placeholder,
          allowClear: true
        }
        ctrl.stateSelectOptions = {
          placeholder: ctrl.fields.state.placeholder,
          allowClear: true
        }
      }
    }

  })
