angular.module('org-admin')
  .component('coachRoleSelect', {
    bindings: {
      seasonTeam: '<',
      personas: '<',
      hasStats: '<',
      cancel: '<',
      confirm: '<'
    },
    templateUrl: '/static/org/roster-management/coach-role-select.html',
    controller: function(_, CompositeRoles, currentOrg, setAs, setWhile) {

      var ctrl = this

      ctrl.details = {
        roster_player: {
          inactive_info: {
            date_deactivated: moment().format('MM/DD/YYYY')
          }
        }
      }

      ctrl.$onInit = setWhile(ctrl, 'loadingRoles', function() {
        ctrl.names = _.map(ctrl.personas, 'full_name')

        return CompositeRoles.findAll({
          boss_organization_id: currentOrg.id,
          applies_to: 'TeamInstance',
          enabled: 1,
          override_enabled: 1
        })
          .then(function(roles) {
            ctrl.roles = _.clone(roles)
            ctrl.roles.push({
              id: -1,
              key: 'other',
              name: 'General Staff'
            })
          })
      })

      ctrl.expand = function(roleId) {
        if (ctrl.expanded == roleId) ctrl.expanded = null
        else ctrl.expanded = roleId
      }
    }
  })
