angular.module('org-admin')
  .component('leagueLocationAvailableTimes', {
    bindings: {
      venue: '<',
      cancel: '<',
      confirm: '<'
    },
    templateUrl: '/static/org/league-locations/league-location-available-times.html',
    controller: function(_, $q, Alerts, currentOrg, setAs, setWhile, Venue, pageViewHandler) {

      var ctrl = this

      ctrl.$onInit = function() {
        if (anyAvailableTimes()) {
          ctrl.mode = 'edit'
        }
        else {
          ctrl.mode = 'add'
        }
      }

      ctrl.submit = function() {
        return ctrl.venue.DSSave({ params: { update_available_times: true } })
          .then(function() { pageViewHandler.fireEvent('Location.AddOpenHoursModal.Save', 8) })
          .then(ctrl.confirm)
          .catch(function(err) {
            Alerts.error(err.data['Invalid parameters'])
          })
      }

      ctrl.revertChanges = function() {
        ctrl.venue.DSRevert()
        ctrl.cancel()
      }

      function anyAvailableTimes() {
        _.find(ctrl.venue.available_times, { closed: false })
      }

    }
  })
