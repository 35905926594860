angular.module('org-admin')
  .component('programListingPreview', {
    templateUrl: '/static/org/program-listings/program-listing-preview.html',
    controller: function(currentOrg, ENV, $sce, $routeParams, $element, ProgramListing,
      setAs, showError, SNAP, $timeout) {

      var $ctrl = this

      $ctrl.loaded = false
      $ctrl.onLoadPreview = onLoadPreview
      $ctrl.logoUrl = SNAP.assetPath + '/images/sportsengine-mark-color-black.svg'

      ProgramListing.find($routeParams.programListingId)
        .then(setAs($ctrl, 'programListing'))
        .then(function() {
          var previewUrl = currentOrg.sportsengineUrl + '/program/' + $ctrl.program.slug + '?iframed=1'
          $ctrl.previewUrl = $sce.trustAsResourceUrl(previewUrl)
          $ctrl.programListingLoaded = true
        })
        .catch(showError)

      function onLoadPreview() {
        $ctrl.loaded = true
        $element.find('.pl-wizard').css('opacity', 1)
        focusStepTitle()
      }

      function focusStepTitle() {
        $timeout(function() {
          $element
            .find('.pl-wizard__title')
            .focus()
        }, 10, false)
      }

    }

  })
