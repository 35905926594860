angular.module('org-admin')
  .directive('membershipDefinitionEdit', function() {
    return {
      restrict: 'EA',
      scope: {},
      bindToController: {
        cancel: '=',
        confirm: '=',
        membershipDefinition: '=',
        dialogOptions: '='
      },
      templateUrl: '/static/org/memberships/membership-definition-edit.html',
      controllerAs: '$ctrl',
      controller: function(_, i18ng, appPermission, currentOrg, moment, Alerts, MembershipAssignmentTypeService, MembershipDefinition, ENV, SNAP) {
        var DEFAULT_TIMEZONE = 'America/New_York'
        var $ctrl = this
        $ctrl.$onInit = $onInit
        $ctrl.assignmentTypes = []
        $ctrl.submit = submit
        $ctrl.currentYear = (new Date()).getFullYear()
        $ctrl.yearOptions = _.range($ctrl.currentYear - 1, $ctrl.currentYear + 3)
        $ctrl.model = {
          can_affiliate_obo: _.get($ctrl.membershipDefinition, 'can_affiliate_obo'),
          description: _.get($ctrl.membershipDefinition, 'description'),
          membership_assignment_type: _.get($ctrl.membershipDefinition, 'membership_assignment_type'),
          name: _.get($ctrl.membershipDefinition, 'name'),
          price: _.get($ctrl.membershipDefinition, 'price'),
          price_cents: _.get($ctrl.membershipDefinition, 'price_cents'),
          number_current: _.get($ctrl.membershipDefinition, 'number_current')
        }
        $ctrl.canEditPrice = appPermission.powerPay.usable || ENV.current !== 'production'

        function $onInit() {
          $ctrl.assignmentTypes = MembershipAssignmentTypeService.labels($ctrl.membershipDefinition.membership_assignment_type)
        }

        function submit() {
          var data = _.clone($ctrl.membershipDefinition)
          data.membership_assignment_type = $ctrl.model.membership_assignment_type
          data.name = $ctrl.model.name
          data.price_cents = Math.round(parseFloat($ctrl.model.price || 0) * 100)
          data.description = $ctrl.model.description

          MembershipDefinition.update($ctrl.membershipDefinition.id, data)
            .then(editSuccess)
            .catch(editError)

          function editSuccess(result) {
            $ctrl.submitting = false
            Alerts.success('MEMBERSHIP_DEFINITION_FORMS.ALERTS.edit_success')
            $ctrl.confirm()
          }

          function editError() {
            $ctrl.submitting = false
            $ctrl.failed = true
            $ctrl.serverErrorMessages = _.get(arguments[0], 'data.error.messages') || [i18ng.t('MEMBERSHIP_DEFINITION_FORMS.ERRORS.edit_failed')]
          }
        }
      }
    }
  })
