angular.module('org-admin')
  .component('membershipDefinitionDetail', {
    templateUrl: '/static/org/memberships/membership-definition-detail.html',
    controller: function(_, $scope, $routeParams, Alerts,
      currentOrg, dialog, showError, renderContext, routeTitle,
      MembershipDefinition, EligibilityRuleSet, pageViewHandler
    ) {
      var $ctrl = this

      $ctrl.archive = archive
      $ctrl.destroyMembership = destroyMembership
      $ctrl.$onInit = $onInit
      $ctrl.share = share
      $ctrl.tagText = tagText

      $ctrl.membershipDefinition = null
      $ctrl.eligibility = null
      $ctrl.membershipUrl = MembershipDefinition.membershipUrl

      function $onInit() {
        var id = $routeParams.membershipDefinitionId
        if (id) {
          $ctrl.loaded = false
          MembershipDefinition.find(id, { params: { include_tags: true } })
            .then(loadSuccess)
            .catch(showError)
        }
        else $ctrl.loaded = true
      }

      function loadSuccess(memdef) {
        $ctrl.membershipDefinition = memdef
        pageViewHandler.addPageDepths({ depth3: memdef.purchase_status })
        routeTitle.setParams({ membership_name: $ctrl.membershipDefinition.name })
        loadEligibility()
        $ctrl.backContext = getBackContext(memdef)
      }

      function getBackContext(memdef) {
        if (memdef.archived_at) return 'app.billing.memberships.archived'
        if (memdef.purchase_status === 'unpublished') return 'app.billing.memberships.unpublished'
        return 'app.billing.memberships.published'
      }

      function loadEligibility() {
        EligibilityRuleSet.findAll({
          membership_definition_id: $ctrl.membershipDefinition.id,
          boss_organization_id: currentOrg.id,
          per_page: 100
        })
          .then(eligibilitySuccess)
          .catch(showError)
      }

      function eligibilitySuccess(result) {
        $ctrl.eligibility = result && result.length ? result[0] : null
        $ctrl.loaded = true
      }

      function share() {
        pageViewHandler.fireEvent('ShareClick', 8)
        dialog.confirm({
          component: 'membership-definition-share',
          scope: $scope,
          attrs: {
            memdef: $ctrl.membershipDefinition,
            url: $ctrl.membershipUrl + $ctrl.membershipDefinition.id
          }
        }).then(function() { pageViewHandler.fireEvent('ShareModal.Copy', 8) })
      }

      function archive() {
        dialog.confirm({
          directive: 'membership-definition-archive',
          scope: $scope,
          attrs: {
            membershipDefinition: $ctrl.membershipDefinition
          }
        }).then(archiveSuccess)
      }

      function destroyMembership() {
        dialog.confirm({
          directive: 'membership-definition-delete',
          scope: $scope,
          attrs: {
            membershipDefinition: $ctrl.membershipDefinition
          }
        }).then(destroySuccess)
      }

      function destroySuccess(data) {
        Alerts.success('MEMBERSHIP_DEFINITION_FORMS.ALERTS.delete_success')
        renderContext.goto('app.billing.memberships.archived')
      }

      function archiveSuccess(data) {
        $ctrl.membershipDefinition = MembershipDefinition.createInstance(data)
        Alerts.success('MEMBERSHIP_DEFINITION_FORMS.ALERTS.archive_success')
      }

      function tagText(memdef) {
        return memdef.isArchived ? 'MEMBERSHIP_DEFINITION_PURCHASE_STATUSES.archived' : 'MEMBERSHIP_DEFINITION_PURCHASE_STATUSES.' + memdef.purchase_status
      }
    }
  })
