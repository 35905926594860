angular.module('pl-shared')
  .factory('makeDroppable', function($timeout, $document) {

    var HTML_DRAG_CLASS = 'pl-drag-in-progress'
    var STOP_DRAG_DELAY = 100 // delay class removal since many enter/leave events fire during a drag
    var $html = angular.element('html')
    var html = $html[0]
    var doc = $document[0]
    var dragTimeout
    var globalDragSetup

    return makeDroppable

    function triggerCallback(e, callback) {
      if (!callback || typeof callback !== 'function') return
      var files
      if (e.dataTransfer) files = e.dataTransfer.files
      else if (e.target) files = e.target.files
      callback.call(null, files)
    }

    function makeDroppable(el, inputId, callback, acceptTypes) {

      var input = doc.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('multiple', true)
      if (inputId) input.setAttribute('id', inputId)
      if (acceptTypes) input.setAttribute('accept', acceptTypes)
      input.style.display = 'none'
      input.addEventListener('change', function(e) {
        triggerCallback(e, callback)
      })

      el.appendChild(input)
      el.addEventListener('dragover', overEl)
      el.addEventListener('dragleave', leaveEl)
      el.addEventListener('drop', dropEl)
      el.addEventListener('click', clickEl)

      // This updates a`pl-drag-in-progress` class on the element while dragging
      // over the window. It will also prevent your browser from blowing away our
      // app if you miss the dropzone with an image.
      bindDocumentDrag()
      return unbindDocumentDrag

      function overEl(e) {
        // docDragStart()
        // stopEvent(e)
        el.classList.add('dragover')
      }

      function leaveEl(e) {
        // docDragStopDelayed()
        // stopEvent(e)
        el.classList.remove('dragover')
      }

      function dropEl(e) {
        // docDragStop()
        // stopEvent(e)
        el.classList.remove('dragover')
        triggerCallback(e, callback)
      }

      function clickEl() {
        input.value = null
        input.click()
      }

      function bindDocumentDrag() {
        $document
          .on('dragstart dragenter dragover', docDragStart)
          .on('drop dragleave dragend', docDragStopDelayed)
      }

      function unbindDocumentDrag() {
        $document
          .off('dragstart dragenter dragover', docDragStart)
          .off('drop dragleave dragend', docDragStopDelayed)
      }

      function docDragStart(e) {
        stopEvent(e)
        cancelDragTimeout()
        $html.addClass(HTML_DRAG_CLASS)
      }

      function docDragStopDelayed(e) {
        stopEvent(e)
        cancelDragTimeout()
        dragTimeout = $timeout(docDragStop, STOP_DRAG_DELAY, false)
      }

      function docDragStop() {
        cancelDragTimeout()
        $html.removeClass(HTML_DRAG_CLASS)
      }

      function cancelDragTimeout() {
        $timeout.cancel(dragTimeout)
      }

      function stopEvent(e) {
        e.preventDefault()
        e.stopPropagation()
      }
    }
  })
