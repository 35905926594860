angular.module('org-admin')
  .component('leagueSeasonsList', {
    templateUrl: '/static/org/league-seasons/league-seasons-list.html',
    controller: function($routeParams, appPermission, currentOrg, dialog, Program, renderContext, setAs, setWhile, showError) {
      if (!appPermission.leagueSeasons.visible) return showError('404')

      var ctrl = this
      var PROGRAM_TYPE = 'League'
      ctrl.$routeParams = $routeParams

      ctrl.$onInit = function() {
        ctrl.loadLeagueSeasons()
      }

      ctrl.details = function(leagueSeason) {
        dialog.confirm({
          component: 'league-season-details',
          attrs: { leagueSeason: leagueSeason }
        })
          .then(ctrl.$onInit)
      }

      ctrl.loadLeagueSeasons = setWhile(ctrl, 'leagueSeasonsLoading', function() {
        return Program.findAll(
          {
            org_id: currentOrg.id,
            type: PROGRAM_TYPE
          },
          { load: 'inBackground' }
        )
          .then(setAs(ctrl, 'leagueSeasons'))
      })

    }
  })
