angular.module('org-admin')
  .factory('AuditableActions', function(DS, paginate) {
    var AuditableAction = DS.defineResource({
      name: 'auditable_actions',
      endpoint: '/v3/roster_personas/auditable_actions',
      basePath: DS.adapters.se_api.defaults.basePath, // workaround for js-data-angular fallbacks
      httpConfig: DS.adapters.se_api.defaults.httpConfig, // workaround for js-data-angular fallbacks
      defaultAdapter: 'se_api'
    })

    return paginate(AuditableAction)
  })
