angular.module('org-admin')
  .component('programListingNew', {
    templateUrl: '/static/org/program-listings/program-listing-new.html',
    controller: function(_, i18ng, ProgramListing, ProgramListingOptions, currentOrg, Alerts,
      renderContext, $q, $location, $scope, $element, $routeParams, $timeout,
      $window, SNAP, GeographicOptions, setAs, dialog) {

      var $ctrl = this
      var currentFormName
      var i
      var today = moment().format('YYYY-MM-DD')
      var tomorrow = moment().add(1, 'day').format('YYYY-MM-DD')
      var twoMonths = moment().add(2, 'months').format('YYYY-MM-DD')

      setStep(0)
      $timeout(function() {
        $scope.$watch(function() {
          return $routeParams.step || 0
        }, setStep)
      }, 10)

      $ctrl.loaded = false
      $ctrl.totalSteps = 6
      $ctrl.programListing = ProgramListing.createInstance({
        org_id: currentOrg.id,
        address_same_as_org: false,
        country: currentOrg.country || 'USA',
        end_date: tomorrow,
        genders: [],
        start_date: today,
        reg_open_date_time: today,
        reg_close_date_time: twoMonths
      })

      $ctrl.programListingTypeIds = ProgramListing.programListingTypeIds
      $ctrl.back = back
      $ctrl.nextIfValid = nextIfValid
      $ctrl.remindMe = remindMe
      $ctrl.selectType = selectType
      $ctrl.skipReg = skipReg
      $ctrl.validators = ProgramListing.validators
      $ctrl.urlPattern = /^(https?:\/\/)?[^ ."]+(\.[^ ."]+)+$/
      $ctrl.logoUrl = SNAP.assetPath + '/images/sportsengine-mark-color-black.svg'
      $ctrl.programListingOptions = {}
      $ctrl.maxPriceError = ProgramListing.maxPriceError
      $ctrl.pricingOptions = i18ng.t('PROGRAM_LISTING_OPTIONS.pricing', { returnObjectTrees: true })

      $ctrl.resetPrices = function() {
        $ctrl.programListing.fixed_price = null
        $ctrl.programListing.min_price = null
        $ctrl.programListing.max_price = null
      }

      $ctrl.stepDescriptions = [null]
      for (i = 1; i <= $ctrl.totalSteps; i++) {
        $ctrl.stepDescriptions[i] = i18ng.t('PROGRAM_LISTING_NEW.title_description_' + i)
      }

      loadData().then(loadSuccess)

      function loadData() {
        return $q.all([
          loadOptions(),
          loadStates()
        ])
      }

      function loadSuccess() {
        $ctrl.loaded = true
        focusStepTitle()
      }

      function loadOptions() {
        return ProgramListingOptions.getOptions()
          .then(function(data) {
            if (_.any(currentOrg.sports)) data.sports = currentOrg.sports
            $ctrl.programListingOptions = data
            if (data.sports.length === 1) $ctrl.programListing.sports = [data.sports[0].value]
          })
      }

      function loadStates() {
        return GeographicOptions.subregions({ params: { country: $ctrl.programListing.country } })
          .then(function(response) {
            $ctrl.subregions = response
          })
      }

      function selectType(id) {
        $ctrl.programListing.program_listing_type_id = id
        nextIfValid()
      }

      function focusStepTitle() {
        $timeout(function() {
          $element
            .find('.pl-wizard__title')
            .focus()
        }, 10, false)
      }

      function nextIfValid() {
        var lastStep = $ctrl.step === $ctrl.totalSteps
        var nextFn = lastStep ? create : next
        var currentForm = $ctrl[currentFormName]
        if (!currentForm) return next()
        else return currentForm.validateAndSubmit().then(nextFn)
      }

      function skipReg() {
        $ctrl.programListing.reg_type = null
        $ctrl.programListing.reg_open_date_time = null
        $ctrl.programListing.reg_close_date_time = null
        $ctrl.programListing.reg_url = null
        nextIfValid()
      }

      function setStep(step) {
        step = parseInt(step, 10)
        if (step < 0 || step > $ctrl.totalSteps) return // or throw or something?
        $ctrl.step = step
        $location.search('step', $ctrl.step || null)
        currentFormName = step ? 'step' + $ctrl.step + 'Form' : null
        focusStepTitle()
      }

      function next() {
        var f = $ctrl[currentFormName]
        setStep($ctrl.step + 1)
      }

      function back() {
        $window.history.back()
      }

      function create() {
        return ProgramListing.saveAndSyncLocation($ctrl.programListing)
          .then(createSuccess, createError)
      }

      function createSuccess(savedProgramListing) {
        renderContext.goto('blank.programListingPreview', { programListingId: savedProgramListing.id })
      }

      function createError() {
        Alerts.error('PROGRAM_LISTING_NEW.ERRORS.create_failed')
      }

      function remindMe() {
        dialog.confirm({
          component: 'program-listing-reminder',
          scope: $scope,
          attrs: {}
        })
          .then(remindMeSuccess)

        function remindMeSuccess() {
          renderContext.goto('app.billing.settings')
        }
      }

    }

  })
