angular.module('org-admin')

  .factory('Leagues', function(DS) {
    var Leagues = DS.defineResource({
      name: 'leagues',
      basePath: DS.adapters.ngin_v1.defaults.basePath,
      httpConfig: DS.adapters.ngin_v1.defaults.httpConfig,
      defaultAdapter: 'ngin_v1',
    })

    return Leagues
  })
