angular.module('pl-shared')
  .component('sportAdminDetail', {
    templateUrl: '/static/shared/components/sport-admin/sport-admin-detail.html',
    bindings: {
      backContext: '<',
      basePath: '@',
      programId: '<',
      handleMessageFallback: '&?',
    },
    controller: function($element, $location, $rootScope, $routeParams, $sce, $timeout, $window, currentOrg, ENV, renderContext) {
      var ctrl = this
      var EXTERNAL_ORIGIN = ENV.urls.hq
      var externalNavReady

      ctrl.$onInit = function() {
        $window.addEventListener('message', handleMessage)
        ctrl.baseRgx = new RegExp('^.*?' + ctrl.basePath + '(/\\w+)?|#.*$', 'g')
        setIframeUrl()
      }

      ctrl.$onDestroy = function() {
        $window.removeEventListener('message', handleMessage)
      }

      ctrl.$onChanges = function(changes) {
        if (!_.get(changes, 'programId.currentValue') ||
            changes.programId.currentValue == changes.programId.previousValue ||
            changes.programId.isFirstChange()) return // first change picked up by $onInit
        navigateIframe()
      }

      function getIframeUrl(setOrg, force) {
        var iframeUrl = ctrl.basePath
        if (ctrl.programId) iframeUrl += '/' + ctrl.programId
        if ($routeParams.route) iframeUrl += $routeParams.route
        if (setOrg) iframeUrl += (_.contains(iframeUrl, '?') ? '&' : '?') + 'set_current_org_id=' + currentOrg.id
        if (force) iframeUrl += (_.contains(iframeUrl, '?') ? '&' : '?') + _.uniqueId()
        return iframeUrl
      }
      function setIframeUrl(force) {
        ctrl.iframeUrl = $sce.trustAsResourceUrl(EXTERNAL_ORIGIN + getIframeUrl(true, force) + '#externalRoute')
      }
      function postMessage(eventType, data) {
        var event = _.extend({ type: eventType }, data)
        $element.find('iframe')[0].contentWindow.postMessage(event, EXTERNAL_ORIGIN)
      }

      function navigateIframe() {
        $timeout.cancel(navTimeout)
        if (!externalNavReady) return navTimeout = $timeout(navigateIframe, 100, false)

        $timeout.cancel(forceNavTimeout)
        forceNavTimeout = $timeout(forceNav, 200)
        postMessage('navigate', { url: getIframeUrl() })
      }
      // If different content is loaded in the iframe (e.g. Payments) and is not responding to postMessage,
      // then forcibly set the URL of the iframe in order to reload the contents.
      function forceNav() {
        externalNavReady = false
        setIframeUrl(true) // new $$unwrapTrustedValue result could be same as old, so cycle the URL to ensure reload
      }
      var navTimeout, forceNavTimeout

      function handleMessage(event) {
        if (event.origin !== EXTERNAL_ORIGIN) return

        function getNewRoute(url) { return (url || '').replace(ctrl.baseRgx, '') }
        var currentRoute = $location.search().route || ''

        switch (_.get(event, 'data.type')) {
          case 'exitDetail':
            renderContext.backto(ctrl.backContext)
            break
          case 'routeChanged':
            var newRoute = getNewRoute(event.data.url)
            if (newRoute === currentRoute) return
            $rootScope.safeApply(function() {
              // The history entry will be generated by the iframe, so silently update the URL here
              $location.replace().search('route', newRoute || undefined)
            })
            break
          case 'navigationReady':
            externalNavReady = true
            if (currentRoute !== getNewRoute(event.data.currentUrl)) navigateIframe()
            break
          case 'navigationReceived':
            $timeout.cancel(forceNavTimeout)
            break
          default:
            if (ctrl.handleMessageFallback) ctrl.handleMessageFallback({ eventData: _.get(event, 'data') })
            break
        }
      }
    }
  })
