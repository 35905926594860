angular.module('org-admin')

  .directive('paymentPlanCard', function() {

    return {
      scope: {},
      bindToController: {
        sale: '<',
        loadPayments: '&',
        payments: '<',
      },
      templateUrl: '/static/org/payment-plans/payment-plan-card.html',
      controllerAs: 'ctrl',
      controller: function($scope, dialog, i18ng, launchDarklyFlags, Alerts, $filter) {

        var ctrl = this

        ctrl.actionLinks = []

        ctrl.$onInit = function() {
          ctrl.enableCancelInstallmentsFlag = launchDarklyFlags.creditReasons

          if (!ctrl.hideEditPaymentPlan()) {
            ctrl.actionLinks.push({
              text: 'Edit Payment Plan',
              action: function() { ctrl.editPaymentPlan() }
            })
          }
          if (getCancellableInstallments().length > 0) {
            ctrl.actionLinks.push({
              text: 'Cancel Installments',
              action: function() { ctrl.cancelInstallments() }
            })
          }
        }

        ctrl.cancelInstallments = function() {
          dialog.confirm({
            directive: 'cancel-installments',
            scope: $scope,
            attrs: {
              sale: ctrl.sale,
              persistedPayments: ctrl.payments
            }
          }).then(function(credits) {
            ctrl.loadPayments()
            Alerts.success(i18ng.t('INVOICE_APPLY_CREDIT.success', {
              credit_amount: $filter('currency')(credits.reduce((partialSum, credit) => {
                return partialSum + parseFloat(credit.amount)
              }, 0))
            }))
            $scope.$emit('invoice:update')
            $scope.$emit('order:update')
          })
        }

        ctrl.getSortedPayments = function() {
          return _.sortByOrder(ctrl.sale.payments, ['is_paid', 'due_date'], ['desc', 'asc'])
        }

        ctrl.editPaymentPlan = function() {
          dialog.confirm({
            directive: 'payment-plan-edit',
            scope: $scope,
            attrs: {
              sale: ctrl.sale,
              persistedPayments: ctrl.payments
            }
          }).then(function() {
            ctrl.loadPayments()
          })
        }

        ctrl.hideEditPaymentPlan = function() {
          return _.every(ctrl.sale.payments, function(payment) {
            return payment.is_paid || payment.status == 'canceled'
          })
        }

        ctrl.getPaymentPlanDescription = function() {
          return (ctrl.sale.payment_term ? i18ng.t('PAYMENT_PLAN_CARD.description') : null)
        }

        function isLockedPaymentStatus(payment) {
          return payment.status === 'canceled' || payment.status === 'disputed' || payment.status === 'chargeback'
        }

        function getCancellableInstallments() {
          return _.filter(ctrl.sale.payments, function(payment) {
            if (isLockedPaymentStatus(payment)) return false
            return !payment.is_paid && !payment.upfront_payment
          })
        }
      }
    }
  })
