angular.module('org-admin')
  .directive('bankAccountEditOther', function() {
    return {
      scope: {},
      bindToController: {
        accountId: '=',
        cancel: '=',
        confirm: '=',
        dialogOptions: '='
      },
      templateUrl: '/static/org/bank-account-settings/Other/edit-account-modal.html',
      controllerAs: 'ctrl',
      controller: function(BankAccount, currentOrg, getEnum, setWhile, showError, i18ng, $rootScope, $scope, ENV, Alerts) {
        var permittedParams, ctrl = this

        ctrl.countries = ['US', 'CA']
        ctrl.accountTypes = ['checking', 'savings']
        ctrl.failed = false
        ctrl.labelChooseFile = i18ng.t('BANK_ACCOUNT_NEW.LABELS.check_image_upload')
        ctrl.checkImageChanged = false
        ctrl.supportLink = 'https://help.sportsengine.com/customer/portal/emails/new'
        ctrl.uploadingImage = false
        ctrl.clareAccountingUrl = ENV.urls.clareAccounting
        ctrl.checkImageUrl = ''
        ctrl.savingBankAccount = false
        $scope.fileSelected = ''
        $scope.checkImageRequired = false

        setWhile(ctrl, 'loading', BankAccount.getAccount(ctrl.accountId, true))()
          .then(
            function(response) {
              ctrl.bankAccount = response
              if (ctrl.bankAccount.status == 'submitted') {
                ctrl.labelChooseFile = i18ng.t('BANK_ACCOUNT_NEW.LABELS.check_image_change')
                ctrl.bankAccount.routing_number_confirmation = ctrl.bankAccount.routing_number
                ctrl.checkImageUrl = ctrl.clareAccountingUrl + '/bank_accounts/' + ctrl.bankAccount.id + '/check_image' + '?' + new Date().getTime()
              }
              else if (ctrl.bankAccount.status == 'rejected') {
                $scope.checkImageRequired = true
                ctrl.bankAccount.routing_number = ''
              }
            },
            function(response) {
              var responseError = response.data.error
              ctrl.serverErrors = responseError.messages || [responseError]
            })

        ctrl.shouldShowCheckImage = function() {
          return (ctrl.bankAccount.status === 'submitted') && !ctrl.checkImageChanged
        }

        // Bind click to hidden file upload input
        ctrl.selectCheckImage = function() {
          angular.element('#checkImageUpload').trigger('click')
        }

        // Get Check Image file information when upload file input change
        $scope.uploadCheckImage = function(el) {
          if (el.files) {
            ctrl.uploadingImage = true
            ctrl.labelChooseFile = ''
            $scope.fileSelected = el.files[0].name
            ctrl.checkImageChanged = true
            var reader  = new FileReader()
            // Encode file data in base64 to add it to the json
            reader.readAsDataURL(el.files[0])
            reader.onload = function() {
              // Remove headers from base64 code
              var matches = reader.result.split(',')
              ctrl.bankAccount.check_image = {
                image_data: matches[1],
                filename: el.files[0].name,
                filetype: el.files[0].type
              }
            }
          }
          else {
            // For testing purposes
            $scope.fileSelected = i18ng.t('BANK_ACCOUNT_NEW.LABELS.check_image_no_image')
          }
          $scope.$apply()
          ctrl.uploadingImage = false
          ctrl.labelChooseFile = i18ng.t('BANK_ACCOUNT_NEW.LABELS.check_image_change')
        }

        ctrl.submit = function() {
          if (ctrl.bankAccount.status === 'rejected') {
            ctrl.bankAccount.status = 'submitted'
          }
          ctrl.savingBankAccount = true
          setWhile(ctrl, 'loading', BankAccount.editAccount(ctrl.accountId, ctrl.bankAccount))()
            .then(
              function() {
                ctrl.savingBankAccount = false
                $scope.$emit('bankAccounts:update')
                Alerts.success('BANK_ACCOUNT_EDIT.success')
                return ctrl.confirm()
              },
              function(response) {
                var responseError = response.data.error
                ctrl.serverErrors = responseError.messages || [responseError]
              }
            ).catch(showError)
        }

        ctrl.removeServerError = function() {
          ctrl.serverErrors = null
        }

        ctrl.hasRejectReason = function(rejectReason) {
          return rejectReason ? true : false
        }
      }
    }
  })
