angular.module('org-admin')
  .component('membershipDefinitionList', {
    templateUrl: '/static/org/memberships/membership-definition-list.html',
    controllerAs: '$ctrl',
    bindings: {
      add: '=',
      status: '@',
      linkableMemDefs: '<'
    },
    controller: function($q, $scope, dialog, _, i18ng, setAs, currentOrg, filterPanelService, $window,
      MembershipStatistics, MembershipDefinition, MembershipsUtilService, Item, ItemPrice,
      EligibilityRuleSet, EligibilitySearch, debounceCallback, appPermission, Alerts, renderContext, pageViewHandler, $filter) {
      var $ctrl = this
      var VIEW_ELIGIBILITY = i18ng.t('MEMBERSHIPS_LIST.view_eligibility')
      var VIEW_ELIGIBILITY_ACTION = 'ViewAllEligibilityTextLink.Click'
      var VIEW_MEMBERS = i18ng.t('MEMBERSHIPS_LIST.view_members')
      var VIEW_MEMBERS_ACTION = 'ViewAllMembersTextLink.Click'
      var RENEW = i18ng.t('MEMBERSHIPS_LIST.renew')
      var RENEW_ACTION = 'RenewTextLink.Click'
      var PREVIEW = i18ng.t('MEMBERSHIPS_LIST.preview')
      var PREVIEW_ACTION = 'PreviewTextLink.Click'
      var cancelPrev

      $ctrl.memberDefinitionListInitialized = false
      $ctrl.membershipUrl = MembershipDefinition.membershipUrl
      $ctrl.share = share
      $ctrl.renew = renew
      $ctrl.selectedMembershipFilter = 'published'
      $ctrl.selectedMembershipFilterText = i18ng.t('MEMBERSHIPS_LIST.MEMBERSHIP_DEFINITION_FILTER.' + $ctrl.selectedMembershipFilter)
      $ctrl.memdefTitleAttributes = {}
      $ctrl.memdefFilterParams = {}
      $ctrl.membershipDefinitions = []
      $ctrl.memdefDescriptions = {}
      $ctrl.memdefTags = {}
      $ctrl.memdefActionLinks = {}
      $ctrl.params = { boss_organization_id: currentOrg.id }
      $ctrl.getMemDefs = debounceCallback(MembershipDefinition.findAll)
      $ctrl.membershipCountLoaded = false
      $ctrl.totalCollectedLoaded = false
      $ctrl.financialAccess = appPermission.transactions.usable
      $ctrl.viewRecent = MembershipsUtilService.viewRecent
      $ctrl.viewEligibility = viewEligibility
      $ctrl.membershipsListActions = []
      $ctrl.shareMemberships = shareMemberships

      $ctrl.$onChanges = function(changes) {
        $ctrl.loaded = false
        $ctrl.membershipsListTitle = i18ng.t('MEMBERSHIPS_LIST.memberships_list_title_' + $ctrl.status)
        var linkableMemDefsOnlyChange = Object.keys(changes).length === 1 && changes.linkableMemDefs
        if ($ctrl.status === 'membershipDefinition') return
        if (!$ctrl.linkableMemDefs) return
        if (linkableMemDefsOnlyChange && $ctrl.memberDefinitionListInitialized) {
          if ($ctrl.memberDefinitionListInitialized) buildMemDefActions()
          $ctrl.loaded = true
          return
        }

        $ctrl.membershipsListActions = []
        if (cancelPrev && $ctrl.memberDefinitionListInitialized) cancelPrev.resolve()
        cancelPrev = $q.defer()

        // @todo consider sorting these on start_date or something
        // Passing the cancelPrev.promise here will force js-data to cancel the in-flight request between screens. Without, js-data will think that it's making the same request, and return the wrong data
        $ctrl.getMemDefs({
          per_page: 25,
          boss_organization_id: currentOrg.id,
          include_tags: true,
          status: $ctrl.status
        }, {
          load: 'all',
          timeout: cancelPrev.promise,
          endpoint: $ctrl.status === 'archived' ? 'membership_definitions/archived' : 'membership_definitions'
        })
          .then(membershipDefinitionSuccess)
          .then(getResources)
          .then(getPrices)
          .then(buildMemDefActions)
          .then(buildMembershipListActions)
          .then(pageViewHandler.addPageDepths({ depth3: _.capitalize($ctrl.status) + 'Memberships' }))
          .finally(setAs($ctrl, 'memberDefinitionListInitialized', true))
      }

      function getResources() {
        return $q.all([getEligibility()])
      }

      function filterMembershipDefinitions() {
        if ($ctrl.status === 'archived')  {
          $ctrl.filteredMembershipDefinitions = $ctrl.membershipDefinitions
        }
        else if ($ctrl.status === 'published') {
          $ctrl.filteredMembershipDefinitions = _.filter($ctrl.membershipDefinitions, function(memdef) {
            return memdef.purchase_status === 'published'
          })
        }
        else {
          $ctrl.filteredMembershipDefinitions = _.filter($ctrl.membershipDefinitions, function(memdef) {
            return memdef.purchase_status !== 'published'
          })
        }
      }

      function getEligibility() {
        var memdefIdsChunked = _.chunk(_.map($ctrl.filteredMembershipDefinitions, 'id'), 25)
        return $q.all(_.map(memdefIdsChunked, function(memdefIds) {
          return EligibilityRuleSet.findAll({
            membership_definition_id: memdefIds.join(','),
            boss_organization_id: currentOrg.id,
            per_page: 25
          })
        }))
          .then(function(eligibilities) {
            return _.flatten(eligibilities)
          })
          .then(processEligibility)
          .catch(function() {
            // @todo handle this error
            $ctrl.ruleSets = []
            processAggs({})
          })
      }

      function findRuleSetByMemdefId(memdefId) {
        return _.find($ctrl.ruleSets, {
          eligibility_rules: [{
            originator_type: 'membership_definition',
            originator_id: memdefId
          }]
        })
      }

      function processEligibility(ruleSets) {
        $ctrl.ruleSets = ruleSets
        var ruleSetIds = _.map(ruleSets, 'id')
        return EligibilitySearch
          .aggregate(null, { data: { eligibility_rule_set_ids: ruleSetIds } })
          .then(processAggs)
      }

      function processAggs(aggs) {
        var memdefIds = _.map($ctrl.filteredMembershipDefinitions, 'id')
        $ctrl.ruleSetsByMemdef = {}
        _.forEach(memdefIds, function(memdefId) {
          var ruleSet = findRuleSetByMemdefId(memdefId)
          if (ruleSet && aggs[ruleSet.id]) {
            ruleSet = EligibilityRuleSet.addAggregations(ruleSet, aggs)
            ruleSet = EligibilityRuleSet.calculateEligibilityVisibility(ruleSet)
          }
          else ruleSet = null
          $ctrl.ruleSetsByMemdef[memdefId] = ruleSet
        })

        return $q.resolve()
      }

      function viewEligibility(memdef, ineligible) {
        var ruleSet = $ctrl.ruleSetsByMemdef[memdef.id]
        if (!ruleSet || !ruleSet.id) return
        var params = { eligibilityRuleSetId: ruleSet.id, status: 'active' }
        if (ineligible) params.ineligible = true
        renderContext.goto('app.billing.eligibility', params)
      }

      function getPrices() {
        if (!appPermission.memberships.financial) {
          $ctrl.loaded = true
          return
        }
        $ctrl.membershipDefinitions.forEach(function(md) {
          if (md.clare_item_uuid) {
            ItemPrice.find(md.clare_item_uuid).then(function(data) {
              var matches = _.filter(data.variations, function(variation) { return variation.id === data.default_variation_id })
              if (matches.length) {
                md.price_cents = matches[0].price_cents
                md.price = parseInt(md.price_cents || 0.00, 10) / 100
              }
            })
          }
        })
        $ctrl.loaded = true
      }

      function membershipDefinitionSuccess(data) {
        setAs($ctrl, 'membershipDefinitions')(data)
        filterMembershipDefinitions()
        $ctrl.showClubAssignmentCard = _.some($ctrl.filteredMembershipDefinitions, 'can_invite')
        _.forEach(data, function(memdef) {
          $ctrl.memdefTags[memdef.id] = []
          var validityLabel = MembershipsUtilService.memdefValidityLabel(memdef)
          $ctrl.memdefDescriptions[memdef.id] = []
          if (validityLabel) $ctrl.memdefDescriptions[memdef.id].push(validityLabel)
          $ctrl.memdefDescriptions[memdef.id].push(MembershipsUtilService.memdefTotalMembers(memdef.membership_count))
          $ctrl.memdefTitleAttributes[memdef.id] = { href: '/org/' + currentOrg.id + '/memberships/' + memdef.id + '/info' }
          $ctrl.memdefFilterParams[memdef.id] = buildFilterParams(memdef.id)
          if (memdef.isExpiring) {
            $ctrl.memdefTags[memdef.id].push({
              color: 'orange',
              value: i18ng.t('MEMBERSHIPS_LIST.expiring_soon')
            })
          }
          if (memdef.membership_definitions_credential_definitions.length && !memdef.can_affiliate_obo) {
            $ctrl.memdefTags[memdef.id].push({
              color: 'yellow',
              value: i18ng.t('CLUB_ASSIGNMENT_LOCK.club_assignment_locked')
            })
          }
          memdef.tagList = _.sortByAll(memdef.tags, ['tag_option_type', 'name']).map(function(tag) { return tag.name }).join(' • ')
        })
      }

      function buildMemDefActions() {
        _.forEach($ctrl.membershipDefinitions, function(memdef) {
          $ctrl.memdefActionLinks[memdef.id] = buildActionLinks(memdef)
        })
      }

      function buildActionLinks(memdef) {
        var links = []
        var ruleSet = $ctrl.ruleSetsByMemdef[memdef.id]

        if (ruleSet && ruleSet.shouldShowEligibility && appPermission.memberships.viewEligibility) {
          links.push({
            primaryAction: true,
            text: VIEW_ELIGIBILITY,
            action: memdefAction.bind(null, VIEW_ELIGIBILITY_ACTION, function() {
              $ctrl.viewEligibility(memdef, false)
            })
          })
        }

        if (memdef.membership_count > 0) {
          links.push({
            text: VIEW_MEMBERS,
            action: memdefAction.bind(null, VIEW_MEMBERS_ACTION, function() {
              $ctrl.viewRecent(memdef, $ctrl.memdefFilterParams[memdef.id])
            })
          })
        }

        if (memdef.isExpiring && !memdef.hasChildMembership) {
          links.push({
            text: RENEW,
            action: memdefAction.bind(null, RENEW_ACTION, function() {
              $ctrl.renew(memdef)
            })
          })
        }

        if (!memdef.isArchived && memdef.can_direct) {
          links.push({
            text: PREVIEW,
            action: memdefAction.bind(null, PREVIEW_ACTION, function() {
              $window.open($ctrl.membershipUrl + memdef.id)
            })
          })
        }

        return links
      }

      function memdefAction(passedAction, action) {
        pageViewHandler.fireEvent(passedAction, 8)
        action()
      }

      function buildMembershipListActions() {
        var directMemdefWithTag = $ctrl.filteredMembershipDefinitions.find(function(memdef) {
          return memdef.tags && memdef.tags.length && memdef.can_direct
        })
        if (directMemdefWithTag && $ctrl.status === 'published') {
          $ctrl.membershipsListActions = [{
            action: $ctrl.shareMemberships,
            primaryAction: true,
            text: i18ng.t('MEMBERSHIPS_LIST.share_memberships')
          }]
        }
        else $ctrl.membershipsListActions = []
      }

      function buildFilterParams(memdefId) {
        return filterPanelService.serializeFiltersAsParam([{
          source: 'memberships',
          field: 'membership_definition_id',
          logic: 'equal',
          value: memdefId
        }])
      }

      function share(memdef) {
        dialog.confirm({
          component: 'membership-definition-share',
          scope: $scope,
          attrs: {
            memdef: memdef,
            url: $ctrl.membershipUrl + memdef.id
          }
        })
      }

      function renew(memdef) {
        dialog.confirm({
          component: 'membership-definition-renew',
          scope: $scope,
          attrs: {
            membershipDefinition: memdef,
            linkableMemDefs: $ctrl.linkableMemDefs
          }
        })
          .then(renewalSuccess)
      }

      /**
       *
       * @param {<membershipDefinition>} memdef
       */
      function renewalSuccess(renewalMemDef) {
        gotoNewMemdefContext(renewalMemDef)
        Alerts.success('MEMBERSHIP_DEFINITION_FORMS.ALERTS.create_success')
      }

      function gotoNewMemdefContext(memdef) {
        renderContext.goto('app.billing.memberships.membershipDefinition.info', { membershipDefinitionId: memdef.id })
      }

      function publishedMenuItem(optionKey) {
        return {
          text: i18ng.t('MEMBERSHIPS_LIST.MEMBERSHIP_DEFINITION_FILTER.' + optionKey),
          action: function() {
            $ctrl.selectedMembershipFilter = optionKey
            $ctrl.selectedMembershipFilterText =  i18ng.t('MEMBERSHIPS_LIST.MEMBERSHIP_DEFINITION_FILTER.' + optionKey)
            filterMembershipDefinitions()
          }
        }
      }

      function shareMemberships() {
        pageViewHandler.fireEvent('ShareMembershipsClick', 8)
        dialog.confirm({
          component: 'membership-definition-share',
          scope: $scope,
          attrs: {
            url: $ctrl.membershipUrl.replace('buy', 'org') + currentOrg.slug
          }
        }).then(function() { pageViewHandler.fireEvent('ShareMembershipsModal.Copy', 8) })
      }
    }
  })
