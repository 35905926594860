angular.module('org-admin')
  .service('SiteProvisioning', function($http, apiV2, currentOrg, ENV) {
    var apiProxyUrl = ENV.urls.apiProxy

    return {
      provisionSite: provisionSite,
      getStatus: getStatus,
      getTemplates: getTemplates
    }

    function provisionSite(template_id, timezone) {
      return $http({
        method: 'POST',
        url: apiProxyUrl + '/site_provisioning/provision',
        data: {
          org_id: currentOrg.id,
          template_id: template_id,
          timezone: timezone
        },
        headers: apiV2.headers,
        withCredentials: true
      })
    }

    function extractMessageData(response) {
      return (response.data || []).message || []
    }

    function getStatus() {
      return $http({
        method: 'GET',
        url: apiProxyUrl + '/site_provisioning/status',
        params: {
          'org_id': currentOrg.id
        },
        headers: apiV2.headers,
        withCredentials: true
      }).then(extractMessageData)
    }

    function getTemplates() {
      return $http({
        method: 'GET',
        url: apiProxyUrl + '/site_provisioning/templates',
        headers: apiV2.headers,
        withCredentials: true
      }).then(extractMessageData)
    }
  })
