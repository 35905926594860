angular.module('org-admin')

  .service('ProgramListing', function(_, $q, DS, paginate, apiV2, moment, currentOrg, Geocoder, i18ng, $filter, ENV) {
    var ProgramListing = DS.defineResource({
      name: 'program_listings',
      basePath: DS.adapters.se_api.defaults.basePath,
      defaultAdapter: 'se_api',
      actions: {
        archive: {
          method: 'DELETE'
        },
        unarchive: {
          method: 'POST'
        }
      },
      methods: {
        archive: function() {
          return ProgramListing.archive(this.id)
            .then(apiV2.deserialize)
            .then(ProgramListing.createInstance)
        }
      },
      computed: {
        publishedStatus: ['deleted_at', 'searchable', function(deletedAt, searchable) {
          if (deletedAt) return 'archived'
          if (searchable) return 'active'
          return 'draft'
        }],
        sportsengineUrl: [function() {
          // This requires the org slug, which we do not have in the list views, so
          // we can only generate this correctly when the current org matches this org.
          if (!currentOrg || currentOrg.id !== this.org_id) return ''
          return currentOrg.sportsengineUrl + '/program/' + this.slug
        }]
      },
      serialize: serialize
    })

    // Use findAll for consistent load tracking and api
    ProgramListing.archived = function(params, options) {
      options = _.extend({ endpoint: 'program_listings/archived' }, options)
      return ProgramListing.findAll(params, options)
    }

    ProgramListing.copy = function(original) {
      var copy = angular.copy(original)
      delete copy.id
      copy.name = i18ng.t('PROGRAM_LISTING_COPY.copy_of_program_listing', { program_name: original.name })
      copy.deleted_at = null
      copy.searchable = false
      copy.sports = _.isArray(original.sports) ? _.map(original.sports, function(sport) {
        return _.isObject(sport) ? sport.value : sport
      }) : []

      if (!copy.reg_type) delete copy.reg_type
      if (!original.org_id) copy.org_id = currentOrg.id

      return ProgramListing.saveAndSyncLocation(copy)
    }

    var MAX_PROGRAM_LISTING_PRICE = 99999.99
    ProgramListing.maxPriceError = i18ng.t('PROGRAM_LISTING_ERRORS.price_max', { price: $filter('currency')(MAX_PROGRAM_LISTING_PRICE) })

    function priceRange(values) {
      var min = getValue(values, 'form_result_price_range_range_min', 'min_price')
      var max = getValue(values, 'form_result_price_range_range_max', 'max_price')
      if (max > MAX_PROGRAM_LISTING_PRICE) return false // used to indicate range erros (as opposed to null)
      var diff = max - min
      return isNaN(diff) ? null : {
        min: min,
        max: max,
        diff: diff
      }
    }

    // getValue(values, 'prop1', 'prop2')
    function getValue(values) {
      var props = Array.prototype.slice.call(arguments, 1)
      while (props.length) {
        var prop = props.shift()
        if (prop in values) return values[prop]
      }
    }

    ProgramListing.validators = {

      program_listing_age_range: function(values) {
        var min = getValue(values, 'form_result_age_range_min_age')
        var max = getValue(values, 'form_result_age_range_max_age')
        return (min && max) ? parseInt(min, 10) <= parseInt(max, 10) : true
      },

      program_listing_date_range: function(values) {
        var start = getValue(values, 'start_date')
        var end = getValue(values, 'end_date')
        return (start && end) ? (new Date(values.end_date)) >= (new Date(values.start_date)) : true
      },

      fixed_price_free: function(values) {
        var fixed = getValue(values, 'form_result_price_range_fixed_price', 'fixed_price')
        return fixed !== 0
      },

      price_max: function(values) {
        for (var k in values) {
          if (values[k] > MAX_PROGRAM_LISTING_PRICE) return false
        }
        return true
      },

      price_range: function(values) {
        var range = priceRange(values)
        return !range || range.diff >= 0
      },

      price_range_free: function(values) {
        var range = priceRange(values)
        return !range || !!range.diff || !!range.max // no diff but max value is fixed
      },

      price_range_fixed: function(values) {
        var range = priceRange(values)
        return !range || !!range.diff || !range.max // no diff and no max value is free
      },

      registration_date_time: function(values) {
        var open = getValue(values, 'reg_open_date_time')
        var close = getValue(values, 'reg_close_date_time')
        return (open && close) ? open <= close : true
      }
    }

    paginate(ProgramListing)
    Geocoder.bindToResource(ProgramListing, { beforeSave: syncTimezoneAndLocationData })

    // TODO: replace this with a better data source once the
    // available options have been synced with the schema.
    ProgramListing.programListingTypeIds = [2, 3, 4]

    return ProgramListing

    function syncTimezoneAndLocationData(newData, originalData) {
      // Strip any address fields if we are going to default to the org address
      var addressSameAsOrg = 'address_same_as_org' in newData ? newData.address_same_as_org : originalData.address_same_as_org
      if (addressSameAsOrg) {
        _.each(Geocoder.addressFields, function(field) {
          delete newData[field]
        })
      }
      // sync datetimes + timezone
      // geolocate if address changed
      var tz = newData.timezone || originalData.timezone || currentOrg.timezone
      var regOpen = newData.reg_open_date_time
      var regClose = newData.reg_close_date_time
      if (regOpen) newData.reg_open_date_time = moment.tz(regOpen, tz).format()
      if (regClose) newData.reg_close_date_time = moment.tz(regClose, tz).format()
      // @todo save reg_timezone here also?
    }

    function serialize(res, data) {
      // This old program_type property is not needed and causes issues on save
      delete data.program_type

      if ('program_listing_type' in data) {
        // The program_listing_type property is sometimes as an object, but must be saved as an id only
        var plt = data.program_listing_type
        data.program_listing_type = plt && plt.value || plt || null
      }

      return data
    }

  })
