angular.module('org-admin')

  .service('DiscountCodes', function(DS, paginate) {
    var discountCodes = DS.defineResource({
      name: 'discountCodes',
      endpoint: 'discount/codes',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
    })

    return paginate(discountCodes)
  })
