angular.module('org-admin')
  .component('discountsList', {
    templateUrl: '/static/org/discounts/discounts-list.component.html',
    controllerAs: 'ctrl',
    controller: function(
      $location,
      $scope,
      currentOrg,
      debounceCallback,
      dialog,
      DiscountCodeDefinitions,
      featureToggles,
      Search,
      PusherService,
      setAs
    ) {

      redirectToNewDiscounts()

      var ctrl = this
      ctrl.discountCodeDefinitions = []
      ctrl.params = { organization_id: currentOrg.id }
      ctrl.options = { result: ctrl.discountCodeDefinitions, pagination: {} }
      ctrl.sortColumns = { 'order[name]': 'asc' }
      ctrl.loading = true
      ctrl.searchDirtied = false
      ctrl.tagColor = DiscountCodeDefinitions.tagColor

      pusherSubscribe()

      ctrl.addDiscountCodeDefinition = function() {
        dialog.confirm({
          component: 'discount-modal',
          scope: $scope,
          attrs: {
            currencySymbol: ctrl.discountCodeDefinitions.length ? ctrl.discountCodeDefinitions[0].currency_symbol : '$'
          }
        })
      }

      ctrl.search = Search.create({
        update: function(searchTerm) {
          ctrl.searchDirtied = true
          ctrl.params.search_fields = 'discount_code_definitions.name,supplied_code'
          ctrl.params.search_term = String(searchTerm) || undefined
        }
      })

      function redirectToNewDiscounts() {
        $location.path('/org/' + currentOrg.id + '/promotions')
      }

      function anyAvailableCount() {
        var everyAvailableCountNull = _.every(ctrl.discountCodeDefinitions, 'available_count', null)
        return !everyAvailableCountNull
      }

      var findAll = debounceCallback(DiscountCodeDefinitions.findAll)
      function loadDiscountCodeDefinitions() {
        return findAll(ctrl.params, ctrl.options)
          .then(setAs(ctrl, 'discountCodeDefinitions'))
          .then(function() {
            ctrl.anyAvailableCount = anyAvailableCount()
          })
          .finally(setAs(ctrl, 'loading', false))
      }

      function pusherSubscribe() {
        PusherService.subscribe('channel_clare_organization_' + currentOrg.id)
        PusherService.bind('event_code_definition_created', loadDiscountCodeDefinitions)
      }

      $scope.$watch('ctrl.sortColumns', function(newSort, oldSort) {
        _.each(oldSort, function(dir, col) {
          delete ctrl.params[col]
        })
        _.extend(ctrl.params, newSort)
      })
      $scope.$watch('ctrl.params', loadDiscountCodeDefinitions, true)
    }
  })
