angular.module('org-admin')
  .directive('paymentTermModal', function() {
    return {
      scope: {},
      templateUrl: '/static/org/payment-terms/payment-term-modal.html',
      controllerAs: 'ctrl',
      bindToController: {
        originalPaymentTerm: '=paymentTerm',
        fromSendInvoices: '<',
        cancel: '=',
        confirm: '='
      },

      controller: function(
        currentOrg,
        currentUser,
        i18ng,
        PaymentTerms,
        SeToastService,
        setWhile,
        showError,
        userSettings
      ) {

        var DAY_OF_MONTH = '1'

        var ctrl = this
        var localStorageDismissedAlert = userSettings.get('dismissedPaymentTermsInfoAlertModal')
        ctrl.userHasDismissedAlert = localStorageDismissedAlert && localStorageDismissedAlert[currentUser.uuid]
        ctrl.nameMaxlength = 32
        ctrl.descriptionMaxlength = 64

        if (ctrl.originalPaymentTerm) {
          ctrl.paymentTerm = angular.copy(ctrl.originalPaymentTerm)
          setDefaultStrategyValues(ctrl.paymentTerm.payment_term_strategy)
          ctrl.editMode = true
        }
        else {
          ctrl.paymentTerm = {
            payment_term_strategy: {
              installment_count: '2',
              strategy_type: 'day_interval',
              day_of_month: DAY_OF_MONTH
            }
          }
        }

        ctrl.setAlertAsDismissed = function() {
          userSettings.set('dismissedPaymentTermsInfoAlertModal.' + currentUser.uuid, true)
        }

        ctrl.hasOneInstallment = function(installmentCount) {
          return installmentCount == '1'
        }

        ctrl.submit = function() {
          ctrl.paymentTerm.organization_id = currentOrg.id
          addCustomPercentages()

          setWhile(ctrl, 'saving', submitPaymentTerm(ctrl.paymentTerm))()
            .then(
              function(response) {
                setAlert(response)
                return ctrl.confirm(response)
              },
              function(response) {
                var responseError = ''
                responseError = response.data.error ? response.data.error : response.statusText
                ctrl.serverErrors = responseError.messages || [responseError]
              }
            ).catch(showError)
        }

        function submitPaymentTerm(paymentTerm) {
          if (ctrl.editMode) {
            return PaymentTerms.update(paymentTerm.id, paymentTerm)
          }
          else {
            return PaymentTerms.create(paymentTerm)
          }
        }

        function addCustomPercentages() {
          var strategy = ctrl.paymentTerm.payment_term_strategy
          if (strategy.custom_percentages) strategy.percentages = transformToNumbers(strategy.percentages)
        }

        function transformToNumbers(percentages) {
          return _.map(percentages, function(percentage) {
            return parseInt(percentage)
          })
        }

        function setAlert(response) {
          if (ctrl.editMode) {
            SeToastService.success(
              i18ng.t('PAYMENT_TERM_MODAL.success_edit', { name: response.name }),
              'check-circle'
            )
          }
          else {
            var href = ''
            var message = i18ng.t('PAYMENT_TERM_MODAL.success_add', { name: response.name })
            if (!ctrl.fromSendInvoices) {
              // Adds the 'View' href to the toast (with a period before it)
              message += '.'
              href = '/org/' + currentOrg.id + '/payment_terms/' + response.id + '/detail'
            }
            SeToastService.success(
              message,
              'check-circle',
              {
                displayText: i18ng.t('PAYMENT_TERM_MODAL.view'),
                href: href
              })
          }
        }

        function setDefaultStrategyValues(strategy) {
          strategy.installment_count = strategy.installment_count.toString()
          strategy.day_of_month = (strategy.day_of_month || DAY_OF_MONTH).toString()
        }
      }
    }
  })
