angular.module('org-admin')
  .component('programListingReminder', {
    templateUrl: '/static/org/program-listings/program-listing-reminder.html',
    bindings: {
      cancel: '<',
      confirm: '<',
      params: '<'
    },
    controller: function() {}
  })
