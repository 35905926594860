angular.module('org-admin')

  .directive('programListingInfo', function() {

    return {
      scope: {},
      bindToController: {
        programListing: '=',
        editMode: '=?',
        createForms: '=?'
      },
      templateUrl: '/static/org/program-listings/program-listing-info.html',
      controllerAs: 'ctrl',
      controller: function(
        _,
        $interval,
        $routeParams,
        $scope,
        $window,
        Alerts,
        currentOrg,
        currentUser,
        dialog,
        Geocoder,
        i18ng,
        listFilter,
        moment,
        ProgramListing,
        ProgramListingForms,
        ProgramListingOptions,
        renderContext
      ) {

        var SUCCESS_CONTEXT = renderContext.programListing && renderContext.programListing.success
        var REG_FIELDS = ['pricing', 'min_price', 'max_price', 'fixed_price', 'reg_status', 'reg_open_date_time', 'reg_close_date_time']

        var ctrl = this
        var intervalStopper
        var programListingOptions = {}
        var newProgramListing = !!$routeParams.newProgramListing

        ctrl.forms = []
        ctrl.createForms = createForms
        ctrl.validators = ProgramListing.validators
        ctrl.submit = submit

        ProgramListingOptions.getOptions().then(function(options) {
          options.sports = ProgramListingOptions.includeSports(ctrl.programListing.sports || [], options.sports)
          programListingOptions = options
          if (SUCCESS_CONTEXT) {
            ctrl.editMode = true
            intervalStopper = $interval(function() {
              var field = angular.element('#form_result_description')
              if (field.length) {
                field.focus()
                stopInterval()
              }
            }, 100)
          }
          createForms()
        })

        function stopInterval() {
          if (angular.isDefined(intervalStopper)) {
            $interval.cancel(intervalStopper)
            intervalStopper = undefined
          }
        }

        function createForms() {
          var model = ctrl.model = _.clone(ctrl.programListing)
          ctrl.forms = []
          ctrl.forms.push(ProgramListingForms.info(model, programListingOptions))
          ctrl.forms.push(ProgramListingForms.dates(model, programListingOptions))
          ctrl.forms.push(ProgramListingForms.location_info(model, programListingOptions))
          ctrl.forms.push(ProgramListingForms.registration(model, programListingOptions))
          ctrl.forms.push(ProgramListingForms.contact(model, programListingOptions))
          ctrl.forms.push(ProgramListingForms.socialMedia(model, programListingOptions))
          if (currentUser.superRole) {
            ctrl.forms.push(ProgramListingForms.display(model, programListingOptions))
          }
          ctrl.readOnly = (model.publishedStatus === 'archived') || (model.owned_externally)
          ctrl.syncForms = true
        }

        function submit(data, formCtrl) {
          var warnIfRegChanges = checkForRegChanges(ctrl.programListing, data)
          return ProgramListing.saveAndSyncLocation(data, ctrl.programListing)
            .then(updateSuccess, updateError)

          function updateSuccess(data) {
            checkChangedPublishStatus(data)
            ctrl.programListing = data
            ctrl.editMode = false
            createForms()
            warnIfRegChanges()
            Alerts.success('FORM_RESULT.success')
          }

          function updateError(data, err) {
            var message = [].concat(_.get(err, 'data.error.messages')).join(', ')
            Alerts.error('FORM_RESULT.error' + (message ? '_message' : ''), { message: _.unescape(message) })
          }
        }

        function checkChangedPublishStatus(data) {
          var oldStatus = ctrl.programListing.publishedStatus
          var newStatus = data.publishedStatus

          if (newProgramListing) $scope.$emit('publishStatusChanged', newStatus)
          else if (newStatus !== oldStatus) $scope.$emit('publishStatusChanged', newStatus, oldStatus)
        }

        function checkForRegChanges(oldData, newData) {
          var changedFields = []
          var newRegFields = _.defaults(_.pick(newData, REG_FIELDS), newData.price_range)
          if (newData.reg_url) _.each(REG_FIELDS, check)
          return changedFields.length ? warn : angular.noop

          function check(rf) {
            if (formatted(oldData[rf]) === formatted(newRegFields[rf])) return
            changedFields.push('<b>' + i18ng.t('PROGRAM_LISTING_FORMS.LABELS.' + rf) + '</b>')
          }

          function formatted(val) {
            return (val && val.toISOString) ? val.toISOString() : val
          }

          function warn() {
            dialog.confirm({
              component: 'confirm-dialog',
              scope: $scope,
              attrs: {
                confirmAction: gotoReg,
                heading: i18ng.t('PROGRAM_LISTING_SAVED.heading'),
                message: listFilter(changedFields, 'PROGRAM_LISTING_SAVED.message', { limit: Infinity }),
                cancelLabel: i18ng.t('PROGRAM_LISTING_SAVED.cancel_label'),
                confirmLabel: i18ng.t('PROGRAM_LISTING_SAVED.confirm_label')
              }
            })
          }

          function gotoReg() {
            $window.open(newData.reg_url)
          }
        }

      }
    }
  })
