angular.module('org-admin')
  .directive('salesReminderButton', function() {
    return {
      replace: true,
      restrict: 'A',
      scope: {},
      bindToController: {
        selectedSales: '='
      },
      controllerAs: 'ctrl',
      templateUrl: '/static/org/sales-reminder/sales-reminder-button.html',
      controller: function($scope, Alerts, dialog, i18ng) {
        var ctrl = this

        ctrl.sendReminder = function() {
          var unsettled_sales = _.filter(ctrl.selectedSales, function(sale) {
            return _.contains(['pending', 'unsettled'], sale.status)
          })

          if (!ctrl.selectedSales.length) {
            Alerts.warning('SALE_REMINDER.ERRORS.select_sales')
          }
          else {
            var allSalesSettled = settledCount() === ctrl.selectedSales.length
            dialog.confirm({
              directive: 'sale-reminder',
              scope: $scope,
              attrs: {
                sales: allSalesSettled ? ctrl.selectedSales : unsettled_sales,
                allSettled: allSalesSettled,
                someSettled: settledCount() && !allSalesSettled
              }
            })
              .then(function(payment) {
                payment.length > 1 ? Alerts.success(payment.length + i18ng.t('SALE_REMINDER.success_plural')) :
                  Alerts.success(i18ng.t('SALE_REMINDER.success'))
              })
          }

          function settledCount() {
            return _.filter(ctrl.selectedSales, function(sale) {
              return sale.is_settled
            }).length
          }
        }
      }
    }
  })
