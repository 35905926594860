angular.module('org-admin')
  .component('rosterFilters', {
    bindings: {
      filters: '<',
      chipClass: '@?',
      count: '<?'
    },
    transclude: true,
    templateUrl: '/static/org/roster-management/roster-filters.html',
    controller: function($timeout) {

      var ctrl = this

      ctrl.toggleFilters = function(event) {
        var selector = '.ba-rostering__filter'
        if (ctrl.chipClass) selector += ',.' + ctrl.chipClass
        if (angular.element(event.target).closest(selector).length) return
        ctrl.filtersExpanded = !ctrl.filtersExpanded
      }

      ctrl.removeFilter = function(filter) {
        $timeout(function() { // allow event to bubble up to parent first
          _.pull(ctrl.filters, filter)
          if (!(ctrl.count || ctrl.filters.length)) ctrl.filtersExpanded = false
        })
      }

    }

  })
