angular.module('org-admin')
  .component('filterPanelValueInput', {
    bindings: {
      rule: '<filterRule',
      readOnly: '<'
    },
    templateUrl: '/static/org/filtering/filter-panel-value-input.html',
    controller: function filterPanelValueInputController(_) {
      var $ctrl = this

      $ctrl.id = _.uniqueId('filter-panel-value-input-')
    }
  })
