angular.module('org-admin')
  .service('PayoutsDetailReport', function(DS, paginate) {
    var PayoutDetailReport = DS.defineResource({
      name: 'payout_details',
      idAttribute: 'settlement_item_id',
      endpoint: 'reports/payouts',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
    })
    return paginate(PayoutDetailReport)
  })
