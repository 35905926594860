angular.module('org-admin')
  .component('contactsDibs', {
    bindings: {
      persona: '<',
    },
    template: '<div class="pl-pane pl-layout--on-gray"><se-card se-data-full-height="true" se-data-full-width-content="true" class="pl-scrolling-card"><se-cp-card-content><iframe src="{{ $ctrl.iframeSrc }}" class="pl-iframe"></iframe></se-card></se-cp-card-content></div>',
    controllerAs: '$ctrl',
    controller: function($sce, currentOrg) {
      var $ctrl = this
      $ctrl.$onInit = function() {
        $ctrl.iframeSrc = $sce.trustAsResourceUrl(currentOrg.siteUrl + '/personas/dibs/' + $ctrl.persona.id)
      }
    }
  })
