angular.module('pl-shared')
  .component('unclaimEntry', {
    bindings: {
      surveyResult: '=',
      parentScope: '=',
      cancel: '=',
      confirm: '='
    },
    templateUrl: '/static/shared/components/registration/survey-results/unclaim-entry.html',
    controller: function($scope, SurveyResult, setAs, Alerts) {
      var $ctrl = this
      $ctrl.unclaiming = false

      $ctrl.unclaimEntry = function() {
        $ctrl.unclaiming = true

        return SurveyResult.unclaim($ctrl.surveyResult.id, { params: { persona_id: $ctrl.surveyResult.persona_id } })
          .then(unclaimEntrySuccess, unclaimEntryFailure)
          .finally(setAs($ctrl, 'unclaiming', false))

        function unclaimEntrySuccess() {
          $ctrl.confirm()
          Alerts.success('SURVEY_RESULT.UNCLAIM_MODAL.success')
          $ctrl.parentScope.$emit('entryUnclaimed', $ctrl.surveyResult)
        }

        function unclaimEntryFailure() {
          $ctrl.confirm()
          Alerts.error('SURVEY_RESULT.UNCLAIM_MODAL.error')
        }
      }

    }
  })
