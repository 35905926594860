/* eslint-disable no-console */
angular.module('org-admin').component('paymentsSuspended', {
  templateUrl: '/static/org/payments-suspended/payments-suspended.component.html',
  controllerAs: 'ctrl',
  controller: function($scope, currentOrg, $location) {
    const ctrl = this

    function navigateToFinancialSettings() {
      $location.url('/org/' + currentOrg.id + '/financial_settings')
    }

    ctrl.navigateToFinancialSettings = navigateToFinancialSettings
  }
})
