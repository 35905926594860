angular.module('org-admin')
  .directive('membershipDefinitionNew', function() {
    return {
      restrict: 'EA',
      scope: {},
      bindToController: {
        cancel: '=',
        confirm: '=',
        dialogOptions: '=',
        membershipDefinitions: '=',
        linkableMemDefs: '<'
      },
      templateUrl: '/static/org/memberships/membership-definition-new.html',
      controllerAs: '$ctrl',
      controller: function(
        _, i18ng,
        $q,
        appPermission,
        currentOrg,
        MembershipDefinition,
        MembershipTagService,
        MembershipsUtilService,
        CredentialDefinition
      ) {
        var $ctrl = this

        $ctrl.failed = false
        $ctrl.model = {
          boss_organization_id: currentOrg.id,
          can_affiliate_obo: false,
          can_direct: false,
          can_invite: false,
          credential_definition_id: null,
          description: null,
          duration_type: 'years',
          duration_value: null,
          effective_type: null,
          end_date: null,
          linkable: false,
          name: null,
          price: null,
          linked_membership_definition_id: null,
          price_cents: 0,
          purchase_status: 'scheduled',
          start_date: null
        }
        $ctrl.step = 1
        $ctrl.totalSteps = 5
        $ctrl.showAddTagsModal = showAddTagsModal
        $ctrl.showEligibilityModal = showEligibilityModal
        $ctrl.showMessageModal = showMessageModal
        $ctrl.showPublishingModal = showPublishingModal
        $ctrl.back = back
        $ctrl.getEligibilityRules = getEligibilityRules

        $ctrl.eligibilityEnabled = false

        $ctrl.$onInit = function() {
          loadCredentialDefinitions()
          checkEligibility()
        }

        $ctrl.submit = submit
        $ctrl.removeServerMessages = removeServerMessages
        $ctrl.removeAgeTagSessionStorage = removeAgeTagSessionStorage
        $ctrl.removeRoleTagSessionStorage = removeRoleTagSessionStorage
        $ctrl.removeGenderTagSessionStorage = removeGenderTagSessionStorage
        $ctrl.removeSeasonalityTagSessionStorage = removeSeasonalityTagSessionStorage
        $ctrl.getMembershipTags = getMembershipTags

        /**
         * Creates the membership definition and closes the dialog if the request is successful.
         */
        function showAddTagsModal() {
          $ctrl.step = 2
        }

        function showEligibilityModal() {
          $ctrl.step = 3
        }

        function showMessageModal() {
          $ctrl.step = 4
        }

        function showPublishingModal() {
          $ctrl.step = 5
        }

        function back() {
          $ctrl.step -= 1
          if ($ctrl.step < 0) {
            $ctrl.step = 0
          }
        }

        function submit() {
          $ctrl.submitting = true
          $ctrl.failed = false
          $ctrl.serverErrorMessages = null

          var attrs = {
            message: $ctrl.message,
            purchase_open_date: $ctrl.purchase_open_date,
            purchase_open_time: $ctrl.purchase_open_time
          }

          var data = MembershipsUtilService.dataFromModel($ctrl.model, attrs)
          data.eligibility_rules = getEligibilityRules()

          MembershipDefinition.create(data)
            .then(createSuccess)
            .then(createConfirm)
            .then(reloadPage)
            .catch(createError)

          function createSuccess(result) {
            $ctrl.newModel = result
            var tags = getMembershipTags(result.id)
            return createTags(tags)
          }

          function createConfirm() {
            $ctrl.submitting = false
            return $ctrl.confirm($ctrl.newModel)
          }

          function reloadPage() {
            window.location.reload()
          }

          function createTags(tags) {
            var requests = tags.map((tag) => MembershipTagService.create(tag))
            return $q.all(requests)
          }
        }

        function getMembershipTags(membershipDefinitionID) {
          var tags = []
          // first the role tag TODO actually get data from input session store
          var retrievedRoleArray = window.sessionStorage.getItem('checkedRoles') ? window.sessionStorage.getItem('checkedRoles').split(',') : false
          var rolesSelected = window.sessionStorage.getItem('roleTagSelection')
          if (retrievedRoleArray && (rolesSelected == 'rolesYes')) {
            retrievedRoleArray.forEach(checkedRole => {
              tags.push({
                boss_organization_id: $ctrl.model.boss_organization_id,
                resource_type: 'MembershipDefinition',
                resource_id: membershipDefinitionID,
                tag_option_type: 'Role',
                tag_value: checkedRole,
                tag_value_type: 'text',
                name: checkedRole
              })
            })
            removeRoleTagSessionStorage()
          }
          // next the age tag
          var endDate = window.sessionStorage.getItem('processedEndDate')
          var startDate = window.sessionStorage.getItem('processedStartDate')
          var ageTagName = window.sessionStorage.getItem('ageTagName')
          if (endDate || startDate) {
            tags.push({
              boss_organization_id: $ctrl.model.boss_organization_id,
              resource_type: 'MembershipDefinition',
              resource_id: membershipDefinitionID,
              tag_option_type: 'Age',
              tag_value: {
                end_date: endDate,
                start_date: startDate
              },
              tag_value_type: 'date_range',
              name: ageTagName
            })
            removeAgeTagSessionStorage()
          }
          var retrievedGenderArray = window.sessionStorage.getItem('checkedGenders') ? window.sessionStorage.getItem('checkedGenders').split(',') : false
          var gendersSelected = window.sessionStorage.getItem('genderTagSelection')
          if (retrievedGenderArray && (gendersSelected == 'gendersYes')) {
            retrievedGenderArray.forEach(checkedGender => {
              tags.push({
                boss_organization_id: $ctrl.model.boss_organization_id,
                resource_type: 'MembershipDefinition',
                resource_id: membershipDefinitionID,
                tag_option_type: 'Gender',
                tag_value: checkedGender,
                tag_value_type: 'text',
                name: checkedGender
              })
            })
            removeGenderTagSessionStorage()
          }
          var retrievedSeasonalityArray = window.sessionStorage.getItem('checkedSeasonality') ? window.sessionStorage.getItem('checkedSeasonality').split(',') : false
          var seasonalitySelected = window.sessionStorage.getItem('seasonalityTagSelection')
          if (retrievedSeasonalityArray && (seasonalitySelected == 'seasonalityYes')) {
            retrievedSeasonalityArray.forEach(checkedSeasonality => {
              tags.push({
                boss_organization_id: $ctrl.model.boss_organization_id,
                resource_type: 'MembershipDefinition',
                resource_id: membershipDefinitionID,
                tag_option_type: 'Seasonality',
                tag_value: checkedSeasonality,
                tag_value_type: 'text',
                name: checkedSeasonality
              })
            })
            removeSeasonalityTagSessionStorage()
          }
          if (tags.length === 0) {
            tags.push({
              boss_organization_id: $ctrl.model.boss_organization_id,
              resource_type: 'MembershipDefinition',
              resource_id: membershipDefinitionID,
              tag_option_type: 'Role',
              tag_value: 'No Role',
              tag_value_type: 'text',
              name: 'No Role'
            })
          }
          if (window.sessionStorage.getItem('roleTagSelection') === 'rolesNo') {
            window.sessionStorage.removeItem('roleTagSelection')
          }
          if (window.sessionStorage.getItem('ageTagSelection') === 'noAge') {
            window.sessionStorage.removeItem('ageTagSelection')
          }
          if (window.sessionStorage.getItem('genderTagSelection') === 'gendersNo') {
            window.sessionStorage.removeItem('genderTagSelection')
          }
          if (window.sessionStorage.getItem('seasonalityTagSelection') === 'seasonalityNo') {
            window.sessionStorage.removeItem('seasonalityTagSelection')
          }
          return tags
        }
        function removeRoleTagSessionStorage() {
          window.sessionStorage.removeItem('roleTagSelection')
          window.sessionStorage.removeItem('checkedRoles')
        }

        function removeAgeTagSessionStorage() {
          window.sessionStorage.removeItem('ageInput')
          window.sessionStorage.removeItem('ageTagSelection')
          window.sessionStorage.removeItem('processedEndDate')
          window.sessionStorage.removeItem('processedStartDate')
          window.sessionStorage.removeItem('startDate')
          window.sessionStorage.removeItem('endDate')
          window.sessionStorage.removeItem('ageTagName')
        }

        function removeGenderTagSessionStorage() {
          window.sessionStorage.removeItem('genderTagSelection')
          window.sessionStorage.removeItem('checkedGenders')
        }

        function removeSeasonalityTagSessionStorage() {
          window.sessionStorage.removeItem('seasonalityTagSelection')
          window.sessionStorage.removeItem('checkedSeasonality')
        }

        function getEligibilityRules() {
          var elig = $ctrl.model.eligibility
          var rules = []
          if (elig) {
            getBackgroundCheck()
            getProofOfBirth()
            getElectronicDocuments()
            getTrainings()
            getFifaIntegration()
            getViolationReview()
            getCustomCredentials()
          }
          return rules

          function getBackgroundCheck() {
            if (!elig.background_check_required) return
            rules.push({
              originator_system: 'Ngin',
              originator_type: 'background_screen',
              originator_id: elig.background_check
            })
          }

          function getProofOfBirth() {
            if (!elig.proof_of_birth_required) return
            rules.push({
              originator_system: 'ProofOfBirthService',
              originator_type: 'proof_of_birth',
              originator_id: currentOrg.id
            })
          }

          function getElectronicDocuments() {
            if (!elig.electronic_document_required) return
            elig.electronic_documents.forEach(function(edocId) {
              rules.push({
                originator_system: 'MembershipService',
                originator_type: 'electronic_document',
                originator_id: edocId
              })
            })
          }

          function getCustomCredentials() {
            if (!elig.custom_credentials_required) return
            elig.custom_credentials.forEach(function(id) {
              rules.push({
                originator_system: 'Ngin',
                originator_type: 'ngin_credential',
                originator_id: id
              })
            })
          }

          function getTrainings() {
            if (!elig.training_required) return
            if (_.includes(elig.trainings, 'safesport')) {
              rules.push({
                originator_system: 'SeAbsorbService',
                originator_type: 'safesport',
                originator_id: 'training'
              })
            }
            if (_.includes(elig.trainings, 'sideline_sports')) {
              rules.push({
                originator_system: 'SeSsdIntegrationService',
                originator_type: 'sideline_sports',
                originator_id: 'sideline_sports'
              })
            }
            if (_.includes(elig.trainings, 'aps')) {
              rules.push({
                originator_system: 'SeApsService',
                originator_type: 'aps',
                originator_id: 'aps'
              })
            }
            if (_.includes(elig.trainings, 'litmos_impact')) {
              rules.push({
                originator_system: 'SeLitmosService',
                originator_type: 'course-C01',
                originator_id: currentOrg.id
              })
            }
            if (_.includes(elig.trainings, 'lms_crash_course')) {
              rules.push({
                originator_system: 'SeLmsService',
                originator_type: 'course',
                originator_id: 'CrashCourse'
              })
            }
          }

          function getFifaIntegration() {
            if (!elig.fifa_integration_required) return
            rules.push({
              originator_system: 'SeFifaProvider',
              originator_type: 'fifa_integration',
              originator_id: 'fifa_integration'
            })
          }

          function getViolationReview() {
            if (!elig.violation_review_required) return
            rules.push({
              originator_system: 'MembershipService',
              originator_type: 'violation_review',
              originator_id: null, // this will be converted to rule_set_id upon save
            })
          }
        }

        function createError() {
          $ctrl.submitting = false
          $ctrl.failed = true
          $ctrl.serverErrorMessages = _.get(arguments[0], 'data.error.messages') || [i18ng.t('MEMBERSHIP_DEFINITION_FORMS.ERRORS.create_failed')]
        }

        function loadCredentialDefinitions() {
          CredentialDefinition.forOrgWithTag(currentOrg.id, 'memberships')
            .then(function(result) {
              $ctrl.credentialDefinition = result
              $ctrl.loaded = true
            })
        }

        function checkEligibility() {
          $ctrl.eligibilityEnabled = appPermission.eligibility.usable
        }

        /**
         * Clears server error messages from the dialog.
         */
        function removeServerMessages() {
          if ($ctrl.serverErrorMessages) {
            $ctrl.serverErrorMessages.length = 0
          }
        }
      }
    }
  })
