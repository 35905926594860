angular.module('org-admin')

  .directive('invoiceGroupsList', function() {
    return {
      scope: {},
      templateUrl: '/static/org/invoice-groups/invoice-groups-list.html',
      controllerAs: 'ctrl',
      controller: function(
        $scope,
        $q,
        $timeout,
        setAs,
        showError,
        Search,
        selectHelper,
        InvoiceGroup,
        currentOrg,
        debounceCallback
      ) {

        var ctrl = this
        var findInvoiceGroups = debounceCallback(InvoiceGroup.findAll)

        ctrl.loading = true
        ctrl.invoiceGroups = []
        ctrl.params = { org_id: currentOrg.id, 'status[]': ['sent', 'error', 'sending'] }
        ctrl.options = { result: ctrl.invoiceGroups, pagination: {} } // passing result allows tracking on the collection
        ctrl.sortColumns = { updated_at: 'desc' }
        ctrl.countParams = _.clone(ctrl.params)

        ctrl.search = Search.create({
          update: function(searchTerm, searchOptions) {
            ctrl.params.search_term = String(searchTerm) || undefined
            ctrl.params.search_fields = 'invoice_group_number,description'
            ctrl.options.load = ctrl.params.search_term ? 'all' : 'default'
          }
        })

        $scope.$watch('ctrl.sortColumns', updateSortParams)
        $scope.$watch('ctrl.invoiceGroups.loading', putErrorsAtTop)
        $scope.$watch('ctrl.params', loadInvoiceGroups, true) // should be last watch

        $scope.listenToRoot('invoiceGroup:updateRequest', loadInvoiceGroups)
        $scope.listenToRoot('invoice:update', loadInvoiceGroups)

        // PRIVATE METHODS

        function updateSortParams() {
          ctrl.params = _.omit(ctrl.params, function(val, key) { return _.startsWith(key, 'order[') })
          _.each(ctrl.sortColumns, function(dir, col) { ctrl.params['order[' + col + ']'] = dir })
        }

        function loadInvoiceGroups() {
          return $q.all({
            invoiceGroups: findInvoiceGroups(ctrl.params, ctrl.options),
            invoiceGroupCount: InvoiceGroup.count(ctrl.countParams)
          })
            .then(setPagination)
            .then(checkSending)
            .then(setAs(ctrl, 'loading', false), showError) // TODO: make this show an inline error
        }

        function setPagination(results) {
          _.extend(ctrl.options.pagination, {
            filtered: !!ctrl.params.search_term,
            unfiltered_total: results.invoiceGroupCount.total_records
          })
        }

        function checkSending() {
          function refreshSending(ig) {
            if (ig.status === 'sending') {
              $timeout(function() {
                ig.DSRefresh().then(refreshSending)
              }, 5000)
            }
          }
          _.each(ctrl.invoiceGroups, refreshSending)
        }

        function putErrorsAtTop(nowLoading, wasLoading) {
          if (!wasLoading || nowLoading) return
          var igs = ctrl.invoiceGroups
          var errorIndex = 0
          for (var i = 0; i < igs.length; i++) {
            if (igs[i].status !== 'error') continue
            igs.splice(errorIndex, 0, igs.splice(i, 1)[0])
            errorIndex++
          }
        }
      }
    }
  })
