angular.module('pl-shared')
  .directive('dynamicColumnHeaders', function() {
    return {
      restrict: 'A',
      replace: true,
      scope: {
        dynamicColumns: '=dynamicColumnHeaders'
      },
      template: '<th title="{{column.title}}" ng-repeat="column in dynamicColumns.shown track by column.key" ng-style="column.style" sort-column="{{column.sort}}"><span ng-style="column.contentStyle">{{column.name}}</span></th>'
    }
  })
