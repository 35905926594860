angular.module('pl-shared')

  .directive('personaHeader', function() {

    return {
      restrict: 'A',
      transclude: true,
      scope: {},
      bindToController: {
        persona: '=',
        verifiedInfo: '<'
      },
      templateUrl: '/static/shared/components/personas/persona-header.html',
      controllerAs: 'ctrl',
      controller: function($scope, dialog, i18ng, fileHelper, renderContext, routeTitle, VerifiedInfoService) {

        var ctrl = this
        ctrl.editPhoto = editPhoto
        ctrl.canEdit = canEdit()
        ctrl.personaName = ''
        setCorrectName()

        this.$onChanges = function() {
          setCorrectName()
        }

        function editPhoto() {
          var original = ctrl.persona.profile_image_original
          var profileImageData = original ? fileHelper.parseUrl(original.url) : {}

          dialog.confirm({
            directive: 'file-upload-modal',
            scope: $scope,
            attrs: {
              fileUploadOptions: {
                accept: fileHelper.staticImageTypes,
                allowCrop: true,
                clearLinkText: i18ng.t('ANSWER.PROFILE_PHOTO.clear_link'),
                imgClass: 'pl-document-upload__image--profile-photo',
                backgroundImage: true,
                showFilename: false,
                showSupports: true,
                titleText: i18ng.t('PROFILE.upload_title'),
                uploadLinkText: i18ng.t('ANSWER.PROFILE_PHOTO.upload_link'),
                save: savePhoto
              },
              model: profileImageData
            }
          })
        }

        function savePhoto(profileImageData) {
          ctrl.uploadingImage = true
          return ctrl.persona.DSUpdate({
            profile_image: profileImageData
          }, {
            // persona has nested resource serializer, and this data cannot be nested
            serialize: function(config, data) {
              return data
            }
          }).then(savePhotoSuccess)
        }

        function savePhotoSuccess(savedPersona) {
          angular.merge(ctrl.persona, savedPersona)
          ctrl.persona.DSCompute()
          ctrl.uploadingImage = false
        }

        // claimed personas can only be edited in the user app
        function canEdit() {
          return renderContext.get('app.user') || ctrl.persona.persona_type === 'orphan'
        }

        function setCorrectName() {
          var name = ''
          var useVerifiedName = ctrl.verifiedInfo && ctrl.verifiedInfo.verified

          if (useVerifiedName) {
            name = VerifiedInfoService.getFormattedName(ctrl.verifiedInfo, 'firstLast')
          }
          else {
            name =  ctrl.persona.full_name_with_suffix
          }

          ctrl.personaName = name
          routeTitle.setParams({
            persona_name: ctrl.personaName
          })
        }

      }
    }

  })
