angular.module('org-admin')
  .factory('rosterHistoryChangeType', function() {

    var typesArray = [
      'active',
      'approved',
      'approved_auto',
      'approved_exception',
      'comment',
      'inactive',
      'missing_information',
      'submitted',
      'rejected',
      'auto_rejected',
      'retired',
      'reverted',
      'rostered',
      'unrostered',
      'unsubmitted'
    ]

    var types = _.object(_.map(typesArray, function(type) { return [type, type] }))

    var actions = [
      'participation_status_changed',
      'roster_persona_added',
      'roster_status_changed',
      'roster_status_comment'
    ]

    // map of action -> auditable field -> from_value -> to_value -> action type
    var changeMatrix = {
      'participation_status_changed': {
        'participation_status': {
          'active': {
            'inactive': types.inactive,
            'retired': types.retired
          },
          'inactive': {
            'active': types.active,
            'retired': types.retired
          },
          'retired': {
            'active': types.active,
            'inactive': types.inactive
          }
        }
      },
      'roster_persona_added': {
        _default: types.rostered
      },
      'roster_persona_removed': {
        _default: types.unrostered
      },
      'roster_status_changed': {
        'roster_status': {
          'approved': {
            'approved_exception': types.approved_exception,
            'pending': types.reverted,
            'rejected': types.rejected,
            'auto_rejected': types.rejected,
            'missing_information': types.missing_information,
            'unsubmitted': types.reverted,
          },
          'approved_exception': {
            'approved': types.approved,
            'pending': types.reverted,
            'rejected': types.rejected,
            'auto_rejected': types.rejected,
            'missing_information': types.missing_information,
            'unsubmitted': types.reverted,
          },
          'no_approval_required': {
            'pending': types.submitted,
            'missing_information': types.missing_information,
            'unsubmitted': types.reverted,
          },
          'pending': {
            'approved': types.approved,
            'approved_exception': types.approved_exception,
            'no_approval_required': types.approved_auto,
            'rejected': types.rejected,
            'auto_rejected': types.rejected,
            'missing_information': types.missing_information,
          },
          'rejected': {
            'approved': types.approved,
            'approved_exception': types.approved_exception,
            'pending': types.reverted,
            'missing_information': types.missing_information,
            'unsubmitted': types.reverted,
          },
          'auto_rejected': {
            'approved': types.approved,
            'approved_exception': types.approved_exception,
            'pending': types.reverted,
            'missing_information': types.missing_information,
            'unsubmitted': types.reverted,
          },
          'unsubmitted': {
            'pending': types.submitted,
            'no_approval_required': types.approved_auto,
            'auto_rejected': types.rejected,
            'missing_information': types.missing_information,
          },
          'missing_information': {
            'approved': types.approved,
            'approved_exception': types.approved_exception,
            'no_approval_required': types.approved,
            'pending': types.submitted,
            'rejected': types.rejected,
            'auto_rejected': types.rejected,
            'unsubmitted': types.reverted,
          }
        }
      },
      'roster_persona_comment': {
        _default: types.comment
      }
    }

    function getChangeType(action) {
      var actionMatrix = changeMatrix[action.action]
      if (!actionMatrix) return 'unknown'
      if (actionMatrix._default) return actionMatrix._default
      for (var changeIndex = 0; changeIndex < action.auditable_fields.length; changeIndex++) {
        var change = action.auditable_fields[changeIndex]
        var changeType = _.get(actionMatrix, [change.field, change.from_value, change.to_value])
        if (changeType) return changeType
      }
      return 'unknown'
    }

    return {
      actions: actions,
      getChangeType: getChangeType,
      typesArray: typesArray,
      types: types
    }
  })
