angular.module('org-admin')
  .directive('billingProvisioningGbr', function() {
    return {
      scope: {},
      bindToController: {
        provisioning: '='
      },
      templateUrl: '/static/org/provisioning/billing-provisioning-GBR.html',
      controllerAs: 'ctrl',
      controller: function($scope, $window, dialog, currentOrg, $filter) {
        var ctrl = this
        var processorSettingsStatuses = ctrl.provisioning.processor_settings.statuses
        var bankFailure = errorOrRejection(ctrl.provisioning.store.bank_account, 'status')
        var documentErrors = errorOrRejection(processorSettingsStatuses, 'document_upload_status')
        var merchantAccountErrors = errorOrRejection(processorSettingsStatuses, 'merchant_account_status')
        var errors = documentErrors || merchantAccountErrors || bankFailure

        function errorOrRejection(settings, status) {
          if (!settings) return
          return settings[status] == 'error' || settings[status] == 'rejected'
        }

        ctrl.bankAccountExists = ctrl.provisioning.store.bank_account.exists
        ctrl.processorSettingsExists = ctrl.provisioning.processor_settings.exists
        ctrl.showMerchantApplicationAlert = documentErrors || merchantAccountErrors
        ctrl.underReview = ctrl.bankAccountExists && ctrl.processorSettingsExists && !errors
        ctrl.rejected = ctrl.bankAccountExists && ctrl.processorSettingsExists && errors
        ctrl.transactionFee = $filter('currency')(currentOrg.transaction_fee, '£', 2)
        ctrl.processingFee = currentOrg.processing_fee + '%'


        ctrl.openBankAccountModal = function(attrs) {
          dialog
            .confirm({
              directive: 'bank-account-modal-gbr',
              scope: $scope,
              attrs: attrs
            })
            .then(function() {
              ctrl.loading = true
              $window.location.reload()
            })
        }
      }
    }
  })
