angular.module('org-admin')
  .directive('financialSettings', function() {
    return {
      scope: {},
      bindToController: {},
      templateUrl: '/static/org/financial-settings/financial-settings.html',
      controllerAs: 'ctrl',
      controller: function(
        appPermission,
        billingProvisioningService,
        currentOrg,
        currentUser,
        featureToggles,
        renderContext,
        showError
      ) {
        // if they are using stripe redirect them to the *new* financial settings (financial settings ui app)
        if (featureToggles.stripe_payment_processing) return renderContext.goto('financialSettings')
        if (!appPermission.financialSettings.visible) return showError('404')

        var ctrl = this
        ctrl.country = (currentOrg.country === 'GBR') ? 'GBR' : 'Other'
        ctrl.loading = true

        if (ctrl.country === 'GBR') {
          billingProvisioningService.fetch().then(function(response) {
            ctrl.provisioning = response
            ctrl.loading = false
          })
        }
        else {
          ctrl.loading = false
        }

      }
    }
  })
