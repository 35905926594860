angular.module('org-admin')

  .service('ProgramListingForms', function(_, rfFormFacade, i18ng, $filter, currentOrg) {

    var COMPETITION_LEVEL_OTHER = 3 // the id of competition_level Other
    var DEFAULT_GENDERS = ['coed']
    var DEFAULT_PRICING = 'undecided' // the default value for pricing
    var DEFAULT_TIMEZONE = 'America/New_York'
    var dateFilter = $filter('date')

    //--------------------------------
    // defines all the elements that may be used in program listing forms

    var shortTextField = { type: 'short_text' }
    var longTextField = { type: 'long_text' }
    var phoneField = { type: 'phone' }
    var socialMediaField = {
      type: 'website',
      namespace: 'social_media_urls'
    }
    var timeField = {
      type: 'time',
      properties: {
        fields: {
          time_of_day_additional_info: { id: 'time_of_day_additional_info' },
          time_of_day: { id: 'time_of_day' },
        },
        flatten_field_data: true,
        additional_info: true
      },
      answer: function(programListing) {
        return {
          value: {
            time_of_day: programListing.time_of_day,
            time_of_day_additional_info: programListing.time_of_day_additional_info
          },
          label: function() {
            var displayValue = '--'
            if (programListing.time_of_day) {
              displayValue = moment('2000-01-01 ' + programListing.time_of_day).format('hh:mm A')
            }
            return displayValue
          }
        }
      }
    }
    var timezoneField = { type: 'timezone' }
    var dateField = {
      type: 'date',
      answer: dateAnswer
    }
    var radioField = { type: 'radio' }
    var pulldownField = { type: 'pulldown' }
    var checkboxField = { type: 'checkbox' }
    var genderField = {
      type: 'checkbox',
      answer: function(programListing) {
        return {
          value: programListing.genders
        }
      }
    }
    var linkField = { type: 'link' }
    var dateRangeField = {
      type: 'program_listing_date_range',
      properties: {
        fields: {}
      },
      answer: function(programListing) {
        return {
          value: {
            start_date: programListing.start_date,
            end_date: programListing.end_date,
            approximate_dates: !!programListing.approximate_dates
          },
          label: function(programListing) {
            var dates = []
            var fmt = programListing.approximate_dates ? 'MMM yyyy' : 'MMM d, yyyy'
            if (programListing.start_date) dates.push(dateFilter(programListing.start_date, fmt))
            if (programListing.end_date) dates.push(dateFilter(programListing.end_date, fmt))
            return _.uniq(dates).join(' - ')
          }
        }
      }
    }

    var withinAYear = moment().add(1, 'years')
    var addressField = {
      type: 'program_listing_address',
      properties: {
        hide_label: true,
        auto_format: 'capitalize'
      },
      answer: function(programListing) {
        return {
          value: _.pick(programListing,
            'address_same_as_org',
            'address_1',
            'address_2',
            'country',
            'state',
            'city',
            'postal_code'
          )
        }
      }
    }

    var elems = {
      name: shortTextField,
      description: longTextField,
      age_range: {
        type: 'program_listing_age_range',
        answer: function(programListing) {
          return {
            value: _.pick(programListing,
              'min_age_years',
              'max_age_years',
              'age_by_date',
              'age_additional_info',
              'start_date'
            )
          }
        }
      },
      sports: checkboxField,
      image: {
        type: 'image',
        properties: {
          translations: {
            // NOTE: other translations use default values
            add_text: 'PROGRAM_LISTING_INFO.IMAGE.add_text',
            help_text: 'PROGRAM_LISTING_INFO.IMAGE.help_text'
          }
          // TODO: use clearWhenMatch for default images
          // clearWhenMatch: /sport-placeholder.jpg$/
        },
        answer: function(programListing) {
          var image = programListing.image || {}
          return {
            value: {
              url: image.url,
              crop_url: image.xlarge_image_url,
              is_default: false
            },
            label: image.url
          }
        }
      },
      program_listing_type: pulldownField,
      competition_level: {
        type: 'pulldown_with_other',
        properties: {
          fields: {
            pulldown: { id: 'competition_level' },
            other: { id: 'competition_level_other' }
          },
          flatten_field_data: true,
          other_label: i18ng.t('PROGRAM_LISTING_FORMS.LABELS.competition_level_other'),
          other_value: COMPETITION_LEVEL_OTHER,
          max: 15
        },
        answer: function(programListing) {
          var compLevel = programListing.competition_level || {}
          return {
            value: {
              pulldown: compLevel.value,
              other: programListing.competition_level_other
            },
            label: function(programListing) {
              if (compLevel.value === COMPETITION_LEVEL_OTHER) {
                return programListing.competition_level_other
              }
              return compLevel.label || '--'
            }
          }
        }
      },
      genders: genderField,
      date_range: dateRangeField,
      days_of_week: {
        type: 'program_listing_day_of_week',
        properties: {
          fields: {
            dow_additional_info: { id: 'dow_additional_info' },
            days_of_week: { id: 'days_of_week' },
            dow_schedule_varies: { id: 'dow_schedule_varies' }
          },
          flatten_field_data: true,
          additional_info: true
        },
        answer: function(programListing) {
          var dowOpts = i18ng.t('PROGRAM_LISTING_OPTIONS.days_of_week', { returnObjectTrees: true })
          var vals = _.filter(_.keys(dowOpts), function(dow) {
            return !!programListing[dow]
          })
          var dowList = _.map(vals, function(dow) { return dowOpts[dow] }).join(', ')
          return {
            value: {
              days_of_week: vals,
              dow_additional_info: programListing.dow_additional_info,
              dow_schedule_varies: programListing.dow_schedule_varies
            },
            label: dowList + (programListing.dow_schedule_varies ? (dowList ? ', ' : '') + i18ng.t('PROGRAM_LISTING_OPTIONS.dow_schedule_varies') : '')
          }
        }
      },
      time_of_day: timeField,
      timezone: timezoneField,
      price_range: {
        type: 'program_listing_pricing',
        answer: function(programListing) {
          var range = programListing.price_range || {}
          return {
            value: {
              pricing: range.pricing || DEFAULT_PRICING,
              min_price: range.min_price,
              max_price: range.max_price,
              fixed_price: range.fixed_price
            },
            label: function() {
              if (range.pricing === 'free') {
                return i18ng.t('PROGRAM_LISTING_OPTIONS.pricing.free')
              }
              if (range.pricing === 'fixed') {
                var p = programListing.fixed_price || 0
                return formatCurrency(p)
              }
              if (range.pricing === 'range') {
                var min = programListing.min_price || 0
                var max = programListing.max_price || 0
                return formatCurrency(min) + ' - ' + formatCurrency(max)
              }

              return i18ng.t('PROGRAM_LISTING_OPTIONS.pricing.undecided')
            }
          }
        }
      },
      registration: {
        type: 'program_listing_registration',
        answer: function(programListing) {
          var tz = getTimezone(programListing)
          var open = momentTzOrNull(programListing.reg_open_date_time, tz)
          var close = momentTzOrNull(programListing.reg_close_date_time, tz)

          var dates = []
          if (open) dates.push(formatDateTime(open, tz))
          if (close) dates.push(formatDateTime(close, tz))

          return {

            value: {
              reg_type: programListing.reg_type,
              reg_url: programListing.reg_url,
              // the calendar and timepicker components do not support timezones
              // so we need to do these conversions :P
              min_date: open,
              max_date: close,
              min_time: open ? moment().hours(open.hours()).minutes(open.minutes()) : null,
              max_time: close ? moment().hours(close.hours()).minutes(close.minutes()) : null,
              min_date_time: open,
              max_date_time: close
            },
            label: function() {
              // this is used by programListingsRegistrationQuestionView directive
              return {
                regUrl: programListing.reg_url,
                regType: i18ng.t('PROGRAM_LISTING_OPTIONS.reg_type.' + programListing.reg_type),
                dateRange: dates.join(' - ')
              }
            }
          }
        },
        properties: {
          hide_label: true,
          fields: {
            reg_type: { id: 'reg_type' },
            reg_url: { id: 'reg_url', is_required: false },
            min_date: {
              id: 'reg_open_date',
              label: i18ng.t('PROGRAM_LISTING_FORMS.LABELS.reg_open_date_time'),
              is_required: false,
              max: withinAYear.format('YYYY-MM-DD')
            },
            max_date: {
              id: 'reg_close_date',
              label: i18ng.t('PROGRAM_LISTING_FORMS.LABELS.reg_close_date_time'),
              is_required: false
            },
            min_time: {
              id: 'reg_open_time',
              is_required: false,
              label: i18ng.t('PROGRAM_LISTING_FORMS.LABELS.reg_open_time')
            },
            max_time: {
              id: 'reg_close_time',
              is_required: false,
              label: i18ng.t('PROGRAM_LISTING_FORMS.LABELS.reg_close_time')
            },
            min_date_time: {
              id: 'reg_open_date_time',
              validation: 'registration_date_time',
              validation_message: i18ng.t('PROGRAM_LISTING_ERRORS.reg_dates_invalid')
            },
            max_date_time: {
              id: 'reg_close_date_time',
              validation: 'registration_date_time',
              validation_message: i18ng.t('PROGRAM_LISTING_ERRORS.reg_dates_invalid')
            }
          },
          flatten_field_data: true
        }
      },
      address: addressField,
      eligibility_requirements: { type: 'long_text' },
      location_name: shortTextField,
      reg_url: linkField,         // @todo make this fancy SE reg picker question
      reg_status: pulldownField,
      phone: phoneField,
      email: shortTextField,
      contact_email: shortTextField,
      contact_phone: phoneField,
      contact_role: shortTextField, // @todo make pulldown
      contact_first_name: shortTextField,
      contact_last_name: shortTextField,
      facebook: socialMediaField,
      twitter:  socialMediaField,
      instagram:  socialMediaField,
      youtube:  socialMediaField,
      searchable: {
        type: 'single_checkbox',
        answer: function(programListing) {
          var searchableStr = programListing.searchable ? 'true' : 'false'
          return {
            value: programListing.searchable,
            label: function() {
              return i18ng.t('PROGRAM_LISTING_FORMS.LABELS.searchable_' + searchableStr)
            }
          }
        }
      }
    }

    //--------------------------------
    // gets element by id, assigns label and options (if sent)
    function getElem(elemId, attrs) {
      var elem = _.clone(elems[elemId])
      elem.id = elemId
      elem.label = i18ng.t('PROGRAM_LISTING_FORMS.LABELS.' + elemId)
      if (attrs && _.isObject(attrs)) _.assign(elem, attrs)

      return elem
    }

    //--------------------------------
    // returns "Add Program Listing" form
    //--------------------------------
    function getAddForm(programListing, options) {
      if (!programListing.genders) programListing.genders = DEFAULT_GENDERS
      var formDefinition = {
        id: 'program_listing_add_form',
        name: i18ng.t('PROGRAM_LISTING_FORMS.TITLES.add_form'),
        model: programListing || {}
      }
      var registration = getElem('registration', { required: false, options: options.registration_types })
      registration.properties.timezone = getTimezone(programListing)
      var formElems = [
        getElem('name',              { required: true, properties: { max: 50 } }),
        getElem('program_listing_type',      { required: true, options: options.program_listing_types, properties: { placeholder: ' ' } }),
        getElem('sports',            { required: true, options: options.sports }),
        getElem('genders',           { required: true, options: options.genders }),
        registration,
        getElem('price_range',       { options: options.pricings, properties: { max: 10, auto_format: 'currency' } }),
        getElem('date_range')
      ]

      return new rfFormFacade.Form(formDefinition, formElems)
    }

    //--------------------------------
    // returns "Program Information" Form
    //--------------------------------
    function getInfoForm(programListing, options) {
      if (!programListing.genders) programListing.genders = ['coed'] // Default Value
      var formDefinition = {
        id: 'program_listing_info_form',
        name: i18ng.t('PROGRAM_LISTING_FORMS.TITLES.info_form'),
        name2: i18ng.t('PROGRAM_LISTING_FORMS.TITLES.last_updated', {
          date: moment.tz(programListing.updated_at, (programListing.timezone || DEFAULT_TIMEZONE)).format('MMM D, YYYY (h:mm a z)')
        }),
        model: programListing
      }

      var formElems = [
        getElem('name',               { column: 0, required: true, properties: { max: 50 } }),
        getElem('description',        { column: 0, properties: { max: 1000, encodeHtml: true } }),
        getElem('age_range',          { column: 0 }),
        getElem('sports',             { column: 0, required: true, options: options.sports, properties: { min: 1 } }),
        getElem('eligibility_requirements', { column: 0, properties: { max: 1000 } }),
        getElem('image',              { column: 1 }),
        getElem('program_listing_type',       { column: 1, required: true, options: options.program_listing_types }),
        getElem('competition_level',  { column: 1, options: options.competition_levels }),
        getElem('price_range',        { column: 1, options: options.pricings, properties: { max: 10, auto_format: 'currency' } }),
        getElem('genders',            { column: 1, required: true, options: options.genders })
      ]

      return new rfFormFacade.Form(formDefinition, formElems)
    }

    //--------------------------------
    // returns "Dates and Times" Form
    //--------------------------------
    function getDatesForm(programListing, options) {
      var formDefinition = {
        id: 'program_listing_dates_form',
        name: i18ng.t('PROGRAM_LISTING_FORMS.TITLES.dates_form'),
        model: programListing
      }

      var formElems = [
        getElem('date_range',   { column: 0 }),
        getElem('days_of_week', { column: 0, options: options.days_of_weeks }),
        getElem('time_of_day',  { column: 1 }),
        getElem('timezone',     { column: 1 })
      ]

      return new rfFormFacade.Form(formDefinition, formElems)
    }

    //--------------------------------
    // returns "Program Location" Form
    //--------------------------------
    function getLocationForm(programListing, options) {
      var formDefinition = {
        id: 'program_listing_location_form',
        name: i18ng.t('PROGRAM_LISTING_FORMS.TITLES.location_form'),
        model: programListing
      }

      // TODO: Add toggle when address is the same as org
      var formElems = [
        getElem('address',       { column: 0 }),
        getElem('location_name', { column: 1, properties: { max: 50 } })
      ]

      return new rfFormFacade.Form(formDefinition, formElems)
    }

    //--------------------------------
    // returns "Registration" Form
    //--------------------------------
    function getRegForm(programListing, options) {
      var formDefinition = {
        id: 'program_listing_registration_form',
        name: i18ng.t('PROGRAM_LISTING_FORMS.TITLES.reg_form'),
        model: programListing
      }

      var registration = getElem('registration', { column: 0, options: options.registration_types })
      registration.properties.timezone = getTimezone(programListing)

      var formElems = [
        registration,
        getElem('reg_status', { column: 1, options: options.registration_statuses })
      ]

      return new rfFormFacade.Form(formDefinition, formElems)
    }

    //--------------------------------
    // returns "Contact" Form
    //--------------------------------
    function getContactForm(programListing, options) {
      var formDefinition = {
        id: 'program_listing_contact_form',
        name: i18ng.t('PROGRAM_LISTING_FORMS.TITLES.contact_form'),
        model: programListing
      }

      var formElems = [
        getElem('phone', {
          column: 0,
          properties: {
            max: 17
          }
        }),
        getElem('email', {
          column: 0,
          properties: {
            auto_format: 'lower',
            has_validate_email: true
          }
        }),
        getElem('contact_first_name', {
          column: 0,
          properties: {
            auto_format: 'capitalize'
          }
        }),
        getElem('contact_last_name', {
          column: 0,
          properties: {
            auto_format: 'capitalize'
          }
        }),
        getElem('contact_email', {
          column: 1,
          properties: {
            auto_format: 'lower',
            has_validate_email: true
          }
        }),
        getElem('contact_phone', {
          column: 1,
          properties: {
            max: 17
          }
        }),
        getElem('contact_role', { column: 1 })
      ]

      return new rfFormFacade.Form(formDefinition, formElems)
    }


    //--------------------------------
    // returns "Social Media" Form
    //--------------------------------
    function getSocialMediaForm(programListing, options) {
      var formDefinition = {
        id: 'program_listing_social_media_form',
        name: i18ng.t('PROGRAM_LISTING_FORMS.TITLES.social_media_form'),
        model: programListing
      }

      var formElems = [
        getElem('facebook', { column: 0 }),
        getElem('instagram', { column: 0 }),
        getElem('twitter', { column: 1 }),
        getElem('youtube', { column: 1 })
      ]

      return new rfFormFacade.Form(formDefinition, formElems)
    }

    //--------------------------------
    // returns "Display" Form
    //--------------------------------
    function getDisplayForm(programListing, options) {

      var formDefinition = {
        id: 'program_listing_display_form',
        name: null,
        model: programListing
      }

      var formElems = [
        getElem('searchable', { column: 0 })
      ]

      return new rfFormFacade.Form(formDefinition, formElems)
    }


    //--------------------------------

    function formatDate(date, tz) {
      var fmt = 'M/D/YYYY'
      return tz ? moment(date).tz(tz).format(fmt) : moment(date).format(fmt)
    }

    function formatDateTime(dt, tz) {
      var fmt = 'MMM D, YYYY, h:mma'
      if (tz) fmt += ' z'
      return tz ? moment(dt).tz(tz).format(fmt) : moment(dt).format(fmt)
    }

    function formatCurrency(currency) {
      return $filter('currency')(currency, '$', 2)
    }

    function dateAnswer(model, field) {
      return {
        value: model[field],
        label: formatDate(model[field], model.timezone)
      }
    }

    function getTimezone(programListing) {
      return programListing.timezone || currentOrg.timezone || DEFAULT_TIMEZONE
    }

    function momentTzOrNull(dateTime, timezone) {
      if (!dateTime) return null
      return moment.tz(dateTime, timezone || DEFAULT_TIMEZONE)
    }

    //--------------------------------

    return {
      addProgramListing: getAddForm,
      info: getInfoForm,
      dates: getDatesForm,
      location_info: getLocationForm,
      registration: getRegForm,
      contact: getContactForm,
      socialMedia: getSocialMediaForm,
      display: getDisplayForm
    }

  })
