angular.module('org-admin')
  .component('membershipDefinitionListView', {
    templateUrl: '/static/org/memberships/membership-definition-list-view.html',
    controllerAs: '$ctrl',
    bindings: {},
    controller: function($q, $routeParams, _, i18ng, setAs, currentOrg, filterPanelService, debounceCallback, MembershipStatistics,
      MembershipDefinition, MembershipsUtilService, EligibilityRuleSet, EligibilitySearch, renderContext, dialog, $scope,
      appPermission, pageViewHandler, Organization, SeConfirmService, Affiliation) {
      var $ctrl = this

      $ctrl.affiliateOrg = undefined    // Root Org (NGB)
      $ctrl.membershipsListTitle = i18ng.t('MEMBERSHIPS_LIST.memberships_list_title')
      $ctrl.memdefFilterParams = {}
      $ctrl.memdefActions = {}
      $ctrl.filteredMembershipDefinitions = []
      $ctrl.memdefDescriptions = {}
      $ctrl.viewRecent = MembershipsUtilService.viewRecent
      $ctrl.membershipUrl = MembershipDefinition.membershipUrl
      $ctrl.memberCounts = {}
      $ctrl.viewEligibility = viewEligibility
      $ctrl.shouldShowEligibility = shouldShowEligibility
      $ctrl.memDefTags = {}
      $ctrl.affiliations = []
      $ctrl.parentOrg = undefined
      $ctrl.showDescription = showDescription
      $ctrl.shareMemberships = shareMemberships

      $ctrl.$onChanges = function(changes) {
        $ctrl.loaded = false

        MembershipsUtilService.loadAffiliatedMemberships(currentOrg.id, parseInt($routeParams.affiliateOrgId), false, true, true)
          .then(filterMembershipDefinitions)
          .then(getMemberCounts)
          .then(initPublicClubAssignment)
          .then(getAffiliateOrg)
          .then(memDefCredDefSuccess)
          .then(getEligibility)
          .then(buildMemdefActions)
          .then(buildTags)
      }

      $scope.$on('$routeChangeSuccess', function($event, next, current) {
        $ctrl.filteredMembershipDefinitions = []
        $ctrl.$onChanges()
      })

      function getAffiliateOrg() {
        return $q(function(resolve) {
          Organization
            .findPublic($routeParams.affiliateOrgId)
            .then(function(affiliateOrg) {
              $ctrl.affiliateOrg = affiliateOrg
              resolve(affiliateOrg)
            })
        })
      }

      function initPublicClubAssignment() {
        $ctrl.affiliations = []
        return Affiliation.findAll({ parent_organization_id: currentOrg.id, credential_definition_organization_id: $routeParams.affiliateOrgId  })
          .then(function(results) {
            if (results.length) return
            return getAffiliations(currentOrg.id)
              .then(function() {
                let parentOrgId = null
                if ($ctrl.affiliations.length > 1) {
                  $ctrl.affiliations.reverse()
                  parentOrgId = $ctrl.affiliations[0].child_organization_id
                }
                else {
                  parentOrgId = $ctrl.affiliations[0]?.parent_organization_id
                }
                return Organization.findPublic(parentOrgId)
                  .then(function(parentOrg) {
                    $ctrl.parentOrg = parentOrg
                  })
              })
          })
          .catch(function(err) { })
      }

      function getAffiliations(workingOrgId) {
        return Affiliation.findAll({ child_organization_id: workingOrgId, credential_definition_organization_id: $routeParams.affiliateOrgId })
          .then(function(results) {
            const result = results[0]
            if (result) {
              $ctrl.affiliations.push(result)
              if (result.parent_organization_id) {
                return getAffiliations(result.parent_organization_id)
              }
            }
            return
          })
          .catch(function(err) { })
      }

      function memDefCredDefSuccess(affiliateOrg) {
        _.forEach($ctrl.filteredMembershipDefinitions, function(memdef) {
          $ctrl.memdefDescriptions[memdef.id] = []
          var validityLabel = MembershipsUtilService.memdefValidityLabel(memdef, affiliateOrg)
          if (validityLabel) $ctrl.memdefDescriptions[memdef.id].push(validityLabel)
          $ctrl.memdefDescriptions[memdef.id].push(MembershipsUtilService.memdefTotalMembers($ctrl.memberCounts[memdef.id]))
          $ctrl.memdefFilterParams[memdef.id] = buildFilterParams(memdef.id)
          memdef.tagList = _.sortByAll(memdef.tags, ['tag_option_type', 'name']).map(function(tag) { return tag.name }).join(' • ')
        })
      }

      function filterMembershipDefinitions(data) {
        return $q(function(resolve) {
          _.forEach(data, function(memdef) {
            // Only include mem defs that are currently published or where published in the past
            if (!memdef.isArchived && !memdef.isScheduled && !memdef.isUnscheduled) $ctrl.filteredMembershipDefinitions.push(memdef)
          })
          resolve()
        })
      }

      function buildFilterParams(memdefId) {
        return filterPanelService.serializeFiltersAsParam([{
          source: 'memberships',
          field: 'membership_definition_id',
          logic: 'equal',
          value: memdefId
        }])
      }

      function getEligibility() {
        var memdefIds = _.map($ctrl.filteredMembershipDefinitions, 'id')
        if (memdefIds.length === 0) {
          $ctrl.loaded = true
          return
        }
        return EligibilityRuleSet.findAll({
          membership_definition_id: memdefIds.join(','),
          boss_organization_id: currentOrg.id,
          with_affiliated: true,
          per_page: 100
        })
          .then(processEligibility)
          .catch(function() {
            // @todo handle this error
            $ctrl.ruleSets = []
            processAggs({})
          })
      }

      function findRuleSetByMemdefId(memdefId) {
        return _.find($ctrl.ruleSets, {
          eligibility_rules: [{
            originator_type: 'membership_definition',
            originator_id: memdefId
          }]
        })
      }

      function processEligibility(ruleSets) {
        $ctrl.ruleSets = ruleSets
        var ruleSetIds = _.map(ruleSets, 'id')
        return EligibilitySearch
          .aggregate(null, { data: { eligibility_rule_set_ids: ruleSetIds, affiliation_path: [{ organization_id: currentOrg.id }] } })
          .then(processAggs)
      }

      function processAggs(aggs) {
        var memdefIds = _.map($ctrl.filteredMembershipDefinitions, 'id')
        $ctrl.ruleSetsByMemdef = {}
        _.forEach(memdefIds, function(memdefId) {
          var ruleSet = findRuleSetByMemdefId(memdefId)

          if (!ruleSet || !aggs[ruleSet.id]) {
            // Hide eligibility data, aggregations are missing
            ruleSet = null
          }
          else {
            ruleSet = EligibilityRuleSet.addAggregations(ruleSet, aggs)
            ruleSet = EligibilityRuleSet.calculateEligibilityVisibility(ruleSet)
          }

          $ctrl.ruleSetsByMemdef[memdefId] = ruleSet
        })
        $ctrl.loaded = true
        return $q.resolve()
      }

      function viewEligibility(memdef, ineligible) {
        var ruleSet = $ctrl.ruleSetsByMemdef[memdef.id]
        if (!ruleSet || !ruleSet.id) return
        var params = { eligibilityRuleSetId: ruleSet.id, status: 'active' }
        if (ineligible) params.statuses = 'ineligible:true'
        if (ruleSet.organization_id !== currentOrg.id) {
          params.affiliateOrgId = ruleSet.boss_organization_id
          renderContext.goto('app.billing.affiliate.eligibility', params)
        }
        else {
          renderContext.goto('app.billing.eligibility', params)
        }
      }

      function getMemberCounts() {
        return $q(function(resolve) {
          MembershipStatistics.affiliatedMembershipsByMemDef(currentOrg.id)
            .then(function(results) {
              _.forEach($ctrl.filteredMembershipDefinitions, function(memdef) {
                var matches = _.filter(results, function(result) { return result.membership_definition_id === memdef.id })
                $ctrl.memberCounts[memdef.id] = matches.length ? matches[0].membership_count : 0
              })
              resolve()
            })
        })
      }

      function shouldShowEligibility(ruleSet) {
        return _.filter(ruleSet.eligibility_rules, function(rule) { return rule.originator_type !== 'membership_definition' }).length > 0
      }

      function buildMemdefActions() {
        $ctrl.showClubAssignmentCard = _.some($ctrl.filteredMembershipDefinitions, 'can_invite')
        _.each($ctrl.filteredMembershipDefinitions, buildActionLinks)
      }

      function buildActionLinks(memdef) {
        var actions = $ctrl.memdefActions[memdef.id] = []
        var ruleSet = $ctrl.ruleSetsByMemdef[memdef.id] || {}
        var memberCount = $ctrl.memberCounts[memdef.id]

        if (ruleSet.shouldShowEligibility && appPermission.memberships.viewEligibility) {
          actions.push({
            text: i18ng.t('MEMBERSHIPS_LIST.view_ineligible') + ' (' + ruleSet.totals.not_eligible + ')',
            action: memdefAction.bind(null, 'ViewIneligibleTextLink.Click', function() {
              $ctrl.viewEligibility(memdef, true)
            })
          })
          actions.push({
            text: i18ng.t('MEMBERSHIPS_LIST.view_eligibility') + ' (' + ruleSet.totals.total + ')',
            action: memdefAction.bind(null, 'ViewAllEligibilityTextLink.Click', function() {
              $ctrl.viewEligibility(memdef, false)
            })
          })
        }

        if (memberCount > 0 && appPermission.profiles.visible) {
          actions.push({
            text: i18ng.t('MEMBERSHIPS_LIST.view_members') + ' (' + memberCount + ')',
            action: memdefAction.bind(null, 'ViewOnly.ViewAllMembersTextLink.Click', function() {
              $ctrl.viewRecent(memdef, $ctrl.memdefFilterParams[memdef.id])
            })
          })
        }
      }

      function memdefAction(passedAction, action) {
        pageViewHandler.fireEvent(passedAction, 8)
        action()
      }

      function buildTags() {
        _.each($ctrl.filteredMembershipDefinitions, function(memdef) {
          if (memdef.membership_definitions_credential_definitions.length && !memdef.can_affiliate_obo) {
            $ctrl.memDefTags[memdef.id] = [{ color: 'yellow', value: i18ng.t('CLUB_ASSIGNMENT_LOCK.club_assignment_locked') }]
          }
        })
      }

      function showDescription(event, memdef) {
        if (event.target.localName === 'a') {
          SeConfirmService.confirm({
            message: memdef.description,
            confirm: i18ng.t('MEMBERSHIPS_LIST.ok')
          })
        }
      }

      function shareMemberships() {
        var hasMemDefFromDirectParentOrg = _.find($ctrl.filteredMembershipDefinitions, function(md) {
          return md.boss_organization_id === $ctrl.parentOrg.id && md.tags.length && md.can_direct
        })
        var slug = hasMemDefFromDirectParentOrg ? $ctrl.parentOrg.slug : $ctrl.affiliateOrg.slug

        pageViewHandler.fireEvent('GenerateLinkClick', 8)
        dialog.confirm({
          component: 'membership-definition-share',
          scope: $scope,
          attrs: {
            url: $ctrl.membershipUrl.replace('buy', 'org') + `${slug}/affiliation/${currentOrg.slug}`
          }
        }).then(function() { pageViewHandler.fireEvent('GenerateLinkClick.Copy', 8) })
      }
    }
  })
