angular.module('org-admin')
  .component('contactsRoleRemove', {
    bindings: {
      persona: '<',
      roleAssignment: '<',
      cancel: '<',
      confirm: '<'
    },
    templateUrl: '/static/org/contacts/contacts-role-remove.html',
    controller: function(appPermission, RoleLabel, setWhile, showError) {
      if (!appPermission.contactRoles.visible) return showError('404')

      var ctrl = this
      ctrl.roleName = RoleLabel.transformRoleAssignment(ctrl.roleAssignment)

      ctrl.removeRole = setWhile(ctrl, 'removing', function() {
        ctrl.roleAssignment.persona_id = ctrl.persona.id
        return ctrl.roleAssignment.DSDestroy()
          .then(ctrl.confirm)
      })
    }
  })
