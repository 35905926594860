/*
Datepicker directive

Usage:

  The datepicker-calendar directive must be included on the page near
  the bottom of the DOM:

    <sn-datepicker-calendar></sn-datepicker-calendar>

  The sn-datepicker directive should be used whereever a datepicker input
  should be displayed:

    <span sn-datepicker-field>
      <input sn-datepicker="{ format: 'MM/DD/YYYY', min?: minimumDate, max?: maximumDate }" type="text" ng-model="ctrl.eventDate" />
      <label sn-datepicker-toggle>icon</label>
    </span>

  If a parent element with sn-datepicker-field is present, it will be used to position the calendar popover.
  If an element with sn-datepicker-toggle is present inside the parent, it will be used for toggling the popover.
  The sn-datepicker input will be used for positioning and/or toggling if either is not present.

  ng-model attribute:
    ng-model is required and will store dates in YYYY-MM-DD format

*/

(function() {
  'use strict'

  // state of the state
  var refocusing = false

  // Returns an array of arrays for the given month.
  // Each array represents a week and contains seven days.
  function calendarDays(year, month, options) {
    var current = moment({ 'year': year, 'month': month })
    var previous = moment({ 'year': year, 'month': month }).subtract(1, 'month')
    var now = moment()
    var thisMonth = month == now.month() && year == now.year()

    var i, days = [[]]
    // previous month days
    if (current.startOf('month').day() > 0) {
      for (i = previous.daysInMonth() + 1 - current.startOf('month').day(); i <= previous.daysInMonth(); i++) {
        days[0].push({ day: i, disabled: true })
      }
    }
    // month days
    for (i = 1; i <= current.daysInMonth(); i++) {
      if ((current.startOf('month').day() + i - 1) % 7 === 0) days.push([])
      var today = thisMonth && i == now.date()
      var disabled = false
      if (options && (options.min || options.max)) {
        var currentDay = current.clone().date(i)
        disabled =
          (options.min && currentDay <= options.min) ||
          (options.max && currentDay >= options.max)
      }
      days[days.length - 1].push({ day: i, today: today, disabled: disabled })
    }
    // next month days
    for (i = 1; i < 7 - (current.endOf('month').day()); i++) {
      days[days.length - 1].push({ day: i, disabled: true })
    }
    return days
  }

  function getParentField(element) {
    var parent = element.parents('[sn-datepicker-field]').first()
    return parent.length ? parent : element
  }
  function getToggle(element) {
    var toggle = getParentField(element).find('[sn-datepicker-toggle]')
    return toggle.length ? toggle : element
  }

  angular.module('pl-shared')

    .directive('snDatepicker', function(snDatepickerService, _, $timeout, i18ng) {

      var defaultDisplayFormat = 'MMM DD YYYY'
      var defaultParseFormats = ['MM D', 'MM DD', 'MM DD YY', 'MM DD YYYY', 'MMM D', 'MMM DD', 'MMM DD YYYY', 'MMMM D', 'MMMM DD', 'MMMM D YYYY', 'MM/YYYY']
      var displayFormat = i18ng.t('DATEPICKER.display_format', { defaultValue: defaultDisplayFormat })
      var customParseFormats = i18ng.t('DATEPICKER.parse_formats', { returnObjectTrees: true })
      var parseFormats = _.union(customParseFormats, defaultParseFormats)
      var serverFormat = 'YYYY-MM-DD'

      return {
        restrict: 'A',
        require: 'ngModel',
        link: function(scope, element, attrs, ngModel) {
          var options = scope.$eval(attrs.snDatepicker) || {}
          var instanceParseFormats = initParseFormats()
          scope.$watch(attrs.snDatepicker, parseOptions)

          element.addClass('datepicker')

          function initParseFormats() {
            // need to include the display format in case it's not included in the default formats
            return instanceParseFormats = options.format ? _.union([options.format], parseFormats) : parseFormats
          }

          function parseOptions(rawOptions) {
            options = rawOptions ? {
              format: rawOptions.format,
              min: rawOptions.min ? moment(rawOptions.min, [serverFormat]) : undefined,
              max: rawOptions.max ? moment(rawOptions.max, [serverFormat]) : undefined
            } : {}
            initParseFormats()
            return options
          }

          function momentDate() {
            var date = moment(snDatepickerService.date)
            return date.isValid() ? date : moment()
          }

          function setDate(date) {
            ngModel.$setTouched()
            ngModel.$setViewValue(displayFormatter(date))
            ngModel.$render()
          }

          function show() {
            if (refocusing || snDatepickerService.element) return false
            scope.$apply(function() {
              snDatepickerService.show(element, ngModel.$modelValue, setDate, options)
            })
            return true
          }

          function hide(e) {
            if (!snDatepickerService.element) return
            scope.$apply(function() {
              snDatepickerService.hide()
              e.stopImmediatePropagation()
            })
          }

          function tryHide(e) {
            setDate(ngModel.$modelValue)
            scope.$apply(snDatepickerService.tryHide)
            if (snDatepickerService.clicking) e.stopImmediatePropagation()
          }

          function next(e) {
            var units = e.shiftKey ? 'months' : 'days'
            setDate(momentDate().add(1, units))
          }

          function prev(e) {
            var units = e.shiftKey ? 'months' : 'days'
            setDate(momentDate().subtract(1, units))
          }

          function displayFormatter(date) {
            var momentDate = moment(date)
            return (momentDate.isValid() && !!date) ? momentDate.format(options.format || displayFormat) : ''
          }

          function modelParser(date) {
            snDatepickerService.date = moment(date, instanceParseFormats)
            var valid = snDatepickerService.date.isValid()
            return valid ? snDatepickerService.date.format(serverFormat) : null
          }

          ngModel.$formatters.push(displayFormatter)
          ngModel.$parsers.push(modelParser)
          ngModel.$validators.date = function(modelValue, viewValue) {
            return !modelValue || moment(modelValue).isValid() // let something else handle nulls
          }

          // Delay this because the toggle element may not exist in the DOM yet
          $timeout(function() {
            getToggle(element).on('click', show)
          }, 0, false)

          element.on('blur', tryHide)
          element.on('keydown', function(e) {
            if (e.which === 27) return hide(e)
            if (e.which === 38) return prev(e)
            if (e.which === 40) return show() || next(e)
          })
          element.on('$destroy', hide)
        }
      }
    })


    .factory('snDatepickerService', function() {
      var currentCallback = null
      var picker = {
        show: function(element, date, callback, options) {
          picker.options = options
          if (element === picker.element) return
          currentCallback = callback
          picker.element = element
          picker.date = date
        },
        hide: function() {
          picker.element = null
          picker.date = null
        },
        tryHide: function() {
          picker.wantsToHide = true
        },
        set: function(date) {
          if (date && typeof currentCallback == 'function') currentCallback(date)
        }
      }
      return picker
    })


    .directive('snDatepickerCalendar', function($window, $timeout, snDatepickerService, i18ng) {

      return {
        restrict: 'E',
        scope: {},
        templateUrl: '/static/shared/components/datepicker/datepicker-calendar.html',
        link: function(scope, element, attrs) {
          var Tether = $window.Tether
          scope.daysOfWeek = _.keys(i18ng.t('DATEPICKER.DAYS_OF_WEEK', { returnObjectTrees: true }))
          scope.today = moment()

          element.css('z-index', 9999)

          element.on('mousedown', function() { snDatepickerService.clicking = true; refocus() })
          element.on('mouseup', function() { snDatepickerService.clicking = false })

          function setScope(year, month) {
            var date = moment(snDatepickerService.date)
            scope.date = date && date.month() == month && date.year() == year ? date.date() : null
            scope.month = month
            scope.year = year
            scope.monthName = moment({ 'year': year, 'month': month }).format('MMMM')
            scope.calDays = calendarDays(year, month, snDatepickerService.options)
          }

          function hide() {
            snDatepickerService.hide()
          }

          function refocus() {
            setTimeout(function() {
              if (snDatepickerService.element) {
                refocusing = true
                snDatepickerService.element.focus()
                refocusing = false
              }
            }, 1)
          }

          scope.select = function(year, month, date, disabled) {
            if (disabled) return
            var newDate = moment({ 'year': year, 'month': month, 'day': date }).format('YYYY-MM-DD')
            snDatepickerService.set(newDate)
            hide()
          }

          scope.selectToday = function() {
            var td = scope.today
            scope.select(td.year(), td.month(), td.date())
          }

          scope.nextMonth = function(year, month) {
            var next = moment({ 'year': year, 'month': month }).add(1, 'month')
            setScope(next.year(), next.month())
          }

          scope.previousMonth = function(year, month) {
            var prev = moment({ 'year': year, 'month': month }).subtract(1, 'month')
            setScope(prev.year(), prev.month())
          }

          scope.$watch(
            function() { return snDatepickerService.wantsToHide },
            function(wantsToHide, oldVal) {
              if (wantsToHide) {
                snDatepickerService.wantsToHide = false
                if (!snDatepickerService.clicking) hide()
              }
            })

          scope.$watch(
            function() { return snDatepickerService.date },
            function(date, oldVal) {
              date = date ? moment(date) : moment()
              if (date.year() < 1000 || date.year() > 9999) date = moment(snDatepickerService.lastValidDate)
              else snDatepickerService.lastValidDate = date
              setScope(date.year(), date.month())
            })

          snDatepickerService.date = undefined

          var tetherRef
          var side = angular.element('html').hasClass('sn') ? 'left' : 'right'
          scope.$watch(
            function() { return snDatepickerService.element },
            function(target, oldVal) {
              if (!target) return scope.showing = false
              if (tetherRef) tetherRef.destroy()

              scope.showing = true

              element.hide() // hide so it doesn't show on-screen until it's positioned correctly
              tetherRef = new Tether({
                element: element[0],
                target: getParentField(target)[0],
                attachment: 'top ' + side,
                targetAttachment: 'bottom ' + side,
                constraints: [{ to: 'scrollParent', attachment: 'together' }]
              })

              $timeout(function() {
                element.css('transform', '').show() // reset position and show it now
                tetherRef.position()
              }, 0, false)
            })
        }
      }

    })

}())
