angular.module('org-admin')
  .directive('contactsExport', function() {

    var $ = angular.element

    return {
      restrict: 'A',
      scope: {},
      bindToController: {
        cancel: '<',
        confirm: '<',
        params: '<',
        filters: '<',
        columns: '<',
        filteredCount: '<',
        unfilteredCount: '<',
        possibleFilters: '<'
      },
      controllerAs: 'ctrl',
      templateUrl: '/static/org/contacts/contacts-export.html',
      controller: function(_, currentOrg, $http, ENV, $sce) {

        var ctrl = this
        ctrl.asyncExport = false
        ctrl.asyncExportThreshold = 2000
        ctrl.submitExport = submitExport
        ctrl.updateExport = updateExport
        ctrl.export = 'current'
        ctrl.exportUrl = ''
        ctrl.urlBase = ''
        ctrl.target = '_blank'
        ctrl.updateExport()

        function submitExport(exportForm) {
          if (ctrl.asyncExport) {
            exportForm.preventDefault()
            $http.post(ctrl.exportUrl, { }, { withCredentials: true })
          }
          ctrl.confirm({ totalCount: ctrl.totalCount, export: ctrl.export, asyncExport: ctrl.asyncExport })
        }

        function updateExport() {

          var params = {
            org_id: currentOrg.id
          }

          if (ctrl.params && ctrl.params.group_persona_id) {
            params.group_persona_id = ctrl.params.group_persona_id
            // Groups have a lower threshold because the filtering is slower (large array containing every persona id)
            ctrl.asyncExportThreshold = 250
          }

          ctrl.totalCount = ctrl.export === 'current' ? ctrl.filteredCount : ctrl.unfilteredCount

          if (ctrl.totalCount > ctrl.asyncExportThreshold) {
            ctrl.asyncExport = true
            ctrl.urlBase = ENV.urls.apiProxy + '/ngin/v2/contact_list/export_async?'
            ctrl.target = ''
            if (ctrl.export === 'current') {
              var stringifiedFilters = _.map(ctrl.filters, function(filter) { return JSON.stringify(filter) })
              var additionalParams = {
                search: ctrl.params.search,
                'filters[]': stringifiedFilters,
                'columns[]': ['se_profile.*.name'].concat(_.map(ctrl.columns.shown, 'key'))
              }
              _.extend(params, additionalParams)
            }
          }
          else {
            _.extend(params, _.omit(ctrl.params, 'search'))
            ctrl.urlBase = ENV.urls.apiProxy + '/ngin/v2/contact_list/export.csv?'
            ctrl.target = '_blank'
          }
          ctrl.exportUrl = ctrl.urlBase + $.param(params)
          ctrl.trustedExportUrl = $sce.trustAsResourceUrl(ctrl.exportUrl)
        }
      }
    }
  })
