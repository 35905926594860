angular.module('pl-shared')

  .factory('PersonaListenee', function(DS, $http, apiV2) {
    var PersonaListenee = DS.defineResource({
      name: 'listenees',
      endpoint: 'persona_listenees',
      cacheResponse: false,
      basePath: DS.adapters.ngin_v2.defaults.basePath,
      httpConfig: DS.adapters.ngin_v2.defaults.httpConfig,
      defaultAdapter: 'ngin_v2',
      computed: {
        is_pending: [
          'status',
          function(status) { return status === 'pending' }
        ]
      }
    })

    return PersonaListenee
  })
