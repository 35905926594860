angular.module('pl-shared')
  .run(function($window, payload) {
    $window.addEventListener('message', startAppCues)

    function startAppCues(event) {
      if (event.data === 'utd:ready') {
        window.fullSizeAnalytics.seDataLayer.appCuesInit(appCuesData())
      }
    }

    function appCuesData() {
      var provisioning = payload && payload.provisioning
      if (!provisioning) return { }

      var site = provisioning.sites && provisioning.sites.sites && provisioning.sites.sites.length && payload.provisioning.sites.sites[0]
      var status = (site && site.provisioned) || 'unprovisioned'

      return {
        siteProvisioningStatus: status
      }
    }
  })
