angular.module('org-admin')
  .component('rosterHistoryFilterChips', {
    bindings: {
      state: '=?'
    },
    templateUrl: '/static/org/roster-history/roster-history-filter-chips.html',
    controller: function($scope, RosterHistoryState) {
      this.state = this.state || RosterHistoryState
    },
  })
