angular.module('org-admin')
  .directive('chooseBankAccount', function() {
    return {
      restrict: 'A',
      scope: {},
      bindToController: {
        accounts: '=bankAccounts',
        cancel: '=',
        confirm: '=',
        dialogOptions: '='
      },
      controllerAs: 'ctrl',
      templateUrl: '/static/org/bank-account-settings/Other/choose-account.html',
      controller: function(
        _,
        $scope,
        setAs,
        BankAccount,
        currentOrg,
        setWhile,
        Alerts
      ) {
        var ctrl = this

        ctrl.currentlySelectedAccount = _.findWhere(ctrl.accounts, { selected: true })
        ctrl.selectableAccounts = _.where(BankAccount.approvedAccounts(ctrl.accounts), { selected: false })
        ctrl.displayAccountNumber = BankAccount.displayAccountNumber

        function closeWithSuccess() {
          $scope.$emit('bankAccounts:update')
          ctrl.confirm()
          Alerts.success('CHOOSE_BANK_ACCOUNT.set_account_success', {
            account_name: ctrl.selectedAccount.nickname
          })
        }

        function closeWithoutSuccess() {
          ctrl.confirm()
          Alerts.error('CHOOSE_BANK_ACCOUNT.set_account_failure', {
            account_name: ctrl.selectedAccount.nickname
          })
        }

        ctrl.submit = function() {
          return BankAccount.selectBankAccount(currentOrg.id, ctrl.selectedAccount.id)
            .then(closeWithSuccess, closeWithoutSuccess)
            .catch(setAs(ctrl, 'failure'))
        }
      }
    }
  })
