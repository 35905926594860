angular.module('org-admin')

  .directive('programListingDayOfWeekQuestion', function() {

    return {
      scope: {},
      templateUrl: '/static/org/program-listings/questions/program-listing-day-of-week-question.html',
      controllerAs: 'ctrl',
      bindToController: {
        model: '=',
        question: '=',
        answer: '='
      },
      controller: function(_, $scope) {
        var ctrl = this

        ctrl.model.days_of_week = ctrl.answer ? _.clone(ctrl.answer.value.days_of_week) : [] // don't use the direct reference
        ctrl.model.dow_additional_info = ctrl.answer ? ctrl.answer.value.dow_additional_info : ''
        ctrl.model.add_additional_info = ctrl.model.dow_additional_info ? true : false

        ctrl.properties = ctrl.question.properties
        ctrl.inputName = 'form_result_' + ctrl.question.id
        ctrl.availableChoices = ctrl.question.choice_elements
        ctrl.toggleCheckAdditionalInfo = function() {
          ctrl.model.dow_additional_info = ''
        }

      }
    }
  })
