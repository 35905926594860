angular.module('pl-shared')

  .service('numberQuestion', function() {

    function getStep(qe) {
      if (qe.question.question_type === 'integer') return 1
      return 1 / Math.pow(10, qe.properties.max_digits_right_of_decimal) || 'any'
    }

    return {
      scope: {},
      templateUrl: '/static/shared/components/registration/questions/number-question.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '='
      },
      // array syntax to fix minification error
      controller: ['_', '$scope', function(_, $scope) {
        var ctrl = this
        ctrl.answer = ctrl.question.answer
        ctrl.properties = ctrl.question.properties
        ctrl.model = _.isObject(ctrl.answer) ? (parseFloat(ctrl.answer.value) || '') : ''
        ctrl.inputName = 'form_result_' + ctrl.question.id
        ctrl.step = getStep(ctrl.question)

      }]
    }

  })
