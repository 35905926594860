angular.module('org-admin', [
  'org-admin-main',
  'ngRoute',
  'ngAnimate',
  'ngSanitize',
  'ngynSelect2',
  'ngImgCrop',
  'ngMessages',
  'ngCookies',
  'ngLoad',
  'dndLists',
  'i18ng',
  'js-data',
  'ngDialog',
  'pl-shared',
  'renderContext',
  'pusher-angular',
  'minicolors',
  'ngResource',
  'ui.mask',
  'ui.timepicker',
  'launch-darkly-flags',
  'ngclipboard',
  'ngQuill',
  'se.ui.accordion',
  'se.ui.actionLinks',
  'se.ui.alert',
  'se.ui.block',
  'se.ui.button',
  'se.ui.callout',
  'se.ui.card',
  'se.ui.confirm',
  'se.ui.containerCard',
  'se.ui.dateTime',
  'se.ui.detailedAvatar',
  'se.ui.dialog',
  'se.ui.dynamicTag',
  'se.ui.emptyState',
  'se.ui.icon',
  'se.ui.indicatorDot',
  'se.ui.list',
  'se.ui.leftNav',
  'se.ui.maskedInput',
  'se.ui.modal',
  'se.ui.menu',
  'se.ui.orgAvatar',
  'se.ui.pagination',
  'se.ui.personaAvatar',
  'se.ui.popover',
  'se.ui.popoverCard',
  'se.ui.progressBar',
  'se.ui.sidePanel',
  'se.ui.tag',
  'se.ui.tagGroup',
  'se.ui.spinner',
  'se.ui.imagePreview',
  'se.ui.tooltip'
])
  .run(function(OrgCurrentUserService, payload, showError, currentUser, currentOrg, SEAnalyticsService, launchDarklyFlags) {

    if (currentUser.username !== 'testuser') { // Do not fire requests to mParticle if in test mode
      var intercomPlat = { intercom: { renderIntercom: true } }
      var currentPlat = {
        currentEnv: payload.env,
        platProp: 'sn_frontend',
        platformSubSec: 'HQ',
        orgId: currentOrg.id
      }
      window.postMessage({ type: 'SnFAnalyticsON', currentPlat: currentPlat  })
      window.dataLayer.push(intercomPlat) // Not really a way around this...
    }
  })
  .config(function($httpProvider) {
    $httpProvider.interceptors.push('globalSessionInterceptor')
  })
