angular.module('org-admin')
  .directive('passportImageUpload', function() {
    return {
      scope: {},
      require: '^^form',
      bindToController: {
        fieldName: '@',
        documentImage: '='
      },
      templateUrl: '/static/org/processor-settings/GBR/passport-image-upload.html',
      controllerAs: 'ctrl',

      link: function($scope, $element, $attrs, processorSettingsForm) {
        $scope.ctrl.processorSettingsForm = processorSettingsForm
      },

      controller: function(i18ng, fileHelper, $scope) {

        var ctrl = this

        ctrl.removeImage = function() {
          ctrl.documentImage = null
          ctrl.processorSettingsForm.$setDirty()
        }


        $scope.$watchCollection('ctrl.documentImage', function() {
          var image = ctrl.documentImage || {}
          if (image.data) image.url = fileHelper.base64Data(image)
        })


      }
    }
  })
