angular.module('org-admin')
  .component('playerTransferDetails', {
    bindings: {
      player: '<',
      seasonTeam: '<',
      cancel: '<',
      confirm: '<'
    },
    templateUrl: '/static/org/roster-management/player-transfer-details.html',
    controller: function(currentOrg, setAs, setWhile) {

      var ctrl = this

      ctrl.details = {
        roster_player: {
          inactive_info: {
            date_deactivated: moment().format('MM/DD/YYYY')
          }
        }
      }

      ctrl.submit = function() {
        // sn-datepicker hardcodes serverFormat, re-format it here
        var info = ctrl.details.roster_player.inactive_info
        info.date_deactivated = info.date_deactivated.replace(/(\d{4})-(\d{2})-(\d{2})/, '$2/$3/$1')
        ctrl.confirm(ctrl.details)
      }

    }
  })
