angular.module('pl-shared')

  .directive('pulldownWithOtherQuestion', function() {

    return {
      scope: {},
      templateUrl: '/static/shared/components/regform/questions/pulldown-with-other-question.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '='
      },
      controller: function(_, $scope, $timeout, $element) {
        var ctrl = this
        var otherValue

        ctrl.properties = ctrl.question.properties
        ctrl.model = _.isObject(ctrl.answer) ? ctrl.answer.value : null
        ctrl.inputName = 'form_result_' + ctrl.question.id
        ctrl.availableChoices = ctrl.question.availableChoiceElements({ include: [].concat(ctrl.model || []) })

        otherValue = ctrl.properties.other_value || null

        if (ctrl.properties.has_validate_password) ctrl.pattern = new RegExp('^' + _.escapeRegExp(ctrl.properties.password_value) + '$')
        else if (ctrl.properties.has_validate_phone) ctrl.pattern = /^([-+/() 0-9]{5,})$/

        ctrl.selectOptions = {
          placeholder: ctrl.question.choicePlaceholder,
          allowClear: true
        }

        ctrl.otherLabel = ctrl.properties.other_label || ''

        ctrl.otherSelected = function() {
          if (otherValue) return ctrl.model.pulldown === otherValue
          return false
        }

      }
    }

  })
