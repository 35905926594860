angular.module('org-admin')
  .directive('unauthorized', function() {
    return {
      restrict: 'A',
      scope: {},
      templateUrl: '/static/org/error/unauthorized.html',
      controller: function($scope, OAuthState, ENV) {
        $scope.clearSession = function() {
          OAuthState.initiateLogin('org')
        }
      }
    }
  })
