angular.module('org-admin')
  .service('FreemiumOrgService', function(currentOrg) {
    return {
      orgIsFreemium: orgIsFreemium
    }
    // NOTE: Using the org status to determine if an org/customer is Free or Premium is
    // a temporary solution, until we find a better way to derermine this differentiation. At
    // that point this service can likely be removed entirely, but for now it allows a reusable
    // way for multiple components to show/hide UI for our "Free" orgs vs the "Premium" ones.
    function orgIsFreemium() {
      return currentOrg.status === 'claimed' || currentOrg.status === 'churned'
    }
  })
