angular.module('pl-shared')
  .factory('EligibilityCredentialSearch', function($http, DS) {
    var EligibilityCredentialSearch = {}
    var apiUrl = DS.adapters.se_api.defaults.basePath
    var opts = { withCredentials: true }

    function extractResponseData(response) {
      return (response.data || []).result || []
    }

    EligibilityCredentialSearch.filter = function(data) {
      return $http.post(apiUrl + '/v3/eligibility/credentials/filter', data, opts)
        .then(extractResponseData)
    }

    return EligibilityCredentialSearch
  })
