angular.module('org-admin')
  .directive('noPurchaseableMemberships', function() {
    return {
      restrict: 'A',
      scope: {},
      bindToController: {
        confirm: '<',
      },
      controllerAs: '$ctrl',
      templateUrl: '/static/org/contacts/no-purchaseable-memberships.html',
      controller: function() {}
    }
  })
