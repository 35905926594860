angular.module('pl-shared')
  .directive('snChange', function() {
    return {
      restrict: 'A',
      scope: {
        snChange: '&'
      },
      controller: function($scope, $element) {
        $element.bind('change', function() {
          var exp = $scope.snChange()
          var expIsFn = typeof exp === 'function'
          return expIsFn ? exp.apply($scope.$parent, arguments) : exp
        })
      }
    }
  })
