angular.module('org-admin')
  .directive('squadLocker', function() {
    return {
      scope: {},
      bindToController: {},
      templateUrl: '/static/org/squad-locker/squad-locker.html',
      controllerAs: 'ctrl',
      controller: function(Alerts, currentOrg, dialog, featureToggles, fileHelper,
        i18ng, Organization, pollUntilResolved, ProcessorSettings,
        setAs, setWhile, showError, SNAP, SquadLockerStores, $q,
        $scope, $timeout, _) {
        if (!featureToggles.squadlocker_org_integration) return showError('404')

        var ctrl = this
        var originalLogo
        ctrl.logo = {}
        ctrl.type_boss_org = 'BossOrganization'
        ctrl.org = _.clone(currentOrg)
        ctrl.color = ctrl.org.color || '#FFFFFF'
        ctrl.editLogo = editLogo
        ctrl.logoGetterSetter = logoGetterSetter
        ctrl.fileHelper = fileHelper
        ctrl.launchStore = launchStore
        ctrl.squadlockerLogoPath = SNAP.assetPath + '/images/squadlocker-logo.svg'
        ctrl.onDelete = function() {
          delete ctrl.squadLockerStore
        }

        setWhile(ctrl, 'loading', function() {
          return findStores()
            .then(function(stores) {
              return _.last(_.filter(stores, 'squad_locker_store_id'))
            })
            .then(setAs(ctrl, 'squadLockerStore'))
            .then(function(store) {
              if (!store || store.status === 'deleted') setWhile(ctrl, 'loadingLogo', getLogo)()
            })
        })()

        function findStores() {
          return SquadLockerStores.findAll({
            resource_type: ctrl.type_boss_org,
            resource_id: ctrl.org.id
          }, { bypassCache: true })
        }

        function logoGetterSetter() {
          if (arguments.length) return // we don't ever expect the setter to run
          var logo = ctrl.logo || {}
          var res = logo.data || !logo.is_default ? logo.url : null
          return res
        }

        function getLogo() {
          setImage(ctrl.org)
        }

        function setImage(org) {
          if (org.vector_logo_url) vectorWrapImage(org.vector_logo_url)
          else if (org.logo) ctrl.logo = originalLogo = _.clone(org.logo)
        }

        function vectorWrapImage(path) {
          var url = originalLogo = _.clone(path)
          ctrl.logo = ctrl.fileHelper.parseUrl(url)
          detectEPS()
        }

        function detectEPS() {
          if (ctrl.logo) ctrl.logo.isEPS = _.includes(ctrl.logo.url, '.eps')
        }

        function editLogo() {
          var model = ctrl.logo && !ctrl.logo.is_default ? ctrl.logo : {}
          dialog.confirm({
            directive: 'file-upload-modal',
            scope: $scope,
            attrs: {
              fileUploadOptions: {
                accept: ['svg', 'eps', 'png', 'jpg'],
                allowCrop: false,
                showFilename: true,
                showSupports: true,
                showSizeLimit: false,
                titleText: i18ng.t('SQUAD_LOCKER.LABELS.upload_your_logo'),
                uploadLinkText: i18ng.t('SQUAD_LOCKER.LABELS.upload_a_logo'),
                clearLinkText: i18ng.t('SQUAD_LOCKER.LABELS.clear_logo'),
                save: saveLogo
              },
              model: model
            }
          })
        }

        function isVectorImage(image) {
          return _.some(fileHelper.vectorTypes, function(el) {
            return _.includes(image, el)
          })
        }

        function saveLogo(imageData) {
          var logo = _.clone(imageData)
          var model = { vector_logo: logo }
          return Organization.saveAndSyncLocation(model, currentOrg).then(logoHandler)
        }

        function logoHandler(org) {
          setImage(org)
          Alerts.success(i18ng.t('SQUAD_LOCKER.LABELS.logo_was_updated'))
        }

        function saveColor() {
          return Organization.saveAndSyncLocation({ color: _.clone(ctrl.color) }, currentOrg)
        }

        function launchStore() {
          return $q.all([
            saveColor()
          ])
            .then(setWhile(ctrl, 'creating', createStore))
        }

        function createStore() {
          return SquadLockerStores.create({ resource_type: ctrl.type_boss_org, resource_id: ctrl.org.id })
            .then(checkForCreatedStore)
            .then(showComplete)
            .then(setAs(ctrl, 'squadLockerStore'))
            .then(setAs(ctrl, 'showNewStoreModal', true))
            .catch(setAs(ctrl, 'createError'))
        }

        function checkForCreatedStore(newStore) {
          return pollUntilResolved({
            success: 'squad_locker_store_id',
            limit: 15,
            limitMsg: 'SQUAD_LOCKER.ERRORS.refresh_limit_exceeded',
            fetch: function() {
              return findStores().then(function(stores) {
                return _.find(stores, { id: newStore.id })
              })
            }
          })
        }

        function showComplete(store) {
          return setWhile(ctrl, 'showComplete', function() {
            return $q(function(resolve, reject) {
              $timeout(resolve, 1000, true, store)
            })
          })()
        }
      }
    }
  })
