angular.module('pl-shared')

  .directive('rfForm', function() {

    return {
      scope: {},
      bindToController: {
        sync: '=',
        forms: '=',
        formActions: '<',
        onSubmit: '=',
        onEdit: '=?',
        onCancel: '=?',
        readOnly: '=?',
        editMode: '=?',
        organizationName: '@?'
      },
      templateUrl: '/static/shared/components/regform/rf-form.html',
      controllerAs: 'ctrl',
      controller: function(_, $scope, pageViewHandler) {

        var ctrl = this
        $scope.$scope = $scope
        $scope.$watch('ctrl.sync', syncModelData)
        $scope.$watch('ctrl.readOnly', setQuestionsReadOnly)

        function setQuestionsReadOnly() {
          withAllQuestionElements(function(q) {
            if (ctrl.readOnly) {
              if (q._isEditableOrig === undefined) q._isEditableOrig = q.isEditable
              q.isEditable = false
            }
            else if (q._isEditableOrig !== undefined) {
              q.isEditable = q._isEditableOrig
            }
          })
        }

        ctrl.editForm = editForm
        ctrl.submitForm = submitForm
        ctrl.cancelForm = cancelForm
        ctrl.syncModelData = syncModelData

        function editForm() {
          if (ctrl.onEdit) return ctrl.onEdit(ctrl)
          ctrl.editMode = true
        }

        function submitForm(action, label) {
          if (!action) action = ctrl.onSubmit
          if (action === 'cancel') return cancelForm()
          if (label != undefined) {
            pageViewHandler.fireEvent(label, 8)
          }
          return ctrl.formController.validateAndSubmit(_submitForm(action))
        }

        function cancelForm() {
          if (ctrl.onCancel) return ctrl.onCancel(ctrl)
          ctrl.editMode = false
          // ctrl.formController.$setPristine()
        }

        function syncModelData(okToSync) {
          if (!okToSync) return
          ctrl.sync = false
          ctrl.models = {}
          ctrl.answersByQuestionId = {}
          withAllQuestionElements(function(qe) {
            ctrl.answersByQuestionId[qe.id] = qe.answer
            ctrl.models[qe.id] = qe.answer ? qe.answer.value : null
          })
          // ctrl.formController.$setPristine()
        }

        function _submitForm(callback) {
          return function() {
            var data = {}
            withAllQuestionElements(function(qe) {
              if (qe.namespace) {
                if (!data[qe.namespace]) data[qe.namespace] = {}
                data[qe.namespace][qe.id] = ctrl.models[qe.id]
              }
              else if (qe.properties.flatten_field_data) {
                _.each(qe.properties.fields, function(field, key) {
                  var fieldId = field.id || key
                  data[fieldId] = ctrl.models[qe.id][key]
                })
              }
              else data[qe.id] = ctrl.models[qe.id]
            })
            return callback(data, ctrl)
          }
        }

        function withAllQuestionElements(fn) {
          _.each(ctrl.forms, function(form) {
            _.each(form.question_elements, fn)
          })
        }
      }
    }
  })
