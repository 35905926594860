angular.module('org-admin')
  .component('contactsRoles', {
    bindings: {
      persona: '<'
    },
    templateUrl: '/static/org/contacts/contacts-roles.html',
    controller: function(
      $q, appPermission, RoleAssignments, RoleLabel, SeasonTeams, Sports,
      ENV, currentOrg, currentUser, i18ng, setWhile, showError,
      dialog, pageViewHandler
    ) {
      if (!appPermission.contactRoles.visible) return showError('404')

      if (!currentUser.personas) currentUser.getPersonas()

      var ctrl = this
      ctrl.roleLabels = {}
      ctrl.roleCategories = [
        {
          id: 'Organization',
          name: i18ng.t('ORG_PERMISSIONS.LABELS.org_role_category_label'),
          canAdd: true,
          canEdit: false,
          hasLogo: false
        },
        {
          id: 'TeamInstance',
          name: i18ng.t('ORG_PERMISSIONS.LABELS.team_role_category_label'),
          canAdd: false,
          canEdit: true,
          hasLogo: true
        }
      ]

      ctrl.$onInit =  setWhile(ctrl, 'loadingRoles', load)

      function load() {
        var requests = {
          personaRoles: RoleAssignments.findAll({
            persona_id: ctrl.persona.id,
            principal_type: 'Persona',
            principal_id: ctrl.persona.id,
            org_id: currentOrg.id,
            collapsed: 1,
            role_type: 'CompositeRole'
          }, {
            bypassCache: true
          }),
          groupRoles: RoleAssignments.findAll({
            persona_id: ctrl.persona.id,
            principal_type: 'Group',
            resource_type: 'Organization',
            resource_id: currentOrg.id
          }, {
            bypassCache: true
          })
        }
        $q.all(requests).then(categorizeRoles)
      }

      function categorizeRoles(data) {
        var allOrgRoles = []

        if (data.groupRoles !== undefined && data.groupRoles !== null && data.groupRoles.length > 0) {
          allOrgRoles = data.personaRoles.concat(condenseToOneRole(data.groupRoles))
        }
        else {
          allOrgRoles = data.personaRoles
        }
        ctrl.roleAssignmentsByCategory = _.groupBy(allOrgRoles, 'resource_type')
        _.forEach(allOrgRoles, function(ra) {
          ctrl.roleLabels[ra.id] = RoleLabel.transformRoleAssignment(ra)
        })
        ctrl.loadTeams()
      }

      function condenseToOneRole(roles) {
        roles[0].role = i18ng.t('ORG_PERMISSIONS.LABELS.org_group_role_se_tourney_description')
        return roles[0]
      }

      ctrl.loadTeams = setWhile(ctrl, 'loadingTeams', function() {
        return $q.all(_.map(ctrl.roleAssignmentsByCategory.TeamInstance, function(roleAssignment) {
          return SeasonTeams.find(roleAssignment.resource_id)
            .then(function(resource) {
              resource.logos = [
                _.get(resource, 'thumbnails_ssl.medium_square'),
                Sports.getSportLogo(_.get(resource, 'sport_name'))
              ]
              resource.displayName = resource.name + ' ' + resource.season_name
              resource.displayLink = ENV.urls.snFrontend + '/teams/' + resource.team_id + '?season_id=' + resource.season_id
              roleAssignment.resource = resource
            })
        }))
      })

      ctrl.editRole = function(resourceType, roleAssignment) {
        dialog.confirm({
          component: 'contacts-role',
          attrs: {
            persona: ctrl.persona,
            resourceType: resourceType,
            roleAssignment: roleAssignment,
            personaRoleAssignments: ctrl.roleAssignmentsByCategory[resourceType]
          }
        })
          .then(function(assignment) {
            if (assignment.resource_type === 'Organization') {
              pageViewHandler.fireEvent('AssignOrganizationRoleModal.' + assignment.role + '.Save', 7)
            }
            ctrl.$onInit()
          })
      }

      ctrl.removeRole = function(roleAssignment) {
        dialog.confirm({
          component: 'contacts-role-remove',
          attrs: {
            persona: ctrl.persona,
            roleAssignment: roleAssignment
          }
        })
          .then(ctrl.$onInit)
      }

      ctrl.manageRole = function() {
        window.location.href = ENV.urls.sportAdmin
      }

      ctrl.canRemoveRole = function(roleAssignment) {
        return !(roleAssignment.role_key === 'orgAdmin' && currentUser.checkIfPlayerIsUser(ctrl.persona.id) ||
          ctrl.canManageRole(roleAssignment))
      }

      ctrl.canManageRole = function(roleAssignment) {
        return roleAssignment.principal_type === 'Group'
      }
    }
  })
