angular.module('pl-shared')

  .directive('pulldownQuestion', function() {

    return {
      scope: {},
      templateUrl: '/static/shared/components/registration/questions/pulldown-question.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '='
      },
      controller: function(_, $scope, $timeout, $element) {
        var ctrl = this

        ctrl.properties = ctrl.question.properties
        ctrl.model = _.isObject(ctrl.answer) ? ctrl.answer.value : null
        ctrl.inputName = 'form_result_' + ctrl.question.id
        ctrl.availableChoices = ctrl.question.availableChoiceElements({ include: [].concat(ctrl.model || []) })

        ctrl.selectOptions = {
          placeholder: ctrl.question.choicePlaceholder,
          allowClear: true
        }
      }
    }

  })
