angular.module('pl-shared')
  .factory('VerifiedInfoService', function($filter, _) {
    var VerifiedInfo = {}
    var genders = [
      { translationKey: 'female', value: 'Female' },
      { translationKey: 'male', value: 'Male' },
      { translationKey: 'unspecified', value: 'Unspecified' }
    ]
    Object.freeze(genders)

    VerifiedInfo.getGenderSelectOptions = function() {
      return genders
    }

    VerifiedInfo.getFormattedDate = function(date) {
      return $filter('date')(date, 'MMM d, yyyy')
    }

    VerifiedInfo.getFormattedGender = function(gender) {
      var keyVal = _.find(genders, { translationKey: gender })
      return keyVal ? keyVal.value : gender
    }

    VerifiedInfo.getFormattedName = function(legalInfo, nameFormat) {
      var parts = [legalInfo.first_name]

      if (nameFormat === 'fullName' && legalInfo.middle_name) {
        parts.push(legalInfo.middle_name)
      }

      if (legalInfo.suffix) {
        parts.push(legalInfo.last_name + ', ' + legalInfo.suffix)
      }
      else {
        parts.push(legalInfo.last_name)
      }

      return parts.join(' ')
    }

    return VerifiedInfo
  })
