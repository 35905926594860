angular.module('pl-shared')
  .service('MembershipDefinitionsCredentialDefinitions', function(DS, apiV2) {
    var MembershipDefinitionsCredentialDefinitions = DS.defineResource({
      name: 'membership_definitions_credential_definitions',
      endpoint: 'membership_definitions_credential_definitions',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
      actions: {
        affiliated: {
          method: 'GET',
          response: apiV2.deserialize
        }
      }
    })
    return MembershipDefinitionsCredentialDefinitions
  })
