angular.module('pl-shared')

  .service('QuestionElement', function(_, DS, directiveExists, renderContext, i18ng) {

    function isOrgAdmin() {
      return !!renderContext.get('app.billing') // This should really be `app.org` but right now all org functionality is still scoped under billing
    }

    return DS.defineResource({
      name: 'question_elements',
      basePath: DS.adapters.ngin_v2.defaults.basePath,
      httpConfig: DS.adapters.ngin_v2.defaults.httpConfig,
      defaultAdapter: 'ngin_v2',
      relations: {
        hasOne: {
          answers: {
            localField: 'answer',
            foreignKey: 'question_element_id'
          }
        },
        belongsTo: {
          forms: {
            localField: 'form',
            localKey: 'form_id'
          }
        }
      },
      computed: {
        hasDependency: ['dependents', 'dependencies', function(dependents, dependencies) {
          return _.any(dependents) || _.any(dependencies)
        }],
        choicePlaceholder: ['choice_elements', function(choice_elements) {
          return this.properties.first_choice_is_not_valid ? _.get(choice_elements, '[0].choice.name') : ''
        }],
        directiveName: ['question', function(question) {
          return _.camelCase(question.question_type) + 'Question'
        }],
        questionLabel: ['report_name', 'question', function(report_name, question) {
          return (report_name || question.report_name || '').replace(/_cp(\d+)$/, '')
        }],
        questionText: ['label', 'question', function(label, question) {
          return label || question.name
        }],
        // make this last so other computed properties are set first
        isEditable: ['editable_by_user', 'hasDependency', 'directiveName', function(editable_by_user, hasDependency, directiveName) {
          return (!!editable_by_user || isOrgAdmin()) && !hasDependency && directiveExists(directiveName)
        }],
        editableInViewMode: ['isEditable', 'question', function(isEditable, question) {
          var editInViewModeTypes = [
            'document_upload',
            'profile_photo',
            'team_roster'
          ]
          return _.contains(editInViewModeTypes, question.question_type)
        }]
      },
      methods: {
        availableChoiceElements: function(opts) {
          var question = this
          return _.filter(question.choice_elements, function(choice, i) {
            if (!i && question.properties.first_choice_is_not_valid) return false // remove placeholder option
            return choice.is_enabled || isOrgAdmin() || opts && opts.include && opts.include.indexOf(choice.id)
          })
        },
        choiceDisplay: function(choiceElement) {
          var status = choiceElement.is_enabled ? 'enabled' : 'disabled'
          return i18ng.t('ANSWER.CHOICE.display_' + status, { choice: choiceElement.choice.name })
        }
      }
    })
  })
  .run(function(QuestionElement) {})
