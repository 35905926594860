angular.module('org-admin')
  .component('payoutDetail', {
    templateUrl: '/static/org/reports/payout-detail.component.html',
    controllerAs: 'ctrl',
    controller: function(
      $filter,
      $routeParams,
      $scope,
      $window,
      currentOrg,
      PayoutsDetailReport,
      ReportsService,
      routeTitle,
      SePagination,
      setWhile,
      ENV,
      i18ng,
      PayoutSubtotalReportService
    ) {

      var ctrl = this

      ctrl.$onInit = function() {
        ctrl.balance_transactions = {}
        ctrl.params = {}
        ctrl.exportErrors = {}
        ctrl.payoutStatusTags = ReportsService.payoutStatusTags
        $scope.$watch(getPayoutReportId, function() { setWhile(ctrl, 'loading', getReport)() })
      }

      function getPayoutReportId() {
        return $routeParams.payoutId
      }

      function getDollarAmount(cents) {
        return cents / 100
      }

      ctrl.onAlertDismiss = function(event) {
        ctrl.exportErrors = {}
      }

      function exportPayoutDetailsActivityReport() {
        const params = `organization_id=${currentOrg.id}`
        $window.open(`${ENV.urls.apiProxy}/reports/payouts/${getPayoutReportId()}?format=csv&${params}`)
      }

      function exportPayoutDetailsSourcesReport() {
        const response = PayoutSubtotalReportService.exportPayoutDetailSources(currentOrg.id, getPayoutReportId())
        response.then((res) => {
          const blob = new Blob([res.data], { type: 'text/csv' })
          const exportBtn = document.createElement('a')
          exportBtn.href = window.URL.createObjectURL(blob)
          exportBtn.download = PayoutSubtotalReportService.getSourceExportFileName($filter('date')(ctrl.payoutReport.settlement_date, 'MMM d, yyyy'), currentOrg.name)
          exportBtn.click()
          exportBtn.remove()
        }).catch(error => {
          ctrl.exportErrors =  {
            message: error.message,
            hasErrors: true
          }
        })
      }

      function initSummaryAmounts() {
        ctrl.dollarAmountFees = getDollarAmount(ctrl.payoutReport.fees_total_cents)
        ctrl.dollarAmountOther = getDollarAmount(ctrl.payoutReport.other_total_cents)
        ctrl.dollarAmountPayments = getDollarAmount(ctrl.payoutReport.payments_total_cents)
        ctrl.dollarAmountRefunds = getDollarAmount(ctrl.payoutReport.refunds_total_cents)
      }

      var getReport = setWhile(ctrl, 'balance_transactions.loading', function(paginationParams) {
        return PayoutsDetailReport
          .find(getPayoutReportId(), {
            params: ctrl.sePagination.addPageParams(_.extend({}, ctrl.params, paginationParams)),
            bypassCache: true,
            cacheResponse: false, // otherwise it may paginate weirdly
            deserialize: function(resourceConfig, resp) {
              ctrl.payoutReport = _.omit(_.get(resp, 'data.result'), 'balance_transactions')
              ctrl.balance_transactions = _.get(resp, 'data.result.balance_transactions')
              ctrl.pagination = _.get(resp, 'data.metadata.pagination')
            }
          })
          .finally(function() {
            routeTitle.setParams({
              payout_date: $filter('date')(ctrl.payoutReport.settlement_date, 'MMM d, yyyy')
            })

            ctrl.currencySymbol = ctrl.payoutReport.currency_symbol
            initSummaryAmounts()
          })
      })

      ctrl.sePagination = new SePagination({
        loadDataFn: getReport,
        storageId: 'payoutDetail'
      })

      ctrl.reportExportMenuOptions = {
        sections: [{
          menuItems: [{
            text: i18ng.t('PAYOUT_DETAIL.EXPORT_MENU_ITEMS.payout_subtotal'),
            action: () => { exportPayoutDetailsSourcesReport() }
          },
          {
            text: i18ng.t('PAYOUT_DETAIL.EXPORT_MENU_ITEMS.activity'),
            action: () => { exportPayoutDetailsActivityReport() }
          },
          ],
        }],
      }
    }
  })
