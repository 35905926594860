(function(_) {
  'use strict'

  var $ = angular.element

  function getScrollParent(el, horizontal) {
    var scrollParent
    el.parentsUntil('body').each(function(i, parent) {
      if (scrollParent) return
      parent = $(parent)
      if (parent.is('[sticky-container]')) return scrollParent = parent
      var overflow = parent.css('overflow') + parent.css('overflow-' + (horizontal ? 'x' : 'y'))
      if (overflow.match(/scroll|auto/)) scrollParent = parent
    })
    if (!scrollParent) scrollParent = $(window)
    return scrollParent
  }

  function getBaseOffset() {
    var el = getBaseOffset.el = getBaseOffset.el || $('<span/>').css({ position: 'fixed', top: 0, left: 0 }).appendTo('body')
    return el.offset()
  }

  function swapNodes(a, b) {
    a = $(a)[0]
    b = $(b)[0]
    var aParent = a.parentNode
    var bParent = b.parentNode
    var aSibling = a.nextSibling === b ? a : a.nextSibling
    if (bParent) bParent.insertBefore(a, b)
    else if (aParent) aParent.removeChild(a)
    if (aParent) aParent.insertBefore(b, aSibling)
    else if (bParent) bParent.removeChild(b)
  }


  function StickyBase(scope, object) {
    this.scope = scope
    this.table = object.closest('table')
    this.container = getScrollParent(object, this instanceof StickyColumn)
    this.win = $(window)
    this.unwatchers = []
    _.bindAll(this, ['update', 'updateWidths', 'referenceWatcher', 'resizeHandler', 'resize', 'rebuild', 'destroy', 'scrollWheel'])
    this.container.on('scroll', this.update)
    this.win.on('resize', this.resizeHandler = _.debounce(this.resizeHandler))
    this.watch(_.debounce(this.referenceWatcher)) // resizes or rebuilds based on what changes
  }

  _.extend(StickyBase.prototype, {
    destroy: function() {
      this.container.off('scroll', this.update)
      this.win.off('resize', this.resizeHandler)
      _.invoke(this.unwatchers, _.call)
      this.disable()
    },

    digest: function() {
      if (this.scope.$root && !this.scope.$root.$$phase) this.scope.$digest()
    },

    resizeHandler: function() {
    // TODO: make this smarter at phone size intead of just disabling
      if (this.tableClone && window.innerWidth <= 500) return this.disable()
      if (!this.tableClone && window.innerWidth > 500) this.enable()
      this.resize()
    },

    resize: function() {
      this.update()
      this.updateWidths()
      _.defer(this.updateWidths) // fixes an issue where the header widths can be read incorrectly immediately after the enable-swap
    },

    rebuild: function() {
      this.disable()
      _.defer(function() {
        this.enable()
        this.resize()
        this.digest() // rebuild happens after digest, this is a safeguard to ensure everything updates.
      }.bind(this))
    },

    scrollWheel: function(event) {
      this.container[0].scrollLeft += event.originalEvent.deltaX
      this.container[0].scrollTop += event.originalEvent.deltaY
      event.preventDefault()
    },

    enable: function() {
      if (this.tableClone || window.innerWidth <= 500) return
      this.tableClone = this.table.clone().addClass(this.tableClass)
      this.tableClone.insertAfter(this.table)
      this.tableClone.find('[id]').removeAttr('id') // fix issues with checkboxes in cloned cells still taking focus
      this.tableClone.on('wheel', this.scrollWheel)
      this._enable()
      this.setReferenceObjects() // initialize setReferenceObjects
    },

    disable: function() {
      if (!this.tableClone) return
      this.tableClone.find('tr > *').width('') // TODO: set this differently if the cells' style width was explicitly set at enable
      this._disable()
      this.tableClone.off('wheel', this.scrollWheel)
      this.tableClone.remove()
      this.tableClone = null
    },

    referenceOffset: function() {
      var obj = this.referenceObj()
      return _.extend({ height: obj.outerHeight(), width: obj.outerWidth() }, obj.offset())
    },

    referenceWatcher: function() {
      var lastRebuildReference = this._rebuildReference
      var lastResizeReference = this._resizeReference
      this.setReferenceObjects()

      if (!angular.equals(this._rebuildReference, lastRebuildReference)) this.rebuild()
      else if (!angular.equals(this._resizeReference, lastResizeReference)) this.resize()
    },

    watch: function() { this.unwatchers.push(this.scope.$watch.apply(this.scope, arguments)) },
    watchCollection: function() { this.unwatchers.push(this.scope.$watchCollection.apply(this.scope, arguments)) },

    // Override these with specific actions
    referenceObj: function() {},
    setReferenceObjects: function() {},
    _enable: function() {},
    _disable: function() {},
    update: function() {},
    updateWidths: function() {}
  })


  function StickyHeader(scope, header, isFooter) {
    StickyBase.call(this, scope, header)
    this.header = header
    this.isFooter = isFooter
    this.selector = isFooter ? '[sticky-footer]' : '[sticky-header]'
    this.digest() // happens for overlaps due to debounced column enable, call this to init watchCollection action
  }

  StickyHeader.prototype = _.create(StickyBase.prototype, {
    constructor: StickyHeader,
    tableClass: 'sn-sticky-header',

    updateWidths: function() {
      if (!this.tableClone) return
      var copyTRs = this.header.find('tr') // now in table clone
      var origTRs = this.headerClone.find('tr') // now in original table
      var copyTDs = copyTRs.eq(0).children()
      var origTDs = origTRs.eq(0).children()

      _.each(copyTRs, function(tr, i) {
        $(tr).height(getComputedStyle(origTRs[i]).height)
      })
      _.each(copyTDs, function(td, i) {
        $(td).outerWidth(getComputedStyle(origTDs[i]).width)
      })
    },

    _enable: function() {
      this.headerClone = this.tableClone.find(this.selector)
      this.tableClone.find('tbody, thead, tfoot').not(this.headerClone).remove()
      swapNodes(this.header, this.headerClone)
    },

    _disable: function() {
      this.headerClone.replaceWith(this.header)
      this.headerClone = null
      this.header.find('tr').height('')
    },

    referenceObj: function() { return this.headerClone || this.header },

    setReferenceObjects: function() {
      var headerClone = this.referenceObj()[0] // whichever one is in the main table
      var header = this.header[0] // angular bound
      this._rebuildReference = _.toArray(header.rows[0].cells)
      this._resizeReference = _.map(headerClone.rows[0].cells, 'clientWidth')
        .concat(headerClone.clientWidth, headerClone.clientHeight) // clone is in the table
        .concat(_.values(getOffset(this.container)))
    },

    update: function() {
      if (!this.tableClone) return

      var referenceOffset = this.referenceOffset()
      var containerOffset = this.container.offset()
      var containerHeight = this.container[0].clientHeight
      var tableOffset = this.table.offset()

      var offset = referenceOffset.top - tableOffset.top
      var top = this.isFooter ?
        Math.min(containerOffset.top + containerHeight - referenceOffset.height, referenceOffset.top) :
        Math.max(containerOffset.top, 0) - offset
      var left = tableOffset.left
      var width = this.table.outerWidth()

      // CSS clipping is used to prevent headers from overflowing during horizontal scrolling.
      // We only care about clipping the left and right sides,
      // but due to limitation with clip, we must set top and bottom explicitly.
      // In order to make content such as popovers work from the header,
      // we set the top and bottom clip properties to extend to the top and bottom of the window.
      var clipLeft = containerOffset.left - left
      var clipTop = -top
      var clipRight = Math.min(this.container[0].clientWidth + clipLeft, width)
      var clipBottom = window.innerHeight - top

      this.tableClone.css({
        top: top + 'px',
        left: left + 'px',
        height: 'auto',
        width: width + 'px',
        clip: 'rect(' + clipTop + 'px ' + clipRight + 'px ' + clipBottom + 'px ' + clipLeft + 'px)'
      })
    }
  })


  function StickyColumn(scope, column) {
    StickyBase.call(this, scope, column)
    this.column = column
    this.columnSpan = this.getColumnSpan()
    this.selector = '[sticky-column]'
    _.bindAll(this, ['updateClasses'])
    this.watch(_.debounce(this.updateClasses, 10)) // can't figure out a more reliable solution for this. 0ms causes race conditions
  }

  StickyColumn.prototype = _.create(StickyBase.prototype, {
    constructor: StickyColumn,
    tableClass: 'sn-sticky-column',

    getColumnSpan: function() {
      return _.sum(_.pluck(this.column.children('col'), 'span')) || this.column[0].span
    },

    updateWidths: function() {
      if (!this.tableClone) return
      var copyTRs = this.tableClone.find('tr')
      var origTRs = this.table.find('tr')
      var copyTDs = copyTRs.eq(0).children()
      var origTDs = origTRs.eq(0).children()
      var cloneWidth = 0
      var colspan = this.columnSpan

      _.each(copyTRs, function(tr, i) {
        $(tr).height(getComputedStyle(origTRs[i]).height)
      })
      _.each(copyTDs, function(td, i) {
        var width = getComputedStyle(origTDs[i]).width
        if (i <= colspan) cloneWidth += parseFloat(width)
        $(td).outerWidth(width)
      })
      this.tableClone.width(cloneWidth)
    },

    updateClasses: function() {
      if (!this.tableClone) return
      var classes = _.pluck(this.table.prop('rows'), 'className')
      _.each(this.tableClone.prop('rows'), function(tr, i) { tr.className = classes[i] })
    },

    _enable: function() {
      this.columnSpan = this.getColumnSpan() // could change due to digests
      var columnType = this.column[0].nodeName

      this.origTDs = this.table[0].querySelectorAll('tr > *:nth-child(-n+' + this.columnSpan + ')')
      this.copyTDs = this.tableClone[0].querySelectorAll('tr > *:nth-child(-n+' + this.columnSpan + ')')

      $(this.tableClone[0].querySelectorAll('tr > *')).not(this.copyTDs).remove()
      this.tableClone.find('colgroup, col').not(function(i, el) {
        return el === this || el.parentNode === this || el === this.parentNode // remove all extraneous col+colgroup
      }.bind(this.tableClone.find(columnType).eq(this.table.find(columnType).index(this.column))[0])).remove()

      _.each(_.zip(this.origTDs, this.copyTDs), _.spread(swapNodes))

      var stickyHeader = this.tableClone.find('[sticky-header]')
      var stickyFooter = this.tableClone.find('[sticky-footer]')

      if (stickyHeader.length) this.header = new StickyHeader(this.scope, stickyHeader)
      if (stickyFooter.length) this.footer = new StickyHeader(this.scope, stickyFooter, true)
    },

    _disable: function() {
      if (this.header) this.header.destroy()
      if (this.footer) this.footer.destroy()
      this.header = this.footer = null
      if (this.origTDs) {
        var origTDs = this.origTDs
        _.each(this.copyTDs, function(copyTD, i) {
          var parent = copyTD.parentNode
          if (parent) parent.replaceChild(origTDs[i], copyTD)
          $(origTDs[i]).width('') // TODO: set this differently if the cells' style width was explicitly set at enable
        })
      }
      this.origTDs = this.copyTDs = null
    },

    referenceObj: function() { return this.table }, //this.column // IE10 does not report getBoundingClientRect correctly for colgroups. Sticky columns limited to initial columns for now.

    setReferenceObjects: function() {
      var table = this.table[0]
      var rows = _.flatten(_.map(_.map(table.tBodies, 'rows'), _.toArray))
      var cells = _.take(rows[0].cells, this.columnSpan)
      this._rebuildReference = [].concat(rows, cells)
      this._resizeReference = _.map(cells, 'clientWidth')
        .concat(table.clientHeight)
        .concat(_.values(getOffset(this.container)))
    },

    update: function() {
      if (!this.tableClone) return

      var referenceOffset = this.referenceOffset()
      var containerOffset = this.container.offset()
      var tableOffset = this.table.offset()

      var offset = referenceOffset.left - tableOffset.left
      var top = tableOffset.top
      var left = Math.max(containerOffset.left, referenceOffset.left, 0) - offset
      var height = referenceOffset.height
      var clipTop = containerOffset.top - top
      var clipBottom = Math.min(this.container[0].clientHeight + clipTop, height)

      this.tableClone.css({ top: top + 'px', left: left + 'px', height: height + 'px', clip: 'rect(' + clipTop + 'px auto ' + clipBottom + 'px auto)' })
    }
  })

  // STICKY ELEMENT

  function getOffset(el) {
    if (el[0] === window) {
      return {
        top: $('#ngin-bar').outerHeight(),
        left: 0,
        right: window.innerWidth,
        bottom: window.innerHeight
      }
    }
    else {
      var offset = el.offset()
      offset.top -= window.scrollY
      offset.left -= window.scrollX
      offset.right = offset.left + el.outerWidth()
      offset.bottom = offset.top + el.outerHeight()
      return offset
    }
  }
  function getContainer(el) {
    var container
    el.parents().each(function(i, parent) {
      if (container) return
      parent = $(parent)
      if (/block|list-item|table(-.*)?/.test(parent.css('display'))) container = parent
    })
    return container
  }
  function getAttrs(el) {
    var obj = {}
    _.each(el[0].attributes, function(attr) {
      obj[attr.name] = attr.value
    })
    return obj
  }
  // Gets the first preceding sticky header with the previous sticky level.
  function getPrevSticky(sticky) {
    var stickies = sticky.scrollParent.find('[sticky]')
    var thisIndex = stickies.indexOf(sticky.el[0])
    var prevStickies = stickies.slice(0, thisIndex).not('.' + STICKY_CLASSES.clone)
    return prevStickies.filter('[sticky="top-' + (sticky.stickyLevel - 1) + '"]').last()
  }
  var CSS_POSITION_RESET = { position: '', top: '', bottom: '', left: '', right: '', width: '', transform: '' }
  var STATIC = {}
  var ABSOLUTE = {}
  var FIXED = {}
  var STICKY_CLASSES = {
    clone: 'pl-sticky--clone',
    topOn: 'pl-sticky--top-on',
    topOff: 'pl-sticky--top-off',
    bottomOn: 'pl-sticky--bottom-on',
    bottomOff: 'pl-sticky--bottom-off'
  }
  var STICKY_ON_SELECTOR = '.' + STICKY_CLASSES.topOn + ', .' + STICKY_CLASSES.bottomOn
  var STICKY_LEVEL_RGX = /(?:top|bottom)-(\d+)/

  function StickyElement(el, attrs) {
    _.bindAll(this, ['resize', 'watch', 'update', 'destroy', 'scrollWheel'])
    this.el = el
    this.container = getContainer(el)
    if (!this.container || !this.setScrollParent()) return
    this.stickToTop = _.startsWith(attrs.sticky, 'top')
    this.stickyLevel = +(attrs.sticky.match(STICKY_LEVEL_RGX) || [attrs.sticky, '1'])[1]
    if (this.stickToTop && this.stickyLevel > 1) this.prevSticky = getPrevSticky(this)
    this.container.on('wheel', STICKY_ON_SELECTOR, this.scrollWheel)
    $(window).on('resize', this.resize)
  }

  _.extend(StickyElement.prototype, {

    setScrollParent: function() {
      var newParent = getScrollParent(this.el)
      var oldParent = this.scrollParent
      this.scrollParent = newParent

      // default position is static, will get updated in watch
      if (!oldParent || !newParent) this.positionStatic()

      // rebind events if the parent has changed
      if (newParent !== oldParent) {
        if (oldParent) oldParent.off('scroll', this.update)
        this.scrollParent.on('scroll', this.update)
      }

      return newParent
    },

    resize: function() {
      if (this.setScrollParent()) this.update()
    },

    watch: function() {
      function mapPrefix(obj, prefix) {
        return _.mapKeys(obj, function(val, key) { return prefix + '_' + key })
      }
      return _.extend(getAttrs(this.el), mapPrefix(getOffset(this.el), 'el'), this.clone ? mapPrefix(getOffset(this.clone), 'clone') : {})
    },

    update: function() {
      var sticky = this
      var el = this.clone || this.el
      var containerOffset = getOffset(this.container)
      var scrollOffset = getOffset(this.scrollParent)
      var elOffset = getOffset(el)
      var elHeight = this.el.outerHeight() // always take height of the element rendered by angular
      var elWidth = el.outerWidth()

      if (this.clone) this.clone.outerHeight(elHeight) // keep clone height in sync

      if (this.stickToTop) {
        var prevIsSticky = _.any(this.prevSticky) && this.prevSticky.hasClass(STICKY_CLASSES.topOn)
        var scrollRef = prevIsSticky ? getOffset(this.prevSticky).bottom : scrollOffset.top
        if (containerOffset.bottom - elHeight < scrollRef) {
          this.positionAbsolute(containerOffset.bottom - elOffset.top - elHeight, elWidth)
        }
        else if (elOffset.top < scrollRef) {
          this.positionFixed(scrollRef, elWidth, elOffset.left)
        }
        else {
          this.positionStatic()
        }
      }
      else {
        if (containerOffset.top + elHeight > scrollOffset.bottom) {
          this.positionAbsolute(containerOffset.top - elOffset.top, elWidth)
        }
        else if (elOffset.bottom > scrollOffset.bottom) {
          this.positionFixed(scrollOffset.bottom - elHeight, elWidth, elOffset.left)
        }
        else {
          this.positionStatic()
        }
      }
    },

    toggleClass: function(bool) {
      var onClass = this.stickToTop ? STICKY_CLASSES.topOn : STICKY_CLASSES.bottomOn
      var offClass = this.stickToTop ? STICKY_CLASSES.topOff : STICKY_CLASSES.bottomOff
      this.el.toggleClass(onClass, bool)
      this.el.toggleClass(offClass, !bool)
    },

    positionFixed: function(top, width, left) {
      var baseOffset = getBaseOffset()
      this.positionSticky(FIXED, width, {
        position: 'fixed',
        top: top - baseOffset.top,
        left: left - baseOffset.left
      })
    },

    positionAbsolute: function(top, width) {
      this.positionSticky(ABSOLUTE, width, {
        position: 'absolute',
        transform: 'translateY(' + top + 'px)'
      })
    },

    positionSticky: function(position, width, css) {
      if (this.position === STATIC) this.showClone()
      this.position = position
      this.el.css(_.defaults(css, CSS_POSITION_RESET)).outerWidth(width)
      this.toggleClass(true)
    },

    positionStatic: function() {
      if (this.position === STATIC) return // no need to reposition if already static
      this.position = STATIC
      this.el.css(CSS_POSITION_RESET)
      this.hideClone()
      this.toggleClass(false)
    },

    showClone: function() {
      var el = this.clone || this.el
      var height = this.el.outerHeight()
      this.clone = this.el
        .clone()
        .empty()
        .removeClass(_.values(STICKY_CLASSES).join(' '))
        .addClass(STICKY_CLASSES.clone)
        .insertAfter(el)
        .outerHeight(height)
    },

    hideClone: function() {
      if (this.clone) this.clone.remove()
      delete this.clone
    },

    scrollWheel: function(event) {
      this.scrollParent[0].scrollLeft += event.originalEvent.deltaX
      this.scrollParent[0].scrollTop += event.originalEvent.deltaY
      event.preventDefault()
    },

    destroy: function() {
      this.hideClone()
      this.container.off('wheel', STICKY_ON_SELECTOR, this.scrollWheel)
      this.scrollParent.off('scroll', this.update)
      $(window).off('resize', this.update)
    }

  })

  angular.module('pl-shared')

  // Controls mousewheel events within a sticky-container, to prevent unintentional back/forward navigation.
    .directive('stickyContainer', function() {
      return {
        restrict: 'A',
        link: function($scope, $el) {
          var el = $el[0]
          function cancelScroll(event) {
            var x = event.originalEvent.deltaX
            var y = event.originalEvent.deltaY
            var horizontalish = Math.abs(x) / Math.abs(y) > 1
            if (!horizontalish) return
            if (x < 0 && el.scrollLeft <= 0 ||
              x > 0 && el.scrollLeft >= el.scrollWidth - el.clientWidth) event.preventDefault()
          }
          $el.on('wheel', cancelScroll)
          $scope.$on('$destroy', function() { $el.off('wheel', cancelScroll) })
        }
      }
    })

  // Controls positioning of sticky elements within the scroll container.
    .directive('sticky', function() {
      return {
        scope: {},
        restrict: 'A',
        controller: function($scope, $element, $attrs) {
          var sticky = new StickyElement($element, $attrs)
          if (!sticky.scrollParent) return $scope.$destroy()
          $scope.$on('$destroy', sticky.destroy)
          $scope.$watchCollection(sticky.watch, sticky.update)
        }
      }
    })

  // Controls positioning of sticky headers within a sticky-container.
    .directive('stickyHeader', function() {
      return {
        restrict: 'A',
        link: function($scope, $el, $attrs) {
          if (!$el.is('thead, tbody')) return console.error('stickyHeader can only be initialized on thead or tbody')
          var stickyRef = new StickyHeader($scope, $el)
          $scope.$on('$destroy', stickyRef.destroy)
          if ($attrs.stickyHeader) _.set($scope, $attrs.stickyHeader, stickyRef)
        }
      }
    })

  // Controls positioning of sticky footers within a sticky-container.
    .directive('stickyFooter', function() {
      return {
        restrict: 'A',
        link: function($scope, $el, $attrs) {
          if (!$el.is('tfoot, tbody')) return console.error('stickyFooter can only be initialized on tfoot or tbody')
          var stickyRef = new StickyHeader($scope, $el, true)
          $scope.$on('$destroy', stickyRef.destroy)
          if ($attrs.stickyFooter) _.set($scope, $attrs.stickyFooter, stickyRef)
        }
      }
    })

  // Controls positioning of sticky columns within a sticky-container.
    .directive('stickyColumn', function() {
      return {
        restrict: 'A',
        link: function($scope, $el, $attrs) {
          if (!$el.is('colgroup, col')) return console.error('stickyColumn can only be initialized on colgroup or col')
          var stickyRef = new StickyColumn($scope, $el)
          $scope.$on('$destroy', stickyRef.destroy)
          if ($attrs.stickyColumn) _.set($scope, $attrs.stickyColumn, stickyRef)
        }
      }
    })

  // Safari jumps the sticky header after printing if resize hasn't run, so fire a resize on the first afterprint
  var $window = angular.element(window)
  $window.one('afterprint', function() {
    $window.trigger('resize')
  })

})(window._)
