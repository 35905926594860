var HQ_DASHBOARD__PROGRAM_LISTING_LIST_LIMIT = 3
var HQ_DASHBOARD__PROGRAM_LISTING_LIST_INTERVAL_DAYS = 30
var HQ_DASHBOARD__PROGRAM_LISTING_HELP_URL = 'https://b2b-sportsengine.pantheonsite.io/blog/best-practices-promoting-your-organization-on-sportsengine'

angular.module('org-admin')
  .component('hqDashboardProgramListings', {
    bindings: {
      status: '<'
    },
    templateUrl: '/static/org/hq-dashboard/hq-dashboard-program-listings.html',
    controller: function(_, currentOrg, ENV, ProgramListing, dateHelper, setAs) {

      var $ctrl = this

      $ctrl.loaded = false
      $ctrl.i18ngOpts = { program_listing_interval: HQ_DASHBOARD__PROGRAM_LISTING_LIST_INTERVAL_DAYS }
      $ctrl.addUrl = '/org/' + currentOrg.id + '/program-listings/add'
      $ctrl.helpUrl = HQ_DASHBOARD__PROGRAM_LISTING_HELP_URL
      $ctrl.orgUrl = ENV.urls.sportsengine + '/org/' + currentOrg.slug
      $ctrl.$onInit = init()

      function init() {
        return fetchProgramListings()
          .then(prepareProgramListings)
          .then(setAs($ctrl, 'programListings'))
          .then(setAs($ctrl, 'loaded', true))
      }

      function fetchProgramListings() {
        return ProgramListing.findAll({
          org_id: currentOrg.id,
          per_page: 25,
          searchable: true
        })
      }

      function prepareProgramListings(programListings) {
        return _(programListings)
          .sortByOrder(['impressions_count'], ['desc'])
          .take(HQ_DASHBOARD__PROGRAM_LISTING_LIST_LIMIT)
          .each(function(programListing) {
            programListing.tags = formatTags(programListing.program_listing_type)
            programListing.dateRange = dateHelper.shortRange(programListing.start_date, programListing.end_date)
            programListing.titleAttributes = { href: '/org/' + currentOrg.id + '/program-listings/' + programListing.id }
          })
          .value()
      }

      function formatTags(programListingType) {
        if (!programListingType) { return [] }
        var value = (programListingType.label || '').split('(')[0]
        return value ? [{ color: 'gray', value: value }] : []
      }

    }
  })
