angular.module('org-admin')
  .directive('membershipDefinitionEffectivityEdit', function() {
    return {
      restrict: 'EA',
      scope: {},
      bindToController: {
        cancel: '=',
        confirm: '=',
        membershipDefinition: '=',
        dialogOptions: '='
      },
      templateUrl: '/static/org/memberships/membership-definition-effectivity-edit.html',
      controllerAs: '$ctrl',
      controller: function(_, $scope, i18ng, currentOrg, Alerts, moment, MembershipDefinition) {
        var $ctrl = this
        var TIMEZONE = currentOrg.timezone || 'America/New_York'

        $ctrl.validators = MembershipDefinition.validators
        $ctrl.submit = submit
        $ctrl.submitting = false
        $ctrl.model = {}
        $ctrl.durationTypeOptions = [
          { value: 'years', label: i18ng.t('MEMBERSHIP_DEFINITION_FORMS.DURATION_OPTIONS.years') },
          { value: 'months', label: i18ng.t('MEMBERSHIP_DEFINITION_FORMS.DURATION_OPTIONS.months') },
          { value: 'weeks', label: i18ng.t('MEMBERSHIP_DEFINITION_FORMS.DURATION_OPTIONS.weeks') },
          { value: 'days', label: i18ng.t('MEMBERSHIP_DEFINITION_FORMS.DURATION_OPTIONS.days') },
        ]
        $ctrl.$onInit = $onInit

        function $onInit() {
          $ctrl.model = angular.copy($ctrl.membershipDefinition)
          $scope.$watch('$ctrl.model.start_date', effectiveTermedChange)
          $scope.$watch('$ctrl.model.end_date', effectiveTermedChange)
          $scope.$watch('$ctrl.model.duration_type', effectiveDurationChange)
          $scope.$watch('$ctrl.model.duration_value', effectiveDurationChange)
        }

        function submit() {
          $ctrl.submitting = true
          $ctrl.failed = false
          $ctrl.serverErrorMessages = null

          var data = {
            effective_type: $ctrl.model.effective_type,
            duration_type: null,
            duration_value: null,
            start_date: null,
            end_date: null
          }

          if (data.effective_type === 'termed') {
            data.start_date = moment.tz($ctrl.model.start_date, TIMEZONE).format()
            // Need to add 23:59:59 to end_date, since the whole day should be included
            data.end_date = moment.tz($ctrl.model.end_date, TIMEZONE).add(1, 'day').subtract(1, 'seconds').format()
          }
          else if ($ctrl.model.effective_type === 'pick_a_date') {
            data.duration_type = $ctrl.model.duration_type
            data.duration_value = $ctrl.model.duration_value
          }

          MembershipDefinition.update($ctrl.model.id, data)
            .then(updateSuccess)
            .catch(updateError)

          function updateSuccess(result) {
            $ctrl.submitting = false
            return $ctrl.confirm(result)
          }

          function updateError() {
            $ctrl.submitting = false
            $ctrl.failed = true
            $ctrl.serverErrorMessages = _.get(arguments[0], 'data.error.messages') || [i18ng.t('MEMBERSHIP_DEFINITION_FORMS.ERRORS.edit_failed')]
          }
        }

        /**
         * watches for changes on start_date, end_date and selects effective_type=termed
         */
        function effectiveTermedChange(newVal, oldVal) {
          if (newVal && (newVal !== oldVal)) $ctrl.model.effective_type = 'termed'
        }

        /**
         * watches for changes on start_date, end_date and selects effective_type=pick_a_date
         */
        function effectiveDurationChange(newVal, oldVal) {
          if (newVal && (newVal !== oldVal)) $ctrl.model.effective_type = 'pick_a_date'
        }
      }
    }
  })
