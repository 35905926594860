angular.module('org-admin')
  .component('addressForm', {
    bindings: {
      outerForm: '=',
      name: '@',
      viewMode: '<?',
      edit: '<?',
      editableField: '<?'
    },
    templateUrl: '/static/org/processor-settings/Other/address-form.html',
    controller: function(getEnum) {
      var $ctrl = this

      $ctrl.states = getEnum('STATES.US')
    }
  })
