angular.module('pl-shared')
  .component('sendOrphanInviteModal', {
    bindings: {
      cancel: '=',
      confirm: '=',
      orphanPersona: '<'
    },
    templateUrl: '/static/shared/components/personas/send-orphan-invite-modal.html',
    controller: function($rootScope, Alerts, currentOrg, i18ng, OrphanInvite, Persona) {
      var $ctrl = this

      $ctrl.sendInvitation = sendInvitation
      $ctrl.removeServerErrorMessages = removeServerErrorMessages

      $ctrl.email = _.get(_.find($ctrl.orphanPersona.email_addresses, 'is_primary', true), 'address') ||
                    _.get(_.find($ctrl.orphanPersona.email_addresses, 'address'), 'address')

      /**
       * Create new orphan invite.
       *
       * @returns {Promise.<OrphanInvite>} - A promise that is resolved with created orphan invite.
       */
      function sendInvitation() {
        $ctrl.failed = false
        $ctrl.serverErrorMessages = null

        return OrphanInvite.create({
          org_id: currentOrg.id,
          email_address: $ctrl.email,
          persona_id: $ctrl.orphanPersona.id
        })
          .then(sendInvitationSuccess, sendInvitationError)
      }

      function sendInvitationSuccess(orphanInvite) {
        return updatePersonaFields()
          .then(function() {
            $ctrl.confirm(orphanInvite)
            Alerts.success('ORPHAN_INVITES.SEND_INVITE.success')
          })
      }

      /**
       * Retrieves a persona and emit event to update it.
       *
       * @private
       * @returns {Promise.<Persona>} - A promise that is resolved with updated persona.
       */
      function updatePersonaFields() {
        return Persona.find($ctrl.orphanPersona.id, { params: { org_id: currentOrg.id }, bypassCache: true })
          .then(function(persona) {
            $rootScope.$emit('persona:update', persona)
          })
      }

      function sendInvitationError() {
        $ctrl.failed = true
        $ctrl.serverErrorMessages = _.get(arguments[0], 'data.error.messages')
      }

      function removeServerErrorMessages() {
        $ctrl.failed = false
        if ($ctrl.serverErrorMessages) {
          $ctrl.serverErrorMessages.length = 0
        }
      }
    }
  })
