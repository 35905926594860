angular.module('org-admin')

  .factory('PusherService', function(ENV, $pusher, $window) {
    var keys = {
      production: '0a234aae0d2b0b9dfc91',
      staging: '642a1b8eed3bf5178bac',
      development: '7393845394273906f579'
    }

    return $pusher(new $window.Pusher(keys[ENV.current]))
  })
