angular.module('org-admin')
  .directive('processorSettingsOther', function() {
    return {
      scope: {},
      bindToController: {},
      templateUrl: '/static/org/processor-settings/Other/processor-settings-form.html',
      controllerAs: 'ctrl',
      controller: function(
        $scope,
        $window,
        appPermission,
        ProcessorSettings,
        currentOrg,
        setAs,
        getEnum,
        i18ng,
        showError,
        moment,
        _
      ) {
        if (!appPermission.bankAccountSettings.visible) return showError('404')

        var ctrl = this
        ctrl.loading = true
        ctrl.updateSettingsLoading = false

        var processorSettings = {}
        var addressKeys = ['address_1', 'address_2', 'city', 'state', 'postal_code', 'country']

        ProcessorSettings.http('GET', currentOrg.id)
          .then(loadProcessorSettings)
          .catch(showError)
          .finally(setAs(ctrl, 'loading', false))

        ctrl.submit = function() {
          var action = (hasSettings()) ? 'PUT' : 'POST'
          finalizeProcessorSettings()
          return ProcessorSettings.http(action, currentOrg.id, ctrl.processorSettings)
            .then(
              function(success) { $window.location.reload() },
              function(err) {
                location.href = '#processor-settings-errors'
                var responseError = err.data.error || err.statusText
                ctrl.serverErrors = responseError.messages || [responseError]
              }
            )
        }

        ctrl.cancel = function() {
          ctrl.processorSettings = angular.copy(processorSettings)
          ctrl.processorSettingsForm.$setPristine()
          ctrl.editMode = false
        }

        ctrl.updateSettings = function() {
          ctrl.updateSettingsLoading = true
          ProcessorSettings.syncProcessorSettings(currentOrg.id)
            .then(loadProcessorSettings)
            .catch(showError)
            .finally(setAs(ctrl, 'updateSettingsLoading', false))
        }

        $scope.$watch('ctrl.processorSettings', function() {
          differentAddress()
        })

        // Private methods
        function differentAddress() {
          if (!ctrl.processorSettings) return

          var legalEntityAddress = getAddress('legal_entity')
          var subMerchantAddress = getAddress('sub_merchant')
          if (!subMerchantAddress) return

          ctrl.orgAddressDifferent = !_.isEqual(legalEntityAddress, subMerchantAddress)
        }

        function getAddress(obj) {
          return _.pick(ctrl.processorSettings[obj], addressKeys)
        }

        function finalizeProcessorSettings() {
          if (ctrl.orgAddressDifferent) return
          ctrl.processorSettings.sub_merchant = _.omit(ctrl.processorSettings.sub_merchant, addressKeys)
        }

        function hasSettings() {
          return !_.isEmpty(processorSettings.legal_entity) && !_.isEmpty(processorSettings.sub_merchant)
        }

        function loadProcessorSettings(data) {
          var resp = data.data.result
          if (!resp) return
          var legalEntity = resp.legal_entity || {}
          var subMerchant = resp.sub_merchant || {}

          // default to org address if legalEntity address is empty
          var legalAddr = _.pick(legalEntity, addressKeys)
          if (_.isEmpty(_.pick(legalAddr, _.identity))) {
            _.assign(legalEntity, _.pick(currentOrg, addressKeys))
          }

          processorSettings = {
            legal_entity: legalEntity,
            sub_merchant: subMerchant
          }

          if (!processorSettings.legal_entity.tax_id_type) processorSettings.legal_entity.tax_id_type = 'ein'
          var noStakePercentSaved = _.isUndefined(processorSettings.legal_entity.stake_percent)
          if (noStakePercentSaved) processorSettings.legal_entity.stake_percent = '100'

          ctrl.createMode = !(legalEntity.id && subMerchant.id)
          ctrl.processorSettings = angular.copy(processorSettings)
          ctrl.processorSettingsShowing = hasSettings()
        }

        ctrl.urlPattern = /^(https?:\/\/)?[^ ."]+(\.[^ ."]+)+$/
        ctrl.phonePattern = '^([0-9]{3})?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$'
        ctrl.businessTypes = _.reject(getEnum('PROCESSOR_SETTINGS.BUSINESS_TYPES'), function(businessType) {
          // Previous options no longer allowed after API v12 upgrade
          return businessType.value == 'ASSOCIATION_ESTATE_TRUST' || businessType.value == 'INTERNATIONAL_ORGANIZATION'
        })

        ctrl.dobDate = function() {
          var date = ctrl.processorSettings.legal_entity.date_of_birth
          if (!date) return
          return moment(date).format('MM/DD/YYYY')
        }

        ctrl.orgAddressDisplay = function() {
          var key = (ctrl.orgAddressDifferent) ? 'different' : 'same'
          return i18ng.t('PROCESSOR_SETTINGS.LABELS.org_address_' + key)
        }

        ctrl.edit = function() {
          ctrl.editMode = true
        }

        ctrl.editableField = function() {
          return ctrl.editMode || ctrl.createMode
        }

        ctrl.viewMode = function() {
          return !ctrl.editMode && !ctrl.createMode
        }

        ctrl.fieldLocked = function() {
          var isApproved = ctrl.processorSettings.legal_entity.litle_status === 'approved'
          return !ctrl.createMode && isApproved
        }

        ctrl.soleProprietorshipSelected = function() {
          return ctrl.processorSettings.legal_entity.legalEntityType === 'INDIVIDUAL_SOLE_PROPRIETORSHIP'
        }

        ctrl.taxExemptSelected = function() {
          return ctrl.processorSettings.legal_entity.legalEntityType === 'TAX_EXEMPT_ORGANIZATION'
        }

        ctrl.showProcessorSettings = function() {
          ctrl.processorSettingsShowing = true
        }

        ctrl.taxIdDisplay = function(id, type) {
          var mask = {
            ein: 'xx-xxx',
            ssn: 'xxx-xx-'
          }
          if (!id) return '--'
          return mask[type] + id
        }
      }
    }
  })
