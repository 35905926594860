angular.module('pl-shared')
  .service('Refund', function(_, DS, i18ng, nestedResource) {
    var Refund = DS.defineResource({
      name: 'refunds',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
      relations: {
        belongsTo: {
          invoices: {
            localKey: 'sale_id',
            localField: 'invoice'
          }
        }
      },
      serialize: nestedResource('sale'), // TODO: is this correct?
      computed: {
        refund_status_display: [
          'status',
          function() {
            return i18ng.t('REFUND_STATUS.' + this.status)
          }
        ]
      }
    })

    return Refund
  })

  .run(function(Refund) {})
