angular.module('org-admin')
  .component('membershipDefinitionInfo', {
    templateUrl: '/static/org/memberships/membership-definition-info.html',
    bindings: {
      membershipDefinition: '=',
      eligibility: '='
    },
    controller: function(
      _, i18ng, $scope, BackgroundScreenService, dialog, moment, currentOrg, $sce, Alerts, renderContext, appPermission, ENV,
      ItemPrice, MembershipAssignmentTypeService, MembershipDefinition, MembershipPriceVariationsService, ElectronicDocumentService, EligibilityUtilService,
      pageViewHandler, featureToggles, CredentialManagementService) {
      var $ctrl = this

      var DEFAULT_TIMEZONE = 'America/New_York'

      $ctrl.$onInit = $onInit
      $ctrl.cancel = cancel
      $ctrl.reset = reset
      $ctrl.submit = submit
      $ctrl.editEffectivity = editEffectivity
      $ctrl.editMembership = editMembership
      $ctrl.editEligibility = editEligibility
      $ctrl.editPriceVariations = editPriceVariations
      $ctrl.isArchived = isArchived
      $ctrl.getTrainingOptionValue = EligibilityUtilService.getTrainingOptionValue
      $ctrl.removeServerMessages = removeServerMessages
      $ctrl.presentModal = presentModal
      $ctrl.priceVariations = null
      $ctrl.itemVariations = null
      $ctrl.validationRuleRequired = validationRuleRequired
      $ctrl.additionalFieldRequired = additionalFieldRequired
      $ctrl.showTrainings = showTrainings

      $ctrl.assignmentTypes = []
      $ctrl.failed = false
      $ctrl.model = {}
      $ctrl.yearOptions = []
      $ctrl.recentPurchases = null
      $ctrl.saleItem = {}
      $ctrl.edocsEnabled = appPermission.electronicDocuments.usable
      $ctrl.eligibilityEnabled = appPermission.eligibility.usable

      function $onInit() {
        $ctrl.currentYear = (new Date()).getFullYear()
        $ctrl.canEditPrice = appPermission.powerPay.usable || ENV.current !== 'production'
        resetModel()
        setAssignmentTypes()
        setYearOptions()
        setEligibilityRules()
        pageViewHandler.addPageDepths({ depth3: _.capitalize($ctrl.membershipDefinition.purchase_status) + 'Memberships' })
      }

      function cancel() {
        reset()
        gotoMemberships()
      }

      function reset() {
        resetModel()
      }

      function submit() {
        var data = _.clone($ctrl.model)
        $ctrl.submitting = true
        $ctrl.failed = false
        $ctrl.serverErrorMessages = null

        data.price_cents = Math.round(parseFloat($ctrl.model.price || 0) * 100)
        data.start_date = moment.tz($ctrl.model.start_date, currentOrg.timezone || DEFAULT_TIMEZONE).format()
        data.end_date = moment.tz($ctrl.model.end_date, currentOrg.timezone || DEFAULT_TIMEZONE).format()

        MembershipDefinition.update($ctrl.membershipDefinition.id, data)
          .then(editSuccess)
          .catch(editError)

        function editSuccess(result) {
          $ctrl.submitting = false
          Alerts.success('MEMBERSHIP_DEFINITION_FORMS.ALERTS.edit_success')
          gotoMemberships()
        }

        function editError() {
          $ctrl.submitting = false
          $ctrl.failed = true
          $ctrl.serverErrorMessages = _.get(arguments[0], 'data.error.messages') || [i18ng.t('MEMBERSHIP_DEFINITION_FORMS.ERRORS.edit_failed')]
        }
      }

      function editEffectivity() {
        pageViewHandler.fireEvent('ValidRange.EditPencil.Click', 8, null, true) // Do not reset pageDepths
        var options = {
          directive: 'membership-definition-effectivity-edit',
          scope: $scope,
          attrs: {
            membershipDefinition: $ctrl.membershipDefinition
          }
        }
        $ctrl.presentModal(options, editEffectivitySuccess)
      }

      function presentModal(options, success, fail) {
        return dialog.confirm(options)
          .then(success)
          .catch(fail)
      }

      function editMembership() {
        pageViewHandler.fireEvent('EditPencil.Click', 8, null, true)
        var options = {
          directive: 'membership-definition-edit',
          scope: $scope,
          attrs: {
            membershipDefinition: $ctrl.membershipDefinition
          }
        }
        $ctrl.presentModal(options, editMembershipSuccess)
      }

      function editEligibility() {
        pageViewHandler.fireEvent('EligibilityRequirements.EditPencil.Click', 8, null, true)
        var options = {
          component: 'membership-definition-eligibility-edit',
          scope: $scope,
          attrs: {
            membershipDefinition: $ctrl.membershipDefinition,
            eligibility: $ctrl.eligibility,
            electronicDocuments: $ctrl.electronicDocuments,
            customCredentials: $ctrl.customCredentials,
            backgroundChecks: $ctrl.backgroundChecks
          }
        }
        $ctrl.presentModal(options, eligiblitySuccess)
      }

      function eligiblitySuccess(result) {
        $ctrl.eligibility = result
        $ctrl.eligibilityRules = EligibilityUtilService.getMembershipEligibilityRules($ctrl.eligibility)
        $ctrl.hasEligibility = EligibilityUtilService.hasEligibility($ctrl.eligibilityRules)
        setLabels()
      }

      function setEligibilityRules() {
        if (!$ctrl.eligibility) return

        $ctrl.eligibilityRules = EligibilityUtilService.getMembershipEligibilityRules($ctrl.eligibility)
        $ctrl.hasEligibility = EligibilityUtilService.hasEligibility($ctrl.eligibilityRules)
        loadCustomCredentials()
        loadElectronicDocuments()
        loadBackgroundChecks()
      }

      function loadCustomCredentials() {
        CredentialManagementService.search(currentOrg.id)
          .then(function(result) {
            $ctrl.customCredentials = result
            setCustomCredentialsLabel()
          }).catch(function() {
            $ctrl.customCredentials = []
          })
      }

      function loadElectronicDocuments() {
        ElectronicDocumentService.findAll({
          boss_organization_id: currentOrg.id
        })
          .then(function(result) {
            $ctrl.electronicDocuments = result
            setElectronicDocumentsLabel()
          })
      }

      function loadBackgroundChecks() {
        const isRuleSetAnyBackgroundScreen = EligibilityUtilService.isAnyBackgroundScreen($ctrl.eligibilityRules)
        const isRuleSetLegacyBackgroundScreen = EligibilityUtilService.isLegacyBackgroundScreen($ctrl.eligibilityRules)
        if (isRuleSetAnyBackgroundScreen || isRuleSetLegacyBackgroundScreen) {
          setBgCheckLabel()
          return
        }
        getBackgroundScreenConfigMode().then(function() {
          // If the org is set to 'any' but the current rule set still specifies a survey, we need to fetch them
          if ($ctrl.backgroundScreenConfigurationModeAny && isRuleSetAnyBackgroundScreen) return

          EligibilityUtilService.loadBackgroundChecks(currentOrg.id)
            .then(function(result) {
              $ctrl.backgroundChecks = result
              setBgCheckLabel()
            })
        })
      }

      function getBackgroundScreenConfigMode() {
        if ($ctrl.backgroundScreenConfigurationModeAny || $ctrl.backgroundScreenConfigurationModeLegacy) return Promise.resolve()

        return BackgroundScreenService.find(currentOrg.id).then(function(result) {
          $ctrl.backgroundScreenConfigurationModeAny = result.mode === 'any'
          $ctrl.backgroundScreenConfigurationModeLegacy = result.mode === 'ncsi_legacy'
          if ($ctrl.backgroundScreenConfigurationModeAny) {
            $ctrl.model.eligibility.background_check = 'any'
            $ctrl.backgroundChecks = null
          }
          else if ($ctrl.backgroundScreenConfigurationModeLegacy) {
            $ctrl.model.eligibility.background_check = 'nsci_legacy'
            $ctrl.backgroundChecks = null
          }
        }).catch(function() {
          $ctrl.backgroundScreenConfigurationMode = null
        })
      }

      function setLabels() {
        setCustomCredentialsLabel()
        setElectronicDocumentsLabel()
        setBgCheckLabel()
      }

      function setCustomCredentialsLabel() {
        $ctrl.customCredentialsLabel = EligibilityUtilService.getCustomCredentialsLabel($ctrl.eligibilityRules, $ctrl.customCredentials)
      }

      function setElectronicDocumentsLabel() {
        $ctrl.edocLabel = EligibilityUtilService.getElectronicDocumentsLabel($ctrl.eligibilityRules, $ctrl.electronicDocuments)
      }

      function setBgCheckLabel() {
        $ctrl.bgCheckLabel = EligibilityUtilService.getBgCheckLabel($ctrl.eligibilityRules, $ctrl.backgroundChecks)
      }

      function removeServerMessages() {
        if ($ctrl.serverErrorMessages) {
          $ctrl.serverErrorMessages.length = 0
        }
      }

      function isArchived() {
        return $ctrl.membershipDefinition.purchase_status === 'archived' ? true : false
      }

      function validationRuleRequired(rule_name) {
        return $ctrl.membershipDefinition.validation_rules &&
          $ctrl.membershipDefinition.validation_rules.includes(rule_name)
      }

      function additionalFieldRequired(field_name) {
        return $ctrl.membershipDefinition.additional_fields &&
          $ctrl.membershipDefinition.additional_fields.includes(field_name)
      }

      function showTrainings() {
        return $ctrl.eligibilityEnabled && (
          featureToggles.safesport ||
          featureToggles.sideline_sports_doc ||
          featureToggles.aps ||
          featureToggles.litmos_training ||
          featureToggles.lms_concussion_training
        )
      }

      // PRIVATE

      function editEffectivitySuccess() {
        resetModel()
      }

      function editMembershipSuccess() {
        resetModel()
        setAssignmentTypes()
      }

      function resetModel() {
        $ctrl.loaded = false
        getPriceAndSetModel($ctrl.membershipDefinition)
        $ctrl.message = $sce.trustAsHtml(_.get($ctrl.model, 'messages[0].body'))
      }

      function editPriceVariations() {
        var options = {
          component: 'membership-price-variation-modal',
          scope: $scope,
          attrs: {
            membershipDefinition: $ctrl.membershipDefinition,
            priceVariations: $ctrl.priceVariations,
            saleItem: $ctrl.saleItem
          }
        }
        $ctrl.presentModal(options, resetModel, resetModel)
      }

      function getPriceAndSetModel(md) {
        if (md.clare_item_uuid) {
          ItemPrice.find(md.clare_item_uuid).then(function(data) {
            $ctrl.saleItem = data
            $ctrl.itemVariations = data.variations || []
            var matches = _.filter(data.variations, function(variation) { return variation.id === data.default_variation_id })
            if (matches.length) {
              md.price_cents = matches[0].price_cents
              md.price = ((parseInt(md.price_cents, 10) || 0) / 100).toFixed(2)
              $ctrl.model = _.clone($ctrl.membershipDefinition)
            }
            getPriceVariations(md)
            $ctrl.loaded = true
          })
        }
        else {
          $ctrl.model = _.clone($ctrl.membershipDefinition)
          $ctrl.loaded = true
        }
      }

      function getPriceVariations(md) {
        MembershipPriceVariationsService.findAll({
          boss_organization_id: currentOrg.id,
          membership_definition_id: md.id,
          configurable: true,
          per_page: 300
        }).then(setPriceVarations)
      }

      function setPriceVarations(data) {
        $ctrl.priceVariations = _.chain(data)
          .filter({ 'archived_at': null, 'default': false })
          .forEach(function(pv) {
            var pvIv = _.find($ctrl.itemVariations, function(iv) {
              return iv.uuid === pv.clare_item_variation_uuid
            })
            pv.price = ((pvIv || {}).price_cents || 0)  / 100
          })
          .reverse()
          .value()
        _.defer(function() { $scope.$digest() })
      }

      function gotoMemberships() {
        renderContext.goto('app.billing.memberships')
      }

      function setYearOptions() {
        var yearNow = $ctrl.currentYear
        var startYear = $ctrl.model.start_date ? Number($ctrl.model.start_date.split('-')[0]) : yearNow
        var endYear = $ctrl.model.end_date ? Number($ctrl.model.end_date.split('-')[0]) : yearNow
        $ctrl.yearOptions = _.range(startYear - 2, endYear + 3)
      }

      function setAssignmentTypes() {
        $ctrl.assignmentTypes = MembershipAssignmentTypeService.labels($ctrl.membershipDefinition.membership_assignment_type)
      }
    }
  })
