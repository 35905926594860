angular.module('org-admin')
  .directive('membershipDefinitionPublishEdit', function() {
    return {
      restrict: 'EA',
      scope: {},
      bindToController: {
        cancel: '=',
        confirm: '=',
        membershipDefinition: '=',
        dialogOptions: '='
      },
      templateUrl: '/static/org/memberships/membership-definition-publish-edit.html',
      controllerAs: '$ctrl',
      controller: function(_, i18ng, currentOrg, Alerts, moment, MembershipDefinition) {
        var $ctrl = this
        var TIMEZONE = currentOrg.timezone || 'America/New_York'

        $ctrl.submit = submit
        $ctrl.publish = publish
        $ctrl.unpublish = unpublish
        $ctrl.schedule = schedule
        $ctrl.model = angular.copy($ctrl.membershipDefinition)

        var defaultPublishDateTime = moment.tz(TIMEZONE)

        if ($ctrl.model.purchase_open && $ctrl.model.purchase_close) {
          $ctrl.model.purchase_status = 'scheduled'
        }
        $ctrl.purchase_open_date = forOrgZone($ctrl.model.purchase_open, defaultPublishDateTime)
        $ctrl.purchase_open_time = forOrgZone($ctrl.purchase_open_date, defaultPublishDateTime).format('HH:mm')

        $ctrl.purchase_close_date = forOrgZone($ctrl.model.purchase_close)
        $ctrl.purchase_close_time = ($ctrl.model.purchase_close ? forOrgZone($ctrl.model.purchase_close).format('HH:mm') : null)

        function forOrgZone(datetimeString, defaultVal) {
          if (!datetimeString) return defaultVal || null
          return moment.tz(datetimeString, TIMEZONE)
        }

        function clearPurchaseDates() {
          $ctrl.purchase_open_date = null
          $ctrl.purchase_open_time = null
          $ctrl.purchase_close_date = null
          $ctrl.purchase_close_time = null
        }

        function setDefaultPurchaseDates() {
          clearPurchaseDates()
          $ctrl.purchase_open_date = defaultPublishDateTime
          $ctrl.purchase_open_time = defaultPublishDateTime.format('HH:mm')
        }

        function publish() {
          setDefaultPurchaseDates()
          $ctrl.model.purchase_status = 'published'
        }

        function unpublish() {
          clearPurchaseDates()
          $ctrl.model.purchase_open = null
          $ctrl.model.purchase_close = null
          $ctrl.model.purchase_status = 'unpublished'
        }

        function schedule() {
          setDefaultPurchaseDates()
          $ctrl.model.purchase_status = 'scheduled'
        }

        function splitOrDefault(timeString, attr) {
          var arrayIndex, defaultValue = null
          if (attr === 'hour') {
            arrayIndex = 0
            defaultValue = 12
          }
          else {
            arrayIndex = 1
            defaultValue = 0
          }

          return (timeString ? timeString.split(':')[arrayIndex] : defaultValue)
        }

        function submit() {
          $ctrl.submitting = true
          var data = _.clone($ctrl.model)
          delete (data.price_cents)

          if ($ctrl.purchase_open_date) {
            $ctrl.purchase_open_date = forOrgZone($ctrl.purchase_open_date)
            $ctrl.purchase_open_date.set('hour', splitOrDefault($ctrl.purchase_open_time, 'hour'))
            $ctrl.purchase_open_date.set('minute', splitOrDefault($ctrl.purchase_open_time, 'minute'))
            data.purchase_open = $ctrl.purchase_open_date.format()
          }
          else data.purchase_open = null

          if ($ctrl.purchase_close_date) {
            $ctrl.purchase_close_date = forOrgZone($ctrl.purchase_close_date)
            $ctrl.purchase_close_date.set('hour', splitOrDefault($ctrl.purchase_close_time, 'hour'))
            $ctrl.purchase_close_date.set('minute', splitOrDefault($ctrl.purchase_close_time, 'minute'))
            data.purchase_close = $ctrl.purchase_close_date.format()
          }
          else data.purchase_close = null

          MembershipDefinition.update($ctrl.model.id, data)
            .then(editSuccess)
            .catch(editError)

          function editSuccess(result) {
            $ctrl.submitting = false
            _.extend($ctrl.model, $ctrl.membershipDefinition)
            Alerts.success('MEMBERSHIP_DEFINITION_AVAILABILITY.ALERTS.edit_success')
            $ctrl.confirm()
          }

          function editError() {
            $ctrl.submitting = false
            $ctrl.failed = true
            $ctrl.serverErrorMessages = _.get(arguments[0], 'data.error.messages') || [i18ng.t('MEMBERSHIP_DEFINITION_AVAILABILITY.ERRORS.edit_failed')]
          }
        }
      }
    }
  })
