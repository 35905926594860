angular.module('org-admin')

  .directive('payOffline', function() {
    return {
      restrict: 'A',
      scope: {},
      bindToController: {
        invoice: '=',
        cancel: '=',
        confirm: '=',
        dialogOptions: '='
      },
      templateUrl: '/static/org/payments/pay-offline.html',
      controllerAs: 'ctrl',
      controller: function($scope, dialog, Payment, SalePayments, setAs, setWhile) {

        var ctrl = this
        ctrl.selectedPaymentMethod = 'check'
        ctrl.loading = true
        $scope.$on('salePayments:update', getOfflinePaymentOptions)

        setWhile(ctrl, 'loading', getOfflinePaymentOptions)()

        ctrl.moreThanOneInstallmentLeft = getEditableInstallments().length > 1

        ctrl.submit = function() {
          return Payment.create({
            sale_id: ctrl.invoice.id,
            user_id: ctrl.invoice.user_id,
            name: ctrl.invoice.user_full_name,
            payment_option: ctrl.selectedOption,
            tender: ctrl.selectedPaymentMethod,
            notes: ctrl.notes
          },
          {
            endpoint: 'sales/' + ctrl.invoice.id + '/payments'
          })
            .then(ctrl.confirm)
            .catch(setAs(ctrl, 'failure'))
        }

        function getEditableInstallments() {
          return _.filter(ctrl.invoice.payments, function(payment) {
            return !payment.is_paid && payment.status !== 'canceled'
          })
        }

        function getOfflinePaymentOptions() {
          return SalePayments.offlinePaymentOptions(ctrl.invoice.sale_number)
            .then(
              function(response) {
                ctrl.paymentOptions = response.data.result
                var firstOption = ctrl.paymentOptions[0]
                ctrl.selectedOption = firstOption.strategy_type
                ctrl.loading = false
              },
              function(response) {
                var responseError = response.data.error
                ctrl.serverErrors = responseError.messages || [responseError]
              })
        }
      }
    }
  })
