angular.module('pl-shared')

  .directive('lengthField', function() {

    return {
      scope: {},
      restrict: 'E',
      require: ['?^form', '?^ngForm'],
      replace: true,
      templateUrl: '/static/shared/components/length-field/length-field.html',
      controllerAs: 'ctrl',
      bindToController: {
        name: '@',
        value: '=ngModel',
        required: '<',
        ngRequired: '<'
      },
      link: function($scope, $element, $attrs, $ctrls) {
        $scope.form = $ctrls[0] || $ctrls[1]
      },
      controller: function(_, $scope, $attrs) {
        var ctrl = this
        var getValue = typeof (ctrl.value) === 'function' ? ctrl.value : _.constant(ctrl.value)
        var setValue = typeof (ctrl.value) === 'function' ? ctrl.value : function(v) { ctrl.value = v }
        var value    = getValue()
        var numberValue = _.isNumber(value)
        var name = ctrl.name


        if (!name) return console.error('Length field required a name attribute.')

        ctrl.feetId = name + '_feet'
        ctrl.inchesId = name + '_inches'
        ctrl.feet = numberValue ? Math.floor(value / 12) : null
        ctrl.inches = numberValue ? Math.round(value % 12) : null

        $scope.$watchGroup(['ctrl.feet', 'ctrl.inches'], function(newVal, oldVal) {
          // Update combined model value
          var feet = parseInt(newVal[0], 10)
          var inches = parseInt(newVal[1], 10)
          var empty = isNaN(feet) && isNaN(inches)
          setValue(empty ? null : (feet || 0) * 12 + (inches || 0))

          var form = $scope.form
          if (!form) return

          // Update combined errors
          var required = ctrl.ngRequired || ctrl.required
          var combinedCtrl = form[name]
          var feetCtrl = form[ctrl.feetId]
          var inchesCtrl = form[ctrl.inchesId]
          var feetIsNum = !feetCtrl.$error.number && !feetCtrl.$error.min
          var inchesIsNum = !inchesCtrl.$error.number && !inchesCtrl.$error.min && !inchesCtrl.$error.max

          combinedCtrl.$setValidity('feet_number', feetIsNum || !inchesIsNum)
          combinedCtrl.$setValidity('inches_number', inchesIsNum || !feetIsNum)
          combinedCtrl.$setValidity('feet_and_inches_number', inchesIsNum || feetIsNum)
          feetCtrl.$setValidity('required', !required || !empty)
          inchesCtrl.$setValidity('required', !required || !empty)

          ctrl.targetField = feetCtrl.$invalid ? ctrl.feetId : ctrl.inchesId

          // trigger dirty and touched on all fields since their validation is tied together
          if (!_.isEqual(newVal, oldVal)) {
            combinedCtrl.$setDirty()
            combinedCtrl.$setTouched()
            feetCtrl.$setDirty()
            feetCtrl.$setTouched()
            inchesCtrl.$setDirty()
            inchesCtrl.$setTouched()
          }
        })
      }
    }
  })
