angular.module('org-admin')
  .directive('addPerson', function() {

    return {
      scope: {},
      bindToController: {
        containerId: '<',
        callbackObj: '<',
        defaultNoGrant: '<',
        hideGrant: '<'
      },
      controllerAs: 'ctrl',
      templateUrl: '/static/org/add-person/add-person.html',
      controller: function(
        $window, currentOrg, appPermission,
        currentUser, i18ng, PeopleAndOrgsUiService, FreemiumOrgService
      ) {

        var ctrl = this
        ctrl.$onInit = $onInit

        function $onInit() {
          setTimeout(() => initAddPersonElement())
        }

        function initAddPersonElement() {
          ctrl.addPersonRef = $window.document.getElementById(ctrl.containerId).firstElementChild
          PeopleAndOrgsUiService.loadScripts('se-add-person', function() {
            ctrl.addPersonRef = PeopleAndOrgsUiService.createElement(
              'se-add-person',
              ctrl.containerId,
              {
                options: JSON.stringify({
                  orgId: currentOrg.id,
                  title: i18ng.t('ADD_PERSON.title'),
                  allowGrant: allowGrant(),
                  defaultNoGrant: ctrl.defaultNoGrant,
                  freeOrg: FreemiumOrgService.orgIsFreemium(),
                  verifyProduct: appPermission.verify.visible,
                  affiliatePermissionOrganization: affiliatePermissionOrganization()
                })
              }
            )

            PeopleAndOrgsUiService.addPersonEventListeners(ctrl.addPersonRef, ctrl.callbackObj)
          })
        }

        function allowGrant() {
          if (ctrl.hideGrant) return false
          if (currentUser.superRole) return true

          return currentUser.hasRoleForResource('org_role_granter', 'Organization', currentOrg.id)
        }

        function affiliatePermissionOrganization() {
          if (!allowGrant()) return undefined

          var rootOrg = currentOrg.rootOrgForAdminManagement()
          return rootOrg ? rootOrg.name : undefined
        }
      }
    }

  })
