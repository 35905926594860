angular.module('pl-shared')

  .directive('snCurrencyField', function(_) {

    var NON_TRANSFERABLE_ATTRS = [
      'class',
      'ngIf',
      'ngHide',
      'ngShow',
      'ngClass',
      'ngSwitchDefault',
      'ngSwitchWhen',
      'plAddonField'
    ]
    var COPY_ATTRS = [
      'ngModel',
      'ngModelOptions'
    ]

    return {
      priority: 101, // run before all form field directives
      require: 'ngModel',
      restrict: 'E',
      replace: true,
      templateUrl: '/static/shared/components/sn-currency-field/sn-currency-field.html',
      compile: snCurrencyFieldCompile
    }

    // Transfer some attributes from outer element
    function snCurrencyFieldCompile($element, $attrs) {
      var input = $element.find('input')
      var transferableAttrs = _.omit.apply(_, [$attrs.$attr].concat(NON_TRANSFERABLE_ATTRS))
      var attr, attrs = {}
      for (var key in transferableAttrs) {
        attr = transferableAttrs[key]
        attrs[attr] = $attrs[key]
        // remove attrs from the original element
        if (!_.contains(COPY_ATTRS, key)) {
          delete $attrs[key]
          $element.removeAttr(attr)
        }
      }
      input.attr(attrs)
    }
  })

  .directive('snCurrencyFieldInput', function($timeout, currencyFilter) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: {
        post: snCurrencyFieldLink
      }
    }

    // Apply formatter/parser to input element
    function snCurrencyFieldLink($scope, $element, $attrs, ngModel) {

      var $ctrl = this
      if (!_.isUndefined($attrs.setFocus)) $ctrl.hasSetFocus = true

      ngModel.$parsers.push(function(viewValue) {
        if (!viewValue) return null
        viewValue = viewValue.replace(/^\$|,/g, '')
        var parsed = _.round(viewValue, 2)
        return isNaN(parsed) ? viewValue : parsed
      })

      ngModel.$formatters.push(function(modelValue) {
        if (!modelValue) return ''
        return currencyFilter(modelValue, '')
      })

      ngModel.$validators.currency = function(modelValue) {
        return !modelValue || !isNaN(modelValue)
      }

      ngModel.$validators.positive = function(modelValue) {
        return isNaN(modelValue) || modelValue >= 0 // let isNaN case get caught in another validator
      }

      $element.on('blur', function(e) {
        $timeout(function() {
          if (ngModel.$valid) {
            // Format the display value as currency on blur
            ngModel.$setViewValue(currencyFilter(ngModel.$modelValue, ''))
            ngModel.$render()
          }
        })
      })
    }
  })
