angular.module('pl-shared')
  .factory('FinancialAdminUiService', function(
    $q, $window, ENV
  ) {

    return {
      loadElementScript: loadElementScript,
      loadSharedScripts: loadSharedScripts
    }

    function loadElementScript(ngElName) {
      injectScript(ngElName)
    }

    function loadSharedScripts() {
      var scripts = ['runtime', 'polyfills', 'styles', 'vendor']
      scripts.forEach(function(scriptName) {
        injectScript(scriptName)
      })
    }

    // private functions
    function injectScript(scriptName) {
      // only load 1 polyfill across all libs
      const prefix = scriptName === 'polyfills' ? 'se-ui-libs-' : 'se-financial-admin-'
      const id = prefix + scriptName
      let script = document.getElementById(id)
      if (!script) {
        script = document.createElement('script')
        script.async = false // ensure the scripts load in order
        script.src = ENV.urls.financialAdminUi + '/' + scriptName + '-es2015.js?v2'
        script.type = 'text/javascript'
        script.id = id
        document.head.appendChild(script)
      }
    }

  })
