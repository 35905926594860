angular.module('org-admin')
  .factory('filterCollectionFactory', function(_, $q, filterPanelService, FilterCollection, FilterRule) {

    var factory = {
      buildFilterCollection: buildFilterCollection,
      buildFilterRules: buildFilterRules
    }

    return factory

    /**
     * @alias FilterRuleInfo - The information used to build a filter rule.
     *
     * @property {number} org_id - The id of the organization the rule is associated with.
     * @property {number} source - The id of the survey the rule is associated with or null.
     * @property {string} field - The unique key of the survey field.
     * @property {string} logic - The unique key of the logic operation.
     * @property {*} [value] - The value to filter on. If null, values is expected to not be null.
     * @property {*} [values] - The values to filter on. If null, value is expected to not be null.
     * @property {FilterRuleInfo[]} or - Info used to build child filters.
     */

    /**
     * Creates a new instance of FilterCollection using the provided options.
     *
     * @param {options} opts
     * @param {Function} [opts.update] - A function that is called when the collection is updated. Defaults to angular.noop
     * @param {FilterRuleInfo[]} [opts.filterRuleInfos] - Info used to build the initial collection of filters.
     * @param {Boolean} [opts.allowEmpty] - Pass true if a new empty rule should not be automatically created for an empty ruleset.
     * @param {Function} [opts.transformSerializedRule] - A function that is called after serializing a rule.
     *                                                    It is passed an object with `ret`, `rule` and `allRules` properties.
     *                                                    It should return the desired serialized object.
     *                                                    Defaults to _.property('ret')
     * @returns {Promise.<FilterCollection>} - The newly created FilterCollection.
     */
    function buildFilterCollection(opts) {
      return buildFilterRules(null, opts.filterRuleInfos || [])
        .then(function(rules) {
          return new FilterCollection(_.extend({
            update: angular.noop,
            allowEmpty: false,
            transformSerializedRule: _.property('ret'),
            rules: rules
          }, opts))
        })
    }

    /**
     * Builds filter rules using the provided info.
     *
     * @param {FilterRule} baseRule - The parent rule for the provided filterRuleData
     * @param {FilterRuleInfo[]} filterRuleInfos - The info used to build the filter rules.
     * @returns {Promise.<FilterRule[]>} - The newly created FilterRules.
     */
    function buildFilterRules(baseRule, filterRuleInfos) {
      return $q.all(_.map(filterRuleInfos, _.partial(buildFilterRule, baseRule)))
        .then(function(rules) {
          return _.flatten(rules)
        })
    }

    /**
     * Builds filter rules using the provided info.
     *
     * @param {FilterRule} baseRule - The parent rule for the provided filterRuleData
     * @param {FilterRuleInfo} filterRuleInfo - The info used to build the filter rule.
     * @returns {Promise.<FilterRule[]>} - The newly created FilterRules.
     */
    function buildFilterRule(baseRule, filterRuleInfo) {
      var dataSourceId = null
      var bucket = null

      // historically numeric sources were how survey_result_ids were stored
      if (/^\d+$/.test(filterRuleInfo.source)) {
        bucket = 'survey_results'
        dataSourceId = bucket + '.' + filterRuleInfo.source
      }
      else {
        var source = filterRuleInfo.source || ''
        var dataSourceParts = source.toString().split('.')
        if (dataSourceParts.length > 1) {
          bucket = dataSourceParts[0]
          dataSourceId = source
        }
        else if (['suspensions', 'memberships'].includes(filterRuleInfo.source)) {
          bucket = dataSourceId = filterRuleInfo.source
        }
        else if (filterPanelService.isSEProfileField({ key: filterRuleInfo.field })) {
          bucket = 'se_profile'
          dataSourceId = 'se_profile'
        }
        else {
          bucket = 'orgProfile'
          dataSourceId = 'orgProfile'
        }
      }

      return filterPanelService.getField(filterRuleInfo.org_id, dataSourceId, filterRuleInfo.field)
        .then(function(field) {
          return filterPanelService.getLogicOptionsByType(field.type)
            .then(function(logicOptions) {
              return { field: field, logicOptions: logicOptions }
            })
        })
        .then(function(result) {
          var logic = _.find(result.logicOptions, { logic: filterRuleInfo.logic })
          var rule = new FilterRule({
            base: baseRule,
            dataSourceId: dataSourceId,
            field: result.field,
            logic: logic,
            value: _.isUndefined(filterRuleInfo.value) ?  filterRuleInfo.values : filterRuleInfo.value
          })

          if (!filterRuleInfo.or) {
            return $q.resolve([rule])
          }

          return buildFilterRules(rule, filterRuleInfo.or)
            .then(function(childRules) {
              return [rule].concat(childRules)
            })
        })
    }
  })
  .factory('FilterCollection', function(_, $q, FilterRule) {

    /**
     * Creates a new instance of FilterCollection.
     *
     * @constructor
     * @param {object} opts
     * @param {FilterRule[]} opts.rules - The rules to create the collection with.
     * @param {Function} opts.update - The method to call when the collection is updated.
     * @param {Boolean} opts.allowEmpty - Whether or not to allow empty rule sets.
     * @param {Function} opts.transformSerializedRule - The method to call after serializing a rule.
     */
    function FilterCollection(opts) {
      this.rules = opts.rules.slice(0)
      this._updateCallback = opts.update
      _.extend(this, _.pick(opts, 'allowEmpty', 'transformSerializedRule'))

      _.bindAll(this) // binds instanceMethods and functions in opts

      _.each(this.rules, _.partial(function(updateFunc, rule) { rule._updateCollection = updateFunc }, this._update))

      if (this.rules.length === 0 && !this.allowEmpty) {
        this.add()
      }

      this._updateValidCount()
      this._serialized = this.serialize()
      this._updateCallback(this._serialized.length ? this._serialized : null)
    }

    var instanceMethods = {

      /**
       * Creates a blank "And" rule and adds it to the collection.
       *
       * @return {FilterRule} - The newly created rule.
       */
      addAnd: function addAnd() {
        return this.add()
      },

      /**
       * Creates a blank "Or" rule and adds to the collection
       *
       * @return {FilterRule} - The newly created rule.
       */
      addOr: function addOr() {
        var last = _.last(this.rules)
        var base = last ? last.base || last : null // null is fallback in case there is no base (it will be an And filter instead)

        return this.add({ base: base })
      },

      /**
       * Creates a new rule using the provided attributes and adds it the collection.
       *
       * @param {*} [attrs] - The attributes used to create the new rule.
       * @return {FilterRule} - The newly created rule.
       */
      add: function add(attrs) {
        var rule = new FilterRule(attrs)
        rule._updateCollection = this._update
        this.rules.push(rule)
        this._update()

        return rule
      },

      /**
       * Removes the provided FilterRule from the collection. If the provided rule is the only rule in the collection
       * then a new blank rule will be added.
       *
       * @param {FilterRule} filter - The filter to remove.
       */
      remove: function remove(filter) {
        _.pull(this.rules, filter)
        _.each(_.filter(this.rules, function(r) { return r.base === filter }), this.remove)
        if (!this.rules.length && !this.allowEmpty) {
          this.addAnd()
        }
        this._update()
      },

      /**
       * Serializes the collection into an array of FilterRuleInfos.
       *
       * @return {FilterRuleInfo[]} - The FilterRuleInfos for the current collection.
       */
      serialize: function serialize() {
        var transform = this.transformSerializedRule
        var allRules = this.rules

        return serializeByBase(null)

        function serializeByBase(base) {
          var rules =  _.filter(allRules, { base: base, valid: true })
          return _.compact(_.map(rules, serializeRule))
        }

        function serializeRule(rule) {
          var source = rule.dataSourceId()
          source = (source === 'se_profile' || source === 'orgProfile') ? '' : source
          var ret = {
            source: source,
            field: rule.field() ? rule.field().key : void 0,
            logic: rule.logic() ? rule.logic().logic : void 0
          }

          // Set correct value property
          if (_.isArray(rule.value())) ret.values = rule.value()
          else if (ret.logic && rule.logic().type !== 'null') ret.value = rule.value()

          // include or rules if present
          var orRules = serializeByBase(rule)
          if (orRules.length) ret.or = orRules

          // Allow implementations to tweak the results here
          ret = transform({ ret: ret, rule: rule, allRules: allRules })

          // TEMP UNTIL BACKEND IS FIXED
          // The `in` and `not_in` logic operators are not supported for choices on the backend just yet
          // but since we only allow one choice at a time anyway, we can just use `equal` and `not_equal` for now.
          if (ret.logic === 'choice_in') ret.logic = 'equal'
          if (ret.logic === 'choice_not_in') ret.logic = 'not_equal'

          return ret
        }
      },

      /**
       * Creates a clone of the current collection.
       *
       * @returns {FilterCollection} - The cloned collection.
       */
      clone: function clone() {
        var clonedRules = _.chain(this.rules)
          .filter({ base: null })
          .map(_.partial(cloneFilterRules, this.rules, null))
          .flatten()
          .value()

        return new FilterCollection({
          rules: clonedRules,
          update: this._updateCallback,
          allowEmpty: this.allowEmpty,
          transformSerializedRule: this.transformSerializedRule
        })

        function cloneFilterRules(rules, clonedBase, originalRule) {
          var clonedRule = new FilterRule({
            base: clonedBase,
            dataSourceId: originalRule.dataSourceId(),
            field: originalRule.field(),
            logic: originalRule.logic(),
            value: originalRule.value()
          })
          var childRules = _.chain(rules)
            .filter({ base: originalRule })
            .map(_.partial(cloneFilterRules, rules, clonedRule))
            .flatten()
            .value()

          return [clonedRule].concat(childRules)
        }
      },

      /**
       * Removes all rules from the collection and adds a blank rule to the collection.
       */
      clear: function clear() {
        this.rules.length = 0
        if (!this.allowEmpty) this.addAnd()
        this._update()
      },

      /**
       * reset any existing rules with a single rule
       */
      setRule: function setRule(attrs) {
        this.rules = []
        var rule = new FilterRule(attrs)
        rule._updateCollection = this._update
        this.rules.push(rule)
        this._update()

        return rule
      },

      /**
       * Determines if the collection has changed. If so, the _updateCallback is called with the serialized collection.
       *
       * @private
       */
      _update: function update() {
        var oldSerialized = this._serialized || []
        var newSerialized = this._serialized = this.serialize()
        var serialized = newSerialized.length ? newSerialized : null
        this._updateValidCount()

        if (_.isEqual(oldSerialized, newSerialized)) return

        var filters = this
        filters.updating = true
        $q.resolve(this._updateCallback(serialized))
          .finally(function() { filters.updating = false })
      },

      /**
       * Updates the validCount and allValid fields on the collection.
       *
       * @private
       */
      _updateValidCount: function updateValidCount() {
        var validRules = _.filter(this.rules, function(rule) {
          return rule.valid && (!rule.base || rule.base.valid)
        })
        this.validCount = validRules.length
        this.allValid = validRules.length === this.rules.length
      }

    }

    _.extend(FilterCollection.prototype, instanceMethods)

    return FilterCollection
  })
  .factory('FilterRule', function(_) {

    /**
     * Creates a new instance of FilterRule
     *
     * @constructor
     * @param {object} [attrs]
     * @param {FilterRule|null} [attrs.base] - The parent rule for this FilterRule. Generally used when creating OR filters.
     * @param {string|number} [attrs.dataSourceId] - The id of the dataSource the field is associated with. Can be an
     *   id of a survey, 'se_profile', or 'orgProfile'.
     * @param {object.<key, type>} [attrs.field] - The field to filter on.
     * @param {object.<logic, type>} [attrs.logic] - The logic used to filter.
     * @param {*} [attrs.value] - The value to filter on.
     */
    function FilterRule(attrs) {
      attrs = attrs || {}

      this.base = attrs.base || null
      this._dataSourceId = attrs.dataSourceId
      this._field = attrs.field
      this._logic = attrs.logic
      this._value = attrs.value

      _.bindAll(this, _.keys(instanceMethods))
      this._validate()
    }

    var instanceMethods = {

      /**
       * Gets or sets the dataSourceId.
       *
       * @param {string|number} [dataSourceId] - The dataSourceId value to set.
       * @return {string|number} - The current dataSourceId
       */
      dataSourceId: function(dataSourceId) {
        if (arguments.length === 1) this._set({ _dataSourceId: dataSourceId })
        return this._dataSourceId
      },

      /**
       * Gets or sets the field.
       *
       * @param {object.<key, type>} [field] - The field to set.
       * @return {Object.<key, type>} - The current field.
       */
      field: function(field) {
        if (arguments.length === 1) this._set({ _field: field })
        return this._field
      },

      /**
       * Gets or sets the logic.
       *
       * @param {object.<key, type>} [logic] - The logic to set.
       * @return {object.<logic, type>} - The current logic.
       */
      logic: function(logic) {
        if (arguments.length === 1) this._set({ _logic: logic })
        return this._logic
      },

      /**
       * Gets or sets the value.
       *
       * @param {*} [value] - The value to set.
       * @return {*} - The current value.
       */
      value: function(value) {
        if (arguments.length === 1) {
          this._value = value
          this._validate()
        }

        return this._value
      },

      /**
       * Generates a function that sets a value at the specified index in the array. If value is not an array value it
       * will be converted into one.
       *
       * @param {number} index - The index to get and set the value at.
       * @return {Function} - A function that gets and sets a value at the specified index.
       */
      valueAt: function(index) {
        var rule = this
        return function(value) {
          if (!_.isArray(rule._value)) rule._convertToRange()
          if (!arguments.length) return rule._value[index]
          rule._value[index] = value
          rule._validate()
        }
      },

      /**
       * Sets the provided params on the instance and validated the current state of the FilterRule.
       *
       * @private
       * @param {object} params - The params to set.
       */
      _set: function(params) {

        var oldDataSourceId = _.get(this, '_dataSourceId', '')
        var oldFieldType = _.get(this, '_field.type', '')
        var oldLogicType = _.get(this, '_logic.type', '')

        _.extend(this, params)

        var newDataSourceId = _.get(this, '_dataSourceId', '')
        var newFieldType = _.get(this, '_field.type', '')
        var newLogicType = _.get(this, '_logic.type', '')

        var similarLogic = similarLogicTypes(newLogicType, oldLogicType)
        var rangeLogic = rangeLogicType(newLogicType)
        var rangeValue = _.isArray(this._value)
        var dataSourceChanged = newDataSourceId !== oldDataSourceId
        var fieldChanged = newFieldType !== oldFieldType

        // Clear everything on data source changes
        if (dataSourceChanged) {
          delete this._value
          delete this._logic
          delete this._field
        }

        // Clear logic and value on field changes
        if (fieldChanged) {
          delete this._value
          delete this._logic
        }

        // Convert between single and range values it types are similar
        else if (similarLogic) {
          if (rangeLogic && !rangeValue) this._convertToRange()
          else if (!rangeLogic && rangeValue) this._convertToSingle()
        }

        // Clear value if not similar logic
        else {
          delete this._value
        }

        this._validate()
      },

      /**
       * Converts _value from an array value to a single value.
       *
       * @private
       */
      _convertToSingle: function() {
        this._value = this._value[0]
      },

      /**
       * Converts _value from a single value to an array value.
       *
       * @private
       */
      _convertToRange: function() {
        var ret = new Array(2)
        if (this._value || this._value === 0) ret[0] = this._value
        this._value = ret
      },

      /**
       * Updates the collection the rule belongs to. This is meant to be overwritten by the collection when the rule is
       * added.
       *
       * @private
       */
      _updateCollection: function() {}, // overwritten when models are added to a collection

      /**
       * Updates the valid field on the rule.
       *
       * @private
       */
      _validate: function() {
        this.valid = !!this._field && !!this._logic && (this._logic.type === 'null' || _.all([].concat(this._value), isPresent))
        this._updateCollection()
      }
    }
    _.extend(FilterRule.prototype, instanceMethods)

    /**
     * Determine if the given value is not null or an empty string.
     *
     * @private
     * @param {*} value - The value to evaluate.
     * @return {boolean} - True if the value is present. False otherwise.
     */
    function isPresent(value) {
      return !(_.isNull(value) || _.isUndefined(value) || value === '')
    }

    /**
     * Determines if the provided logic type is a ranged type.
     *
     * @private
     * @param {string} type - The type to evaluate.
     * @return {boolean} - True if the type is a ranged type. False otherwise.
     */
    function rangeLogicType(type) {
      return _.startsWith(type, 'between_') || _.endsWith(type, '_in')
    }

    /**
     * Determines if the provided logic type are similar. Similar logic type generally have the same data type.
     *
     * @private
     * @param {string} newLogicType - The new logic type to compare.
     * @param {string} oldLogicType - The old logic type to compare.
     * @return {boolean} - True if the two types are similar. False otherwise.
     */
    function similarLogicTypes(newLogicType, oldLogicType) {
      newLogicType = newLogicType.replace(/^between_|_in$/, '')
      oldLogicType = oldLogicType.replace(/^between_|_in$/, '')
      return newLogicType === oldLogicType
    }

    return FilterRule
  })
