angular.module('org-admin')
  .directive('adyenRejectionCode', function() {
    return {
      scope: {},
      bindToController: {
        merchantErrors: '<',
        documentErrorCode: '<',
        merchantErrorMessage: '<',
        documentImageName: '<',
        documentErrorMessage: '<'
      },
      templateUrl: '/static/org/adyen-reject-reasons/adyen-rejection-code.html',
      controllerAs: 'ctrl',
      controller: function(i18ng) {
        var ctrl = this
        ctrl.link = 'https://help.sportsengine.com/customer/portal/emails/new'
        if (ctrl.merchantErrors) ctrl.merchantErrorCodes = _.uniq(ctrl.merchantErrors.split(', '))

        ctrl.translateErrorCode = function(code, opts) {
          var messageKey = 'ADYEN_REJECTION_CODES.' + code
          if (code === '1501') return i18ng.t(messageKey, opts)
          var translatedMessage = i18ng.t(messageKey, opts)
          if (translatedMessage === messageKey) ctrl.showGenericError = true
          return translatedMessage
        }
      }
    }
  })
