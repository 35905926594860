angular.module('org-admin')
  .factory('EligibilityUtilService', function(_, Survey, currentOrg, i18ng) {

    var ORIGINATOR_TYPE_MEMDEF = 'membership_definition'
    var ORIGINATOR_TYPE_NGIN_CREDENTIAL = 'ngin_credential'
    var ORIGINATOR_TYPE_ELECTRONIC_DOCUMENT = 'electronic_document'
    var ORIGINATOR_TYPE_WAIVER = 'waiver'
    var ORIGINATOR_TYPE_SAFESPORT = 'safesport'
    var ORIGINATOR_TYPE_SSD = 'sideline_sports'
    var ORIGINATOR_TYPE_APS = 'aps'
    var ORIGINATOR_TYPE_PROOF_OF_BIRTH = 'proof_of_birth'
    var ORIGINATOR_TYPE_BGCHECK = 'background_screen'
    var ORIGINATOR_TYPE_FIFA = 'fifa_integration'
    var ORIGINATOR_TYPE_VIOLATION_REVIEW = 'violation_review'
    var ORIGINATOR_TYPE_LITMOS_IMPACT = 'course-C01'
    var ORIGINATOR_TYPE_LMS_COURSE = 'course'

    var ORIGINATOR_ID_SAFESPORT = 'training'
    var ORIGINATOR_ID_SSD = 'sideline_sports'
    var ORIGINATOR_ID_APS = 'aps'
    var ORIGINATOR_ID_LITMOS_IMPACT = 'litmos_impact'
    var ORIGINATOR_ID_FIFA = 'fifa_integration'
    var ORIGINATOR_ID_LMS_CRASHCOURSE = 'lms_crash_course'

    var ORIGINATOR_SYSTEM_MEMBERSHIPS = 'MembershipService'
    var ORIGINATOR_SYSTEM_ABSORB = 'SeAbsorbService'
    var ORIGINATOR_SYSTEM_SSD = 'SeSsdIntegrationService'
    var ORIGINATOR_SYSTEM_APS = 'SeApsService'
    var ORIGINATOR_SYSTEM_FIFA = 'SeFifaProvider'
    var ORIGINATOR_SYSTEM_PROOF_OF_BIRTH = 'ProofOfBirthService'
    var ORIGINATOR_SYSTEM_BGCHECK = 'Ngin'
    var ORIGINATOR_SYSTEM_VIOLATION_REVIEW = 'MembershipService'
    var ORIGINATOR_SYSTEM_LITMOS = 'SeLitmosService'
    var ORIGINATOR_SYSTEM_LMS = 'SeLmsService'
    var ORIGINATOR_SYSTEM_NGIN = 'Ngin'

    var service = {
      getMembershipEligibilityObject: getMembershipEligibilityObject,
      getMembershipEligibilityRules: getMembershipEligibilityRules,
      getUpdatedEligibilityRules: getUpdatedEligibilityRules,
      getRulesByType: getRulesByType,
      hasEligibility: hasEligibility,
      loadBackgroundChecks: loadBackgroundChecks,
      getBgCheckLabel: getBgCheckLabel,
      isAnyBackgroundScreen: isAnyBackgroundScreen,
      isLegacyBackgroundScreen: isLegacyBackgroundScreen,
      getElectronicDocumentsLabel: getElectronicDocumentsLabel,
      getCustomCredentialsLabel: getCustomCredentialsLabel,
      getTrainingOptionValue: getTrainingOptionValue,
    }

    return service

    /**
     * maps eligibility_rule_set.eligibility_rules to an object that can be used
     * in membership eligibility forms to populate selected values
     * @param {EligibilityRuleSet} eligibility
     * @return {MembershipEligibilityObject}
     */
    function getMembershipEligibilityObject(eligibility) {
      var bgChecks = getRulesByType(eligibility, ORIGINATOR_TYPE_BGCHECK, null)
      var bgCheck = bgChecks.length ? parseInt(bgChecks[0].originator_id, 10) : null
      var proofOfBirth = getRulesByType(eligibility, ORIGINATOR_TYPE_PROOF_OF_BIRTH, null)
      var customCredentials = getRulesByType(eligibility, ORIGINATOR_TYPE_NGIN_CREDENTIAL, null)
      var electronicDocuments = getRulesByType(eligibility, ORIGINATOR_TYPE_ELECTRONIC_DOCUMENT, null)
      var waivers = getRulesByType(eligibility, ORIGINATOR_TYPE_WAIVER, null)
      var safesport = getRulesByType(eligibility, ORIGINATOR_TYPE_SAFESPORT, null)
      var ssd = getRulesByType(eligibility, ORIGINATOR_TYPE_SSD, null)
      var aps = getRulesByType(eligibility, ORIGINATOR_TYPE_APS, null)
      var impact = getRulesByType(eligibility, ORIGINATOR_TYPE_LITMOS_IMPACT, null)
      var lmsConcussionTraining = getRules(eligibility, ORIGINATOR_SYSTEM_LMS, ORIGINATOR_TYPE_LMS_COURSE, null)
      var trainings = [].concat(safesport, ssd, aps, impact, lmsConcussionTraining)
      trainings = trainings.map(function(rule) {
        return getTrainingOptionValue(rule.originator_system, rule.originator_type)
      })
      var fifaIntegration = getRulesByType(eligibility, ORIGINATOR_TYPE_FIFA, null)
      var violationReview = getRulesByType(eligibility, ORIGINATOR_TYPE_VIOLATION_REVIEW, null)

      return {
        background_check_required: !!(bgChecks && bgChecks.length),
        background_checks: _.map(bgChecks, function(rule) { return rule.originator_id }),
        background_check: bgCheck,
        custom_credentials_required: !!(customCredentials && customCredentials.length),
        custom_credentials: _.map(customCredentials, function(rule) { return rule.originator_id }),
        proof_of_birth_required: !!(proofOfBirth && proofOfBirth.length),
        training_required: !!(trainings && trainings.length),
        trainings: trainings,
        electronic_document_required: !!(electronicDocuments && electronicDocuments.length),
        electronic_documents: _.map(electronicDocuments, function(rule) { return rule.originator_id }),
        waiver_required: !!(waivers && waivers.length),
        waivers: _.map(waivers, function(rule) { return rule.originator_id }),
        fifa_integration_required: !!(fifaIntegration && fifaIntegration.length),
        violation_review_required: !!(violationReview && violationReview.length)
      }
    }

    /**
     * maps eligibility_rule_set.eligibility_rules to an object that can be used
     * to display existing
     * @param {EligibilityRuleSet} eligibility
     * @return {MembershipEligibilityRules}
     */
    function getMembershipEligibilityRules(eligibility) {
      var bgChecks = getRulesByType(eligibility, ORIGINATOR_TYPE_BGCHECK, null)
      var proofOfBirth = getRulesByType(eligibility, ORIGINATOR_TYPE_PROOF_OF_BIRTH, null)
      var customCredentials = getRulesByType(eligibility, ORIGINATOR_TYPE_NGIN_CREDENTIAL, null)
      var electronicDocuments = getRulesByType(eligibility, ORIGINATOR_TYPE_ELECTRONIC_DOCUMENT, null)
      var waivers = getRulesByType(eligibility, ORIGINATOR_TYPE_WAIVER, null)
      var safesport = getRulesByType(eligibility, ORIGINATOR_TYPE_SAFESPORT, null)
      var ssd = getRulesByType(eligibility, ORIGINATOR_TYPE_SSD, null)
      var aps = getRulesByType(eligibility, ORIGINATOR_TYPE_APS, null)
      var impact = getRulesByType(eligibility, ORIGINATOR_TYPE_LITMOS_IMPACT, null)
      var lmsConcussionTraining = getRules(eligibility, ORIGINATOR_SYSTEM_LMS, ORIGINATOR_TYPE_LMS_COURSE, null)
      var trainings = [].concat(safesport, ssd, aps, impact, lmsConcussionTraining)
      var fifaIntegration = getRulesByType(eligibility, ORIGINATOR_TYPE_FIFA, null)
      var violationReview = getRulesByType(eligibility, ORIGINATOR_TYPE_VIOLATION_REVIEW, null)

      return {
        background_checks:  { items: bgChecks, required: !!(bgChecks && bgChecks.length) },
        proof_of_birth: { items: proofOfBirth, required: !!(proofOfBirth && proofOfBirth.length) },
        custom_credentials: { items: customCredentials, required: !!(customCredentials && customCredentials.length) },
        electronic_documents: { items: electronicDocuments, required: !!(electronicDocuments && electronicDocuments.length) },
        waivers: { items: waivers, required: !!(waivers && waivers.length) },
        trainings: { items: trainings, required: !!(trainings && trainings.length) },
        fifa_integration: { required: !!(fifaIntegration && fifaIntegration.length) },
        violation_review: { required: !!(violationReview && violationReview.length) }
      }
    }

    /**
     * helper function to grab rules by type
     * @param {EligibilityRuleSet} eligibility
     * @param {string} type
     * @param {string} linked_eligibility_rule_set_id
     */
    function getRulesByType(eligibility, type, linked_eligibility_rule_set_id) {
      var rules = _.filter(eligibility.eligibility_rules, { originator_type: type })
      if (linked_eligibility_rule_set_id !== undefined) {
        rules = _.filter(rules, { linked_eligibility_rule_set_id: linked_eligibility_rule_set_id })
      }
      return rules
    }

    /**
     * helper function to grab rules by system and type
     * @param {EligibilityRuleSet} eligibility
     * @param {string} system
     * @param {string} type
     * @param {string} linked_eligibility_rule_set_id
     */
    function getRules(eligibility, system, type, linked_eligibility_rule_set_id) {
      var rules = _.filter(eligibility.eligibility_rules, { originator_system: system, originator_type: type })
      if (linked_eligibility_rule_set_id !== undefined) {
        rules = _.filter(rules, { linked_eligibility_rule_set_id: linked_eligibility_rule_set_id })
      }
      return rules
    }

    /**
     * determines if any eligibility rules are selected
     * @param {MembershipEligibilityRules} eligibilityRules
     * @return boolean
     */
    function hasEligibility(eligibilityRules) {
      return _.any(Object.values(eligibilityRules), function(rule) {
        return rule.required
      })
    }

    /**
     * @param {EligibilityRuleSet} oldEligibility
     * @param {MembershipEligibilityObject} newSelections
     * @return {EligibilityRuleCollection}
     */
    function getUpdatedEligibilityRules(oldEligibility, newSelections) {
      var newRules = []

      // handle waivers
      var oldWaivers = getRulesByType(oldEligibility, ORIGINATOR_TYPE_WAIVER, null)
      var oldEDocs = getRulesByType(oldEligibility, ORIGINATOR_TYPE_ELECTRONIC_DOCUMENT, null)

      // delete old waivers as they will be replaced with edocs
      _.forEach(oldWaivers, function(oldRule) {
        newRules.push({ id: oldRule.id, _destroy: true })
      })
      _.forEach(oldEDocs, function(oldRule) {
        if (!newSelections.electronic_document_required || newSelections.electronic_documents.indexOf(oldRule.originator_id) === -1) {
          newRules.push({ id: oldRule.id, _destroy: true })
        }
      })

      // add newly required electronic_documents
      if (newSelections.electronic_document_required) {
        _.forEach(newSelections.electronic_documents, function(edocId) {
          if (!_.find(oldEDocs, { originator_id: edocId })) {
            newRules.push({
              originator_system: ORIGINATOR_SYSTEM_MEMBERSHIPS,
              originator_type: ORIGINATOR_TYPE_ELECTRONIC_DOCUMENT,
              originator_id: edocId,
            })
          }
        })
      }

      // handle training
      var safesport = getRulesByType(oldEligibility, ORIGINATOR_TYPE_SAFESPORT, null)
      var ssd = getRulesByType(oldEligibility, ORIGINATOR_TYPE_SSD, null)
      var aps = getRulesByType(oldEligibility, ORIGINATOR_TYPE_APS, null)
      var impact = getRulesByType(oldEligibility, ORIGINATOR_TYPE_LITMOS_IMPACT, null)
      var lmsConcussionTraining = getRules(oldEligibility, ORIGINATOR_SYSTEM_LMS, ORIGINATOR_TYPE_LMS_COURSE, null)
      var oldTrainings = [].concat(safesport, ssd, aps, impact, lmsConcussionTraining)
      _.forEach(oldTrainings, function(oldRule) {
        if (!newSelections.training_required || newSelections.trainings.map(getTrainingOriginatorType).indexOf(oldRule.originator_type) === -1) {
          newRules.push({ id: oldRule.id, _destroy: true })
        }
      })
      if (newSelections.training_required) {
        _.forEach(newSelections.trainings, function(trainingId) {
          if (!_.find(oldTrainings, { originator_type: getTrainingOriginatorType(trainingId) })) {
            newRules.push({
              originator_system: getTrainingOriginatorSystem(trainingId),
              originator_type: getTrainingOriginatorType(trainingId),
              originator_id: getTrainingOriginatorId(trainingId),
            })
          }
        })
      }

      // handle bg checks
      // currently only 1 bg_check selection is supported via ui, but backend supports multiple
      newSelections.background_checks = [newSelections.background_check]
      var oldBgChecks = getRulesByType(oldEligibility, ORIGINATOR_TYPE_BGCHECK, null)
      _.forEach(oldBgChecks, function(oldRule) {
        // if and when we ever add bg_screens outside of ngin, this needs to be updated to match on originator_system also
        if (!newSelections.background_check_required || newSelections.background_checks.indexOf(oldRule.originator_id) === -1) {
          newRules.push({ id: oldRule.id, _destroy: true })
        }
      })
      if (newSelections.background_check_required) {
        _.forEach(newSelections.background_checks, function(surveyId) {
          if (!_.find(oldBgChecks, { originator_id: surveyId })) {
            newRules.push({
              originator_system: ORIGINATOR_SYSTEM_BGCHECK,
              originator_type: ORIGINATOR_TYPE_BGCHECK,
              originator_id: surveyId,
            })
          }
        })
      }

      // handle custom credentials
      var oldCustomCredentials = getRulesByType(oldEligibility, ORIGINATOR_TYPE_NGIN_CREDENTIAL, null)
      if (!newSelections.custom_credentials_required) {
        oldCustomCredentials.forEach(function(cred) { newRules.push({ id: cred.id, _destroy: true }) })
      }
      else {
        newSelections.custom_credentials.filter(function(id) {
          return !oldCustomCredentials.find(function(rule) { return rule.originator_id.toString() === id.toString() })
        }).forEach(function(id) {
          newRules.push({
            originator_system: ORIGINATOR_SYSTEM_NGIN,
            originator_type: ORIGINATOR_TYPE_NGIN_CREDENTIAL,
            originator_id: id,
          })
        })
        oldCustomCredentials.filter(function(rule) {
          return !newSelections.custom_credentials.find(function(id) { return rule.originator_id.toString() === id.toString() })
        }).forEach(function(cred) { newRules.push({ id: cred.id, _destroy: true }) })
      }


      // handle fifa integration
      var oldFifaTraining = getRulesByType(oldEligibility, ORIGINATOR_TYPE_FIFA, null)
      switch (newSelections.fifa_integration_required) {
        case true: // Turned on
          if (oldFifaTraining.length > 0) {
            _.forEach(oldFifaTraining, function(oldRule) {
              newRules.push(oldRule)
            })
          }
          else {
            newRules.push({
              originator_system: ORIGINATOR_SYSTEM_FIFA,
              originator_type: ORIGINATOR_TYPE_FIFA,
              originator_id: ORIGINATOR_ID_FIFA
            })
          }
          break
        case false: // Turned off
          _.forEach(oldFifaTraining, function(oldRule) {
            newRules.push({ id: oldRule.id, _destroy: true })
          })
          break
        case undefined: // Option was unavailable
        default:
          _.forEach(oldFifaTraining, function(oldRule) {
            newRules.push(oldRule)
          })
          break
      }

      // handle proof_of_birth
      var oldPOB = getRulesByType(oldEligibility, ORIGINATOR_TYPE_PROOF_OF_BIRTH, null)
      switch (newSelections.proof_of_birth_required) {
        case true: // Turned on
          if (oldPOB.length > 0) {
            _.forEach(oldPOB, function(oldRule) {
              newRules.push(oldRule)
            })
          }
          else {
            newRules.push({
              originator_system: ORIGINATOR_SYSTEM_PROOF_OF_BIRTH,
              originator_type: ORIGINATOR_TYPE_PROOF_OF_BIRTH,
              originator_id: currentOrg.id
            })
          }
          break
        case false: // Turned off
          _.forEach(oldPOB, function(oldRule) {
            newRules.push({ id: oldRule.id, _destroy: true })
          })
          break
        case undefined: // Option was unavailable
        default:
          _.forEach(oldPOB, function(oldRule) {
            newRules.push(oldRule)
          })
          break
      }

      // handle violation_review
      var oldViolationReview = getRulesByType(oldEligibility, ORIGINATOR_TYPE_VIOLATION_REVIEW, null)
      switch (newSelections.violation_review_required) {
        case true: // Turned on
          if (oldViolationReview.length > 0) {
            _.forEach(oldViolationReview, function(oldRule) {
              newRules.push(oldRule)
            })
          }
          else {
            newRules.push({
              originator_system: ORIGINATOR_SYSTEM_VIOLATION_REVIEW,
              originator_type: ORIGINATOR_TYPE_VIOLATION_REVIEW,
              originator_id: oldEligibility.id
            })
          }
          break
        case false: // Turned off
          _.forEach(oldViolationReview, function(oldRule) {
            newRules.push({ id: oldRule.id, _destroy: true })
          })
          break
        case undefined: // Option was unavailable
        default:
          _.forEach(oldViolationReview, function(oldRule) {
            newRules.push(oldRule)
          })
          break
      }

      return newRules
    }

    /** Mapping selected option value to eligibility rule originator_system */
    function getTrainingOriginatorSystem(trainingId) {
      if (trainingId === ORIGINATOR_ID_SAFESPORT) return ORIGINATOR_SYSTEM_ABSORB
      if (trainingId === ORIGINATOR_ID_SSD) return ORIGINATOR_SYSTEM_SSD
      if (trainingId === ORIGINATOR_ID_APS) return ORIGINATOR_SYSTEM_APS
      if (trainingId === ORIGINATOR_ID_LITMOS_IMPACT) return ORIGINATOR_SYSTEM_LITMOS
      if (trainingId === ORIGINATOR_ID_LMS_CRASHCOURSE) return ORIGINATOR_SYSTEM_LMS
      return ORIGINATOR_SYSTEM_ABSORB // Fall back to safesport, since the "trainingId" can be "usav" etc.

    }

    /** Mapping selected option value to eligibility rule originator_type */
    function getTrainingOriginatorType(trainingId) {
      if (trainingId === ORIGINATOR_ID_SAFESPORT) return ORIGINATOR_TYPE_SAFESPORT
      if (trainingId === ORIGINATOR_ID_SSD) return ORIGINATOR_TYPE_SSD
      if (trainingId === ORIGINATOR_ID_APS) return ORIGINATOR_TYPE_APS
      if (trainingId === ORIGINATOR_ID_LITMOS_IMPACT) return ORIGINATOR_TYPE_LITMOS_IMPACT
      if (trainingId === ORIGINATOR_ID_LMS_CRASHCOURSE) return ORIGINATOR_TYPE_LMS_COURSE
      return ORIGINATOR_TYPE_SAFESPORT // Fall back to safesport, since the "trainingId" can be "usav" etc.
    }

    /** Mapping selected option value to eligibility rule originator_id */
    function getTrainingOriginatorId(trainingId) {
      if (trainingId === ORIGINATOR_ID_LITMOS_IMPACT) return currentOrg.id
      if (trainingId === ORIGINATOR_ID_LMS_CRASHCOURSE) return 'CrashCourse'
      if (trainingId === ORIGINATOR_TYPE_SAFESPORT) return ORIGINATOR_ID_SAFESPORT
      return trainingId
    }

    /** Mapping eligibility rule system and type to an option value */
    function getTrainingOptionValue(originatorSystem, originatorType) {
      if (originatorSystem === ORIGINATOR_SYSTEM_LITMOS && originatorType === ORIGINATOR_TYPE_LITMOS_IMPACT) return ORIGINATOR_ID_LITMOS_IMPACT
      if (originatorType === ORIGINATOR_TYPE_SAFESPORT) return ORIGINATOR_TYPE_SAFESPORT
      if (originatorSystem === ORIGINATOR_SYSTEM_LMS && originatorType === ORIGINATOR_TYPE_LMS_COURSE) return ORIGINATOR_ID_LMS_CRASHCOURSE
      if (originatorSystem === ORIGINATOR_SYSTEM_APS) return ORIGINATOR_ID_APS
      if (originatorSystem === ORIGINATOR_SYSTEM_SSD) return ORIGINATOR_ID_SSD
    }

    /**
     * return bgCheck options for an org (background_screen enabled surveys)
     * @param int orgId
     * @return Promise<SurveyCollection>
     */
    function loadBackgroundChecks(orgId) {
      return  Survey.findAll({
        background_screen_enabled: 'true',
        exclude_forms: true,
        exclude_team_only_surveys: 'true',
        order_by: 'created_at',
        organization_id: orgId,
        per_page: 100,
        survey_types: 'registration',
      })
    }

    /**
     * returns a label generated from the eligiblity rules waivers and waivers array
     * @param {MembershipEligibilityRules} eligiblityRules
     * @param {[]} waivers
     * @return {string}
     */
    function getElectronicDocumentsLabel(eligibilityRules, electronicDocuments) {
      if (!eligibilityRules || (!eligibilityRules.waivers && !eligibilityRules.electronic_documents) || !electronicDocuments) {
        return null
      }

      var eligibilityEdocs = []
      _.forEach((eligibilityRules.waivers.items), function(w) {
        var foundEdoc = _.find(electronicDocuments, { waiver_id: w.originator_id })
        if (foundEdoc) eligibilityEdocs.push(foundEdoc.current_version.title)
      })

      if (eligibilityRules.electronic_documents) {
        _.forEach((eligibilityRules.electronic_documents.items), function(rule) {
          var foundEdoc = _.find(electronicDocuments, { id: rule.originator_id })
          if (foundEdoc) eligibilityEdocs.push(foundEdoc.current_version.title)
        })
      }

      var edocsLabel = eligibilityEdocs.length ?
        eligibilityEdocs.join(', ') :
        i18ng.t('MEMBERSHIP_DEFINITION_INFO.ELIGIBILITY_REQUIREMENTS.not_required')

      return edocsLabel
    }

    function getCustomCredentialsLabel(eligibilityRules, customCredentials) {
      if (!eligibilityRules || !eligibilityRules.custom_credentials || !customCredentials) {
        return null
      }

      var label = eligibilityRules.custom_credentials.items.map(function(rule) {
        return customCredentials.find(function(credential) { return credential.key.toString() === rule.originator_id })?.name
      }).join(', ')

      return label ? label : i18ng.t('MEMBERSHIP_DEFINITION_INFO.ELIGIBILITY_REQUIREMENTS.not_required')
    }

    function isAnyBackgroundScreen(eligibilityRules) {
      var isAnyAllowed = false
      if (!eligibilityRules) return isAnyAllowed
      _.forEach((eligibilityRules.background_checks.items), function(bgsRule) {
        if (bgsRule.originator_id === 'any') isAnyAllowed = true
      })
      return isAnyAllowed
    }

    function isLegacyBackgroundScreen(eligibilityRules) {
      var isLegacyAllowed = false
      if (!eligibilityRules) return isLegacyAllowed
      _.forEach((eligibilityRules.background_checks.items), function(bgsRule) {
        if (bgsRule.originator_id === 'ncsi_legacy') isLegacyAllowed = true
      })
      return isLegacyAllowed
    }
    /**
      * returns a label generated from the eligibility rules bgcs and the bgcs array
      * @param {MembershipEligibilityRules} eligibilityRules
      * @param {[]} backgroundChecks
      * @return {string}
      */
    function getBgCheckLabel(eligibilityRules, backgroundChecks) {
      if (!eligibilityRules || !eligibilityRules.background_checks || (!backgroundChecks && !isAnyBackgroundScreen(eligibilityRules) && !isLegacyBackgroundScreen(eligibilityRules))) {
        return null
      }

      var eligibilityBgChecks = []
      _.forEach((eligibilityRules.background_checks.items), function(bgsRule) {
        if (bgsRule.originator_id === 'any' || bgsRule.originator_id === 'ncsi_legacy') eligibilityBgChecks.push(i18ng.t('MEMBERSHIP_DEFINITION_INFO.ELIGIBILITY_REQUIREMENTS.required'))
        var foundSurvey = _.find(backgroundChecks, { id: parseInt(bgsRule.originator_id, 10) })
        if (foundSurvey) eligibilityBgChecks.push(foundSurvey.name)
      })

      var bgCheckLabel = eligibilityBgChecks.length ?
        eligibilityBgChecks.join(', ') :
        i18ng.t('MEMBERSHIP_DEFINITION_INFO.ELIGIBILITY_REQUIREMENTS.not_required')

      return bgCheckLabel
    }
  })
