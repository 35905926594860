// <footer class="sn-page-footer sn-pane shrink">
//   <pagination collection="items" />
// </footer>


angular.module('pl-shared')
  .directive('pagination', function(paginationSettings, userSettings) {
    var paginationId = 0

    return {
      restrict: 'E',
      scope: {},
      controllerAs: 'ctrl',
      bindToController: {
        collection: '=',
        selectedItems: '=',
        filters: '=',
        label: '=',
        hideControls: '=',
        hidePerPage: '<',
        onAddSmartGroup: '&?',
        unfilteredTotal: '<?'
      },
      templateUrl: '/static/shared/components/pagination/pagination.html',
      controller: function($scope, $element, $timeout) {

        var $ = angular.element
        var ctrl = this

        ctrl.paginationId = 'pagination-' + (++paginationId)
        ctrl.perPageId = ctrl.paginationId + '-set-per-page'
        ctrl.settings = paginationSettings
        ctrl.gotoPage = gotoPage
        ctrl.getUnfilteredTotal = getUnfilteredTotal
        ctrl.filteredOutTotal = filteredOutTotal
        ctrl.page = 1

        $scope.$watchCollection(getPagination, updatePagingData)
        $scope.$watch('ctrl.page', gotoPage)
        $scope.$watch('ctrl.settings.perPage', updatePerPage)
        $element.on('focus', '.js-page', focusInput)
        $element.on('keydown', '.js-page', updateInput)

        function getPagination() {
          return ctrl.collection && ctrl.collection.pagination
        }

        /**
         * Get the total count of the collection without filters applied.
         *
         * @returns {number} - The unfiltered total of the collection.
         */
        function getUnfilteredTotal() {
          if (_.isUndefined(ctrl.unfilteredTotal)) {
            var pagination = getPagination()
            return pagination.unfiltered_total
          }

          return ctrl.unfilteredTotal
        }

        /**
         * Calculates the total count of the collection elements that do not satisfy the filters conditions.
         *
         * @returns {number} - The filtered out total of the collection.
         */
        function filteredOutTotal() {
          var pagination = getPagination()
          return getUnfilteredTotal() - pagination.total
        }

        function gotoPage(page, force) {
          var pagination = getPagination()
          if (!pagination || !pagination.valid || !pagination.goto) return // sometimes this can be an empty object...?
          page = pagination.valid(page)
          if (page === null || page === pagination.current_page) return
          pagination.goto(page)
        }

        function focusInput(e) {
          $(e.currentTarget).select()
        }

        function updateInput(e) {
          if (!isFinite(parseInt(e.key, 10))) {
            $timeout(function() {
              e.target.value = e.target.value.replace(/[^0-9]/g, '')
            })
          }
          if (e.which === 13) $(e.currentTarget).blur().focus()
        }

        function updatePagingData(pagination) {
          if (!pagination) return
          ctrl.page = pagination.current_page
          ctrl.start = pagination.total ? (ctrl.page - 1) * pagination.limit + 1 : 0
          ctrl.end = Math.min(ctrl.page * pagination.limit, pagination.total)
          ctrl.min = 1
          ctrl.max = pagination.total_pages
        }

        function updatePerPage(newVal, oldVal) {
          var pagination = getPagination()
          if (!pagination || newVal === oldVal) return
          userSettings.set('pagination.perPage', newVal)
          pagination.setPerPage(newVal)
        }

      }
    }
  })
