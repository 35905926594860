angular.module('org-admin')
  .directive('activityReportList', function() {
    return {
      scope: { },
      templateUrl: '/static/org/reports/activity-report-list.html',
      controllerAs: 'ctrl',
      controller: function(
        $scope,
        $window,
        $filter,
        $http,
        apiV2,
        setAs,
        currentOrg,
        i18ng,
        ActivityReport,
        ReportsService,
        ENV
      ) {

        var ctrl = this
        ctrl.activities = { }
        ctrl.loading = true
        ctrl.loadNginOrder = true
        ctrl.reload = true
        ctrl.params = {
          start_date: sessionStorage.getItem('report_start_date') || moment().startOf('month').format('YYYY-MM-DD'),
          end_date: sessionStorage.getItem('report_end_date') || moment().startOf('day').format('YYYY-MM-DD')
        }
        ctrl.sortColumns = { 'order[datetime]': 'desc' }
        getLatestNginOrder()

        ctrl.typeSelector = {
          init: function() {
            ctrl.typeSelector.selected = sessionStorage.getItem('selected_activity_report') || ctrl.typeSelector.defaultActivity
          },
          defaultActivity: 'online_payments',
          activities: function() {
            return ctrl.activities[ctrl.typeSelector.selected]
          },
          total: function() {
            return ctrl.activities.totals[ctrl.typeSelector.selected]
          },
          select2Options: {
            minimumResultsForSearch: Infinity,
            templateResult: function(data) {
              switch (data.id) {
                case 'online_payments':
                  return i18ng.t('ACTIVITY_REPORT.TYPE_SELECTOR.online')
                case 'offline_payments':
                  return i18ng.t('ACTIVITY_REPORT.TYPE_SELECTOR.offline')
                case 'online_refunds':
                  return i18ng.t('ACTIVITY_REPORT.TYPE_SELECTOR.online')
                case 'offline_refunds':
                  return i18ng.t('ACTIVITY_REPORT.TYPE_SELECTOR.offline')
              }
              return data.text
            }
          },
          labels: { },
          updatePreviousSelection: function() {
            sessionStorage.setItem('selected_activity_report', ctrl.typeSelector.selected)
          }
        }

        ctrl.isSelectedActivityEmpty = function() {
          return ctrl.typeSelector.activities().length === 0
        }

        ctrl.isPurchase = function(activity) {
          return activity.activity === 'Purchase' || activity.invoice_group_number === null
        }

        ctrl.isRefund = function(typeSelected) {
          return typeSelected === 'online_refunds' || typeSelected === 'offline_refunds'
        }

        ctrl.showTypeColumn = function() {
          return _.includes(['online_payments', 'offline_payments', 'online_refunds', 'offline_refunds'], ctrl.typeSelector.selected)
        }

        ctrl.showStatusColumn = function() {
          return _.includes(['online_payments', 'online_refunds', 'invoices'], ctrl.typeSelector.selected)
        }

        ctrl.statusRequiresWarning = function(status) {
          return _.includes(['past-due', 'declined', 'chargeback'], status)
        }

        ctrl.statusDisputed = function(status) {
          return status === 'disputed'
        }

        ctrl.wrapRefundOutputs = function(isRefund, currency, currencySymbol) {
          if (currency && currency.indexOf('$') < 0) {
            currency = $filter('currency')(currency, currencySymbol)
          }
          return isRefund ? '(' + currency + ')' : currency
        }

        ctrl.viewLegacyReports = function() {
          ReportsService.viewLegacyReports()
        }

        ctrl.exportReport = function() {
          var params = angular.element.param({
            start_date: ctrl.params.start_date,
            end_date: ctrl.params.end_date
          })
          $window.open(ENV.urls.apiProxy + '/reports/activity/' + currentOrg.id + '?format=csv&' + params)
        }

        function replaceSortParam(newSort, oldSort) {
          _.each(oldSort, function(dir, col) {
            delete ctrl.params[col]
          })
          _.extend(ctrl.params, newSort)
          ctrl.reload = true
        }
        $scope.$watch('ctrl.sortColumns', replaceSortParam)

        function reloadActivities() {
          if (!ctrl.reload) return
          ctrl.loadActivities()
          ctrl.reload = false
        }

        ctrl.loadActivities = function() {
          ctrl.activities.loading = true
          sessionStorage.setItem('report_start_date', ctrl.params.start_date)
          sessionStorage.setItem('report_end_date', ctrl.params.end_date)
          return ActivityReport.findAll(currentOrg.id, ctrl.params)
            .then(setAs(ctrl, 'activities'))
            .then(function() {
              ctrl.totals = ctrl.activities.totals
            })
            .catch(function(data, status) {
              console.error('Error updating program', data, status)
            })
            .finally(setAs(ctrl, 'loading', false))
        }

        function getLatestNginOrder() {
          ReportsService.getLatestNginOrder()
            .then(function(response) {
              ctrl.anyNginOrders = !!response.data.result
            })
            .catch(setAs(ctrl, 'failure'))
            .finally(setAs(ctrl, 'loadNginOrder', false))
        }

        $scope.$watch('ctrl.reload', reloadActivities, true)
      }
    }
  })
  .filter('status_decorator', function() {
    return function(input) {
      input = input || ''
      if (input == 'paid_offline') {
        input = 'paid'
      }
      return _.capitalize(input)
    }
  })
