angular.module('org-admin')

  .service('ItemVariationService', function(DS, paginate) {

    var ItemVariations = DS.defineResource({
      name: 'item_variations',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
      actions: {
        count: {
          method: 'GET',
        }
      }
    })

    return paginate(ItemVariations)
  })
