angular.module('org-admin')
  .component('groupInfo', {
    bindings: {
      group: '<',
      org: '<',
      onGroupUpdated: '&?'
    },
    templateUrl: '/static/org/groups/group-info.html',
    controller: function groupInfoController(
      $scope, dialog, filterCollectionFactory, filterPanelService, i18ng,
      setAs, SmartGroupPersona, _, MembershipsUtilService, ENV) {
      var $ctrl = this

      $ctrl.filterCollection = null
      $ctrl.filterPanelStatus = {
        readOnly: true
      }

      $ctrl.isFormBuilderSmartGroup = isFormBuilderSmartGroup
      $ctrl.isElasticSmartGroup = isElasticSmartGroup
      $ctrl.isStaticGroup = isStaticGroup
      $ctrl.isSmartGroup = isSmartGroup
      $ctrl.editFilterPanel = editFilterPanel
      $ctrl.editStaticGroup = editStaticGroup
      $ctrl.editSmartGroup = editSmartGroup
      $ctrl.getACPGroupUrl = getACPGroupUrl
      $ctrl.getTitle = getTitle
      $ctrl.updateGroup = updateGroup
      $ctrl.canPurchaseOboMemberships = false
      $ctrl.useMemberIdSource = false

      $ctrl.$onInit = $onInit

      function $onInit() {
        $scope.$watch('$ctrl.group', updateFilters)

        MembershipsUtilService.loadPurchaseableOboMemberships($ctrl.org.id)
          .then(function(result) {
            $ctrl.canPurchaseOboMemberships = _.some(result)
          })

        $ctrl.useMemberIdSource = filterPanelService.filterSourceHasMemDef($ctrl.group.filters)
      }

      function updateGroup(filterCollection) {
        return SmartGroupPersona.update($ctrl.group.group_id, {
          org_id: $ctrl.org.id,
          filters: filterCollection.serialize()
        })
          .then($ctrl.onGroupUpdated)
      }

      function updateFilters(group) {
        $ctrl.filters = null

        if (!group) return

        if (!group.filters) {
          $ctrl.loaded = group && !$ctrl.isSmartGroup()
        }
        else {
          filterCollectionFactory.buildFilterCollection({ filterRuleInfos: group.filters })
            .then(setAs($ctrl, 'filterCollection'))
            .finally(setAs($ctrl, 'loaded', true))
        }
      }

      function isFormBuilderSmartGroup() {
        return !!$ctrl.group && $ctrl.group.group_type === 'FormBuilder::SmartGroup'
      }

      function isElasticSmartGroup() {
        return !!$ctrl.group && $ctrl.group.group_type === 'ElasticSmartGroup'
      }

      /**
       * Indicates if the current group is a smart group.
       *
       * @private
       * @return {boolean} - True if the current group is a smart group. False otherwise.
       */
      function isSmartGroup() {
        return !!$ctrl.group && $ctrl.group.type === 'Smart Group'
      }

      /**
       * Transitions the panel into edit mode.
       */
      function editFilterPanel() {
        $ctrl.filterPanelStatus.readOnly = false
      }

      /**
       * Indicates if the current group is a static group.
       *
       * @private
       * @return {boolean} - True if the current group is a static group. False otherwise.
       */
      function isStaticGroup() {
        return !!$ctrl.group && $ctrl.group.type === 'Static Group'
      }

      /**
       * Opens a dialog that allows the user to edit information on a static group.
       *
       * @returns {Promise} - A promise that is resolved once the dialog is closed.
       */
      function editStaticGroup() {
        return dialog.confirm({
          component: 'edit-static-group',
          attrs: { group: $ctrl.group }
        })
      }

      /**
       * Opens a dialog that allows the user to edit information on a smart group.
       *
       * @returns {Promise} - A promise that is resolved once the dialog is closed.
       */
      function editSmartGroup() {
        return dialog.confirm({
          component: 'edit-smart-group',
          attrs: { group: $ctrl.group }
        })
      }

      /**
       * Generates a url for the group in the Admin Control Panel
       *
       * @return {string} - The url for the group.
       */
      function getACPGroupUrl() {
        if (!$ctrl.org || !$ctrl.group) {
          return ''
        }

        var groupTypes = {
          'Static Group': 'static_group',
          'FormBuilder::SmartGroup': 'smart_group',
          'ElasticSmartGroup': 'smart_group',
          'Roster': 'static_group'
        }
        var groupType = groupTypes[$ctrl.group.group_type]
        if (!groupType) {
          throw new Error('Unknown group type: ' + $ctrl.group.group_type)
        }

        return '{site_url}/{group_type}/show/{group_id}'
          .replace('{site_url}', siteUrl())
          .replace('{group_type}', groupType)
          .replace('{group_id}', $ctrl.group.group_id)
      }

      function getTitle() {
        return $ctrl.appliedFiltersCount ?
          i18ng.t('CONTACTS_LIST.filter_count', { count: $ctrl.appliedFiltersCount }) :
          i18ng.t('CONTACTS_LIST.filter')
      }

      function siteUrl() {
        return ENV.urls.siteRedirect + '/' + $ctrl.org.id
      }

    }
  })
