angular.module('pl-shared')

  .component('modalNavBar', {

    replace: true,

    bindings: {
      backContext: '@',
      backText: '@',
      contextStrategy: '@'
    },

    templateUrl: '/static/shared/components/modal-nav-bar/modal-nav-bar.html',

    controller: function() {}

  })
