var MEMBERSHIP_CS_VERIFICATION_SHOW_UNTIL = '2019-09-10'
var USCLUB_ORG_ID = 32165

angular.module('org-admin')
  .component('hqDashboardRecommendedActions', {
    templateUrl: '/static/org/hq-dashboard/hq-dashboard-recommended-actions.component.html',
    bindings: {
      showActionTypes: '@',
      onHasActions: '&'
    },
    controller: function(_, $q, $http, dateFilter, currentOrg, OffersService, appPermission, MembershipDefinition, pageViewHandler, ENV) {
      var $ctrl = this
      var allActionTypes = ['offers', 'membershipRequests', 'customerService']
      var limitedActionTypes

      $ctrl.$onInit = loadActions
      $ctrl.trackLoadOffer = trackLoadOffer
      $ctrl.trackHideOffer = trackHideOffer

      function loadActions() {
        if ($ctrl.showActionTypes) limitedActionTypes = $ctrl.showActionTypes.split(/,\s*/)
        var allowedTypes = limitedActionTypes || allActionTypes
        var requests = {
          offers: _.includes(allowedTypes, 'offers') && loadOffers(),
          customerService: _.includes(allowedTypes, 'customerService') && loadCustomerServiceActions(),
          membershipRequests: _.includes(allowedTypes, 'membershipRequests') && loadMembershipRequests()
        }
        $q.all(requests).then(loadActionsComplete)
      }

      function loadActionsComplete(data) {
        $ctrl.loaded = true
        if (data.offers || data.customerService || data.membershipRequests) $ctrl.onHasActions()
      }

      function loadOffers() {
        $ctrl.offers = OffersService.get()
        return !!$ctrl.offers.length
      }

      function loadMembershipRequests() {
        if (!appPermission.memberships.usable) return false
        return orgUsesMembershipAssignment()
          .then(countPendingMembershipRequests)
          .then(setMembershipRequestStatus)
          .catch(_.constant(false))
      }

      function orgUsesMembershipAssignment() {
        return MembershipDefinition
          .findAll({
            boss_organization_id: currentOrg.id,
            membership_assignment_type: ['allow_paid', 'allow_free'],
            per_page: 1
          }).then(function(memDefs) {
            return !!memDefs.length
          })
      }

      function countPendingMembershipRequests() {
        var params = {
          membership_definition_organization_ids: [currentOrg.id],
          status: ['approval_needed', 'under_review']
        }
        var options = {
          headers: {
            Accept: 'application/vnd.ngin-api.v2, application/json'
          },
          withCredentials: true
        }
        return $http
          .post(ENV.urls.apiProxy + '/v3/eligibility/membership_requests', params, options)
          .then(function(resp) {
            return _.get(resp, 'data.metadata.pagination.total', 0)
          })
      }

      function setMembershipRequestStatus(pendingCount) {
        $ctrl.pendingMembershipRequests = pendingCount
        return $ctrl.showMembershipRequests = !!pendingCount || alwaysShow('membershipRequests')
      }

      function alwaysShow(type) {
        return !!limitedActionTypes && _.includes(limitedActionTypes, type)
      }

      function trackLoadOffer(key) {
        let action = 'Offers' + upCase(key) + '.Load'
        pageViewHandler.fireEvent(action)
      }

      function trackHideOffer(offer) {
        let action = 'Offers' + upCase(offer.key) + '.Hide'
        pageViewHandler.fireEvent(action)
        offer.hide()
      }

      function loadCustomerServiceActions() {
        return setCustomerVerificationStatus()
      }

      function setCustomerVerificationStatus() {
        var today = dateFilter(Date.now(), 'yyyy-MM-dd')
        var isUSClubOrg = _.some(currentOrg.root_organizations, { id: USCLUB_ORG_ID })
        var isDateValid = (today <= MEMBERSHIP_CS_VERIFICATION_SHOW_UNTIL)
        return $ctrl.showCustomerServiceVerification = isUSClubOrg && isDateValid
      }

      function upCase(str) {
        return str.charAt(0).toUpperCase() + str.slice(1)
      }

    }
  })
