angular.module('org-admin')

  .directive('sendInvoices', function() {

    return {
      restrict: 'A',
      scope: {},
      bindToController: {
        groupPersonas: '<',
        recipients: '<',
        showStaticRecipients: '<',
        cancel: '=',
        confirm: '='
      },
      controllerAs: 'ctrl',
      templateUrl: '/static/org/send-invoices/send-invoices.html',
      controller: function(
        _,
        $element,
        $q,
        $scope,
        Alerts,
        currentOrg,
        InvoiceGroup,
        moment,
        setAs,
        setWhile,
        Store
      ) {

        var ctrl = this

        ctrl.step = 0
        ctrl.maxStep = 4
        ctrl.loading = true
        ctrl.invoiceAmountChanged = false
        ctrl.$onInit = $onInit

        $q.when(getInvoiceGroup())
          .then(setAs(ctrl, 'invoiceGroup'))
          .then(setAs(ctrl, 'invoiceGroup.itemized', true))
          .then(findStore)
          .then(setStoreFees)
          .then(setAs(ctrl, 'loading', false))

        ctrl.nextStep = function() {
          if (ctrl.step === 2 && !ctrl.invoiceGroup.formValid) {
            ctrl.paymentTermError = true
          }
          else {
            if (ctrl.failure && ctrl.step === 2) ctrl.failure = null
            ctrl.paymentTermError = false
            ctrl.step++
          }
        }

        ctrl.prevStep = function() {
          if (ctrl.step === 0) return
          ctrl.step--
        }

        ctrl.submit = function() {
          if (ctrl.saving) return

          ctrl.invoiceGroup.due_date = dueDate()
          return setWhile(ctrl, 'saving', ctrl.invoiceGroup.saveAll())()
            .then(
              function(response) {
                sendRequests()
              },
              function(response) {
                ctrl.saving = false
                if (response.data && response.data.error) {
                  scrollToTop()
                  ctrl.failure = response.data.error.messages ? [response.data.error.messages[0]] : [response.data.error.message]
                }
              }
            )
        }

        /**
         *  Private Controller Methods
         */
        function $onInit() {
          ctrl.forms = [
            'generalInfoForm',
            'amountInfoForm',
            'paymentTermForm'
          ]
        }

        function getInvoiceGroup() {
          if (!ctrl.invoiceGroup) {
            return InvoiceGroup.new({ params: { organization_id: currentOrg.id } })
              .then(InvoiceGroup.createInstance)
          }
        }

        function findStore() {
          return Store.find(ctrl.invoiceGroup.store_id)
        }

        function setStoreFees(response) {
          ctrl.processingFee = response.variable_rate
          ctrl.transactionFee = response.transaction_fee
        }

        function sendRequests() {
          return ctrl.invoiceGroup.generateInvoices()
            .then(sendSuccess, saveSuccessSendError)
            .finally(setAs(ctrl, 'saving', false))
        }

        function sendSuccess() {
          Alerts.success('SEND_INVOICES.success_alert', { count: ctrl.invoiceGroup.persona_ids.length })
          $scope.$emit('invoiceGroup:updateRequest')
          ctrl.confirm(ctrl.invoiceGroup)
        }

        function saveSuccessSendError(response) {
          $scope.$emit('invoiceGroup:updateDraft')
          showFailure('submit')(response)
        }

        function showFailure(failureType) {
          return function(response) {
            var err = response.data && response.data.error || response.data
            var messages = [].concat(err && err.messages || err || [])
            scrollToTop()
            ctrl.failure = { type: failureType, messages: _.filter(messages) }
          }
        }

        function scrollToTop() {
          $($element.find('.pl-modal__content')[0]).scrollTop(0)
        }

        function dueDate() {
          if (!ctrl.invoiceGroup.payment_terms) return moment().format()

          var customDate = ctrl.invoiceGroup.payment_terms.find(function(paymentTerm) {
            return paymentTerm.payment_term_name === 'Due on Custom Date'
          })
          return (customDate && customDate.payment_term_dates[0]) || moment().format()
        }
      }
    }
  })
