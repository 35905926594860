angular.module('pl-shared')

  .service('sitesProvisioningService', function($http, currentOrg, apiV2, ENV) {

    function fetch() {
      var url = ENV.urls.apiProxy + '/sites?organization_id=' + currentOrg.id
      var opts = { headers: apiV2.headers, withCredentials: true }
      return $http.get(url, opts).then(checkIfComplete)
    }

    function checkIfComplete(resp) {
      var sites = apiV2.deserialize(resp)
      return {
        complete: sites.length > 0,
        incomplete: sites.length <= 0
      }
    }

    return {
      fetch: fetch
    }

  })

  .run(function(Provisioning, sitesProvisioningService) {

    Provisioning.register('sites', sitesProvisioningService.fetch)

  })
