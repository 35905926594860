angular.module('org-admin')

  .directive('programListingAdd', function() {

    return {
      scope: {},
      bindToController: {},
      templateUrl: '/static/org/program-listings/program-listing-add.html',
      controllerAs: 'ctrl',
      controller: function(_, $scope, ProgramListing, ProgramListingOptions, ProgramListingForms, Alerts, currentOrg, renderContext, i18ng, $routeParams) {

        var ctrl = this

        ctrl.programListing = {}
        ctrl.model = {}

        ctrl.forms = []
        ctrl.editMode = true
        ctrl.readOnly = false
        ctrl.organizationName = currentOrg.name
        ctrl.isInteger = false
        ctrl.validators = ProgramListing.validators

        ProgramListingOptions
          .getOptions()
          .then(parseOptions)
          .then(createForms)

        function parseOptions(programListingOptions) {
          if (currentOrg.sports && currentOrg.sports.length > 0) programListingOptions.sports = currentOrg.sports
          ctrl.loaded = true
          // set program type if query param is valid
          var programListingType = parseInt($routeParams.program_listing_type, 10)
          if (_.find(programListingOptions.program_listing_types, { value: programListingType })) {
            ctrl.programListing.program_listing_type = programListingType
          }
          return programListingOptions
        }

        function createForms(programListingOptions) {
          ctrl.model = _.clone(ctrl.programListing)
          ctrl.model.sports = programListingOptions.sports
          ctrl.forms.push(ProgramListingForms.addProgramListing(ctrl.model, programListingOptions))
          ctrl.formActions = [
            {
              label: 'Cancel',
              action: 'cancel'
            },
            {
              label: 'Publish',
              action: ctrl.submitPublish
            },
            {
              label: 'Save As Draft',
              action: ctrl.submitDraft
            }
          ]
          ctrl.syncForm = true
        }

        ctrl.cancel = function(formCtrl) {
          var prevState = renderContext._prevState() || {}
          renderContext.goto(prevState.context || 'programListings.active')
        }

        ctrl.submitPublish = function(data, formCtrl) {
          data.searchable = true
          return submit(data, formCtrl)
        }

        ctrl.submitDraft = function(data, formCtrl) {
          data.searchable = false
          return submit(data, formCtrl)
        }

        function submit(programListing, formCtrl) {
          programListing.org_id = currentOrg.id
          return ProgramListing
            .saveAndSyncLocation(programListing, ctrl.programListing)
            .then(submitSuccess)
            .catch(submitError)
        }

        function submitSuccess(programListing) {
          ctrl.programListing = programListing
          if (programListing.searchable) {
            Alerts.success('PROGRAM_LISTING_ADD.improve_add_more_info', { title: i18ng.t('PROGRAM_LISTING_ADD.program_created') })
          }
          else {
            Alerts.success('PROGRAM_LISTING_ADD.publish_to_post', { title: i18ng.t('PROGRAM_LISTING_ADD.draft_is_saved') })
          }
          $scope.$emit('publishStatusChanged', programListing.publishedStatus)
          renderContext.goto('programListings.programListing.info', { programListingId: programListing.id, newProgramListing: true })
        }

        function submitError(data, err) {
          var message = [].concat(_.get(err, 'data.error.messages')).join(', ')
          Alerts.error('FORM_RESULT.error' + (message ? '_message' : ''), { message: _.unescape(message) })
        }

      }
    }
  })
