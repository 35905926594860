angular.module('org-admin')
  .component('rosterTeamDetail', {
    bindings: {
      assignTeams: '&',
      transferPlayer: '&',
      team: '='
    },
    templateUrl: '/static/org/roster-management/roster-team-detail.html',
    controller: function($scope, $element, $filter, Alerts, appPermission, currentUser, ENV, Player, RosteringPersonas, RosteringState, setWhile) {

      var ctrl = this
      ctrl.state = RosteringState
      ctrl.sortColumns = {
        player: { last_name: 'asc' },
        coach: { last_name: 'asc' }
      }
      if (ctrl.state.transferable) ctrl.uniqueId = _.uniqueId()

      ctrl.positionsListParams = {
        limit: Infinity,
        separator_only: ', ',
        separator_final: ', '
      }

      ctrl.$onInit = setWhile(ctrl, 'loading', function() {
        if (!currentUser.personas) currentUser.getPersonas()
        ctrl.teamCenterUrl = ENV.urls.snFrontend + '/teams/' + ctrl.team.team_id + '?season_id=' + ctrl.team.season_id
        ctrl.teamPageUrl = ENV.urls.nginRedirect + '/ngin_redirect/team/' + ctrl.team.team_id + '?season_id=' + ctrl.team.season_id

        var params = { include_rostering_persona: 1, check_stats: 1, exclude_rostering_persona_players: 1, per_page: 25 }
        _.extend(params, _.pick(ctrl.team, 'team_id', 'season_id'))
        setOrderedTypes()
        return Player.findAll(params, { load: 'all' }) // js-data relations will load the data needed for display after this completes
      })
      $scope.$watch('$ctrl.state.type', setOrderedTypes)

      ctrl.dragStart = function(player, event) {
        if (event.dataTransfer.setDragImage) {
          var dragImage = $element.find('.ba-rostering__drag-image')
          dragImage.text(player.full_name)
          event.dataTransfer.setDragImage(dragImage[0], 0, 0)
        }
      }

      ctrl.removePlayer = function(playerToRemove) {
        var player = playerToRemove || ctrl.playerToRemove
        return player.DSDestroy()
          .then(function() {
            Alerts.success('ROSTERING.REMOVE.success', { player: player.fullName() })
            $scope.$emit('rostering:removeSuccess', player)
            ctrl.state.updateDataBySurveyId(player)
            ctrl.playerToRemove = null
          })
      }

      ctrl.removePlayerModal = function(playerToRemove) {
        if (playerToRemove.has_stats) return ctrl.playerToRemove = playerToRemove
        ctrl.removePlayer(playerToRemove) // If no stats, remove player without displaying remove player modal
      }

      ctrl.preventOwnStaffDelete = function(player) {
        return appPermission.rosterManagement.visible &&
               !appPermission.adminControlPanel.visible &&
               player.type === 'coach' &&
               currentUser.checkIfPlayerIsUser(player.persona_id)
      }

      ctrl.orderByProperty = function(type) {
        return function(player) {
          var prop = _.keys(ctrl.sortColumns[type])[0]
          if (!prop) return player.last_name

          switch (prop) {
            case 'age':
              return $filter('age')(player.rostering_persona.date_of_birth)
            case 'positions':
              return $filter('list')(player.positionsAbbrev, ctrl.positionsListParams)
            default:
              return player[prop]
          }
        }
      }

      ctrl.orderByReverse = function(type) {
        return _.values(ctrl.sortColumns[type])[0] == 'desc'
      }

      function setOrderedTypes() {
        ctrl.orderedTypes = ['player', 'coach']
        if (ctrl.state.type === 'coach') ctrl.orderedTypes.reverse()
      }

    }
  })
