angular.module('pl-shared')

  .factory('OrphanInvite', function(apiV2, DS) {
    var OrphanInvite = DS.defineResource({
      name: 'orphan_invites',
      endpoint: 'orphan_invites',
      basePath: DS.adapters.ngin_v2.defaults.basePath,
      httpConfig: DS.adapters.ngin_v2.defaults.httpConfig,
      defaultAdapter: 'ngin_v2',
      cacheResponse: false,
      computed: {
        orphan_invite_type: [
          'is_roster_player', 'is_member_invite',
          function(is_roster_player, is_member_invite) {
            if (is_roster_player) return 'roster_player'
            if (is_member_invite) return 'member_invite'
            return 'registration'
          }
        ],
      }
    })

    OrphanInvite.claim = function(id, data) {
      var postData = data || {}

      return OrphanInvite.create(postData, {
        endpoint: 'orphan_invites/' + id + '/claim',
        method: 'POST',
        response: apiV2.deserialize
      })
    }

    OrphanInvite.resend = function(id) {
      return OrphanInvite.create({}, {
        endpoint: 'orphan_invites/' + id + '/resend',
        method: 'PUT',
        response: apiV2.deserialize
      })
    }

    OrphanInvite.getMine = function() {
      return OrphanInvite.findAll({}, {
        endpoint: 'orphan_invites/mine',
        response: apiV2.deserialize
      })
    }

    return OrphanInvite
  })
