angular.module('org-admin')
  .component('membershipDefinitionPublishingForm', {
    templateUrl: '/static/org/memberships/membership-definition-publishing-form.html',
    bindings: {
      model: '=',
      next: '=',
      back: '=',
      organization: '=',
      submitting: '<'
    },
    controllerAs: '$ctrl',
    controller: function(
      _, moment,
      MembershipsUtilService
    ) {
      var $ctrl = this
      var forOrgZone = MembershipsUtilService.forOrgZone
      var getInvalidFields = MembershipsUtilService.getInvalidFields
      var trackAction = MembershipsUtilService.trackAction
      var defaultPublishDateTime = moment.tz(MembershipsUtilService.TIMEZONE)

      $ctrl.$onInit = $onInit
      $ctrl.validateAndSubmit = validateAndSubmit
      $ctrl.publish = publish
      $ctrl.unpublish = unpublish
      $ctrl.schedule = schedule

      function $onInit() {
        $ctrl.loaded = true
        $ctrl.isRenewal = !!$ctrl.model.parent_membership_definition_id
        if (!$ctrl.model.purchase_status || $ctrl.model.purchase_status === 'scheduled') {
          if (!$ctrl.purchase_open_date) return $ctrl.schedule()

          if ($ctrl.model.purchase_open && $ctrl.model.purchase_close) {
            $ctrl.model.purchase_status = 'scheduled'
          }
          $ctrl.purchase_open_date = forOrgZone($ctrl.model.purchase_open, defaultPublishDateTime)
          $ctrl.purchase_open_time = forOrgZone($ctrl.purchase_open_date, defaultPublishDateTime).format('HH:mm')

          $ctrl.purchase_close_date = forOrgZone($ctrl.model.purchase_close)
          $ctrl.purchase_close_time = ($ctrl.model.purchase_close ? forOrgZone($ctrl.model.purchase_close).format('HH:mm') : null)
        }
      }

      function getPublishingErrors() {
        var formErrors = $ctrl.publishingForm.$error.required
        if (formErrors) {
          return getInvalidFields(formErrors)
        }
        return null
      }

      function validateAndSubmit() {
        trackAction(getPublishingErrors(), 'SaveButton.Click')
        saveDates()
        $ctrl.publishingForm.validateAndSubmit($ctrl.next)
      }

      function clearPurchaseDates() {
        $ctrl.purchase_open_date = null
        $ctrl.purchase_open_time = null
        $ctrl.purchase_close_date = null
        $ctrl.purchase_close_time = null
      }

      function setDefaultPurchaseDates() {
        clearPurchaseDates()
        $ctrl.purchase_open_date = defaultPublishDateTime
        $ctrl.purchase_open_time = defaultPublishDateTime.format('HH:mm')
      }

      function publish() {
        setDefaultPurchaseDates()
        $ctrl.model.purchase_status = 'published'
      }

      function unpublish() {
        clearPurchaseDates()
        $ctrl.model.purchase_open = null
        $ctrl.model.purchase_close = null
        $ctrl.model.purchase_status = 'unpublished'
      }

      function schedule() {
        setDefaultPurchaseDates()
        $ctrl.model.purchase_status = 'scheduled'
      }

      function saveDates() {
        if ($ctrl.purchase_open_date) {
          $ctrl.purchase_open_date = forOrgZone($ctrl.purchase_open_date)
          $ctrl.purchase_open_date.set('hour', splitOrDefault($ctrl.purchase_open_time, 'hour'))
          $ctrl.purchase_open_date.set('minute', splitOrDefault($ctrl.purchase_open_time, 'minute'))
          $ctrl.model.purchase_open = $ctrl.purchase_open_date.format()
        }
        else $ctrl.model.purchase_open = null

        if ($ctrl.purchase_close_date) {
          $ctrl.purchase_close_date = forOrgZone($ctrl.purchase_close_date)
          $ctrl.purchase_close_date.set('hour', splitOrDefault($ctrl.purchase_close_time, 'hour'))
          $ctrl.purchase_close_date.set('minute', splitOrDefault($ctrl.purchase_close_time, 'minute'))
          $ctrl.model.purchase_close = $ctrl.purchase_close_date.format()
        }
        else $ctrl.model.purchase_close = null
      }

      function splitOrDefault(timeString, attr) {
        var arrayIndex, defaultValue = null
        if (attr === 'hour') {
          arrayIndex = 0
          defaultValue = 12
        }
        else {
          arrayIndex = 1
          defaultValue = 0
        }
        return (timeString ? timeString.split(':')[arrayIndex] : defaultValue)
      }
    }
  })
