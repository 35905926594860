angular.module('org-admin')
  .directive('transactionsList', function() {
    return {
      scope: {},
      templateUrl: '/static/org/transactions/transactions-list.html',
      controllerAs: 'ctrl',
      controller: function(
        debounceCallback,
        SaleTransactions,
        $scope,
        currentOrg,
        Search,
        SePagination,
        i18ng,
        PusherService,
        $window,
        $httpParamSerializer,
        ENV,
        currentUser,
        $http,
        launchDarklyFlags
      ) {

        var ctrl = this

        ctrl.loading = true
        ctrl.transactionsLoading = true
        ctrl.transactions = []
        ctrl.summary = {}
        ctrl.params = { organization_id: currentOrg.id }
        ctrl.sortColumns = { 'order[created_at]': 'desc' }
        ctrl.paginationObject = {}
        ctrl.currentUser = currentUser
        ctrl.pagination = new SePagination({
          loadDataFn: loadTransactions.bind(this),
          storageId: 'transactionList'
        })
        var searchFields = 'payment_methods.name,sales.sale_number,sale_transactions.id,sale_transactions.payment_identifier,sales.description'

        ctrl.transactionsExportable = launchDarklyFlags.transactionExport

        //Temporarily disabled
        // pusherSubscribe()

        ctrl.filterButtonLabel = function() {
          var status = ctrl.params.success
          if (!status) return i18ng.t('TRANSACTIONS_LIST.LABELS.status_filter')
          return i18ng.t('TRANSACTIONS_LIST.STATUS_LABEL.' + status)
        }

        ctrl.setStatusFilter = function(val) {
          ctrl.params.monetary_status = val
        }

        ctrl.search = Search.create({
          update: function(searchTerm) {
            ctrl.searchDirtied = true
            ctrl.params.search_fields = searchFields
            ctrl.params.search_term = String(searchTerm) || undefined
            loadTransactions()
          }
        })

        ctrl.exportReport = function() {
          ctrl.showExportError = false
          ctrl.showExportSuccess = !ctrl.showExportSuccess
          ctrl.params.user_email = currentUser.email.address
          ctrl.params.search_fields = searchFields
          ctrl.params.search_term = String(ctrl.search.term) || undefined
          var serializedParams = $httpParamSerializer(ctrl.params)
          var opts = {
            headers: { Accept: 'text/csv, application/json' },
            withCredentials: true
          }
          var url = ENV.urls.apiProxy + '/sale_transactions?' + serializedParams
          $http.get(url, opts)
            .then(function(response) {
              ctrl.showExportSuccess = true
            })
            .catch(function(error) {
              ctrl.showExportError = true
              ctrl.showExportSuccess = false
            })
        }

        $scope.$watch('ctrl.sortColumns', function(newSort, oldSort) {
          _.each(oldSort, function(dir, col) {
            delete ctrl.params[col]
          })
          _.extend(ctrl.params, newSort)
          loadTransactions()
        })

        var findAll = debounceCallback(SaleTransactions.findAll)

        function loadTransactions(paginationParams) {
          ctrl.transactionsLoading = true
          ctrl.params.page = 1
          if (ctrl.pagination) ctrl.params.per_page = ctrl.pagination.pageParams.per_page
          if (paginationParams) ctrl.params = angular.merge(ctrl.params, paginationParams)
          return findAll(ctrl.params)
            .then(function(response) {
              ctrl.paginationObject = response.pagination
              ctrl.transactions = response
              return response
            })
            .finally(function() {
              ctrl.loading = false
              ctrl.transactionsLoading = false
            })
        }

        //Temporarily disabled
        // function pusherSubscribe() {
        //   PusherService.subscribe('channel_clare_organization_' + currentOrg.id)
        //   PusherService.bind('event_transaction_created', loadTransactions)
        // }
      }
    }
  })
