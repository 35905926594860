angular.module('pl-shared')
  .service('Order', function(DS, i18ng, apiV2, paginate, statusTagColors) {
    function sortByUpdatedAt(array) {
      return _.sortBy(array, 'updated_at').reverse()
    }
    function sortByUpdatedAtGetter(Resource, relationDef, instance, origGetter) {
      return sortByUpdatedAt(origGetter())
    }

    var Order = DS.defineResource({
      name: 'orders',
      endpoint: 'commerce/orders',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
      actions: {
        activity: {
          method: 'GET',
        },
        sale_adjustments: {
          method: 'POST',
        },
      },
      computed: {
        payment_plan_account_type: [
          'payment_plan_payment_method',
          function(payment_plan_payment_method) {
            if (payment_plan_payment_method) {
              return i18ng.t('PAYMENT_ACCOUNT_TYPES.' + payment_plan_payment_method.acct_type)
            }
          }
        ]
      },
      methods: {
        activity: function() {
          return Order.activity(this.id)
            .then(apiV2.deserialize)
        },
        canBePaid: function() {
          return Number(this.amount_outstanding) > 0
        },
        credit: function(attrs) {
          return Order.sale_adjustments(this.id, { data: attrs })
            .then(apiV2.deserialize)
        },
        hasUpfrontPayment: function() {
          return _.any(this.payments, function(payment) {
            return payment.upfront_payment
          })
        },
        nonRefundablePayments: function() {
          return _.filter(this.payments, function(payment) {
            return payment.status !== 'unpaid' && !payment.can_be_refunded && !payment.zero_amount
          })
        },
        refundablePayments: function() {
          return _.filter(this.payments, { can_be_refunded: true })
        },
        significantActivity: function(viewMode) {
          return Order.activity(this.id, { params: { view_mode: viewMode || 'org', significance: 'high' } })
            .then(apiV2.deserialize)
        },
        statusTagColor: function() {
          return statusTagColors[this.monetary_status]
        },
        surveyEntries: function() {
          return _.filter(this.sale_sources, function(source) {
            return source.originator_type === 'survey_result'
          })
        },
        surveySource: function() {
          return _.find(this.sale_sources, function(source) {
            return source.originator_type === 'survey'
          })
        },
      },
      relations: {
        hasMany: {
          payments: {
            localField: 'payments',
            foreignKey: 'sale_id',
            get: sortByUpdatedAtGetter
          },
          refunds: {
            localField: 'refunds',
            foreignKey: 'sale_id',
            get: sortByUpdatedAtGetter
          }
        }
      }
    })

    return paginate(Order)
  })
