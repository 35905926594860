angular.module('pl-shared')

  .service('Invoice', function(_, i18ng, ENV, DS, paginate, apiV2, $http, statusTagColors) {
    function sortByUpdatedAt(array) {
      return _.sortBy(array, 'updated_at').reverse()
    }
    function sortByUpdatedAtGetter(Resource, relationDef, instance, origGetter) {
      return sortByUpdatedAt(origGetter())
    }

    var Invoice = DS.defineResource({
      name: 'invoices',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
      actions: {
        count: {
          method: 'GET',
        },
        activity: {
          method: 'GET',
        },
        void: {
          method: 'POST',
        },
        sale_adjustments: {
          method: 'POST',
        }
      },
      computed: {
        past_due: [
          'monetary_status',
          function(monetaryStatus) {
            return monetaryStatus === 'past-due'
          }
        ],
        is_settled: [
          'status',
          function(status) {
            return status === 'voided' || status === 'settled'
          }
        ],
        payment_plan_account_type: [
          'payment_plan_payment_method',
          function(payment_plan_payment_method) {
            if (payment_plan_payment_method) {
              return i18ng.t('PAYMENT_ACCOUNT_TYPES.' + payment_plan_payment_method.acct_type)
            }
          }
        ]
      },
      methods: {
        activity: function() {
          return Invoice.activity(this.id)
            .then(apiV2.deserialize)
        },
        significantActivity: function(viewMode) {
          return Invoice.activity(this.id, { params: { view_mode: viewMode || 'org', significance: 'high' } })
            .then(apiV2.deserialize)
        },
        void: function(attrs) {
          return Invoice.void(this.id, { data: attrs })
            .then(function(response) {
              var invoiceData = apiV2.deserialize(response)
              return Invoice.inject(invoiceData)
            })
        },
        credit: function(attrs) {
          return Invoice.sale_adjustments(this.id, { data: attrs })
            .then(apiV2.deserialize)
        },
        canBePaid: function() {
          return Number(this.amount_outstanding) > 0
        },
        statusTagColor: function() {
          var status = ['refunded', 'partial-refund'].includes(this.monetary_status) ? this.monetary_status : this.display_status
          return statusTagColors[status]
        },
        statusTagLabel: function() {
          if (['refunded', 'partial-refund'].includes(this.monetary_status)) {
            return i18ng.t('MONETARY_STATUS.' + this.monetary_status)
          }
          return i18ng.t('SALE_DETAIL.STATUSES.' + this.display_status)
        },
        hasUpfrontPayment: function() {
          return _.any(this.payments, function(payment) {
            return payment.upfront_payment
          })
        },
        refundablePayments: function() {
          return _.filter(this.payments, { can_be_refunded: true })
        },
        nonRefundablePayments: function() {
          return _.filter(this.payments, function(payment) {
            return payment.status !== 'unpaid' && !payment.can_be_refunded && !payment.zero_amount
          })
        }
      },
      relations: {
        hasMany: {
          payments: {
            localField: 'payments',
            foreignKey: 'sale_id',
            get: sortByUpdatedAtGetter
          },
          refunds: {
            localField: 'refunds',
            foreignKey: 'sale_id',
            get: sortByUpdatedAtGetter
          }
        }
      }
    })

    Invoice.getCreditReasons = async function(orgId) {
      const url = ENV.urls.apiProxy + '/v3/commerce/clare/tenants/' + orgId + '/credit_reasons'
      const response = await $http.get(url, {
        headers: { Accept: 'application/json' },
        withCredentials: true
      })
      const reasons = await apiV2.deserialize(response)
      return reasons
    }

    var _count = Invoice.count
    Invoice.count = function(params) {
      return _count({ params: params }).then(apiV2.deserialize)
    }

    return paginate(Invoice)
  })
