angular.module('org-admin')
  .component('premiumUpgradeStatus', {
    templateUrl: '/static/org/premium-upgrade-status/premium-upgrade-status.html',
    controller: function(SiteProvisioning, $routeParams, $window, $location, $scope, $rootScope, $sce) {

      var $ctrl = this

      $ctrl.$onInit = init
      $ctrl.itunesUrl = 'https://itunes.apple.com/us/app/sport-ngin/id499597400?mt=8&ct=HQDashboard&pt=319609'
      $ctrl.playUrl = 'https://play.google.com/store/apps/details?id=com.sportngin.android&hl=en&utm_campaign=HQDashboard'
      $ctrl.reload = reload

      function init() {
        checkStatus()
      }

      function reload() {
        $window.location.reload()
      }

      function reloadIfProvisioned(provision_status) {
        if (provision_status === 'provisioned') {
          reload()
        }
        else {
          setTimeout(checkStatus, 5000)
        }
      }

      function checkStatus() {
        SiteProvisioning.getStatus().then(reloadIfProvisioned)
      }
    }
  })
