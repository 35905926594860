angular.module('pl-shared')

  .factory('GroupPersona', function(DS, paginate) {
    var GroupPersona = DS.defineResource({
      name: 'group_personas',
      endpoint: 'groups',
      basePath: DS.adapters.ngin_v2.defaults.basePath,
      httpConfig: DS.adapters.ngin_v2.defaults.httpConfig,
      defaultAdapter: 'ngin_v2',
    })

    return paginate(GroupPersona)
  })
