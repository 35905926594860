angular.module('pl-shared')
  .component('inviteStatusCell', {
    bindings: {
      contact: '='
    },
    templateUrl: '/static/shared/components/dynamic-columns/invite-status-cell.html',
    controller: function($rootScope, $q, Alerts, currentOrg, dialog, setAs, OrphanInvite, Persona) {
      var $ctrl = this

      $ctrl.openInviteModal = openInviteModal
      $ctrl.resendInvite = resendInvite
      $ctrl.cancelInvite = cancelInvite

      $ctrl.requestExecution = false

      function findPersona() {
        if ($ctrl.persona) return $q.resolve()
        return Persona.find($ctrl.contact.id, { params: { org_id: currentOrg.id } })
          .then(setAs($ctrl, 'persona'))
      }

      /**
       * Opens a dialog that allows the user to send orphan invite.
       *
       * @returns {Promise} - A promise that is resolved once the dialog is closed.
       */
      function openInviteModal() {
        return findPersona()
          .then(function() {
            return dialog.confirm({
              component: 'send-orphan-invite-modal',
              attrs: {
                orphanPersona: $ctrl.persona
              }
            })
              .then(function() {
                $ctrl.contact.invite_status = 'invited'
                updatePersonaFields()
              })
          })
      }

      /**
       * Resend orphan invite.
       *
       * @returns {Promise.<OrphanInvite>} - A promise that is resolved with sent orphan invite.
       */
      function resendInvite() {
        $ctrl.requestExecution = true
        return findPersona()
          .then(function() {
            return OrphanInvite.resend($ctrl.persona.orphan_invite_id)
              .then(resendInviteSuccess, resendInviteError)
              .finally(setAs($ctrl, 'requestExecution', false))
          })
      }

      function resendInviteSuccess() {
        Alerts.success('ORPHAN_INVITES.RESEND_INVITE.success')
      }

      function resendInviteError() {
        Alerts.error('ORPHAN_INVITES.RESEND_INVITE.error')
      }

      /**
       * Send a request to cancel orphan invite.
       *
       * @returns {Promise.<OrphanInvite>} - A promise that is resolved with the current status of destroying orphan invite.
       */
      function cancelInvite() {
        $ctrl.requestExecution = true
        return findPersona()
          .then(function() {
            return OrphanInvite.destroy($ctrl.persona.orphan_invite_id)
              .then(cancelInvitationSuccess, cancelInvitationError)
              .finally(setAs($ctrl, 'requestExecution', false))
          })
      }

      function cancelInvitationSuccess() {
        $ctrl.contact.invite_status = 'uninvited'
        return (function() {
          Alerts.success('ORPHAN_INVITES.CANCEL_INVITE.success')
        })
      }

      /**
       * Retrieves a persona and emit event to update it.
       *
       * @private
       * @returns {Promise.<Persona>} - A promise that is resolved with updated persona.
       */
      function updatePersonaFields() {
        return Persona.find($ctrl.contact.id, { bypassCache: true })
          .then(function(persona) {
            $rootScope.$emit('persona:update', persona)
          })
      }

      function cancelInvitationError() {
        Alerts.error('ORPHAN_INVITES.CANCEL_INVITE.error')
      }
    }
  })
