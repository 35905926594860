angular.module('org-admin')

  .service('FeatureInstances', function(DS) {
    var FeatureInstances = DS.defineResource({
      name: 'feature_instances',
      endpoint: 'feature_instances',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
    })

    return FeatureInstances
  })
