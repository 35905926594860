angular.module('pl-shared')
  .service('MembershipTagService', function(DS, apiV2, paginate, ENV, $http) {
    var MembershipTagService = DS.defineResource({
      name: 'membership_tags',
      endpoint: 'v3/memberships/tags',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
    })

    return MembershipTagService
  })
