// $ctrl.max = 9999.99
// <input max-number="$ctrl.max" />
angular.module('pl-shared')

  .directive('maxNumber', function() {
    return {
      require: 'ngModel',
      scope: {
        max: '<maxNumber'
      },
      link: function(scope, el, attrs, ctrl) {
        var ngModel = ctrl

        function parser(inputVal) {
          var num = parseFloat(inputVal.replace(/[^\d.]/g, ''))
          var isValid = isNaN(num) || isNaN(scope.max) || num <= scope.max
          ngModel.$setValidity('maxNumber', isValid)
          return inputVal
        }

        ngModel.$parsers.push(parser)
      }
    }
  })
