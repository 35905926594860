angular.module('org-admin')
  .component('contactsAddToGroups', {
    bindings: {
      cancel: '<',
      confirm: '<',
      personas: '<',
      personasCount: '<'
    },
    templateUrl: '/static/org/contacts/contacts-add-to-groups.html',
    controller: function contactsAddToGroupsController(_, $scope, $q, i18ng, Alerts, GroupPersona, StaticGroupPersona, currentOrg) {
      var $ctrl = this

      $ctrl.$onInit = $onInit

      function $onInit() {
        $q.when($ctrl.personas)
          .then(function(contacts) {
            $ctrl.contacts = _.flatten(contacts)
          })
      }

      $ctrl.groupsOptions = {
        ajax: {
          delay: 1000,
          transport: function(params, success, failure) {
            GroupPersona.findAll({
              name: params.data.term,
              org_id: currentOrg.id,
              type: 'static'
            }).then(success, failure)
          },
          processResults: function(groups) {
            return {
              results: formatResults(groups)
            }
          }
        },
        collapseOverflow: true,
        minimumInputLength: 2,
        templateResult: function(group) {
          return group.html || group.text
        }
      }

      $ctrl.save = function() {
        var group_ids = $ctrl.selectedGroups.join(',')
        var persona_ids = _.map($ctrl.contacts, function(item) { return item.id }).join(',')

        StaticGroupPersona.add_personas_to_groups({ params: { group_ids: group_ids, persona_ids: persona_ids } }).then(function() {
          Alerts.success(
            'CONTACTS_LIST.ADD_TO_GROUPS.people_added_to_groups',
            {
              count: $ctrl.personasCount,
              groupsCount: $ctrl.selectedGroups.length,
              groupsLabel: i18ng.t('CONTACTS_LIST.ADD_TO_GROUPS.groups_label', { count: $ctrl.selectedGroups.length })
            }
          )

          $ctrl.confirm()
        })
      }

      function formatResults(groups) {
        return _.map(groups, function(group) {
          var association = group.association || i18ng.t('GROUP_TYPE.DESCRIPTION.static_default')
          var additionalInfo = i18ng.t('GROUP_TYPE.DESCRIPTION.Static Group', { association: association })
          var html = '<div pl-grid="tight align-middle">' +
              '<div pl-grid="shrink@phone" pl-grid-el class="pl-lead">' +
                '<svg class="pl-icon--light">' +
                  '<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-persons-three-circle"></use>' +
                '</svg>' +
              '</div>' +
              '<div pl-grid="3/4@phone" pl-grid-el>' +
                '<div>' + group.name + '</div>' +
                '<small class="pl-field__help--inline">' + additionalInfo + '</small>' +
              '</div>' +
            '</div>'

          return { id: group.group_id, text: group.name, html: angular.element(html) }
        })
      }
    }
  })
