angular.module('org-admin')

  .directive('billingProvisioningModal', function() {

    return {
      restrict: 'A',
      templateUrl: '/static/org/provisioning/billing-provisioning-modal.html',
      scope: {},
      bindToController: {
        cancel: '=',
        confirm: '=',
        dialogOptions: '='
      },
      controllerAs: 'ctrl',
      controller: function() {

      }
    }

  })
