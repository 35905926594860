angular.module('org-admin')

  .directive('contactsCustomize', function() {

    return {
      scope: {},
      bindToController: {
        dynamicColumns: '=',
        backText: '@?',
        enableMemberships: '<?',
        useMemberIdSource: '<?'
      },
      templateUrl: '/static/org/contacts/contacts-customize.html',
      controllerAs: 'ctrl',
      controller: function($scope, $timeout, currentOrg, _, setAs, pageViewHandler) {

        var ctrl = this

        ctrl.org = currentOrg
        ctrl.availableColumns = null // Loaded once a dataSource is selected.
        ctrl.shownColumns = _.clone(ctrl.dynamicColumns.shown)
        ctrl.selectedDataSource = null

        ctrl.addToShownColumns = addToShownColumns
        ctrl.removeFromShownColumns = removeFromShownColumns
        ctrl.removable = removable
        ctrl.dragover = dragover
        ctrl.drop = drop

        $scope.$watchCollection('ctrl.shownColumns', save)
        $scope.$watch('ctrl.selectedDataSource.dataSource', onDataSourceChanged)

        function addToShownColumns(column) {
          swapLists(column, ctrl.shownColumns, ctrl.availableColumns)
          pageViewHandler.fireEvent('ColumnAdd.' + column.name, 8, null)
        }

        function removeFromShownColumns(column) {
          swapLists(column, ctrl.availableColumns, ctrl.shownColumns)
          pageViewHandler.fireEvent('ColumnRemove.' + column.name, 8, null)
        }

        function removable(column) {
          return !ctrl.dynamicColumns.isRequiredColumn(column.key)
        }

        function dragover(index) {
          return index > 0 // name item is static
        }

        function drop(index, item) {
          $timeout(function() { // $timeout runs a digest afterward and looks smoother (no flash)
            var prevItem = _.find(ctrl.shownColumns, { key: item.key })
            ctrl.shownColumns.splice(index - 1, 0, item) // -1 for name item
            _.pull(ctrl.shownColumns, prevItem)
            save()
          })
          return true
        }

        // PRIVATE

        /**
         * Updates available columns when a data source changes.
         *
         * @private
         * @param {dataSourceSummary} selectedDataSource - The newly selected data source.
         * @param {dataSourceSummary} oldSelectedDataSource - The previously selected data source.
         */
        function onDataSourceChanged(selectedDataSource, oldSelectedDataSource) {
          if (!selectedDataSource) {
            ctrl.availableColumns = null
            return
          }

          var parts = selectedDataSource.id.toString().split('.')
          var bucket = parts[0]
          var id = parts[1] || null
          ctrl.availableColumns = null
          ctrl.dynamicColumns.getAvailableColumns(_.zipObject([bucket], [id]))
            .then(setAs(ctrl, 'availableColumns'))
        }

        function swapLists(column, toList, fromList) {
          _.pull(fromList, column)

          if (columnMatchesDataSource(column, ctrl.selectedDataSource.dataSource)) {
            toList.push(column)
          }

          save()
        }

        function columnMatchesDataSource(column, dataSource) {
          var dataSourceId = dataSource.id.split('.')[1]
          return ((column.dataSourceId || '') === (dataSourceId || '') &&
            (column.dataSourceType || '') === (dataSource.column_type || ''))
        }

        function save() {
          var shownColumns = _.map(ctrl.shownColumns, 'key')
          ctrl.dynamicColumns.updateShownColumns(shownColumns)
            .then(function() {
              ctrl.dynamicColumns.save()
            })
        }
      }
    }
  })
