angular.module('pl-shared')
  .factory('PeopleAndOrgsUiService', function(
    $q, $window, ENV, SeToastService, i18ng, renderContext, pageViewHandler
  ) {

    var service = {
      addAdminsListListeners: addAdminsListListeners,
      addBannerEventListeners: addBannerEventListeners,
      addHouseholdInfoEventListeners: addHouseholdInfoEventListeners,
      addPersonEventListeners: addPersonEventListeners,
      addProfileCardEventListeners: addProfileCardEventListeners,
      loadScripts: loadScripts,
      createElement: createElement,
    }

    return service

    /**
     * Load an element js payload from the library
     * @param {string} ngElName - Name of the Angular Element in the library to laod (e.g. se-add-person)
     * @param {fn} callback -a callback function when the script is loaded
     * @return void
     */
    function loadScripts(ngElName, callback) {
      var scriptId = getScriptId(ngElName, 'element')
      if ($window.document.getElementById(scriptId)) {
        callback()
      }
      else {
        injectScripts(ngElName, callback)
      }
    }

    /**
     * Adds event listeners to a se-add-person element to handle Output events emitted
     * @param {HTMLElement} elementRef - a reference to the se-add-person angular element
     * @param {object} callbackObj - keyed by eventName, valued by additional callback function
     * @return void
     */
    function addPersonEventListeners(elementRef, callbackObj, preventDeafaultCallback) {

      if (!callbackObj) callbackObj = {}
      elementRef.addEventListener('personAdded', function(event) {
        if (callbackObj.personAdded) callbackObj.personAdded(event)
        if (event.detail.success) {
          SeToastService.success(
            i18ng.t('ADD_PERSON.person_added_success', { name: event.detail.params.first_name })
          )
        }
        else {
          const errMsg = i18ng.t('ADD_PERSON.person_added_error')
          SeToastService.error(errMsg)
        }
      })

      elementRef.addEventListener('adminInvited', function(event) {
        if (callbackObj.adminInvited) callbackObj.adminInvited(event)
        if (event.detail.success && !preventDeafaultCallback) {
          SeToastService.success(
            i18ng.t('ADD_PERSON.person_added_success', { name: event.detail.params.first_name })
          )
        }
        else if (!preventDeafaultCallback) {
          const errMsg = i18ng.t('ADD_PERSON.person_added_error')
          SeToastService.error(errMsg)
        }
      })

      elementRef.addEventListener('adminRemoved', function(event) {
        if (callbackObj.adminRemoved) callbackObj.adminRemoved(event)
        if (event.detail.success && event.detail.params.permission_type === null) {
          SeToastService.success(
            i18ng.t('ADMINS.PERMISSIONS_CARD.EDIT_MODAL.admin_updated_success')
          )
        }
        else {
          const errMsg = i18ng.t('ADMINS.PERMISSIONS_CARD.EDIT_MODAL.admin_invited_error').toString()
          SeToastService.error(errMsg)
        }
      })

      elementRef.addEventListener('analyticsClick', function(event) {
        pageViewHandler.fireEvent(event.detail)
      })
    }

    function addBannerEventListeners(elementRef) {
      elementRef.addEventListener('bannerActions', function(event) {
        var bannerType = event.detail
        switch (bannerType) {
          case 'financialsRestrictedSoon':
          case 'financialSettingsRequest':
          case 'financialsUpdateCanada':
            renderContext.goto('editFinancialSettings')
            break
        }
      })

      elementRef.addEventListener('showBanners', function(event) {
        if (event.detail) {
          event.target.parentElement.className = 'has-banners'
        }
        else {
          event.target.parentElement.className = ''
        }
      })
    }

    /**
     * Adds event listeners to a se-household-info element to handle Output events emitted
     * @param {HTMLElement} elementRef - a reference to the se-household-info angular element
     * @param {object} callbackObj - keyed by eventName, valued by additional callback function
     * @return void
     */
    function addHouseholdInfoEventListeners(elementRef, callbackObj) {
      elementRef.addEventListener('messageClick', function(event) {
        if (callbackObj.messageClick) callbackObj.messageClick(event)
      })
    }

    /**
     * Adds event listeners to a se-profile-card element to handle Output events emitted
     * @param {HTMLElement} elementRef - a reference to the se-profile-card angular element
     * @param {object} callbackObj - keyed by eventName, valued by additional callback function
     * @return void
     */
    function addProfileCardEventListeners(elementRef, callbackObj) {
      elementRef.addEventListener('verifiedInfoUpdate', function(event) {
        if (callbackObj.verifiedInfoUpdate) callbackObj.verifiedInfoUpdate(event)
      })
    }

    function getScriptId(ngElName, script) {
      if (script === 'polyfills') return 'se-ui-libs-' + script

      var prefix = 'paouil-'
      var suffix = '-script'
      return prefix + ngElName + '-' + script + suffix
    }

    /**
     * Adds event listeners to a admins-list element to handle Output events emitted
     * @param {HTMLElement} elementRef - a reference to the se-profile-card angular element
     * @param {object} callbackObj - keyed by eventName, valued by additional callback function
     * @return void
     */
    function addAdminsListListeners(elementRef, callbackObj) {
      elementRef.addEventListener('openEditModal', function(event) {
        if (callbackObj.openEditModal) callbackObj.openEditModal(event)
      })
      elementRef.addEventListener('messageAdmins', function(event) {
        if (callbackObj.messageAdmins) callbackObj.messageAdmins(event)
      })
      elementRef.addEventListener('filterChange', function(event) {
        if (callbackObj.filterChange) callbackObj.filterChange(event)
      })
      elementRef.addEventListener('adminSelected', function(event) {
        if (callbackObj.adminSelected) callbackObj.adminSelected(event)
      })
    }

    /**
     *
     * @param {string} ngElName - Name of the Angular Element in the library to laod (e.g. se-add-person)
     * @param {string} containerId - an id of a container element to inject the Angular Element into (e.g. my-container-div)
     * @param {object} attrs - key value hash of attributes to assign to the Angular Element's Input
     * @return HTMLElement - the element that was created and appended to the container
     */
    function createElement(ngElName, containerId, attrs) {
      var container = $window.document.getElementById(containerId)
      var el = $window.document.createElement(ngElName + '-element')
      if (attrs) {
        for (var k in attrs) {
          el.setAttribute(k, attrs[k])
        }
      }
      container.appendChild(el)
      return el
    }

    // private

    function injectScripts(ngElName, callback) {
      var scripts = { }
      var promises = []
      for (var scriptName of ['runtime', 'polyfills', 'element']) {
        var scriptId = getScriptId(ngElName, scriptName)
        if ($window.document.getElementById(scriptId)) {
          promises.push($q.resolve())
        }
        else {
          var script = $window.document.createElement('script')
          script.async = false // ensure the scripts load in order
          script.src = ENV.urls.peopleAndOrgsUi + '/' + ngElName + '/' + scriptName + '.js?v11'
          script.id = scriptId
          promises.push(loadScriptPromise(script))
          scripts[scriptName] = $window.document.body.appendChild(script)
        }
      }
      $q.all(promises).then(callback)
    }

    function loadScriptPromise(script) {
      return $q(function(resolve, reject) {
        script.addEventListener('load', function handleScriptLoad() {
          this.removeEventListener('load', handleScriptLoad)
          resolve(script)
        })
      })
    }
  })
