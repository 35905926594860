angular.module('org-admin')
  .component('detailCard', {
    bindings: {
      discountCodeDefinition: '<'
    },
    templateUrl: '/static/org/discounts/detail-card.component.html',
    controllerAs: 'ctrl',
    controller: function(
      $scope,
      dialog,
      DiscountCodeDefinitions,
      currentOrg,
      PusherService,
      $filter,
      i18ng
    ) {

      var ctrl = this

      $scope.$watch(ctrl.discountCodeDefinition.id, pusherSubscribe)

      ctrl.$onChanges = function() {
        reloadData()
      }

      function reloadData() {
        ctrl.redeemedData = redeemedData()
        ctrl.valueOff = valueOff()
        ctrl.cardTags = cardTags()
      }

      function cardTags() {
        var tagColor = DiscountCodeDefinitions.tagColor
        var cardTags = [{
          value: i18ng.t('DISCOUNTS_LIST.STATUSES.' + ctrl.discountCodeDefinition.status),
          color: tagColor[ctrl.discountCodeDefinition.status]
        }]

        if (ctrl.discountCodeDefinition.available_count === 0) {
          var outOfStock = {
            value: i18ng.t('DISCOUNTS_LIST.STATUSES.out_of_stock'),
            color: tagColor.out_of_stock
          }
          cardTags.push(outOfStock)
        }
        return cardTags
      }

      ctrl.editDiscount = function() {
        dialog.confirm({
          component: 'discount-modal',
          attrs: {
            discountCodeDefinition: ctrl.discountCodeDefinition
          }
        })
      }

      function redeemedData() {
        var redeemedOnly = !ctrl.discountCodeDefinition.single_use && !ctrl.discountCodeDefinition.max_use_count
        return redeemedOnly ? 'redeemed' : 'redeemed_and_available'
      }

      function valueOff() {
        if (ctrl.discountCodeDefinition.discount_type === 'percentage') {
          return parseInt(ctrl.discountCodeDefinition.discount_value) + '%'
        }
        else {
          return $filter('displayCurrency')(ctrl.discountCodeDefinition.discount_value, ctrl.discountCodeDefinition.currency_symbol)
        }
      }

      function pusherSubscribe() {
        PusherService.subscribe('channel_clare_organization_' + currentOrg.id)
        PusherService.bind('event_code_definition_updated_' + ctrl.discountCodeDefinition.id, reloadData)
      }

    }
  })
