angular.module('ui.timepicker')
  .value('uiTimepickerConfig', {
    timeFormat: 'g:i A',
    appendTo: 'body',
    asMoment: true
  })

angular.module('pl-shared')
  .component('timePicker', {
    require: {
      ngModel: 'ngModel'
    },
    bindings: {
      placeholder: '=',
      ngDisabled: '<?'
    },
    template: '<div class="pl-addon-field"><input type="text" ng-required="$ctrl.requiredBool" class="pl-addon-field__input" id="{{$ctrl.attrs.id}}" ui-timepicker ng-model="$ctrl.temp.date" ng-model-options="{updateOn: \'blur\'}" ng-blur="$ctrl.setTouched()" base-date="$ctrl.fallback.date" placeholder="{{$ctrl.placeholder}}" ng-disabled="$ctrl.ngDisabled" /><label class="pl-addon-field__label" for="{{$ctrl.attrs.id}}"><svg-img class="\'pl-icon\'" use="\'time\'"></svg-img></label></div>',
    controller: function($scope, $element, $attrs, moment) {

      var $ctrl = this
      var input
      var dropdown

      $ctrl.$onInit = function() {
        input = $element.find('input:first')
        dropdown = input.data('timepicker-list')
        $element.removeAttr('id')
        $ctrl.attrs = $attrs
        $ctrl.temp = {}
        $ctrl.fallback = {
          date: moment().startOf('day')
        }
      }

      $scope.$watch(modelValueChange, setTempDate)
      $scope.$watch(tempDateChange, setModelValue)
      $scope.$watch('$ctrl.attrs.required', setRequired)

      $ctrl.setTouched = function() { $ctrl.ngModel.$setTouched() }

      function modelValueChange() {
        return $ctrl.ngModel.$modelValue
      }

      function tempDateChange() {
        return +$ctrl.temp.date
      }

      function setModelValue() {
        var date = $ctrl.temp.date
        var newVal = date && date.isValid() ? date.format('HH:mm') : null
        $ctrl.ngModel.$setViewValue(newVal)
      }

      function setTempDate(modelVal) {
        if (!modelVal) return $ctrl.temp.date = null
        var hoursAndMinutes = modelVal.split(':')
        var hours = parseInt(hoursAndMinutes[0], 10) || 0
        var minutes = parseInt(hoursAndMinutes[1], 10) || 0
        $ctrl.temp.date = $ctrl.temp.date ? $ctrl.temp.date.clone() : $ctrl.fallback.date.clone()
        $ctrl.temp.date
          .set('hours', hours)
          .set('minutes', minutes)
      }

      function setRequired(required) {
        $ctrl.requiredBool = !!required
      }

    }
  })

