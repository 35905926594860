angular.module('pl-shared')

  .factory('SmartGroupPersona', function(DS, paginate) {
    var SmartGroupPersona = DS.defineResource({
      name: 'smart_group_personas',
      endpoint: 'groups/smart',
      basePath: DS.adapters.ngin_v2.defaults.basePath,
      httpConfig: DS.adapters.ngin_v2.defaults.httpConfig,
      defaultAdapter: 'ngin_v2',
      cacheResponse: false,
    })

    return paginate(SmartGroupPersona)
  })
