angular.module('org-admin')
  .component('claimPreview', {
    templateUrl: '/static/org/claim/claim-preview.html',
    controller: function(currentOrg, ENV, SNAP, $sce, renderContext, $element, $timeout) {

      var $ctrl = this
      $ctrl.orgName = currentOrg.name.toUpperCase()
      $ctrl.orgId = currentOrg.id
      $ctrl.previewUrl = $sce.trustAsResourceUrl(currentOrg.sportsengineUrl + '?iframed=1')
      $ctrl.logoUrl = SNAP.assetPath + '/images/sportsengine-mark-color-black.svg'
      $ctrl.onLoadPreview = onLoadPreview

      function onLoadPreview() {
        $ctrl.loaded = true
        $element.find('.pl-wizard').css('opacity', 1)
        focusStepTitle()
      }

      function focusStepTitle() {
        $timeout(function() {
          $element
            .find('.pl-wizard__title')
            .focus()
        }, 10, false)
      }
    }

  })
