angular.module('org-admin')
  .directive('membershipDefinitionDelete', function() {

    var $ = angular.element

    return {
      restrict: 'EA',
      scope: {},
      bindToController: {
        cancel: '=',
        confirm: '=',
        membershipDefinition: '=',
        dialogOptions: '='
      },
      templateUrl: '/static/org/memberships/membership-definition-delete.html',
      controllerAs: '$ctrl',
      controller: function(_, i18ng) {
        var $ctrl = this
        $ctrl.destroyMembershipDefinition = destroyMembershipDefinition

        function destroyMembershipDefinition() {
          $ctrl.submitting = true
          $ctrl.membershipDefinition.DSDestroy()
            .then(destroySuccess)
            .catch(destroyError)

          function destroySuccess(result) {
            $ctrl.submitting = false
            return $ctrl.confirm(result)
          }

          function destroyError() {
            $ctrl.submitting = false
            $ctrl.serverErrorMessages = _.get(arguments[0], 'data.error.messages') || [i18ng.t('MEMBERSHIP_DEFINITION_DELETE.error')]
          }
        }
      }
    }
  })
