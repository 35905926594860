angular.module('org-admin')
  .component('orgPersonaMemberships', {
    bindings: {
      persona: '<',
      organization: '<'
    },
    templateUrl: '/static/org/memberships/org-persona-memberships.html',
    scope: {},
    controllerAs: '$ctrl',
    controller: function(_, i18ng, $scope, $rootScope, $routeParams, appPermission, renderContext, Membership, EligibilityRuleSet, Organization, Sale, dialog, currentOrg, currentUser, EligibilityMembershipRequestService, MembershipInvitesService, SuspensionService, MembershipsUtilService, $q, ENV, pageViewHandler) {
      var $ctrl = this
      $ctrl.$routeParams = $routeParams
      $ctrl.memberships = []
      $ctrl.organizations = []
      $ctrl.params = {}
      $ctrl.pastMemberships = []
      $ctrl.eligibilityMembershipRequests = []
      $ctrl.totalMemberships = 0
      $ctrl.transactions = {}
      $ctrl.timezone = currentOrg.timezone || 'America/New_York'
      $ctrl.pastMembershipsTitle = i18ng.t('ORG_PERSONA_MEMBERSHIPS.past_memberships')
      $ctrl.eligibilityCloseContext = 'app.billing.personas.persona.info.memberships'
      $ctrl.pendingClubAssignments = []
      $ctrl.membershipCards = []
      $ctrl.cancelMembership = cancelMembership
      $ctrl.clearClubAssignment = clearClubAssignment
      $ctrl.viewPurchaseDetails = viewPurchaseDetails
      $ctrl.getValidTerm = getValidTerm
      $ctrl.getSaleNumber = getSaleNumber
      $ctrl.membershipForOrg = membershipForOrg
      $ctrl.onUpdate = onUpdate
      $ctrl.showNotes = showNotes
      $ctrl.sortColumns = { effective_at: 'desc' }
      $ctrl.showPurchaseMemberships = false
      $ctrl.purchaseableOboMemberships = []
      $ctrl.ruleSets = []
      $ctrl.selectedEligibilityRuleSet = {}
      $ctrl.actionLinks = {}
      $ctrl.activeSuspensions = []
      $ctrl.suspendedOrgs = []
      $ctrl.moveMembership = moveMembership
      $ctrl.inviteBossOrganizationId = null

      $scope.$watch('$ctrl.sortColumns', onSortColumnsChanged)
      $rootScope.$on(
        'suspensions:create_instance',
        suspensionCreateHandler
      )

      function init() {
        loadSuspensions()
          .then(loadMemberships())
          .then(loadMembershipRequests())
          .then(loadClubAssignments())
          .then(function() {
            // Todo: update to use Angular EventEmitter instead of AngularJS
            $scope.$watchCollection('$ctrl.activeSuspensions', onSuspensionsChange)
          })
      }

      function onSuspensionsChange() {
        processSuspensions($ctrl.activeSuspensions)
        loadMemberships()
      }

      /**
       * Allows for child components to trigger re-initialization when
       * data is updated.
       * @param {*} event
       */
      function onUpdate(event) {
        $ctrl.loaded = false
        setTimeout(function() {
          init()
        }, 2000)
      }

      function loadSuspensions() {
        var params = {
          persona_id: $ctrl.persona.id,
          boss_organization_id: currentOrg.id,
          active: 1
        }

        if (appPermission.memberships.governingSeasonAdmin) params.with_affiliated_suspensions = 1

        return SuspensionService.findAll(params)
          .then(processSuspensions)
      }

      function processSuspensions(suspensions) {
        $ctrl.activeSuspensions = []
        $ctrl.suspendedOrgs = []
        _.forEach(suspensions, function(suspension) {
          if (SuspensionService.isSuspensionActive(suspension)) {
            $ctrl.activeSuspensions.push(suspension)
            $ctrl.suspendedOrgs = _.union($ctrl.suspendedOrgs, [suspension.boss_organization_id])
          }
        })
      }

      function loadMemberships() {
        var params = _.extend({}, $ctrl.params, {
          boss_organization_id: $ctrl.organization.id,
          persona_id: $ctrl.persona.id,
          'statuses[]': ['Active', 'Canceled', 'Requested', 'Denied'],
          include_membership_invite: 1
        })

        if (appPermission.memberships.governingSeasonAdmin) params.with_affiliated_memberships = 1

        return Membership.findAll(params)
          .then(initMemberships)
          .then(getOrgs)
          .then(membershipCheck)
          .then(loadInviteBossOrganizationId)
      }

      function loadMembershipRequests() {
        return EligibilityMembershipRequestService
          .query({
            membership_definition_organization_ids: [currentOrg.id],
            persona_ids: [$ctrl.persona.id],
            status: ['under_review', 'approval_needed']
          })
          .then(result => {
            $ctrl.eligibilityMembershipRequests = result
            setTimeout(function() { $scope.$digest() }, 0)
          })
      }

      function loadClubAssignments() {
        var params = {
          boss_organization_id: currentOrg.id,
          include_sent_by_persona_name: 1,
          persona_id: $ctrl.persona.id,
          status: 'pending',
          sort: 'created_at'
        }

        if (appPermission.memberships.governingSeasonAdmin) params.with_affiliated_membership_invites = 1

        return MembershipInvitesService.findAll(params)
          .then(function(result) {
            $ctrl.pendingClubAssignments = result
            setTimeout(function() { $scope.$digest() }, 0)
          })
      }

      function getValidTerm(membership) {
        if (membership.membership_status.toLowerCase() === 'expired') {
          return membership.effective_at_formatted + ' - ' + membership.expires_at_formatted
        }
        else if (membership.status.toLowerCase() === 'canceled') {
          if (membership.effective_at) {
            return membership.effective_at_formatted + ' - ' + membership.cancel_date_formatted
          }
          else {
            return 'No Activation Date - ' + membership.cancel_date_formatted
          }
        }
        else {
          return '--'
        }
      }

      function getSaleNumber(membership) {
        var tx = $ctrl.transactions[membership.providerId]
        return (tx && tx.id) || '--'
      }

      function initMemberships(data) {
        $ctrl.memberships = data.filter(function(m) {
          return ['valid', 'pending'].indexOf(m.membership_status.toLowerCase()) !== -1
        })
        groupMemberships()
        addMembershipCardTags()
        $ctrl.pastMemberships = data.filter(function(m) {
          return ['expired', 'canceled', 'denied'].indexOf(m.membership_status.toLowerCase()) !== -1
        })
        $ctrl.totalMemberships = $ctrl.memberships.length + $ctrl.pastMemberships.length
        var memdefIds = _.uniq(_.map($ctrl.memberships, 'membership_definition.id'))

        if (memdefIds.length) {
          return getEligibility(memdefIds)
            .then(function(ruleSets) {
              $ctrl.ruleSets = ruleSets
              if ($routeParams.eligibilityRuleSetId) {
                $ctrl.selectedEligibilityRuleSet = _.find(ruleSets, function(rs) { return rs.id === $routeParams.eligibilityRuleSetId })
              }
              $ctrl.actionLinks = loadActionLinks()
            })
        }
      }

      function initSales() {
        var sales = _.map($ctrl.memberships.concat($ctrl.pastMemberships), 'providerId').join(',')
        if (!sales) return $ctrl.loaded = true
        return Sale.findAll({ sale_numbers: sales })
          .then(function(results) {
            _.forEach(results, function(s) {
              var tx = _.filter(s.transactions, { trxtype: 'S', success: true })
              $ctrl.transactions[s.sale_number] = _.first(tx)
            })
            $ctrl.sales = results
            $ctrl.loaded = true
          })
          .catch(function(err) {
            // 401/403 probably
            $ctrl.loaded = true
          })
      }

      function createMembershipCard(membership, child, parent) {
        return {
          childMembership: child,
          membership: membership,
          parentMembership: parent,
        }
      }

      function groupMemberships() {
        if (!$ctrl.memberships || !$ctrl.memberships.length) return

        var ownedMemberships = []
        var regionMemberships = []
        var remainingMemberships = { }
        var allMemberships = angular.copy($ctrl.memberships)
        _.forEach(allMemberships, function(m) { remainingMemberships[m.id] = m })

        _.forEach(Object.keys(remainingMemberships), function(id) {
          if (remainingMemberships[id].membership_definition.boss_organization_id === currentOrg.id) {
            ownedMemberships.push(remainingMemberships[id])
            delete remainingMemberships[id]
          }
        })
        var ownedMembershipCards = _.map(ownedMemberships, function(m) { return groupMembership(m, remainingMemberships) })
        _.forEach(Object.keys(remainingMemberships), function(id) {
          if (remainingMemberships[id].parent_membership_links.length) {
            regionMemberships.push(remainingMemberships[id])
            delete remainingMemberships[id]
          }
        })
        var regionMembershipCards = _.map(regionMemberships, function(m) { return groupMembership(m, remainingMemberships) })
        var otherMembershipCards = _.map(Object.values(remainingMemberships), function(m) { return groupMembership(m, remainingMemberships) })

        var allCards = ownedMembershipCards.concat(regionMembershipCards, otherMembershipCards)
        $ctrl.membershipCards = _.sortBy(allCards, function(card) {
          return card.membership.membership_definition.name.toLowerCase()
        })
      }

      function groupMembership(m, remainingMemberships) {
        var childMem = m.child_membership_links[0] ? remainingMemberships[m.child_membership_links[0].child_membership_id] : undefined
        var parentMem = m.parent_membership_links[0] ? remainingMemberships[m.parent_membership_links[0].parent_membership_id] : undefined
        if (childMem) delete remainingMemberships[m.child_membership_links[0].child_membership_id]
        if (parentMem) delete remainingMemberships[m.parent_membership_links[0].parent_membership_id]
        return createMembershipCard(m, childMem, parentMem)
      }

      function addMembershipCardTags() {
        $ctrl.membershipCards = _.map($ctrl.membershipCards, function(card) {
          if (_.includes($ctrl.suspendedOrgs, card.membership.membership_definition.boss_organization_id)) {
            card.tags = [{
              value: i18ng.t('FILTER_PANEL_SERVICE.MEMBERSHIP_FIELDS.status_suspended'),
              color: i18ng.t('FILTER_PANEL_SERVICE.MEMBERSHIP_FIELDS.status_color_suspended')
            }]
          }
          return card
        })
      }

      function getOrgIdsFromMemberships(memberships) {
        var orgIds = _.map(memberships, function(membership) {
          return membership.membership_definition.boss_organization_id
        })
        var affiliatedOrgIds = _.map(memberships, function(membership) {
          return membership.affiliation_organization_id
        })
        var inviteOrgIds = _.map(memberships, function(membership) {
          return membership.membership_invite ? membership.membership_invite.sent_by_org_id : undefined
        })
        return _.uniq(orgIds.concat(affiliatedOrgIds).concat(inviteOrgIds)).filter(function(orgId) {
          return !!orgId
        })
      }

      function getOrgs() {
        var orgIds = getOrgIdsFromMemberships($ctrl.memberships.concat($ctrl.pastMemberships))
        return Organization.index(orgIds)
          .then(organizationSuccess)
          .then(initSales)
      }

      function organizationSuccess(orgs) {
        $ctrl.organizations = orgs
        $ctrl.pastMemberships = _.map($ctrl.pastMemberships, function(membership) {
          membership.organization = _.find($ctrl.organizations, 'id', membership.membership_definition.boss_organization_id)
          membership.effective_at_formatted = formatDate(membership.effective_at, membership.organization.timezone)
          membership.expires_at_formatted = formatDate(membership.expires_at, membership.organization.timezone)
          membership.cancel_date_formatted = formatDate(membership.cancel_date, membership.organization.timezone)
          return membership
        })
        _.forEach($ctrl.memberships, function(membership) {
          membership.organization = $ctrl.organizations.find(function(org) {
            return org.id === membership.membership_definition.boss_organization_id
          })
          if (membership.affiliation_organization_id) {
            membership.affiliation_organization = $ctrl.organizations.find(function(org) {
              return org.id === membership.affiliation_organization_id
            })
          }
        })
      }

      function formatDate(date, timezone) {
        return moment.tz(date, timezone).format('MMM D, YYYY')
      }

      function onSortColumnsChanged() {
        var sortColumnKey = _.chain($ctrl.sortColumns)
          .keys()
          .first()
          .value()

        $ctrl.params.sort = sortColumnKey
        $ctrl.params.sort_dir = $ctrl.sortColumns[sortColumnKey]

        init()
      }

      function suspensionCreateHandler(event, suspension) {
        processSuspensions($ctrl.activeSuspensions.concat([suspension]))
        loadMemberships()
      }

      function cancelMembership(membership) {
        dialog.confirm({
          component: 'cancel-membership',
          scope: $scope,
          attrs: {
            membership: membership,
            persona: $ctrl.persona,
            transaction: $ctrl.transactions[membership.providerId] || {}
          }
        })
          .then(function() {
            $ctrl.loaded = false
            loadMemberships()
            pageViewHandler.fireEvent('CancelMembershipModal.CancelClick', 8)
          })
      }

      function clearClubAssignment(membership) {
        dialog.confirm({
          component: 'clear-club-assignment-modal',
          scope: $scope,
          attrs: {
            membership: membership
          }
        })
          .then(function() {
            $ctrl.loaded = false
            loadMemberships()
            pageViewHandler.fireEvent('ClearClubAssignmentModal.ClearClick', 8)
          })
      }

      function membershipForOrg(membership, orgId) {
        if (!orgId) orgId === currentOrg.id
        return membership.membershipDefinition.boss_organization_id === orgId
      }

      function showNotes(membership) {
        dialog.confirm({
          component: 'membership-notes-details',
          scope: $scope,
          attrs: {
            membership: membership
          }
        })
      }

      function moveMembership(membership) {
        pageViewHandler.fireEvent('MoveMembership.Click', 8)
        dialog.confirm({
          component: 'move-membership-modal',
          scope: $scope,
          attrs: {
            membership: membership,
            ruleSets: $ctrl.ruleSets
          }
        }).then(function() {
          $ctrl.loaded = false
          loadMemberships()
        })
      }

      function loadActionLinks() {
        var output = {}
        _.forEach($ctrl.memberships, function(membership) {
          var items = []
          var ruleSet = findRuleSetByMemdefId(membership.membership_definition.id)
          if (
            (membership.membership_definition.boss_organization_id === currentOrg.id || (membership.membership_affiliation_path && _.map(membership.membership_affiliation_path, 'organization_id').includes(currentOrg.id))) &&
            (ruleSet && ruleSet.eligibility_rules.length > 1) && _.includes(['Pending', 'Valid'], membership.membership_status)
          ) {
            items.push({
              text: i18ng.t('ORG_PERSONA_MEMBERSHIPS.view_eligibility'),
              action: function() { viewEligibility(membership, ruleSet) }
            })
          }
          if (canCancelMembership(membership)) {
            items.push({
              text: i18ng.t('ORG_PERSONA_MEMBERSHIPS.cancel'),
              action: function() { $ctrl.cancelMembership(membership) }
            })
          }
          if (membership.affiliation_organization_id &&
            (appPermission.memberships.superRole ||
            (appPermission.memberships.userCanClear &&
              Array.isArray(membership.affiliatedAndOwnerOrganizationIds) &&
              membership.affiliatedAndOwnerOrganizationIds.includes(currentOrg.id)))
          ) {
            items.push({
              text: i18ng.t('ORG_PERSONA_MEMBERSHIPS.clear_club_assignment'),
              action: function() { $ctrl.clearClubAssignment(membership) }
            })
          }
          if (appPermission.memberships.superRole) {
            items.push({
              text: i18ng.t('ORG_PERSONA_MEMBERSHIPS.move_membership'),
              action: function() { $ctrl.moveMembership(membership) }
            })
          }

          items.push({
            text: i18ng.t('ORG_PERSONA_MEMBERSHIPS.purchase_details'),
            action: function() { $ctrl.viewPurchaseDetails(membership) }
          })

          if (items.length) {
            output[membership.id] = items
          }
        })

        return output
      }

      function viewEligibility(membership, ruleSet) {
        var personaId = membership.persona_id
        var params = { eligibilityRuleSetId: ruleSet.id, personaId: personaId }
        $ctrl.selectedEligibilityRuleSet = ruleSet
        renderContext.goto('app.billing.personas.persona.info.memberships.detail', params)
      }

      function viewPurchaseDetails(membership) {
        dialog.confirm({
          component: 'membership-purchase-details-modal',
          scope: $scope,
          attrs: {
            membership: membership,
            organizations: $ctrl.organizations
          }
        })
      }

      function getEligibility(memdefIds) {
        return EligibilityRuleSet.findAll({
          membership_definition_id: memdefIds.join(','),
          boss_organization_id: currentOrg.id,
          with_affiliated: true,
          per_page: 100
        })
      }

      function findRuleSetByMemdefId(memdefId) {
        return _.find($ctrl.ruleSets, {
          eligibility_rules: [{
            originator_type: 'membership_definition',
            originator_id: memdefId,
            trigger: true
          }]
        })
      }

      function canCancelMembership(membership) {
        return (
          _.includes(membership.affiliatedAndOwnerOrganizationIds, currentOrg.id) &&
            appPermission.memberships.userCanCancel
        )
      }

      $ctrl.purchaseMemberships = function() {
        if (!$ctrl.persona) return

        if ($ctrl.canBuyMembership) {
          $ctrl.contactsForMembership = $q.resolve([$ctrl.persona])
          $ctrl.showPurchaseMemberships = true
        }
        else {
          dialog.confirm({
            directive: 'no-purchaseable-memberships',
            scope: $scope,
          })
        }
      }

      $ctrl.sendClubAssignment = function() {
        dialog.confirm({
          attrs: {
            bossOrgId: $ctrl.inviteBossOrganizationId,
            personaIds: [$ctrl.persona.id]
          },
          component: 'membership-invite-modal',
          scope: $scope
        })
      }

      function loadInviteBossOrganizationId() {
        return MembershipsUtilService.getInviteBossOrganizationId($ctrl.purchaseableOboMemberships, false)
          .then(function(orgId) {
            $ctrl.inviteBossOrganizationId = orgId
          })
      }

      function membershipCheck() {
        return MembershipsUtilService.loadPurchaseableOboMemberships(currentOrg.id)
          .then(function(result) {
            $ctrl.purchaseableOboMemberships = result
            $ctrl.canBuyMembership = _.some(result)
            var invitableMemDefs = _.filter(result, function(memDef) {
              return memDef.can_invite
            })
            $ctrl.canSendClubAssignment = $ctrl.canBuyMembership && currentUser.canSendClubAssignment() && _.some(invitableMemDefs)
          })
      }
    }
  })
