/**

Question Element Structure:

{
  id: FIELD-SECTION-ID,
  type: 'address',
  label: FIELD-SECTION-LABEL,
  fields: object[],
  answer: function(obj) {
    return {
      value: {
      },
      label: function(obj) {
      }
    }
  }
  id: unique ID to identify the field section inside the form
  type: always 'Address'
  label: single label string for hole address field section
  fields: object with the six input information for 'edit mode'
                if it's not present the default value will be used
                format: {
                  address_1: {},
                  address_2: {},
                  country: {},
                  state: {},
                  city: {},
                  postal_code: {},
                }
                field object format:
                {
                  id: string // mandatory
                  label: string // optional
                  is_required: boolean // optional, false by default,
                  placeholder: string
                  max: integer
                  auto_format: string (JS string method to apply 'upper' toUpperCase, 'lower' toLowerCase for example)
                  has_validate_postal_code: bool (allows to apply a patter validation for postal codes)
                }
  answer: function to map values from object to inputs
}

Check "defaultsFields" object for field structure example.

**/
angular.module('pl-shared')

  .directive('addressQuestion', function() {

    return {
      scope: {},
      templateUrl: '/static/shared/components/regform/questions/address-question.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        model: '=',
        answer: '='
      },
      controller: function(_, $scope, i18ng, GeographicOptions) {
        var ctrl = this

        var defaultsFields = {
          address_1: {
            id: 'address_1',
            label: i18ng.t('ADDRESS.LABELS.address_1'),
            is_required: false
          },
          address_2: {
            id: 'address_2',
            label: i18ng.t('ADDRESS.LABELS.address_2'),
            is_required: false
          },
          country: {
            id: 'country',
            label: i18ng.t('ADDRESS.LABELS.country'),
            is_required: true,
            placeholder: i18ng.t('ADDRESS.LABELS.country_placeholder'),
            default: 'USA'
          },
          state: {
            id: 'state',
            label: i18ng.t('ADDRESS.LABELS.state'),
            is_required: false,
            placeholder: i18ng.t('ADDRESS.LABELS.state_placeholder')
          },
          city: {
            id: 'city',
            label: i18ng.t('ADDRESS.LABELS.city'),
            is_required: false
          },
          postal_code: {
            id: 'postal_code',
            label: i18ng.t('ADDRESS.LABELS.postal_code'),
            is_required: true
          }
        }

        ctrl.countries = []
        ctrl.subregions = []
        GeographicOptions.countries().then(function(response) {
          ctrl.countries = response
        })

        $scope.$watch('ctrl.model.country', function() {
          if (ctrl.model.country) {
            GeographicOptions.subregions({ params: { country: ctrl.model.country } }).then(function(response) {
              ctrl.subregions = response
            })
          }
          else {
            ctrl.subregions = []
            ctrl.model.state = ''
          }
        })

        ctrl.getPatternErrorMessage = function(field) {
          if (field.has_validate_postal_code) return 'postal_code'
          else return ''
        }
        // see shared/components/registration/questions/short-text-question-directive.js
        ctrl.getPattern = function(field) {
          if (field.has_validate_postal_code) return /^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/
          else return ''
        }

        ctrl.properties = ctrl.question.properties

        // NOTE: This property allows to set all fields on the directive as different fields on the data object
        //        Check rf-form-directive.js (submitForm method) for more information.
        ctrl.properties = _.extend(ctrl.properties, { flatten_field_data: true })

        ctrl.model = _.isObject(ctrl.answer) ? ctrl.answer.value : null
        ctrl.fields = {}
        for (var field in defaultsFields) {
          ctrl.fields[field] = _.extend(defaultsFields[field], ctrl.properties.fields[field])
          ctrl.fields[field].pattern = ctrl.getPattern(ctrl.fields[field])
        }

        ctrl.model.country = ctrl.model.country || ctrl.fields.country.default

        ctrl.countrySelectOptions = {
          placeholder: ctrl.fields.country.placeholder,
          allowClear: true
        }
        ctrl.stateSelectOptions = {
          placeholder: ctrl.fields.state.placeholder,
          allowClear: true
        }
      }
    }

  })
