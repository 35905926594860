angular.module('org-admin')
  .component('templateSelect', {
    templateUrl: '/static/org/template-select/template-select.html',
    controller: function(
      SiteProvisioning,
      templateSelectData,
      setAs,
      $window,
      $location,
      currentOrg,
      currentUser,
      Alerts,
      SNAP,
      i18ng,
      _,
      ApiTimezone
    ) {
      var $ctrl = this

      $ctrl.$onInit = init
      $ctrl.provisionSite = provisionSite
      $ctrl.imgSrc = imgSrc
      $ctrl.adminTemplates = []
      function init() {
        _.each(templateSelectData, includeTemplateLabel)
        $ctrl.templates = templateSelectData
        $ctrl.template = templateSelectData[0]
        ApiTimezone.getOptions().then(prepareTimezones)
        SiteProvisioning.getStatus().then(update)
      }

      function includeTemplateLabel(template) {
        template.label = i18ng.t('TEMPLATE_SELECT.TEMPLATE_NAME.' + template.key)
      }

      function update(results) {
        if (results === 'provisioned') return redirectToHQ()
        $ctrl.canProvision = (results === 'no_site' || results === 'unprovisioned')
        if (currentUser.hasRole('platform_admin') || currentUser.hasRole('third_north')) {
          SiteProvisioning.getTemplates().then(prepareAdminTemplates)
        }
        else {
          $ctrl.loaded = true
        }
      }

      async function prepareAdminTemplates(results) {
        $ctrl.adminTemplates = results
        $ctrl.loaded = true
      }

      async function prepareTimezones(results) {
        $ctrl.timezone = currentOrg.timezone
        $ctrl.timezones = results
      }

      function provisionSite(templateId) {
        if ($ctrl.saving) return
        $ctrl.saving = true
        SiteProvisioning
          .provisionSite($ctrl.adminTemplateId || templateId || null, $ctrl.timezone)
          .then(reloadAfterDelay, provisioningError)
      }

      function provisioningError() {
        $ctrl.saving = false
        Alerts.error('TEMPLATE_SELECT.PROVISIONING.error')
      }

      function reloadAfterDelay() {
        setTimeout(function() { $window.location.reload() }, 1000)
      }

      function imgSrc(key) {
        return SNAP.assetPath + '/images/billing/template-select/' + key + '_thumb.png'
      }

      function redirectToHQ() {
        $location.replace()
        $location.path('/org/' + currentOrg.id)
      }
    }
  })
