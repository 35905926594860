angular.module('org-admin')
  .component('hqDashboardV1', {
    templateUrl: '/static/org/hq-dashboard/hq-dashboard-v1.html',
    controller: function(
      _,
      $q,
      $window,
      $sce,
      userSettings,
      currentUser,
      currentOrg,
      HqMenu,
      appPermission,
      Contact,
      OrgInvoiceSummary,
      $filter,
      iframeFeedbackModal,
      i18ng,
      $element,
      OffersService,
      pageViewHandler,
      FreemiumOrgService,
      PeopleAndOrgsUiService,
      $timeout,
    ) {

      var $ctrl = this

      $ctrl.$onInit = init
      $ctrl.org = currentOrg
      $ctrl.currentUser = currentUser
      $ctrl.offers = OffersService.get()
      $ctrl.freemiumOrg = FreemiumOrgService.orgIsFreemium()

      $ctrl.salesforce_crm_id = currentOrg.salesforce_crm_id
      $ctrl.showMobileCallout = !userSettings.get('hq-dashboard-hide-mobile')
      $ctrl.goToRequestWebsite = goToRequestWebsite
      $ctrl.addTracking = addTracking
      $ctrl.hideMobile = hideMobile
      $ctrl.iframeOne = $sce.trustAsResourceUrl('https://live-sportsengine.pantheonsite.io/rapid-replay/promotion/se-hq')
      $ctrl.mobileUrl = 'https://b2b-sportsengine.pantheonsite.io/hq/features/mobile-app'
      $ctrl.itunesUrl = 'https://itunes.apple.com/us/app/sport-ngin/id499597400?mt=8&ct=HQDashboard&pt=319609'
      $ctrl.playUrl = 'https://play.google.com/store/apps/details?id=com.sportngin.android&hl=en&utm_campaign=HQDashboard'
      $ctrl.greeting = 'HQ_DASHBOARD.GREETING.' + timeOfDay()
      $ctrl.greetingOpts = {
        user: currentUser.firstname,
        org: currentOrg.name
      }
      $ctrl.feedbackModal = iframeFeedbackModal.create({
        title: i18ng.t('FEEDBACK_MODAL.title'),
        url: 'https://www.surveymonkey.com/r/HQ-feedback',
        app: 'SE HQ'
      })

      function init() {
        showOrHideOnboardingGuide()
        trackClickEvents()
        var requests = {
          menu: HqMenu.get(currentOrg.id),
          contacts: checkContacts(),
          invoiceSummary: OrgInvoiceSummary.init() // does not return data
        }
        $q.all(requests).then(setData)
      }

      $ctrl.$postLink = function() {
        $timeout(function() {
          loadDashboardNotifications()
        })
      }

      function loadDashboardNotifications() {
        if (appPermission.topDownOrgAccess) return

        var CONTAINER_ID = 'hq-dashboard-notifications'
        $ctrl.hqDashboardNotifications = $window.document.getElementById(CONTAINER_ID)
        PeopleAndOrgsUiService.loadScripts('se-dashboard-notifications', function() {
          PeopleAndOrgsUiService.addBannerEventListeners($ctrl.hqDashboardNotifications)
        })
      }

      function trackClickEvents() {
        trackSelectorClick('.js-registration-list .se-popover__reference', 'RegPopover')
        trackSelectorClick('.js-program-list .se-popover__reference', 'ProgramListingPopover')
        trackSelectorClick('.js-request-registration .se-empty-state__button', 'RequestRegistration')
        trackSelectorClick('.js-add-new-program .se-empty-state__button', 'AddNewProgramListing')
        trackSelectorClick('.js-enable-payments .se-empty-state__button', 'EnablePayments')
        trackSelectorClick('.js-program-list se-list-item:nth-child(1) a.se-list-item__title', 'FirstProgramListing', 1)
        trackSelectorClick('.js-program-list se-list-item:nth-child(2) a.se-list-item__title', 'SecondProgramListing', 2)
        trackSelectorClick('.js-program-list se-list-item:nth-child(3) a.se-list-item__title', 'ThirdProgramListing', 3)
        trackSelectorClick('.js-registration-list se-list-item:nth-child(1) a.se-list-item__title', 'FirstRegistration', 1)
        trackSelectorClick('.js-registration-list se-list-item:nth-child(2) a.se-list-item__title', 'SecondRegistration', 2)
        trackSelectorClick('.js-registration-list se-list-item:nth-child(3) a.se-list-item__title', 'ThirdRegistration', 3)
      }

      function trackSelectorClick(selector) {
        var label = arguments[1]
        var value = arguments[2]
        $element.on('click', selector, function() {
          pageViewHandler.fireEvent(label + '.Click', 8, value)
        })
      }

      function resolveFailed() {
        return $q.resolve()
      }

      function addTracking(action) {
        pageViewHandler.fireEvent(action, 8)
      }

      function hideMobile() {
        $ctrl.showMobileCallout = !userSettings.set('hq-dashboard-hide-mobile', true)
      }

      function goToRequestWebsite() {
        $window.open('https://sportsengine.outgrow.us/hq-package-recommendations')
      }

      function timeOfDay() {
        var hour = (new Date()).getHours()
        if (hour < 3 || hour > 21) return 'night'
        if (hour < 12) return 'morning'
        if (hour < 17) return 'afternoon'
        return 'evening'
      }

      function checkContacts() {
        return Contact.findAll({
          exclude_teams: true,
          org_id: currentOrg.id,
          per_page: 1
        }).catch(resolveFailed) // non-critical error
      }

      function memberCount(contacts) {
        var pagination = contacts && contacts.pagination || {}
        return pagination.total ? $filter('number')(pagination.total) : '- -'
      }

      function setData(data) {
        var hqMenu = data.menu
        var nav = hqMenu.data.nav_tree
        var regNav = subNav(nav, 'Registration')
        var invoicingNav = subNav(nav, 'Invoicing')
        var promoNav = subNav(nav, 'Promotion')
        var hasWebsite = !!navItems(nav, 'Website')
        var regVisible = !!regNav.Registrations
        var orgAdmin = currentUser.hasRole('org_admin')
        var invoicingVisible = invoicingNav.Invoices
        var programListingsReady = promoNav['SportsEngine.com Listings']
        var nginUrl = _.get(appPermission, 'checks.sites.sites[0].url')
        var siteCheck = _.get(appPermission, 'checks.sites') || {}

        $ctrl.loaded = true
        $ctrl.memberCount = memberCount(data.contacts)
        $ctrl.websiteUrl = siteCheck.provisioned && _.get(siteCheck, 'sites[0].url') || '' // only use ngin site url for this, not from the org
        $ctrl.requestWebsiteUrl = '/org/' + currentOrg.id + '/website/learn_more'

        $ctrl.registrationStatus = {
          visible: regVisible || orgAdmin,
          unprovisioned: !regVisible && orgAdmin
        }

        $ctrl.invoicingStatus = {
          visible: invoicingVisible,
          unprovisioned: invoicingVisible && !appPermission.powerPay.usable
        }

        $ctrl.programListingsStatus = {
          visible: programListingsReady
        }
      }

      function subNav(nav, label) {
        var map = {}
        _.each(navItems(nav, label), function(i) { map[i.label] = true })
        return map
      }

      function navItems(nav, label) {
        return (_.find(nav, { label: label }) || {}).secondaryItems
      }

      function showOrHideOnboardingGuide() {
        $ctrl.showOnboardingGuide = currentUser.hasRole('org_admin') && $ctrl.freemiumOrg
      }

      function updateWindowWidth() {
        $ctrl.windowWidth = $window.innerWidth
      }

      updateWindowWidth()

      angular.element($window).on('resize', function() {
        $ctrl.windowWidth = $window.innerWidth
        $ctrl.$apply()
      })

      $ctrl.$onDestroy = function() {
        angular.element($window).off('resize')
      }
    }
  })
