angular.module('pl-shared')

  .factory('OrganizationProfileSchema', function($q, DS, apiV2, setAs) {
    var OrganizationProfileSchema = DS.defineResource({
      name: 'organization_profile_schemas',
      basePath: DS.adapters.ngin_v2.defaults.basePath,
      httpConfig: DS.adapters.ngin_v2.defaults.httpConfig,
      defaultAdapter: 'ngin_v2',
      actions: {
        filters: {
          method: 'GET'
        },
        membership_organizations_by_classification: {
          method: 'GET'
        },
        indexed_membership_definitions: {
          method: 'GET'
        },
        indexed_seasons: {
          method: 'GET'
        },
        indexed_divisions: {
          method: 'GET'
        },
        indexed_teams: {
          method: 'GET'
        }
      }
    })

    var cache = {}
    var _filters = OrganizationProfileSchema.filters
    OrganizationProfileSchema.filters = function() {
      return cache.filters ?
        $q.when(cache.filters) :
        _filters().then(apiV2.deserialize).then(setAs(cache, 'filters'))
    }
    var _membership_organizations_by_classification = OrganizationProfileSchema.membership_organizations_by_classification
    OrganizationProfileSchema.membership_organizations_by_classification = function(orgId, memdefId) {
      var cacheKey = orgId + ':' + memdefId
      var cacheObj = cache.membership_organizations_by_classification || {}
      return cacheObj[cacheKey] ?
        $q.when(cacheObj[cacheKey]) :
        _membership_organizations_by_classification({ params: { boss_organization_id: orgId, membership_definition_id: memdefId } })
          .then(apiV2.deserialize)
          .then(function(result) {
            if (!cache.membership_organizations_by_classification) cache.membership_organizations_by_classification = {}
            cache.membership_organizations_by_classification[cacheKey] = result
            return result
          })
    }

    var _indexed_membership_definitions = OrganizationProfileSchema.indexed_membership_definitions
    OrganizationProfileSchema.indexed_membership_definitions = function(orgId) {
      var cacheKey = orgId
      var cacheObj = cache.indexed_membership_definitions || {}
      return cacheObj[cacheKey] ?
        $q.when(cacheObj[cacheKey]) :
        _indexed_membership_definitions({ params: { boss_organization_id: orgId } })
          .then(apiV2.deserialize)
          .then(function(result) {
            if (!cache.indexed_membership_definitions) cache.indexed_membership_definitions = {}
            cache.indexed_membership_definitions[cacheKey] = result
            return result
          })
    }

    var _indexed_seasons = OrganizationProfileSchema.indexed_seasons
    OrganizationProfileSchema.indexed_seasons = function(orgId) {
      var cacheKey = orgId
      var cacheObj = cache.indexed_seasons || {}
      return cacheObj[cacheKey] ?
        $q.when(cacheObj[cacheKey]) :
        _indexed_seasons({ params: { boss_organization_id: orgId } })
          .then(apiV2.deserialize)
          .then(function(result) {
            if (!cache.indexed_seasons) cache.indexed_seasons = {}
            cache.indexed_seasons[cacheKey] = result
            return result
          })
    }

    var _indexed_divisions = OrganizationProfileSchema.indexed_divisions
    OrganizationProfileSchema.indexed_divisions = function(orgId, seasonId) {
      var cacheKey = orgId + ':' + seasonId
      var cacheObj = cache.indexed_divisions || {}
      return cacheObj[cacheKey] ?
        $q.when(cacheObj[cacheKey]) :
        _indexed_divisions({ params: { boss_organization_id: orgId, season_id: seasonId } })
          .then(apiV2.deserialize)
          .then(function(result) {
            if (!cache.indexed_divisions) cache.indexed_divisions = {}
            cache.indexed_divisions[cacheKey] = result
            return result
          })
    }

    var _indexed_teams = OrganizationProfileSchema.indexed_teams
    OrganizationProfileSchema.indexed_teams = function(orgId, seasonId) {
      var cacheKey = orgId + ':' + seasonId
      var cacheObj = cache.indexed_teams || {}
      return cacheObj[cacheKey] ?
        $q.when(cacheObj[cacheKey]) :
        _indexed_teams({ params: { boss_organization_id: orgId, season_id: seasonId } })
          .then(apiV2.deserialize)
          .then(function(result) {
            if (!cache.indexed_teams) cache.indexed_teams = {}
            cache.indexed_teams[cacheKey] = result
            return result
          })
    }

    return OrganizationProfileSchema
  })
