angular.module('org-admin')
  .factory('RosterHistoryState', function($debounce, $rootScope, $timeout, currentOrg, DS, i18ng, Program, rosterHistoryChangeType, SeasonTeams) {

    var state = {
      store: DS.store,
      filters: {},
      filtering: {
        select2Opts: {
          allowClear: true,
          dropdownAutoWidth: true,
          minimumResultsForSearch: 0
        },
        programsSelect2Opts: {
          allowClear: true,
          dropdownAutoWidth: true,
          placeholder: i18ng.t('ROSTER_HISTORY.FILTERS.program_name_placeholder'),
          minimumInputLength: 2,
          multiple: true,
          ajax: {
            transport: $debounce(function(params, success, failure) {
              return Program.findAll({
                org_id: currentOrg.id,
                name_like: params.data.term,
                page: params.data.page || 1,
                per_page: 10,
                sort: 'order_by_name',
                sort_dir: 'asc'
              }, {
                bypassCache: true
              })
                .then(success, failure)
            }, 250),
            processResults: function(programs) {
              return {
                results: _.map(programs, function(program) {
                  return { id: program.id, text: program.name }
                }),
                pagination: {
                  more: !programs.pagination.last_page
                }
              }
            }
          }
        },
        teamsSelect2Opts: {
          allowClear: true,
          dropdownAutoWidth: true,
          placeholder: i18ng.t('ROSTER_HISTORY.FILTERS.team_name_placeholder'),
          minimumInputLength: 2,
          multiple: true,
          ajax: {
            transport: $debounce(function(params, success, failure) {
              return SeasonTeams.findAll({
                org_id: currentOrg.id,
                name_like: params.data.term,
                page: params.data.page || 1,
                per_page: 10,
                sort: 'order_by_name',
                sort_dir: 'asc'
              }, {
                bypassCache: true
              })
                .then(success, failure)
            }, 250),
            processResults: function(teams) {
              var uniqueTeams = _.uniq(teams, 'team_id')
              if (teams.pagination.first_page && !teams.pagination.last_page && uniqueTeams.length <= 6) {
                // tell select2 to load the next page, since this one won't fill up the dropdown and load is triggered by scroll
                var select2 = this.container
                $timeout(function() {
                  select2.$results.trigger('scroll')
                }, 0, false)
              }
              return {
                results: _.map(uniqueTeams, function(team) {
                  return { id: team.team_id, text: team.name }
                }),
                pagination: {
                  more: !teams.pagination.last_page
                }
              }
            }
          }
        }
      }
    }

    var selectFilters = [
      // filtering by user is not supported by the auditable_actions endpoint yet
      // {
      //   key: 'by',
      //   t: 'by',
      //   select2Opts: { tbd },
      // },
      {
        key: 'action',
        t: 'action',
        i18nPrefix: 'ROSTER_HISTORY.ACTIONS.',
        select2Opts: {
          multiple: false,
          data: _.map(rosterHistoryChangeType.actions, function(type) {
            return {
              id: type,
              text: i18ng.t('ROSTER_HISTORY.ACTIONS.' + type),
            }
          })
        },
      },
      {
        key: 'programName',
        t: 'program_name',
        storeAccessor: {
          key: 'programs',
          filter: function(entry) {
            return {
              id: entry
            }
          },
          displayField: 'name'
        },
        select2Opts: state.filtering.programsSelect2Opts,
      },
      // filtering by team is not supported yet
      // {
      //   key: 'teamName',
      //   t: 'team_name',
      //   storeAccessor: {
      //     key: 'seasonTeams',
      //     filter: function(entry) {
      //       return {
      //         team_id: entry
      //       }
      //     },
      //     displayField: 'name'
      //   },
      //   select2Opts: state.filtering.teamsSelect2Opts,
      // },
    ]

    state.filtering.selectFilters = selectFilters

    state.convertFiltersToFields = convertFiltersToFields
    state.convertFieldsToFilters = convertFieldsToFilters
    state.removeFilter = removeFilter
    state.clearAll = clearAll
    state.filterCount = filterCount
    state.clone = clone

    function convertFiltersToFields(filters) {
      filters = this.filters
      var fields = this.filterFields = {}

      if (filters.changeDate) {
        fields.changeDateMode = filters.changeDate.mode
        fields.changeDateStart = filters.changeDate.start
        fields.changeDateEnd = filters.changeDate.end
      }

      _.each(this.filtering.selectFilters, function(filter) {
        if (typeof filters[filter.key] !== 'undefined') {
          fields[filter.key] = filters[filter.key]
        }
      })
    }

    function convertFieldsToFilters() {
      var filters = this.filters
      var fields = this.filterFields
      this.filtersEmpty = true

      switch (fields.changeDateMode) {
        case 'after':
        case 'before':
        case 'on':
          if (fields.changeDateStart) {
            filters.changeDate = {
              mode: fields.changeDateMode,
              start: fields.changeDateStart,
            }
            this.filtersEmpty = false
          }
          else {
            delete filters.changeDate
          }
          break

        case 'between':
          if (fields.changeDateStart && fields.changeDateEnd) {
            filters.changeDate = {
              mode: fields.changeDateMode,
              start: fields.changeDateStart,
              end: fields.changeDateEnd,
            }
            this.filtersEmpty = false
          }
          else {
            delete filters.changeDate
          }
          break

        default:
          delete filters.changeDate
      }

      var self = this
      _.each(state.filtering.selectFilters, function(filter) {
        var fieldValue = fields[filter.key]
        // needed to support filters that don't allow multiple values
        if (typeof fieldValue === 'undefined' || fieldValue === null || fieldValue === '') {
          delete filters[filter.key]
          return
        }
        filters[filter.key] = _.isArray(fieldValue) ? fieldValue : [fieldValue]
        self.filtersEmpty = false
      })
    }

    function removeFilter(filter, item) {
      var filters = this.filters
      var self = this
      $timeout(function() { // allow event to bubble up to parent first
        if (item) {
          filters[filter] = _.without(filters[filter], item)
          if (!filters[filter].length) {
            delete filters[filter]
          }
        }
        else {
          delete filters[filter]
        }
        self.convertFiltersToFields()
      })
    }

    function filterCount() {
      return _.size(this.filters)
    }

    function clearAll() {
      this.filterFields = {}
    }

    function clone() {
      return _.extend(
        {}, // start with a new obj
        state, // add all state props (importantly, including functions)
        JSON.parse(JSON.stringify(_.pick(this, 'filters', 'filterFields'))) // overwrite filter with deep clones
      )
    }

    return state

  })
