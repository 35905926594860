angular.module('org-admin')
  .directive('rosterHistory', function() {
    return {
      restrict: 'E',
      scope: {},
      bindToController: {
        persona: '='
      },
      templateUrl: '/static/org/roster-history/roster-history.html',
      controllerAs: 'ctrl',
      controller: function(_, $scope, $timeout, AuditableActions, currentOrg, dialog, DS, i18ng, moment, rosterHistoryChangeType, RosterHistoryState, Search, setAs, pageViewHandler) {
        var ctrl = this

        ctrl.allowTextSearch = false // not supported by the auditable_actions API yet
        ctrl.state = RosterHistoryState
        ctrl.openFilterbox = function() {
          dialog.confirm({
            component: 'roster-history-filterbox'
          })
            .then(function(filters) {
              pageViewHandler.fireEvent('FilterModal.ApplyFilters', 8)
              ctrl.state.filters = filters
            })
            .then(ctrl.state.convertFiltersToFields.bind(ctrl.state))
        }
        ctrl.search = Search.create({
          minLength: 1,
          update: function(search) {
            ctrl.state.filters.search = _.uniq(_.compact(_.map(search.split(','), _.trim)))
          }
        })
        ctrl.showControls = true
        ctrl.options = { result: [], pagination: {} }
        ctrl.sortColumns = { change_date: 'desc' }
        ctrl.sortableColumns = {
          // not implemented by the auditable_actions API yet
          // changeDate: 'change_date',
          // changeType: 'change_type',
          // programName: 'program_name',
          // programType: 'program_type',
          // by: 'by'
        }
        ctrl.principalName = function(entry) {
          // TODO: Change this hack of ['1628', '1', '-1'] once we figure out why the type isn't Client
          if (entry.principal_type === 'User' && !_.contains(['1628', '1', '-1'], entry.principal_id)) return entry.principal_name
          return entry.change === 'rostered' ? i18ng.t('ROSTER_HISTORY.added_by_system') : i18ng.t('ROSTER_HISTORY.changed_by_system')
        }

        ctrl.$onInit = function() {
          $scope.$watch('ctrl.sortColumns', onSortColumnsChanged)
          $scope.$watch('ctrl.state.filters', loadAuditableActions, true)

          return loadAuditableActions()
            .then(setAs(ctrl, 'loaded', true))
        }

        function loadAuditableActions() {
          var params = {
            persona_id: ctrl.persona.id,
            org_id: currentOrg.id,
          }
          if (ctrl.state.filters.action) {
            // filtering by more than one change type is not supported yet
            params.change_type = ctrl.state.filters.action.join(',')
          }
          if (ctrl.state.filters.changeDate) {
            switch (ctrl.state.filters.changeDate.mode) {

              case 'after':
                params.start = moment.utc(ctrl.state.filters.changeDate.start).add(1, 'd').toISOString()
                break

              case 'before':
                params.end = ctrl.state.filters.changeDate.start
                break

              case 'between':
                params.start = ctrl.state.filters.changeDate.start
                params.end = moment.utc(ctrl.state.filters.changeDate.end).add(1, 'd').toISOString()
                break

              case 'on':
                params.start = ctrl.state.filters.changeDate.start
                params.end = moment.utc(ctrl.state.filters.changeDate.start).add(1, 'd').toISOString()
                break
            }
          }
          if (_.get(ctrl.state.filters, 'programName.length')) {
            _.extend(params, {
              program_originator_type: 'program',
              program_originator_system: 'StatNgin',
              program_originator_ids: ctrl.state.filters.programName.join(',')
            })
          }
          return AuditableActions.findAll(params, ctrl.options)
            .then(function(entries) {
              _.each(entries, function(entry) {
                return _.extend(entry, {
                  change: rosterHistoryChangeType.getChangeType(entry)
                })
              })
              return entries
            })
            .then(setAs(ctrl, 'entries'))
        }

        function onSortColumnsChanged(cols) {
          var fieldNames = _.keys(cols)
          if (!ctrl.entries) return
          ctrl.entries.sort(function(a, b) {
            var field = _.find(fieldNames, function(fieldName) {
              return a[fieldName] !== b[fieldName]
            })
            if (!field) {
              return 0
            }
            if (cols[field] === 'asc') {
              return a[field] < b[field] ? -1 : 1
            }
            return a[field] < b[field] ? 1 : -1
          })
        }
      }
    }
  })
