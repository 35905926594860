angular.module('org-admin')

  .directive('paymentsOverview', function() {

    return {
      scope: {},
      templateUrl: '/static/org/payments/payments-overview.html',
      controllerAs: 'ctrl',
      controller: function(
        $rootScope,
        $scope,
        appPermission,
        currentOrg,
        debounceCallback,
        dialog,
        ENV,
        FinancialAdminUiService,
        InvoiceGroup,
        pageViewHandler,
        setWhile
      ) {

        var ctrl = this
        ctrl.helpLink = 'https://help.sportsengine.com/en/articles/7544529-what-is-invoicing'
        var findInvoices = debounceCallback(InvoiceGroup.findAll)

        ctrl.$onInit = function() {
          if (!$rootScope.financialAdminUiLibraryLoaded) {
            FinancialAdminUiService.loadSharedScripts()
            $rootScope.financialAdminUiLibraryLoaded = true
          }
          fireAppcuesEvent()
        }

        // PUBLIC METHODS
        ctrl.year = new Date().getFullYear()

        ctrl.sendInvoices = function() {
          dialog.confirm({
            directive: 'send-invoices',
            scope: $scope,
            attrs: {}
          })
        }

        ctrl.setAccount = function() {
          dialog.confirm({
            directive: 'choose-bank-account-other',
            scope: $scope,
            attrs: {
              org: currentOrg
            }
          })
        }

        setWhile(ctrl, 'loading', getInvoiceGroupCount)()

        $scope.listenToRoot('invoice:update', getInvoiceGroupCount)
        $scope.listenToRoot('invoiceGroup:updateRequest', getInvoiceGroupCount)


        // PRIVATE METHODS

        function getInvoiceGroupCount() {
          return InvoiceGroup.count({ org_id: currentOrg.id })
            .then(function(res) {
              ctrl.customerIsNew = !res || !res.total_records
            })
        }

        function fireAppcuesEvent() {
          pageViewHandler.mergeToDL('appCuesData', { showStripePrompt: !!appPermission.financialSettings.showStripePrompt })
          pageViewHandler.pushEventToDL('appCues:showStripePrompt:ready')
        }
      }
    }

  })
