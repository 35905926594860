angular.module('pl-shared')

  .factory('IndexArchivedSurvey', function(Alerts, i18ng, Survey) {

    return {
      index: index
    }

    /**
     * Send a request to index a survey. After request shows a toast notification depends on request result
     *
     * @param {object} survey - Unindexed survey dataSource object.
     */
    function index(survey) {
      var surveyId = survey.id.toString().replace('survey_results.', '')

      return Survey.index_survey({ params: { survey_id: surveyId } })
        .then(indexingSurveySuccess)
        .catch(indexingSurveyFail)

      function indexingSurveySuccess() {
        var title = i18ng.t('CONTACTS_LIST.FILTERS.INDEXING_SURVEY.success_title', { survey_name: survey.name || survey.text })
        var message = i18ng.t('CONTACTS_LIST.FILTERS.INDEXING_SURVEY.success')
        Alerts.info(message, { title: title })
      }

      function indexingSurveyFail() {
        Alerts.error('CONTACTS_LIST.FILTERS.INDEXING_SURVEY.error', { survey_name: survey.name || survey.text })
      }
    }

  })
