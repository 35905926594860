angular.module('org-admin')
  .factory('contactsDynamicColumnsFactory', function(_, $q, currentOrg, dynamicColumnsFactory, filterPanelService, i18ng, OrganizationProfileSchema, MembershipDefinition, SurveySchema, userSettings) {
    return {
      create: create
    }

    /**
     * Creates a new instance of dynamicColumns and loads the initial state
     *
     * @param {object} config - The config object.
     * @param {string} config.cacheKey - The unique cache key. Will be used to load and save the state of the object.
     * @param {string[]} [config.defaultColumnKeys] - The columns to display if the user has not selected any columns.
     * @param {string[]} [config.requiredColumnKeys] - The columns that cannot be hidden. These columns will always be returned by getShownColumns().
     * @param {string} strategy - Optional param passed when bypassing local storage. Options are "smart-group-api" and "static-group-api"
     * @param {number} groupId - Optional param passed when using the "smart-group-api" oe "static-group-api" strategy
     * @param {string[]} displayColumns - Optional param used to override columns saved in local storage
     * @returns {Promise.<object>} - A promise that is resolved once the initial state has been loaded.
     */
    function create(config) {
      config = _.defaults({}, config, {
        defaultColumnKeys: [
          'se_profile.*.invite_status',
          'se_profile.*.email',
          'se_profile.*.cell_phone'
        ],
      })
      config.columnProviders = _.extend({
        'se_profile': loadSEProfileColumns,
        'memberships': loadMembershipColumns,
        'organization_profile': loadOrgProfileColumns,
        // these are duplicated for backward compatibility
        'survey': loadSurveyColumns,
        'survey_results': loadSurveyColumns,
        'rostering': loadRosteringColumns,
        'team_instance_ids': loadRosteringColumns,
        'suspensions': loadSuspensionColumns
      }, config.columnProviders)

      return dynamicColumnsFactory.create(config)
    }

    /**
     * Loads column data for se profile fields.
     *
     * @private
     * @returns {columnConfig[]} - The column data.
     */
    function loadSEProfileColumns() {
      return OrganizationProfileSchema.find(currentOrg.id)
        .then(function(organizationProfileSchema) {
          return _.chain(organizationProfileSchema.schema)
            .reject(function(record) {
              return record.key.indexOf('organization_profile') >= 0
            })
            .map(_.partial(mapSchemaRecordToColumn, { name: 'SportsEngine Profile', type: 'se_profile' }))
            .value()
        })
    }

    /**
     * Loads column data for organization profile fields.
     *
     * @private
     * @param {number} dataSourceId - The organization profile id.
     * @returns {columnConfig[]} - The column data.
     */
    function loadOrgProfileColumns(dataSourceId) {
      return OrganizationProfileSchema.find(dataSourceId)
        .then(function(organizationProfileSchema) {
          return _.chain(organizationProfileSchema.schema)
            .filter(function(record) {
              return record.key.indexOf('organization_profile') >= 0
            })
            .map(_.partial(mapSchemaRecordToColumn, { name: organizationProfileSchema.organization_name, type: 'organization_profile', id: dataSourceId }))
            .value()
        })
    }

    /**
     * Loads column data for membershipdefinition fields.
     *
     * @private
     * @param {string} dataSourceId - The membership id (optional).
     * @returns {columnConfig[]} - The column data.
     */
    function loadMembershipColumns(dataSourceId) {
      return getMembershipDefinitions(dataSourceId).then(function(memdef) {
        var memDefId = dataSourceId === 'memberships' ? null : dataSourceId
        return filterPanelService.getMembershipFields({ orgId: currentOrg.id, membershipDefinitionId: memDefId })
          .then(function(fields) {
            var params = { name: memdef?.name || 'Membership', type: 'memberships' }
            if (_.any(dataSourceId)) _.extend(params, { id: dataSourceId })
            return (fields).map(_.partial(mapSchemaRecordToColumn, params))
          })
      })
    }

    function getMembershipDefinitions(memDefId) {
      if (_.isEmpty(memDefId)) return $q.when()

      return MembershipDefinition.find(memDefId)
    }

    function loadSuspensionColumns() {
      return filterPanelService.getSuspensionFields()
        .then(function(fields) {
          return fields.filter(function(field) {
            return field.id !== 'active' && field.id !== 'expired'
          }).map(function(field) {
            field.name = 'Suspension ' + field.name
            return mapSchemaRecordToColumn({ type: 'suspensions' }, field)
          })
        })
    }

    /**
     * Loads column data for survey fields.
     *
     * @private
     * @param {number} dataSourceId - The survey id.
     * @returns {columnConfig[]} - The column data.
     */
    function loadSurveyColumns(dataSourceId) {
      if (_.startsWith(dataSourceId, 'survey_results.')) {
        dataSourceId = parseInt(dataSourceId.split('.')[1], 10)
      }
      return SurveySchema.find(dataSourceId)
        .then(function(surveySchema) {

          var dataSource = { name: surveySchema.survey_name, type: 'survey', id: dataSourceId }
          var quEls = _.chain(surveySchema.schema)
            .map('question_elements')
            .flatten()
            .map(_.partial(mapSchemaRecordToColumn, dataSource))
            .value()
          var metadata = (surveySchema.metadata_question_elements || [])
            .map(_.partial(mapSchemaRecordToColumn, dataSource))
          var participationOptions = (surveySchema.participation_options || [])
            .map(_.partial(mapSchemaRecordToColumn, dataSource))

          return quEls.concat(participationOptions).concat(metadata)
        })
    }

    /**
     * Loads column data for rostering fields.
     *   This is hardcoded for now, until it has been decided to move to the back end.
     *
     * @private
     * @returns {columnConfig[]} - The column data.
     */
    function loadRosteringColumns() {
      // TODO: reduce complexity
      return $q.when([{
        key: 'rostering.*.roster_player_count',
        field: 'roster_players',
        name: i18ng.t('ROSTERING.team_plural'),
        title: i18ng.t('ROSTERING.team_plural'),
        type: 'integer',
        style: {
          'max-width': '110px', // th adds 35px (caret+padding)
          'white-space': 'nowrap'
        },
        contentStyle: {
          'max-width': '75px', // this will fit the rendered width of 80% of question name texts
          'display': 'inline-block',
          'vertical-align': 'text-bottom', // fixes align issues with text due to inline-block
          'overflow': 'hidden',
          'text-overflow': 'ellipsis'
        },
        id: 'roster_players',
        dataSourceName: 'Mass Rostering',
        dataSourceId: null,
        dataSourceType: 'rostering'
      }])
    }

    /**
     * Maps schema data to column data for the dynamicColumns service.
     *
     * @private
     * @param {object} dataSource - The object containing data source info.
     * @param {string} dataSource.name - The user-friendly name of the data source.
     * @param {number} [dataSource.id] - Optional. The unique id for the provided data source.
     * @param {string} dataSource.type - The type of the data source.
     * @param {object} schemaRecord - The object containing the schema info.
     * @param {string} schemaRecord.key - The unique key for the record.
     * @param {string} schemaRecord.name - The name of the record.
     * @param {string} schemaRecord.type - The data type of the record.
     * @returns {columnConfig} - An object representing a column.
     */
    function mapSchemaRecordToColumn(dataSource, schemaRecord) {
      var columnStyle = {
        'max-width': '207px', // th adds 35px (caret+padding)
        'white-space': 'nowrap'
      }

      var columnContentStyle = {
        'max-width': '172px', // this will fit the rendered width of 80% of question name texts
        'display': 'inline-block',
        'vertical-align': 'text-bottom', // fixes align issues with text due to inline-block
        'overflow': 'hidden',
        'text-overflow': 'ellipsis'
      }

      var key = [dataSource.type, (dataSource.id || '*'), schemaRecord.key].join('.')

      return {
        key: key,
        field: schemaRecord.key,
        name: schemaRecord.name,
        title: schemaRecord.report_name || schemaRecord.name,
        type: schemaRecord.type,
        style: columnStyle,
        contentStyle: columnContentStyle,
        id: _.snakeCase(key),
        dataSourceName: dataSource.name,
        dataSourceId: dataSource.id,
        dataSourceType: dataSource.type
      }
    }
  })
