angular.module('pl-shared')

  /*/////////////////

    Search.config({
      minLength: 2,
      error: function(err) { ... }, // default noop
      ngModelOptions: {
        debounce: 500 // default is 300 (ms)
      }
    })

    ctrl.search = Search.create({
      minLength: 2,
      update: function(term) { ... must return a promise ... },
      error: function(err) { ... },
      ngModelOptions: {
        getterSetter: true, // this is defaulted to true and cannot be overridden
        debounce: 500 // default is 300 (ms)
      }
    })

    <input ng-model="ctrl.search" ng-model-options="ctrl.search.ngModelOptions" />

  /*/////////////////

  .service('Search', function(_, $q) {

    var DEFAULT_CONFIG = {
      minLength: 2,
      error: angular.noop,
      ngModelOptions: {
        debounce: 1000
      }
    }

    var REQUIRED_CONFIG = {
      ngModelOptions: {
        getterSetter: true
      }
    }

    function create(opts) {

      var cancelSearch
      var config = angular.merge({}, DEFAULT_CONFIG, opts, REQUIRED_CONFIG)
      if (!config.update) throw new Error('Invalid search options: `update` function is required.')

      function searchFn(newTerm) {
        // getter
        if (!arguments.length) return searchFn.term
        // no need to update
        if (newTerm === searchFn.term) return
        // always keep term up to date
        searchFn.term = newTerm
        // only search when cleared or reached min length
        if (newTerm.length > 0 && newTerm.length < config.minLength) return
        // start the search
        searchFn.inProgress = true
        if (cancelSearch) cancelSearch.resolve()
        cancelSearch = $q.defer()

        $q.when(config.update(newTerm, { timeout: cancelSearch.promise }))
          .catch(config.error)
          .finally(function() { searchFn.inProgress = false })
      }

      searchFn.term = opts.term || ''
      searchFn.ngModelOptions = config.ngModelOptions
      searchFn._config = config

      return searchFn
    }

    function setConfig(newConfig) {
      angular.merge(DEFAULT_CONFIG, newConfig)
    }

    return {
      create: create,
      config: setConfig
    }

  })
