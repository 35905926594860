angular.module('pl-shared')
  .factory('dateHelper', function($filter) {

    var dateFilter = $filter('date')

    return {
      shortRange: shortRange,
      formatFullDate: formatFullDate,
      normalizedFullDate: normalizedFullDate,
      fullDateWithTime: fullDateWithTime
    }

    // Jan 3 - 8, 2018
    // Jan 3 - Feb 14, 2018
    // Dec 21, 2017 - Jan 4, 2018
    function shortRange(startDateTime, endDateTime) {
      var startDateString = normalizeDateTime(startDateTime)
      var endDateString = normalizeDateTime(endDateTime)

      // Handle cases when we don't have two dates
      if (!startDateString && !endDateString) return ''
      if (!endDateString) return formatFullDate(startDateString)
      if (!startDateString) return formatFullDate(endDateString)

      var separator = ' - '
      var sameYear = startDateString.substring(0, 4) === endDateString.substring(0, 4)
      var sameMonth = startDateString.substring(5, 7) === endDateString.substring(5, 7)

      if (sameYear) {
        if (sameMonth) return dateFilter(startDateString, 'MMM d') + separator + dateFilter(endDateString, 'd, yyyy')
        else return dateFilter(startDateString, 'MMM d') + separator + formatFullDate(endDateString)
      }
      else {
        return formatFullDate(startDateString) + separator + formatFullDate(endDateString)
      }
    }

    function normalizeDateTime(dateTime) {
      return (typeof dateTime === 'string') ? dateTime.substring(0, 10) : ''
    }

    function formatFullDate(dateStr) {
      return dateFilter(dateStr, 'MMM d, yyyy')
    }

    function normalizedFullDate(dateStr) {
      return formatFullDate(normalizeDateTime(dateStr))
    }

    function fullDateWithTime(dateStr) {
      return dateFilter(dateStr, "MMM d, yyyy 'at' hh:mm a")
        .replace('AM', 'am')
        .replace('PM', 'pm')
    }

  })
