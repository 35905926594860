angular.module('pl-shared')

  .directive('saleActivityCard', function() {

    return {
      scope: {},
      bindToController: {
        sale: '<',
        activity: '<',
        viewMode: '<?'
      },
      templateUrl: '/static/shared/components/sales/sale-activity-card.html',
      controllerAs: 'ctrl',
      controller: function($scope, dialog, currentOrg) {

        var ctrl = this
        ctrl.orgView = ctrl.viewMode === 'org'
        ctrl.userView = ctrl.viewMode === 'user'
        ctrl.displayType = ctrl.userView ? 'endUser' : 'accountant'
        ctrl.saleType = getSaleType()
        $scope.$watchCollection('ctrl.activity', updateActivity)

        function updateActivity() {
          ctrl.hasTransactions = checkForTransactions()
          ctrl.hasNotes = checkForNotes()
        }

        function getSaleType() {
          if (ctrl.sale.type === 'Order') return 'order'
          else if (ctrl.orgView) return 'invoice'
          else return 'bill'
        }

        function checkForTransactions() {
          var transactions = _.filter(ctrl.activity, 'sale_transaction_id')
          return transactions.length
        }

        function checkForNotes() {
          var notes =  _.filter(ctrl.activity, 'notes')
          return notes.length
        }

        ctrl.isSentLabel = function(label) {
          return label === 'Bill Sent' || label === 'Invoice Sent' || label === 'Order Created'
        }

        ctrl.isDiscountLabel = function(label) {
          return label.includes('discount redeemed')
        }

        ctrl.isReminderLabel = function(label) {
          return label === 'Reminder Sent'
        }

        ctrl.activityLabelKey = function(label) {
          if (ctrl.isSentLabel(label)) {
            return ctrl.saleType + '_sent'
          }
          if (label === 'Bill Voided' || label === 'Order Voided') {
            return ctrl.saleType  + '_voided'
          }
          return false
        }

        ctrl.noteLabelKey = function(label) {
          return ctrl.isSentLabel(label) || ctrl.isReminderLabel(label) ?
            this.sale.type.toLowerCase() + '_message' :
            this.sale.type.toLowerCase()  + '_note'
        }

        ctrl.triggerMessageDialog = function(message) {
          dialog.confirm({
            component: 'message-dialog',
            attrs: {
              message: message
            }
          })
        }
      }
    }
  })
