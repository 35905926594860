angular.module('org-admin')
  .directive('membershipDefinitionMessageEdit', function() {
    return {
      restrict: 'EA',
      scope: {},
      bindToController: {
        cancel: '=',
        confirm: '=',
        membershipDefinition: '=',
        hasEligibility: '=',
        dialogOptions: '='
      },
      templateUrl: '/static/org/memberships/membership-definition-message-edit.html',
      controllerAs: '$ctrl',
      controller: function(_, i18ng, currentOrg, Alerts, MembershipDefinition, SNAP) {
        var $ctrl = this
        var emailImageFileName = $ctrl.hasEligibility ? 'sample-eligibility-email.svg' : 'sample-email.svg'
        $ctrl.submit = submit
        $ctrl.message = _.get($ctrl.membershipDefinition, 'messages[0].body')
        $ctrl.emailPreviewPath = SNAP.assetPath + '/images/memberships/' + emailImageFileName
        $ctrl.editorCreated = editorCreated

        /**
         * I don't love this, but RTM delays of 5 minutes need to be temporarily reset to 0
         * in order for the correct radio button to be selected.
         */
        if ($ctrl.membershipDefinition.welcome_delay_minutes === 1 || $ctrl.membershipDefinition.welcome_delay_minutes === 5) {
          $ctrl.membershipDefinition.welcome_delay_minutes = 0
        }

        function submit() {
          var data = _.clone($ctrl.membershipDefinition)
          data.messages[0].body = $ctrl.message

          MembershipDefinition.update($ctrl.membershipDefinition.id, data)
            .then(editSuccess)
            .catch(editError)

          function editSuccess(result) {
            $ctrl.submitting = false
            Alerts.success('MEMBERSHIP_DEFINITION_MESSAGE.ALERTS.edit_success')
            $ctrl.confirm()
          }

          function editError() {
            $ctrl.submitting = false
            $ctrl.failed = true
            $ctrl.serverErrorMessages = _.get(arguments[0], 'data.error.messages') || [i18ng.t('MEMBERSHIP_DEFINITION_MESSAGE.ERRORS.edit_failed')]
          }

        }

        function editorCreated(editor) {
          editor.root.setAttribute('data-gramm', false)
          editor.root.setAttribute('data-gramm_editor', false)
        }
      }
    }
  })
