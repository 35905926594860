angular.module('org-admin')
  .factory('RoleLabel', function(currentOrg) {

    var DEFAULT_LABEL = 'Governing Season'

    var gsRoleKeyMap = {
      governingSeasonAdmin: 'Manager',
      governingSeasonView: 'Viewer',
      governingSeasonAdminMenuReader: 'Manager - Ineligible',
      governingSeasonViewMenuReader: 'Viewer - Ineligible'
    }

    var gsAdminManagementMap = {
      governing_season_admin_access: 'Manager',
      governing_season_view_access: 'Viewer',
    }

    return {
      transformCompositeRole: transformCompositeRole,
      transformRoleAssignment: transformRoleAssignment,
      transformAdminManagement: transformAdminManagement,
      isGoverningSeasonRoleAssignment: isGoverningSeasonRoleAssignment,
    }

    function isGoverningSeasonRoleAssignment(roleAssignment) {
      return Object.keys(gsRoleKeyMap).includes(roleAssignment.role_key)
    }

    function rootOrgName() {
      // if no root-orgs, this is root org, use Gov Seas terms
      if (!currentOrg.root_organizations || !currentOrg.root_organizations[0]) {
        return DEFAULT_LABEL
      }
      // in the case of multi-root-orgs, this will just grab the first one - not ideal
      return (currentOrg.root_organizations[0] || {}).name
    }

    function transformCompositeRole(role) {
      if (!Object.keys(gsRoleKeyMap).includes(role.key)) return role.name

      return rootOrgName() + ' ' + gsRoleKeyMap[role.key]
    }

    function transformRoleAssignment(roleAssignment) {
      if (!isGoverningSeasonRoleAssignment(roleAssignment)) return roleAssignment.role

      return rootOrgName() + ' ' + gsRoleKeyMap[roleAssignment.role_key]
    }

    function transformAdminManagement(admin) {
      return Object.keys(gsAdminManagementMap)
        .filter(function(perm) { return admin[perm] })
        .map(function(perm) { return rootOrgName() + ' ' + gsAdminManagementMap[perm] })
    }
  })
