angular.module('pl-shared')

  .directive('overflowButton', function() {
    var idCounter = 1
    return {
      replace: true,
      restrict: 'E',
      scope: {},
      bindToController: {
        actions: '=',
        id: '=overflowButtonId'
      },
      templateUrl: '/static/shared/components/overflow-button/overflow-button.html',
      controllerAs: 'ctrl',
      controller: function($scope, snPopover, i18ng) {

        var ctrl = this
        var parent = $scope.$parent

        ctrl.click = function() {
          if (ctrl.onlyAction) ctrl.doAction(ctrl.onlyAction)
          else snPopover.show(ctrl.id)
        }

        ctrl.doAction = function(actionItem) {
          var actionFn = parent.$eval(actionItem.action)
          if (typeof actionFn === 'function') actionFn()
        }

        $scope.$watchCollection(filteredActions, filterActions)

        function filterActions(filteredActions) {
          ctrl.enabledActions = filteredActions
          ctrl.onlyAction = ctrl.enabledActions.length === 1 ? ctrl.enabledActions[0] : null
        }

        function filteredActions() {
          return _.filter(ctrl.actions, enabledActions)
        }

        function enabledActions(action) {
          if (typeof action.enabled === 'boolean') return action.enabled
          return parent.$eval(action.enabled)
        }
      }
    }
  })
