angular.module('org-admin')
  .component('filterPanelLogicSelect', {
    bindings: {
      filterRule: '<',
      readOnly: '<'
    },
    templateUrl: '/static/org/filtering/filter-panel-logic-select.html',
    controller: function filterPanelLogicSelectController(_, $scope, filterPanelService) {
      var $ctrl = this

      $ctrl.id = _.uniqueId('filter-panel-logic-select-')
      $ctrl.select2Options = {
        collapseOverflow: true,
        dropdownAutoWidth: true,
        minimumResultsForSearch: -1,
        setupAutoWidth: true
      }
      $ctrl.logicOptions = null
      $ctrl.timestamp = null
      $ctrl.currentLogic = null

      $ctrl.$onInit = $onInit
      $ctrl.LEGAL_FIELDS = ['first_name', 'last_name', 'middle_name', 'suffix', 'gender',
        'graduation_year', 'date_of_birth']

      /**
       * Initializes the component.
       */
      function $onInit() {
        $scope.$watch(function() { return $ctrl.filterRule && $ctrl.filterRule.field() }, onFieldChanged)
        $scope.$watch(function() { return $ctrl.filterRule && $ctrl.filterRule.logic() }, onLogicChanged)
      }

      function onLogicChanged(newVal, oldVal) {
        if (newVal !== oldVal) {
          $ctrl.currentLogic = newVal || oldVal
        }
      }

      function onFieldChanged(newVal, oldVal) {
        if (!newVal) {
          return
        }

        if (newVal.type === 'team_instance_ids' || newVal.type === 'suspensions') {
          // team_instance_ids currently requires {source: "team_instance_ids:123", field: "equal", logic: "equal"}
          // so we will just force it
          $ctrl.filterRule.logic({ logic: newVal.key, type: 'null' }) // type: 'null' is required here
          $ctrl.logicOptions = [] // truthy but empty
          return
        }

        var timestamp = $ctrl.timestamp = Date.now()

        filterPanelService.getLogicOptionsByType(newVal.type)
          .then(function(logicOptions) {
            var workingOptions = logicOptions.map((l) => l)
            if ($ctrl.LEGAL_FIELDS.includes(newVal.key)) {
              _.remove(workingOptions, function(e) {
                return ['blank', 'not_blank', 'not_contain', 'not_equal'].includes(e.logic)
              })
            }
            if (timestamp !== $ctrl.timestamp) {
              return
            }

            var sameOptions = _.isEqual($ctrl.logicOptions, workingOptions)
            $ctrl.logicOptions = workingOptions

            if (newVal && !oldVal && !$ctrl.filterRule.logic()) {
              setFirstOption()
            }
            else if ((newVal && oldVal) && (newVal !== oldVal)) {
              setFirstOption(sameOptions)
            }
          })
      }

      function setFirstOption(sameOptions) {
        var logic = sameOptions ? $ctrl.currentLogic : _.first($ctrl.logicOptions)
        $ctrl.filterRule.logic(logic)
      }
    }
  })
