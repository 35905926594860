angular.module('pl-shared')

  .directive('addressQuestionView', function() {

    return {
      scope: {},
      templateUrl: '/static/shared/components/regform/questions/address-question-view.html',
      controllerAs: 'ctrl',
      bindToController: {
        question: '=',
        answer: '='
      },
      controller: function(_, i18ng, GeographicOptions) {
        var ctrl = this
        var answer = ctrl.answer || {}


        var defaultsFields = {
          address_1: {
            label: i18ng.t('ADDRESS.LABELS.address_1')
          },
          address_2: {
            label: i18ng.t('ADDRESS.LABELS.address_2')
          },
          country: {
            label: i18ng.t('ADDRESS.LABELS.country')
          },
          state: {
            label: i18ng.t('ADDRESS.LABELS.state')
          },
          city: {
            label: i18ng.t('ADDRESS.LABELS.city')
          },
          postal_code: {
            label: i18ng.t('ADDRESS.LABELS.postal_code')
          }
        }

        ctrl.properties = ctrl.question.properties
        ctrl.fields = _.extend(defaultsFields, ctrl.properties.fields)

        // Get country lable
        GeographicOptions.countries()
          .then(function(countries) {
            _.each(countries, function(country) {
              if (country.value == ctrl.answer.value.country) {
                ctrl.display_country = country.label
              }
            })
          })
        // Get subregion label
        GeographicOptions.subregions({ params: { country: ctrl.answer.value.country } })
          .then(function(subregions) {
            _.each(subregions, function(subregion) {
              if (subregion.value == ctrl.answer.value.state) {
                ctrl.display_state = subregion.label
              }
            })
          })
      }
    }

  })
