angular.module('pl-shared')
  .component('multiselectDropdown', {
    templateUrl: '/static/shared/components/multiselect-dropdown/multiselect-dropdown.html',
    bindings: {
      options: '<',
      selectedOption: '=?selectedOption',
      placeholder: '@',
      isRequired: '<',
      showStaticOptions: '<',
      staticOptionsMessage: '@',
      hasErrors: '<',
      name: '@',
      form: '='
    },
    controllerAs: '$ctrl',
    controller: function($element, $timeout) {
      var $ctrl = this

      $ctrl.$onInit = function() {
        $ctrl.filteredOptions = angular.copy($ctrl.options)
        $ctrl.selectedOption = $ctrl.selectedOption || null
        $ctrl.select2Options = {
          tags: true,
          allowClear: true,
          placeholder: $ctrl.placeholder,
          minimumResultsForSearch: 0,
          templateResult: function(data) {
            if (!data.id) {
              return null
            }
            return data.text
          }
        }

        $timeout(function() {
          var selectElement = $element.find('#multiselectDropdown')
          selectElement.select2($ctrl.select2Options)
          selectElement.on('select2:select', function(e) {
            var selectedData = e.params.data
            $ctrl.handleSelectChange(selectedData)
          })

          selectElement.on('select2:unselect', function(e) {
            $ctrl.handleSelectChange(null)
          })
        })
      }

      $ctrl.$onChanges = function(changes) {
        if (changes.options) {
          $ctrl.filteredOptions = angular.copy($ctrl.options)
          $timeout(function() {
            var selectElement = $element.find('#multiselectDropdown')
            selectElement.select2('destroy')
            $ctrl.select2Options.placeholder = $ctrl.placeholder
            selectElement.select2($ctrl.select2Options)
            if ($ctrl.selectedOption && $ctrl.selectedOption.id !== null) {
              selectElement.val($ctrl.selectedOption.id).trigger('change')
            }
            else {
              selectElement.val(null).trigger('change')
            }
          })
        }
      }

      $ctrl.handleSelectChange = function(selectedData) {
        if (selectedData) {
          if (selectedData._resultId) {
            if (!$ctrl.options.find(opt => opt.title === selectedData.text)) {
              $ctrl.selectedOption = {
                id: Number(selectedData.id),
                title: selectedData.text
              }
              $ctrl.selectedValues = [$ctrl.selectedOption]
              if (!$ctrl.options.some(opt => Number(opt.id) === $ctrl.selectedOption.id)) {
                $ctrl.options.push($ctrl.selectedOption)
                $ctrl.filteredOptions = angular.copy($ctrl.options)
              }
            }
          }
          else {
            $ctrl.selectedOption = {
              title: selectedData.text,
              id: null
            }
            $ctrl.selectedValues = [$ctrl.selectedOption]
            if (!$ctrl.options.some(opt => opt.title === selectedData.text)) {
              $ctrl.options.push($ctrl.selectedOption)
              $ctrl.filteredOptions = angular.copy($ctrl.options)
            }
          }
        }
        else {
          if ($ctrl.selectedOption && !$ctrl.selectedOption.id) {
            $ctrl.options = $ctrl.options.filter(opt => opt.title !== $ctrl.selectedOption.title)
            $ctrl.filteredOptions = angular.copy($ctrl.options)
          }
          $ctrl.selectedOption = null
          $ctrl.selectedValues = []
        }
      }

      $ctrl.$doCheck = function() {
        if ($ctrl.selectedValues && $ctrl.selectedValues.length > 0) {
          if ($ctrl.selectedOption !== $ctrl.selectedValues[0]) {
            $ctrl.selectedValues = $ctrl.selectedOption ? [$ctrl.selectedOption] : []
          }
        }
      }
    }
  })
