angular.module('org-admin')

  .component('programListingsList', {
    bindings: {
      publishedStatus: '@',
      reload: '='
    },
    templateUrl: '/static/org/program-listings/program-listings-list.html',
    controller: function(
      $scope, $rootScope, $filter, $q, Alerts, renderContext,
      setAs, selectHelper, showError, Search, ProgramListing, ProgramListingOptions,
      currentOrg, debounceCallback, dialog, listFilter, i18ng, pageViewHandler) {

      var $ = angular.element
      var $ctrl = this
      var publishedStatusActions = {
        'active': 'findAll',
        'archived': 'archived',
        'draft': 'findAll'
      }
      var publishedStatusParams = {
        'active': { searchable: true },
        'draft': { searchable: false },
        'archived': {}
      }

      $ctrl.org = currentOrg
      $ctrl.programListings = []
      $ctrl.options = { result: $ctrl.programListings }
      $ctrl.params = { org_id: currentOrg.id, sort: 'start_date', sort_dir: 'asc' }
      $ctrl.filters = []
      $ctrl.sortColumns = { start_date: 'asc' }
      $ctrl.selected = selectHelper.bind($scope, '$ctrl.programListings')
      $ctrl.archive = archive
      $ctrl.unarchive = unarchive
      $ctrl.publish = publish
      $ctrl.remove = remove
      $ctrl.copy = copy
      $ctrl.formatDate = formatDate
      $ctrl.addTracking = addTracking
      $ctrl.originator = $ctrl.org.owned_externally ? i18ng.t('ORIGINATORS.' + $ctrl.org.originator_system) : null

      // debounce the find method to avoid spamming the api
      var findWithAction = debounceCallback(_findWithAction)

      // dereigster listener on rootScope
      $scope.$watch('$ctrl.sortColumns', updateSortParams)
      $scope.$watch('$ctrl.reload', reload)

      $ctrl.search = Search.create({
        update: function(searchTerm) {
          $ctrl.params.name = String(searchTerm) || undefined
          load()
        }
      })

      load()

      // PRIVATE METHODS

      function reload(bool) {
        if (bool) load()
        $ctrl.reload = false
      }

      function draftList(programNames, translationKey) {
        return listFilter(
          programNames,
          translationKey,
          { other_term: 'draft', count: programNames.length }
        )
      }

      function remove() {
        if (!$ctrl.selected.length) return

        dialog.confirm({
          component: 'confirm-dialog',
          scope: $scope,
          attrs: {
            confirmAction: _remove,
            heading: i18ng.t('PROGRAM_LISTING_DELETE_DRAFT.heading'),
            message: draftList(_.map($ctrl.selected, 'name'), 'PROGRAM_LISTING_DELETE_DRAFT.message'),
            confirmLabel: i18ng.t('PROGRAM_LISTING_DELETE_DRAFT.confirm_label')
          }
        })

        function _remove() {
          return listAction({
            action: 'destroy',
            successMessage: 'PROGRAM_LISTING_DELETE_DRAFT.success',
            errorMessage: 'PROGRAM_LISTING_DELETE_DRAFT.error'
          })
        }
      }

      function archive() {
        if (!$ctrl.selected.length) return

        dialog.confirm({
          component: 'confirm-dialog',
          scope: $scope,
          attrs: {
            confirmAction: _archive,
            heading: i18ng.t('PROGRAM_LISTING_ARCHIVE.heading'),
            message: draftList(_.map($ctrl.selected, 'name'), 'PROGRAM_LISTING_ARCHIVE.message'),
            confirmLabel: i18ng.t('PROGRAM_LISTING_ARCHIVE.confirm_label')
          }
        })

        function _archive() {
          return listAction({
            action: 'archive',
            successMessage: 'PROGRAM_LISTING_ARCHIVE.success',
            errorMessage: 'PROGRAM_LISTING_ARCHIVE.error'
          })
        }
      }

      function unarchive() {
        return listAction({
          action: 'unarchive',
          successMessage: 'PROGRAM_LISTING_UNARCHIVE.success',
          errorMessage: 'PROGRAM_LISTING_UNARCHIVE.failed'
        })
      }

      function publish() {
        return listAction({
          action: 'update',
          params: { searchable: true },
          successMessage: 'PROGRAM_LISTING_PUBLISH.success',
          errorMessage: 'PROGRAM_LISTING_PUBLISH.failed'
        })
      }

      function copy() {
        if ($ctrl.selected.length !== 1) return

        return ProgramListing.copy($ctrl.selected[0])
          .then(copySuccess, copyFail)

        function copySuccess(data) {
          Alerts.success('PROGRAM_LISTING_COPY.success', { list: data.name })
          renderContext.goto('programListings.programListing.info', { programListingId: data.id })
        }

        function copyFail() {
          Alerts.error('PROGRAM_LISTING_COPY.failed', { list: $ctrl.selected[0].name })
        }
      }

      function listAction(opts) {
        if ($ctrl.selected.length === 0) return

        var success = []
        var failed = []

        $ctrl.programListings.loading = true
        var promises = []
        for (var i = 0; i < $ctrl.selected.length; i++) {
          var programListing = $ctrl.selected[i]
          var p = ProgramListing[opts.action](programListing.id, opts.params || {})
            .then(actionSuccess(programListing.name), actionError(programListing.name))
          promises.push(p)
        }

        return $q.all(promises).then(actionComplete, actionComplete)

        function actionSuccess(name) {
          return function() {
            success.push(name)
          }
        }

        function actionError(name) {
          return function() {
            failed.push(name)
          }
        }

        function actionComplete() {
          if (failed.length) Alerts.error(draftList(failed, opts.errorMessage))
          else Alerts.success(draftList(success, opts.successMessage))
          return load()
        }

      }

      // @todo: update to use program listing timezone
      function formatDate(date, approx) {
        if (!date) return ''

        var TIMEZONE_OFFSET = '+0000'
        var targetDate = new Date(date)
        var format = approx ? 'MMM yyyy' : 'MMM d, yyyy'
        return $filter('date')(targetDate, format, TIMEZONE_OFFSET)
      }

      function updateSortParams(newSort, oldSort) {
        if (newSort !== oldSort) {
          _.each($ctrl.sortColumns, function(dir, col) {
            $ctrl.params.sort = col
            $ctrl.params.sort_dir = dir
          })

          load()
        }
      }

      function _findWithAction(action, params, options) {
        return ProgramListing[action](params, options)
      }

      function load() {
        var action = publishedStatusActions[$ctrl.publishedStatus]
        if (!action) return // don't fetch unless we are at a valid list context

        var params = angular.merge(angular.copy($ctrl.params), publishedStatusParams[$ctrl.publishedStatus] || {})

        return ProgramListingOptions.getOptions()
          .then(loadProgramListings)
          .then(checkForDrafts)
          .then(function() {
            pageViewHandler.addPageDepths({ depth3: $filter('titleCase')($ctrl.publishedStatus) })
          })
          .then(loadComplete, loadComplete) // complete on success or fail

        function loadProgramListings() {
          return findWithAction(action, params, $ctrl.options)
            .catch(showError)
            .then(setAs($ctrl, 'programListings'))
        }

        function checkForDrafts() {
          // only show drafts button on active programs empty state when drafts are present
          if ($ctrl.programListings.length || $ctrl.publishedStatus !== 'active') {
            return $ctrl.hasDrafts = false
          }
          else {
            var draftParams = { searchable: false, per_page: 1, org_id: currentOrg.id }
            return ProgramListing.findAll(draftParams).then(setHasDrafts, setHasDrafts)
          }

          function setHasDrafts(drafts) {
            $ctrl.hasDrafts = !!drafts && !!drafts.length
          }
        }

        function loadComplete() {
          $ctrl.loaded = true
        }
      }

      function addTracking() {
        pageViewHandler.fireEvent('AddProgram.Click', 8)
      }
    }

  })
