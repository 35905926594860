angular.module('pl-shared')

  .factory('StaticGroupPersona', function(DS, apiV2, paginate) {
    var StaticGroupPersona = DS.defineResource({
      name: 'static_group_personas',
      endpoint: 'groups/static',
      basePath: DS.adapters.ngin_v2.defaults.basePath,
      httpConfig: DS.adapters.ngin_v2.defaults.httpConfig,
      defaultAdapter: 'ngin_v2',
      cacheResponse: false,
      actions: {
        add_personas_to_groups: {
          method: 'PUT',
          endpoint: 'groups/static/',
          response: apiV2.deserialize
        }
      }
    })

    return paginate(StaticGroupPersona)
  })
