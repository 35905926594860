// This shim provides SeTranslate with a factory to access i18ng.

function seTranslatorFactory(i18ng) {
  return function(key, tokens) {
    var i18ngOpts = {}
    for (var k in tokens) { i18ngOpts[k] = tokens[k] }

    // These net two properties mimic the expected behavior of SeTranslate
    i18ngOpts.returnObjectTrees = true
    i18ngOpts.defaultValue = ''

    return i18ng.t(key, i18ngOpts)
  }
}

// Makes it injectable for the app
try {
  angular.module('org-admin').factory('SeTranslator', seTranslatorFactory)
  delete window.seTranslatorFactory
}

// Allows the test to require it
catch (e) {
  module.exports = seTranslatorFactory // eslint-disable-line
}
