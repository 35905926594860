angular.module('pl-shared')

  .service('Ethnicities', function(DS) {
    return DS.defineResource({
      name: 'Ethnicities',
      endpoint: '/ethnicities',
      basePath: DS.adapters.se_api.defaults.basePath,
      httpConfig: DS.adapters.se_api.defaults.httpConfig,
      defaultAdapter: 'se_api',
    })
  })
