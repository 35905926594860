angular.module('org-admin')
  .directive('invoiceGroupPreview', function() {

    return {
      scope: {},
      bindToController: {
        invoiceGroup: '=',
        paymentTerm: '<',
        processingFee: '<',
        transactionFee: '<'
      },
      controllerAs: 'ctrl',
      templateUrl: '/static/org/send-invoices/invoice-group-preview.html',
      controller: function(_, Contact, setAs, Store) {

        var ctrl = this
        var total = ctrl.invoiceGroup.total()
        ctrl.customerPaysFee = ctrl.invoiceGroup.customer_pays_fee
        ctrl.loading = true
        ctrl.showItemizedDetails = false
        ctrl.maxRecipientsShown = 20
        ctrl.personaNames = _.map(ctrl.invoiceGroup.persona_ids, function(id) {
          return Contact.get(id).full_name
        })
        calculateTotals()
        ctrl.loading = false

        ctrl.showItemizedList = function() {
          return ctrl.invoiceGroup.itemized && ctrl.showItemizedDetails
        }

        ctrl.toggleShowItemizedDetails = function() {
          ctrl.showItemizedDetails = !ctrl.showItemizedDetails
        }

        function calculateTotals() {
          ctrl.serviceFee = parseFloat(ctrl.invoiceGroup.service_fee)
          if (ctrl.customerPaysFee) {
            ctrl.customerPaysAmount = total + ctrl.serviceFee
            ctrl.organizationReceivesAmount = total
          }
          else {
            ctrl.customerPaysAmount = total
            ctrl.organizationReceivesAmount = total - ctrl.serviceFee
          }
        }
      }
    }
  })
