angular.module('org-admin')
  .component('leagueLocationsList', {
    templateUrl: '/static/org/league-locations/league-locations-list.html',
    controller: function($scope, ENV, preloadScript, appPermission, currentOrg, dialog, i18ng, Persona, Search, SePagination, showError, Venue, pageViewHandler) {
      if (!appPermission.leagueSeasons.visible || !appPermission.seasonManagement.premium) return showError('404')

      var ctrl = this
      var searching = false

      preloadScript(`${ ENV.urls.sportAdminElements }/venue-add-edit-element/element.js`)

      ctrl.params = { org_id: currentOrg.id, order_by: 'name' }

      ctrl.pagination = new SePagination({
        loadDataFn: updateParams,
        storageId: 'leagueLocationsList'
      })

      ctrl.search = Search.create({
        update: (searchTerm) => {
          const name = searchTerm?.trim() || undefined
          updateParams({ name })
        }
      })

      ctrl.showList = () => ctrl.loaded && ctrl.venues.length
      ctrl.showSearchEmptyState = () => ctrl.loaded && !ctrl.venues.length && searching
      ctrl.showEmptyState = () => ctrl.loaded && !ctrl.venues.length && !searching

      ctrl.$onInit = function() {
        ctrl.Persona = Persona
        updateParams() // presets stored perPage and starts initial load
      }

      ctrl.addLocation = function(ev) {
        const detail = { target: ev.target, orgId: currentOrg.id, skipEdit: true }
        const el = document.getElementById('venue-add-edit-modal')
        el.dispatchEvent(new CustomEvent('open', { detail }))
        el.removeEventListener('venueSave', ctrl.loadVenues)
        el.addEventListener('venueSave', ctrl.loadVenues)
      }

      ctrl.toggleDetailLocation = function(venue) {
        ctrl.panelLoading = false
        ctrl.detailLocation = (ctrl.detailLocation === venue) ? null : venue
      }

      ctrl.loadVenues = function() {
        ctrl.reloading = true
        return Venue
          .findAll(ctrl.params, { bypassCache: true })
          .then((venues) => {
            searching = !!ctrl.params.name
            ctrl.venues = venues
            ctrl.paginationObject = _.extend({}, venues.pagination) // clone triggers changes in the se-pagination component
          })
          .then(buildactionLinks)
          .then(() => {
            ctrl.reloading = false
            ctrl.loaded = true
          })
      }

      function updateParams(params = { page: 1 }) {
        if (ctrl.pagination) ctrl.params.per_page = ctrl.pagination.pageParams.per_page
        if (params) ctrl.params = { ...ctrl.params, ...params }
      }

      function buildactionLinks() {
        if (!ctrl.venues.length) return

        _.forEach(ctrl.venues, function(venue) {
          venue.actionLinks = [
            {
              text: i18ng.t('Delete'),
              action: function() {
                dialog.confirm({
                  component: 'confirm-dialog',
                  attrs: {
                    heading: i18ng.t('LEAGUE_LOCATIONS.DETAILS.LABELS.title', { action: 'Delete' }),
                    message: i18ng.t('LEAGUE_LOCATIONS.confirm_delete', { name: venue.name }),
                    confirmAction: function() { deleteVenue(venue) },
                    modalSize: 'small',
                    deleteButton: true
                  }
                })
              }
            }
          ]
        })
      }

      function deleteVenue(venue) {
        venue.DSDestroy()
          .then(function() { pageViewHandler.fireEvent('Location.DeleteLocationModal.Delete', 8) })
          .then(ctrl.loadVenues)
      }

      // This triggers initial load and when search/page changes
      $scope.$watch('$ctrl.params', ctrl.loadVenues, true)
    }
  })
