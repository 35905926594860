angular.module('pl-shared')
  .component('imageUpload', {
    templateUrl: '/static/shared/components/image-upload/image-upload.html',
    bindings: {
      imgHeight: '<',
      imgWidth: '<',
      maxWidth: '<',
      circular: '<',
      onSave: '<',
      cropperTitle: '@',
      inputId: '@',
      model: '=',
      staticFileTypes: '<?'
    },
    transclude: true,
    controller: function($q, $scope, $element, _, fileHelper, makeDroppable, $timeout, Alerts) {
      var $ctrl = this

      $ctrl.cancel = cancel
      $ctrl.$onInit = init
      $ctrl.cancel = cancel
      $ctrl.save = save
      $ctrl.done = done
      $ctrl.uploadedImage = null
      $ctrl.croppedImage = null

      var acceptTypes = $ctrl.staticFileTypes ? fileHelper.staticImageTypes : fileHelper.imageTypes
      var acceptTypesStr = _.uniq(_.values(_.pick(fileHelper.mimeTypes, acceptTypes))).join(',')
      var acceptRgx = new RegExp('\\.(' + acceptTypes.map(_.escapeRegExp).join('|') + ')$', 'i')
      var filename
      var filetype

      function init() {
        $ctrl.aspectRatio = thousandths($ctrl.imgWidth / $ctrl.imgHeight)
        $ctrl.aspectRatioStyle = { paddingTop: thousandths(100 / $ctrl.aspectRatio) + '%' }
        $ctrl.areaType = $ctrl.circular ? 'circle' : 'rectangle'
        if ($ctrl.circular) $ctrl.wrapperClass = 'pl-image-upload--circular'
        if ($ctrl.maxWidth) $ctrl.wrapperStyle = { maxWidth: $ctrl.maxWidth }
        if (!$ctrl.cropperTitle) $ctrl.cropperTitle = 'IMAGE_UPLOAD.cropper_title'
        // make the element droppable and unbind the global event handlers when the scope is destroyed
        $scope.$on('$destroy', makeDroppable($element.children().first()[0], $ctrl.inputId, newImage, acceptTypesStr))
      }

      function thousandths(float) {
        return Math.round(float * 1000) / 1000
      }

      function newImage(files) {
        var file = validateFile(files)
        if (!file) return

        filename = file.name
        filetype = file.type

        var reader = new FileReader()

        reader.onload = function(event) {
          $ctrl.uploadedImage = event.target.result
          $scope.$apply(openCropper)
        }

        reader.readAsDataURL(file)
      }

      function validateFile(fileData) {
        var file = fileData[0]
        if (_.any(acceptTypes) && !acceptRgx.test(file.name)) {
          console.error('Invalid File Type', file.name)
          return false
        }

        return file
      }

      function cancel() {
        $ctrl.showCropper = false
      }

      function openCropper() {
        $ctrl.showCropper = true
        $timeout(function() {
          var cropperEl = $element.find('.pl-overlay').detach().appendTo('body')
          $scope.$on('$destroy', function() { cropperEl.remove() })
        })
      }

      function save() {
        return $q.when($ctrl.onSave(modelData())).then(done)
      }

      function done() {
        $ctrl.showCropper = false
        if ($ctrl.croppedImage) $ctrl.model = modelData()
      }

      function modelData() {
        var file = $ctrl.croppedImage
        return {
          data: fileHelper.stripBase64Data(file),
          filetype: filetype,
          filename: filename
        }
      }

    }

  })
