// Toggle pl-has-focus on the parent element when a child input is focused/blurred.
function plHasFocusToggler(childSelector) {
  return function toggleHasFocus($scope, $element, $attrs) {
    $element.on('focus', childSelector, function() {
      $element.addClass('pl-has-focus')
    })
    $element.on('blur', childSelector, function() {
      $element.removeClass('pl-has-focus')
    })
  }
}

angular.module('pl-shared')

  .directive('plAddonField', function() {
    return {
      restrict: 'C',
      link: plHasFocusToggler('input')
    }
  })
