angular.module('org-admin')
  .component('rosterTeamsFilterChips', {
    bindings: {
      filters: '<',
      leagueHidden: '<?',
      leagueRemovable: '<',
      leagueSelectable: '<?', // chevron will not show on league chip if not present
      removeFilter: '&' // arguments: `filter` (name of property on filters object)
    },
    templateUrl: '/static/org/roster-management/roster-teams-filter-chips.html',
    controller: function(currentOrg, DivisionInstances, Leagues, RosteringState, Seasons, SeasonTeams, Subseasons) {

      var ctrl = this
      ctrl.state = RosteringState

      ctrl.$onInit = function() {
        // Preload the DS data store in case this is shown before the filterbox modal
        if (ctrl.filters.league_id) Leagues.find(ctrl.filters.league_id)
        if (ctrl.filters.season_id) Seasons.find(ctrl.filters.season_id)
        if (ctrl.filters.subseason_id) Subseasons.find(ctrl.filters.subseason_id)
        if (ctrl.filters.division_instance_id) DivisionInstances.find(ctrl.filters.division_instance_id)
        if (ctrl.filters.team_id) {
          var teamIDs = ctrl.filters.team_id.map(Number)
          var loadedTeamIDs = _.map(ctrl.state.store.seasonTeams.collection, 'team_id')
          var missingTeamIDs = _.difference(teamIDs, loadedTeamIDs)
          if (missingTeamIDs.length) {
            SeasonTeams.findAll({
              org_id: currentOrg.id,
              team_id: missingTeamIDs
            }, {
              load: 'all',
              paramSerializer: '$httpParamSerializerJQLike'
            })
          }
        }
      }

    }
  })
