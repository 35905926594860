angular.module('org-admin')
  .component('claimConfirm', {
    bindings: {},
    templateUrl: '/static/org/claim/claim-confirm.html',
    controller: function(_, $q, $element, currentOrg, Organization, OrganizationOptions,
      GeographicOptions, i18ng, Geocoder, renderContext, setAs, SNAP, $timeout) {
      var $ctrl = this

      cloneOrg()
      $ctrl.save = save
      $ctrl.showAddress1 = !!currentOrg.address_1
      $ctrl.showAddress2 = !!currentOrg.address_2
      $ctrl.logoUrl = SNAP.assetPath + '/images/sportsengine-mark-color-black.svg'

      loadData().then(loadSuccess)

      function cloneOrg() {
        $ctrl.org = angular.copy(currentOrg)
        $ctrl.country = $ctrl.country || 'USA'
        $ctrl.org.postal_code = ($ctrl.org.postal_code || '').toUpperCase()
        $ctrl.org.sports = _.map($ctrl.org.sports, function(sport) {
          return sport.value
        })
      }

      function loadData() {
        return $q.all([
          loadSports(),
          loadStates()
        ])
      }

      function loadSuccess() {
        $ctrl.loaded = true
        focusStepTitle()
      }

      function loadSports() {
        return OrganizationOptions.getOptions()
          .then(function(data) {
            $ctrl.sports = data.sports
          })
      }

      function loadStates() {
        return GeographicOptions.subregions({ params: { country: $ctrl.org.country } })
          .then(function(response) {
            $ctrl.stateOptions = response
          })
      }

      function focusStepTitle() {
        $timeout(function() {
          $element
            .find('.pl-wizard__title')
            .focus()
        }, 10, false)
      }

      function save() {
        return Organization.saveAndSyncLocation($ctrl.org, currentOrg)
          .then(saveSuccess, saveError)
      }

      function saveSuccess(data) {
        angular.merge(currentOrg, data)
        cloneOrg()
        renderContext.goto('claimPreview')
      }

      function saveError(err) {
        $ctrl.failure = err
      }

    }
  })
