(function() {

  function addKarmaBase(url) {
    if (typeof window.__karma__ !== 'undefined') return '/base' + url
    return url
  }

  angular.module('org-admin')
    .config(function(i18ngProvider) {
      i18ngProvider.init({
        getAsync: false,
        lng: 'en',
        fallbackLng: 'en',
        ns: { namespaces: ['org', 'shared'], defaultNs: 'org' },
        fallbackNS: 'shared',
        useCookie: false,
        useLocalStorage: false,
        resGetPath: addKarmaBase('/static/__ns__/locales/__lng__/translation.json')
      })
    })

})()
