angular.module('org-admin')
  .component('lockRosterAlert', {
    bindings: {
      program: '<',
    },
    templateUrl: '/static/org/governing-seasons/lock-roster-alert.html',
    controller: function() {
      var ctrl = this
    }
  })
