angular.module('org-admin')
  .service('orgAppPermissions', function(
    _, billingProvisioningService, currentUser, currentOrg, featureToggles, ENV,
    OAuthState, OrganizationProduct, CentralService, CurrentUserService, payload, $q, Site, showError
  ) {

    var orgId

    function init(_orgId) {
      orgId = _orgId
      return CurrentUserService.find()
        .then(checkUser)
        .then(getPowerPayProvisioning)
        .then(hqPolicyCheck)
        .then(function(policy) {
          const actions = (policy || {}).actions || {}
          if (!actions.access) {
            showError('401')
            return
          }
          currentUser.hqRoles = actions
          return $q.all([
            getFeatureToggles(),
            getSitesProvisioning(),
            getOrganizationProducts(),
          ]).then(build)
        })
    }

    function checkUser(user) {
      if (!user || !user.id || !user.roles) {
        OAuthState.initiateLogin('org')
        return false
      }

      return true
    }

    function hqPolicyCheck(powerPay) {
      const resource = {
        id: orgId,
        type: 'hq_permissions'
      }
      return CentralService.checkPolicy({
        actions: '*',
        resource,
        context: {
          power_pay_provisioning: powerPay,
          root_organization_ids: _.map(currentOrg.root_organizations, function(ro) { return ro.id }),
        }
      })
    }

    function build() {
      var permissions = {}
      Object.keys(currentUser.hqRoles || {}).forEach(roleKey => {
        var ccRoleKey = _.camelCase(roleKey)
        if (['access', 'topDownOrgAccess'].includes(ccRoleKey)) {
          permissions[ccRoleKey] = currentUser.hqRoles[roleKey]
        }
        else {
          permissions[ccRoleKey] = {}
          Object.keys(currentUser.hqRoles[roleKey]).forEach(actionKey => {
            var ccActionKey = _.camelCase(actionKey)
            permissions[ccRoleKey][ccActionKey] = currentUser.hqRoles[roleKey][actionKey]
          })
        }
      })
      var checks = {
        powerPay: payload.provisioning.powerPay || {},
        sites: angular.copy(payload.provisioning.sites) || {},
      }
      permissions.checks = checks

      return permissions
    }

    return {
      build: build,
      init: init,
    }

    // private

    function getPowerPayProvisioning(userCheck) {
      if (!orgId || !userCheck) return $q.resolve(null)
      return billingProvisioningService.fetch(orgId).then(function(result) {
        var provisioning = (result && result.result) || result || {}
        payload.provisioning.powerPay = provisioning
        return provisioning
      })
        .catch(function(error) {
          payload.provisioning.powerPay = {
            complete: false,
            discounts: {},
            financial_settings: {},
            incomplete: true,
            payment_terms: {},
            processor_settings: {},
            store: { bank_account: {} },
            transactions: {},
            usable: false,
            use_financials: false,
            visible: false,
          }
        })
    }

    function getSitesProvisioning() {
      var promise = orgId ? Site.findAll({ organization_id: orgId }) : $q.resolve([])
      return promise.then(function(sites) {
        payload.provisioning.sites = {
          complete: sites.length > 0,
          incomplete: sites.length <= 0,
          provisioned: (sites.length > 0) && (sites[0].provisioned == 'provisioned'),
          sites: sites
        }
      })
    }

    function getOrganizationProducts() {
      if (!orgId) return $q.resolve(null)
      return OrganizationProduct.findByOrgId(orgId).then(function(results) {
        payload.organizationProducts = results
      })
    }

    function getFeatureToggles() {
      return featureToggles.$promise
    }
  })
